import {
  handleRequest,
  request,
  setQuery
} from '../utils/fetch';
import creatHttp from '../utils/apiFactory';
const apiConfig = require('../config/api.config');
import Rsa from '~/utils/rsa';
// rsa加密
const encryption = async (params) => {
  const PublicKey =  await getPublicKey()
  let __params = {}
  for(let key of Object.keys(params)) {
    __params[key] = key == 'password' ? Rsa.encryption(PublicKey, params[key]) :  params[key]
  }
 
  return __params
}

// 有关用户的接口调用
// 判断登录
export const checkLogin = () => {
  return handleRequest(request.get(apiConfig.checkLogin));
};
// 获取法币转换
export const getCurrencies = () => {
  return handleRequest(request.get(apiConfig.getCurrencies));
};
export const getPublicKey = () => {
  return handleRequest(request.get(apiConfig.getPublicKey)).then(res => {
    if (res.success && res.data) {
     return res.data
    }
    return null
  });
};
// 登录
export const login = (params) => {
  return handleRequest(request.post(apiConfig.loginApi, params));
};
// 登录2
export const login2 = async (params) => {
  const __params = await encryption(params)
  return handleRequest(request.post(apiConfig.loginApi2, __params));
};
// google验证v2
export const recaptchaV3Check = (params) => {
  return handleRequest(request.post(apiConfig.recaptchaV3Check, params));
};
// google验证v3
export const recaptchaV2Check = (params) => {
  return handleRequest(request.post(apiConfig.recaptchaV2Check, params));
};

// 阿里验证
export const aliSliderCheck = (params) => {
  return handleRequest(request.post(apiConfig.aliSliderCheck, params));
};

// 腾讯验证
export const tencentCaptchaCheck = (params) => {
  return handleRequest(request.post(apiConfig.tencentCaptchaCheck, params));
};

// 阿里登录
export const login4AliSlider = (params) => {
  return handleRequest(request.post(apiConfig.login4AliSlider, params));
};

// 登出
export const logout = (params) => {
  return handleRequest(request.post(apiConfig.logoutApi + setQuery(params)));
};
// 手机注册发送短信
export const registerSendSMS = (params) => {
  return handleRequest(request.post(apiConfig.registerSendSMSApi, params, {
    transformRequest: [function (data) {
      let ret = '';
      for (let it in data) {
        ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&';
      }
      return ret;
    }]
  }));
};
// 手机注册发送短信2
export const registerSendSMS2 = (params) => {
  return handleRequest(request.post(apiConfig.registerSendSMSApi2, params, {
    transformRequest: [function (data) {
      let ret = '';
      for (let it in data) {
        ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&';
      }
      return ret;
    }]
  }));
};

// 手机注册
export const registerByPhone = async (params) => {
  const __params = await encryption(params)
  return handleRequest(request.post(apiConfig.registerForPhoneApi, __params), params.isHandlerError);
};

// 邮箱注册发送邮件验证码
export const registerByEmail = (params) => {
  return handleRequest(request.post(apiConfig.registerByEmailApi, params));
};
// 邮箱注册发送邮件验证码2
export const registerByEmail2 = async (params) => {
  const __params = await encryption(params)
  return handleRequest(request.post(apiConfig.registerByEmailApi2, __params));
};

// 常用设备发送邮箱验证码
export const EquipmentSendEmailCode = (params) => {
  return handleRequest(request.post(apiConfig.EquipmentSendEmailCodeApi + setQuery(params)));
};

// 常用设备发送手机验证码
export const EquipmentSendMobileCode = (params) => {
  return handleRequest(request.post(apiConfig.EquipmentSendMobileCodeApi + setQuery(params)));
};

// 常用设备储存
export const EquipmentConfirm = (params) => {
  return handleRequest(request.post(apiConfig.EquipmentConfirmApi + setQuery(params)));
};

// 是否为常用设备储存
export const EquipmentIsTure = (params) => {
  return handleRequest(request.post(apiConfig.EquipmentIsTureApi + setQuery(params)));
};

// 常用设备管理列表
export const EquipmentDataList = (params) => {
  return handleRequest(request.post(apiConfig.EquipmentDataListApi + setQuery(params)));
};

// 删除设备列表
export const EquipmenDel = (params) => {
  return handleRequest(request.post(apiConfig.EquipmenDelApi + setQuery(params)));
};

// 邮件注册激活
export const emailActive = (params) => {
  return handleRequest(request.post(apiConfig.emailActiveApi + setQuery(params)), params.isHandlerError);
};

// 安全验证发送短信
export const securityAuthenticateSendSMS = (params) => {
  return handleRequest(request.post(apiConfig.securityAuthenticateSendSMSApi + setQuery(params)));
};

// 安全验证
export const securityAuthenticate = (params) => {
  return handleRequest(request.post(apiConfig.securityAuthenticateApi + setQuery(params)), params.isHandlerError);
};

// 异地登录验证
export const loginRegionCheckAuth = (params) => {
  return handleRequest(request.post(apiConfig.loginRegionCheckAuthApi + setQuery(params)), params.isHandlerError);
};

// 用户重新发送异地登录验证码
export const resendRegionAuthCode = (params) => {
  return handleRequest(request.post(apiConfig.resendRegionAuthCode + setQuery(params)));
};

// 极验验证初始化
export const requestCheckCode = (params) => {
  return handleRequest(request.post(apiConfig.requestCheckCode + setQuery(params)));
};

// 更新邮件订阅
export const updateMailSubscribe = (params) => {
  return handleRequest(request.post(apiConfig.updateMailSubscribe + setQuery(params)));
};

// 更新语言设置
export const updateLang = (params) => {
  return handleRequest(request.post(apiConfig.updateLang + setQuery(params)));
};

// 重置密码发送短信
export const resetPwdSendSMS = (params) => {
  return handleRequest(request.post(apiConfig.resetPwdSendSMSApi, params, {
    transformRequest: [function (data) {
      let ret = '';
      for (let it in data) {
        ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&';
      }
      return ret;
    }]
  }));
};
// 重置密码发送短信2
export const resetPwdSendSMS2 = (params) => {
  return handleRequest(request.post(apiConfig.resetPwdSendSMSApi2, params, {
    transformRequest: [function (data) {
      let ret = '';
      for (let it in data) {
        ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&';
      }
      return ret;
    }]
  }));
};

// 重置密码验证短信
export const resetPwdSMSConfirm = (params) => {
  return handleRequest(request.post(apiConfig.resetPwdSMSConfirmApi, params), params.isHandlerError);
};

// 重置密码判断密码
export const resetPassword = (params) => {
  return handleRequest(request.post(apiConfig.resetPasswordApi + setQuery(params)));
};

// 注册成功自动登录
export const loginByToken = (params) => {
  return handleRequest(request.post(apiConfig.loginByTokenApi + setQuery(params)));
};

export const getUserBaseInfo = (params) => {
  return handleRequest(request.post(apiConfig.getUserBaseInfoApi + setQuery(params)));
};

// 获取用户认证信息
export const getUserVerificationInfo = (params) => {
  return handleRequest(request.post(apiConfig.getUserVerificationInfoApi + setQuery(params)));
};
// 上传企业认证信息
export const companyVerification = (params) => {
  return handleRequest(request.post(apiConfig.companyVerification, params, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }));
};
// 上传企业认证信息图片
export const companyVerificationUpload = (params) => {
  return handleRequest(request.post(apiConfig.companyVerificationUpload, params, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }));
};
// 获取用企业认证信息
export const getCompanyVerificationInfo = (params) => {
  return handleRequest(request.post(apiConfig.getCompanyVerificationInfo + setQuery(params)));
};
// 获取kyc认证国家
export const getKycCountry = (params) => {
  return handleRequest(request.post(apiConfig.getKycCountryApi + setQuery(params)));
};
// 获取用户银行卡绑定信息
export const getUserBandingRechargeCard = (params) => {
  return handleRequest(request.post(apiConfig.getUserBandingRechargeCardApi + setQuery(params)));
};

// 提交用户认证信息
export const identityVerification = (params) => {
  return handleRequest(request.post(apiConfig.identityVerificationApi, params, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }));
};

// 获取用户关于某币种的main资产信息
export const getCurSymbolAssets = (params) => {
  let commodity = {
    coinCode: params.symbol.split('-')[2]
  };
  let currency = {
    coinCode: params.symbol.split('-')[1]
  };
  return Promise.all([
    handleRequest(request.post(apiConfig.getMainAssetByCoin + setQuery(commodity))),
    handleRequest(request.post(apiConfig.getMainAssetByCoin + setQuery(currency)))
  ]);
};

export const getWSTokenInfo = (params) => {
  return handleRequest(request.post(apiConfig.getWSToken + setQuery(params)));
};

// 币币资产
// 获取用户main资产总和
export const getUserTradeTotalAssets = (params) => {
  return handleRequest(request.post(apiConfig.getUserTradeTotalAssetApi + setQuery(params)));
};
// 获取用户关于某币种的trade资产信息
export const getTradeAssetBySymbol = (params) => {
  return handleRequest(request.post(apiConfig.getTradeAssetBySymbol + setQuery(params)));
};

// 根据币种获取用户资产信息
export const getTradeAssetByCoin = (params) => {
  return handleRequest(request.post(apiConfig.getTradeAssetByCoin + setQuery(params)));
};
// 获取用户总资产
export const getUserFixAssetByCoin = (params) => {
  return handleRequest(request.post(apiConfig.getUserFixAssetByCoin + setQuery(params)));
};
// 取用户活动资产
export const getUserActiveAssetByCoin = (params) => {
  return handleRequest(request.post(apiConfig.getUserActiveAssetByCoin + setQuery(params)));
};
// 获取用户资产变更记录
export const getUserTradeAssetChangeRecords = (params) => {
  return handleRequest(request.post(apiConfig.getUserTradeAssetChangeRecords + setQuery(params)));
};
// 获取用户所有币种资产
export const getUserTradeAssetsList = (params) => {
  return handleRequest(request.post(apiConfig.getUserTradeAssetsList + setQuery(params)));
};
// 主账户转币币
export const transferMainToTrade = (params) => {
  return handleRequest(request.post(apiConfig.transferMainToTrade + setQuery(params)));
};
// 主账户转币币
export const transferTradeToMain = (params) => {
  return handleRequest(request.post(apiConfig.transferTradeToMain + setQuery(params)));
};
// MT5划转，主账户转capps
export const transferMt5 = (params) => {
  return handleRequest(request.post(apiConfig.transferMt5 + setQuery(params)));
};
// USDT划转
export const transferUsdt = (params) => {
  return handleRequest(request.get(apiConfig.transferUsdt + setQuery(params) ));
};
// 申请capps转主账户
export const requestTransferDapps2Main = (params) => {
  return handleRequest(request.post(apiConfig.requestTransferDapps2Main + setQuery(params)));
};

// 主账户资产
// 获取用户所有币种的资产信息
export const getUserMainAssetsList = (params) => {
  return handleRequest(request.post(apiConfig.getUserMainAssetsList + setQuery(params)));
};

// 获取用户关于某币种的资产信息
export const getMainAssetByCoin = (params) => {
  return handleRequest(request.post(apiConfig.getMainAssetByCoin + setQuery(params)));
};
// 获取用户main资产总和
export const getUserMainTotalAssets = (params) => {
  return handleRequest(request.post(apiConfig.getUserMainTotalAssetApi + setQuery(params)));
};

// 获取用户资产变更信息
export const getUserAssetChangeRecords = (params) => {
  return handleRequest(request.post(apiConfig.getUserAssetChangeRecords + setQuery(params)));
};
// 获取用户安全信息
export const getUserSafeInfos = (params) => {
  return handleRequest(request.post(apiConfig.getUserSafeInfoApi + setQuery(params)));
};
// 修改密码发送验证码
export const modifyPwdSendCode = (params) => {
  return handleRequest(request.post(apiConfig.modifyPwdSendCodeApi + setQuery(params)));
};
// 修改密码确认
export const modifyPwdConfirm = (params) => {
  return handleRequest(request.post(apiConfig.modifyPwdConfirmApi, params));
};
// 绑定邮箱发送邮件验证码
export const bindEmailSendEmailCode = (params) => {
  return handleRequest(request.post(apiConfig.bindEmailSendEmailCodeApi + setQuery(params)));
};
// 绑定邮箱发送短信验证码
export const bindEmailSendMobileCode = (params) => {
  return handleRequest(request.post(apiConfig.bindEmailSendMobileCodeApi + setQuery(params)));
};
// 绑定邮箱确认
export const bindEmailConfirm = (params) => {
  return handleRequest(request.post(apiConfig.bindEmailConfirmApi + setQuery(params)));
};
// 绑定手机发送短信
export const bindPhoneSendSMS = (params) => {
  return handleRequest(request.post(apiConfig.bindPhoneSendSMSApi + setQuery(params)));
};
// 绑定手机发送邮件验证码
export const bindPhoneSendEmailCode = (params) => {
  return handleRequest(request.post(apiConfig.bindPhoneSendEmailCodeApi + setQuery(params)));
};
// 绑定手机确认
export const bindPhoneConfirm = (params) => {
  return handleRequest(request.post(apiConfig.bindPhoneConfirmApi + setQuery(params)));
};
// 解绑手机发送短信
export const unbindPhoneSendSMS = (params) => {
  return handleRequest(request.post(apiConfig.unbindPhoneSendSMSApi + setQuery(params)));
};
// 解绑手机发送邮件验证码
export const unbindPhoneSendEmailCode = (params) => {
  return handleRequest(request.post(apiConfig.unbindPhoneSendEmailCodeApi + setQuery(params)));
};
// 解绑手机确认
export const unbindPhoneConfirm = (params) => {
  return handleRequest(request.post(apiConfig.unbindPhoneConfirmApi + setQuery(params)));
};
// 绑定谷歌
// 绑定/更换google发送邮箱验证码
export const bindGoogleSendCode = (params) => {
  return handleRequest(request.post(apiConfig.bindGoogleSendCodeApi + setQuery(params)));
};
// 绑定google发送短信验证码
export const bindGoogleSendSmsCode = (params) => {
  return handleRequest(request.post(apiConfig.bindGoogleSendSmsCodeApi + setQuery(params)));
};
// 绑定google确认
export const bindGoogleConfirm = (params) => {
  return handleRequest(request.post(apiConfig.bindGoogleConfirmApi + setQuery(params)));
};
// 绑定google确认
export const bindGoogleSmsCodeConfirm = (params) => {
  return handleRequest(request.post(apiConfig.bindGoogleSmsCodeConfirmApi + setQuery(params)));
};
// 初始化谷歌私钥
export const initializeGooglePrivateKey = (params) => {
  return handleRequest(request.post(apiConfig.initializeGooglePrivateKeyApi + setQuery(params)));
};
// 更换谷歌
// （初始化谷歌私钥）
export const initializeResetGooglePrivateKey = (params) => {
  return handleRequest(request.post(apiConfig.initializeResetGooglePrivateKey + setQuery(params)));
};
// （谷歌验证器验证码验证）参数（googleCode）
export const resetGoogleCaptcha = (params) => {
  return handleRequest(request.post(apiConfig.resetGoogleCaptcha + setQuery(params)));
};
// （发送手机验证码）
export const resetBindGoogleSendSmsCode = (params) => {
  return handleRequest(request.post(apiConfig.resetBindGoogleSendSmsCode + setQuery(params)));
};
// （谷歌邮箱验证）参数（emailCode，googleCode）
export const resetGoogleAuthenticate = (params) => {
  return handleRequest(request.post(apiConfig.resetGoogleAuthenticate + setQuery(params)));
};
// （谷歌手机验证）参数（emailCode，googleCode）
export const resetGoogleAuthenticateSmsConfirm = (params) => {
  return handleRequest(request.post(apiConfig.resetGoogleAuthenticateSmsConfirm + setQuery(params)));
};

// 获取用户登录记录
export const getUserLoginLog = (params) => {
  return handleRequest(request.post(apiConfig.getUserLoginLogApi + setQuery(params)));
};
// 提交防钓鱼码
export const handleAntiCodeConfirm = (params) => {
  return handleRequest(request.post(apiConfig.handleAntiCodeConfirmApi + setQuery(params)));
};
// 手机验证码
export const securitySendMobileCode = (params) => {
  return handleRequest(request.post(apiConfig.securitySendMobileCodeApi + setQuery(params)));
};
// 邮箱验证码
export const securitySendEmailCode = (params) => {
  return handleRequest(request.post(apiConfig.securitySendEmailCodeApi + setQuery(params)));
};
// 操作安全设置
export const operationSecuritySet = (params) => {
  return handleRequest(request.post(apiConfig.operationSecuritySetApi + setQuery(params)));
};
// 绑定手机发送短信
export const getUserAuthenticationSecuritySetState = (params) => {
  return handleRequest(request.post(apiConfig.getUserAuthenticationSecuritySetApi + setQuery(params)));
};

// 返佣
export const queryMyBalance = (params) => {
  return handleRequest(request.post(apiConfig.queryMyBalanceApi + setQuery(params)));
};
export const getDistribCommsInfo = (params) => {
  return handleRequest(request.post(apiConfig.getDistribCommsInfo + setQuery(params)));
};
export const getUserInviteList = (params) => {
  return handleRequest(request.post(apiConfig.userInviteList + setQuery(params)));
};
export const queryReturnRecordInfo = (params) => {
  return handleRequest(request.post(apiConfig.queryReturnRecordInfo + setQuery(params)));
};
// 签到
export const isSignIned = (params = {}) => {
  return handleRequest(request.post(apiConfig.isSignIned + setQuery(params)));
};
export const signIn = (params = {}) => {
  return handleRequest(request.post(apiConfig.signIn + setQuery(params)));
};
export const signInRecord = (params = {}) => {
  return handleRequest(request.post(apiConfig.signInRecord + setQuery(params)));
};
export const getCurrentSignInActive = (params = {}) => {
  return handleRequest(request.post(apiConfig.getCurrentSignInActive + setQuery(params)));
};

// 统计用户邀请人数
export const queryInviteUserForReturn = (params) => {
  return handleRequest(request.post(apiConfig.queryInviteUserForReturn + setQuery(params)));
};
// 获取用户返佣资产整合
export const countTradeCommossions = (params) => {
  return handleRequest(request.post(apiConfig.staticDistribComms + setQuery(params)));
};
// 统计用户邀请人数
export const countTradeUserInvite = (params) => {
  return handleRequest(request.post(apiConfig.countTradeUserInviteApi + setQuery(params)));
};
// 获取返佣比例
export const queryRebateRatio = (params) => {
  return handleRequest(request.get(apiConfig.getRebateRatio));
};

// 根据渠道号查询邀请用户
export const queryUserByChannel = (params) => {
  return handleRequest(request.post(apiConfig.queryUserByChannel + setQuery(params)));
};
// 查询用户渠道相关信息
export const readUserActivityInfo = (params) => {
  return handleRequest(request.post(apiConfig.readUserActivityInfo + setQuery(params)));
};
// 获取佣金数据的接口
export const getDisturbCommInfoRank = (params) => {
  return handleRequest(request.post(apiConfig.getDisturbCommInfoRank + setQuery(params)));
};

// 设置交易密码
export const setTradePwd = (params) => {
  return handleRequest(request.post(apiConfig.setTradePwd, params));
};
// 设置交易密码时校验邮箱验证码
export const validateEmailCode = (params) => {
  return handleRequest(request.post(apiConfig.validateEmailCode, params));
};
// 设置交易密码时重新发送邮箱验证码
export const sendEmailCodeForTradePwd = (params) => {
  return handleRequest(request.post(apiConfig.sendEmailCodeForTradePwd + setQuery(params)));
};
// 修改交易密码验证频率
export const updateTradePwdStatus = (params) => {
  return handleRequest(request.post(apiConfig.updateTradePwdStatus, params));
};
// 查询交易密码验证频率
export const readTradePwdStatus = (params) => {
  return handleRequest(request.post(apiConfig.readTradePwdStatus + setQuery(params)));
};
// 获取ATOM借入价格
export const getAtomPrices = (params) => {
  return handleRequest(request.post(apiConfig.getExchargeBuyPrices + setQuery(params)));
};
// 按现有价格借入ATOM
export const buyAtom = (params) => {
  return handleRequest(request.post(apiConfig.buyExcharge + setQuery(params)));
};
// 获取用户兑换ATOM的信息
export const getAtomBorrow = (params) => {
  return handleRequest(request.post(apiConfig.getUserExchargeInfos + setQuery(params)));
};
// 获取IOU限制信息
export const getExchargeInfo = (params) => {
  return handleRequest(request.post(apiConfig.getExchargeInfo + setQuery(params)));
};
// 归还已借入的ATOM
export const returnAtomBack = (params) => {
  return handleRequest(request.post(apiConfig.returnBack + setQuery(params)));
};
// 获取用户是否有赠金
export const couponMessage = creatHttp(apiConfig.couponMessage);

// 获取所有功能白名单配置信息
export const getAllFunConifigInfo = (params) => {
  return handleRequest(request.get(apiConfig.getAllFunConifigInfo, { params }));
};
// 提现费率
export const getWithdrawFeeList = () => {
  return handleRequest(request.get(apiConfig.getWithdrawFeeListAPI));
};
// 查询首页赠金弹窗
export const popupQuery = (params) => {
  return handleRequest(request.get(apiConfig.popupQuery, { params }));
};
// 不再展示首页赠金弹窗
export const popupDel = (params) => {
  return handleRequest(request.get(apiConfig.popupDel, { params }));
};
// 查询盈止损弹窗
export const triggerQuery = (params) => {
  return handleRequest(request.get(apiConfig.triggerQuery, { params }));
};
// 不再展示盈止损弹窗
export const triggerClose = (params) => {
  return handleRequest(request.get(apiConfig.triggerClose, { params }));
};
