//  import common from './common/en';
 /*import pages from './pages/en';
 import userCenter from './userCenter/en';
 import shangbi from './shangbi/en';
 import turbo from './turbo/en'; */
 import enLocale from 'element-ui/lib/locale/lang/en';

 import en from './new/en';
 /* import iou from './iou/en';
 import user from './user/en'; */

 export default Object.assign(enLocale, en);
