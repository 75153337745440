export default {en:{
  "403": "Operation failed. Please contact customer service.",
  "500": "System error occurred",
  "5843": "Identification you submit has been used for another account. Please try again.",
  "10000": "Servers busy, please try later.",
  "100001": "Parameter error",
  "100002": "Abnormal Parameter",
  "100003": "Request \"header\" Error",
  "100004": "Verfication Code Expired",
  "100005": "Verofication Code Error",
  "100006": "You have been forbidden from trading, If you have any enquiries, please contact customer service.",
  "100007": "Position has been locked",
  "100008": "Invalid Amount",
  "100009": "Max Holding Position Missing",
  "100010": "Failed to place order - Max holding position limit exceeded!",
  "100011": "Max Order Amount Exceeded!",
  "100012": "Insufficient Margin",
  "100013": "Fail to update order",
  "100014": "Order doesn't exist",
  "100015": "No counter, can not place order",
  "100016": "Login time expired, please login again.",
  "100017": "No position to close!",
  "100018": "Incorrect price decimal!",
  "100019": "No Assets!",
  "100022": "Price cannot be 0",
  "200007": "Token trading searched is no longer available",
  "200008": "Contract doesn't exist!",
  "200009": "The coin is not available yet!",
  "200010": "Amount decimal is greater than this coin allows",
  "200011": "Failed to get quote",
  "200012": "Trading pair doesn't exist",
  "200013": "Trading pair required",
  "200015": "Insufficient balance",
  "200017": "Transfer failed!",
  "200018": "Please read and agree to the \"BitForex MT5 Users Agreement\"",
  "200024": "Tansfer error, please contact the custome service support.",
  "200025": "The amount you transfer must be more than {num}",
  "200026": "Transfer out limitation: Have to complete at least one transfer-in record",
  "300004": "Transfer failed, abnormal system environtment",
  "300006": "Transfer failed, invalid balance",
  "300007": "Please set the security verification method",
  "1000025": "Order failed. Close position orders can only be made when existing close position order is filled.",
  "1000026": "Order failed. Position with a better Best-Bit-Offer price detected on your account.",
  "ACT1000": "Parameter error",
  "ACT1001": "Voting has not started",
  "ACT1002": "Voting has ended",
  "ACT1003": "Less than the minimum vote amount",
  "ACT1004": "Insufficient assets",
  "ACT1005": "Duplicate Voting",
  "BACK_UF019": "This account is frozen.",
  "CAPP001": "Account does not exist",
  "CAPP0010": "Project you chose expired, please choose another project.",
  "CAPP0011": "Incorrect Password",
  "CAPP0012": "Authorization expired, please return to confirm the authorization.",
  "CAPP002": "Transfer in function is prohibited.",
  "CAPP003": "Account frozen, please contact the customer service support.",
  "CAPP004": "Transfer out function is prohibited.",
  "CAPP005": "Abnormal asset error",
  "CAPP006": "Token/coin trading you are looking for is not supported.",
  "CAPP007": "Abnormal account error",
  "CAPP008": "Order does not exist",
  "CAPP009": "Expired order",
  "CAPP010": "Project you chose expired, please choose another project.",
  "CAPP011": "Incorrect Password",
  "CAPP012": "Authorization expired, please return to confirm the authorization.",
  "CAPP018": "Asset transfer is forbidden.",
  "CAPP500": "Abnormal system error",
  "CT102": "Searched trading pair is not yet available. Please stay tuned to the latest annoucements for availabilities.",
  "CT103": "The entered value is outside of the allowable price range.",
  "CT104": "Insufficient available funds.",
  "CT105": "The order does not exist.",
  "CT106": "The order amount exceeds Max. limit.",
  "CT107": "The order is less than the Min. limit.",
  "CT108": "This account has been frozen.",
  "CT110": "Exceeded the maximum limit.",
  "CT200": "Spot Trading function on your account is locked, please contact the Customer Support Team for details.",
  "CT801": "Phone number not bound.",
  "CT804": "Phone number bound.",
  "CT805": "This phone number is already registered, please try again.",
  "CT806": "Verification code has expired.",
  "CT807": "SMS verification code check exceeded the number of restrictions.",
  "CT809": "Incorrect SMS verification code.",
  "CT810": "Please set up safety authentication.",
  "CT811": "Please bind Google private key.",
  "CT812": "Incorret Google verification code.",
  "CT822": "Google private key bound",
  "CT823": "Does not exist.",
  "CT824": "Can not be modified.",
  "CT825": "No change.",
  "CT826": "Security authentication successfully set up.",
  "CT902": "Does not exist.",
  "FC101": "Orderbook stopped",
  "FC102": "The account has been frozen.",
  "FC103": "Price exceeds limit.",
  "FC104": "The order amount is exceeds Max. limit.",
  "FC105": "Insufficient Funds.",
  "FC106": "Insufficient positions.",
  "FC108": "Failed to cancel the order.",
  "FC109": "The order amount is less than Min. limit",
  "FC200": "CApp functions on your account is locked, please contact the Customer Support Team for details.",
  "FC999": "Asset transfer is forbidden.",
  "FI105": "Current order have been redeemed",
  "FI109": "The order does not exist",
  "FI110": "Current order have been redeemed",
  "FT002": "Not enough balance in you account.",
  "LN1000": "Failed to increase/decrease assets",
  "LN1001": "Parameter Error",
  "LN1002": "Incorrect Loan Amount",
  "LN1003": "User does not exist",
  "LN1004": "Failed to obtain available user assets",
  "LN1005": "Insufficient available user assets",
  "LN1006": "No query result to this order",
  "LN1007": "Failed to freeze user assets",
  "LN1008": "Order creation failed",
  "LN1009": "Order repayment failed",
  "LN1010": "Failed to adjust order LTV",
  "LN1011": "Failed to unfreeze user assets",
  "LN1012": "Token rate have changed, please refresh the page and try again",
  "LN1013": "Failed to save LTV adjustment record",
  "LN1014": "Failed to freeze user assets, failed to unfreeze user assets",
  "LN1015": "Failed to deduct user assets",
  "LN1016": "Failed to deduct system assets",
  "LN1017": "Failed to add user assets",
  "LN1018": "Failed to add system assets",
  "LN1019": "Failed to freeze system assets",
  "LN1020": "Insufficient available system assets",
  "LN1021": "Failed to obtain available system assets",
  "LN1022": "Incorrect LTV adjustment amount",
  "LN1023": "Wrong type of coin or collateral coin borrowed",
  "LN1024": "Wrong Loan Term",
  "LN1025": "Wrong coin borrowed or collateral coin",
  "LN1026": "The order is complete, no need to continue to repay coins",
  "LN1027": "Failed to release borrowed coin",
  "LN1028": "Insufficient available coin to borrow",
  "LN1029": "Failed to increase the total amount of coin available to borrow",
  "LN1030": "Cannot repay due to order status change",
  "LN1032": "Asset overview calculation failed",
  "LN1033": "Network blocking，please try again later.",
  "LN1034": "There is no limit to this currency, please try again later.",
  "NFT100": "Event doesn't exist",
  "NFT101": "Event hasn't started",
  "NFT102": "Ended",
  "NFT103": "Directly purchase is not allowed",
  "NFT104": "Buyout is not allowed",
  "NFT105": "Buyout price is wrong",
  "NFT106": "Sold",
  "NFT107": "Unable to put on sale repeatable",
  "NFT108": "NFT assets error",
  "NFT110": "Transaction fee insufficient",
  "NFT111": "Current NFT is not operatable",
  "NFT112": "Withdrawal cancellation is not allowed",
  "NFT113": "Abnormal withdrawal data",
  "NFT114": "Listing parameter error",
  "NFT115": "Don't less than the current bid price",
  "NFT116": "The price cannot be 0",
  "NFT117": "Auction lot doesn't exist",
  "NFT118": "Unable to cancel after trading starts",
  "NFT120": "Withdrawal suspension",
  "NFT121": "Deposit suspension",
  "NFT122": "Withdrawal function on your account is locked, please contact the Customer Support Team for details.",
  "NFT123": "NFT Trading function on your account is locked, please contact the Customer Support Team for details.",
  "OAUTH100001": "Payment session expired, please try again.",
  "OAUTH100002": "Sign error",
  "OAUTH100003": "Authentication error",
  "OAUTH100004": "Swap error",
  "PB100000": "Wrong asset amount.",
  "PB100001": "Insufficient assets.",
  "PB100002": "Pre-purchase can not be less than 100.",
  "PB100003": "Incorrect pre-purchase price.",
  "PB100004": "Incorrect token type.",
  "PB100005": "The price is updated, please place your order again.",
  "PB100006": "Incorrect pre-purchase amount.",
  "PB110000": "Pre-purchase time has not come yet.",
  "RT100": "Verofication Code Error",
  "RT101": "Incorrect parameter format.",
  "RT102": "The trading pair does not exist",
  "RT103": "System configuration error.",
  "RT104": "Abnormal system environment",
  "RT201": "Favorites reached the Max. limit",
  "RT301": "Internal Server Error.",
  "RT302": "Internal Server Error.",
  "RT303": "Internal Server Error.",
  "RT304": "Internal Server Error.",
  "RT398": "Internal Server Error.",
  "RT399": "Server request frequency is too high.",
  "RT401": "Transfer type not supported",
  "RT999": "Undefined error.",
  "SL303": "Network blocking，please try again later.",
  "SL304": "Failed to place order - Max holding position limit exceeded!",
  "SP801": "Listing application has been approved and can not be changed.",
  "SP802": "No record of listing application. Please apply to list first.",
  "SP803": "You can not edit the configuration parameters.",
  "SP804": "Please pay the listing fee first.",
  "SP805": "Insufficient funds.",
  "SP806": "Listing fee has been paid.",
  "SP807": "The configuration parameter does not exist.",
  "TF001": "Your wallet account transfer function is prohibited, please contact the Customer Support Team for details.",
  "TF002": "Transfer function on your Spot account is prohibited, please check with the Customer Support Center for details.",
  "TF003": "Transfer function on your Spot account is prohibited, please check with the Customer Support Center for details.",
  "TF004": "Transfer function on your MT5 account is prohibited, please check with the Customer Support Center for details.",
  "TF005": "Transfer function on your CApp account is prohibited, please check with the Customer Support Center for details.",
  "TS1001": "System busy, Please try again later.",
  "TS1002": "System busy, Please try again later.",
  "TS1003": "Subscribed share exceeded",
  "TS1004": "Insufficient balance",
  "TS1005": "Query failed",
  "TS1006": "Subscription failed",
  "TS1007": "System busy, Please try again later.",
  "TS1008": "System busy, Please try again later.",
  "TS1009": "System busy, Please try again later.",
  "TS1010": "System busy, Please try again later.",
  "TS1011": "System busy, Please try again later.",
  "TS1012": "System busy, Please try again later.",
  "u100017": "Account does not exist",
  "u100018": "Invalid Balance",
  "u100019": "Assets update error",
  "u100020": "Position not found",
  "u100021": "More positions required",
  "u100022": "Tokens not found",
  "u100023": "Token Frozen Error",
  "u100024": "Trading pair not found",
  "u100025": "Ratio Mistmatch Error",
  "u100026": "Amount Error",
  "u100027": "Swap Amount Error",
  "u100028": "Pool Empty",
  "u100029": "Invalid Pool Balance",
  "u100030": "Redemption Ratio Error",
  "u100031": "Swap Trading function on your account is locked, please contact the Customer Support Team for details.",
  "u300006": "Account frozen, please contract the Customer Service Team",
  "u300007": "Asset Error",
  "u300008": "System Overload",
  "u300009": "Tokens already exist",
  "u400001": "The earliest time slot you can choose is 24 hours from when you participate.",
  "UF001": "Data cannot be empty.",
  "UF002": "No more than 20 withdrawal addresses.",
  "UF003": "This address already exists.",
  "UF015": "This account has been frozen.",
  "UF016": "The withdrawal address does not exist.",
  "UF017": "The withdrawal address does not belong to this user.",
  "UF018": "Resolving address failed.",
  "UF019": "Withdrawal function on your account is locked, please contact the Customer Support Team for details.",
  "UF020": "Exceeds the daily withdrawal limit.",
  "UF021": "Insufficient funds.",
  "UF022": "Unconfirmed deposit exists.",
  "UF023": "Resolving address failed.",
  "UF024": "Invalid FIO Crypto Handle.",
  "UF030": "Failed to add the address, please check the network connection and try again.",
  "UF034": "Cancel Failed",
  "UF036": "The withdrawal transaction fee is lower than the minimum limit.",
  "UF037": "The withdrawal amount is less than the minimum limit.",
  "UF038": "Withdraw to a BitForex address is not supported for this Coin/Token, please try another address.",
  "UF039": "Under maintenance, withdrawal is not supported temporarily",
  "UF101": "Incorrect TXID format, please enter again.",
  "UF102": "You have requested 5 times in an hour, please try again later.",
  "UF108": "Please deposit with the address you submitted.",
  "UF301": "The latest price has been changed. Please borrow again.",
  "UF302": "Insufficient Fund.",
  "UF303": "Invalid Amount.",
  "UF304": "Available borrowing amount has reached the limit.",
  "UF305": "Redeem is not available currently.",
  "UF307": "Borrowing Amount has reached the maximum amount.",
  "UF308": "The number of participants has reached the limit.",
  "UF312": "Borrow and return is not available due to forced liquidation.",
  "UF313": "Returning amount is more than required.",
  "US1752": "Phone No. Disabled",
  "US1753": "Email address Disabled",
  "US1754": "Identity authentication Disabled",
  "US1755": "Account closed",
  "US601": "Wrong email address format.",
  "US602": "Your password should be 8-20 characters long, containing uppercase & lowercase characters, and numbers.",
  "US604": "The file size exceeds the limit.",
  "US605": "Wrong file format.",
  "US607": "Please upload your ID photo.",
  "US608": "Please upload your Passport photo.",
  "US609": "Please complete ID verification (KYC) first.",
  "US651": "The API Info does not belong to the user",
  "US660": "Wrong image verification code.",
  "US661": "Identification you submit has been used for another account. Please try again.",
  "US665": "Failed to validate reCAPTCHA, please try again",
  "US667": "Please enter the phone number you binded.",
  "US668": "Identification you submit has been used for another account. Please try again.",
  "US669": "Unsupported Email Domain",
  "US671": "TOTP verification failed too many times, please try again later",
  "US701": "This email already exists",
  "US702": "Verification code has expired.",
  "US703": "Verification code is incorrect.",
  "US704": "This account does not exist.",
  "US705": "Your account is locked, please contact BitForex online service.",
  "US706": "Your account is locked, please try again in 1 hour.",
  "US707": "You have entered an incorrect verification code for 5 times. Please request a new verification code.",
  "US708": "The email does not exist.",
  "US709": "Incorrect email or password.",
  "US710": "This mobile already exists",
  "US711": "SMS code is incorrect.",
  "US712": "Incorrect username or password.",
  "US715": "Inviter's UID does not exist",
  "US724": "Remote login is too frequent, please try again later.",
  "US725": "Remote login failed.",
  "US726": "Remote login is too frequent, please try again later.",
  "US727": "Remote login is too frequent, please try again later.",
  "US732": "You have entered an incorrect passwords 6 times, please try again in 5 minutes.",
  "US733": "Incorrect trading password",
  "US750": "Email bound successfully!",
  "US752": "Please link your email address to your account.",
  "US753": "Verofication Code Error",
  "US754": "Verofication Code Error",
  "US756": "Verification code does not exist or has expired.",
  "US801": "No phone bound.",
  "US802": "Please request a new SMS verification code in 30s.",
  "US803": "SMS code error exceeds the maximum number of attempts. Please try again in 1 hour.",
  "US804": "Mobile number bound.",
  "US805": "The mobile number has been bound.",
  "US806": "Mobile Temp Info does not exist.",
  "US807": "You have entered an incorrect verification code 5 times, please request a new one.",
  "US808": "Verification code does not exist or has expired.",
  "US809": "Please enter the correct SMS code.",
  "US810": "Security authentication has not been set.",
  "US811": "Google private key has not been set.",
  "US812": "Please enter the correct Google 2FA password.",
  "US813": "Previous Mobile Temp Info does not exist.",
  "US814": "New mobile Temp info does not exist.",
  "US815": "Too many SMS verification code attempts, please try again in 1 hour.",
  "US816": "Please enter the correct SMS code.",
  "US817": "Please enter the correct previous SMS code.",
  "US818": "Too many SMS verification code attempts, please try again in 1 hour.",
  "US819": "Please enter the correct SMS code.",
  "US820": "Please enter the correct new SMS code.",
  "US821": "Unbind Phone Temp Info dose not exist.",
  "US822": "Google private key bound.",
  "US823": "The [securityAuthenticationName] dose not exist.",
  "US824": "The [securityAuthenticationName] can not change.",
  "US825": "The [securityAuthenticationName] has not changed.",
  "US826": "Security authentication set up.",
  "US829": "Verification failed, please try again.",
  "US830": "API Key amount exceeds the limit.",
  "US832": "Verification code does not exist or has expired.",
  "US833": "Invalid verification code.",
  "US834": "Please link your email address to your account.",
  "US835": "Verofication Code Error",
  "US857": "Email code failed 5 times. Please request a new Email code.",
  "US901": "The [notificationName] does not exist.",
  "US902": "The notification status does not change.",
  "UW205": "Sorry, your account assets have been frozen. If you have any questions, please contact BitForex online customer service.",
  "UW208": "Email verification failed, please check the network connection and try again.",
  "UW209": "The email failed to be sent, please try again.",
  "UW210": "The email verification code has expired, please request a new one.",
  "UW213": "The link has expired. Please resubmit the send request.",
  "UW236": "Please finish the security settings first.",
  "WE101": "Please login first.",
  "WE102": "No permission.",
  "WE103": "IP address is not allowed.",
  "WE104": "User access frequency is too high.",
  "WE204": "The account has been locked.",
  "WE205": "The account has been frozen.",
  "WE206": "Exceeded daily limit.",
  "WE207": "Insufficient funds.",
  "WE209": "Email failed to send.",
  "WE218": "User has not completed authentication.",
  "WE219": "Please complete the KYC verification."
}}