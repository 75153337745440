<template>
  <footer id="footer" :theme="footerTheme" :class="{'trade-page': isTradePage}">
    <div class="footer_head ipad">
      <div class="title text-[var(--color-text-1)]">BitForex</div>
      <div class="right">
        <a :href="$t('link_telegram')" target="_blank" rel="external nofollow noopener noreferrer" v-if="locale !== 'kr'">
                <svg class="ficon" aria-hidden="true">
                  <use xlink:href="#bf-icon_telegram"></use>
                </svg>
              </a>
              <a href="https://discord.com/invite/mGNxBxzk8b" target="_blank" rel="external nofollow noopener noreferrer">
                <svg class="ficon" aria-hidden="true">
                  <use xlink:href="#bf-discord-fill"></use>
                </svg>
              </a>
              <a :href="$t('link_twitter')" target="_blank" rel="external nofollow noopener noreferrer">
                <svg class="ficon" aria-hidden="true">
                  <use xlink:href="#bf-icon_twitter"></use>
                </svg>
              </a>
              <a :href="$t('link_facebook')" target="_blank" rel="external nofollow noopener noreferrer">
                <svg class="ficon" aria-hidden="true">
                  <use xlink:href="#bf-facebook-box-fill"></use>
                </svg>
              </a>
              <a :href="$t('link_instagram')" target="_blank" rel="external nofollow noopener noreferrer">
                <svg class="ficon" aria-hidden="true">
                  <use xlink:href="#bf-instagram-fill"></use>
                </svg>
              </a>
              <a :href="$t('link_linkedin')" target="_blank" rel="external nofollow noopener noreferrer">
                <svg class="ficon" aria-hidden="true">
                  <use xlink:href="#bf-linkedin-box-fill"></use>
                </svg>
              </a>
              <a :href="$t('link_youtube')" target="_blank" rel="external nofollow noopener noreferrer">
                <svg class="ficon" aria-hidden="true">
                  <use xlink:href="#bf-youtube-fill"></use>
                </svg>
              </a>
      </div>
    </div>
    <div class="main center">
      <div class="flex row1">
        <div class="flex row2">
          <div class="foot-item">
            <p class="item-title">{{$t('footer_7')}}</p>
            <ul>
              <li>
                <a :href="`/${locale}/AboutUs`">{{$t('footer_8')}}</a>
              </li>
              <li>
                <a :href="`/${locale}/Capital`">{{$t('footer_37')}}</a>
              </li>
              <li>
                <a :href="`/${locale}/JoinUs`">{{$t('footer_11')}}</a>
              </li>
              <li>
                <a :href="`/${locale}/ContactUs`">{{$t('footer_12')}}</a>
              </li>
              <li>
                <a :href="`/${locale}/vulnerability`">{{ $t('vulnerability_1') }}</a>
              </li>
              <li>
                <router-link to="/knightpage">{{$t('footer_6')}}</router-link>
              </li>
              <li>
                <a :href="`/${locale}/Fees`">{{$t('footer_15')}}</a>
              </li>
              <!-- Terms of -->
              <li>
                <a :href="$t('link_terms')" target="_blank" rel="external nofollow noopener noreferrer">{{$t('footer_14')}}</a>
              </li>
              <li  v-if="['en', 'pt'].includes(locale)">
                <a :href="`/${locale}/academy`">{{$t('academy_5')}}</a>
              </li>
            </ul>
          </div>
          <div class="foot-item">
            <p class="item-title">{{$t('home_v2_40')}}</p>
            <ul>
              <li>
                <router-link class="head-item ripple-effect" :to="`/buyCoins`"><span>{{$t('9243')}}</span>
                 </router-link>
              </li>
              <li>
                <router-link :to="`/spot/${spotSymbol}`">{{$t('footer_2')}}</router-link>
              </li>
              <li>
                <router-link :to="`/perpetual/${perpetualSymbol}`">{{$t('footer_3')}}</router-link>
              </li>
              <li>
                <a :href="`/${locale}/asset-management/list`">{{$t('home_v2_88')}}</a>
              </li>
              <li>
                <router-link :to="`/nft-market/index/market`">{{$t('home_v2_123')}}</router-link>
              </li>
              <li>
                <a :href="`/${locale}/starter/index`">{{$t('home_v2_124')}}</a>
              </li>
              <li>
                <a :href="`/${locale}/loan/index`">{{$t('home_v2_125')}}</a>
              </li>
              <!-- <li v-if="funConfigs[1]">
                <router-link to="/capptown">CApp</router-link>
              </li> -->
              <!-- <li>
                <a :href="`/${locale}/eazyswap-introduction`">EazySwap</a>
              </li> -->
            </ul>
          </div>
          <div class="foot-item">
            <p class="item-title">{{$t('footer_31')}}</p>
            <ul>
              <li><a :href="`/${locale}/Support`">{{$t('footer_17')}}</a></li>
              <li>
                <a :href="`https://support.bitforex.com/hc/${curZendeskLang}/categories/360000629712`" target="_blank" rel="external nofollow noopener noreferrer">{{$t('footer_36')}}</a>
              </li>
              <li>
                <a :href="$t('link_request')" target="_Blank" rel="external nofollow noopener noreferrer" @click="handleRequest">{{$t('footer_24')}}</a>
              </li>
              <li>
                <a :href="$t('link_api')" target="_Blank" rel="external nofollow noopener noreferrer" @click="handleRequest">{{$t('home_v2_130')}}</a>
              </li>
              <li>
                <a :href="$t('support_30')" target="_Blank" rel="external nofollow noopener noreferrer">{{$t('support_4')}}</a>
              </li>
              <li>
                <a :href="`https://forms.gle/ZnPvmvuvYPbEgpCLA`" target="_blank">{{$t('footer_listing_application')}}</a>
              </li>
              <li>
                <a :href="`/${locale}/officialverify`">{{$t('media_1')}}</a>
              </li>
              <!-- <li>
                <router-link to="/bftokeninfo">BF Token</router-link>
              </li> -->
            </ul>
          </div>
          <div class="foot-item">
            <p class="item-title">{{$t('home_v2_28')}}</p>
            <ul>
              <li>
                <router-link :to="`/spot/bf_usdt`">{{$t('home_v2_41')}}</router-link>
              </li>
              <li>
                <router-link :to="`/spot/btc_usdt`">{{$t('home_v2_42')}}</router-link>
              </li>
              <li>
                <router-link :to="`/spot/eth_usdt`">{{$t('home_v2_43')}}</router-link>
              </li>
              <li>
                <router-link :to="`/spot/omi_usdt`">{{$t('home_v2_44')}}</router-link>
              </li>
              <li>
                <router-link :to="`/spot/doge_usdt`">{{$t('home_v2_45')}}</router-link>
              </li>
              
            </ul>
          </div>
          <div class="foot-item web">
            <p class="item-title">{{$t('footer_12')}}</p>
            <div class="icon">
              <a :href="$t('link_telegram')" target="_blank" rel="external nofollow noopener noreferrer" v-if="locale !== 'kr'">
                <svg class="ficon" aria-hidden="true">
                  <use xlink:href="#bf-icon_telegram"></use>
                </svg>
              </a>
              <a href="https://discord.com/invite/mGNxBxzk8b" target="_blank" rel="external nofollow noopener noreferrer">
                <svg class="ficon" aria-hidden="true">
                  <use xlink:href="#bf-discord-fill"></use>
                </svg>
              </a>
              <a :href="$t('link_twitter')" target="_blank" rel="external nofollow noopener noreferrer">
                <svg class="ficon" aria-hidden="true">
                  <use xlink:href="#bf-icon_twitter"></use>
                </svg>
              </a>
              <a :href="$t('link_facebook')" target="_blank" rel="external nofollow noopener noreferrer">
                <svg class="ficon" aria-hidden="true">
                  <use xlink:href="#bf-facebook-box-fill"></use>
                </svg>
              </a>
            </div>
            <div class="icon">
              <a :href="$t('link_instagram')" target="_blank" rel="external nofollow noopener noreferrer">
                <svg class="ficon" aria-hidden="true">
                  <use xlink:href="#bf-instagram-fill"></use>
                </svg>
              </a>
              <a :href="$t('link_linkedin')" target="_blank" rel="external nofollow noopener noreferrer">
                <svg class="ficon" aria-hidden="true">
                  <use xlink:href="#bf-linkedin-box-fill"></use>
                </svg>
              </a>
              <a :href="$t('link_youtube')" target="_blank" rel="external nofollow noopener noreferrer">
                <svg class="ficon" aria-hidden="true">
                  <use xlink:href="#bf-youtube-fill"></use>
                </svg>
              </a>
            </div>
            <a :href="`/${locale}/ContactUs`" class="iconMore">{{$t('home_v2_107')}}</a>
          </div>
        </div>
      </div>
      <div class="footer-btm">
        <div class="relate-link">
          <a :href="item.link" target="_blank" class="inline-block" v-for="item in friendshipLink" :key="item.name" :rel="item.name === 'CoinQuora' ? 'external dofollow noopener noreferrer' : 'external nofollow noopener noreferrer'">{{item.name}}</a>
        </div>
        <div class="copy-right">Copyright &copy; 2017-2023 bitforex.com All Rights Reserved.</div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { userIsOpen } from '~/model/clientFinancingModel.js';
import { setCookie } from '../../utils/cookieUtil';
export default {
  name: 'Footer',
  data () {
    return {
      userIsOpenTrue: false, // 白名单:true为是，false为否
      isBiBi: false, // 是否币币交易页面
      isSimulationPerpetual: false, // 是否永续模拟页面
      spotSymbol: '',
      perpetualSymbol: '',
      langs: [
        { code: 'en', name: 'English' },
        { code: 'kr', name: '한국어' },
        { code: 'ru', name: 'Русский' },
        // { code: 'jp', name: '日本語' },
        { code: 'vn', name: 'Tiếng Việt' },
        { code: 'pt', name: 'Português' },
        { code: 'es', name: 'Español' },
        { code: 'tr', name: 'Türk dili' },
        // { code: 'cn', name: '简体中文' },
        { code: 'hk', name: '繁體中文' }
      ],
      friendshipLink: [{
          name: 'CoinMarketCap',
          link: 'https://coinmarketcap.com/'
        },
        {
          name: 'CoinDesk',
          link: 'https://www.coindesk.com/'
        },
        {
          name: 'CoinGecko',
          link: 'https://www.coingecko.com/en'
        },
        {
          name: 'CoinWeb',
          link: 'https://coinweb.com/'
        },
        {
          name: 'CryptoRank',
          link: 'https://cryptorank.io/'
        },
        {
          name: 'Coin360',
          link: 'https://coin360.com/'
        },
        {
          name: 'Numen',
          link: 'https://www.numencyber.com/'
        },
        {
          name: 'TokenInsight',
          link: 'https://tokeninsight.com/en/cryptocurrencies/'
        },
        {
          name: 'Coin Edition',
          link: 'https://coinedition.com/'
        }, 
        {
          name: 'Investor Bites',
          link: 'https://investorbites.com/'
        },
      ]
    };
  },
  mounted () {
    this.userIsOpen();
  },
  computed: {
    ...mapState({
      isLogin: state => state.user.isLogin,
      theme: state => state.common.theme,
      locale: state => state.common.locale,
      mainLocales: state => state.common.mainLocales,
      isTradePage: state => state.common.isTradePage
    }),
    ...mapGetters({
      funConfigs: 'user/funConfigs'
    }),
    curZendeskLang () {
      switch (this.locale) {
        case 'cn':
          return 'zh-cn';
        case 'kr':
          return 'ko';
        case 'hk':
          return 'zh-tw';
        case 'ru':
          return 'ru';
        case 'jp':
          return 'ja';
        // case 'vn':
        //   return 'vi';
        default:
          return 'en-us';
      }
    },
    curLang () {
      const index = this.langs.findIndex(item => {
        return item.code === this.locale;
      });
      return this.langs[index];
    },
    footerTheme () {
      return this.isTradePage
      ? this.theme
      : this.$route.name === 'bftokeninfo' || this.$route.name === 'perpetual_trading_competition' || this.$route.path.includes('/cryptoCup') || this.$route.path.includes('/buyCoins') || this.$route.path.includes('/NFT-BlindBox') || this.$route.path.includes('/redemptionCode')
      ? 'black'
      : 'white';
    }
  },
  methods: {
    async userIsOpen () {
      const res = await userIsOpen();
      if (res.success) {
        this.userIsOpenTrue = res.data;
      }
    },
    setLang (base) {
      let lang = window.location.pathname.split('/')[1];
      if (lang) {
        let url = window.location.origin + window.location.pathname.replace(lang, base) + window.location.search;
        window.location.href = url;
      } else {
        let url = window.location.href;
        window.location.href = url + base + '/';
      }
      this.$store.commit('common/LOCALE', base);
      setCookie('lang', base, 'y1');
    },
    handleRequest () {
      // window.sensors.track('ViewWorksheet', {
      //   locate_page: window.location.href
      // }, this.$log('访问提交工单'));
    },
    handleHelp () {
      // window.sensors.track('ViewHelpCenter', {
      //   locate_page: window.location.href
      // }, this.$log('访问帮助中心'));
    }
  },
  watch: {
    '$route': {
      handler (curVal, oldVal) {
        if (curVal.path.indexOf('/spot') > -1) {
          this.isBiBi = true;
        } else {
          this.isBiBi = false;
        }
        if (curVal.path.indexOf('/simulation-perpetual') > -1) {
          this.isSimulationPerpetual = true;
        } else {
          this.isSimulationPerpetual = false;
        }
        this.spotSymbol = window.sessionStorage.getItem('spotSymbol') || '';
        this.perpetualSymbol = window.sessionStorage.getItem('perpetualSymbol') || '';
      },
      immediate: true
    },
    isLogin () {
        this.userIsOpen();
      }
  }
};
</script>

<style lang="scss" scoped>

@import "./footer.scss";

</style>
