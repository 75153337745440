export default {
  // 计算属性
  combineState (state) {
    return `${state.data1} -> ${state.data2}`;
  },
  userMainAssetsList (state) {
    return sortFun(state.userMainAssetsList);
  },
  userTradeAssetsList (state) {
    return sortFun(state.userTradeAssetsList);
  },
  userPerpetualAssetsList (state) {
    return sortFun(state.userPerpetualAssetsList);
  },
  userCappsAssetsList (state) {
    return sortFun(state.userCappsAssetsList);
  },
  userUsdtAssetsList (state) {
    return sortFun(state.userUsdtAssetsList);
  },
  /**
   * Object
   * {id: state}
   */
  funConfigs (state) {
    let configs = {};
    // let isLogin = state.isLogin;
    if (state.isLogin) {
      if (state.funConfig.length && state.userSafeInfo.userId) {
        let wlist = state.userSafeInfo.funWhiteList || []; // 白名单
        state.funConfig.forEach(item => {
          let _state; // 入口显示
          // if (item.state) {
          //   _state = true;
          // } else {
          //   if (isLogin) {
          //     if (wlist.includes(item.configId)) {
          //       _state = true;
          //     } else {
          //       _state = false;
          //     }
          //   } else {
          //     _state = false;
          //   }
          // }
          _state = !item.state || wlist.includes(item.type);
          configs[item.type] = _state;
        });
        return configs;
      } else {
        return {
          '1': null
        };
      }
    } else {
      if (state.funConfig.length) {
        state.funConfig.forEach(item => {
          let _state; // 入口显示
          _state = !item.state;
          configs[item.type] = _state;
        });
        return configs;
      } else {
        return {
          '1': null
        };
      }
    }
  }
};

// 排序规则;
// 1.按照用户资产进行排序。持有资产的币种优先展示，排在主流币种之前（优先级最高）
// 2.按照主流币种进行排序（优先级次之），热门币展示在主流币之后
// 3.能进行充提的币优先展示，排在热门币之后
// 4.有新增币种上线时，在可充提币后展示
const hotCoins = ['BF', 'BTC', 'ETH', 'XRP', 'USDT', 'BCH', 'LTC', 'EOS', 'BSV', 'XMR', 'ADA', 'XLM', 'TRX', 'XTZ', 'ATOM', 'LINK', 'NEO', 'ETC', 'MIOTA', 'DASH', 'GRIN', 'BEAM', 'DOGE', 'GRAM', 'DOT'];

const sortFun = (data) => {
  const origin = data || [];
  if (origin.length) {
    let list1 = []; // 有资产
    let list2 = []; // 热门币
    let list3 = []; // 能充提
    let list4 = []; // 最近15天新发币
    let arr1 = [];
    let arr2 = [];
    for (let i = 0; i < origin.length; i++) {
      if (origin[i].userFixAmount * 1 || origin[i].equity * 1) {
        list1.push(origin[i]);
      } else {
        arr1.push(origin[i]);
      }
    }
    // console.log('list1', list1);
    for (let j = 0; j < hotCoins.length; j++) {
      const index = arr1.findIndex(item => {
        return item.coinName === hotCoins[j];
      });
      if (index > -1) {
        list2.push(arr1[index]);
        arr1.splice(index, 1);
      }
    };
    // console.log('list2', list2);
    for (let k = 0; k < arr1.length; k++) {
      if (arr1[k].isOpenDeposit || arr1[k].isOpenWithdraw) {
        list3.push(arr1[k]);
      } else {
        arr2.push(arr1[k]);
      }
    }
    // console.log('list3', list3);
    arr1 = [];
    for (let m = 0; m < arr2.length; m++) {
      if (arr2[m].onlineTime > (new Date().getTime() - (15 * 24 * 60 * 60 * 1000))) {
        list4.push(arr2[m]);
      } else {
        arr1.push(arr2[m]);
      }
    }
    // console.log('list4', list4);
    // console.log('arr1', arr1);
    return list1.concat(list2).concat(list3).concat(list4).concat(arr1);
  } else {
    return [];
  }
};
