import Cookies from 'js-cookie';
import {Base64} from 'js-base64';
import Vue from 'vue';
export default {
  _headerData: null,
  get headerData() {
    if (this._headerData) {
      return this._headerData
    }
    return null
  },
  setHeaderData() {
    const headerData = this.getParam('headerData');
    if (headerData) {
      this._headerData = JSON.parse(Base64.decode(decodeURIComponent(headerData)))
      window.sessionStorage.setItem('bf-headerData', decodeURIComponent(headerData));
      Cookies.set('bf-currency', this._headerData.currency)
    } else {
      this._headerData = null
      Cookies.set('bf-currency', null)
      sessionStorage.removeItem('bf-headerData');
    }
  },
  getParam(mQuery) {
    const __params = window.location.href.split('?')[1]
    if(__params) {
      let param = {}
      const __paramsParse = __params.split('&')
      for(let i = 0; i < __paramsParse.length; i ++) {
        const item =  __paramsParse[i]
        param[item.split('=')[0]] = item.slice(item.indexOf('=')+1,item.length)
      }
      return param[mQuery]
    }
    return null
  },
  getQuery(name) {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    let r = window.location.search.substr(1).match(reg);
    if (r != null) {
        return decodeURIComponent(r[2]);
    };
    return null;
  }
  
}
