<template>
  <div>
<!--    <bf-dialog :visible="currentMaintenance==null" :show-close="false" wrapper-classes="z-bg">-->
      <div class="m-page">
        <div class="content" v-if="currentMaintenance">
          <div class="title">
            {{maintenanceContent.title}}
          </div>
          <div class="desc">
            {{maintenanceContent.desc}}
          </div>
          <div class="more" v-if="currentMaintenance.link" @click="go(currentMaintenance.link)">
            {{$t('more')}}
          </div>
          <div class="btn-group">
            <!--          <div class="btn-confirm" @click="back">Back</div>-->
            <router-link to="/userCenter/userMyAssets" class="btn-confirm">
              {{$t('p_m_7')}}
            </router-link>
            <a href="/" class="btn-cancel">
              {{$t('p_m_8')}}
            </a>
          </div>
        </div>
      </div>
<!--    </bf-dialog>-->
  </div>
</template>

<script>
  import {mapActions, mapState} from 'vuex';
  import {checkMaintenance, maintenanceList} from '~/utils/maintinenceUtil';
  export default {
    name: 'maintenance_dialog',
    computed: {
      ...mapState({
        locale: state => state.common.locale,
        maintenanceList: state => state.common.maintenanceList
        // currentMaintenance: state => state.common.currentMaintenance
      }),
      currentMaintenance () {
        if (!this.$route) return null;
        let currentMaintenance = checkMaintenance(this.maintenanceList, this.$route.name);
        if (!currentMaintenance) {
          return null;
        } else {
          return currentMaintenance;
        }
      },
      maintenanceContent () {
        if (!this.$route) return null;
        let currentMaintenance = checkMaintenance(this.maintenanceList, this.$route.name);
        if (!currentMaintenance) {
          return null;
        }
        let defaultLocaleMaintenance;
        // eslint-disable-next-line no-unused-vars
        for (let item of currentMaintenance.maintenanceLangList) {
          if (item.lang === 'en') {
            defaultLocaleMaintenance = item;
          }
          if (item.lang === 'pt' && this.locale === 'pt-br') {
            return item;
          }
          // eslint-disable-next-line eqeqeq
          if (item.lang == this.locale) {
            return item;
          }
        }
        return defaultLocaleMaintenance;
      }
    },
    watch: {
      '$route': {
        handler (route) {
          this.checkStart();
        },
        deep: true
      },
      start (val) {
        if (val) {
          this.initMaintenanceInterval();
        }
      }
    },
    data () {
      return {
        maintenanceIntervaler: null,
        start: false
      };
    },
    async mounted () {
      await this.getMaintenanceConfigList();
      this.checkStart();
    },
    destroyed () {
      clearTimeout(this.maintenanceIntervaler);
    },
    methods: {
      ...mapActions('common', ['getMaintenanceList']),
      async getMaintenanceConfigList () {
        await this.getMaintenanceList();
      },
      checkStart () {
        if (!this.$route) return;
        if (maintenanceList.filter((item) => { return this.$route.name === item; }).length > 0) {
          this.start = true;
        } else {
          this.start = false;
        }
      },
      initMaintenanceInterval () {
        if (this.maintenanceIntervaler) {
          clearTimeout(this.maintenanceIntervaler);
          this.maintenanceIntervaler = null;
        }
        if (!this.start) {
          return;
        }
        this.getMaintenanceConfigList();
        // this.maintenanceIntervaler = setTimeout(() => {
        //   this.initMaintenanceInterval();
        // }, 5000);
      },
      go (url) {
        window.open(url);
      }
    }
  };
</script>

<style scoped lang="scss">

  .z-bg{
    background: rgba(255,255,255,0.8);
  }
  .m-page {
    background-color: white;
    display: flex;
    justify-content: center;
    padding-top: 50px;
    .content {
      width: 468px;
      margin-top: 96px;
      margin-bottom: 328px;
      .title {
        
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 40px;
        color: rgba(0, 0, 0, 0.87);

      }

      .desc {
        
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.87);
        margin-top: 16px;
        word-break: break-all;
      }

      .more {
        
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: #14C393;
        margin-top: 16px;
        cursor: pointer;
      }

      .btn-confirm {
        margin-top: 24px;
        width: 100%;
        height: 38px;
        background: #14C393;
        border-radius: 4px;
        
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
      }

      .btn-cancel {
        height: 38px;
        background: #F2F2F7;
        border-radius: 4px;
        
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #000000;
        margin-top: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

    }
  }

</style>
