export default {
  curSymbolInfo (state) {
    const filtered = state.symbolsInfo.filter(item => {
      return item.symbol === state.curSymbol;
    });
    return filtered.length ? filtered[0] : {};
  },
  token1 (state, getter) {
    return getter.curSymbolInfo.currencyCoinCode;
  },
  token2 (state, getter) {
    return getter.curSymbolInfo.commodityCoinCode;
  },
  name1 (state, getter) {
    return getter.curSymbolInfo.currencyCoinName;
  },
  name2 (state, getter) {
    return getter.curSymbolInfo.commodityCoinName;
  },
  precision1 (state, getter) {
    return getter.curSymbolInfo.currencyPrecision || 8;
  },
  precision2 (state, getter) {
    return getter.curSymbolInfo.commodityPrecision || 8;
  },
  curPool (state, getter) { // 当前交易对的最新ticker
    const filtered = state.pools.filter(item => {
      return item.tokenCode === getter.token2;
    });
    return filtered.length ? filtered[0] : {};
  }
};
