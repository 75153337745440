<template>
  <bf-dialog :on-click-body="onClickBody"
             v-if="activeBanner!=null && show"
             @click="go" :visible="activeBanner!=null"
             :showClose='false' class="guid-wrap"
             body-classes="pointer"
             :backgroundImage="activeBanner.imgUrl" :class="{ 'homeGuid' :findLocalBanner(locale).displayPage == 1 }">
    <i class="dia-close move_down" @click.stop="close"><img src="./imgs/close_btn.svg"> </i>
    <!-- <div class="btn" v-if="findLocalBanner(locale).displayPage == 1">{{$t('8582')}}</div> -->
  </bf-dialog>
</template>

<script>
  import {mapState} from 'vuex';
  // eslint-disable-next-line no-unused-vars
  import {uniswapBannerList} from '~/model/clientSwapModel';
  // eslint-disable-next-line no-unused-vars
  import {getFilteredBannerList} from '~/utils/bannerUtil';

  const bannerMappers = {
    'home': '1',
    'spot': '2',
    'perpetual': '3',
    'eazyswap': '4',
    'mt5': '5'
  };
  export default {
    data () {
      return {
        show: false,
        timePeriod: [1610424000000, 1610424000001],
        timePeriod2: [1613613600000, 1613664000000],
        activeIndex: 1,
        time: null,
        banners: null,
        activeStatu: 0
      };
    },
    async mounted () {
      await this.getBannerList();
    },
    computed: {
      ...mapState({
        locale: state => state.common.locale,
        mainLocales: state => state.common.mainLocales
      }),
      filteredLocale () {
        const lang = ['hk', 'en', 'kr', 'ru', 'pt-br', 'jp'].includes(this.locale) ? this.locale : 'en';
        return lang;
      },
      isTargetPage () {
        return ['spot'].includes(this.$route.name);
      },
      activeBanner () {
        this.show = false;
        if (this.banners && Object.keys(this.banners).length > 0) {
          let locale = this.filteredLocale;
          if (locale === 'kr') locale = 'ko';
          if (locale === 'pt-br') locale = 'pt';
          let banner = this.findLocalBanner(locale);
          // if (!banner) {
          //   banner = this.findLocalBanner('en');
          // }
          return banner;
        }
        return null;
      }
    },
    destroyed () {
      clearInterval(this.time);
    },
    methods: {
      findLocalBanner (locale) {
        let routeName;
        if (window.location.href.includes('/mt5')) {
          routeName = 'mt5';
        } else {
          if (this.$route) { routeName = this.$route.name; }
        }
        let localeBanners = this.banners[locale];
        if (!localeBanners) {
          return null;
        }
        for (let banner of localeBanners) {
          banner.locale = locale;
          // eslint-disable-next-line eqeqeq
          if (bannerMappers[routeName] == banner.displayPage && banner.pagePosition == 2) {
            if (!window.sessionStorage.getItem('guidRecharge_' + banner.id)) {
              this.show = true;
            }
            return banner;
          }
        }
        return null;
      },
      onClickBody () {
        this.go();
      },
      async getBannerList () {
        let result = await uniswapBannerList();
        if (result.success) {
          this.banners = result.data;
        }
        // this.banners = defaultBannerList;
      },
      close () {
        window.sessionStorage.setItem('guidRecharge_' + this.activeBanner.id, 1);
        this.show = false;
      },
      go () {
        if (!/^http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- ./?%&=]*)?$/.test(this.activeBanner.jumpUrl)) {
          return;
        }
        this.close();
        window.location.href = this.activeBanner.jumpUrl;
      },
      open () {
        this.show = true;
      },
      setActiveStatu () {
      }
    }
  };
</script>

<style lang="scss" scoped>

  .guid-wrap /deep/ {
    .bf-dialog {
      width: 670px !important;
      min-height: 400px;
      text-align: center;
      overflow: inherit !important;
      background-repeat: no-repeat;
      background-position: top center;
      background-size: 100% 100%;

      .guid-con {
        padding-top: 100px;

        > .title {
          font-size: 22px;
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
          line-height: 27px;
          margin-bottom: 10px;
        }

        > .guid-btn {
          min-width: 230px;
          text-align: center;
          height: 44px;
          color: #000;
          font-size: 16px;
          line-height: 44px;
          background: rgba(240, 189, 66, 1);
          border-radius: 3px;
          display: inline-block;
          margin: 20px auto 5px;
        }

        > .desc {
          font-size: 18px;
          font-weight: normal;
          color: rgba(255, 255, 255, 1);
          line-height: 22px;
          margin-bottom: 10px;
        }

        > .tip {
          font-size: 12px;
          color: #fff;
          margin-top: 5px;

          > a {
            color: #14c393;
            vertical-align: bottom;
          }
        }
      }

      .dia-close {
        position: absolute;
        right: 16px;
        top: 16px;
      }

      .btn {
        width: 240px;
        height: 40px;
        background: #14C393;
        margin: 0 auto;
        margin-top: 456px;
        border-radius: 8px;
        color: #fff;
        line-height: 40px;
        font-weight: 400;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }
  .homeGuid /deep/ {
    .bf-dialog {
      width: 400px !important;
      height: 560px !important;
    }
  }

</style>
