<template>
  <p class="bf-tooltips">
    <slot name="reference"></slot>
    <span class="bf-tooltips-content" :style="{width: width}">
      <slot></slot>
    </span>
  </p>
</template>

<script>
export default {
  name: 'BfTooltip',
  props: {
    width: String,
    default: '160px'
  }
};
</script>

<style lang="scss" scoped>

.bf-tooltips {
  position: relative;
  cursor: help;

  .bf-tooltips-content {
    display: inline-block;
    position: absolute;
    left: 50%;
    top: calc(100% + 10px);
    transition: transform 0.2s ease-out 0.1s;
    transform: translateX(-50%) scaleY(0);
    transform-origin: 50% -10px;
    min-width: 160px;
    font-size: 12px;
    color: var(--COLOR-87);
    line-height: 20px;
    text-align: left;
    background-color: var(--COLOR-bg);
    border-radius: 4px;
    border: 1px solid var(--COLOR-10);
    box-sizing: border-box;
    word-break: break-word;
    padding: 10px;
    z-index: 20;

    a {
      font-size: 12px;
      color: var(--COLOR-link);
      line-height: 20px;

      &:hover {
        text-decoration: underline;
      }
    }

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      top: -4px;
      transform: translateX(-50%) rotateZ(45deg);
      width: 8px;
      height: 8px;
      background-color: var(--COLOR-bg);
      border-top: 1px solid var(--COLOR-10);
      border-left: 1px solid var(--COLOR-10);
      border-right: 1px solid transparent;
      border-bottom: 1px solid transparent;
      box-sizing: border-box;
    }
  }

  &:hover .bf-tooltips-content {
    transform: translateX(-50%) scaleY(1);
  }

}

</style>
