export default {
  required: {
    rule: value => value !== '',
    message: '此项必填！'
  },
  rand: {
    rule: (value, min, max) => {
      let bool = true;
      if (min !== undefined) {
        bool = value.length >= min;
      }
      if (max !== undefined) {
        bool && (bool = value.length <= max);
      }
      return bool;
    },
    message: '请输入长度在$min到$max个字符的内容'
  },
  password: {
    rule: value => {
      const reg = /^(?!\s)((?=.*[a-zA-Z])(?=.*[a-z])(?=.*[A-Z])(?=.*[\d]).\S{7,})$/;
      return reg.test(value);
    },
    message: '请输入正确的密码格式'
  },
  ip: {
    rule: value => {
      const reg = /^(2(5[0-5]{1}|[0-4]\d{1})|[0-1]?\d{1,2})(\.(2(5[0-5]{1}|[0-4]\d{1})|[0-1]?\d{1,2})){3}$/g;
      return reg.test(value.trim());
    }
  },
  tel: {
    rule: value => {
      const reg = /^[0-9*#]*$/;
      return reg.test(value);
    }
  },
  email: {
    rule: value => {
      const reg = /(\S)+[@]{1}(\S)+[.]{1}(\w)+/;
      return reg.test(value);
    }
  }
};
