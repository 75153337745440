import * as types from '../../../types';
import * as defiModel from '~/model/clientSwapModel';

import eventBus from '~/utils/eventBus';

const handleError = (err) => {
  // handle error
  if (err.code === 401) {
    // notify({
    //   content: '请登录！'
    // })
    eventBus.$emit('auth');
  }
};

export default {
  // 获取所有交易对配置信息
  getSymbolsInfo ({dispatch, commit}, params) {
    return defiModel.uniswapContractAll().then(res => {
      if (res.success) {
        commit(types.DEFI_SYMBOLS_INFO, res.data || []);
      }
    }).catch(err => {
      handleError(err);
    });
  },
  getPools ({dispatch, commit}, params) {
    return defiModel.uniswapMarketPoolPrices().then(res => {
      if (res.success) {
        res.data.forEach(item => {
          item.price2 = item.mainChainAmount ? item.tokenAmount / item.mainChainAmount : 0;
        });
        commit(types.DEFI_POOLS, res.data || []);
        // if (res.data && res.data[0]) {
        //   commit(types.DEFI_SYMBOL, `uniswap-${res.data[0].mainChainCode}-${res.data[0].tokenCode}`);
        // }
      }
    }).catch(err => {
      handleError(err);
    });
  }
};
