import { setCookie, getCookie } from '~/utils/cookieUtil';
// event_category: 一般为一个大类，比如品牌brand,视频video等等
// eventAction: 一般为一个具体的操作，比如download, play, click等等
// event_label: 一般为额外的一些信息，比如具体的品牌ID，视频的title等等
// value: 任意的度量值，必须为正的整数，比如表示品牌的价值，视频的时长等等
/**
 * event_category, page_title, event_label, value, page_title, page_path, page_location, debug_mode
 * **/
const debug_host = ['t.bitforex.com', 'test112.bitforex.com', 'test123.bitforex.com', 'ceshi.bitforex.center', 'upceshi.bitforex.center']
const __debug_mode = getCookie('debug_mode')
const debug_mode = JSON.parse(__debug_mode)

export default {
    GA_TRACKING_ID: debug_host.includes(location.hostname) ? 'G-D9KXCXN2KQ' : 'G-V25SLCREJW', //ga的id
    event(eventAction, options) {
        if (window.gtag) {
            let __param = {
                ...options,
                'event_platform': 'web',
                'send_to': this.GA_TRACKING_ID
            }
            if(!!debug_mode) {
                __param['debug_mode'] = true
            }
            window.gtag('event', eventAction, __param)
        }
    },
    set(options) {
        if (window.gtag) {
            let __param = {
                ...options,
                'event_platform': 'web',
            }
            if(!!debug_mode) {
                __param['debug_mode'] = true
            }
            window.gtag('set', __param)
        }
    },
    page(options) {
        if (window.gtag) {
            let __param = {
                ...options,
                'event_platform': 'web',
                'send_to': this.GA_TRACKING_ID
            }
            if(!!debug_mode) {
                __param['debug_mode'] = true
            }
            window.gtag('config', this.GA_TRACKING_ID, __param)
        }
    }
}


