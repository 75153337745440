<template>
  <div
    class="theme-default"
    id="app"
    :class="{ noFixed: $route.name === 'perpetual' || $route.name === 'spot' }"
  >
    <router-view
      v-show="true"
      v-if="$route.name == 'perpetual_kfull'"
    ></router-view>
    <template v-else-if="isReload">
      <div class="tipsBgContain" @click="isLower = false" v-if="isLower">
        <div class="tipsContainer">
          <p
            class="tipsTxt"
            :class="[locale === 'cn' ? 'cn' : locale === 'kr' ? 'kr' : 'en']"
          ></p>
          <ul class="browserList" @click.stop="">
            <li class="item-1">
              <a
                href="https://www.google.com/chrome"
                target="_blank"
                rel="external nofollow noopener noreferrer"
              ></a>
            </li>
            <li class="item-2">
              <a
                href="https://www.mozilla.org"
                target="_blank"
                rel="external nofollow noopener noreferrer"
              ></a>
            </li>
            <li class="item-3">
              <a
                href="https://www.apple.com/safari"
                target="_blank"
                rel="external nofollow noopener noreferrer"
              ></a>
            </li>
            <li class="item-4">
              <a
                href="https://www.opera.com"
                target="_blank"
                rel="external nofollow noopener noreferrer"
              ></a>
            </li>
          </ul>
        </div>
      </div>
      <template v-else>
        <div
          class="top-wrap"
          v-bind:class="{ sticky: matchPathSticky() }"
          v-if="!isApp"
        >
          <TopBanner />
          <RightEnter />
          <!-- <LanguageSelect/> -->
          <HeaderWeb :userIsOpenAction="userIsOpen">
            <template #Announcement>
              <Announcement width="360px" :theme="theme" />
            </template>
          </HeaderWeb>
          <!-- <cHeader/> -->
        </div>
        <!-- 赠金弹框 -->
        <CouponBanner v-if="isLogin" />
        <!--  -->
        <router-view v-show="maintenanceObj == null"></router-view>
        <!--  -->
        <maintenance_dialog
          v-show="maintenanceObj != null"
        ></maintenance_dialog>
        <!-- <template v-if="$route.name !== 'perpetual' && $route.name !== 'perpetual_app'">
          <cFooter v-if="!singlePage" class="webs"/>
          <cFooter2 v-if="!singlePage" class="iphones"></cFooter2>
        </template> -->

        <AppDownTip v-if="!isApp"></AppDownTip>
        <guidRecharge />
        <ptoBanner />
        <!-- 理财引流弹窗 -->
        <managePop />
        <!-- 主题切换弹窗 -->
        <ThemeSelect />

        <!--      <CEntry/>-->
        <!-- <BottomBanner v-if="!singlePage && !isTradePage && $route.name !== 'defizone'"/> -->
      </template>
    </template>
  </div>
</template>
<script>
import "~/assets/css/windi-reset.css";
import "virtual:windi-components.css";
import "virtual:windi-utilities.css";
import "bitforex/lib/index.css";

import Announcement from "~/components/announcement/announcement.vue";
import cFooter from "~/components/footer/footer.vue";
import cFooter2 from "~/components/footer/iphoneFooter.vue";
// import LanguageSelect from '~/components/languageSelect/languageSelect.vue';
import TopBanner from "~/components/topBanner/topBannerDynamic.vue";
// import BottomBanner from '~/components/bottomBanner/bottomBanner.vue';
import managePop from "~/components/managePop/managePop.vue";
import RightEnter from "~/components/rightEnter/rightEnter.vue";

import guidRecharge from "~/pages/activity/simulation/components/guidRecharge/guidRechargeDynamic.vue";
import ptoBanner from "~/pages/activity/simulation/components/guidRecharge/ptoBanner.vue";
import CouponBanner from "~/components/couponBanner/couponBanner.vue";
import AppDownTip from "~/pages/home/components/appDownTip/appDownTip.vue";
import RippleEffect from "~/utils/ripple";
import { getCookie, setCookie } from "~/utils/cookieUtil";
import { mapState, mapActions } from "vuex";
import eventBus from "~/utils/eventBus";
import store from "store";
import { seo_home } from "~/config/seo";
const seo_custorm = require("~/config/seo.custorm.config");
import { getMeta } from "~/model/clientAcademyModel";
import { userIsOpen } from "~/model/clientFinancingModel.js";
import uapi from "~/model/clientUsdtModel";
// eslint-disable-next-line no-unused-vars
import { checkMaintenance, maintenanceList } from "~/utils/maintinenceUtil";
import Maintenance_dialog from "~/pages/maintenance/maintenance_dialog.vue";
// import CEntry from '~/pages/activity/christmas2/c-entry.vue';

export default {
  data() {
    return {
      userIsOpen,
      meta_title: undefined,
      meta_description: undefined,
      isLower: false, // 浏览器版本低
      intervaler: "", // 轮询拿汇率
      maintenanceIntervaler: null,
      headSticky: [
        "home",
        "eazyswap",
        "userMyAssets",
        "assets",
        "assetsEarn",
        "userBfToken",
        "userAuthentication",
        "userApi",
        "security",
        "tokens",
        "userWithdraw",
        "userAuthDetail",
        "deviceList",
        "loginList",
        "fundingRate",
      ],
      headPathSticky: ["/order/"],
      disabledShow: true,
    };
  },
  components: {
    Maintenance_dialog,
    // CEntry,
    cFooter,
    cFooter2,
    TopBanner,
    // BottomBanner,
    RightEnter,
    // LanguageSelect,
    CouponBanner,
    AppDownTip,
    guidRecharge,
    ptoBanner,
    managePop,
    Announcement,
  },
  computed: {
    ...mapState({
      isApp: (state) => state.common.isApp,
      theme: (state) => state.common.theme,
      colors: (state) => state.common.colors,
      topSticky: (state) => state.common.topSticky,
      locale: (state) => state.common.locale,
      locales: (state) => state.common.locales,
      isTradePage: (state) => state.common.isTradePage,
      isLogin: (state) => state.user.isLogin,
      inviterIdType: (state) => state.common.inviterIdType,
      userSafeInfo: (state) => state.user.userSafeInfo,
      maintenanceList: (state) => state.common.maintenanceList,
      currency: (state) => state.common.currency,
      isReload: (state) => state.common.isReload,
      maintenanceObj() {
        // if(window.location.href.indexOf('https://www.bitforex.com/') !== -1){
        return checkMaintenance(this.maintenanceList, this.$route.name);
        // } else {
        //   return null
        // }
      },
    }),
    singlePage() {
      const routes = ["login", "register", "reset"];
      if (this.$route) {
        if (routes.includes(this.$route.name)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  watch: {
    theme: {
      handler(val) {
        this.setThemeMode(val);
      },
    },
    colors: {
      immediate: true,
      handler(val) {
        document.body.setAttribute("theme-colors", val);
      },
    },
    isLogin: {
      handler(val) {
        this.cmiShowFlag();
        this.getUserSafeInfo().then((res) => {
          const _lang = this.getLang();
          if (res.success && res.data.lang !== _lang) {
            this.updateLang({ lang: _lang });
            this.$store.commit("common/LOCALE", _lang);
            setCookie("lang", _lang, "y1");
          }
        });
      },
    },
    // 监听路由变化 修改网站title名称
    $route(to, from) {
      if (to.path.includes("buyCoins")) {
        document.title = seo_custorm["buyCoins"][this.locale].title;
      } else if (to.path.includes("loan")) {
        document.title = seo_custorm["loan"][this.locale].title;
      } else if (!(to.name.includes("spot") || to.name.includes("perpetual"))) {
        document.title = seo_home[this.locale].title || "";
      }

      const theme = store.get("theme") || "black";
      !this.isApp && this.setThemeMode(theme);
    },
  },
  // metaInfo () {
  //   console.log('name------',  this.$route.name )
  //  // this.$route.name == 'buyCoins'
  //   return {
  //     title: '',
  //     meta: [
  //       {vmid: 'description', name: 'description', content: ''},
  //       { vmid: 'keywords', name: 'keywords', content: '' }
  //     ],
  //     __dangerouslyDisableSanitizers: ['meta', 'title','keywords']
  //   };
  // },
  created() {
    if (document.documentElement.clientWidth < 730) {
      this.disabledShow = false;
    }
    this.getSymbolsInfo();
    this.getRatesInit();
    this.intervaler = setInterval(() => {
      this.getRatesInit();
    }, 3 * 60 * 1000);
    this.getAllFunConifigInfo();
    // this.getPerpetualAct();
    this.isLogin && this.getVipLevel();
  },
  mounted() {
    this.getCurrencies().then(() => {
      const { headerData, isoId = "" } = this.$route.query;
      if (headerData) {
        this.setAppIsoId(isoId);
      }
    });
    this.setAppParams(); // app 参数处理

    // this.getWhiteUserFlag();
    // 返佣数据分析入口是否展示
    this.cmiShowFlag();
    if (this.$route.query.inviterId) {
      setCookie("inviterId", this.$route.query.inviterId, "1d");
    }

    // 判断是否交易页，设置主题色
    if ("grid" in document.body.style) {
      this.isLower = false;
      // 设置主题
      const theme = store.get("theme") || "black";
      !this.isApp && this.$store.commit("common/THEME", theme);
      // 设置配色
      const colors = store.get("colors") || "cva";
      this.$store.commit("common/COLORS", colors);
      if (this.isLogin) {
        this.getUserSafeInfo().then((res) => {
          const _lang = this.getLang();
          if (res.success && res.data.lang !== _lang) {
            // 更新语言
            this.updateLang({ lang: _lang });
            this.$store.commit("common/LOCALE", _lang);
            setCookie("lang", _lang, "y1");
          }
        });
        this.getUserBaseInfo();
      }
      this.setValuationCurrency();
      RippleEffect();
    } else {
      this.isLower = true;
      !this.isApp && this.$store.commit("common/THEME", "white");
      this.$store.commit("common/COLORS", "cva");
    }

    eventBus.$on("needBindGoogle", () => {
      this.$confirm(this.$t("form_89"), this.$t("tips"), () => {
        this.$router.push({
          path: "/userCenter/userSafeCenter",
          query: {
            needBindGoogle: true,
          },
        });
      });
    });
    this.initMaintenanceInterval();
  },
  methods: {
    ...mapActions("user", [
      "getUserSafeInfo",
      "getUserBaseInfo",
      "updateLang",
      "getAllFunConifigInfo",
      "getVipLevel",
    ]),
    ...mapActions("common", [
      "getRatesInit",
      "getMaintenanceList",
      "getCurrencies",
    ]),
    ...mapActions("trade", ["getSymbolsInfo"]),
    ...mapActions("perpetual", ["getPerpetualAct"]),
    getWhiteUserFlag() {
      uapi.getWhiteUserFlag().then((res) => {
        this.$store.commit("common/USDT_PERPETUAL_SHOW", res);
      });
    },
    cmiShowFlag() {
      uapi.cmiShowFlag().then((res) => {
        this.$store.commit("common/REBATE_SHOW", res);
      });
    },
    setAppParams() {
      const {
        headerData,
        appTheme,
        fromClient,
        currency = "",
        isoId = "",
      } = this.$route.query;
      // 从app访问m站
      if (headerData) {
        if (!!appTheme) {
          const __theme = appTheme === "dark" ? "black" : "white";
          this.setThemeMode(__theme);
        }
        !!fromClient && this.$store.commit("common/FROM_CLIENT", fromClient);
        this.$store.commit("common/ISAPP", true);
        this.loadingAction();
      }
    },
    loadingAction() {
      const { fromClient } = this.$route.query;
      const param = JSON.stringify({
        loading: false,
      });
      if (fromClient == "ios") {
        if (window.webkit && window.webkit.messageHandlers.loadingAction) {
          window.webkit.messageHandlers.loadingAction.postMessage(param);
        } else {
          this.$alert(this.$t("TS1001"));
        }
      } else {
        if (window.loadingAction) {
          window.loadingAction.postMessage(param);
        } else {
          this.$alert(this.$t("TS1001"));
        }
      }
    },
    setAppIsoId(isoId = 1) {
      const __currency = this.currency.filter((item) => item.id == isoId);
      if (__currency.length > 0) {
        this.$store.commit("common/CURRENT_CURRENCY", __currency[0]);
      }
    },
    matchPathSticky() {
      const __path = this.$route.path;
      const isMatchPathSticky =
        this.headPathSticky.filter((item) => !!__path.match(item)).length >= 0;
      const isMatchNameSticky = this.headSticky.includes(this.$route.name);
      return isMatchPathSticky || isMatchNameSticky;
    },
    initMaintenanceInterval() {
      this.getMaintenanceConfigList();
      if (this.maintenanceIntervaler) {
        clearInterval(this.maintenanceIntervaler);
        this.maintenanceIntervaler = null;
      }
      this.maintenanceIntervaler = setInterval(() => {
        // eslint-disable-next-line eqeqeq
        // if (this.$route.path.indexOf('/maintenance') != -1) {
        //   this.getMaintenanceConfigList();
        // }
      }, 5000);
    },
    async getMaintenanceConfigList() {
      this.getMaintenanceList();
    },
    setThemeMode(theme) {
      if (
        this.$route.name === "eazyswap" ||
        this.$route.path.indexOf("/perpetual/") === 0 ||
        this.$route.path.indexOf("/perpetual_app/") === 0 ||
        this.$route.path.indexOf("/perpetual_kfull/") === 0 ||
        this.$route.path.indexOf("/fundingRate") === 0 ||
        // this.$route.path.indexOf('/order/') === 0 ||
        this.$route.path.indexOf("/simulation-perpetual/") === 0 ||
        this.$route.path.indexOf("/spot/") === 0 ||
        this.$route.path === "/login" ||
        this.$route.path.indexOf("/register") === 0 ||
        this.$route.path.indexOf("/reset") === 0 ||
        this.$route.path.indexOf("/knightpage") === 0 ||
        this.$route.path.indexOf("/applyKninght") === 0
      ) {
        document.body.setAttribute("theme-mode", theme);
        this.$store.commit("common/THEME", theme);
      } else {
        document.body.setAttribute("theme-mode", "white");
        this.$store.commit("common/THEME", "white");
        // document.body.removeAttribute('theme-mode', 'white')
      }
    },
    getLang() {
      let lang = window.location.pathname.split("/")[1];
      if (!this.locales.includes(lang)) {
        lang = getCookie("lang") || "en";
      }
      return lang;
    },
    // 设置计价单位和符号
    setValuationCurrency() {
      let curCurrency;
      let curSymbol;
      if (this.locale === "cn") {
        curCurrency = "CNY";
        curSymbol = "¥";
      } else if (this.locale === "kr") {
        curCurrency = "KRW";
        curSymbol = "₩";
      } else {
        curCurrency = "USD";
        curSymbol = "$";
      }
      setCookie("valuationCurrency", curCurrency, "d30");
      setCookie("valuationSymbol", curSymbol, "d30");
      this.$store.commit("common/VALUATION_CURRENCY", curCurrency);
      this.$store.commit("common/VALUATION_SYMBOL", curSymbol);
    },
    setIntercom(data) {
      let lang;
      switch (this.locale) {
        case "cn":
          lang = "zh-CN";
          break;
        case "kr":
          lang = "ko";
          break;
        case "jp":
          lang = "ja";
          break;
        case "ru":
          lang = "ru";
          break;
        default:
          lang = "en";
          break;
      }
      if (data) {
        window.Intercom &&
          window.Intercom("update", {
            app_id: "cxlw5xnv",
            email: data.email,
            phone: data.phone,
            name: data.email || data.phone,
            user_id: data.userId,
            language_override: lang,
            custom_launcher_selector: "#intercom_link",
            created_at: Date.now(),
          });
      } else {
        window.Intercom &&
          window.Intercom("update", {
            app_id: "cxlw5xnv",
            custom_launcher_selector: "#intercom_link",
            language_override: lang,
          });
      }
    },
  },
};
</script>
<style lang="scss">

@import "./app.scss";

</style>
