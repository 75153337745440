
module.exports = {
  buyCoins: {
    en: {
      title: 'Buy Cryptocurrency Online With Credit Cards, Debit and Bank Transfer | Bitforex',
      description: 'Buy your favourite cryptocurrency online like btc, eth within minutes with your credit card, bank card and bank transfer. Instant transaction. Get started now!',
      keywords: 'buy cryptocurrency online,buy cryptocurrency with debit card,buy cryptocurrency with credit card'
    },
    hk: {
      title: '使用信用卡、借記卡和銀行轉賬在線購買加密貨幣|幣夫',
      description: '使用信用卡、銀行卡和銀行轉賬在幾分鐘內在線購買您最喜歡的加密貨幣，例如 btc、eth。 即時交易。 現在就開始！',
      keywords: '在線購買加密貨幣,使用借記卡購買加密貨幣,使用信用卡購買加密貨幣'
    },
    kr: {
      title: '신용카드, 체크카드 및 계좌 이체로 온라인으로 암호화폐를 구매하세요 | 비트포렉스',
      description: '신용 카드나 체크 카드, 혹은 계좌 이체로 몇 분 내에 비트코인이나 이더리움 같은 암호화폐를 온라인으로 구입할 수 있습니다. 결제 즉시 입금됩니다. 지금 시작하세요!',
      keywords: '온라인에서 코인 구매,체크카드로 코인 구매,신용카드로 코인 구매'
    },
    ru: {
      title: 'Покупайте криптовалюту онлайн с помощью кредитных карт, дебетовых и банковских переводов | Битфорекс',
      description: 'Мгновенная покупка Вашей любимой криптовалюты онлайн, например, btc, eth, по кредитной и банковской картам, через банковский перевод. Мгновенное зачисление. купить сейчас!',
      keywords: 'купить криптовалюту онлайн,купить криптовалюту по дебетовой карте,купить криптовалюту по кредитной карте'
    },
    jp: {
      title: 'クレジット カード、デビット、銀行振込で仮想通貨をオンラインで購入 |BitForex',
      description: 'クレジット カード、銀行カード、銀行振込を使用して、数分以内に btc、eth などのお気に入りの暗号通貨をオンラインで購入できます。 インスタント取引。 今すぐ始めましょう！',
      keywords: '暗号通貨をオンラインで購入する,デビットカードで暗号通貨を購入する,クレジットカードで暗号通貨を購入する'
    },
    'pt-br': {
      title: 'Compre Cripto Online com Cartões de Crédito, Débito e Transferência Bancária | BitForex',
      description: 'Compre sua cripto favorita online como BTC, ETH em minutos com seu cartão de crédito, cartão bancário e transferência bancária. Transação instantânea. Comece agora!',
      keywords: 'compre criptomoedas online,compre criptomoedas com cartão de débito,compre criptomoedas com cartão de crédito'
    }
  },
  loan: {
    en: {
      title: 'Cryptocurrency loans - Instant Crypto loan - Bitcoin Ethereum loan online | Bitforex',
      description: 'Get instant crypto loans on your Bitcoin and Ethereum!  7, 14, 30 day loan tenure at competitive rates. Repay anytime. Apply today!',
      keywords: 'cryptocurrency loan,instant crypto loan,crypto loan,bitcoin loan,bitcoin loan online'
    },
    hk: {
      title: '加密貨幣貸款 - 即時加密貸款 - 比特幣以太坊在線貸款 |幣夫',
      description: '在您的比特幣和以太坊上獲得即時加密貨幣貸款！ 7、14、30 天的貸款期限，具有競爭力的利率。 隨時還款。 今天申請！',
      keywords: '加密貨幣貸款,即時加密貸款,加密貸款,比特幣貸款,在線比特幣貸款'
    },
    kr: {
      title: '암호화폐 대출 - 즉시 대출 - 비트코인 이더리움 온라인 대출 온라인 | 비트포렉스',
      description: '온라인으로 바로 비트코인과 이더리움 대출을 받으세요! 적절한 수준의 금리로 7, 14, 30일 동안 대출을 받을 수 있습니다. 언제든지 갚으세요. 지금 바로 신청하세요!',
      keywords: '암호화폐 대출,즉시 암호화폐 대출,코인 대출,비트코인 대출,온라인 비트코인 대출'
    },
    ru: {
      title: 'Криптовалютные кредиты - Мгновенный крипто-кредит -  Биткоин и Эфириум онлайн-кредиты | Битфорекс',
      description: 'Получите мгновенные крипто-кредиты на свой Биткоин и Эфириум! Кредит на 7, 14, 30 дней по конкурентоспособным ставкам. С любым сроком погашения. Подайте заявку сегодня!',
      keywords: 'криптовалютный кредит,мгновенный крипто-кредит,crypto loan,крипто-кредит,онлайн кредит в биткоинах'
    },
    jp: {
      title: '暗号通貨ローン - インスタント暗号ローン - ビットコイン イーサリアム ローン オンライン |Bitforex',
      description: 'ビットコインとイーサリアムで即時の暗号ローンを取得しましょう! 競争力のある料金で 7、14、30 日間のローン期間。 いつでも返済。 今すぐお申し込みください。',
      keywords: '暗号通貨ローン,インスタント暗号ローン,クリプトローン,ビットコインローン,ビットコインローンオンライン'
    },
    'pt-br': {
      title: 'Empréstimos Cripto - Empréstimo Instantâneos de Cripto - Empréstimo de BitCoin & Ethereum Online | BitForex',
      description: 'Obtenha Empréstimos Cripto Instantâneos em Bitcoin e Ethereum! Prazo de empréstimo de 7, 14, 30 dias a taxas competitivas. Reembolse a qualquer momento. Faça hoje!',
      keywords: 'empréstimos cripto,empréstimo instantâneo cripto,empréstimo cripto,empréstimo de bitcoin,empréstimo de bitcoin online'
    }
  }
  
};