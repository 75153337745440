<template>
  <div class="bf-table">
    <div class="bf-table-box">
      <table cellpadding="0">
        <tbody>
          <tr>
            <th v-for="(head, index) in config" :key="index" :style="setStyle(head)">{{head.prop}}</th>
          </tr>
          <slot></slot>
          <!-- <tr v-for="(item, index) in list" :key="index">
            <td></td>
            <td></td>
            ……
          </tr> -->
        </tbody>
      </table>
    </div>
    <div class="bf-table-pagination" v-if="$slots.pagination" :style="{textAlign: paginationAlign}">
      <slot name="pagination"></slot>
    </div>
    <Loading class="bf-table-loading" v-if="loading"></Loading>
    <div class="bf-table-nodata" v-if="!data.length && !loading">{{$t('noData')}}</div>
  </div>
</template>
<script>
export default {
  name: 'BfTable',
  data () {
    return {
      // config: [
      //   {prop: 'Coin', width: '100px', textAlign: ''},
      //   {prop: 'Amount', width: '', textAlign: ''},
      //   {prop: 'From', width: '', textAlign: ''},
      //   {prop: 'To', width: '', textAlign: ''},
      //   {prop: 'Time', width: '', textAlign: ''},
      //   {prop: 'Action', width: '', textAlign: 'center'}
      // ],
      // loading: false
    };
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    // 配置
    config: {
      type: Array,
      required: true
    },
    // 加载icon
    loading: {
      type: Boolean,
      default: false
    },
    // 分页对齐方式
    paginationAlign: {
      type: String,
      default: 'right'
    }
  },
  methods: {
    setStyle (head) {
      let _head = JSON.parse(JSON.stringify(head));
      _head.prop = null;
      _head.class = null;
      return JSON.parse(JSON.stringify(_head));
    }
  }
};
</script>
<style lang="scss" scoped>

@import "./bf-table.scss";

</style>
