export default {tr:{
  "BACK_UF019": "Kullanıcı hesabı dondurulmuş!",
  "CT102": "İlgili iş kapalı",
  "CT103": "Doldurulan fiyat, izin verilen fiyat aralığının dışında",
  "CT104": "Kullanıcının mevcut varlıkları yetersiz",
  "CT105": "Sipariş mevcut değil",
  "CT106": "Sipariş miktarı limiti aşıyor.",
  "CT107": "Kullanıcı minimum sipariş miktarından daha az sipariş verir",
  "CT108": "Kullanıcı donmuş",
  "CT110": "Maksimum satın alma limiti aşıldı",
  "CT801": "Kullanıcı telefona bağlı değil",
  "CT804": "Kullanıcı telefonu bağladı",
  "CT805": "Telefon numarası zaten kullanılıyor",
  "CT806": "Geçici bilgi bağlama telefonu mevcut değil",
  "CT807": "SMS doğrulama kodu kontrolü kısıtlama sayısını aştı",
  "CT809": "Mobil doğrulama kodu hatası",
  "CT810": "Güvenlik sertifikası ayarlanmadı",
  "CT811": "Sınırsız Google özel anahtarı",
  "CT812": "Google doğrulama kodu hatası",
  "CT822": "Kullanıcı zaten Google özel anahtarını bağladı",
  "CT823": "mevcut değil",
  "CT824": "Değiştirilemez",
  "CT825": "Değişiklik yok",
  "CT826": "Kullanıcı güvenlik kimlik doğrulaması ayarladı",
  "CT902": "mevcut değil",
  "FC101": "sipariş defteri durdu",
  "FC102": "hesap kilitlendi",
  "FC103": "fiyat limit aşımı",
  "FC104": "miktar limit aşımı",
  "FC105": "subFund yetersiz",
  "FC106": "userStore yetersiz",
  "FC108": "sipariş iptali başarısız oldu",
  "FC109": "miktar çok az",
  "FC999": "Forbit transferi",
  "PB100000": "Yanlış Varlık",
  "PB100001": "Varlık Yeterli Değil",
  "PB100002": "Ön sipariş için en az 100",
  "PB100003": "Yanlış ön sipariş fiyatı",
  "PB100004": "Yanlış simge",
  "PB100005": "Fiyat değişti, lütfen tekrar sipariş edin",
  "PB100006": "Yanlış ön sipariş parası",
  "PB110000": "Satın alma zamanı değil",
  "RT101": "Parametrenin formatı yanlış",
  "RT102": "Kayıt Mevcut Değil",
  "RT103": "Sistem yapılandırma hatası",
  "RT104": "Sistem ortamı anormal",
  "RT301": "Sunucu Hatası",
  "RT302": "Sunucu Hatası",
  "RT303": "Sunucu Hatası",
  "RT304": "Sunucu Hatası",
  "RT398": "Sunucu Hatası",
  "RT399": "Sunucu Hatası",
  "RT999": "Tanımlanmamış istisna",
  "SP801": "Form değiştirilemiyor",
  "SP802": "Lütfen önce applyForm ekleyin",
  "SP803": "Config Bilgisi düzenlenemiyor",
  "SP804": "Lütfen depozito ücretini ödeyin",
  "SP805": "Fon yeterli değil",
  "SP806": "Ücret ödendi",
  "SP807": "Yapılandırma Bilgisi mevcut değil",
  "UF001": "Veri girişi boş olamaz",
  "UF002": "Para Çekme Adresi 5'i aşamaz.",
  "UF003": "adres çıkışları var",
  "UF015": "Kullanıcı donmuş!",
  "UF016": "pickupAdress mevcut değil",
  "UF017": "pickupAdress kullanıcıya ait değil!",
  "UF020": "Günlük limiti aştı！",
  "UF021": "Yetersiz fon faaliyetleri！",
  "UF022": "Onaylanmayan bir durum olarak bir yeniden yükleme kaydı var!",
  "UF030": "adres eklenemedi, lütfen ağ bağlantısını kontrol edip yeniden deneyin.",
  "UF036": "Miktar minimum limitten küçük",
  "UF037": "Para çekme miktarı minimum limitin altında",
  "UF101": "TXID format hatası, lütfen tekrar girin",
  "UF102": "Bir saat içinde zaten 5 kez istediniz, lütfen daha sonra tekrar deneyin",
  "UF108": "Lütfen gönderdiğiniz adrese para yatırın",
  "UF301": "Son fiyat değişti. Lütfen tekrar ödünç alın.",
  "UF302": "Yetersiz Fon",
  "UF303": "Tutara izin verilmiyor",
  "UF304": "Kullanılabilir borçlanma miktarı limite ulaştı",
  "UF305": "İade şu an geçerli değil",
  "UF307": "Kullanıcının borçlanma sınırına ulaşıldı",
  "UF308": "Etkinlik katılımcıları sınırlama sayısına ulaştı",
  "UF312": "Zorla tasfiye nedeniyle borçlanma ve iade mümkün değildir",
  "UF313": "İade miktarı, gerekli iade miktarından daha büyük",
  "US601": "E-posta adresi biçim hatası",
  "US604": "Dosya boyutu “n” sınırını aşıyor!",
  "US605": "Dosya formatı yanlış",
  "US607": "Kimlik kartı fotoğrafı boş",
  "US608": "Pasaport fotoğrafı boş",
  "US609": "Lütfen önce kimlik doğrulama işlemini (KYC) tamamlayın",
  "US651": "APIInfo kullanıcıya ait değil",
  "US660": "Resim doğrulama kodu hatası",
  "US701": "E-posta kaydedildi!",
  "US702": "Doğrulama kodunun süresi doldu",
  "US703": "E-posta captcha yanlış!",
  "US704": "Kullanıcı mevcut değil",
  "US705": "Hesabınız kilitlendi. Lütfen çevrimiçi servisle irtibata geçin.",
  "US706": "Hesabınız kilitlendi, lütfen 1 saat sonra tekrar deneyin.",
  "US707": "5 kez hata doğrulama kodunu girdiniz, yeni doğrulama kodu alın.",
  "US708": "E-posta kayıtlı değil.",
  "US709": "Geçersiz e-posta veya şifre.",
  "US710": "Telefon kaydedildi!",
  "US711": "smsCaptcha mevcut değil!",
  "US712": "geçersiz kullanıcı adı veya şifre",
  "US724": "Frekans aşırı yüklenmiş, lütfen daha sonra tekrar deneyin",
  "US725": "Yeni yer doğrulaması başarısız",
  "US726": "Frekans aşırı yüklenmiş, lütfen daha sonra tekrar deneyin",
  "US727": "Frekans aşırı yüklenmiş, lütfen daha sonra tekrar deneyin",
  "US732": "Şifreyi altı kez yanlış girdiniz, lütfen beş dakika sonra tekrar deneyin",
  "US750": "Kullanıcının bağlama e-postası vardı!",
  "US801": "Kullanıcı telefona bağlı değil",
  "US802": "Bir sonraki SMS'i göndermek için 30 saniye var!",
  "US803": "SMS Kodu hatası maksimum süreyi aşıyor. Lütfen 1 saat sonra tekrar deneyin",
  "US804": "Kullanıcı telefonu bağladı!",
  "US805": "Telefon numarası kullanıldı.",
  "US806": "BindPhoneTempInfo mevcut değil!",
  "US807": "5 kez hata doğrulama kodunu girdiniz, yeni doğrulama kodu alın.",
  "US809": "Lütfen doğru SMS kodunu giriniz.",
  "US810": "Ayarlanmış güvenlikKimlik Doğrulama yok.",
  "US811": "GooglePrivateKey bağlama yok!",
  "US812": "Lütfen doğru Google 2FA simgesini girin.",
  "US813": "ModifyPhoneOldPhoneTempInfo mevcut değil!",
  "US814": "ModifyPhoneNewPhoneTempInfo mevcut değil!",
  "US815": "SMS Kodu hatası maksimum süreyi aştı. Lütfen 1 saat sonra deneyin",
  "US816": "Lütfen doğru SMS kodunu girin.",
  "US817": "Lütfen eski SMS kodunu girin.",
  "US818": "SMS Kodu hatası maksimum süreyi aşıyor. Lütfen 1 saat sonra deneyin",
  "US819": "Lütfen doğru SMS kodunu girin.",
  "US820": "Lütfen doğru yeni SMS Kodunu girin.",
  "US821": "UnBindPhoneTempInfo mevcut değil!",
  "US822": "Kullanıcı googlePrivateKey'i bağladı!",
  "US823": "[SecurityAuthenticationName] mevcut değil!",
  "US824": "[SecurityAuthenticationName] değiştirilemez!",
  "US825": "[SecurityAuthenticationName] değişmedi!",
  "US826": "Kullanıcı securityAuthentication ayarladı!",
  "US829": "tanımlayıcı geçersiz!",
  "US830": "apikey sayısı sınırı aşıyor",
  "US832": "Doğrulama kodu mevcut değil veya süresi dolmuş",
  "US833": "Geçersiz doğrulama kodu",
  "US834": "Kullanıcı e-postayı bağlamadı",
  "US857": "E-posta kodu giriş hatası 5 kereden fazla, lütfen kodu tekrar gönderin",
  "US901": "[notificationName] mevcut değil!",
  "US902": "Bildirim durumu değişmedi!",
  "UW205": "Özür dilerim, varlığınız dondu. Herhangi bir sorunuz varsa, lütfen çevrimiçi hizmetle iletişime geçin.",
  "UW208": "E-posta onayı başarısız oldu, lütfen ağı kontrol edin ve tekrar deneyin",
  "UW209": "E-posta gönderme başarısız, tekrar göndermek için lütfen tıklayın",
  "UW210": "E-posta onayının süresi doldu, lütfen isteğinizi tekrar gönderin",
  "UW213": "1 saat içinde e-postayla gönderilen en son bağlantıyı tıkladığınızdan lütfen emin olun ve e-posta 1 saatlik zamanı aşıyorsa isteğinizi yeniden gönderin.",
  "UW236": "Bağlanmayan güvenlik, lütfen önce güvenliği kontrol edin！",
  "WE101": "Kullanıcı Giriş Yapmıyor",
  "WE102": "Kullanıcıya izni yok",
  "WE103": "Kullanıcı erişim sıklığı çok yüksek",
  "WE104": "Kullanıcı erişim sıklığı çok yüksek",
  "WE204": "Kullanıcı donmuş",
  "WE205": "Kullanıcı donmuş",
  "WE206": "Günlük limit aşıldı",
  "WE207": "Faaliyetlerde yeterli fon yok",
  "WE209": "E-posta gönderme başarısız",
  "WE218": "Kullanıcı kimlik doğrulamasını tamamlamadı",
  "WE219": "Henüz gerçek bir adınız yok"
}}