import api from '~/model/clientUsdtModel.js';
const dateFomat = (key, data, method, index) => {
    return {
        key,
        data,
        method,
        index
    };
};
// U合约接口
export default {
  // 获取当前合约币对杠杆信息
  async usdtGetLeverageInfo ({
    state,
    commit
  }, params) {
    let res = await api.leverageFind(params);
    commit('commonSet', dateFomat('leverageInfo', res));
    return res;
  },
  // 修改杠杆倍数
  async usdtLeverageUpdate ({
    state,
    commit
  }, params) {
    let res = await api.leverageUpdate(params);
    // commit('commonSet', dateFomat('leverageInfo', res));
   return res;
  },
  // 获取合约公共信息
  async usdtContractBaseInfo ({
    state,
    commit
  }, params) {
    let res = await api.usdtContractBaseInfo(params);
    commit('commonSet', dateFomat('contractBaseInfo', res[0]));
  },
  // 获取永续合约全部币对配置信息列表
  async usdtContractAllInfo ({
    state,
    commit
  }) {
    return api.usdtContractAllInfo().then(res => {
      commit('commonSet', dateFomat('contractList_USDT', res));
      return res;
    });
  },
   // 获取永续合约全部币对配置信息列表
   async usdtUpdataCurrentContract ({
    state,
    commit
  }, params) {
    // return api.usdtContractAllInfo().then(res => {
    //   commit('commonSet', dateFomat('contractList_USDT', res));
    //   return res;
    // });
  },
  // 获取U本位账户咨询
  async usdtUserContractInfo ({
    state,
    commit
  }, params) {
    return api.usdtUserContractInfo(params).then(res => {
      commit('commonSet', dateFomat('accountInfo_USDT', res));
      return res;
    });
  },
  // 获取合约详细信息
  async usdtContractDetailInfo ({
    state,
    commit
  }, params) {
    let res = await api.usdtContractAllInfo(params);
    commit('commonSet', dateFomat('contractInfo', res[0]));
    return res;
  },
  // 获取永续合约全部币对ticker
  async usdtContractAllTickers ({
    state,
    commit
  }) {
    return api.usdtContractAllTickers().then(res => {
      commit('commonSet', dateFomat('contract_all_tickers', res));
      commit('commonSet', dateFomat('contract_all_tickers_USDT', res));
      if (state.activeContract === 'USDT-M') {
        commit('commonSet', dateFomat('contract_tickers', res[state.symbol] || {}));
      }
      return res;
    });
  },
  async usdtUpdataCurrentContract ({
    state,
    commit
  }, params) {
    const { symbol } = params
    const __contract_all_tickers_USDT = {...state.contract_all_tickers_USDT}
    if(__contract_all_tickers_USDT && state.contract_tickers && state.contract_deals[0]) {
      const __contract_tickers = {...state.contract_tickers}
      __contract_tickers.last = state.contract_deals[0].price
      __contract_tickers.float =  state.contract_deals[0].float
      __contract_all_tickers_USDT[symbol] = __contract_tickers
      commit('commonSet', dateFomat('contract_all_tickers_USDT', __contract_all_tickers_USDT));
    }
  },
  // 委托单下单
  async usdtAddContractOrder ({
    state,
    commit
  }, params) {
    return api.usdtContractOrder(params);
    // commit('commonSet', dateFomat('incompleteOrders', res, 'add'));
  },
  // 条件单下单
  async usdtAddConditionOrder ({
    state,
    commit
  }, params) {
    return api.usdtConditionOrder(params);
  },
  // 获取仓位信息
  async usdtGetPosition ({
    state,
    commit
  }) {
    commit('commonSet', dateFomat('positionInfo_USDT', {}));
    commit('commonSet', dateFomat('positionInfoList_USDT', []));
    let res = await api.usdtPosition({
      symbol: state.symbol
    }, false);
    for (let i = 0; i < res.length; i++) {
      if (res[i].symbol === state.symbol) {
     
        commit('commonSet', dateFomat('positionInfo_USDT', res[i] || {}));
      }
    }
    commit('commonSet', dateFomat('positionInfoList_USDT', res || []));
    return res;
  },
  // 获取平仓盈亏列表
  async usdtGetPlaceList ({
    state,
    commit
  }, params) {
    commit('commonSet', dateFomat('placeList_USDT', []));
    return api.usdtGetPnl(params).then(res => {
      commit('commonSet', dateFomat('placeList_USDT', res.pageData));
      return res;
    });
  },
  // 获取当前委托列表
  async usdtGetIncompleteOrders ({
    state,
    commit
  }, params) {
    commit('commonSet', dateFomat('incompleteOrders_USDT', []));
    return api.usdtIncompleteOrders(params).then(res => {
      commit('commonSet', dateFomat('incompleteOrders_USDT', res.pageData));
      // commit('commonSet', dateFomat('accountInfo', {
      //   buyCount: res.buyCount,
      //   sellCount: res.sellCount
      // }, 'update'));
      return res;
    });
  },
  // 撤单
  async usdtCancelOrder ({
    state,
    commit
  }, params) {
    const {
      index
    } = params;
    delete params['index'];
    return api.usdtCancleContractOrder(params).then(res => {
      commit('commonSet', dateFomat('incompleteOrders_USDT', res, 'del', index));
    });
  },
  // 全部撤单
  async usdtCancelAllOrder ({
    state,
    commit
  }, params) {
    return api.usdtCancelOrderAll(params).then(res => {
      commit('commonSet', dateFomat('incompleteOrders_USDT', []));
    });
  },
  // 修改当前订单
  async usdtUpdateOrder ({
    state,
    commit
  }, params) {
    return api.usdtUpdateOrder(params);
  },
  // 获取条件委托列表
  async usdtGetConditionContractOrders ({
    state,
    commit
  }, params) {
    commit('commonSet', dateFomat('conditionContractOrders_USDT', []));
    return api.usdtConditionOrders(params).then(res => {
      commit('commonSet', dateFomat('conditionContractOrders_USDT', res.pageData));
      return res;
    });
  },
  // 条件单撤单
  async usdtCancelConditionOrder ({
    state,
    commit
  }, params) {
    const {
      index
    } = params;
    delete params['index'];
    return api.usdtCancleConditionContractOrder(params).then(res => {
      commit('commonSet', dateFomat('conditionContractOrders_USDT', res, 'del', index));
    });
  },
  // 条件单全部撤单
  async usdtCancelAllConditionOrder ({
    state,
    commit
  }, params) {
    return api.usdtCancelAllConditionContractOrder(params).then(res => {
      commit('commonSet', dateFomat('conditionContractOrders_USDT', []));
    });
  },
  // 获取已成交委托列表
  async usdtGetCompleteOrders ({
    state,
    commit
  }, params) {
    commit('commonSet', dateFomat('completeOrders_USDT', []));
    return api.usdtCompleteOrders(params).then(res => {
      commit('commonSet', dateFomat('completeOrders_USDT', res.pageData));
      return res;
    });
  },
  // 获取历史委托列表
  async usdtGetHistoryContractOrders ({
    state,
    commit
  }, params) {
    commit('commonSet', dateFomat('historyContractOrders_USDT', []));
    return api.usdtHistoryContractOrders(params).then(res => {
      commit('commonSet', dateFomat('historyContractOrders_USDT', res.pageData));
      return res;
    });
  },
  // 获取条件订单历史委托列表
  async usdtGetHistoryConditionOrders ({
    state,
    commit
  }, params) {
    commit('commonSet', dateFomat('historyConditionOrders_USDT', []));
    return api.usdtGetHistoryConditionOrders(params).then(res => {
      commit('commonSet', dateFomat('historyConditionOrders_USDT', res.pageData));
      return res;
    });
  },
  // 用户U合约保证金列表
  async usdtGetusdtPositionMargin ({
    state,
    commit
  }, params) {
    // commit('commonSet', dateFomat('positionMarginList_USDT', {}));
    return api.usdtPositionMargin(params).then(res => {
      commit('commonSet', dateFomat('positionMarginList_USDT', res));
      return res;
    });
  },
   // 平仓
   async usdtClosePosition ({
    state,
    commit
  }, params) {
    return api.closePosition(params);
    // commit('commonSet', dateFomat('incompleteOrders', res, 'add'));
  },
  // 平仓
  async getUsdtUserAssetsList ({
    state,
    commit
  }, params) {
    let ret;
    return api.usdtUserAssetsList(params).then(res => {
      ret = res;
      let _usdtUserAsset = [];
      for (let k in res.accounts) {
        _usdtUserAsset.push(Object.assign({}, res.accounts[k], {
          coin: k.toLowerCase()
        }));
      }
      commit('commonSet', dateFomat('usdtUserMainAssetsList',_usdtUserAsset));
    }).catch(err => {
      console.log(err);
    });
  }
}