<template>
  <transition name="dialog-fade">
    <div class="dialog-main" v-show="visible" :class="dialogType" @click='bgClick&&(visible=false)' :theme='theme'>
      <div class="dialog-box" :class="{'status-box':status}">
        <span v-if="status"><svg class="ficon" aria-hidden="true" :class="status">
            <use :xlink:href="statusClass"></use>
          </svg></span>
        <div class="dialog-header">
          <h5 class="dialog-title text-style-body-600" v-if="title" :class="status">{{title}}</h5>
        </div>
        <div class="dialog-body">
          <slot>
            <div class="dialog-con text-style-medium-400" v-html="message">
            </div>
          </slot>
        </div>
        <div class="dialog-footer" v-if="showCancelButton||showSureButton"> 
          <mq-button
            class="flex-1 mr-10px"
            type="gray"
            size="medium"
            :text="cancelButtonText"
            v-if="showCancelButton"
            @click="handAction('cancel')"
          ></mq-button>
          <mq-button
            class="flex-1"
            type="green"
            size="medium"
            :text="sureButtonText"
            v-if="showSureButton"
            @click="handAction('sure')"
          ></mq-button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import mqButton from '../mq-button/component.vue';
export default {
  name: 'message',
  props: {
  },
  components: {mqButton},
  data () {
    return {
      visible: false,
      bgClick: false,
      title: '',
      message: '',
      status: '',
      dialogType: '',
      theme: '',
      showClose: true,
      showCancelButton: true,
      showSureButton: true,
      cancelButtonText: '取消',
      sureButtonText: '确认',
      sureFn: null,
      cancelFn: null
    };
  },
  computed: {
    statusClass () {
      switch (this.status) {
        case 'success':
          return '#bf-icon_xxzzyds';
        case 'error':
          return '#bf-icon_kxgth';
        default:
          return '';
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('hashchange', this.close);
    });
  },
  beforeDestroy () {
    window.removeEventListener('hashchange', this.close);
  },
  methods: {
    close () {
      this.onclose && this.onclose();
    },
    handAction (action) {
      if (action === 'sure') {
        this.sureFn && this.sureFn();
      } else if (action === 'cancel') {
        this.cancelFn && this.cancelFn();
      }
      this.close();
    }
  },
  watch: {
    '$route': () => this.close
  }
};
</script>

<style lang="scss" scoped>

body[theme-mode="black"]{
  .prompt, .confirm {
    background: rgba(0, 0, 0, 0.8) !important;
  }
}
.dialog-main {
  position: fixed;
  z-index: 3000;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.prompt,
  &.confirm {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
  &.alert,
  &.alertMore {
    top: 40px;
    left: 0;
    right: 0;
  }
}
.dialog-box {
  width: 100%;
  max-width: 374px;
  background: var(--color-background-1);
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.08);
  border: 1px solid var(--color-background-3);
  border-radius: 16px;
  padding: 24px;
  @at-root .alert & {
    padding: 9px 20px;
    top: 98px;
    box-shadow: 0px 10px 15px 0px rgba(37, 84, 107, 0.15);
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }
  &.status-box {
    padding: 15px 15px 15px 44px;
    position: relative;
    @at-root .alert & {
      padding: 9px 9px 9px 44px;
    }
  }
}
.dialog-title {
  color: var(--color-text-1);
  @at-root .alert & {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.5);
    line-height: 22px;
    &.success {
      color: var(--COLOR-green);
    }
    &.error {
      color: var(--COLOR-red);
    }
  }
  @at-root .status-box & {
    text-align: left;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
  }
}
.ficon {
  position: absolute;
  width: 14px;
  height: 14px;
  top: 13px;
  left: 15px;
}
.alertMore .status-box .ficon {
  top: 18px;
}
.dialog-close {
  position: absolute;
  width: 14px;
  height: 14px;
  color: rgba(0, 0, 0, 0.2);
  top: 15px;
  right: 15px;
  left: initial;
  cursor: pointer;
  &:hover {
    color: rgba(0, 0, 0, 0.4);
  }
}
.dialog-con {
  color: var(--color-text-2);
  @at-root .confirm & {
    font-size: 14px;
    // color: rgba(0, 0, 0, 0.5);
    line-height: 22px;
    margin-top: 8px;
  }
  @at-root .alertMore & {
    font-size: 14px;
    margin-top: 5px;
    // color: rgba(0, 0, 0, 0.5);
    line-height: 22px;
  }
}

.success {
  color: var(--COLOR-green);
}
.error {
  color: var(--COLOR-red);
}
.dialog-footer {
  margin-top: 24px;
  display: flex;
}
.sure-btn,
.cancel-btn {
  min-width: 58px;
  height: 36px;
  line-height: 36px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  background: none;
  border: none;
  margin-left: 15px;
  outline: none;
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
}
.sure-btn {
  color: var(--COLOR-link);
}
.cancel-btn {
  color: var(--COLOR-50);
}

</style>
