<template>
  <transition name="dialog-fade">
    <div class="bf-dialog__wrapper" :style="{'z-index':hierarchy}" v-bind:class="[wrapperClasses]"
         v-show="visible"
         @click.self="handleWrapperClick">
      <div class="bf-dialog"
           @click.stop="onClickBody"
           ref="dialog" :style="style"
           :class="[theme === 'black' ? 'black' : 'white', bodyClasses]">
        <svg class="bf-dialog__headerbtn" v-if="showClose" @click="handleClose" aria-hidden="true">
          <use xlink:href='#bf-close-line'></use>
        </svg>
        <div class="bf-dialog__header" v-if="title">
          <slot name="title">
            <p class="bf-dialog__title text-style-body-600" v-html="title"></p>
          </slot>
        </div>
        <div class="bf-dialog__body text-style-medium-400">
          <slot></slot>
        </div>
        <div class="bf-dialog__footer" v-if="$slots.footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'BfDialog',
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: ''
    },
    hierarchy: {
      type: Number,
      default: 3000
    },
    onClickBody: {
      type: Function,
      default () {}
    },
    bodyClasses: {
      type: [Array, String],
      default: null
    },
    wrapperClasses: {
      type: [Array, String],
      default: null
    },
    closeOnClickModal: {
      type: Boolean,
      default: true
    },
    showClose: {
      type: Boolean,
      default: true
    },
    width: String,
    top: {
      default: undefined
    },
    backgroundImage: {
      type: String
    },
    beforeClose: Function,
    // dialog append to body,里面的内容使用自定义组件，否则样式要设置成全局的。
    // 因为在页面class下的样式不会生效，因为已经在body里了。
    appendToBody: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'white'
    }
  },
  data () {
    return {
      closed: false
      // rendered: false
    };
  },
  computed: {
    style () {
      let style = {};
      if (this.width) {
        style.width = this.width;
      }
      if (this.top !== undefined) {
        style.top = this.top;
        style.transform = 'translateX(-50%)';
        style.webkitTransform = 'translateX(-50%)';
      }
      if (this.backgroundImage) {
        style.backgroundImage = `url(${this.backgroundImage})`;
      }
      return style;
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.closed = false;
        this.$emit('open');
        this.$nextTick(() => {
          this.$refs.dialog && (this.$refs.dialog.scrollTop = 0);
        });
        if (this.appendToBody) {
          document.body.appendChild(this.$el);
        }
      }
    }
  },
  methods: {
    handleWrapperClick () {
      if (!this.closeOnClickModal) return;
      this.handleClose();
    },
    handleClose () {
      if (typeof this.beforeClose === 'function') {
        this.beforeClose(this.hide);
      } else {
        this.hide();
      }
    },
    hide (cancel) {
      if (cancel !== false) {
        this.$emit('update:visible', false);
        this.$emit('close');
        this.closed = true;
      }
    }
  },
  mounted () {
    if (this.visible) {
      // this.rendered = true;
      this.$emit('open');
      if (this.appendToBody) {
        document.body.appendChild(this.$el);
      }
    }
  },
  destroyed () {
    // if appendToBody is true, remove DOM node after destroy
    if (this.appendToBody && this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
  }
};
</script>
<style lang="scss" scoped>

@import "./bf-dialog.scss";

</style>
