import { handleRequest, request } from '../utils/fetch';
const apiConfig = require('../config/api.capps');

// 得到用户capps资产汇总
export const cappsSumAssets = (params) => {
    return handleRequest(request.get(apiConfig.cappsSumAssets, { params }));
};

// 获取用户capps资产列表
export const getCappsAllUserAsset = (params) => {
    return handleRequest(request.get(apiConfig.getCappsAllUserAsset, { params }));
};

// 获取用户capps某个币种资产信息
export const getUserCappsAssetByCoin = (params) => {
    return handleRequest(request.get(apiConfig.getUserCappsAssetByCoin, { params }));
};

// 根据币种获取用户capps可用资产
export const getUserCappsActiveAssetByCoin = (params) => {
    return handleRequest(request.get(apiConfig.getUserCappsActiveAssetByCoin, { params }));
};

// 获取用户资产变更记录
export const getUserCappsAssetChangeRecords = (params) => {
    return handleRequest(request.get(apiConfig.getUserCappsAssetChangeRecords, { params }));
};

// 根据语言获取所有项目方信息
export const getCappAccountList = (params) => {
  return handleRequest(request.get(apiConfig.getCappAccountList, { params }));
};
// 取得当前支付信息接口
export const postCappOrderInfo = (params) => {
  let ret = [];
  for (let it in params) {
    ret.push(it + '=' + params[it]);
  }
  ret.join('&');
  return handleRequest(request.post(apiConfig.postCappOrderInfo + '?' + ret.join('&')));
};
// 转账支付动作接口
export const postCappOrderPay = (params) => {
  let ret = [];
  for (let it in params) {
    ret.push(it + '=' + params[it]);
  }
  ret.join('&');
  return handleRequest(request.post(apiConfig.postCappOrderPay + '?' + ret.join('&')));
};
// 获取capps订单列表
export const getUserCappsPayOrders = (params) => {
  return handleRequest(request.get(apiConfig.getUserCappsPayOrders, { params }));
};
// 获取项目方名称
export const getAllAppAccountListNoLang = (params) => {
  // state：不传为全部，0：未上架，1：已上架，2：已下架
  return handleRequest(request.get(apiConfig.getAllAppAccountListNoLang, params));
};
// 获取项目方名称
export const getAppAccountListNoLang = (params) => {
  // state：不传为全部，0：未上架，1：已上架，2：已下架
  return handleRequest(request.get(apiConfig.getAppAccountListNoLang, params));
};
// 获取所有项目方用户统计数据
export const getCappAllAppAccountUserStatistic = (params) => {
  return handleRequest(request.get(apiConfig.getCappAllAppAccountUserStatistic, params));
};
// 获取总用户数据统计
export const getCappTotalAppAccountUserStatistic = (params) => {
  return handleRequest(request.get(apiConfig.getCappTotalAppAccountUserStatistic, params));
};
// 增加登录日志
export const getCappUpdateLogin = (params) => {
  return handleRequest(request.get(apiConfig.getCappUpdateLogin, { params }));
};
// 增加授权日志
export const getCappUpdateAuthorize = (params) => {
  return handleRequest(request.get(apiConfig.getCappUpdateAuthorize, { params }));
};
// 增加授权日志
export const getAppAccountByClientId = (params) => {
  return handleRequest(request.get(apiConfig.getAppAccountByClientId, { params }));
};
