import { handleRequest, request } from '../utils/fetch';
const apiConfig = require('../config/api.mt5');

// 同意协议
export const mt5Agreement = (params) => {
  return handleRequest(request.get(apiConfig.mt5Agreement, { params }));
};
// 获取协议状态
export const mt5GetAgreement = (params) => {
  return handleRequest(request.get(apiConfig.mt5GetAgreement, { params }));
};
// 得到用户所有的账号
export const mt5GetAccounts = (params) => {
  return handleRequest(request.get(apiConfig.mt5GetAccounts, { params }));
};
// 创建账号
export const mt5CreatAccount = (params) => {
  return handleRequest(request.get(apiConfig.mt5CreatAccount, { params }));
};
// 查看密码
export const mt5GetPassword = (params) => {
  return handleRequest(request.get(apiConfig.mt5GetPassword, { params }));
};
// 重置密码
export const mt5ResetPassword = (params) => {
  return handleRequest(request.post(apiConfig.mt5ResetPassword, params));
};
// 得到用户的资产汇总
export const mt5SumAssets = (params) => {
  // return null;
  return handleRequest(request.get(apiConfig.mt5SumAssets, { params }));
};
// MT5转到其他钱包
export const mt5TransferOut = (params) => {
  return handleRequest(request.post(apiConfig.mt5TransferOut, params));
};
// 申请记录
export const mt5TransferRecord = (params) => {
  return handleRequest(request.get(apiConfig.mt5TransferRecord, { params }));
};
// 获取用户历史账单
export const mt5bills = ({ currency, ...rest }) => {
  return handleRequest(request.get(apiConfig.mt5bills(currency), { params: rest }));
};
// 取消划转申请
export const mt5TransferCancel = ({ id, ...rest }) => {
  return handleRequest(request.get(apiConfig.mt5TransferCancel(id), { params: rest }));
};
// 获取mt5是否维护
export const getSystemStatus = (params) => {
  return handleRequest(request.get(apiConfig.getSystemStatus, { params }));
};
// 获取mt5交易大赛排名
export const mt5EventRankList = (params) => {
  return handleRequest(request.get(apiConfig.mt5EventRankList, { params }));
};
// 获取mt5交易大赛个人排名
export const mt5EventPersonRankList = (params) => {
  return handleRequest(request.get(apiConfig.mt5EventPersonRankList, { params }));
};
// 获取赠金提示
export const mt5EventBonusTips = (params) => {
  return handleRequest(request.get(apiConfig.mt5EventBonusTips, { params }));
};
// 赠金活动信息
export const mt5EventActivity = (params) => {
  return handleRequest(request.get(apiConfig.mt5EventActivity, { params }));
};
