<template>
  <div>
    <div class="input-box">
      <input type="text"
             @focus="showDrop=true"
             @blur="blurSelect"
             v-model="query"
             class="input"
             :readonly="!hasSearch"
             :disabled="disable"
             :placeholder="placeholder"
      >
      <svg class="ficon" aria-hidden="true" :class="{'up':showDrop}">
        <use xlink:href='#bf-icon_cutover'></use>
      </svg>
    </div>
    <transition name="fade">
      <ul>
        <slot></slot>
      </ul>
    </transition>
  </div>
</template>

<script>
  export default {
    name: 'vueSelect',
    provide () {
      return {
        select: this
      };
    },
    data () {
      return {
        query: '',
        showDrop: false,
        innerPlaceholder: '',
        filterFn: null
      };
    },
    props: {
      placeholder: {
        type: String,
        default: '请选择'
      },
      value: {
        require: true
      },
      hasSearch: {
        type: Boolean,
        default: false
      },
      disable: {
        type: Boolean,
        default: false
      },
      filter: {
        type: Function
      }
    },
    created () {

    },
    methods: {
      blurSelect () {},
      changeValue (value) {
        console.log(value);
      }
    }
  };
</script>

<style scoped>



</style>
