export default {
  // 计算属性
  combineState (state) {
    return `${state.data1} -> ${state.data2}`;
  },
  currentList (state) { // 资产显示数组
    return [
      {label: 'BTC', value: 0},
      {label: 'USDT', value: 1},
      {label: state.currentCurrency.isoCode || 'USD', value: 2},
    ];
  },
  currentUnit (state, getters) { // 资产显示数组
    return getters.currentList[state.currentVal].label
  },
};
