import * as types from '../../../types';
import * as tradeModel from '~/model/clientTradeModel';
import eventBus from '~/utils/eventBus';
import { filterCommodity } from '~/utils/util';

const handleError = (err) => {
  // handle error
  if (err.code === 401) {
    // notify({
    //   content: '请登录！'
    // })
    eventBus.$emit('auth');
  }
};

export default {
  // 一个自动区分clientModel和serverModel的例子
  getTicker ({dispatch, commit}, params) {
    return tradeModel.getTickerInfoByBusitype(params).then((res) => {
      if (typeof res === 'string') {
        res = JSON.parse(res);
      }
      if (res.success === true) {
        commit(types.UPDATE_TICKER, res.data || {});
      }
    }).catch(err => {
      handleError(err);
    });
  },
  getDeepDataByBusiType ({dispatch, commit}, params) {
    return tradeModel.getDeepDataByBusiType(params).then((res) => {
      if (typeof res === 'string') {
        res = JSON.parse(res);
      }
      if (res.success === true) {
        commit(types.DEPTHS, res.data);
      }
    }).catch(err => {
      handleError(err);
    });
  },
  // 获取所有交易对配置信息
  getSymbolsInfo ({dispatch, commit}, params) {
    return tradeModel.getBusiTypeInfos().then(res => {
      if (res.success === true) {
        let _symbolsInfo = {};
        res.data.forEach(item => {
          item.commodityName = filterCommodity(item.commodityCode.toUpperCase());
          _symbolsInfo[item.busitype] = item;
        });
        commit(types.SYMBOLS_INFO, _symbolsInfo);
        commit(types.SYMBOLS_INFO_READY, true);
      }
    }).catch(err => {
      handleError(err);
    });
  }
};
