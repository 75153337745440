const getDefaultState = () => {
  return {
  // 1待开始， 2进行中， 3已结束
  active_status: 0
  };
};

const state = getDefaultState();

const mutations = {
  setActiveStatus: (state, status) => {
    state.active_status = status;
  }
};

const actions = {
  ceshi ({ commit, state }) {

  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
