import {handleRequest, request} from '../utils/fetch';
// eslint-disable-next-line no-unused-vars
import qs from 'qs';
const apiConfig = require('../config/api.config');

// 获取理财列表（定期）
export const getFixedProductList = (params) => {
  return handleRequest(request.get(apiConfig.getFixedProductList, { params }));
};

// 获取理财详情
export const getFixedProductDetail = (id) => {
  return handleRequest(request.get(`${apiConfig.getFixedProductDetail}/${id}` ));
};
// 创建理财订单
export const createFinancingOrder = (params) => {
  return handleRequest(request.post(apiConfig.createFinancingOrder, params));
};
// 赎回活期理财订单
export const redeem = (params) => {
  return handleRequest(request.post(apiConfig.redeem + params));
};
// 获取理财资产
export const getFinancingAsset = () => {
  return handleRequest(request.get(apiConfig.getFinancingAsset));
};
// 获取旧理财资产
export const getFixedAsset = () => {
  return handleRequest(request.get(apiConfig.getFixedAsset));
};
// 获取理财资产
export const getDetailId = (id) => {
  return handleRequest(request.get(`${apiConfig.getDetailId}/${id}`));
};
// 获取理财订单
export const getOrderList = (params) => {
  return handleRequest(request.get(apiConfig.getOrderList, {params}));
};
// 获取旧理财订单
export const getOrderFixedList = (params) => {
  return handleRequest(request.get(apiConfig.getOrderFixedList, {params}));
};

// 按板块获取理财产品列表
export const moduleList = (params) => {
  return handleRequest(request.get(apiConfig.moduleList, {params}));
};
// 获取板块列表
export const getStakingOrderList = (params) => {
  return handleRequest(request.get(apiConfig.getStakingOrderList, {params}));
};
// 获取板块列表
export const getModuleList = (params) => {
  return handleRequest(request.get(apiConfig.getModuleList, {params}));
};
// 板块订单概览列表
export const moduleOrderOverview = (params) => {
  return handleRequest(request.get(apiConfig.moduleOrderOverview, {params}));
};
// 板块订单概览列表
export const moduleOrderOverviewOrderList = (params) => {
  return handleRequest(request.get(apiConfig.moduleOrderOverviewOrderList, {params}));
};
// 查用户已借数量
export const getUserLoanedAmount = (params) => {
  return handleRequest(request.get(apiConfig.getUserLoanedAmount, {params}));
};

// 用户白名单
export const userIsOpen = (params) => {
  return handleRequest(request.get(apiConfig.userIsOpen, {params}));
};

// 全部币种
export const getAllCoinInfos = () => {
  return handleRequest(request.post(apiConfig.getAllCoinInfos));
};
