export default {
   /***
   * 合约列表
   * ***/
  usdtContractList: {
    url: '/contract/list'
  },
  /***
   * 全部合约信息明细
   * ***/
  usdtContractAllInfo: {
    url: '/contract/infos'
  },
  /***
   * U合约用户的资产汇总
   * ***/
  usdtUserAssetsList: {
    url: '/account/sumFound'
  },
  /***
 * 合约公共信息
 * ***/
  usdtContractBaseInfo: {
    url: '/contract/premiumIndex'
  },
  /***
   * 全部合约行情
   * ***/
   usdtContractAllTickers: {
    url: '/mkapi/tickers'
  },
  /**
   * params:
   * symbol 合约代码
   */
   allowPositionMarginType: { // 币对全仓逐仓
    url: '/account/allowPositionMarginType'
  },
  /**
   * 保存全仓逐仓
   */
   savePositionMarginType: {
    method: 'post',
    url: '/account/update/marginType'
  },
  /**
   * 获取U本位币对杠杆倍数
   */
   leverageFind: {
    url: '/leverage/find'
  },
  /**
   * 修改U本位币对杠杆倍数
   */
   leverageUpdate: {
    url: '/leverage/update'
  },
  /**
   * 计算杠杆倍数调整变化值
   */
  //  leverageCalculate: {
  //   url: '/leverage/calculate'
  // },
  /**
   * U本位账户资讯
   */
   usdtUserContractInfo: {
    url: '/account/coin'
  },
  /**
   * 条件单下单
   */
   usdtConditionOrder: {
    method: 'post',
    url: '/trade/condition/create'
  },
  /**
   * 委托单下单
   */
   usdtContractOrder: {
    method: 'post',
    url: '/trade/order'
  },
  /**
   * 用户持仓
   */
  usdtPosition: {
    url: '/trade/position'
  },
  /**
   * 获取用户当前委托列表
   */
  usdtIncompleteOrders: {
    url: '/trade/order/list'
  },
  /**
   * 获取用户平仓盈亏
   */
  usdtGetPnl: {
    url: '/trade/trade/pnl'
  },
  /**
   * 获取用户平仓盈亏
   */
  usdtGetPnlDetail: {
    url: '/trade/trade/pnl/details'
  },
  /**
   * 撤销全部订单
   */
  usdtCancelOrderAll: {
    method: 'delete',
    url: '/trade/order/all'
  },
  /**
   * 撤销订单
   */
  usdtCancleContractOrder: {
    method: 'delete',
    url: '/trade/order'
  },
  /**
   * 修改订单
   */
  usdtUpdateOrder: {
    method: 'post',
    url: '/trade/order/update'
  },
  /**
   * 获取用户条件委托列表
   */
  usdtConditionOrders: {
    url: '/trade/condition/active/list'
  },
  /**
   * 撤销全部条件委托订单
   */
  usdtCancelAllConditionContractOrder: {
    method: 'delete',
    url: '/trade/condition/deleteAll'
  },
  /**
   * 条件单撤单
   */
  usdtCancleConditionContractOrder: {
    method: 'delete',
    url: '/trade/condition/delete'
  },
  /**
   * 获取用户已成交列表
   */
  usdtCompleteOrders: {
    url: '/trade/order/trade'
  },
  /**
   * 获取用户历史委托列表
   */
  usdtHistoryContractOrders: {
    url: '/trade/order/history/list'
  },
  /**
   * 获取用户条件订单历史委托列表
   */
  usdtGetHistoryConditionOrders: {
    url: '/trade/condition/history/list'
  },
  /**
   * 用户U合约保证金列表
   */
  usdtPositionMargin: {
    url: '/account/getPositionMargin'
  },
  /**
   * 获取合约资金费用日志
   */
  collectFund: {
    url: '/contract/collectFund'
  },
  /**
   * 逐仓保证金调整信息查询
   */
  adjustment: {
    method: 'post',
    url: '/account/isolated/margin/adjustment'
  },
  /**
   * 逐仓保证金调整信息查询
   */
  liqPrice: {
    method: 'post',
    url: '/account/isolated/margin/liqPrice'
  },
  /**
   * 增加逐仓保证金
   */
  positionMargin: {
    method: 'post',
    url: '/account/update/positionMargin'
  },
  /**
   * 获取U合约资产汇总
   */
  assetSumInfo: {
    url: '/account/assetSumInfo'
  },
   /**
   * 平仓
   * params:
   * {
   *   symbol
   *   price
   *   source
   * }
   */
  closePosition: {
    method: 'post',
    url: '/trade/order/position'
  },
  /**
   * 获取用户U本位账单
   */
  getPerBill: {
    url: '/account/coin/ledger'
  },
  /**
   * 获取法币列表
   */
  getCoinList: {
    url: '/contract/coinList'
  },
  /**
   * 关闭永续新手引导
   */
  addUserTip: {
    url: '/account/addUserTip'
  },
  /**
   * 是否展示永续导航
   */
  getUserTipFlag: {
    url: '/account/getUserTipFlag'
  },
  /**
   * 白名单接口
   */
  getWhiteUserFlag: {
    url: '/account/getWhiteUserFlag'
  },
  /**
   * 返佣汇总信息查询
   */
  getCommissionInfo: {
    url: '/commission/info'
  },
  /**
   * 佣金、交易额图表
   */
  getCommissionTradeChart: {
    url: '/commission/commissionTradeChart'
  },
  /**
   * 邀请客户列表
   */
  getInviteClient: {
    url: '/commission/invite/client'
  },
  /**
   * 邀请客户列表
   */
  userStatus: {
    url: '/commission/userStatus'
  },
  /**
   * 返佣汇总数据概况
   */
  rebateSummaryInfo: {
    url: '/commissionStatistics/cmiAll'
  },
  /**
   * 返佣记录
   */
  rebateRecordList: {
    url: '/commissionStatistics/cmiAll/cmiList'
  },
  /**
   * 返佣汇总列表
   */
  rebateSummaryList: {
    url: '/commissionStatistics/cmiAll/list'
  },
  /**
   * 返佣统计概况
   */
  contractStatisticsInfo: {
    url: '/commissionStatistics/info'
  },
  /**
   * 返佣合伙人列表
   */
  cmiDetail: {
    url: '/commissionStatistics/cmiDetail'
  },
  /**
   * 返佣合伙人委托列表
   */
  cmiOrderList: {
    url: '/commissionStatistics/cmiOrderList'
  },
  /**
   * 返佣合伙人出入金列表
   */
  cmiTransferList: {
    url: '/commissionStatistics/cmiTransferList'
  },
  /**
   * 返佣后台入口是否展示
   */
  cmiShowFlag: {
    url: '/commissionStatistics/cmiShowFlag'
  }
};
