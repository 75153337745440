import { setCookie, getCookie } from '~/utils/cookieUtil';
export function ga_submit() {
  this.$GA.event('click',{
    event_category: '登录页面',
    page_title: '登录页面',
    event_label: '点击登录按钮',
  })
}
export function ga_username() {
  this.$GA.event('click',{
    event_category: '登录页面',
    page_title: '登录页面',
    event_label: '点击手机号/邮箱输入框',
  })

}
export function ga_passworld() {
  this.$GA.event('click',{
    event_category: '登录页面',
    page_title: '登录页面',
    event_label: '点击密码输入框',
  })
}
export function ga_alislide() {
  this.$GA.event('click',{
    event_category: '登录页面',
    page_title: '登录页面',
    event_label: '阿里滑块验证通过',
  })
}
export function ga_tencent_success() {
  this.$GA.event('click',{
    event_category: '登录页面',
    page_title: '登录页面',
    event_label: '腾讯验证通过',
  })
}
export function ga_googlecaptcha() {
  this.$GA.event('click',{
    event_category: '登录页面',
    page_title: '登录页面',
    event_label: '谷歌安全验证通过',
  })
}
export function ga_loginsuccess(_uid) {
  this.$GA.event('click',{
    event_category: '登录页面',
    page_title: '登录页面',
    event_label: '登录成功并跳转',
  })
  source_ga(this.$GA)
  ga_login_user_id(this.$GA, _uid)
}
export function ga_forgot() {
  this.$GA.event('click',{
    event_category: '登录页面',
    page_title: '登录页面',
    event_label: '忘记密码',
  })
}
export function ga_enter() {
  this.$GA.event('page_view',{
    event_category: '登录页面',
    page_title: '登录页面',
    page_location:  window.location.href,
    page_path:  window.location.pathname,
    event_label: '进入登录页面',
  })
}

export function ga_enter_register() {
  this.$GA.event('page_view',{
    event_category: '注册页面',
    page_title: '注册页面',
    page_location:  window.location.href,
    page_path: window.location.pathname,
    event_label: '进入注册页面',
  })
}

export function ga_loginsuccess_register(_uid) {
  this.$GA.event('click',{
    event_category: '注册页面',
    page_title: '注册页面',
    event_label: '注册成功并跳转',
  })
  source_ga_register(this.$GA, _uid)
  ga_register_user_id(this.$GA, _uid)
  ga_register_success(this.$GA, _uid)
 
}

export function source_ga_tags(GA,router) {

  function gtag_ga(g_source, g_project) {
    const param = JSON.stringify({g_source, g_project})
    const tags = {
      twitter: 'Twitter',
      telegram: 'Telegram'
    }
    setCookie('g_tags', param, '3d')
    GA.event('page_view',{
      event_category: tags[g_source],
      page_title: g_project,
      page_location:  window.location.href,
      page_path:  window.location.pathname,
      event_label: '外部来源_进入页面',
    })
  }
  
  const { g_source = '', g_project = '' } =  router.currentRoute.query
  if(g_source) {
    gtag_ga(g_source, g_project)
  } else {
    const g_tags =  getCookie('g_tags')
    const { g_source = '', g_project = '' } = !!g_tags ? JSON.parse(g_tags) : {}
    if(g_source) {
      gtag_ga(g_source, g_project)
    }
  }
}

export function source_ga(GA) {
  const g_tags =  getCookie('g_tags')
  const { g_source = '', g_project = '' } = !!g_tags ? JSON.parse(g_tags) : {}
  const tags = {
    twitter: 'Twitter',
    telegram: 'Telegram'
  }
 
  if(g_source) {
    GA.event('click',{
      event_category: tags[g_source],
      page_title: g_project,
      event_label: '外部来源_登录成功并跳转',
    })
  }
}


export function source_ga_register(GA, _uid) {
  const g_tags =  getCookie('g_tags')
  const { g_source = '', g_project = '' } = !!g_tags ? JSON.parse(g_tags) : {}
  const tags = {
    twitter: 'Twitter',
    telegram: 'Telegram'
  }
 
  if(g_source) {
    GA.set({ user_id: _uid })
    GA.event('click',{
      event_category: tags[g_source],
      page_title: g_project,
      event_label: '外部来源_注册成功并跳转',
      user_id: _uid
    })
  }
}

export function ga_other_place_login () {
  this.$GA.event('click', {
    event_category: '登录页面',
    page_title: '登录页面',
    event_label: '触发异地登录'
  });
};

export function ga_register_googlecaptcha () {
  this.$GA.event('click', {
    event_category: '注册页面',
    page_title: '注册页面',
    event_label: '谷歌验证通过'
  });
};

export function ga_register_alislide () {
  this.$GA.event('click', {
    event_category: '注册页面',
    page_title: '注册页面',
    event_label: '阿里滑块验证通过'
  });
};

export function ga_tencent_register_success() {
  this.$GA.event('click',{
    event_category: '注册页面',
    page_title: '注册页面',
    event_label: '腾讯验证通过',
  })
}

export function ga_register_phone () {
  this.$GA.event('click', {
    event_category: '注册页面',
    page_title: '注册页面',
    event_label: '手机号注册成功并跳转'
  });
};

export function ga_register_email () {
  this.$GA.event('click', {
    event_category: '注册页面',
    page_title: '注册页面',
    event_label: '邮箱注册成功并跳转'
  });
};

export function ga_register_success(GA,_uid) {
  GA.set({ user_id: _uid })
  GA.page({
    event_category: '注册页面',
    page_title: '注册页面',
    page_location: location.origin + location.pathname.replace('register','register_success') + '?UserId=' + _uid,
    page_path:  location.pathname.replace('register','register_success') ,
    event_label: '进入注册成功页面',
    user_id: _uid,
    value: _uid,
  })
}

export function ga_login_user_id(GA, user_id = '') {
  GA.set({ user_id: user_id })
  GA.event('click',{
    event_category: '登录页面',
    page_title: '登录页面',
    event_label: '登录用户UID',
    page_location:  window.location.href,
    page_path:  window.location.pathname,
    user_id: user_id,
    value: user_id
  })
}

export function ga_register_user_id(GA, user_id = '') {
  GA.set({ user_id: user_id })
  GA.event('click',{
    event_category: '注册页面',
    page_title: '注册页面',
    event_label: '注册用户UID',
    page_location:  window.location.href,
    page_path:  window.location.pathname,
    user_id: user_id,
    value: user_id
  })
}

export function ga_secure() {
  this.$GA.event('click',{
    event_category: '安全',
    page_title: '',
    event_label: '疑似黑客',
  })
}


