import 'babel-polyfill';
import Big from 'big.js';
import Vue from 'vue';
import Meta from 'vue-meta';
import { Skeleton } from 'vue-loading-skeleton';
import md5 from 'js-md5';
// import MQ from 'vue-match-media/src'
import VueMq from 'vue-mq';
import localStore from 'store';
import Ga from './utils/google-analyze.js';
import GoogleAnalyze from './utils/google-analyze.js';
import {
  formatDate,
  formatDataTime,
  formatTime,
  formatNum,
  formatNumByValid,
  toNonExponentials,
  toNumber,
  formatMoney,
  formatTokenPair,
  numFormatStr,
  formatTimeRemain,
  toNonExponential,
  effecNumber,
  toInteger,
  doubleDigit,
  filterCommodity, getLocalLangData, getMomentLocale, filterCurrency, getReferrer
} from './utils/util';
import {getCookie, setCookie} from '~/utils/cookieUtil';

import {
  source_ga_tags
} from '~/ga/ga';
import {
  currencyFilter
} from './utils/currencyFilter';
import {
  Form,
  Button,
  Select,
  Tooltip,
  Radio,
  RadioGroup,
  Option,
  Dialog,
  Input,
  InputNumber,
  Checkbox,
  CheckboxGroup,
  Table,
  TableColumn,
  Carousel,
  CarouselItem,
  Popover,
  DatePicker,
  Message,
  Slider,
  FormItem,
  Pagination,
  Collapse,
  CollapseItem,
  Divider,
  Drawer,
  Switch,
  Dropdown,
  DropdownMenu,
  DropdownItem,
} from 'element-ui';
import Common from '~/utils/common';
import VueLottiePlayer from 'vue-lottie-player';
import VueClipboards from 'vue-clipboards';
import ClickOutside from 'vue-click-outside';
import lottie from 'lottie-web'; 
import Precision from './directives/precision';
// 雪花飘落
import LetItSnow from 'vue-let-it-snow';
import Loading from './components/loading';
import BfDialog from './components/bf-dialog';
import MqButton from './components/mq-button';
import BfTable from './components/bf-table';
import BfPagination from './components/bf-pagination';
import BfTooltip from '~/components/bf-tooltip';
import BasePagination from './components/base-pagination';
import vueSelect from './components/vueSelect';
import vueForm from './components/vueForm';
import {
  confirm,
  prompt,
  // alert,R
  alertMore
} from './components/message';

import App from './app.vue';
import createRouter from './router';
import store from './store';
import i18n from './locales';
import moment from 'moment';
import eventBus from './utils/eventBus';

// 按需引用 bitforex-ui
import {
  MqSearch,
  HeaderWeb,
  ThemeSelect,
  MqTooltip,
  MqChecked,
  TradeInput,
  LayPopup
} from 'bitforex'

require('./assets/css/common.scss');
require('./assets/css/bf-ui.scss');
require('libDir/plugins/animate.css');
Vue.use(Meta);
Vue.use(VueClipboards);
Vue.directive('ClickOutside', ClickOutside);
Vue.directive('Precision', Precision);

// Vue.use(MQ);
Vue.use(VueMq, {
  defaultBreakpoint: 'web',
  breakpoints: {
    ph: 743,
    pd: 1200,
    web: Infinity
  }
});

// 按需使用 bitforex-ui
Vue.use(HeaderWeb);
Vue.use(ThemeSelect);
Vue.use(MqTooltip);
Vue.use(MqChecked);
Vue.use(TradeInput);
Vue.use(LayPopup);
Vue.use(MqSearch);

// 自定义全局组件
Vue.use(BfDialog);
Vue.use(MqButton);
Vue.use(BfTable);
Vue.use(BfPagination);
Vue.use(BfTooltip);
Vue.use(BasePagination);
Vue.use(Loading);
Vue.use(vueSelect);
Vue.use(vueForm);
Vue.use(VueLottiePlayer);
Vue.use(LetItSnow);
// element-ui组件按需引入使用
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Button);
Vue.use(Tooltip);
Vue.use(Select);
Vue.use(Radio);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(RadioGroup);
Vue.use(Option);
Vue.use(Dialog);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Popover);
Vue.use(DatePicker);
Vue.use(Slider);
Vue.use(Pagination);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Divider);
Vue.use(Skeleton);
Vue.use(Drawer);
Vue.use(Switch);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);

Vue.prototype.$md5 = md5;
Vue.prototype.$message = Message;
Vue.prototype.$confirm = confirm;
Vue.prototype.$prompt = prompt;
Vue.prototype.$alert = Message;
Vue.prototype.$alertMore = alertMore;
// 全局注册lottie动画库
Vue.prototype.$lottie = lottie;
Vue.prototype.$eventBus = eventBus;
Vue.prototype.$localStore = localStore;
Vue.prototype.$setCookie = setCookie;
Vue.prototype.$getCookie = getCookie;
Vue.prototype.$GA = GoogleAnalyze;
// Vue.prototype.$sensors = window.sensors;
Vue.prototype.$currency = (item, currentCurrency) => {
  const number = Number(item);
  if (number && currentCurrency && currentCurrency.usdToThisRate) {
    const returnNumber = Big(number).times(currentCurrency.usdToThisRate).valueOf();
      if (returnNumber >= 1) {
        return formatNum(returnNumber, currentCurrency.precision);
      } else if (returnNumber >= 0.01 && returnNumber < 1) {
        return formatNum(returnNumber, 2);
      } else {
        return returnNumber;
      }
  } else {
    return item;
  }
};
Vue.prototype.$log = (msg) => {
  if (process.env.NODE_ENV === 'development') {
    // console.log('------前端提示: ' + msg);
  }
};

// 全局filters
/**
 * format 时间
 * @param {string} formatString 'yyyy-MM-dd h:m:s' || 'yyyy-MM-dd'
 */
Vue.filter('currencyFilter', currencyFilter);
Vue.filter('filterCurrency', filterCurrency);
Vue.filter('toNonExponentials', toNonExponentials);
Vue.filter('formatDate', formatDate);
Vue.filter('formatDataTime', formatDataTime);
Vue.filter('formatTime', formatTime);
Vue.filter('formatNumber', formatNum);
Vue.filter('formatNumByValid', formatNumByValid);
Vue.filter('toNumber', toNumber);
Vue.filter('formatMoney', formatMoney);
Vue.filter('formatTokenPair', formatTokenPair);
Vue.filter('numFormatStr', numFormatStr);
Vue.filter('formatTimeRemain', formatTimeRemain);
Vue.filter('formatToInteger', toInteger);
Vue.filter('filterCommodity', filterCommodity);
Vue.filter('toNonExponential', toNonExponential);
Vue.filter('effecNumber', effecNumber);
Vue.filter('doubleDigit', doubleDigit);

if (!Vue.prototype.$isServer) {
  window.WS_DOMAIN = window.location.origin;
  window.WS_DOMAIN2 = window.location.origin;
  window.WS_DOMAIN3 = window.location.origin;
  window.WS_DOMAIN4 = window.location.origin;
  if (window.WS_DOMAIN) {
    // || window.WS_DOMAIN === 'http://192.168.199.166'
    if (window.WS_DOMAIN === 'http://t.bitforex.com:8000' || window.WS_DOMAIN === 'http://localhost:8000' || window.WS_DOMAIN === 'http://192.168.199.166:82' || window.WS_DOMAIN === 'http://127.0.0.1:8000') {
      window.WS_DOMAIN = 'ws://192.168.199.166';
      window.WS_DOMAIN2 = 'ws://192.168.199.166';
      window.WS_DOMAIN3 = 'ws://192.168.199.166/contract';
      window.WS_DOMAIN4 = 'ws://test112.bitforex.com/';
    } else if (window.WS_DOMAIN === 'https://192.168.199.151' || window.WS_DOMAIN === 'https://www151.bitforex.com') {
      window.WS_DOMAIN = 'wss://192.168.199.151';
      window.WS_DOMAIN2 = 'wss://192.168.199.151';
      window.WS_DOMAIN3 = 'wss://192.168.199.151';
    } else if (window.WS_DOMAIN === 'https://192.168.199.123') {
      window.WS_DOMAIN = 'wss://192.168.199.123';
      window.WS_DOMAIN2 = 'wss://192.168.199.123';
      window.WS_DOMAIN3 = 'wss://192.168.199.123';
    } else {
      window.WS_DOMAIN = window.WS_DOMAIN.replace(/^http/, 'ws');
      // window.WS_DOMAIN2 = window.WS_DOMAIN2.replace(/^http/, 'ws'); // 模拟
      // window.WS_DOMAIN3 = window.WS_DOMAIN3.replace(/^http/, 'ws'); // 实盘
      window.WS_DOMAIN2 = window.WS_DOMAIN2.replace(/^http/, 'ws') + '/testnet'; // 模拟
      window.WS_DOMAIN3 = window.WS_DOMAIN3.replace(/^http/, 'ws') + '/contract'; // 实盘
      window.WS_DOMAIN4 = window.WS_DOMAIN4.replace(/^http/, 'ws');
    }
  }
}

if (process.env.NODE_ENV === 'development') {
  // window.WS_DOMAIN = 'wss://ws.bitforex.com';
  window.WS_DOMAIN2 = 'wss://test112.bitforex.com/testnet';
  window.WS_DOMAIN3 = 'wss://test112.bitforex.com/contract';
  window.WS_DOMAIN = 'wss://test112.bitforex.com';
  // window.WS_DOMAIN2 = 'ws://192.168.199.112';
  // window.WS_DOMAIN3 = 'ws://192.168.199.112/contract';
}

export async function createApp (lang) {
  // if (process.env.VUE_ENV === 'server') {
  //   i18n.locale = 'en';
  // }
  console.log('create app lang:' + lang);
  if (store.state.common.locale !== lang) {
    import(/* webpackChunkName: "lang-[request]" */ `~/locales/${lang}`).then(msgs => {
      const _localData = getLocalLangData(lang, msgs.default);
      i18n.setLocaleMessage(lang, Object.assign({}, msgs.default, _localData));
      i18n.locale = lang;
      store.commit('common/LOCALE', lang);
      moment.locale(getMomentLocale(lang));
      // lazyLocalLang(lang, _localData);
    });
  } else {

  }
  // 根据语言设置不同字体文件，给sass传语言标识
  document.documentElement.setAttribute('data-lang', lang === 'kr' ? 'kr' : 'default');

  const router = createRouter(lang);
  router.beforeEach((to, from, next) => {
    // window.sensors && window.sensors.quick('autoTrackSinglePage');
    if (to.matched.some(record => record.meta.requiresAuth)) {
      // this route requires auth, check if logged in
      // if not, redirect to login page.
      if (!store.state.user.isLogin) {
        next({
          path: '/login',
          query: {
            redirect: to.fullPath
          }
        });
      } else {
        next();
      }
    } else {
      next();
    }
  });
  router.afterEach((to, from, next) => {
    // 判断是否交易页，设置主题色
    if (to.path.indexOf('/spot/') === 0 || to.path.indexOf('/simulation-perpetual/') === 0 || to.path.indexOf('/perpetual/') === 0 || to.name === 'eazyswap' || to.name === 'fundingRate' || to.path.indexOf('/order/') === 0) {
      store.commit('common/IS_TRADE_PAGE', true);
    } else {
      store.commit('common/IS_TRADE_PAGE', false);
    }
    // 仅Support页面显示客服入口
    if (to.path.indexOf('/Support') === 0) {
      document && document.querySelector('#launcher') && (document.querySelector('#launcher').style.opacity = 1);
    } else {
      document && document.querySelector('#launcher') && (document.querySelector('#launcher').style.opacity = 0);
    }
    // 判断页面是否正在维护
    // let maintenanceObj = checkMaintenance(store.state.common.maintenanceList, to.name);
    // if (maintenanceObj) {
    //   // let fromUrl = window.location.href;
    //   if (window.location.host.includes('127.0.0.1')) {
    //     window.location.href = `/#/${store.state.common.locale}/maintenance/${maintenanceObj.id}?from=${window.location.protocol}//${window.location.host}${to.fullPath}`;
    //   } else {
    //     window.location.href = `/${store.state.common.locale}/maintenance/${maintenanceObj.id}?from=${window.location.protocol}//${window.location.host}${to.fullPath}`;
    //   }
    // }

    // 客服系统显示 (交易页面不显示，刷新时要单独处理)
    /* if (to.path.indexOf('/iou') === 0 || to.path.indexOf('/userCenter') === 0 || to.name === 'home') { // 只在 首页，个人中心，IOU 显示
      if (document.getElementById('intercom-container')) {
        document.getElementById('intercom-container').style.display = 'block';
      }
    } else {
      window.Intercom && window.Intercom('hide');
      if (document.getElementById('intercom-container')) {
        document.getElementById('intercom-container').style.display = 'none';
      }
    }
    if (to.name === 'register') {
      window.sensors && window.sensors.track('ViewRegister', {
        locate_page: document.referrer || from.fullPath
      }, app.$log('访问注册页面'));
    } */

    GoogleAnalyze.page({
      page_title:  to.meta.gaTitle,
      page_path:  window.location.pathname,
      page_location: window.location.href
    })
  });

  router.onReady(async () => {
    // app挂载之前获取基础数据
    // await Promise.all([
    //   // store.dispatch('user/getDigitalCoinInfos'), // 币种配置信息
    //   // store.dispatch('trade/getSymbolsInfo') // 交易的配置信息
    //   // store.dispatch('user/checkLogin') // 交易的配置信息
    // ]);
    // await store.dispatch('user/checkLogin');
    app.$mount('#root');
    //console.log('route--AAA-------',  router.currentRoute.query    )
    getReferrer() // 获取 Referrer
    source_ga_tags(GoogleAnalyze, router); // 获取Twitter等来源的参数，记录进session，用来上报ga
  });
  
  router.onError((error) => {
    // 路由异步加载出现error：ChunkLoadError: Loading chunk about failed, 重新加载一次页面
    const pattern = /Loading chunk (\S)+ failed/g;
    const isChunkLoadFailed = error.message.match(pattern);
    const targetPath = router.history.pending.fullPath;
    if (isChunkLoadFailed) {
      window.location.reload()
    }
  });
  Common.setHeaderData(); // app 登录信息注入
  // 监听bus事件
  let lock_auth = false;
  eventBus.$on('auth', (msg) => {
    if (!lock_auth) {
      lock_auth = true;
      store.commit('user/IS_LOGIN', false);
      const ignoreLogin = ['spot', 'perpetual', 'virtualPerpetual', 'simulationCurrent', 'simulationFills', 'simulationHistory', 'perpetual_app'];
      if (!ignoreLogin.includes(router.currentRoute.name)) {
        router.push('/login');
        msg && Vue.prototype.$alert(msg);
      } else {
        store.commit('common/IS_RELOAD', false);
        Vue.nextTick(() => {
          store.commit('common/IS_RELOAD', true);
        });
      }
    }
    setTimeout(() => {
      lock_auth = false;
    }, 6000);
  });
  await store.dispatch('user/checkLogin');
  const app = new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
    // components: { App }
  });
  return {
    app,
    router,
    store,
    i18n
  };
}
