const maintenanceMappers = {
  'perpetual': '1',
  'spot': '2',
  'mt5': '3',
  '/mt5': '3',
  'eazyswap': '4',
  '/eazyswap': '4'
};
export const maintenanceList = ['perpetual', 'spot', 'mt5', 'eazyswap'];
export const checkMaintenance = (maintenanceList, routeName) => {
  // eslint-disable-next-line no-unreachable
  for (let key in maintenanceMappers) {
    // let index = routeName === key ;
    // eslint-disable-next-line eqeqeq
    if (routeName === key) {
      for (let maintenance of maintenanceList) {
        // eslint-disable-next-line eqeqeq
        if (maintenance.type == maintenanceMappers[key]) {
          // return maintenance;
          let now = Date.now();
          if (maintenance.beginTime <= now) {
            if (!maintenance.endTime || maintenance.endTime > now) {
              return maintenance;
              // return;
            }
          }
        }
      }
    }
  }
  return null;
};
