import { handleRequest, request } from '../utils/fetch';
const apiConfig = require('../config/api.swap');

// 兑换
export const uniswapExchange = (params) => {
  return handleRequest(request.post(apiConfig.uniswapExchange, params));
};
// 兑换记录
export const uniswapExchangeList = (params) => {
  return handleRequest(request.get(apiConfig.uniswapExchangeList, { params }));
};
// 抵押
export const uniswapMortgage = (params) => {
  return handleRequest(request.post(apiConfig.uniswapMortgage, params));
};
// 抵押记录
export const uniswapMortgageList = (params) => {
  return handleRequest(request.get(apiConfig.uniswapMortgageList, { params }));
};
// 持仓列表(正在抵押)
export const uniswapPositionList = (params) => {
  return handleRequest(request.get(apiConfig.uniswapPositionList, { params }));
};
// 添加新币种
export const uniswapCoinAdd = (params) => {
  return handleRequest(request.post(apiConfig.uniswapCoinAdd, params));
};
// 根据合约地址获取币种
export const uniswapCoinGet = (params) => {
  return handleRequest(request.get(apiConfig.uniswapCoinGet, { params }));
};
// 获取所有币种信息
export const uniswapCoinAll = (params) => {
  return handleRequest(request.get(apiConfig.uniswapCoinAll, { params }));
};
// 获取所有币对信息
export const uniswapContractAll = (params) => {
  return handleRequest(request.get(apiConfig.uniswapContractAll, { params }));
};
// 赎回
export const uniswapRedeem = (params) => {
  return handleRequest(request.post(apiConfig.uniswapRedeem, params));
};
// 获取所有币种资产
export const uniswapAssetsAll = (params) => {
  return handleRequest(request.get(apiConfig.uniswapAssetsAll, { params }));
};
// 获取所有币种资产折合btc
export const uniswapAssetsAllSum = (params) => {
  return handleRequest(request.get(apiConfig.uniswapAssetsAllSum, { params }));
};
// 获取所有币种资产折合usdt
export const uniswapSumAssetsMap = (params) => {
  return handleRequest(request.get(apiConfig.uniswapSumAssetsMap, { params }));
};
// 获取特定币种资产
export const uniswapUserAssetByCode = (params) => {
  return handleRequest(request.get(apiConfig.uniswapUserAssetByCode, { params }));
};
// 获取特定币对资产
export const uniswapUserAssetBySymbol = (params) => {
  return handleRequest(request.get(apiConfig.uniswapUserAssetBySymbol, { params }));
};
// 获取资产流水,分页显示
export const uniswapUserAssetRecord = (params) => {
  return handleRequest(request.get(apiConfig.uniswapUserAssetRecord, { params }));
};
// 资金池
export const uniswapFunPool = (params) => {
  return handleRequest(request.get(`/uniswap/pool/detail/${params.mainChainCode}/${params.tokenCode}`));
};
// 获取所有币对行情
export const uniswapMarketPoolPrices = (params) => {
  return handleRequest(request.post(apiConfig.uniswapMarketPoolPrices, params));
};
// 获取24H交易量
export const uniswapMarketPoolTicker = (params) => {
  return handleRequest(request.post(apiConfig.uniswapMarketPoolTicker, params));
};
// 获取币对Kline
export const uniswapMarketKline = (params) => {
  return handleRequest(request.post(apiConfig.uniswapMarketKline, params));
};
// 获取交易记录
export const uniswapMarketTradeRecord = (params) => {
  return handleRequest(request.post(apiConfig.uniswapMarketTradeRecord, params));
};
// 获取用户竞猜
export const btcPredictionGetGuess = (params) => {
  return handleRequest(request.get(apiConfig.btcPredictionGetGuess, { params }));
};
// 新增或修改用户竞猜
export const btcPredictionPostGuess = (params) => {
  return handleRequest(request.post(apiConfig.btcPredictionPostGuess, params));
};

// 新增或修改用户竞猜(元旦)
export const newYearUpdateGuess = (params) => {
  return handleRequest(request.post(apiConfig.newYearUpdateGuess, params));
};
// 获取用户信息(春节)
export const isConformToActTheSpringFestivalRule = () => {
  return handleRequest(request.get(apiConfig.isConformToActTheSpringFestivalRule));
};
// 是否可以参加春节活动
export const isJoinActTheSpringFestival = () => {
  return handleRequest(request.get(apiConfig.isJoinActTheSpringFestival));
};
// 是否可以参加春节活动
export const isJoinActTheValentineDay = () => {
  return handleRequest(request.get(apiConfig.isJoinActTheValentineDay));
};
// 获取活动列表
export const uniswapActList = () => {
    return handleRequest(request.get(apiConfig.uniswapActList));
};
// 获取宣传列表
export const uniswapBannerList = () => {
  return handleRequest(request.get(apiConfig.uniswapBannerList));
};
// 获取shouyebanner列表
export const uniswapHomwBannerList = (params) => {
  return handleRequest(request.get(apiConfig.uniswapHomwBannerList, {params}));
};
// 参加活动
export const uniswapActJoinSimpleAct = (params) => {
  return handleRequest(request.get(apiConfig.uniswapActJoinSimpleAct, {params}));
};
// 是否参与过
export const uniswapActJHasJoined = (params) => {
  return handleRequest(request.get(apiConfig.uniswapActJHasJoined, {params}));
};
// 进行中的维护列表
export const maintenanceConfigList = () => {
  return handleRequest(request.get(apiConfig.maintenanceConfigList));
};
// 维护详情
export const maintenanceInfo = (id) => {
  return handleRequest(request.get(apiConfig.maintenanceInfo + id));
};
