import { handleRequest, request, setQuery } from '../utils/fetch';
import qs from 'qs';
const apiConfig = require('../config/api.config');

// 获取系统所有数字货币币种信息
export const getDigitalCoinInfos = (params) => {
  return handleRequest(request.post(apiConfig.getDigitalCoinInfos + setQuery(params)));
};

// 获取用户充值地址
export const getChargeAddress = (params) => {
  return handleRequest(request.post(apiConfig.getChargeAddress + setQuery(params)));
};

// 获取用户充值地址2
export const getChargeAddressV2 = (params) => {
  return handleRequest(request.post(apiConfig.getChargeAddressV2 + setQuery(params)));
};

// 校验用户充值地址
export const checkChargeAddress = (params) => {
  return handleRequest(request.post(apiConfig.checkChargeAddress + setQuery(params)));
};

// 获取用户充值记录
export const getChargeRecords = (params) => {
  return handleRequest(request.post(apiConfig.getChargeRecords + setQuery(params)));
};

// 获取txid的url
export const getCoinChainUrlAddress = (params) => {
  return handleRequest(request.post(apiConfig.getCoinChainUrlAddress + setQuery(params)));
};

// lvt共生币充值
export const addChargeRecord = (params) => {
  return handleRequest(request.post(apiConfig.addChargeRecord + setQuery(params)));
};
// grin充值
export const submitRechargeForm = (params) => {
  return handleRequest(request.post(apiConfig.submitRechargeForm + setQuery(params)));
};

// lvt共生币的撤单操作
export const cancleChargeRecord = (params) => {
  return handleRequest(request.post(apiConfig.cancleChargeRecord + setQuery(params)));
};

// 获取用户lvt充值记录
export const getOtherChargeRecord = (params) => {
  return handleRequest(request.post(apiConfig.getOtherChargeRecord + setQuery(params)));
};

// 获取提现地址列表
export const listPickUpAdress = (params) => {
  return handleRequest(request.post(apiConfig.listPickUpAdress + setQuery(params)));
};

// 获取币种相关的业务参数
export const getCoinConfigInfo = (params) => {
  return handleRequest(request.post(apiConfig.getCoinConfigInfo + setQuery(params)));
};

// 获取币种链类型
export const getCurrencyLineTypes = (params) => {
  return handleRequest(request.post(apiConfig.getCurrencyLineTypes + setQuery(params)));
};

// 添加提现地址
export const applyAddPickUpAdressV2 = (params) => {
  return handleRequest(request.post(apiConfig.applyAddPickUpAdressV2, qs.stringify(params), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }));
};

// 删除提现地址
export const deletePickUpAdress = (params) => {
  return handleRequest(request.post(apiConfig.deletePickUpAdress + setQuery(params)));
};

// 地址簿提现
export const applySimplePickUpV2 = (params) => {
  return handleRequest(request.post(apiConfig.applySimplePickUpV2 + setQuery(params)));
};

// 新地址提现
export const applySimplePickUpV3 = (params) => {
  return handleRequest(request.post(apiConfig.applySimplePickUpV3 + setQuery(params)));
};

// 撤销提现申请
export const canclePickUp = (params) => {
  return handleRequest(request.post(apiConfig.canclePickUp + setQuery(params)));
};

// 获取用户提现记录信息
export const getWithDrawRecords = (params) => {
  return handleRequest(request.post(apiConfig.getWithDrawRecords + setQuery(params)));
};

// 新增提币地址发邮件验证码
export const sendEmailCode4AddAddress = (params) => {
  return handleRequest(request.post(apiConfig.sendEmailCode4AddAddress + setQuery(params)));
};
// 新增提币地址发邮件验证码
export const sendEmailCode4PickUp = (params) => {
  return handleRequest(request.post(apiConfig.sendEmailCode4PickUp + setQuery(params)));
};
// 新增提币地址发手机验证码
export const sendSMSCode4PickUp = (params) => {
  return handleRequest(request.post(apiConfig.sendSMSCode4PickUp + setQuery(params)));
};
// 新增提币地址提交验证码
export const confirmApplySimplePickUpV3 = (params) => {
  return handleRequest(request.post(apiConfig.confirmApplySimplePickUpV3 + setQuery(params)));
};
// 新增提币地址提交验证码
export const getFioAddress = (params) => {
  return handleRequest(request.post(apiConfig.getFioAddress + setQuery(params)));
};


// 获取vip等级
export const getVipLevel = (params) => {
  return handleRequest(request.get(apiConfig.getVipLevel + setQuery(params)));
};

// 是否显示弹窗
export const showVipLevel = (params) => {
  return handleRequest(request.get(apiConfig.showVipLevel + setQuery(params)));
};