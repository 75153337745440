import CN from '../locales/errorCode/cn';
import EN from '../locales/errorCode/en';
import KR from '../locales/errorCode/kr';
import RU from '../locales/errorCode/ru';
import JP from '../locales/errorCode/jp';
import VN from '../locales/errorCode/vn';
import PT from '../locales/errorCode/pt';
import ptBr from '../locales/errorCode/pt-br';
import ES from '../locales/errorCode/es';
import TR from '../locales/errorCode/tr';
import HK from '../locales/errorCode/hk';

export function errorCode (code, msg, lang) {
  if (lang === 'pt-br') {
    lang = 'ptBr';
  }
  const langObj = Object.assign({}, CN, EN, KR, RU, JP, VN, PT, ptBr, ES, TR, HK);
  if (langObj[lang][code] || langObj['en'][code]) {
    return langObj[lang][code] || langObj['en'][code];
  } else {
    return '';
  }
};
