export default {es:{
  "BACK_UF019": "¡La cuenta del usuario fue congelada!",
  "CT102": "El negocio correspondiente está cerrado",
  "CT103": "El precio ejecutado está fuera del rango permitido de precios",
  "CT104": "El usuario no tiene suficientes activos disponibles",
  "CT105": "Pedido no existe",
  "CT106": "El monto del pedido supera el límite.",
  "CT107": "El pedido del usuario es menor a la cantidad mínima para un pedido",
  "CT108": "Usuario congelado",
  "CT110": "Límite máximo de compra excedido",
  "CT801": "El usuario no ha vinculado su teléfono",
  "CT804": "El usuario ha vinculado el teléfono",
  "CT805": "Ese número de teléfono ya está en uso",
  "CT806": "El teléfono vinculado para la información provisoria no existe",
  "CT807": "La comprobación del código de verificación por SMS superó el número de restricciones",
  "CT809": "Error de código de verificación móvil",
  "CT810": "No se ha establecido ningún certificado de seguridad",
  "CT811": "Desvincular clave privada de Google",
  "CT812": "Error de código de verificación de Google",
  "CT822": "El usuario ya ha vinculado la clave privada de Google",
  "CT823": "no existe",
  "CT824": "No puede ser modificado",
  "CT825": "Sin cambios",
  "CT826": "El usuario ha configurado autenticación de seguridad",
  "CT902": "no existe",
  "FC101": "libro de pedidos detenido",
  "FC102": "la cuenta está bloqueada",
  "FC103": "precio excede el límite",
  "FC104": "monto excede el límite",
  "FC105": "subFund insuficiente",
  "FC106": "userStore insuficiente",
  "FC108": "fallo en cancelación de pedido",
  "FC109": "el monto es muy pequeño",
  "FC999": "prohibir transferencia",
  "PB100000": "Activo incorrecto",
  "PB100001": "Activo insuficiente",
  "PB100002": "Al menos 100 para reservar",
  "PB100003": "Precio de reserva incorrecto",
  "PB100004": "Token incorrecto",
  "PB100005": "El precio ha cambiado, vuelva a realizar el pedido",
  "PB100006": "Dinero incorrecto para la reserva",
  "PB110000": "No es período de compra",
  "RT101": "El formato del parámetro es incorrecto",
  "RT102": "Registro no existe",
  "RT103": "Error de configuración del sistema",
  "RT104": "El entorno del sistema es anormal",
  "RT301": "Error en el servidor",
  "RT302": "Error en el servidor",
  "RT303": "Error en el servidor",
  "RT304": "Error en el servidor",
  "RT398": "Error en el servidor",
  "RT399": "Error en el servidor",
  "RT999": "Excepción no definida",
  "SP801": "No se puede cambiar de",
  "SP802": "Añada primero applyForm",
  "SP803": "No se puede editar la información de configuración",
  "SP804": "Pague la tarifa de depósito",
  "SP805": "Fondos insuficientes",
  "SP806": "Tarifa paga",
  "SP807": "No existe información de configuración",
  "UF001": "Los campos de datos no pueden quedar vacíos",
  "UF002": "La dirección de retiro no puede excederse de 5.",
  "UF003": "la dirección tiene salidas",
  "UF015": "¡El usuario ha sido congelado!",
  "UF016": "pickupAdress no existe",
  "UF017": "¡La pickupAdress no pertenece al usuario!",
  "UF020": "¡Límite diario superado！",
  "UF021": "¡Actividades con fondos insuficientes!",
  "UF022": "¡Hay un registro de recarga que aún está sin confirmar!",
  "UF030": "no se pudo agregar la dirección, verifique la conexión a la red y vuelva a intentarlo.",
  "UF036": "La cantidad es menor que el límite mínimo",
  "UF037": "El monto del retiro está por debajo del límite mínimo",
  "UF101": "Error de formato TXID, vuelva a ingresar",
  "UF102": "Ya ha hecho 5 solicitudes en una hora, inténtelo nuevamente más tarde",
  "UF108": "Deposite con la dirección que ha enviado",
  "UF301": "El último precio ha cambiado. Pida prestado nuevamente.",
  "UF302": "Fondos insuficientes",
  "UF303": "Monto no permitido",
  "UF304": "La cantidad disponible de préstamos ha alcanzado el límite",
  "UF305": "Actualmente, la devolución no se encuentra disponible.",
  "UF307": "Se ha alcanzado el límite de préstamo para el usuario",
  "UF308": "Los participantes del evento han alcanzado el límite",
  "UF312": "El préstamo y la devolución no están disponibles debido a la liquidación forzada",
  "UF313": "El importe de devolución es mayor que el importe de devolución requerido",
  "US601": "Hay un error en el formato del correo electrónico",
  "US604": "¡El tamaño del archivo excede el límite de “n”!",
  "US605": "El formato del archivo es incorrecto",
  "US607": "La foto de la tarjeta de identidad esta vacía",
  "US608": "La foto del pasaporte esta vacía",
  "US609": "Realice primero la verificación de ID (KYC)",
  "US651": "La información de la API no pertenece al usuario",
  "US660": "Error en el código de verificación por imágenes",
  "US701": "¡El correo electrónico ha sido registrado!",
  "US702": "El código de verificación ha caducado",
  "US703": "¡El captcha del correo electrónico tiene un error!",
  "US704": "Ese usuario no existe",
  "US705": "Su cuenta está bloqueada. Póngase en contacto con el servicio en línea.",
  "US706": "Su cuenta está bloqueada, inténtelo nuevamente tras 1 hora.",
  "US707": "Ha ingresado el código de verificación con errores 5 veces, obtenga un nuevo código de verificación.",
  "US708": "Correo electrónico no registrado.",
  "US709": "Correo electrónico o contraseña incorrectos.",
  "US710": "¡El teléfono ha sido registrado!",
  "US711": "¡Ese smsCaptcha no existe!",
  "US712": "usuario o contraseña incorrectos",
  "US724": "Frecuencia sobrepasada, inténtelo nuevamente más tarde.",
  "US725": "Nueva verificación de ubicación ha fallado",
  "US726": "Frecuencia sobrepasada, inténtelo nuevamente más tarde.",
  "US727": "Frecuencia sobrepasada, inténtelo nuevamente más tarde.",
  "US732": "Ha ingresado la contraseña de manera incorrecta seis veces, inténtelo nuevamente tras cinco minutos",
  "US750": "¡El usuario tenía un correo electrónico vinculado!",
  "US801": "El usuario no ha vinculado su teléfono",
  "US802": "¡Tiene 30 segundos para enviar el SMS la próxima vez!",
  "US803": "El error del código de SMS excede el plazo máximo. Vuelva a intentarlo tras 1 hora.",
  "US804": "¡El usuario ha vinculado el teléfono!",
  "US805": "El número de teléfono ha sido utilizado.",
  "US806": "¡BindPhoneTempInfo no existe!",
  "US807": "Ha ingresado el código de verificación con errores 5 veces, obtenga un nuevo código de verificación.",
  "US809": "Ingrese el código de SMS correctamente.",
  "US810": "¡No se ha establecido securityAuthenticate!",
  "US811": "¡No se ha vinculado ninguna googlePrivateKey!",
  "US812": "Ingrese el Token Google 2FA correctamente.",
  "US813": "¡ModifyPhoneOldPhoneTempInfo no existe!",
  "US814": "¡ModifyPhoneNewPhoneTempInfo no existe!",
  "US815": "El error del código de SMS excede los tiempos máximos. Vuelva a intentarlo tras 1 hora.",
  "US816": "Ingrese el código de SMS correctamente.",
  "US817": "Ingrese el antiguo código de SMS correctamente.",
  "US818": "El error del código de SMS excede los tiempos máximos. Vuelva a intentarlo tras 1 hora.",
  "US819": "Ingrese el código de SMS correctamente.",
  "US820": "Ingrese el nuevo código de SMS correctamente.",
  "US821": "¡UnBindPhoneTempInfo no existe!",
  "US822": "¡El usuario ha vinculado la googlePrivateKey!",
  "US823": "¡El [securityAuthenticationName] no existe!",
  "US824": "¡El [securityAuthenticationName] no puede cambiar!",
  "US825": "¡El [securityAuthenticationName] no ha sido modificado!",
  "US826": "¡El usuario ha establecido securityAuthentication!",
  "US829": "¡El identificador no es válido!",
  "US830": "La cantidad de claves de API excede el límite",
  "US832": "No existe código de verificación o el mismo ha caducado",
  "US833": "Código de verificación incorrecto",
  "US834": "El usuario no ha vinculado un correo electrónico",
  "US857": "Ha ingresado el código del correo electrónico con errores más de 5 veces. Vuelva a enviar el código",
  "US901": "¡El [notificationName] no existe!",
  "US902": "¡El estado de la notificación no ha cambiado!",
  "UW205": "Lo sentimos, su activo se ha congelado. Si tiene alguna pregunta, comuníquese con el servicio en línea.",
  "UW208": "La confirmación por correo electrónico ha fallado, compruebe la conexión a la red y vuelva a intentarlo",
  "UW209": "El envío del correo electrónico ha fallado, haga clic en \"Reenviar\" para enviarlo nuevamente",
  "UW210": "La confirmación por correo electrónico ha caducado, vuelva a enviar su solicitud",
  "UW213": "Asegúrese de haber hecho clic en el último enlace enviado por correo electrónico dentro de la última hora y, en caso de exceder 1 hora, vuelva a enviar su solicitud.",
  "UW236": "Desvinculando seguridad, ¡Verifique primero la seguridad!",
  "WE101": "El usuario no ha iniciado sesión",
  "WE102": "El usuario no tiene los permisos requeridos",
  "WE103": "La frecuencia de acceso del usuario es demasiado alta",
  "WE104": "La frecuencia de acceso del usuario es demasiado alta",
  "WE204": "El usuario ha sido congelado",
  "WE205": "El usuario ha sido congelado",
  "WE206": "Límite diario excedido",
  "WE207": "No hay fondos suficientes para las actividades",
  "WE209": "Hubo un fallo con el correo electrónico enviado",
  "WE218": "El usuario no ha realizado la autenticación",
  "WE219": "Aún no tiene un nombre real"
}}