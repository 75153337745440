<template>
  <i class="icon-loading" :style="[{'width': width, 'height': height}]">
    <img v-if="showColor === 'white'" src="../../assets/img/new/loading_black.svg" alt="" title="black">
    <img v-if="showColor === 'black'" src="../../assets/img/new/loading_white.svg" alt="" title="white">
  </i>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'Loading',
  props: [
    'width',
    'height',
    'showTheme'
  ],
  data () {
    return {
    };
  },
  computed: {
    ...mapState({
      theme: state => state.common.theme
    }),
    showColor () {
      return this.showTheme ? this.showTheme : this.theme;
    }
  }
};
</script>

<style lang="scss" scoped>

.icon-loading {
  display: inline-block;
  width: 30px;
  height: 30px;
  img {
    width: 100%;
    height: 100%;
  }
}

</style>

