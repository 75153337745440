<template>
  <div class="tradeSelect" v-click-outside="hide">
    <!--<div class="selectedVal ripple-effect"
      autocomplete="false"
      disabled
      :placeholder="placeholderTxt"
      @click="toggleItemsShow"
    >{{selectedVal || placeholderTxt}}</div>-->
    <div class="input-box">
      <svg class="search-icon" aria-hidden="true" v-if="hasSearch">
        <use xlink:href='#bf-icon_investigation'></use>
      </svg>
      <input type="text" :disabled="selectDisable" v-model="query" class="input" @focus="showDrop = true" :class="{hasSearch: hasSearch}" :readonly="!hasSearch" :placeholder="innerPlaceholder">
      <!--<i ref="r-arr" class="arrow" @click="toggleItemsShow"></i>-->
      <svg class="arrow" aria-hidden="true" :class="{active:showDrop}" @click="showDrop=!showDrop">
        <use xlink:href='#bf-icon_cutover'></use>
      </svg>
    </div>

    <div v-show="showDrop">
      <ul class="selectDrop" v-if="isArray">
        <li v-for="(item,index) in filterData" @click="setValue(item)" class="selectItems ripple-effect" :key="index">{{getText(item)}}</li>
      </ul>
      <ul class="selectDrop" v-else>
        <li v-if="filterData['']" @click="setValue(filterData[''],'')" class="selectItems ripple-effect" :key="filterData['']">{{getText(filterData[''])}}</li>
        <!-- <li v-for="(value,key) in filterData" @click="setValue(value,key)" class="selectItems ripple-effect" :key="value">{{getText(value)}}</li> -->
        <template v-for="(value,key) in filterData">
          <li @click="setValue(value,key)" class="selectItems ripple-effect" :key="key" v-if="key!==''">{{getText(value)}}</li>
        </template>
      </ul>
    </div>
  </div>
</template>
<script>
import { debounce } from '~/utils/util';
import { mapState } from 'vuex';
export default {
  name: 'TradeSelect',
  data () {
    return {
      query: '',
      showDrop: false,
      innerPlaceholder: '',
      filterFn: null,
      _t: null
    };
  },
  props: {
    placeholder: {
      type: String
    },
    data: {
      type: [Array, Object]
    },
    name: {
      type: String,
      default: ''
    },
    value: {
      require: true
    },
    hasSearch: {
      type: Boolean,
      default: false
    },
    selectDisable: {
      type: Boolean,
      default: false
    },
    filter: {
      type: Function
    }
  },
  computed: {
    ...mapState({
      locale: state => state.common.locale
    }),
    filterData () {
      if (!this.data) {
        return [];
      }
      if (this.hasSearch && this.query) {
        if (this.isArray) {
          return this.data.filter(item => {
            if (this.filter) {
              return this.filter(item, this.query.toLocaleLowerCase());
            }
            const text = this.getText(item).toLocaleLowerCase();
            return text.includes(this.query.toLocaleLowerCase());
          });
        } else {
          let _midData = {};
          Object.keys(this.data).forEach(key => {
            if (this.filter) {
              this.filter(this.data[key], this.query.toLocaleLowerCase()) && (_midData[key] = this.data[key]);
            } else {
              const text = this.getText(this.data[key]).toLocaleLowerCase();
              if (text.includes(this.query.toLocaleLowerCase())) {
                _midData[key] = this.data[key];
              }
            }
          });
          return _midData;
        }
      }
      return this.data;
    },
    defaultPlace () {
      return this.$t('form_39');
    },
    /* filterFn(){
      if(this.isArray){
        return (array,query)=>array.filter(item => {
          const text = this.getText(item).toLocaleLowerCase();
          return text.includes(query.toLocaleLowerCase());
        })
      }else {
        return (obj,query)=>{
          let _midData = {};
          Object.keys(obj).forEach(key => {
            const text = this.getText(obj[key]).toLocaleLowerCase();
            if (text.includes(query.toLocaleLowerCase())) {
              _midData[key] = obj[key];
            }
          });
          return _midData;
        }
      }
    }, */
    isArray () {
      return Object.prototype.toString.apply(this.data) === '[object Array]';
    }
  },
  created () {
  },
  methods: {
    toggleItemsShow () {
      this.isShowItems = !this.isShowItems;
    },
    changeConditionCall () {
      debounce(() => {
        this.showData = this.filterName(this.searchCondition, this.data);
      }, 200)();
    },
    hide () {
      this.showDrop = false;
    },
    isEmpty (obj) {
      for (var key in obj) {
        return false;
      }
      return true;
    },
    getText (item) {
      return typeof item === 'object' ? this.name && item[this.name] : item;
    },
    getSelectItem (value) {
      if (this.isArray) {
        return this.data.filter(item => {
          const val = this.getText(item);
          return val !== undefined && val.toString().toLocaleLowerCase() === value.toString().toLocaleLowerCase();
        })[0];
      } else {
        return this.getText(this.data[value]);
      }
    },
    queryChange () {
      // this
    },
    /* filterFn(){

    }, */
    setValue (item, key) {
      this.showDrop = false;
      const text = this.getText(item);
      // this.innerPlaceholder = text;
      this.$emit('input', key === undefined ? text : key, item);
      this.query = '';
    }
    // blurSelect () {
    //   clearTimeout(this._t);
    //   this._t = setTimeout(() => {
    //     this.showDrop = false;
    //     if (this.query !== '') {
    //       this.query = '';
    //       // this.innerPlaceholder = this.placeholder;
    //     }
    //   }, 250);
    // }
  },
  watch: {
    data: {
      handler (n, o) {
        const val = this.getSelectItem(this.value);
        this.innerPlaceholder = val === undefined ? this.placeholder || this.$t('form_39') : (this.name ? val[this.name] : val);
      },
      deep: true
      // immediate: true
    },
    locale () {
      this.innerPlaceholder = this.$t('form_39');
    },
    value: {
      handler (n, o) {
        const val = this.getSelectItem(n);
        this.innerPlaceholder = val === undefined ? this.placeholder || this.$t('form_39') : (this.name ? val[this.name] : val);
      },
      immediate: true
    },
    placeholder (n) {
      if (n) {
        this.innerPlaceholder = n;
      }
    }
  }
};
</script>
<style lang="scss" scoped>

@import "./theme-default.scss";

</style>

