module.exports = {
  cappsSumAssets: '/server/dappsFund.act?cmd=staticUserSumAsset', // 得到用户的资产汇总
  getCappsAllUserAsset: '/server/dappsFund.act?cmd=getAllUserAsset', // 获取用户所有币种资产
  getUserCappsAssetByCoin: '/server/dappsFund.act?cmd=getUserAssetInfo', // 获取用户某个币种资产信息
  getUserCappsActiveAssetByCoin: '/server/dappsFund.act?cmd=getUserActiveAsset', // 根据币种获取可用资产
  getUserCappsAssetChangeRecords: '/server/dappsFund.act?cmd=getUserAssetChangeRecords', // 获取用户资产变更记录
  getUserCappsPayOrders: '/server/dappsOrder.act?cmd=getPayOrders', // capps订单列表
  getAllAppAccountListNoLang: '/server/dappsAccount.act?cmd=getAllAppAccountListNoLang', // 获取项目方名称（全部）
  getAppAccountListNoLang: '/server/dappsAccount.act?cmd=getAppAccountListNoLang', // 获取项目方名称（上线和预上线）

  getCappUpdateLogin: '/server/dappsLogin.act?cmd=updateLogin', // 增加登录日志
  getCappUpdateAuthorize: '/server/dappsFund.act?cmd=updateAuthorize', // 增加授权日志

  getAppAccountByClientId: '/server/dappsAccount.act?cmd=getAppAccountByClientId', // 获取单个项目方信息
  getCappAccount: '/server/dappsAccount.act?cmd=getAppAccount', // 获取项目方信息
  getCappAccountList: '/server/dappsAccount.act?cmd=getAppAccountList', // 根据语言获取所有项目方信息
  /* type 0-金融、1-信息、2-游戏
  state 0-未上架、1-上架、2-下架
  lang cn, hk,en,kr,jp,ru,vn,pt,es,tr */
  getCappAccountListByType: '/server/dappsAccount.act?cmd=getAppAccountListByType', // 根据类型和语言获取所有项目方信息
  getCappAllAppAccountUserStatistic: '/server/dappsAccount.act?cmd=getAllAppAccountUserStatistic', // 获取所有项目方用户统计数据
  getCappTotalAppAccountUserStatistic: '/server/dappsAccount.act?cmd=getTotalAppAccountUserStatistic', // 获取总用户数据统计

  postCappOrderInfo: '/plaweb/transfer/info', // 取得当前支付信息接口
  postCappOrderPay: '/plaweb/transfer/tran'// 转账支付动作接口
};
