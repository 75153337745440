import Rules from './rules';
function validate (value, rules, callback) {
  let message;
  let asyncResult;
  let result = rules.length ? rules.every(rule => {
    let bool = true;
    if (rule.min || rule.max) {
      bool = Rules['rand'].rule(value, rule.min, rule.max);
      bool || (message = (rule.message || Rules['rand'].message).replace(/\$min/g, rule.min).replace(/\$max/g, rule.max));
      return bool;
    }
    message = rule.message || (Rules[rule.type] && Rules[rule.type].message);
    if (rule.type) {
      return Rules[rule.type].rule(value);
    }
    if (rule.validator) {
      return rule.validator(value);
    }
    if (rule.asyncValidate) {
      asyncResult = rule.asyncValidate(value, message);
      return bool;
    }
  }) : true;
  const obj = {
    result,
    asyncResult,
    message
  };
  if (callback) {
    callback(obj);
  }
}
export default validate;
