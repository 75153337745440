<template>
  <li
    @click.stop="selectOption"
    class=""
    v-show="visible">
    <slot>
      <span>{{ label }}</span>
    </slot>
  </li>
</template>

<script>
  export default {
    name: 'vueOption',
    inject: ['select'],
    props: {
      value: {
        require: true
      },
      label: {
        require: true
      }
    },
    data () {
      return {
        visible: true
      };
    },
    methods: {
      selectOption () {
        // this.$emit('changeValue', this.value);
        this.select.changeValue(this.value);
      }
    }
  };
</script>

<style scoped>



</style>
