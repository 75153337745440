import JSEncrypt from 'jsencrypt'

let priKey = 'MIICdwIBADANBgkqhkiG9w0BAQEFAASCAmEwggJdAgEAAoGBAKYHJnEeqp/sK0yAjRu52Cj662NUQyLMhs0Yb7lMFUGhNL3QuYna6cdxVhtgWJNu+du6/DUbMNr+SHYklYPpHlYKm1B92Srk/QguTB0gws5Q6e3Byzp/APARnKvgGX2Fg+bY0W+hEv4KM+Cb6TRof4yfnuZA7uhd1hCqm7FuzyR9AgMBAAECgYB0KLSAmFAQK4/vqlEPgOr3YPanPCDsi+QUQnCIV+7XSA46b3ogNQUzANyZssLGA/LUBPLHxQpdbWMNJXNmIweRUAWHDXQ16HMHTxltYmb1n5nty5mBSSa4Av6Y9Fo9crAWjo0eSD2sacYbgAAm5A3G51gzXnw5Y81TVN/U51dNEQJBAOyyaxDnFyM9JT79CSzwmrv920ctF5kHapuNC08z55cZQQ783H0agNmZL6dvOzirA3cUHeMdlV+JV9aYlOIa63sCQQCzkVtqP1oZ6fZxFIqKRlTdkrBXKEJfZc5j6YzaIVw9n0dlRJdPI9sDQTgfiTArOdfDZvfaagk6bTLXheeDl1JnAkEA2f/ZrE+qd8IxM7pJYSKY4NwFwaA+Q6sY/PsCl4funLOdv/gT+wUV91OnGEbR/HdFUb8tZ6gvkzYjuHn/Zd/1UQJAHQWBIMrcZRPLILuRKIf1b1ol5fA7ye9KTBuTCC4cGlmD0HolekTxQLVpvK8Peez/UjVyGXrJwwejD/I0WLA8QQJBAOxlap6MyfiQmiXmLbMDlxFDAi0QfXbG9uc7cXb/sAIwbKugzwBCmNEV2XdN5VY+K8cGGUrLxSmKCJxacazLCBc='

let RSA = {
    encryption(pubKey, str) {
        let encrypt = new JSEncrypt();
        encrypt.setPublicKey(pubKey);
        let encrypted = encrypt.encrypt(str);
        return encrypted
    },
    decryption(str) {
        let decrypt = new JSEncrypt();
        decrypt.setPrivateKey(priKey);
        let uncrypted = decrypt.decrypt(str);
        return uncrypted;
       
    }
}
export default RSA;

