const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import( /* webpackChunkName: "home" */ '../pages/home/index.vue')
  },
  {
      path: '/starter',
      name: 'starter',
      component: () => import('../pages/nftMarket/layout.vue'),
      children: [{
          path: 'index',
          name: 'starterIndex',
          meta: {
            gaTitle: 'Turbo IEO',
          },
          component: () => import('../pages/turbo/index.vue')
        },
        {
          path: '/starter',
          // name: 'starterdetail',
          component: () => import('../pages/turbo/detail/index.vue'),
          redirect: '/starter/btc_usdt',
          children: [{
            path: ':symbol/:id?',
            name: 'starterdetail'
          }]
        }
      ]
  },
  {
    path: '/academy',
    name: 'academys',
    component: () => import( /* webpackChunkName: "academys" */ '../pages/academy/index.vue'),
    children: [
      {
        path: '',
        name: 'categories-index',
        component: () => import( /* webpackChunkName: "academys" */ '../pages/academy/academys.vue')
      },
      {
        path: ':categories',
        name: 'categories-item',
        component: () => import( /* webpackChunkName: "categories" */ '../pages/academy/categories/index.vue'),
        children: [
          {
            path: '',
            name: 'url-index',
            component: () => import( /* webpackChunkName: "categories" */ '../pages/academy/categories/categories.vue')
          },
          {
            path: ':url',
            name: 'url-item',
            component: () => import( /* webpackChunkName: "url" */ '../pages/academy/categories/url/url.vue')
          }
        ]
      }
    ]
  },
  // {
  //   path: '/buyCoins',
  //   name: 'buyCoins',
  //   meta: {
  //     gaTitle: '买币',
  //     requiresAuth: false
  //   },
  //   // redirect: '/buyCoins/mercuryo',
  //   component: () => import( /* webpackChunkName: "userCenter" */ '../pages/buyCoins/buyCoins.vue'),
  //   children: [{
  //     path: 'mercuryo',
  //     name: 'mercuryo',
  //     component: () => import( /* webpackChunkName: "userMyAssets" */ '../pages/buyCoins/components/Mercuryo.vue')
  //   },
  //   {
  //     path: 'paxful',
  //     name: 'paxful',
  //     component: () => import( /* webpackChunkName: "userMyAssets" */ '../pages/buyCoins/components/Paxful.vue')
  //   },
  //   {
  //     path: 'advCash',
  //     name: 'advCash',
  //     component: () => import( /* webpackChunkName: "userMyAssets" */ '../pages/buyCoins/components/AdvCash.vue')
  //   },
  //   {
  //     path: 'banxa',
  //     name: 'banxa',
  //     component: () => import( /* webpackChunkName: "userMyAssets" */ '../pages/buyCoins/components/Banxa.vue')
  //   },
  //   {
  //     path: 'onmeta',
  //     name: 'onmeta',
  //     component: () => import( /* webpackChunkName: "userMyAssets" */ '../pages/buyCoins/components/Onmeta.vue')
  //   },
  //     {
  //       path: 'coinify',
  //       name: 'coinify',
  //       component: () => import( /* webpackChunkName: "userMyAssets" */ '../pages/buyCoins/components/Coinfy.vue')
  //     },
  //     {
  //       path: 'moonpay',
  //       name: 'moonpay',
  //       component: () => import( /* webpackChunkName: "userMyAssets" */ '../pages/buyCoins/components/MoonPay.vue')
  //     },
  //     {
  //       path: 'moonpaySale',
  //       name: 'moonpaySale',
  //       component: () => import( /* webpackChunkName: "userMyAssets" */ '../pages/buyCoins/components/MoonPaySale.vue')
  //     }
  //   ]
  // },
  {
    path: '/buyCoinsNew',
    name: 'buyCoinsNew',
    component: () => import( /* webpackChunkName: "buyCoinsNew" */ '../pages/buyCoinsNew/index.vue')
  },
  {
    path: '/deviceList',
    name: 'deviceList',
    component: () => import( /* webpackChunkName: "paymentCards" */ '../pages/user/security/deviceList/index.vue')
  },
  {
    path: '/loginList',
    name: 'loginList',
    component: () => import( /* webpackChunkName: "paymentCards" */ '../pages/user/security/loginList/index.vue')
  },
    {
      path: '/userCenter',
      name: 'userCenter',
      meta: {
        requiresAuth: true
      },
      redirect: '/userCenter/userMyAssets',
      component: () => import( /* webpackChunkName: "userCenter" */ '../pages/userCenter/index.vue'),
      children: [{
        path: 'userMyAssets',
        name: 'userMyAssets',
        component: () => import( /* webpackChunkName: "userMyAssets" */ '../pages/userCenter/myAssets/myAssets.vue')
      },
      // {
      //   path: 'assets/earn',
      //   name: 'assetsEarn',
      //   component: () => import( /* webpackChunkName: "assetsEarn" */ '../pages/userCenter/assets/earn.vue')
      // },
      {
        path: 'assets/:tid',
        name: 'assets',
        component: () => import( /* webpackChunkName: "assets" */ '../pages/userCenter/assets/index.vue')
      },
      {
        path: 'userSafeCenter',
        name: 'userSafeCenter',
        component: () => import( /* webpackChunkName: "userSafeCenter" */ '../pages/user/security/index.vue'),
        redirect: 'userSafeCenter/security',
          children: [
            {
              path: 'security',
              name: 'security',
              component: () => import( /* webpackChunkName: "userRecharge" */ '../pages/user/security/security/index.vue')
            }
          ]
        },
        {
          path: 'userAuthentication',
          name: 'userAuthentication',
          component: () => import( /* webpackChunkName: "userAuth" */ '../pages/userCenter/auth/auth.vue')
        },
        {
          path: 'userAuthDetail',
          name: 'userAuthDetail',
          component: () => import( /* webpackChunkName: "userAuth" */ '../pages/userCenter/auth/detail.vue')
        },
        {
          path: 'enterpriseCertification',
          name: 'enterpriseCertification',
          component: () => import( /* webpackChunkName: "userAuth" */ '../pages/userCenter/auth/firm.vue')
        },
        // {
        //   path: 'userApi',
        //   name: 'userApi',
        //   component: () => import( /* webpackChunkName: "userApi" */ '../pages/user/api/api.vue')
        // },
        {
          path: 'userRecharge',
          name: 'userRecharge',
          component: () => import( /* webpackChunkName: "userRecharge" */ '../pages/userCenter/recharge/index.vue'),
          redirect: 'userRecharge/tokens',
          children: [
            {
              path: 'tokens',
              name: 'tokens',
              component: () => import( /* webpackChunkName: "userRecharge" */ '../pages/userCenter/recharge/recharge.vue')
            },
            {
              path: 'paymentCards',
              name: 'paymentCards',
              component: () => import( /* webpackChunkName: "paymentCards" */ '../pages/userCenter/recharge/paymentCards/paymentCards.vue')
            }
          ]
        },
        // {
        //   path: 'userBfToken',
        //   name: 'userBfToken',
        //   component: () => import( /* webpackChunkName: "userBfToken" */ '../pages/userCenter/bfToken/bfToken.vue')
        // },
        {
          path: 'userWithdraw',
          name: 'userWithdraw',
          component: () => import( /* webpackChunkName: "userWithdraw" */ '../pages/userCenter/withdraw/withdraw.vue')
        },
        {
          path: 'userSaleCoin',
          name: 'userSaleCoin',
          component: () => import( /* webpackChunkName: "userWithdraw" */ '../pages/userCenter/salecoin/salecoin.vue')
        },
        {
          path: 'userTransfer',
          name: 'userTransfer',
          component: () => import( /* webpackChunkName: "userTransfer" */ '../pages/user/transfer/transfer.vue')
        }
        // {
        //   path: 'userMt5Account',
        //   name: 'userMt5Account',
        //   component: () => import( /* webpackChunkName: "userMt5Account" */ '../pages/user/mt5account2/mt5account.vue')
        // }
      ]
    },
    {
      path: '/giftVoucher',
      name: 'giftVoucher',
      component: () => import( '../pages/activity/giftVoucher/giftVoucher.vue')
    },
    {
      path: '/worldCup',
      name: 'worldCup',
      meta: {
        requiresAuth: false
      },
      redirect: '/worldCup/game/group_match',
      component: () => import( /* webpackChunkName: "activity" */ '../pages/activity/worldCup/index.vue'),
      children: [
        {
          path: 'game/:id',
          name: 'game',
          component: () => import( /* webpackChunkName: "activity" */ '../pages/activity/worldCup/game.vue')
        },
        {
          path: 'champion',
          name: 'champion',
          component: () => import( /* webpackChunkName: "activity" */ '../pages/activity/worldCup/champion.vue')
        },
        {
          path: 'record',
          name: 'record',
          component: () => import( /* webpackChunkName: "activity" */ '../pages/activity/worldCup/record.vue')
        }
      ]
    },
    {
      path: '/officialverify',
      name: 'officialverify',
      component: () => import( /* webpackChunkName: "mediaQuery" */ '../pages/activity/mediaQuery/query.vue')
    },
    {
      path: '/mercuryoCard',
      name: 'mercuryoCard',
      component: () => import( /* webpackChunkName: "mercuryoCard" */ '../pages/userCenter/recharge/mercuryoCard/mercuryoCard.vue')
    },
    {
      path: '/coinifyCard',
      name: 'coinifyCard',
      component: () => import( /* webpackChunkName: "coinifyCard" */ '../pages/userCenter/recharge/coinifyCard/coinifyCard.vue')
    },
    {
      path: '/moonPayCard',
      name: 'moonPayCard',
      component: () => import( /* webpackChunkName: "moonPayCard" */ '../pages/userCenter/recharge/moonPayCard/moonPayCard.vue')
    },
    {
      path: '/banxaCard',
      name: 'banxaCard',
      component: () => import( /* webpackChunkName: "banxaCard" */ '../pages/userCenter/recharge/banxaCard/banxaCard.vue')
    },
    {
      path: '/onmetaCard',
      name: 'onmetaCard',
      component: () => import( /* webpackChunkName: "onmetaCard" */ '../pages/userCenter/recharge/onmetaCard/onmetaCard.vue')
    },
    {
      path: '/login',
      name: 'login',
      meta: {
        gaTitle: '登录',
      },
      component: () => import( /* webpackChunkName: "login" */ '../pages/login/index.vue')
    },
    // {
    //   path: '/register',
    //   name: 'register',
    //   meta: {
    //     gaTitle: '注册',
    //   },
    //   component: () => import( /* webpackChunkName: "registered" */ '../pages/register/index.vue')
    // },
    {
      path: '/reset',
      name: 'reset',
      component: () => import(/* webpackChunkName: "reset" */ '../pages/reset/index.vue')
    },
    {
      path: '/registeredSuccess',
      name: 'registeredSuccess',
      component: () => import( /* webpackChunkName: "registeredSuccess" */ '../pages/registeredSuccess/index.vue')
    },
    {
      path: '/registeredSuccess2',
      name: 'registeredSuccess2',
      component: () => import( /* webpackChunkName: "registeredSuccess2" */ '../pages/registeredSuccess2/index.vue')
    },
  // 超级返佣
    {
      path: '/referral',
      redirect: {
        name: 'knightpage'
      }
    },
    {
      path: '/knightpage',
      name: 'knightpage',
      component: () => import( /* webpackChunkName: "knight" */ '../pages/knight/knight.vue')
    },
    {
      path: '/referralRankingList',
      name: 'referralRankingList',
      component: () => import( /* webpackChunkName: "referralRankingList" */ '../pages/rebate/rankingList/rankingList.vue')
    },
    {
      path: '/applyKninght',
      name: 'applyKninght',
      meta: {
        requiresAuth: true
      },
      component: () => import( /* webpackChunkName: "applyKninght" */ '../pages/rebate/applyKninght/applyKninght.vue')
    },
    // {
    //   path: '/referralsystem',
    //   // name: 'supercashback',
    //   component: () => import( /* webpackChunkName: "supercashback" */ '../pages/rebate/index.vue'),
    //   meta: {
    //     requiresAuth: true
    //   },
    //   children: [{
    //     path: '',
    //     name: 'referralsystem',
    //     component: () => import('../pages/rebate/spot/index.vue')
    //   }, {
    //     path: 'perpetual',
    //     name: 'perpetualRebate',
    //     component: () => import('../pages/rebate/perpetual/index.vue')
    //   }, {
    //     path: 'promotion',
    //     name: 'promotionRebate',
    //     component: () => import('../pages/rebate/promotion/index.vue')
    //   }]
    // },
  // 邀请注册
    {
      path: '/invitationRegister',
      name: 'invitationRegister',
      redirect: '/register'
      // component: () => import( /* webpackChunkName: "invitationRegister" */ '../pages/invitation/index.vue')
    },
    {
      path: '/rebateData',
      name: 'rebateData',
      component: () => import( /* webpackChunkName: "Fees" */ '../pages/rebateData/index.vue'),
      redirect: '/rebateData/contractStatistics',
      children: [
        {
          path: 'contractStatistics',
          name: 'contractStatistics',
          component: () => import( /* webpackChunkName: "Fees" */ '../pages/rebateData/components/contractStatistics.vue')
        }, {
          path: 'rebateSummary',
          name: 'rebateSummary',
          component: () => import( /* webpackChunkName: "Fees" */ '../pages/rebateData/components/rebateSummary.vue')
        }, {
          path: 'rebateRecord',
          name: 'rebateRecord',
          component: () => import( /* webpackChunkName: "Fees" */ '../pages/rebateData/components/rebateRecord.vue')
        }, {
          path: 'partnersList',
          name: 'partnersList',
          component: () => import( /* webpackChunkName: "Fees" */ '../pages/rebateData/components/partnersList.vue')
        }, {
          path: 'contractsInquire',
          name: 'contractsInquire',
          component: () => import( /* webpackChunkName: "Fees" */ '../pages/rebateData/components/contractsInquire.vue')
        }, {
          path: 'receiptsStatistics',
          name: 'receiptsStatistics',
          component: () => import( /* webpackChunkName: "Fees" */ '../pages/rebateData/components/receiptsStatistics.vue')
        }
      ]
    },
  // {
  //   path: '/loan',
  //   name: 'loan',
  //   component: () => import( /* webpackChunkName: "Fees" */ '../pages/loan/index.vue'),
  //   redirect: '/loan/index',
  //   children: [
  //     {
  //       path: 'index',
  //       name: 'loanIndex',
  //       meta: {
  //         gaTitle: '质押借币',
  //       },
  //       component: () => import( /* webpackChunkName: "Fees" */ '../pages/loan/borrowMoney.vue')
  //     },
  //     {
  //       path: 'borrow',
  //       name: 'borrow',
  //       component: () => import( /* webpackChunkName: "Fees" */ '../pages/loan/loanOrder.vue')
  //     },
  //     {
  //       path: 'asset',
  //       name: 'asset',
  //       component: () => import( /* webpackChunkName: "Fees" */ '../pages/loan/assetOverview.vue')
  //     }
  //   ]
  // },
    {
      path: '/Terms',
      name: 'Terms',
      component: () => import( /* webpackChunkName: "Terms" */ '../pages/terms/terms.vue')
    },
    // {
    //   path: '/spot',
    //   // name: 'tradeSpot',
    //   component: () => import( /* webpackChunkName: "spot" */ '../pages/trade/trade.vue'),
    //   redirect: '/spot/btc_usdt',
    //   children: [{
    //     path: ':symbol',
    //     name: 'spot',
    //     meta: {
    //       gaTitle: '币币交易',
    //     },
    //   }]
    // },
    {
      path: '/maintenance/:id',
      name: 'maintenance',
      component: () => import( /* webpackChunkName: "userMt5Account" */ '../pages/maintenance/maintenance.vue')
    },
    {
      path: '/system-maintenance',
      name: 'system-maintenance',
      component: () => import( /* webpackChunkName: "spot2" */ '../pages/trade/trade2.vue')
    },
  // 真实永续合约
    // {
    //   path: '/perpetual',
    //   // name: 'perpetual',
    //   component: () => import( /* webpackChunkName: "perpetual" */ '../pages/perpetual/perpetual.vue'),
    //   redirect: '/perpetual/btc_usd',
    //   children: [
    //     {
    //       path: ':symbol',
    //       name: 'perpetual',
    //       meta: {
    //         gaTitle: '永续交易'
    //       }
    //     }
    //   ]
    // },
    // {
    //   path: '/perpetual_app',
    //   // name: 'perpetual',
    //   component: () => import('../pages/perpetual/perpetual_app.vue'),
    //   redirect: '/perpetual_app/btc_usd',
    //   children: [
    //     {
    //       path: ':symbol',
    //       name: 'perpetual_app',
    //       meta: {
    //         gaTitle: '永续交易APP'
    //       }
    //     }
    //   ]
    // },
    // {
    //   path: '/perpetual_kfull',
    //   component: () => import( /* webpackChunkName: "perpetual_kfull" */ '../pages/perpetual/tvMobileFull.vue'),
    //   children: [
    //     {
    //       path: ':symbol',
    //       name: 'perpetual_kfull'
    //     },
    //   ]
    // },
    {
      path: '/fundingRate',
      name: 'fundingRate',
      component: () => import( /* webpackChunkName: "fundingRate" */ '../pages/fundingRate/fundingRate.vue')
    },
    // {
    //   path: '/bill',
    //   meta: {
    //     requiresAuth: true
    //   },
    //   redirect: {
    //     name: 'wallet'
    //   },
    //   component: () => import( /* webpackChunkName: "bill" */ '../pages/bill/bill.vue'),
    //   children: [{
    //     path: 'wallet',
    //     name: 'wallet',
    //     component: () => import('../pages/bill/wallet/wallet.vue')
    //   },
    //     {
    //       path: 'coinBill',
    //       name: 'coinBill',
    //       component: () => import('../pages/bill/coinBill/coinBill.vue')
    //     },
    //     {
    //       path: 'perpetual',
    //       name: 'renewBill',
    //       component: () => import('../pages/bill/renewBill/renewBill.vue')
    //     },
    //     {
    //       path: 'usdtPerpetual',
    //       name: 'usdtPerpetualBill',
    //       component: () => import('../pages/bill/usdtPerpetualBill/usdtPerpetualBill.vue')
    //     },
    //     // {
    //     //   path: 'mt5',
    //     //   name: 'mt5Bill',
    //     //   component: () => import('../pages/bill/mt5Bill/mt5Bill.vue')
    //     // },
    //     {
    //       path: 'capps',
    //       name: 'cappsBill',
    //       component: () => import('../pages/bill/cappsBill/cappsBill.vue')
    //     },
    //     {
    //       path: 'swap',
    //       name: 'swapBill',
    //       component: () => import('../pages/bill/swapBill/swapBill.vue')
    //     },
    //     {
    //       path: 'loan',
    //       name: 'loanBill',
    //       redirect: {
    //         name: 'loan1'
    //       },
    //       component: () => import('../pages/bill/loan/loanHistory.vue'),
    //       children: [
    //         {
    //           path: 'loan1',
    //           name: 'loan1',
    //           component: () => import('../pages/bill/loan/loanHistory1.vue')
    //         },
    //         {
    //           path: 'loan2',
    //           name: 'loan2',
    //           component: () => import('../pages/bill/loan/loanHistory2.vue')
    //         },
    //         {
    //           path: 'loan3',
    //           name: 'loan3',
    //           component: () => import('../pages/bill/loan/loanHistory3.vue')
    //         },
    //         {
    //           path: 'loan4',
    //           name: 'loan4',
    //           component: () => import('../pages/bill/loan/loanHistory4.vue')
    //         }
    //       ]
    //     }
    //   ]
    // },
    // {
    //   path: '/order',
    //   meta: {
    //     requiresAuth: true
    //   },
    //   redirect: {
    //     name: 'coinOrderCurrent'
    //   },
    //   component: () => import( /* webpackChunkName: "order" */ '../pages/order/index.vue'),
    //   children: [
    //     {
    //     path: 'loas',
    //     name: 'loanOrder',
    //     component: () => import('../pages/order/loanOrder.vue')
    //   },
    //   {
    //     path: 'ieo',
    //     name: 'ieo',
    //     component: () => import('../pages/order/ieoOrder.vue')
    //   },
    //   {
    //     path: 'spot',
    //     component: () => import('../pages/order/coinOrder.vue'),
    //     children: [{
    //       path: 'open',
    //       name: 'coinOrderCurrent',
    //       component: () => import('../pages/order/components/coinOrder/current.vue')
    //     },
    //       {
    //         path: 'history',
    //         name: 'coinOrderHistory',
    //         component: () => import('../pages/order/components/coinOrder/history.vue')
    //       },
    //       {
    //         path: 'old',
    //         name: 'coinOrderOld',
    //         component: () => import('../pages/order/components/coinOrder/old.vue')
    //       },
    //       {
    //         path: 'details',
    //         name: 'coinOrderDetails',
    //         component: () => import('../pages/order/components/coinOrder/detail.vue')
    //       }
    //     ]
    //   },
    //     {
    //       path: 'perpetual',
    //       name: 'perpetualOrder',
    //       redirect: {
    //         name: 'perpetualPlace'
    //       },
    //       component: () => import('../pages/order/perpetualOrder.vue'),
    //       children: [{
    //         path: 'open',
    //         name: 'perpetualCurrent',
    //         component: () => import('../pages/order/components/perpetual/current.vue')
    //       },
    //         {
    //           path: 'fills',
    //           name: 'perpetualFills',
    //           component: () => import('../pages/order/components/perpetual/complete.vue')
    //         },
    //         {
    //           path: 'history',
    //           name: 'perpetualHistory',
    //           component: () => import('../pages/order/components/perpetual/history.vue')
    //         },
    //         {
    //           path: 'place',
    //           name: 'perpetualPlace',
    //           component: () => import('../pages/order/components/perpetual/place.vue')
    //         },
    //         {
    //           path: 'comdition',
    //           name: 'perpetualComdition',
    //           component: () => import('../pages/order/components/perpetual/comdition.vue')
    //         }, {
    //           path: 'comditionHistory',
    //           name: 'usdtPerpetualComditionHistory',
    //           component: () => import('../pages/order/components/usdtPerpetual/comditionHistory.vue')
    //         }
    //       ]
    //     },
    //     {
    //       path: 'usdtPerpetual',
    //       name: 'usdtPerpetualOrder',
    //       redirect: {
    //         name: 'usdtPerpetualCurrent'
    //       },
    //       component: () => import('../pages/order/usdtPerpetualOrder.vue'),
    //       children: [
    //       {
    //         path: 'open',
    //         name: 'usdtPerpetualCurrent',
    //         component: () => import('../pages/order/components/usdtPerpetual/current.vue')
    //       }, {
    //         path: 'complete',
    //         name: 'usdtPerpetualComplete',
    //         component: () => import('../pages/order/components/usdtPerpetual/complete.vue')
    //       }, {
    //         path: 'history',
    //         name: 'usdtPerpetualHistory',
    //         component: () => import('../pages/order/components/usdtPerpetual/history.vue')
    //       }, {
    //         path: 'place',
    //         name: 'usdtPerpetualPlace',
    //         component: () => import('../pages/order/components/usdtPerpetual/place.vue')
    //       }
    //     ]
    //     },
    //     {
    //       path: 'capps',
    //       name: 'cappsOrder',
    //       redirect: {
    //         name: 'cappsOrderUnfinished'
    //       },
    //       component: () => import('../pages/order/cappsOrder.vue'),
    //       children: [{
    //         path: 'unfinished',
    //         name: 'cappsOrderUnfinished',
    //         component: () => import('../pages/order/components/capps/unfinished.vue')
    //       },
    //         {
    //           path: 'history',
    //           name: 'cappsOrderHistory',
    //           component: () => import('../pages/order/components/capps/history.vue')
    //         }
    //       ]
    //     },
    //     {
    //       path: 'simulated-perpetual',
    //       name: 'virtualPerpetual',
    //       redirect: {
    //         name: 'simulationCurrent'
    //       },
    //       component: () => import('../pages/order/simulationPerpetualOrder.vue'),
    //       children: [{
    //         path: 'open',
    //         name: 'simulationCurrent',
    //         component: () => import('../pages/order/components/simulationPerpetual/current.vue')
    //       },
    //         {
    //           path: 'fills',
    //           name: 'simulationFills',
    //           component: () => import('../pages/order/components/simulationPerpetual/complete.vue')
    //         },
    //         {
    //           path: 'history',
    //           name: 'simulationHistory',
    //           component: () => import('../pages/order/components/simulationPerpetual/history.vue')
    //         }
    //       ]
    //     },
    //     {
    //       path: 'presale',
    //       name: 'nftAuctionOrder',
    //       component: () => import('../pages/order/nftAuctionOrder.vue')
    //     },
    //     {
    //       path: 'asset_management',
    //       name: 'moneyManagementOrder',
    //       component: () => import('../pages/order/moneyManagementOrder.vue')
    //     }
    //   ]
    // },
  
    {
      path: '/capptown',
      component: () => import( /* webpackChunkName: "capptown" */ '../pages/capptown/index.vue'),
      children: [
        {
          path: '',
          name: 'capptown',
          component: () => import( /* webpackChunkName: "capptown" */ '../pages/capptown/index/index.vue')
        },
        {
          path: 'authorize',
          name: 'authorize',
          component: () => import( /* webpackChunkName: "authorize" */ '../pages/capptown/authorize/index.vue')
        },
        {
          path: 'authorizeError',
          name: 'authorizeError',
          component: () => import( /* webpackChunkName: "authorizeError" */ '../pages/capptown/authorizeError/index.vue')
        },
        {
          path: 'payment',
          name: 'payment',
          component: () => import( /* webpackChunkName: "payment" */ '../pages/capptown/payment/index.vue')
        },
        {
          path: 'list',
          name: 'cappList',
          component: () => import( /* webpackChunkName: "cappList" */ '../pages/capptown/list/index.vue')
        }
      ]
    },
    {
      path: '/eventcenter',
      redirect: '/perpetual_trading_competition'
    },
    {
      path: '/perpetual_trading_competition',
      name: 'perpetual_trading_competition',
      component: () => import( /* webpackChunkName: "missions" */ '../pages/activity/simulation/missions.vue')
    },
    {
      path: '/download',
      name: 'download',
      component: () => import(/* webpackChunkName: "download" */ '../pages/activity/simulation/download.vue')
    },
    {
      path: '/eazyswap',
      component: () => import(/* webpackChunkName: "eazyswap" */ '../pages/eazyswap/index.vue'),
      children: [
        {
          path: '',
          name: 'eazyswap',
          component: () => import(/* webpackChunkName: "eazyswap" */ '../pages/eazyswap/trade/trade.vue')
        },
        {
          path: 'add-coin',
          name: 'addCoin',
          meta: {
            requiresAuth: true
          },
          component: () => import(/* webpackChunkName: "addCoin" */ '../pages/eazyswap/addCoin/addCoin.vue')
        },
        {
          path: 'trading-info',
          name: 'trading-info',
          component: () => import(/* webpackChunkName: "tradingInfo" */ '../pages/eazyswap/tradingInfo/tradingInfo.vue')
          // children: [{
          //   path: ':symbol',
          //   name: 'trading-info'
          // }]
        },
        {
          path: 'order',
          name: 'swapOrder',
          redirect: '/eazyswap/order/current',
          meta: {
            requiresAuth: true
          },
          component: () => import(/* webpackChunkName: "swapOrder" */ '../pages/eazyswap/order/order.vue'),
          children: [
            {
              path: 'current',
              name: 'swapOrderCurrent',
              component: () => import(/* webpackChunkName: "swapOrderCurrent" */ '../pages/eazyswap/order/current.vue')
            },
            {
              path: 'swap',
              name: 'swapOrderSwap',
              component: () => import(/* webpackChunkName: "swapOrderSwap" */ '../pages/eazyswap/order/swap.vue')
            },
            {
              path: 'pledeg',
              name: 'swapOrderPledeg',
              component: () => import(/* webpackChunkName: "swapOrderPledeg" */ '../pages/eazyswap/order/pledeg.vue')
            }
          ]
        }
      ]
    },
    {
      path: '/bitcoin-price-prediction',
      name: 'bitcoin-price-prediction',
      component: () => import('../pages/activity/bitcoin-price-prediction/bitcoin-price-prediction.vue')
    },
    {
      path: '/nft-market',
      name: 'nft-market',
     
      redirect: '/nft-market/index',
      component: () => import('../pages/nftMarket/layout.vue'),
      children: [{
          path: 'index',
          name: 'Index',
          redirect: '/nft-market/index/market',
          component: () => import('../pages/nftMarket/index/index.vue'),
          children: [{
            path: 'market',
            name: 'market',
            meta: {
              gaTitle: 'NFT主页',
            },
            component: () => import('../pages/nftMarket/index/components/market.vue')
          },
        {
            path: 'ino',
            name: 'ino',
            component: () => import('../pages/nftMarket/index/components/ino.vue')
          },
        {
            path: 'marketindex',
            name: 'marketindex',
            component: () => import('../pages/nftMarket/index/components/marketIndex.vue')
          }]
        },
        {
          path: 'info',
          name: 'Info',
          component: () => import('../pages/nftMarket/info/index.vue'),
          children: [{
            path: 'market',
            name: 'market',
            component: () => import('../pages/nftMarket/info/components/NFT-Market.vue')
          },
          {
            path: 'presale',
            name: 'presale',
            component: () => import('../pages/nftMarket/info/components/NFT-Auction.vue')
          },
          {
            path: 'record',
            name: 'record',
            component: () => import('../pages/nftMarket/info/components/NFT-Record.vue')
          }]
        },
        {
          path: 'details-market',
          name: 'details-market',
          component: () => import('../pages/nftMarket/details/market.vue')
        },
        {
          path: 'product',
          name: 'product',
          component: () => import('../pages/nftMarket/product/index.vue')
        }
      ]
    },
    {
      path: '/asset-management',
      name: 'moneyManagement',
      redirect: '/asset-management/list',
      component: () => import('../pages/moneyManagerment/index.vue'),
      children: [
        {
          path: 'list',
          name: 'moneyManagementList',
          meta: {
            gaTitle: '理财主页',
          },
          component: () => import('../pages/moneyManagerment/list/index.vue')
        }, {
          path: 'buy',
          name: 'moneyManagementBuyForm',
          meta: {
            gaTitle: '理财购买页',
          },
          component: () => import('../pages/moneyManagerment/buy/index.vue')
        }
      ]
    },
    {
      path: '/policy',
      name: '404',
      component: () => import('../pages/404/index.vue')
    },
    {
      path: '/agreement',
      name: '404',
      component: () => import('../pages/404/index.vue')
    },
    {
      path: '/riskWaring',
      name: '404',
      component: () => import('../pages/404/index.vue')
    },
    {
      path: '*',
      name: '404',
      component: () => import('../pages/404/index.vue')
    }
  ];
  export default routes;
  