import usdt_state from './usdt/usdt_state';

const coin_state = {
  symbol: '', // 当前查看合约币对
  contractList: [], // 合约币对列表
  accountInfo: {}, // 当前合约币对的账户信息
  contractBaseInfo: {}, // 合约公共信息
  contractInfo: {}, // 当前合约币对详细信息
  leverageInfo: {}, // 当前合约币对杠杆信息
  contractInfoReady: false, // 当前合约币对信息获取ok
  userInfo: {}, // 合约币对的账户信息
  positionInfo: {}, // 持仓信息,只会有一个仓位
  incompleteOrders: [], // 当前委托列表
  completeOrders: [], // 已成交委托列表
  historyContractOrders: [], // 历史委托列表
  conditionContractOrders: [], // 条件委托列表
  placeList: [], // 平仓盈亏列表

  symbolSupported: false,
  contract_depths: {
    bids: [],
    asks: []
  }, // 深度数据
  contract_tickers: {}, // 当前合约币对行情
  contract_all_tickers: {}, // 所有合约币对行情
  contract_all_tickers_COIN: {}, // 币本位合约币对行情
  depthType: 0, // 深度参数
  contract_deals: [], // 最新成交
  priceVolInput: [],
  orderCheckConfirm: false // 委托确认
}

export default {
  contractType: 'COIN-M', // 合约类型
  perpetual_ws: null,
  apiLoading: false,   // 接口加载状态
  activeContract: '', // 当前选中合约
  ...coin_state,
  ...usdt_state
};
