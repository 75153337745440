module.exports = {
  // 币币，老接口
  getBanner: '/server/support.act?cmd=getBannerList', // 获取banner
  showIndexSymbols: '/server/index.act?cmd=showIndexSymbols', // 获取首页展示交易对里列表
  getAllFunConifigInfo: '/server/common.act?cmd=getAllFunConifigInfo', // 获取所有功能白名单配置信息
  getCurrencies: '/server/currency.act?cmd=getCurrencies',
  // 用户
  getAllCoin: '/server/util.act?cmd=getAllCoinPriceToUsdtObject', // 获取所有币种对USDT的价格
  getPublicKey: '/server/user_account.act?cmd=passwordRSAPublicKey', // 获取 getPublicKey
  checkLogin: '/server/user_security.act?cmd=checkLogin', // 是否已登录
  loginApi: '/server/user_account.act?cmd=login0',
  loginApi3: '/server/user_account.act?cmd=login1', // v3
  loginApi2: '/server/user_account.act?cmd=login4RecaptchaV2', // v2
  logoutApi: '/server/user_account.act?cmd=logout',
  registerSendSMSApi: '/server/user_account.act?cmd=registerSendSMS', // 手机注册发送短信
  registerSendSMSApi2: '/server/user_account.act?cmd=registerSendSMS4Recaptcha', // 手机注册发送短信2
  registerForPhoneApi: '/server/user_account.act?cmd=registerByPhoneV2', // 手机注册
  registerByEmailApi: '/server/user_account.act?cmd=registerByEmail', // 邮件注册发送验证码
  registerByEmailApi2: '/server/user_account.act?cmd=registerByEmail4RecaptchaV2', // 邮件注册发送验证码2
  emailActiveApi: '/server/user_account.act?cmd=emailActive', // 邮件注册激活
  securityAuthenticateSendSMSApi: '/server/user_security.act?cmd=securityAuthenticateSendSMS', // 安全验证发送短信
  securityAuthenticateApi: '/server/user_security.act?cmd=securityAuthenticate', // 安全验证
  loginRegionCheckAuthApi: '/server/user_account.act?cmd=loginRegionCheckAuth', // 异地登录验证
  resendRegionAuthCode: '/server/user_account.act?cmd=resendRegionAuthCode', // 用户重新发送异地登录验证码
  resetPwdSendSMSApi: '/server/user_account.act?cmd=resetPwdSendCode', // 重置密码发送短信
  resetPwdSendSMSApi2: '/server/user_account.act?cmd=resetPwdSendCode4Recaptcha', // 重置密码发送短信2
  resetPwdSMSConfirmApi: '/server/user_account.act?cmd=resetPwdConfirm', // 重置密码短信确认
  resetPasswordApi: '/server/user_account.act?cmd=resetPassword0', // 重置密码判断密码
  loginByTokenApi: '/server/user_account.act?cmd=loginByToken', // 注册成功自动登录
  requestCheckCode: '/server/user_account.act?cmd=requestCheckCode', // 极验验证初始化
  recaptchaV3Check: '/server/user_account.act?cmd=recaptchaV3Check', // google验证初始化v3
  recaptchaV2Check: '/server/user_account.act?cmd=recaptchaV2Check', // google验证初始化v2

  // 阿里验证
  aliSliderCheck: '/server/user_account.act?cmd=aliSliderCheck',
  login4AliSlider: '/server/user_account.act?cmd=login4AliSlider',
  post_registerSendSMS4AliSlider: '/server/user_account.act?cmd=registerSendSMS4AliSlider',
  post_registerByEmail4AliSlider: '/server/user_account.act?cmd=registerByEmail4AliSlider',
  post_resetPwdSendCode4AliSlider: '/server/user_account.act?cmd=resetPwdSendCode4AliSlider',
  // 腾讯验证
  tencentCaptchaCheck: '/server/user_security.act?cmd=tencentCaptchaCheck',

  getUserBaseInfoApi: '/server/user_account.act?cmd=getUserBaseInfo',
  identityVerificationApi: '/server/user_account.act?cmd=identityVerification', // 提交用户认证信息
  getUserVerificationInfoApi: '/server/user_account.act?cmd=getUserVerificationInfo', // 获取用户认证信息
  companyVerification: '/server/user_account.act?cmd=companyVerification', // 上传企业认证信息
  companyVerificationUpload: '/server/user_account.act?cmd=companyVerificationUpload', // 上传企业认证信息图片
  getCompanyVerificationInfo: '/server/user_account.act?cmd=getCompanyVerificationInfo', // 获取企业认证信息
  getKycCountryApi: '/server/kycCountry.act?cmd=getKycCountries', // 获取kyc认证国家
  getUserBandingRechargeCardApi: '/server/usdRecharge.act?cmd=getUserBandingRechargeCard', // 获取用户银行卡绑定信息
  getWSToken: '/server/cointrade.act?cmd=getUserWSToken',

  // 币币新系统接口 trade
  getUserTradeTotalAssetApi: '/server/tradeFund.act?cmd=staticUserSumAsset', // 用户资产总和
  getTradeAssetBySymbol: '/server/tradeFund.act?cmd=getUserAssetByTrade', // 据交易对获取用户的交易对资产 busitype
  getTradeAssetByCoin: '/server/tradeFund.act?cmd=getUserAssetInfo', // 根据币种获取用户资产信息 coinCode
  getUserFixAssetByCoin: '/server/tradeFund.act?cmd=getUserFixAsset', // 获取用户总资产 coinCode
  getUserActiveAssetByCoin: '/server/tradeFund.act?cmd=getUserActiveAsset', // 取用户活动资产 coinCode
  getUserTradeAssetChangeRecords: '/server/tradeFund.act?cmd=getUserAssetChangeRecords', // 获取用户资产变更记录 coinCode
  getUserTradeAssetsList: '/server/tradeFund.act?cmd=getAllUserAsset', // 获取用户所有币种资产

  transferMainToTrade: '/server/transfer.act?cmd=transferMainToTrade', // 主账户转币币
  transferTradeToMain: '/server/transfer.act?cmd=transferTradeToMain', // 币币转主账户
  transferMt5: '/server/transfer.act?cmd=transferUserAsset', // MT5划转,主账户划转Capps
  transferUsdt: '/server/transfer.act?cmd=transferUserAsset', //USDT划转
  requestTransferDapps2Main: '/server/transfer.act?cmd=requestTransferDapps2Main', // capps划转主账户

  // 主账户资产 main
  getUserMainTotalAssetApi: '/server/userFund.act?cmd=staticUserSumAsset', // 用户资产总和
  getUserMainAssetsList: '/server/userFund.act?cmd=getUserAllAsset', // 获取用户所有币种的main资产信息
  getMainAssetByCoin: '/server/userFund.act?cmd=getUserAssetInfo', // 获取用户关于某币种的资产信息
  getUserAssetChangeRecords: '/server/userFund.act?cmd=getUserAssetChangeRecords', // 获取用户资产变更信息
  // 获取全部币种信息
  getAllCoinInfos: '/server/userFund.act?cmd=getAllCoinInfos&type=showAll',
  getCoinRate: '/ng/tokeninsight',
  getUserSafeInfoApi: '/server/user_account.act?cmd=getUserSafeInfo', // 获取用户安全信息
  modifyPwdSendCodeApi: '/server/user_account.act?cmd=modifyPwdSendCode', // 修改密码发送验证码
  modifyPwdConfirmApi: '/server/user_account.act?cmd=modifyPwdConfirm', // 修改密码确认
  bindEmailSendEmailCodeApi: '/server/user_security.act?cmd=bindEmailSendEmailCode', // 绑定邮箱发送邮件验证码
  bindEmailSendMobileCodeApi: '/server/user_security.act?cmd=bindEmailSendMobileCode', // 绑定邮箱发送短信验证码
  bindEmailConfirmApi: '/server/user_security.act?cmd=bindEmailConfirm', // 绑定邮箱确认
  bindPhoneSendSMSApi: '/server/user_security.act?cmd=bindPhoneSendSMS', // 绑定手机发送短信
  bindPhoneSendEmailCodeApi: '/server/user_security.act?cmd=bindPhoneSendEmailCode', // 绑定手机发送邮件验证码
  bindPhoneConfirmApi: '/server/user_security.act?cmd=bindPhoneConfirm', // 绑定手机确认
  unbindPhoneSendSMSApi: '/server/user_security.act?cmd=unbindPhoneSendSMS', // 绑定手机发送短信
  unbindPhoneSendEmailCodeApi: '/server/user_security.act?cmd=unbindPhoneSendEmail', // 绑定手机发送邮件验证码
  unbindPhoneConfirmApi: '/server/user_security.act?cmd=unbindPhoneAuth', // 绑定手机确认
  bindGoogleSendCodeApi: '/server/user_security.act?cmd=bindGoogleSendCode', // 绑定/更换google发送邮箱验证码
  bindGoogleSendSmsCodeApi: '/server/user_security.act?cmd=bindGoogleSendSmsCode', // 绑定google发送短信验证码
  bindGoogleConfirmApi: '/server/user_security.act?cmd=bindGoogleConfirm', // 绑定google邮箱确认
  bindGoogleSmsCodeConfirmApi: '/server/user_security.act?cmd=bindGoogleSmsCodeConfirm', // 绑定google短信确认
  initializeGooglePrivateKeyApi: '/server/user_security.act?cmd=initializeGooglePrivateKey', // 初始化谷歌私钥

  initializeResetGooglePrivateKey: '/server/user_security.act?cmd=initializeResetGooglePrivateKey', // （初始化谷歌私钥）
  resetGoogleCaptcha: '/server/user_security.act?cmd=resetGoogleCaptcha', // （谷歌验证器验证码验证）参数（googleCode）
  resetBindGoogleSendSmsCode: '/server/user_security.act?cmd=resetBindGoogleSendSmsCode', // （发送手机验证码）
  resetGoogleAuthenticate: '/server/user_security.act?cmd=resetGoogleAuthenticate', // （谷歌邮箱验证）参数（emailCode，googleCode）
  resetGoogleAuthenticateSmsConfirm: '/server/user_security.act?cmd=resetGoogleAuthenticateSmsConfirm', // （谷歌手机验证）参数（emailCode，googleCode）

  getUserLoginLogApi: '/server/user_account.act?cmd=getUserLoginLog', // 获取用户登录记录
  operationSecuritySetApi: '/server/user_security.act?cmd=operationSecuritySet', // 操作安全设置【OK】
  getUserAuthenticationSecuritySetApi: '/server/user_security.act?cmd=getUserAuthenticationSecuritySet', // 获取安全设置列表
  setTradePwd: '/server/user_security.act?cmd=setTradePwd', // 设置交易密码
  validateEmailCode: '/server/user_security.act?cmd=validateEmailCode', // 设置交易密码时校验邮箱验证码
  sendEmailCodeForTradePwd: '/server/user_security.act?cmd=sendEmailCodeForTradePwd', // 设置交易密码时重新发送邮箱验证码
  updateTradePwdStatus: '/server/user_security.act?cmd=updateTradePwdStatus', // 修改交易密码验证频率
  readTradePwdStatus: '/server/user_security.act?cmd=readTradePwdStatus', // 查询交易密码验证频率
  checkTradePwd: '/server/user_security.act?cmd=checkTradePwd', // 交易密码校验
  validateTradePwd: '/server/user_security.act?cmd=validateTradePwd', // 交易密码校验
  updateMailSubscribe: '/server/user_account.act?cmd=updateMailSubscribe', // 更新邮件订阅
  updateLang: '/server/user_account.act?cmd=updateLang', // 更新用户选择语言

  // 返佣
  queryMyBalanceApi: '/server/userFund.act?cmd=staticUserSumAsset',
  getDistribCommsInfo: '/server/userFund.act?cmd=getDistribCommsInfo', // 获取用户返佣信息
  userInviteList: '/server/user_invite.act?cmd=userInviteList', // 获取用户邀请列表
  queryReturnRecordInfo: '/server/user_invite.act?cmd=queryReturnRecordInfo', // 查询用户返币
  queryInviteUserForReturn: '/server/user_invite.act?cmd=queryInviteUserForReturn',
  staticDistribComms: '/server/userFund.act?cmd=staticDistribComms', // 获取用户返佣资产整合
  countTradeUserInviteApi: '/server/user_invite.act?cmd=countUserInvite', // 统计用户邀请人数
  queryUserByChannel: '/server/user_invite.act?cmd=queryUserByChannel', // 根据渠道号查询邀请用户
  readUserActivityInfo: '/server/user_invite.act?cmd=readUserActivityInfo', // 查询用户渠道相关信息
  getDisturbCommInfoRank: '/server/userFund.act?cmd=getDisturbCommInfoRank', // 获取佣金数据的接口
  getRebateRatio: '/contract/swap/account/getUserCmiChannel',  // 获取返佣比例

  // BF Token 预购
  getCoinPriceForPrebuy: '/server/prebuy.act?cmd=getCoinPriceForPrebuy', // 获取预购价格
  create: '/server/prebuy.act?cmd=create', // 预购
  getOrders: '/server/prebuy.act?cmd=getOrders', // 获取预购列表
  getPrebuyTotalAmount: '/server/prebuy.act?cmd=getPrebuyTotalAmount', // 获取认购总额
  getBidBalance: '/server/prebuy.act?cmd=getBalance', // 获取预购帐户余额
  getPrebuyDate: '/server/prebuy.act?cmd=getPrebuyDate', // 获取预购时间
  getPrebuyTotalAmountByDay: '/server/prebuy.act?cmd=getPrebuyTotalAmountByDay', // 获取预购时间
  getPrebuyChangeList: '/server/prebuy.act?cmd=getPrebuyChangeList', // 获取用户平台币解冻记录列表

  // 挖矿
  getMiningStatistics: '/server/mining.act?cmd=getMiningStatistics', // 查询平台相关各种统计数据
  getTradeMiningRealtimeStatistics: '/server/mining.act?cmd=getTradeMiningRealtimeStatistics', // 交易挖矿实时统计
  getTradeFeeStatistics: '/server/mining.act?cmd=getTradeFeeStatistics', // 查询手续费资金池总额 (手续费回购基金收入折合)
  getBlockBalance: '/server/mining.act?cmd=getBalance', // 获取持仓余额 (获取个人持仓账户信息)
  getUserMiningStatistics: '/server/mining.act?cmd=getUserMiningStatistics', // 查询用户挖矿统计
  getMiningStatisticsByDay: '/server/mining.act?cmd=getMiningStatisticsByDay', // 查询挖矿历史 (平台)
  getBfBtcPrice: '/server/mining.act?cmd=getBfBtcPrice', // 查询价格汇率
  getYesterdayTradePairFeeHistory: '/server/mining.act?cmd=getYesterdayTradePairFeeHistory', // 查询昨天交易对挖矿数据
  getMiningHistory: '/server/mining.act?cmd=getMiningHistory', // 查询挖矿历史 (个人邀请)
  getTradeMiningHistory: 'server/mining.act?cmd=getTradeMiningHistory', // 查询交易挖矿历史 (个人)
  getUserRealtimeMining: 'server/mining.act?cmd=getUserRealtimeMining', // 查询个人实时交易挖矿预估 (个人)

  lastWeekBuyBack: 'server/mining.act?cmd=lastWeekBuyBack', // 上周回购数量
  totalBuyBack: 'server/mining.act?cmd=totalBuyBack', // 总回购数量
  buyBackReport: 'server/mining.act?cmd=buyBackReport', // 回购报告(分页查询)

  // 充值
  getChargeAddress: '/server/charge.act?cmd=getChargeAddress', // 获取用户充值地址
  getChargeAddressV2: '/server/charge.act?cmd=getChargeAddressV2', // 获取用户充值地址
  checkChargeAddress: '/server/charge.act?cmd=checkChargeAddress', // 校验用户充值地址
  getChargeRecords: '/server/charge.act?cmd=getChargeRecords', // 获取用户充值记录
  getCoinChainUrlAddress: '/server/charge.act?cmd=getCoinChainUrlAddress', // 获取txid的url

  // 提现
  getDigitalCoinInfos: '/server/userFund.act?cmd=getDigitalCoinInfos', // 获取系统所有数字货币币种信息
  getCoinConfigInfo: '/server/userFund.act?cmd=getCoinConfigInfo', // 获取币种相关的业务参数
  getCurrencyLineTypes: '/server/support.act?cmd=getSubInfoCoinInfo', // 获取币种链类型
  listPickUpAdress: '/server/pickUp.act?cmd=listPickUpAdress', // 获取提现地址列表
  applyAddPickUpAdress: '/server/pickUp.act?cmd=applyAddPickUpAdress', // 添加提现地址
  applyAddPickUpAdressV2: '/server/pickUp.act?cmd=applyAddPickUpAdressV2', // 添加提现地址
  deletePickUpAdress: '/server/pickUp.act?cmd=deletePickUpAdress', // 删除提现地址
  getWithdrawFeeListAPI: '/server/pickUp.act?cmd=getWithdrawFeeList', // 提现费率
  applySimplePickUp: '/server/pickUp.act?cmd=applySimplePickUp', // 提现
  applySimplePickUpV2: '/server/pickUp.act?cmd=applySimplePickUpV2', // 地址簿提现
  applySimplePickUpV3: '/server/pickUp.act?cmd=applySimplePickUpV3', // 新地址提现
  canclePickUp: '/server/pickUp.act?cmd=canclePickUp', // 撤销提现申请
  getWithDrawRecords: '/server/pickUp.act?cmd=getWithDrawRecords', // 获取用户提现记录信息
  sendEmailCodeForPickUp: '/server/pickUp.act?cmd=sendEmailCodeForPickUp', // 发送提现邮箱验证码
  sendEmailCode4AddAddress: '/server/pickUp.act?cmd=sendEmailCode4AddAddress', // 发送提现邮箱验证码
  sendEmailCode4PickUp: '/server/pickUp.act?cmd=sendEmailCode4PickUp', // 发送提币邮箱验证码
  sendSMSCode4PickUp: '/server/pickUp.act?cmd=sendSMSCode4PickUp', // 发送提币手机验证码
  confirmApplySimplePickUpV3: '/server/pickUp.act?cmd=confirmApplySimplePickUpV3', // 提交验证码
  getFioAddress: '/server/pickUp.act?cmd=getFioAddress', // 获取fio地址
  // 币币交易
  getBusiTypeInfos: '/server/cointrade.act?cmd=getBusiTypeInfos', // 获取所有交易对信息
  getBusiTypeInfo: '/server/cointrade.act?cmd=getBusiTypeInfo', // 获取单个交易对信息
  getTicker: '/server/cointrade.act?cmd=getTicker', // 交易对的Ticker信息(24H)
  getDeepData: '/server/cointrade.act?cmd=getDefaultDeepenDatas', // 获取交易对深度数据
  getTickers: '/server/cointrade.act?cmd=getTickers', // 获取交易对列表
  getUnCompleteOrders: '/server/cointrade.act?cmd=listUnCompleteOrders', // 获取委托中订单
  cancelStopLimitOrder: '/server/cointrade.act?cmd=cancelStopLimitOrder', // 币币交易单个撤销止盈止损单的接口
  getCompleteOrders: '/server/cointrade.act?cmd=listRecentCmpOrders', // 获取最近已完成订单
  getCompleteOrdersForEndTime: '/server/cointrade.act?cmd=getCompleteOrdersForEndTime', // 获取用户历史订单信息
  getOrdersBySearchCondition: '/server/cointrade.act?cmd=getCompleteOrders-v3',
  getOldOrdersBySearchCondition: '/server/cointrade.act?cmd=getOldCompleteOrders',
  placeBuyOrder: '/server/cointrade.act?cmd=placeBuyOrder-v3',
  placeStopLimitOrder: '/server/cointrade.act?cmd=placeStopLimitOrder',
  placeSellOrder: '/server/cointrade.act?cmd=placeSellOrder-v3',
  cancelOrder: '/server/cointrade.act?cmd=cancelOrder',
  cancelAllOrder: '/server/cointrade.act?cmd=cancelAllOrder',
  getUsdCnyExchangeRate: '/server/util.act?cmd=getUsdCnyExchangeRate',
  getExchangeRateBySrc: '/server/util.act?cmd=ExchangeRate', // 获取汇率
  getUsdtUsdExchangeRate: '/server/util.act?cmd=usdtUsdExchangeRate', // 获取usdtusd汇率
  getECurrencyExchangeRate: '/server/util.act?cmd=eCurrencyExchangeRate', // 获取加密货币对法币的汇率
  getOrderTradeInfo: '/server/cointrade.act?cmd=getOrderTradeInfo',
  getBlockCoinList: '/server/block.act?cmd=getBlockCoinList', // 获取板块信息
  getBlockList: '/server/block.act?cmd=getBlockList', // 获取板块列表
  getUserBusiTypeInfos: '/server/cointrade.act?cmd=getUserBusiTypeInfos', // 币币历史获取筛选币对数组

  getSubscribeSymbools: '/server/cointrade.act?cmd=getSubscribeSymbools', // 获取认购交易对
  checkIsProjectUsers: '/server/cointrade.act?cmd=checkIsProjectUsers', // 判断是否是对应项目方用户
  placeSubscribeSellOrder: '/server/cointrade.act?cmd=placeSubscribeSellOrder', // 项目方委托认购卖单
  placeSubscribeBuyOrder: '/server/cointrade.act?cmd=placeSubscribeBuyOrder', // 用户委托认购买单
  spotWhiteList: '/server/cointrade.act?cmd=whitelist', // 币币交易白名单
  doCollectTicker: '/server/cointrade.act?cmd=collectTicker',

  // 平台币模块
  getExchangeRate: '/server/mining.act?cmd=getBfBtcPrice', // bf，btc,usdt 的换算比率
  getBalance: '/server/mining.act?cmd=getBalance', // 用户持仓余额
  getInternal: '/server/mining.act?cmd=isInternalPrebuyUser', // 是否为基石轮用户
  getContractListingTime: '/server/market.act?cmd=getContractListingTime', // 获取预售倒计时
  getGroupName: '/server/market.act?cmd=getGroupName', // 获取websocket getGroupName
  getMarketTickers: '/server/market.act?cmd=getTickers', // 通过分组查询交易对Ticker
  getSymbolsJson: '/server/market.act?cmd=getSymbolsJson', // 获取币币交易对树结构
  getAllTicker: '/server/cointrade.act?cmd=getAllTicker', // 获取所有现货交易对的ticker信息
  searchTickers: 'server/market.act?cmd=searchTickers', // 查询所有交易对ticker接口 (new)
  searchTickersV3: 'server/market.act?cmd=searchTickersV3', // 查询所有交易对ticker接口 (new)
  searchTickersV2: 'server/market.act?cmd=searchTickersV2', // 查询所有交易对ticker接口 (new)
  checkUserLicense: '/server/license.act?cmd=checkUserLicense', // 查询是否同意期货协议
  agreeLicense: '/server/license.act?cmd=agreeLicense', // 同意期货协议

  getExchargeBuyPrices: '/server/atomexcharge.act?cmd=getExchargeBuyPrices', // 获取ATOM借入价格
  buyExcharge: '/server/atomexcharge.act?cmd=buyExcharge', // 按现有借入购买ATOM
  getUserExchargeInfos: '/server/atomexcharge.act?cmd=getUserExchargeInfos', // 获取用户兑换ATOM的信息
  getExchargeInfo: '/server/atomexcharge.act?cmd=getExchargeInfo', // 获取IOU限制信息
  returnBack: '/server/atomexcharge.act?cmd=returnBack', // 归还已借入的ATOM

  // 上币申请模块
  ApplyForm: '/server/currencyLaunching.act?cmd=applyForm', // 增加/修改上币申请表单
  getProcessState: '/server/currencyLaunching.act?cmd=getProcessState', // 查询用户上币申请流程状态
  getApplyForm: '/server/currencyLaunching.act?cmd=getApplyForm', // 查询上币申请信息明细
  addAdditionalInfo: '/server/currencyLaunching.act?cmd=addAdditionalInfo', // 增加/修改上币补充信息
  getAdditionalInfo: '/server/currencyLaunching.act?cmd=getAdditionalInfo', // 查询上币补充信息明细
  getCostAndFund: '/server/currencyLaunching.act?cmd=getCostAndFund', // 查询上币支付费用及账户余额
  payCost: '/server/currencyLaunching.act?cmd=payCost', // 支付上币费用
  getCostInfo: '/server/currencyLaunching.act?cmd=getCostInfo', // 查询上币支付信息
  getConfirmInfo: '/server/currencyLaunching.act?cmd=getConfirmInfo', // 查询上币确认信息
  getRecommendOrgList: 'server/currencyLaunching.act?cmd=getRecommendOrgList', // 获取推荐机构列表

  // academy
  getArticlesOfIndex: '/napi/getArticlesOfIndex', // 获取首页文章
  getArticlesOfCategories: '/napi/getArticlesOfCategories', // 获取分类文章
  getArticleDetail: '/napi/getArticleDetail', // 获取文章详情
  getArticleRecommend: '/napi/getArticleRecommend', // 获取推荐文章列表 (底部)
  getArticleRelated: '/napi/getArticleRelated', // 获取相关文章列表 (左侧)
  getAcademys: '/napi/articles', // 获取文章
  getCategories: '/napi/categories', // 获取文章分类列表
  getMeta: '/napi/getMeta', // 获取Meta
  getDefizoneHots: '/napi/getDefizoneHots', // 获取DeFi 列表

  // market
  getKLinV1: '/server/api/v1/market/kline',

  // 理财
  getFixedProductList: '/financing/product/fixed/list', // 获取定期理财产品列表
  getFixedProductDetail: '/financing/product/fixed', // 获取理财产品详情
  createFinancingOrder: '/financing/order/createOrder', // 创建理财订单
  redeem: '/financing/order/redeem?', // 赎回活期理财订单
  getFinancingAsset: '/financing/asset', // 获取理财资产
  getFixedAsset: '/financing/asset/getFixedAsset', // 获取旧理财资产
  moduleList: '/financing/product/moduleProductList', // 按板块获取理财产品列表
  getDetailId: '/financing/product', // 获取理财产品详情
  getStakingOrderList: '/financing/order/list', // 获取板块列表
  moduleOrderOverview: '/financing/order/moduleOrderOverview', // 板块订单概览列表
  moduleOrderOverviewOrderList: '/financing/order/moduleOrderOverviewOrderList', // 板块订单概览列表
  getModuleList: '/financing/module/list', // 板块接口
  getOrderList: '/financing/order/list', // 获取理财订单
  getOrderFixedList: '/financing/order/fixed/list', // 获取旧理财订单
  // 安全设置
  handleAntiCodeConfirmApi: '/server/user_security.act?cmd=fillAntiPhishingCodeConfirm', // 添加防钓鱼码
  securitySendEmailCodeApi: '/server/user_security.act?cmd=fillAntiPhishingSendEmailCode', // 防钓鱼码发送邮箱验证
  securitySendMobileCodeApi: '/server/user_security.act?cmd=fillAntiPhishingSendMobileCode', // 防钓鱼码发送手机验证

  // 常用设备
  EquipmentSendEmailCodeApi: '/server/user_security.act?cmd=addLoginCommonEquipmentSendEmailCode', // 常用设备发送邮箱验证码
  EquipmentSendMobileCodeApi: '/server/user_security.act?cmd=addLoginCommonEquipmentSendMobileCode', // 常用设备发送手机验证码
  EquipmentConfirmApi: '/server/user_security.act?cmd=addLoginCommonEquipmentConfirm', // 常用设备存储
  EquipmentIsTureApi: '/server/user_account.act?cmd=isCommonEquipment', // 是否常用设备存储
  EquipmentDataListApi: '/server/user_account.act?cmd=listLoginEquipmentWithAuth', // 是否常用设备存储
  EquipmenDelApi: '/server/user_account.act?cmd=delLoginEquipment', // 删除常用设备存储
  GetUserGuideApi: '/server/user_guide_config.act?cmd=getUserGuideConfigStatus', // 删除常用设备存储
  UserGuideSetApi: '/server/user_guide_config.act?cmd=userGuideSetAsReminded', // 删除常用设备存储

  // NFT市场
  rechargeList: '/auction/nft/asset/rechargeList', // 充值列表
  withdrawList: '/auction/nft/asset/withdrawList', // 提现列表
  myNFTList: '/auction/nft/asset/list', // 我的NFT列表
  cancelWithdraw: '/auction/nft/withdraw/cancel', // 取消提现 撤销提现 参数：withdrawId
  saleList: '/auction/nft/sale/list', // 获取所有正在拍卖的NFT列表
  listModuleNft: '/auction/nft/sale/listModuleNft', // NFT市场首页接口，全部分类
  assetCount: '/auction/nft/asset/count', // 获取所有类别总数
  saledetail: '/auction/nft/sale/detail', // 获取所有正在拍卖的NFT的详情
  createNFT: '/auction/nft/sale/sell', // 批量上架NFT拍品
  createNFTList: '/auction/nft/sale/batchSell', // 上架NFT拍品
  auctionList: '/auction/nft/auction/list', // 获取某个用户NFT拍卖的所有出价记录
  listBySaleRecordId: '/auction/nft/auction/listBySaleRecordId', // 获取某个NFT拍卖的所有出价记录 查询某个NFT的出价记录 参数：ntfSaleRecordId
  listCirculationRecord: '/auction/nft/sale/listCirculationRecord', // 获取nft项目的记录
  buyNFT: '/auction/nft/auction/bid?cmd=buyNFT', // 购买固定价格的NFT
  saleNFT: '/auction/nft/auction/auction', // 出价拍卖某个NFT
  listMy: '/auction/nft/sale/listMy', // 获取我的正在拍卖的NFT列表
  cancel: '/auction/nft/sale/cancel?', // 撤销我的正在拍卖的NFT列表
  listHistory: '/auction/nft/sale/listHistory', // 我的已售出
  buyOut: '/auction/nft/auction/buyout?cmd=buyOut', // 买断NFT
  // cryptoCup
  giveVote: '/uniswap/tokenvote/projects', // 投票
  getNftCoinType: '/auction/nft/recharge/getNftCoinTypeRechargeList', // 获取可充值NFT币种列表
  apple: '/auction/nft/withdraw/apply?', // 提现
  getNftCoinTypeId: '/auction/nft/nftCoinType/detail', // 获取nft手续费
  // getRechargeAddress: '/auction/nft/recharge/getRechargeAddress', // 获取充值地址
  // U本位赠金接口
  popupQuery: '/uniswap/bonus/popup/query', // 查询首页赠金弹窗
  popupDel: '/uniswap/bonus/popup/del?', // 不再展示首页赠金弹窗
  // U本位止盈止损弹窗
  triggerQuery: '/uniswap/popup/reminder/upstoporder/query', // 查询盈止损弹窗
  triggerClose: '/uniswap/popup/reminder/upstoporder/close', // 不再展示盈止损弹窗
  // 借贷接口
  saveOrder: '/loans/loanCryptoCoin/saveOrder', // 保存订单接口
  getHomeLoanInfo: '/loans/loanCryptoCoin/getHomeLoanInfo', // 首页借贷信息查询
  dajustLtv: '/loans/loanCryptoCoin/adjustLtv', // 调整质押率接口
  repayment: '/loans/loanCryptoCoin/repayment', // 还款接口
  getLoanCoins: '/loans/loanCryptoCoin/getLoanCoins', // 可借可质押币种查询
  getOrderCoinInfo: '/loans/loanCryptoCoin/getOrderCoinInfo', // 查询订单币种信息
  getOngoingOrderDetail: '/loans/loanCryptoCoin/getOngoingOrderDetail', // 进行中订单查询接口
  getBorrowCoinOrderDetail: '/loans/loanCryptoCoin/getBorrowCoinOrderDetail', // 借币订单列表查询接口
  getTotalAssets: '/loans/loanCryptoCoin/getTotalAssets', // 资产总览接口
  getLoansHistory: '/loans/loanCryptoCoin/getLoansHistory', // 借币历史查询
  getLiquidatiogHistory: '/loans/loanCryptoCoin/getLiquidatiogHistory', // 平仓历史查询接口
  getLtvAdjustHistory: '/loans/loanCryptoCoin/getLtvAdjustHistory', // 调整质押率历史查询接口
  getRepaymentHistory: '/loans/loanCryptoCoin/getRepaymentHistory', // 还款历史查询接口
  getUserLoanedAmount: '/loans/loanCryptoCoin/getUserLoanedAmount', // 查用户已借数量
  userIsOpen: '/loans/loanCryptoCoin/userIsOpen', // 获取白名单

  getIeoOrderDetail: '/turbo_starter/turbo/ieo/order', // ieo订单查询
  getInProgressTokenList: '/turbo_starter/turbo/ieo/getInProgressTokenList', // 获取turbo正在进行中的数据
  getCompletedTokenList: '/turbo_starter/turbo/ieo/getCompletedTokenList', // 获取turbo已结束的数据
  getSubscribeInfo: '/turbo_starter/turbo/ieo/getSubscribeInfo', // 获取认购信息
  saveSubscriptionShare: '/turbo_starter/turbo/ieo/saveSubscriptionShare', // 提交turbo
  getTokenDetail: '/turbo_starter/turbo/ieo/getTokenDetail', // 获取详情页面turbo
  getInoList: '/turbo_starter/turbo/ino/list', // 获取Ino信息
  getCheckKycStatus: '/turbo_starter/turbo/ieo/checkKycStatus', // 获取kyc及黑名单

  getNftWithdrawSendMobileCode: '/auction/nft/withdraw/nftWithdrawSendMobileCode', // 获取nft手机验证码
  getNftWithdrawSendEmailCode: '/auction/nft/withdraw/nftWithdrawSendEmailCode', // 获取nft邮箱验证码
  getRechargeAddress: '/auction/nft/recharge/getRechargeAddress', // 获取充值地址
  saleInoList: '/auction/nft/sale/inoList', // 获取Ino
  getCourseList: '/uniswap/course/list', // 查询分类下教程
  getCourseListType: '/uniswap/course/listType', // 查询首页新手导航分类
  functionalBanner: '/uniswap/functionalBanner/list', // 获取首页banner
  adBannerClose: '/uniswap/banner/adBannerClose', // 理财引流图片24h不再展示
  adBannerInfo: '/uniswap/banner/adBannerInfo', // 获取理财引流图片
  applyKnight: '/uniswap/knight/apply', // 提交骑士信息
  exportOrder: {
    exportPerpetualNumber: '/contract/swap/order/exportBillNumber', // 获取当月剩余导出次数
    exportPerpetualAction: '/contract/swap/order/exportHistoricalBill', // 导出用户历史账单
    exportSpotNumber: '/server/tradeFund.act?cmd=coinBillExportCount', // 获取当月剩余导出次数
    exportSpotAction: '/server/tradeFund.act?cmd=coinBillExport', // 导出用户历史账单
    exportUsdtPerpetualNumber: '/uswap/account/exportBillNumber', // 获取当月剩余导出次数
    exportUsdtPerpetualAction: '/uswap/account/coin/ledger/export' // 导出用户历史账单
  },
  getVipLevel: 'server/vipLevelConfig.act?cmd=getVipLevel', // 获取vip等级
  showVipLevel: 'server/vipLevelConfig.act?cmd=showVipLevel', // 查询是否显示弹窗

};
