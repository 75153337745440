import * as types from '../../types';
export default {
  [types.SYMBOL] (state, status) {
    state.symbol = status;
  },
  [types.CONTRACT_LIST] (state, status) {
    state.contractList = status;
  },
  [types.ACCOUNT_INFO] (state, status) {
    state.accountInfo = status;
  },
  [types.CONTRACT_INFO] (state, status) {
    state.contractInfo = status;
  },
  [types.CONTRACT_TICKERS] (state, status) {
    state.contract_tickers = status;
  },
  [types.CONTRACT_All_TICKERS] (state, status) {
    state.contract_all_tickers = status;
  },
  [types.CONTRACT_DEPTHS] (state, status) {
    state.contract_depths = status;
  },
  [types.DEPTH_TYPE] (state, status) {
    state.depthType = status;
  },
  [types.CONTRACT_DEALS] (state, status) {
    state.contract_deals = status;
  },
  [types.PRICE_VOL_INPUT] (state, status) {
    state.priceVolInput = status;
  },
  [types.SYMBOL_SUPPORTED] (state, status) {
    state.symbolSupported = status;
  },
  [types.CONTRACT_INFO_READY] (state, status) {
    state.contractInfoReady = status;
  },
  setOrderCheck (state, status) {
    state.orderCheckConfirm = status;
  },
  commonSet (state, payload) {
    if (state.hasOwnProperty(payload.key)) {
      switch (payload.method) {
        case 'add':
          state[payload.key].push(payload.data);
          break;
        case 'del':
          if (payload.index !== undefined) {
            state[payload.key].splice(payload.index, 1);
          };
          break;
        case 'update':
          if (Array.isArray(state[payload.key])) {
            if (payload.index !== undefined) {
              state[payload.key].splice(payload.index, 1, payload.data);
            };
          } else {
            let obj = {};
            Object.assign(obj, state[payload.key], payload.data);
            state[payload.key] = obj;
          }

          break;
        default:
          state[payload.key] = payload.data;
      }
    }
  }
};
