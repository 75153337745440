export default {
  isLogin: false,
  userBaseInfo: {}, // 会从服务端同步过来的
  curSymbolAssets: { // 当前交易对资产
    commodity: {},
    currency: {}
  },
  userSafeInfo: {}, // 获取用户安全信息
  userSecuritySettings: {},
  authVerify: {},
  bankBinding: {},
  totalAsset: {}, // 用户资产总和
  userMainAssetsList: [], // 用户所有币种的main资产信息
  userTradeAssetsList: [], // 用户所有币种的trade资产信息
  userPerpetualAssetsList: [], // 用户所有币种的perpetual资产信息
  userMt5AssetsList: [], // 用户所有mt5资产信息
  userCappsAssetsList: [], // 用户所有capps资产信息
  userDefiAssetsList: [], // 用户所有defi资产信息
  userUsdtAssetsList: [], // 用户所有U本位资产信息
  coinsInfo: [], // 系统所有数字货币币种信息
  coinCode: 'btc', // 当前币种
  listBoard: [], // 公告列表
  userType: null, // 用户返佣账户类型
  funConfig: [], // 功能显示配置
  vipLevels: { // 阶梯手续费vip等级
    id: '',
    name: '',
    level: -1,
  },
};
