import Big from 'big.js';

/**
 * 仓位价值
 * @param total 合约数量
 * @param hp  持仓价格
 * @returns {*}
 */
export const pv = (total, hp) => {
  if (total && hp) {
    return new Big(total).div(hp);
  }
};
export const usdt_pv = (total, hp) => {
  if (total && hp) {
    return new Big(total).times(hp);
  }
};
/**
 *  未实现盈亏
 *  持仓价值 - 标记价值   卖则相反
 * @param hp  持仓价格
 * @param fp   标记价格
 * @param total  合约数量
 * @param side   方向（买或者卖）
 */
export const unrealisedPNL = (hp, fp, total, side = true) => {
  if (hp && fp && total) {
    const _hp = new Big(1).div(hp);
    const _fp = new Big(1).div(fp);
    const result = _hp.minus(_fp).times(total);
    return side ? result : -result;
  }
};
/**
 *  未实现盈亏
 *  持仓价值 - 标记价值   卖则相反
 * @param hp  持仓价格
 * @param fp   标记价格
 * @param total  合约数量
 * @param side   方向（买或者卖）
 */
export const usdt_unrealisedPNL = (hp, fp, total, side) => {
  if (hp && fp && total) {
    const result = new Big(hp).minus(fp).times(total).times(side ? -1 : 1);
    return result;
  }
};
/***
 *  委托价值
 *  合约数量/委托价格
 *  @param total  合约数量
 * @param op   委托价格
 */
export const ov = (total, op) => {
  if (total && op) {
    return new Big(total).div(op);
  }
};

/**
 *  起始仓位保证金
 *  持仓价值*起始保证金率 + 持仓价值*提取流动性手续费
 * @param pv 持仓价值
 * @param IMR  起始保证金率
 * @param R  提取流动性手续费
 * @returns {*}
 * @constructor
 */
export const IPM = (pv, IMR, R) => {
  if (pv && IMR && R) {
    const _p = new Big(IMR).plus(R);
    return _p.times(pv);
  }
};

/**
 *  盈利率(回报率)
 * @param PNL 未实现盈亏
 * @param IPM 起始仓位保证金
 * @returns {*}
 * @constructor
 */
export const ROE = (PNL, IPM) => {
  if (PNL && IPM && Number(PNL)) {
    return new Big(PNL).div(IPM);
  }
};

/**
 *  实际仓位保证金
 *  起始仓位保证金 + min(0,未实现盈亏)
 * @param IPM 起始仓位保证金
 * @param unrealisedPNL 未实现盈亏
 * @param AM 账户可用保证金
 * @returns {*}
 * @constructor
 */
export const RM = (IPM = 0, unrealisedPNL = 0, AM = 0) => {
  if (IPM && unrealisedPNL && AM) {
    return new Big(AM).plus(unrealisedPNL) >= 0 ? IPM : new Big(IPM).plus(AM).plus(unrealisedPNL);
  }
};

/**
 * 盈利百分比
 * （标记价值-平均开仓价值）/abs(平均开仓价值)
 * @param hp 持仓价格
 * @param fp 标记价格
 * @param total 合约数量
 * @returns {*}
 */
export const pp = (hp, fp, total) => {
  if (hp && fp && total) {
    const _hp = new Big(total).div(hp);
    const _fp = new Big(total).div(fp);
    return _fp.minus(_hp).div(_hp.abs());
  }
};
