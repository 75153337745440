<template>
  <transition name="fade"
              v-on:before-enter="beforeEnter"
              v-on:enter="enter"
              v-on:after-enter="afterEnter"

              v-on:before-leave="beforeLeave"
              v-on:leave="leave"
              v-on:after-leave="afterLeave"
  >
    <!-- mt5event -->
    <!-- missions -->
    <!-- <a class="top-baner" href="https://www.socialtrading.vip/?utm_source=site-head-banner&utm_medium=referral&utm_campaign=social-trading" target="_blank" v-if="showed&&isTargetPage&&activeStatu===0" @click.stop="cancelFun" rel="external nofollow noopener noreferrer"> -->
    <span class="top-baner pointer" v-if="activeBanner && showed" @click="go(activeBanner.jumpUrl)">
      <div ref="top_banner" class="top-banner-box qwe" :style="{backgroundImage:'url('+activeBanner.imgUrl+')'}">
        <span @click.stop="cancelFun"><svg class="ficon icon-cancel" aria-hidden="true">
            <use xlink:href="#bf-icon_fine_closr"></use>
          </svg></span>
      </div>
    </span>
  </transition>
</template>

<script>
  import {mapActions, mapState} from 'vuex';
  // eslint-disable-next-line no-unused-vars
  import {uniswapBannerList} from '~/model/clientSwapModel';

  const bannerMappers = {
    'home': '1',
    'spot': '2',
    'perpetual': '3',
    'eazyswap': '4',
    'mt5': '5'
  };
  export default {
    data () {
      return {
        showed: false,
        isTargetPage: false,
        time: null,
        banners: null,
        activeStatu: 0,
        coinType: null
      };
    },
    computed: {
      ...mapState({
        locale: state => state.common.locale,
        mainLocales: state => state.common.mainLocales
      }),
      filteredLocale () {
        const lang = ['hk', 'en', 'kr', 'ru', 'jp', 'pt-br'].includes(this.locale) ? this.locale : 'en';
        return lang;
      },
      activeBanner () {
        this.showed = false;
        if (this.banners && Object.keys(this.banners).length > 0) {
          let locale = this.filteredLocale;
          if (locale === 'kr') locale = 'ko';
          if (locale === 'pt-br') locale = 'pt';
          let banner = this.findLocalBanner(locale);
          return banner;
        }
        return null;
      }
    },
    async mounted () {
      await this.getBannerList();
    },
    methods: {
      ...mapActions('common', ['setTopSticky']),
      enter (e) {
      },
      beforeEnter (e) {
      },
      afterEnter (e) {
      },
      leave (e) {
      },
      beforeLeave (e) {
      },
      afterLeave (e) {
      },
      findLocalBanner (locale) {
        let routeName;
        if (window.location.href.includes('/mt5')) {
          routeName = 'mt5';
        } else {
          if (this.$route != null) {
            routeName = this.$route.name;
          }
        }
        let localeBanners = this.banners[locale];
        if (!localeBanners) {
          return null;
        }
        let targetPageBanenrs = localeBanners.filter((banner) => {
          // eslint-disable-next-line eqeqeq
          return banner.displayPage == bannerMappers[routeName] && banner.pagePosition == 1;
        });
        for (let banner of targetPageBanenrs) {
          banner.locale = locale;
          // eslint-disable-next-line eqeqeq
          if (banner.displayPage == 2 || banner.displayPage == 3) {
            if ( banner.coinType ) {
              let coinType = this.$route.params.symbol;
              // eslint-disable-next-line eqeqeq
              if (banner.displayPage == 3) {
                coinType = coinType.replace('usd', 'usdt');
              }
              this.coinType = coinType;
              let coinTypes = banner.coinType.split(',');
              if (coinTypes.includes(coinType && coinType.toUpperCase().replace('_', '/'))) {
               return banner;
              }
            } else {
              return banner;
            }
          } else {
            return banner;
          }
        }
        return null;
      },
      async getBannerList () {
        let result = await uniswapBannerList();
        if (result.success) {
          this.banners = result.data;
        }
      },
      go (url) {
        if (!/^http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- ./?%&=]*)?$/.test(url)) {
          return;
        }
        // this.cancelFun();
        window.location.href = url;
      },
      cancelFun () {
        this.showed = false;
        window.sessionStorage.setItem(`showTopBanner_${this.activeBanner.id}${this.coinType}`, 1);
      }
    },
    watch: {
      $route: {
        handler (curVal, oldVal) {
          this.getBannerList();
        },
        immediate: true
      },
      activeBanner (val) {
        if (val) {
          this.showed = !window.sessionStorage.getItem(`showTopBanner_${val.id}${this.coinType}` );
        }
      }
    }
  };
</script>

<style lang="scss" scoped>

  .top-baner {
    height: 90px;
    background-position: center center;
    background-size: cover;
    display: block;
    position: relative;
    /*  width: 100%;
    top: 50px;
    z-index: 10; */
    // background:linear-gradient(-9deg,rgba(26,213,180,1),rgba(20,195,147,1));
    background-color: var(--COLOR-87);
    box-shadow: 9px -27px 42px 0px rgba(61, 102, 91, 0.11);

    > .top-banner-box {
      height: 100%;
      /*max-width: 1200px;*/
      /*width: 1200px;*/
      margin: 0 auto;
      background-size: cover;
      background-position: center center;

      > span {
        position: absolute;
        right: 50px;
        top: 25px;
        background: #14b691;
        padding: 10px;
        cursor: pointer;
      }
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    height: 0;
  }

</style>
