import * as types from '../../../types';
export default {
  [types.TRADE_TYPE] (state, status) {
    state.tradeType = status;
  },
  [types.SYMBOLS_INFO] (state, status) {
    state.symbolsInfo = status;
  },
  [types.CUR_SYMBOL_INFO] (state, status) {
    state.curSymbolInfo = status;
  },
  [types.SYMBOLS_INFO_READY] (state, status) {
    state.symbolsInfoReady = status;
  },
  [types.SYMBOL_SUPPORTED] (state, status) {
    state.symbolSupported = status;
  },
  [types.SYMBOL] (state, status) {
    state.symbol = status;
  },
  [types.PTO_LIST] (state, status) {
    state.ptoList = status;
  },
  [types.ALLCOIN] (state, status) {
    state.allCoin = status;
  },
  [types.TICKERS] (state, status) {
    const curTicker = state.tickers[state.symbol];
    if (curTicker) {
      status[state.symbol] = Object.assign({}, status[state.symbol], curTicker);
    }
    // console.log('obj----', status[state.symbol]);
    state.tickers = status;
  },
  [types.UPDATE_TICKER] (state, status) {
    const oldTicker = state.tickers[status.symbol] || {};
    const newTicker = Object.assign({}, oldTicker, status);
    // console.log('update ticker', status.symbol, newTicker);
    state.tickers[status.symbol] = newTicker;
  },
  [types.CURRENCY_CODE] (state, status) {
    state.currencyCode = status;
  },
  [types.COMMODITY_CODE] (state, status) {
    state.commodityCode = status;
  },
  [types.DEPTHS] (state, status) {
    state.depths = status;
  },
  [types.DEPTH_TYPE] (state, status) {
    state.depthType = status;
  },
  [types.DEALS] (state, status) {
    state.deals = status;
  },
  [types.USERSAFEINFO] (state, status) {
    state.userSafeInfo = status;
  },
  [types.USER_SECURITY_SETTINGS] (state, status) {
    state.userSecuritySettings = status;
  },
  [types.PRICE_VOL_INPUT] (state, status) {
    state.priceVolInput = status;
  },
  [types.OPEN_ORDERS] (state, status) {
    state.openOrders = status;
  },
  [types.WS_GROUP_NAME_CACHE] (state, status) {
    state.wsGroupNameCache = status;
  }

};
