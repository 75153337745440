// Ripple-effect animation
/* export default () => {
  document.querySelector('#app').onclick = function (e) {
    let rippler;
    let hasRippler = false; // 本身或者父元素是否包含'.ripple-effect'类
    for (let i = 0; i < e.path.length - 2; i++) { // document 和 window 没有classList
      if (e.path[i].classList.contains('ripple-effect')) {
        rippler = e.path[i];
        hasRippler = true;
        e.stopPropagation();
        break;
      }
    }
    if (!hasRippler) return false;

    // create .ink element if it doesn't exist
    let ink;
    if (rippler.children.length > 0 && rippler.children[rippler.children.length - 1].classList.contains('ink')) { // 最后一个子元素是否包含'.ink'类
      ink = rippler.children[rippler.children.length - 1];
    } else {
      let child = document.createElement('span');
      child.className = 'ink';
      rippler.appendChild(child);
      ink = child;
    }

    // prevent quick double clicks
    ink.className = 'ink';

    // set .ink diametr
    if (!ink.offsetHeight && !ink.offsetWidth) {
      const d = Math.max(rippler.offsetWidth, rippler.offsetHeight);
      ink.style.width = d + 'px';
      ink.style.height = d + 'px';
    }

    // get click coordinates
    const x = e.pageX - rippler.offsetLeft - ink.style.width.replace('px', '') / 2;
    const y = e.pageY - rippler.offsetTop - ink.style.height.replace('px', '') / 2;

    // set .ink position and add class .animate
    ink.style.top = y + 'px';
    ink.style.left = x + 'px';
    ink.className += ' animate';
  };
}; */

// jQuery版本
/* eslint-disable */
import $ from 'jquery';
export default () => {
  $('#app').on('click', '.ripple-effect', function(e) {
    var rippler = $(this);

    // create .ink element if it doesn't exist
    if (rippler.find('.ink').length === 0) {
      rippler.append('<span class="ink"></span>');
    }

    var ink = rippler.find('.ink');

    // prevent quick double clicks
    ink.removeClass('animate');

    // set .ink diametr
    if (!ink.height() && !ink.width()) {
      var d = Math.max(rippler.outerWidth(), rippler.outerHeight());
      ink.css({
        height: d,
        width: d
      });
    }

    // get click coordinates
    var x = e.pageX - rippler.offset().left - ink.width() / 2;
    var y = e.pageY - rippler.offset().top - ink.height() / 2;

    // set .ink position and add class .animate
    ink.css({
      top: y + 'px',
      left: x + 'px'
    }).addClass('animate');

    setTimeout(() => {
      ink.removeClass('animate');
    }, 500);
  })
};
