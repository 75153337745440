import Message from './message.vue';
import Vue from 'vue';
import i18n from '~/locales';

const defaultOptions = {
  visible: false,
  title: '',
  message: '',
  status: '',
  time: 0,
  dialogType: '',
  showClose: true,
  showCancelButton: true,
  showSureButton: true
  /* cancelButtonText: i18n.t('order_45'),
  sureButtonText: i18n.t('order_44') */
};

const VueMsg = Vue.extend(Message);
let instance = new VueMsg();
let msgQueue = [];
const showMsg = () => {
  if (msgQueue.length && !instance.visible && !instance.time) {
    let vm = instance.$mount();
    let options = msgQueue.shift();
    for (let key in options) {
      if (options.hasOwnProperty(key)) {
        instance[key] = options[key];
      }
    }
    instance.cancelButtonText = options['cancelButtonText'] || i18n.t('order_45');
    instance.sureButtonText = options['sureButtonText'] || i18n.t('order_44');
    document.body.appendChild(vm.$el);
    Vue.nextTick(() => {
      instance.visible = true;
    });
    if (instance.time) {
      setTimeout(closeMsg, instance.time);
    }
  }
};
const closeMsg = () => {
  instance.visible = false;
  instance.time = 0;
  showMsg();
};
const Msg = function (options, callBack) {
  if (typeof options === 'string') {
    options = {
      message: options

    };
    if (typeof arguments[1] === 'string') {
      options.title = arguments[1];
    }
  }
  options.sureFn = () => {
    callBack && callBack();
    showMsg();
  };

  if (!options.cancelFn) {
    options.cancelFn = closeMsg;
  } else {
    let oldCancel = options.cancelFn;
    options.cancelFn = () => {
      oldCancel();
      showMsg();
    };
  }
  options.onclose = closeMsg;
  msgQueue.push(options);
  showMsg();
};

export const confirm = (message, title, sureFn, options) => {
  if (typeof title === 'function') {
    sureFn = title;
    title = '';
  }
  return Msg(Object.assign({}, defaultOptions, {
    message,
    title,
    dialogType: 'confirm'
  }, options), sureFn);
};
export const prompt = (message, sureFn, options) => {
  return Msg(Object.assign({}, defaultOptions, {
    message,
    dialogType: 'prompt',
    showClose: false,
    options
  }), sureFn);
};
export const alert = (message, status = '', options) => {
  return Msg(Object.assign({}, defaultOptions, {
    title: message,
    status,
    dialogType: 'alert',
    showCancelButton: false,
    showSureButton: false,
    showClose: false,
    time: 2000
  }, options));
};
export const alertMore = (message, status = '', options) => {
  return Msg(Object.assign({}, defaultOptions, {
    message,
    status,
    title: status === 'success' ? '成功' : '失败',
    dialogType: 'alertMore',
    showCancelButton: false,
    showSureButton: false,
    showClose: false,
    time: 2000
  }, options));
};
