<template>
  <footer id="footer" :theme="footerTheme" :class="{'trade-page': isTradePage}">
    <el-collapse v-model="activeName">
      <el-collapse-item :title="$t('footer_7')" name="1">
        <div>
          <a :href="`/${locale}/AboutUs`">{{$t('footer_8')}}</a>
        </div>
        <div>
          <a :href="`/${locale}/Capital`">{{$t('footer_37')}}</a>
        </div>
        <div>
          <a :href="`/${locale}/JoinUs`">{{$t('footer_11')}}</a>
        </div>
        <div>
          <a :href="`/${locale}/ContactUs`">{{$t('footer_12')}}</a>
        </div>
        <div>
                <a :href="`/${locale}/vulnerability`">{{ $t('vulnerability_1') }}</a>
              </div>
              <div>
                <router-link to="/knightpage">{{$t('footer_6')}}</router-link>
              </div>
              <div>
                <a :href="`/${locale}/Fees`">{{$t('footer_15')}}</a>
              </div>
              <!-- Terms of -->
              <div>
                <a :href="$t('link_terms')" target="_blank" rel="external nofollow noopener noreferrer">{{$t('footer_14')}}</a>
              </div>
              <div  v-if="['en', 'pt'].includes(locale)">
                <a :href="`/${locale}/academy`">{{$t('academy_5')}}</a>
              </div>
      </el-collapse-item>
      <el-collapse-item :title="$t('home_v2_40')" name="2">
        <div>
          <router-link :to="`/buyCoins`">{{$t('header_buy')}}</router-link>
        </div>
        <div>
          <router-link :to="`/spot/${spotSymbol}`">{{$t('footer_2')}}</router-link>
        </div>
        <div>
          <router-link :to="`/perpetual/${perpetualSymbol}`">{{$t('footer_3')}}</router-link>
        </div>
        <div>
                <a :href="`/${locale}/asset-management/divst`">{{$t('home_v2_88')}}</a>
              </div>
              <div>
                <router-link :to="`/nft-market/index/market`">NFT Marketplace</router-link>
              </div>
              <div>
                <a :href="`/${locale}/starter/index`">Turbo Starters</a>
              </div>
              <div>
                <a :href="`/${locale}/loan/index`">{{$t('home_v2_125')}}</a>
              </div>
      </el-collapse-item>
      <el-collapse-item :title="$t('footer_31')" name="3">
        <div><a :href="`/${locale}/Support`">{{$t('footer_17')}}</a></div>
              <div>
                <a :href="`https://support.bitforex.com/hc/${curZendeskLang}/categories/360000629712`" target="_blank" rel="external nofollow noopener noreferrer">{{$t('footer_36')}}</a>
              </div>
              <div>
                <a :href="$t('link_request')" target="_Blank" rel="external nofollow noopener noreferrer" @click="handleRequest">{{$t('footer_24')}}</a>
              </div>
              <div>
                <a :href="$t('link_api')" target="_Blank" rel="external nofollow noopener noreferrer" @click="handleRequest">{{$t('home_v2_130')}}</a>
              </div>
              <div>
                <a :href="$t('support_30')" target="_Blank" rel="external nofollow noopener noreferrer">{{$t('support_4')}}</a>
              </div>
              <div>
                <a :href="`https://forms.gle/ZnPvmvuvYPbEgpCLA`" target="_blank">{{$t('footer_listing_application')}}</a>
              </div>
              <div>
                <a :href="`/${locale}/officialverify`">{{$t('media_1')}}</a>
              </div>
      </el-collapse-item>
      <el-collapse-item :title="$t('home_v2_28')" name="4">
        <div>
                <a :href="`/${locale}/spot/bf_usdt`">{{$t('home_v2_41')}}</a>
              </div>
              <div>
                <a :href="`/${locale}/spot/btc_usdt`">{{$t('home_v2_42')}}</a>
              </div>
              <div>
                <a :href="`/${locale}/spot/eth_usdt`">{{$t('home_v2_43')}}</a>
              </div>
              <div>
                <a :href="`/${locale}/spot/omi_usdt`">{{$t('home_v2_44')}}</a>
              </div>
              <div>
                <a :href="`/${locale}/spot/doge_usdt`">{{$t('home_v2_45')}}</a>
              </div>
      </el-collapse-item>
    </el-collapse>
    <a class="Community" :href="`/${locale}/ContactUs`">{{$t('footer_12')}}</a>
    <div class="icon">
      <div v-if="locale !== 'kr'">
      <a :href="$t('link_telegram')" target="_blank" rel="external nofollow noopener noreferrer">
        <svg class="ficon" aria-hidden="true">
          <use xlink:href="#bf-icon_telegram"></use>
        </svg>
      </a>
      </div>
      <div>
      <a href="https://discord.com/invite/mGNxBxzk8b" target="_blank" rel="external nofollow noopener noreferrer">
        <svg class="ficon" aria-hidden="true">
          <use xlink:href="#bf-discord-fill"></use>
        </svg>
      </a>
      </div>
      <div>
      <a :href="$t('link_twitter')" target="_blank" rel="external nofollow noopener noreferrer">
        <svg class="ficon" aria-hidden="true">
          <use xlink:href="#bf-icon_twitter"></use>
        </svg>
      </a>
      </div>
      <div>
      <a :href="$t('link_facebook')" target="_blank" rel="external nofollow noopener noreferrer">
        <svg class="ficon" aria-hidden="true">
          <use xlink:href="#bf-facebook-box-fill"></use>
        </svg>
      </a>
      </div>
      <div>
      <a :href="$t('link_instagram')" target="_blank" rel="external nofollow noopener noreferrer">
        <svg class="ficon" aria-hidden="true">
          <use xlink:href="#bf-instagram-fill"></use>
        </svg>
      </a>
      </div>
      <div>
      <a :href="$t('link_linkedin')" target="_blank" rel="external nofollow noopener noreferrer">
        <svg class="ficon" aria-hidden="true">
          <use xlink:href="#bf-linkedin-box-fill"></use>
        </svg>
      </a>
      </div>
      <div>
      <a :href="$t('link_youtube')" target="_blank" rel="external nofollow noopener noreferrer">
        <svg class="ficon" aria-hidden="true">
          <use xlink:href="#bf-youtube-fill"></use>
        </svg>
      </a>
      </div>
    </div>
    <p class="copy-right">Copyright &copy; 2017-2023 bitforex.com All Rights Reserved.</p>
  </footer>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { setCookie } from '../../utils/cookieUtil';
export default {
  name: 'Footer',
  data () {
    return {
      activeName: ['0'],
      isBiBi: false, // 是否币币交易页面
      isSimulationPerpetual: false, // 是否永续模拟页面
      spotSymbol: '',
      perpetualSymbol: '',
      langs: [
        { code: 'en', name: 'English' },
        { code: 'kr', name: '한국어' },
        { code: 'ru', name: 'Русский' },
        // { code: 'jp', name: '日本語' },
        { code: 'vn', name: 'Tiếng Việt' },
        { code: 'pt', name: 'Português' },
        { code: 'es', name: 'Español' },
        { code: 'tr', name: 'Türk dili' },
        // { code: 'cn', name: '简体中文' },
        { code: 'hk', name: '繁體中文' }
      ],
      friendshipLink: [{
          name: 'Coinmarketcap',
          link: 'https://coinmarketcap.com/'
        },
        {
          name: 'CoinQuora',
          link: 'https://coinquora.com/'
        },
        {
          name: 'BitUniverse',
          link: 'https://www.bituniverse.org/'
        },
        {
          name: 'Coindesk',
          link: 'https://www.coindesk.com/'
        },
        {
          name: 'Coinhills',
          link: 'https://www.coinhills.com/'
        },
        {
          name: 'CoinGecko',
          link: 'https://www.coingecko.com/en'
        },
        {
          name: 'Xangle',
          link: 'https://xangle.io/'
        },
        {
          name: 'Neironix',
          link: 'https://neironix.io/'
        }
      ]
    };
  },
  computed: {
    ...mapState({
      theme: state => state.common.theme,
      locale: state => state.common.locale,
      mainLocales: state => state.common.mainLocales,
      isTradePage: state => state.common.isTradePage
    }),
    ...mapGetters({
      funConfigs: 'user/funConfigs'
    }),
    curZendeskLang () {
      switch (this.locale) {
        case 'cn':
          return 'zh-cn';
        case 'kr':
          return 'ko';
        case 'hk':
          return 'zh-tw';
        case 'ru':
          return 'ru';
        case 'jp':
          return 'ja';
        // case 'vn':
        //   return 'vi';
        default:
          return 'en-us';
      }
    },
    curLang () {
      const index = this.langs.findIndex(item => {
        return item.code === this.locale;
      });
      return this.langs[index];
    },
    footerTheme () {
      return this.isTradePage
      ? this.theme
      : this.$route.name === 'bftokeninfo' || this.$route.name === 'perpetual_trading_competition' || this.$route.path.includes('/cryptoCup') || this.$route.path.includes('/buyCoins') || this.$route.path.includes('/NFT-BlindBox') || this.$route.path.includes('/redemptionCode')
      ? 'black'
      : 'white';
    }
  },
  methods: {
    setLang (base) {
      let lang = window.location.pathname.split('/')[1];
      if (lang) {
        let url = window.location.origin + window.location.pathname.replace(lang, base) + window.location.search;
        window.location.href = url;
      } else {
        let url = window.location.href;
        window.location.href = url + base + '/';
      }
      this.$store.commit('common/LOCALE', base);
      setCookie('lang', base, 'y1');
    },
    handleRequest () {
      // window.sensors.track('ViewWorksheet', {
      //   locate_page: window.location.href
      // }, this.$log('访问提交工单'));
    },
    handleHelp () {
      // window.sensors.track('ViewHelpCenter', {
      //   locate_page: window.location.href
      // }, this.$log('访问帮助中心'));
    }
  },
  watch: {
    '$route': {
      handler (curVal, oldVal) {
        if (curVal.path.indexOf('/spot') > -1) {
          this.isBiBi = true;
        } else {
          this.isBiBi = false;
        }
        if (curVal.path.indexOf('/simulation-perpetual') > -1) {
          this.isSimulationPerpetual = true;
        } else {
          this.isSimulationPerpetual = false;
        }
        this.spotSymbol = window.sessionStorage.getItem('spotSymbol') || '';
        this.perpetualSymbol = window.sessionStorage.getItem('perpetualSymbol') || '';
      },
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>

@import "./footer.scss";

</style>
