export default {
  symbol: '', // 交易对
  symbolsInfo: {}, // 所有交易对配置信息
  symbolsInfoReady: false,
  symbolSupported: false, // 是否支持的交易对
  curSymbolInfo: {}, // 当前交易对的详细配置信息
  tradeType: 1,
  tickers: {}, // 所有交易对tickers
  // curTicker: {}, // 当前交易对的最新ticker
  ptoList: [], // 认购中(PTO)的交易对
  allCoin: {},
  // currencyCode: 'USDT', // 法币
  // commodityCode: 'BTC', // 数字货币
  depths: {}, // 深度数据
  depthType: 0, // 深度参数
  deals: [], // 最新成交

  priceVolInput: [], // 买卖价格

  openOrders: [], // 未成交委托

  wsGroupNameCache: {} // 缓存交易对wsGroup
};
