import Big from 'big.js';
import moment from 'moment';
Big.NE = -9;
// moment.locale('pt')
// 前端自定义错误格式
export const createError = (code, msg) => {
  // console.log(code);
  const err = msg;
  // const err = new Error(msg);
  // err.code = code;
  return err;
};

// export function auth () {
//   if (!this.isLogin) {
//     this.$router.push('/login');
//     return false;
//   }
//   return true;
// };

// 重名币特殊处理 symbol code: btc_usdt
export const filterCommodity = (code) => {
  if (!code) {
    return '';
  } else if (code === 'LCPLUS') {
    return 'LC+';
  } else {
    return code;
  }
};
// index === 1
// 当换算后的法币数值 大于等于 1 时，应展示何种小数精度，取相应的接口精度数据（常规精度）。
// 当换算后的法币数值 小于 1 且大于 0.01 时，展示2位小数。（舍位，末尾不补0）
// 当换算后的法币数值 小于 0.01 时，展示 < {symbol} 0.01
// index === 2
// 当换算后的法币数值 大于等于 1 时，应展示何种小数精度，取相应的接口精度数据（常规精度）。
// 当换算后的法币数值 小于 1 且大于 0.0001 时，展示4位小数。（舍位，末尾不补0）
// 当换算后的法币数值 小于 0.0001 时，展示8位小数.
// 小于 0.00000001 时直接取 0.00000001。
// index === 3
// 任何时候都取相应的接口精度数据（常规精度）。
// 数值小于接口精度最小值时，等于 0。
export const filterCurrency = (item, currentCurrency, index) => {
  const number = Number(item)
  if (number && currentCurrency && currentCurrency.usdToThisRate) {
    const returnNumber = Big(number).times(currentCurrency.usdToThisRate).valueOf();
    if (index === 1) {
      if (returnNumber >= 1) {
        return formatNum(returnNumber, currentCurrency.precision);
      } else if (returnNumber >= 0.01 && returnNumber < 1) {
        return formatNum(returnNumber, 2);
      } else {
        return 0.01;
      }
    } else if (index === 2) {
      if (returnNumber >= 1) {
        return formatNum(returnNumber, currentCurrency.precision);
      } else if (returnNumber >= 0.0001 && returnNumber < 1) {
        return formatNum(returnNumber, 4);
      } else if (returnNumber >= 0.00000001 && returnNumber < 0.0001){
        return formatNum(returnNumber, 8);;
      } else {
        return 0.00000001
      }
    } else if (index === 3) {
      return formatNum(returnNumber, currentCurrency.precision);
    }
  } else {
    return item;
  }
}

export const getCookie = (name) => {
  let arr;
  let reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');
  arr = document.cookie.match(reg);
  if (arr) {
    return unescape(arr[2]);
  } else {
    return null;
  }
};

// 节流函数
export const throttle = (method, duration, delay) => {
  let timer = null;
  // 记录下开始执行函数的时间
  let begin = new Date();
  return function () {
    let context = this;
    let args = arguments;
    // 记录下当前时间
    let current = new Date();
    // 函数节流里的思路
    clearTimeout(timer);
    // 记录下的两个时间相减再与duration进行比较
    if (current - begin >= duration) {
      method.apply(context, args);
      begin = current;
    } else {
      timer = setTimeout(function () {
        method.apply(context, args);
      }, delay);
    }
  };
};

// 防抖函数
export const debounce = (callback, delay) => {
  let timer = null;
  return function () {
    let context = this;
    clearTimeout(timer);
    timer = setTimeout(() => {
      callback.apply(context, arguments);
      clearTimeout(timer);
    }, delay);
  };
};

// 过滤深度图买
export function filterDeepBuy (arrParam) {
  // console.log(arrParam);
  if (!arrParam) return [];
  let arr = JSON.parse(JSON.stringify(arrParam));
  if (!arr) return [];
  // if (arr.length > 20) {
  //   arr = arr.slice(0, 20);
  // }
  let maxVal = 0;
  arr.sort((a, b) => {
    return a.price - b.price;
  });
  for (let i = 0; i < arr.length; i++) {
    if (i === 0) {
      arr[i]['total'] = arr[i]['amount'];
    } else {
      arr[i]['total'] = new Big(Number(arr[i - 1]['total'])).plus(Number(arr[i]['amount'])).valueOf();
    }
    if (Number(arr[i]['amount']) > Number(maxVal)) {
      maxVal = arr[i]['amount'];
    }
  }
  let arrr = arr;
  arrr.reverse();
  for (let i = 0; i < arr.length; i++) {
    arr[i]['computedBG'] = (arr[i]['total'] / arrr[0]['total']) * 100;
    arr[i]['maxVal'] = maxVal;
  }
  arr.reverse();
  return arr;
}

// 过滤深度图卖
export function filterDeepSell (arrParam) {
  if (!arrParam) return [];
  let arr = JSON.parse(JSON.stringify(arrParam));
  if (!arr) return [];
  let maxVal = 0;
  arr.sort((a, b) => {
    return b.price - a.price;
  });
  for (let i = 0; i < arr.length; i++) {
    if (i === 0) {
      arr[i]['total'] = arr[i]['amount'];
    } else {
      arr[i]['total'] = new Big(Number(arr[i - 1]['total'])).plus(Number(arr[i]['amount'])).valueOf();
    }
    if (Number(arr[i]['amount']) > Number(maxVal)) {
      maxVal = arr[i]['amount'];
    }
  }
  let arrr = arr;
  arrr.reverse();
  for (let i = 0; i < arr.length; i++) {
    arr[i]['computedBG'] = (arr[i]['total'] / arrr[0]['total']) * 100;
    arr[i]['maxVal'] = maxVal;
  }
  arrr.reverse();
  return arr;
}

export const formatDataTime = (val, formatString) => {
  let dataTime = new Date(val - 0);
  let date = {
    'M+': dataTime.getMonth() + 1,
    'd+': dataTime.getDate(),
    'h+': dataTime.getHours(),
    'm+': dataTime.getMinutes(),
    's+': dataTime.getSeconds(),
    'q+': Math.floor((dataTime.getMonth() + 3) / 3),
    'S+': dataTime.getMilliseconds()
  };
  if (/(y+)/i.test(formatString)) {
    formatString = formatString.replace(RegExp.$1, (dataTime.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  for (let k in date) {
    if (new RegExp('(' + k + ')').test(formatString)) {
      formatString = formatString.replace(RegExp.$1, RegExp.$1.length === 1
        ? date[k] : ('00' + date[k]).substr(('' + date[k]).length));
    }
  }
  return formatString;
};
/**
 * 格式化数字精度
 * @param {string|number} val val
 * @param {number} precision 精度
 * @param {boolean} isRound true 向上取值，false 向下取值
 */
export const formatNum = (val, precision, isRound = false, isWithOutZero = false) => {
  if (typeof val === 'undefined' || val === null || Number(val) === Infinity || isNaN(val) || val === 0 || val === '0' || Number(val) === 0) {
    val = 0;
  }
  if (precision === undefined && val) { // 无精度，处理科学计数法
    return Big(Number(val)).valueOf();
  }
  try {
    val = Big(Number(val)).round(precision, isRound ? 3 : 0).toFixed(precision);
    if (isWithOutZero) {
      const arr = String(val).split('.');
      if (arr.length > 1 && arr[1] !== '') {
        const right = arr[1].replace(/0+$/, '');
        val = `${arr[0]}${right ? '.' + right : ''}`;
      }
    }
    return val;
  } catch (err) {
    console.error(err);
    return val;
  }
};

/**
 * 处理小数点后有效数字
 */
export const formatNumByValid = (val, precision, isRound = false) => {
  if (typeof val === 'undefined' || val === null || Number(val) === Infinity || isNaN(val) || val === 0 || val === '0' || Number(val) === 0) {
    val = 0;
  }
  let valueStr = Number(val) + '';
  if (valueStr.indexOf('.') !== -1) {
    // let decimalPart = valueStr.match(new RegExp('(?<=\\.)0*'))[0];
    let decimalPart = valueStr.match(new RegExp('(?:\\.)0*'))[0];
    precision = precision + decimalPart.length - 1;
  }
  if (precision) {
    return formatNum(val, precision, isRound);
  } else {
    return formatNum(val, undefined, isRound);
  }
};

/**
 * 去除数字字符串后的空0
 * @param str
 * @returns {number}
 */
export const toNumber = (str) => {
  return Number(str);
};
export const formatNum2 = (val, precision, isRound = false) => {
  // 特殊值时
  if (typeof val === 'undefined' || val === null || Number(val) === Infinity) {
    return '0.00000000'.slice(0, precision + 2);
  }
  if (val === 0 || val === '0' || Number(val) === 0) {
    return '0.00000000'.slice(0, precision + 2);
  }
  // 四舍五入时
  if (isRound) {
    return Number(val).toFixed(precision);
  }

  let valStr = Number(val).toFixed(precision + 2); // 可以转换科学计数法
  let ret = valStr.substr(0, valStr.length - 2);
  return ret;
};

export const doubleDigit = ( val) => {
  return val < 10 ? '0' + val : val;
};

export const toInteger = (val, formatLen = 6) => {
  if (isNaN(Number(val))) {
    throw new Error(val + ' not a number!');
  }
  if (val !== val + '') {
    val = val.toString();
  }
  if (val.indexOf('.') < 0) {
    return val;
  }
  let valArr;
  valArr = val.split('.');
  if (valArr[0].length >= formatLen) {
    return valArr[0];
  }
  return val;
};

// 金额，三行逗号隔开
export const formatMoney = (num) => {
  if (num === undefined || num === null || Number(num) === Infinity) return 0;
  if (!num || !Number(num)) return 0;
  let isNegative = false; // 负数？
  if (Number(num) < 1000 && Number(num) > 0) {
    return num;
  }
  if (Number(num) < 0) {
    isNegative = true;
    // num = Math.abs(num);
    num = num.toString();
    num = num.replace('-', '');
  }

  let right = new Big(num).toFixed().split('.')[1];
  right = (right === undefined) ? '' : right; // 有小数位？
  num = new Big(num).toFixed().split('.')[0];
  let len = num.length;
  let r = len % 3;
  if (len > 3) {
    num = r > 0 ? num.slice(0, r) + ',' + num.slice(r, len).match(/\d{3}/g).join(',') : num.slice(r, len).match(/\d{3}/g).join(',');
  };
  if (right) {
    num = num + '.' + right;
  };
  if (isNegative) {
    num = '-' + num;
  };
  return num;
};

// 数字转 million billion，保留两位小数
export const numFormatStr = (num) => {
  if (num === undefined || num === null || Number(num) === Infinity) return 0;
  if (!num || !Number(num)) return 0;
  if (num === 0) return 0;
  /*
    million：百万
    billion：十亿
  */
  let num_str = '';
  if (num >= 1000000000) {
      num = num / 1000000000;
      num = num.toFixed(2);
      num_str = parseFloat(num) + ' B';
  } else if (num >= 1000000) {
      num = num / 1000000;
      num = num.toFixed(2);
      num_str = parseFloat(num) + ' M';
  } else {
    num_str = num;
  }
  return num_str;
};
// 格式化数字成K,M等格式
export const nFormatter = (num, digits) => {
  const si = [
      { value: 1, symbol: "" },
      { value: 1E3, symbol: "K" },
      { value: 1E6, symbol: "M" },
      { value: 1E9, symbol: "G" },
      { value: 1E12, symbol: "T" },
      { value: 1E15, symbol: "P" },
      { value: 1E18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
          break;
      }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}

// 字符串转交易对
export const formatTokenPair = (str) => {
  let str_arr = str.split('-');
  return str_arr[2].toUpperCase() + '/' + str_arr[1].toUpperCase();
};

// 根据毫秒数格式化剩余时间
export const formatTimeRemain = (msec) => {
  if (!msec) {
    return '0S';
  }
  if (msec < 0) {
    return 0;
  }
  let D = 24 * 3600 * 1000; // 一天
  let H = 3600 * 1000; // 一小时
  let m = 60 * 1000; // 一分钟
  let remainD;
  let remainH;
  let remainM;
  let remainS;
  remainD = parseInt(msec / D);
  remainH = parseInt((msec % D) / H);
  remainM = parseInt(((msec % D) % H) / m);
  remainS = parseInt(((msec % D) % H) % m / 1000);

  /* if (remainD > 0) {
    return remainD + ' D';
  } */
  if (remainD > 0) {
    return remainD + 'D : ' + remainH + 'H : ' + remainM + 'M : ' + remainS + 'S';
  } else {
    return remainH + 'H : ' + remainM + 'M : ' + remainS + 'S';
  }
};

export const formatTime = function (time, fmt = 'yyyy-MM-dd hh:mm:ss') {
  const Time = new Date(time);
  const o = {
    'M+': Time.getMonth() + 1, // 月份
    'd+': Time.getDate(), // 日
    'h+': Time.getHours(), // 小时
    'm+': Time.getMinutes(), // 分
    's+': Time.getSeconds(), // 秒
    'q+': Math.floor((Time.getMonth() + 3) / 3), // 季度
    'S': Time.getMilliseconds() // 毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (Time.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)));
    }
  }
  return fmt;
};

export const getLangLocal = (lang) => {
  switch (lang) {
    // case 'cn':
    //   return 'zh';
    case 'en':
      return 'en-US';
    case 'kr':
      return 'ko-KR';
    case 'ru':
      return 'ru-RU';
    case 'pt-br':
      return 'pt-BR';
    case 'jp':
      return 'ja-JP';
    case 'vn':
      return 'vi';
    case 'pt':
      return 'pt-BR';
    case 'es':
      return 'es';
    case 'tr':
      return 'tr';
    case 'hk':
      return 'cn-HK';
    default:
      return 'en-US';
  }
};

export const getMomentLocale = (lang) => {
  switch (lang) {
    case 'cn':
      return 'zh-cn';
    case 'en':
      return 'en';
    case 'kr':
      return 'ko';
    case 'ru':
      return 'ru';
    case 'jp':
      return 'ja';
    case 'vn':
      return 'vi';
    case 'pt':
      return 'pt';
    case 'es':
      return 'es';
    case 'tr':
      return 'tr';
    case 'hk':
      return 'zh-hk';
    default:
      return 'en';
  }
};

export const getBrowserLang = () => {
  let lang = navigator.language || navigator.userLanguage;
  lang = lang.substr(0, 2);
  switch (lang) {
    // case 'zh':
    //   return 'cn';
    case 'en':
      return 'en';
    case 'ko':
      return 'kr';
    case 'ru':
      return 'ru';
    case 'ja':
      return 'jp';
    case 'vi':
      return 'vn';
    case 'pt':
      return 'pt';
    case 'es':
      return 'es';
    case 'tr':
      return 'tr';
    default:
      return 'en';
  }
};

export const sumStrings = (a) => {
  a = a.toString();
  var indexOfE = a.indexOf('E');
  if (indexOfE < 0) {
    indexOfE = a.indexOf('e');
  }
  if (indexOfE === -1) {
    return a;
  }
  var indexOfFloat = a.indexOf('.');
  var zs = a.substr(0, indexOfE);
  var xs = a.substring(indexOfE + 1);
  xs = Number(xs);
  if (xs < 0) {
    xs = Math.abs(xs);
  }
  var float = '';
  while (xs--) {
    float += '0';
  }
  if (indexOfFloat < 0) {
    float = float.substring(1);
    zs = '0.' + float + zs;
    return zs;
  }
  var zsArr = zs.split('.');
  float = float.substr(0, float.length - zsArr[0].length);
  zs = zs.replace('.', '');
  zs = '0.' + float + zs;
  return zs;
};

/*
 * js数组排序 支持数字和字符串
 * @param attr   string     必填  属性名称
 * @param type  boolean  选填 默认非数字
 * @param rev     boolean     选填  默认true 正顺  false反顺
 */
export const sortBy = (attr, type = false, rev = true) => {
  rev = (rev) ? 1 : -1;
  return function (a, b) {
    a = a[attr];
    b = b[attr];
    if (type) {
      if (rev > 0) {
        return Number(a) - Number(b);
      } else {
        return Number(b) - Number(a);
      }
    } else {
      if (a < b) {
        return rev * -1;
      }
      if (a > b) {
        return rev * 1;
      }
      return 0;
    }
  };
};

/*
 * js数组排序 支持数字和字符串
 * @param attr   string     必填  属性名称
 * @param rev     boolean     选填  默认true 正顺  false反顺
 */
export const sortByDate = (attr, rev = true) => {
  return function (a, b) {
    a = a[attr];
    b = b[attr];
    if (rev) {
      return new Date(a).getTime() - new Date(b).getTime();
    } else {
      return new Date(b).getTime() - new Date(a).getTime();
    }
  };
};

/**
 * 判断是否移动端
 */
export const isMobile = () => {
  let sUserAgent = navigator.userAgent.toLowerCase();
  let deviceTypeList = ['ipad', 'android', 'iphone', 'ipod'];
  for (let i = 0; i < deviceTypeList.length; i++) {
    if (sUserAgent.indexOf(deviceTypeList[i]) !== -1) {
      return true;
    }
  }
  return false;
};

/**
 * 判断是否为iOS设备
 */
export const isIOS = () => {
  let sUserAgent = navigator.userAgent.toLowerCase();
  console.log(sUserAgent);
  let deviceTypeList = ['ipad', 'iphone', 'ipod'];
  for (let i = 0; i < deviceTypeList.length; i++) {
    if (sUserAgent.indexOf(deviceTypeList[i]) !== -1) {
      return true;
    }
  }
  return false;
};

/**
 * 判断是否IE9以下
 */

export const LowerIE9 = () => {
  let ua = window.navigator.userAgent.toLowerCase();
  if ((ua.match(/msie\s\d+/) && ua.match(/msie\s\d+/)[0]) || (ua.match(/trident\s?\d+/) && ua.match(/trident\s?\d+/)[0])) {
    var ieVersion = ua.match(/msie\s\d+/)[0].match(/\d+/)[0] || ua.match(/trident\s?\d+/)[0];
    return ieVersion < 9;
  }
  return false;
};

/**
 * 输入限制为数字，并且可以设置精度
 * params {string} val 输入
 * params {number} floatKey 精度
 */
export const formatInput = (val, floatKey) => {
  if (!val) {
    return '';
  }
  if (typeof val !== 'string') {
    val = val.toString();
  }
  const reg = '/^\\d+(\\.\\d{0,' + floatKey + '}){0,1}/';
  val = val.replace(/[^\d.]/g, '');
  let str = val.match(reg);
  val = str ? str[0] : '';
  return val;
};
export const formatInput2 = (val, floatKey) => {
  if (!val) {
    return '';
  }
  if (typeof val !== 'string') {
    val = val.toString();
  }
  // 先把非数字的都替换掉，除了数字和.
  val = val.replace(/[^\d.]/g, '');
  // 必须保证第一个为数字而不是.
  val = val.replace(/^\./g, '');
  // 保证只有出现一个.而没有多个.
  val = val.replace(/\.{2,}/g, '.');
  // 保证.只出现一次，而不能出现两次以上
  val = val.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
  // 只能输入小数点后两位
  const reg = '^(-)*(\\d+)\\.(\\d{' + floatKey + '}).*$';
  val = val.replace(new RegExp(reg), '$1$2.$3');
  return val;
};

// 处理输入内容,isPositive是否必须为正数
export const formatInputNum = (vals, precision, isPositive = true) => {
  const val = vals.toString();
  const arr = val.split('.');
 
  const len = arr.length;
  // 去除非数字
  const fNum = (n) => n.replace(/\D/g, '');
  const minus = val.startsWith('-') && !isPositive;
  let newVal = '';
  if (len > 2) {
    // 超过一个.
    newVal = `${fNum(arr[0])}.${fNum(arr[1])}`;
  } else if (len === 2) {
    newVal = `${fNum(arr[0])}.${fNum(arr[1]).slice(0, precision)}`;
  } else {
    newVal = fNum(val);
  }
  if (precision === 0) {
    newVal = newVal.replace(/\.$/, '');
  }
  return minus ? `-${newVal}` : newVal;
};
// 科学技术法转数字
export const toNonExponentials = num => {
  var data = num.toString().split(/[eE]/);
  if (data.length === 1) return data[0];
  var z = '';
 
  var sign = num < 0 ? '-' : '';
  var str = data[0].replace('.', '');
  var mag = Number(data[1]) + 1;

  if (mag < 0) {
    z = sign + '0.';
    while (mag++) z += '0';
    return z + str.replace(/^\\-/, '');
  }
  mag -= str.length;
  while (mag--) z += '0';
  return str + z;
};

/**
 * 获取url query
 * @param {string} variable 需要获取的字段
 */
export const getQueryVariable = (variable) => {
  let query = window.location.search.substring(1);
  let vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split('=');
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return '';
};

/**
 *  返回数字的有效数值
 * @param numStr
 * @returns {*}
 */
export const effecNumber = numStr => {
  if (!numStr) return '';
  const reg = /-?(0|[1-9]\d*)(.\d*[^0])?/;
  return numStr.match(reg)[0];
};

/**
 *  处理科学记数法
 * @param num
 * @returns {*|string}
 */
export const toNonExponential = num => {
  if (num) {
    if (num == null || typeof num === 'undefined') return '';
    var m = num.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/);
    return num.toFixed(Math.max(0, (m[1] || '').length - m[2]));
  }
};
export const getLocalLangData = (lang, souceData, item) => {
  let _local_json = window.localStorage.getItem('__bf_lang');
  _local_json = _local_json ? JSON.parse(_local_json) : {};
  if (!_local_json[lang]) {
    _local_json[lang] = {};
  }
  if (Math.max(souceData.__version, _local_json[lang].__version || 0) < ((item && item.__version) || 0)) {
    Object.assign(_local_json[lang], item);
    window.localStorage.setItem('__bf_lang', JSON.stringify(_local_json));
    return _local_json[lang];
  } else if (souceData.__version >= (_local_json[lang].__version || 0)) {
    _local_json[lang] = null;
    delete _local_json[lang];
    window.localStorage.setItem('__bf_lang', JSON.stringify(_local_json));
    return {
      __version: souceData.__version
    };
  } else {
    return _local_json[lang];
  }
};

export const formatDate = (date, rule = 'YYYY/MM/DD HH:mm:ss') => {
  return moment(date).format(rule);
};

export const getTimeLeft = (startTime, endTime) => {
    let a = moment(startTime);
    let b = moment(endTime);
    let days = a.diff(b, 'days');
    let hours = a.diff(b, 'hours') - days * 24;
    let minutes = a.diff(b, 'minutes') - (hours * 60) - (days * 24 * 60);
    let seconds = a.diff(b, 'seconds') - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);
    let totalSeconds = a.diff(b, 'seconds');
    return {
      d: days,
      h: hours,
      m: minutes,
      s: seconds,
      ts: totalSeconds
    };
};

export const loadScript = (url, doc = document) => {
  return new Promise(function (resolve) {
      let script_ = doc.createElement("script");
      script_.type = "text/javascript";
      script_.src = url;
      script_.onload = function () {
            console.log(url);
          resolve();
      };
      doc.body.appendChild(script_);
  });
}

export const getReferrer = () => {
  var referrer = !!document.referrer ? document.referrer : '';
  var referrer_url = location.href
  // if (!referrer) {
  //     try {
  //         if (window.opener) {
  //             // IE下如果跨域则抛出权限异常
  //             // Safari和Chrome下window.opener.location没有任何属性
  //             referrer = window.opener.location.href;
  //         }
  //     } 
  //     catch (e) {}
  // }
  if(window.sessionStorage && !window.sessionStorage.source_referrer) {
    const source_referrer = JSON.stringify({
      referrer,
      referrer_url
    })
    window.sessionStorage.setItem('source_referrer', source_referrer);
  }
}
