// common
export const ISAPP = 'ISAPP'; // 是否是app
export const THEME = 'THEME'; // 主题
export const COLORS = 'COLORS'; // 配色
export const LOCALE = 'LOCALE'; // 语言版本
export const CURRENCY = 'CURRENCY'; // 法币汇率
export const CURRENT_CURRENCY = 'CURRENT_CURRENCY'; // 法币币种信息
export const COUNTRY_ID = 'COUNTRY_ID'; // 国家ID
export const CURRENCY_CODE_SIGN = 'CURRENCY_CODE_SIGN';
export const INVITERID_TYPE = 'INVITERID_TYPE';
export const VALUATION_CURRENCY = 'VALUATION_CURRENCY'; // 计价单位
export const VALUATION_SYMBOL = 'VALUATION_SYMBOL'; // 计价符号
export const FRATES = 'FRATES';
export const RATES = 'RATES';
export const FUN_CONFIG = 'FUN_CONFIG';
export const SET_ACADEMYS = 'SET_ACADEMYS';
export const SET_ACADEMYS_COUNT = 'SET_ACADEMYS_COUNT';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_RELATED = 'SET_RELATED';
export const SET_RECOMMENDS = 'SET_RECOMMENDS';
export const MAINTENANCE_LIST = 'MAINTENANCE_LIST';// 维护列表
export const MAINTENANCE_OBJ = 'MAINTENANCE_OBJ';// 维护页面对象
export const TOP_STICKY = 'TOP_STICKY';
export const CURRENT_VAL = 'CURRENT_VAL';
export const CURRENT_UNIT = 'CURRENT_UNIT';
export const USDT_PERPETUAL_SHOW = 'USDT_PERPETUAL_SHOW';
export const REBATE_SHOW = 'REBATE_SHOW';
// user
export const IS_LOGIN = 'IS_LOGIN';
export const FROM_CLIENT = 'FROM_CLIENT';
export const USERBASEINFO = 'USERBASEINFO';
export const CUR_SYMBOL_ASSETS = 'CUR_SYMBOL_ASSETS'; // 当前交易对两个币的资产信息
export const USERSAFEINFO = 'USERSAFEINFO'; // 获取用户安全信息
export const AUTHVERIFY = 'AUTHVERIFY'; // 用户身份认证
export const BANKBINDING = 'BANKBINDING'; // 银行卡绑定
export const MAIN_ASSETS_LIST = 'MAIN_ASSETS_LIST'; // 用户所有币种的main资产信息
export const TRADE_ASSETS_LIST = 'TRADE_ASSETS_LIST'; // 用户所有币种的trade资产信息
export const PERPETUAL_ASSETS_LIST = 'PERPETUAL_ASSETS_LIST'; // 用户所有币种的perpetual资产信息
export const USDT_ASSETS_LIST = 'USDT_ASSETS_LIST'; // 用户所有币种的U本位资产信息
export const MT5_ASSETS_LIST = 'MT5_ASSETS_LIST'; // 用户所有mt5资产信息
export const CAPPS_ASSETS_LIST = 'CAPPS_ASSETS_LIST'; // 用户所有capps资产信息
export const DEFI_ASSETS_LIST = 'DEFI_ASSETS_LIST'; // 用户所有defi资产信息
export const USER_TOTAL_ASSET = 'USER_TOTAL_ASSET'; // 用户资产综合
export const COIN_CODE = 'COIN_CODE'; // 当前选择币种
export const COINS_INFO = 'COINS_INFO'; // 获取系统所有数字货币币种信息
export const USER_SECURITY_SETTINGS = 'USER_SECURITY_SETTINGS'; // 获取用户安全信息
export const LISTBOARD = 'LISTBOARD'; // 获取公告列表
export const VIPLEVELS = 'VIPLEVELS'; // 
export const VIPSHOWLEVEL = 'VIPSHOWLEVEL'; // 
export const IS_RELOAD = 'IS_RELOAD'; // 静态刷新

// trade
export const TRADE_TYPE = 'TRADE_TYPE'; // 交易类型： 1：币币交易， 2：合约交易
export const SYMBOLS_INFO = 'SYMBOLS_INFO';
export const SYMBOLS_INFO_READY = 'SYMBOLS_INFO_READY';
export const SYMBOL_SUPPORTED = 'SYMBOL_SUPPORTED';
export const CUR_SYMBOL_INFO = 'CUR_SYMBOL_INFO';
export const SYMBOL = 'SYMBOL';
export const PTO_LIST = 'PTO_LIST';
export const TICKERS = 'TICKERS';
export const CUR_TICKER = 'CUR_TICKER';
export const UPDATE_TICKER = 'UPDATE_TICKER';
export const IS_TRADE_PAGE = 'IS_TRADE_PAGE';
export const CURRENCY_CODE = 'CURRENCY_CODE';
export const COMMODITY_CODE = 'COMMODITY_CODE';
export const DEPTHS = 'DEPTHS';
export const DEPTH_TYPE = 'DEPTH_TYPE';
export const DEALS = 'DEALS';
export const MAIN_COIN_LIST = 'MAIN_COIN_LIST';
export const COIN_INFO_CACHEED_DATA = 'COIN_INFO_CACHEED_DATA';
export const COIN_INFO_CACHEED_DATA_READY = 'COIN_INFO_CACHEED_DATA_READY';
export const ALLCOIN = 'ALLCOIN';
export const PERPETUAL_API_LOADING = 'PERPETUAL_API_LOADING';

export const PRICE_VOL_INPUT = 'PRICE_VOL_INPUT';
export const OPEN_ORDERS = 'OPEN_ORDERS';
export const WS_GROUP_NAME_CACHE = 'WS_GROUP_NAME_CACHE';
export const UPDATE_POSITION_LIST_USDT = 'UPDATE_POSITION_LIST_USDT';

// contract
export const ALIAS = 'ALIAS';
export const CONTRACT_LIST = 'CONTRACT_LIST';
export const ACCOUNT_INFO = 'ACCOUNT_INFO';
export const CONTRACT_INFO = 'CONTRACT_INFO';
export const CONTRACT_INFO_READY = 'CONTRACT_INFO_READY';
export const CONTRACT_TICKERS = 'CONTRACT_TICKERS';
export const CONTRACT_All_TICKERS = 'CONTRACT_All_TICKERS';
export const CONTRACT_DEPTHS = 'CONTRACT_DEPTHS';
export const CONTRACT_DEALS = 'CONTRACT_DEALS';
export const CONTRACT_TYPE = 'CONTRACT_TYPE';
export const BIN_TYPE = 'BIN_TYPE';
export const SHOW_ORTHER_CION = 'SHOW_ORTHER_CION';
export const ACTIVE_CONTRACT = 'ACTIVE_CONTRACT';
export const PERPETAL_WS = 'PERPETAL_WS';

// defi
export const DEFI_SYMBOL = 'DEFI_SYMBOL';
export const DEFI_SYMBOLS_INFO = 'DEFI_SYMBOLS_INFO';
export const DEFI_POOLS = 'DEFI_POOLS';
export const UPDATE_DEFI_POOLS = 'UPDATE_DEFI_POOLS';
export const DEFI_FKLINES = 'DEFI_FKLINES';
export const DEFI_KLINES = 'DEFI_KLINES';

export const USDT_USER_MAIN_ASSETS_LIST = 'USDT_USER_MAIN_ASSETS_LIST';
export const CONTRACT_All_TICKERS_COIN = 'CONTRACT_All_TICKERS_COIN';
export const CONTRACT_All_TICKERS_USDT = 'CONTRACT_All_TICKERS_USDT';
