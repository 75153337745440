import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
import common from './modules/common';
import trade from './modules/trade';
import contract from './modules/contract';
import perpetual from './modules/perpetual';
import defi from './modules/defi';
import cryptoCup from './modules/cryptoCup';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    common,
    user,
    trade,
    contract,
    perpetual,
    defi,
    cryptoCup
  }
});
