<template>
  <div
    class="mq_button rounded-8px"
    :class="[type, size !== 'self' ? size : $mq == 'web' ? 'medium' : $mq == 'pd' ? 'small' : 'mini', status]"
    :style="customStyle"
    @click="status!=='not' && $emit('click', $event)"
  >
    {{ text }}
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "MqButton",
  data() {
    return {};
  },
  props: {
    type: {
      type: String,
      default: "green",
    },
    customStyle: {
      type: Object,
      default: () => {
        return {}
      },
    },
    size: {
      type: String,
      default: "medium",
    },
    status: {
      type: String,
      default: "pointer",
    },
    text: {
      type: String,
      default: "",
    },
  },
  methods: {
    triggerEvent() {
     // this.$emit('click')
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/ui-package.scss";
.mq_button {
  text-align: center;
}
.mq_button:hover {
  opacity: 0.8;
}
.mq_button:active {
  opacity: 0.4;
}
.green {
  background: var(--color-color-brand-600-primary);
  color: var(--color-grey-white);
}
.aqua {
  background: var(--color-color-brand-rgb-a-10);
  color: var(--color-color-brand-600-primary);
}
.white {
  background: var(--color-background-1);
  color: var(--color-text-2);
  border: 1px solid var(--color-background-3);
}
.gray {
  background: var(--color-background-3);
  color: var(--color-text-1);
}
.green100 {
  background: var(--color-green-100);
  color: var(--color-grey-white);
}
.red100 {
  background: var(--color-red-100);
  color: var(--color-grey-white);
}
.greenHollow {
  border: 1px solid var(--color-color-brand-600-primary);
  background: none;
  color: var(--color-color-brand-600-primary);
}
.medium {
  @extend .text-style-body-400;
  padding: 8px 24px;
}
.small {
  @extend .text-style-medium-400;
  padding: 7px 24px;
}
.mini {
  @extend .text-style-medium-400;
  padding: 5px 24px;
}
.icon {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
}
@media (max-width: 743px) {
  .icon {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
.flat {
  @extend .text-style-medium-400;
  padding: 1px 16px;
}
.pointer {
  cursor: pointer;
}
.not {
  cursor: not-allowed;
  opacity: 0.3 !important;
}
</style>