import * as types from '../../../types';
export default {
  [types.CONTRACT_TYPE] (state, status) {
    state.contractType = status;
  },
  [types.BIN_TYPE] (state, status) {
    state.binType = status;
  },
  [types.SHOW_ORTHER_CION] (state, status) {
    state.showOrtherCion = status;
  },
  [types.USDT_USER_MAIN_ASSETS_LIST] (state, status) {
    state.usdtUserMainAssetsList = status;
  },
  [types.CONTRACT_All_TICKERS_USDT] (state, status) {
    state.contract_all_tickers_USDT = status;
  },
  [types.UPDATE_POSITION_LIST_USDT] (state, status) {
    state.positionInfoList_USDT = status;
  }
};
