<template>
  <div class="pagerContainers">
    <!-- 选择页码 -->
    <div>
      <span class="in-b mr-4px text-[var(--color-text-2)]">
        {{ $t("pagination_lines") }}
      </span> 
      <c-trade-select
        class="cSelect"
        v-model="curSize"
        :data="sizes"
        @input="sizeChange"
      ></c-trade-select>
    </div>
    <!-- 当前页数和总数 -->
    <div class="ph:mt-8px">
      <span class="in-b text-[var(--color-text-3)] web:ml-16px pd:ml-16px ph:ml-0">
        {{ $t("pagination_curPage", { cur: curPage }) }}
      </span>
      <span class="mx-16px in-b text-[var(--color-text-3)]">
        {{ $t("pagination_total", { total: curTotal }) }}
      </span>
    </div>
    <!-- 左右按钮 -->
    <div class="btn">
      <svg
        class="ficon mr-16px"
        aria-hidden="true"
        @click="goPrevPage"
        :class="{ disabled: curPage <= 1 }"
      >
        <use xlink:href="#bf-arrow-left-s-line"></use>
      </svg>
      <svg
        class="ficon"
        aria-hidden="true"
        @click="goNextPage"
        :class="{ disabled: curPage >= totalPage }"
      >
        <use xlink:href="#bf-arrow-right-s-line"></use>
      </svg>
    </div>
  </div>
</template>
<script>
import cTradeSelect from "~/pages/bill/components/tradeSelect/tradeSelect.vue";
export default {
  name: "BfPagination",
  data() {
    return {
      curSize: 20, // 每页条数
      curPage: 1, // 当前页码
      curTotal: 0, // 总条数
    };
  },
  props: {
    cursor: {
      type: Object,
      default: () => ({
        page: 1,
        size: 20,
        total: 0,
      }),
    },
    sizes: {
      type: Array,
      default: () => [5, 10, 20, 50, 100],
    },
  },
  components: {
    cTradeSelect,
  },
  created() {
    this.init();
  },
  computed: {
    totalPage() {
      // 总页码数
      return Math.ceil(this.curTotal / this.curSize) || 0;
    },
  },
  methods: {
    init() {
      this.curPage = this.cursor.page || 1;
      this.curSize = this.cursor.size || 20;
      this.curTotal = this.cursor.total || 0;
    },
    sizeChange(value) {
      this.curSize = value;
      this.$emit("change", {
        page: 1,
        size: value,
      });
    },
    goPrevPage() {
      if (this.curPage > 1) {
        --this.curPage;
        this.$nextTick(() => {
          this.$emit("change", {
            page: this.curPage,
            size: this.curSize,
          });
        });
      }
    },
    goNextPage() {
      if (this.curPage < this.totalPage) {
        ++this.curPage;
        this.$nextTick(() => {
          this.$emit("change", {
            page: this.curPage,
            size: this.curSize,
          });
        });
      }
    },
  },
  watch: {
    cursor: {
      handler(curVal, oldVal) {
        this.init();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss">

.theme-default {
  @import "./theme-default.scss";
}

</style>
