import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter);

// eslint-disable-next-line no-unused-vars
const isDev = process.env.NODE_ENV === 'development';

export default (lang) => new VueRouter({
  mode: 'history',
  // mode: isDev ? 'hash' : 'history',
  // base,
  base: `/${lang}`,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes
});
