import VueI18n from 'vue-i18n';
import Vue from 'vue';

// import cn from './cn';
import en from './en';
// import kr from './kr';
// import ru from './ru';
// import jp from './jp';
// import vn from './vn';

// element-ui国际化
import ElementLocale from 'element-ui/lib/locale';

Vue.use(VueI18n);

// const messages = {
//   en: Object.assign(en, enLocale),
//   kr: Object.assign(kr, krLocale),
//   cn: Object.assign(cn, zhLocale),
//   ru: Object.assign(ru, ruLocale),
//   jp: Object.assign(jp, jpLocale),
//   vn: Object.assign(vn, viLocale)
// };

const isDev = process.env.NODE_ENV === 'development';

const i18n = new VueI18n({
  locale: 'en', // 当前语言
  fallbackLocale: 'en', // 当前语言不存在时使用en
  silentFallbackWarn: !isDev,
  silentTranslationWarn: !isDev,
  messages: {
    en
  }
  // numberFormats: cn,
  // dateTimeFormats: cn
});
ElementLocale.i18n((key, value) => i18n.t(key, value));

export default i18n;
