
/**
 * U合约state
 * **/
export default {
  contractList_USDT: [], // 合约币对列表
  accountInfo_USDT: {}, // 账户资讯
  usdtUserMainAssetsList: [], // 用户的资产汇总
  positionInfo_USDT: {}, // 当前币对仓位
  placeList_USDT: [], // 平仓盈亏列表
  positionInfoList_USDT: [], // 用户U合约仓位
  incompleteOrders_USDT: [], // 用户U合约当前委托列表
  conditionContractOrders_USDT: [], // 用户U合约条件委托列表
  completeOrders_USDT: [], // 用户U合约已成交委托列表
  historyContractOrders_USDT: [], // 用户U合约历史委托列表
  historyConditionOrders_USDT: [], // 用户U合约条件订单历史委托列表
  positionMarginList_USDT: {}, // 用户U合约保证金对象
  contract_all_tickers_USDT: {}, // U本位合约币对行情
  showOrtherCion: JSON.parse(window.localStorage.getItem('showOrtherCion') || false),
  binType: 1 // 币对仓位类型
};
