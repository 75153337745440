import Big from 'big.js';
import {
  formatNum,
  toNonExponential
} from '~/utils/util'; // formatMoney, formatTime,
import * as formula from '~/utils/formula';
import i18n from '~/locales';
import usdt_getters from './usdt/usdt_getters';
const commonDealNum = (num, precision, isRound) => {
  if (!num || !(num - 0)) {
    return (num === undefined || num === null) ? '' : 0;
  }
  num = toNonExponential(num - 0);
  if (precision) {
    num = formatNum(num, precision, isRound);
  }
  return new Big(num).valueOf();
};

const getOrderType = (type, side) => {
  switch (type) {
    case '1': // 限价单
    case '4': // 限价平仓单
      return side === '1' ? i18n.t('order_24') : i18n.t('order_25');
    case '2': // 市价单
    case '5': // 市价强平单
      return side === '1' ? i18n.t('perpetual_177') : i18n.t('perpetual_178');
    case '3': // 强平单
      // return i18n.t('perpetual_163');

      return side === '2' ? i18n.t('bill_44') : i18n.t('bill_45');
    default:
      return '';
  }
};
// 新的类型名字区分
const getOrderType1 = type => {
  switch (type) {
    case '1': // 限价单
    case '4': // 限价平仓单
      return i18n.t('perpetual_193');
    case '2': // 市价单
    case '5': // 市价强平单
      return i18n.t('perpetual_194');
    case '3': // 强平单
      return i18n.t('perpetual_195');
    default:
      return '';
  }
};

export default {
  // 计算属性
  combineState (state) {
    return `${state.data1} -> ${state.data2}`;
  },
  alias (state) { // 币对显示
    if (state.symbol) {
      return state.symbol.split('-')[2].toUpperCase() + '/' + state.symbol.split('-')[1].toUpperCase();
    } else {
      return '';
    }
  },
  commodityCode (state) { // 数字货币
    return state.symbol && state.symbol.split('-')[2].toUpperCase();
  },
  currencyCode (state) { // 计价货币
    return state.symbol && state.symbol.split('-')[1].toUpperCase();
  },
  contractList (state) { // 永续合约列表
    return state.contractList || [];
    /* if (state.contractList && state.contractList.length) {
      let list = [];
      state.contractList.forEach(item => {
        if (item.symbol !== 'swap-usd-grin') {
          list.push(item);
        }
      });
      return list;
    } else {
      return [];
    } */
  },
  accountInfo (state) { // 用户永续账户信息
    const accountInfo = state.accountInfo;
    /* const posi = state.positionInfo;
    const maxDelegateNum = state.contractInfo.maxDelegateNum; */
    // console.log(accountInfo);
    /* if (maxDelegateNum !== undefined) {
      if (posi.side === '1') {
        accountInfo.canBuyNum = new Big(maxDelegateNum).minus(posi.currentPosition || 0).minus(accountInfo.buyCount || 0).valueOf();
        accountInfo.canSellNum = new Big(maxDelegateNum).plus(posi.currentPosition || 0).minus(accountInfo.sellCount || 0).valueOf();
      } else {
        accountInfo.canBuyNum = new Big(maxDelegateNum).plus(posi.currentPosition || 0).minus(accountInfo.buyCount || 0).valueOf();
        accountInfo.canSellNum = new Big(maxDelegateNum).minus(posi.currentPosition || 0).minus(accountInfo.sellCount || 0).valueOf();
      }
    } */
    // console.log(posi);
    // console.log('--3', accountInfo);
    return accountInfo;
  },
  contractBaseInfo (state) { // 永续合约公共信息
    state.contractBaseInfo.fundRate = state.contractBaseInfo.fundRate ? new Big(state.contractBaseInfo.fundRate).times(100).valueOf() : 0;
    return state.contractBaseInfo;
  },
  leverageInfo (state) { // 当前合约币对杠杆信息
    return state.leverageInfo;
  },
  contractDetailInfo (state) { // 永续合约详细信息
    state.contractInfo.fundRate = state.contractInfo.fundRate ? new Big(state.contractInfo.fundRate).times(100).valueOf() : 0;
    return state.contractInfo;
  },
  positionShowInfo (state, getters) { // 仓位页面展示信息
    let info = state.positionInfo;
    info.symbolDes = getters.alias;
    info.availPositionDes = commonDealNum(info.availPosition);
    info.avgCostPriceDes = commonDealNum(info.avgCostPrice, state.contractInfo.priceOrderPrecision);
    info.currentPositionDes = commonDealNum(info.currentPosition);
    info.liquidationPriceDes = info.liquidationPrice > 0 ? commonDealNum(info.liquidationPrice, state.contractInfo.priceOrderPrecision) : 0;
    info.markPriceDes = commonDealNum(state.contractBaseInfo.flagPrice, state.contractInfo.priceOrderPrecision);

    info.closeOrderPriceDes = commonDealNum(info.closeOrderPrice, state.contractInfo.priceOrderPrecision);
    info.realizedPnlDes = commonDealNum(info.realizedPnl, state.contractInfo.baseShowPrecision);
    // 仓位价值
    info.pv = commonDealNum(formula.pv(info.currentPosition, info.avgCostPrice), state.contractInfo.baseShowPrecision);
    // 未实现盈亏
    info.unrealisedPNL = commonDealNum(formula.unrealisedPNL(info.avgCostPrice, state.contractBaseInfo.flagPrice, info.currentPosition, info.side === '1'), state.contractInfo.baseShowPrecision);
    // 回报率
    // const pp = formula.pp(info.avgCostPrice, info.markPrice, info.currentPosition);
    // 起始仓位保证金
    const ipm = formula.IPM(info.pv, state.leverageInfo.initMargins || state.contractInfo.iniMargins, state.contractInfo.feeRateTaker);
    // 回报率
    const roe = formula.ROE(info.unrealisedPNL, ipm);
    info.pp = commonDealNum(roe ? roe.times(100) : 0, 2) + '%';
    info.pps = Number(info.unrealisedPNL) >= 0; // 永续分享时区别正负
    // 账户可用保证金
    let am = state.accountInfo.accb === undefined ? 0 : new Big(state.accountInfo.accb).minus(state.accountInfo.frozen).minus(ipm || 0);
    // 实际仓位保证金
    info.marginDes = commonDealNum(formula.RM(ipm, info.unrealisedPNL, am), state.contractInfo.baseShowPrecision);
    return info;
  },
  incompleteOrders (state, getters) { // 未完成委托订单
    let data = state.incompleteOrders;
    // 订单状态
    const orderStatu = {
      2: i18n.t('order_19'),
      3: i18n.t('order_18'),
      4: i18n.t('order_21'),
      7: i18n.t('order_20'),
      1: i18n.t('order_17'),
      8: i18n.t('perpetual_142')
    };

    if (data) {
      return data.map(item => {
        item.symbolDes = getters.alias;
        item.volumeDes = commonDealNum(item.volume);
        item.priceDes = commonDealNum(item.price, state.contractInfo.priceOrderPrecision);
        item.dealVolumeDes = commonDealNum(item.dealVolume);
        item.remainVolume = commonDealNum(new Big(item.volume).minus(item.dealVolume).valueOf());
        item.avgPriceDes = commonDealNum(item.avgPrice, state.contractInfo.priceOrderPrecision) || '- -';
        item.sideDes = getOrderType(item.type, item.side);
        item.commissionVal = commonDealNum(formula.ov(item.volume, item.price), state.contractInfo.baseShowPrecision);
        item.cv = commonDealNum(formula.ov(item.dealVolume, item.avgPrice), state.contractInfo.baseShowPrecision) || '- -';
        item.statusDes = orderStatu[item.status] ? orderStatu[item.status] : item.status;
        return item;
      });
    }
    return [];
  },
  completeOrders (state, getters) { // 已完成委托订单
    let data = state.completeOrders;
    // 委托类型
    // const orderType = [i18n.t('perpetual_162'), '市价', i18n.t('perpetual_163')]; // 1限价/2市价/3强平订单
    if (data) {
      return data.map(item => {
        item.symbolDes = getters.alias;
        item.dealPriceDes = commonDealNum(item.dealPrice, state.contractInfo.priceOrderPrecision);
        // 成交价值 （借用委托价值公式）
        item.cv = commonDealNum(formula.ov(item.dealVolume, item.dealPrice), state.contractInfo.baseShowPrecision);
        item.feeDes = commonDealNum(item.fee, state.contractInfo.baseShowPrecision);
        item.sideDes = getOrderType(item.type, item.side);
        return item;
      });
    }
    return [];
  },
  historyContractOrders (state, getters) { // 历史委托订单
    let data = state.historyContractOrders;
    // 订单状态
    const orderStatu = {
      2: i18n.t('order_19'),
      3: i18n.t('order_18'),
      4: i18n.t('order_21'),
      7: i18n.t('order_20'),
      1: i18n.t('order_17'),
      8: i18n.t('perpetual_142')
    };
    // 条件单订单状态
    const conditionOrderStatus = {
      1: i18n.t('order_19'),
      2: i18n.t('order_21'),
      3: i18n.t('order_21')
    };
    // 委托类型
    // const orderType = [i18n.t('order_24'), i18n.t('order_25'), i18n.t('perpetual_163')];
    if (data) { 
      return data.map(item => {
        item.symbolDes = getters.alias;
        item.volumeDes = commonDealNum(item.volume);
        item.priceDes = (item.type === '2' || item.type === '5') && !item.price ? i18n.t('perpetual_167') : commonDealNum(item.price, state.contractInfo.priceOrderPrecision);
        item.dealVolumeDes = commonDealNum(item.dealVolume, 8);
        // item.remainVolume = commonDealNum(new Big(item.volume).minus(item.dealVolume).valueOf());
        item.triggerPriceDes = commonDealNum(item.triggerPrice, state.contractInfo.priceOrderPrecision) || '- -';
        item.avgPriceDes = commonDealNum(item.avgPrice, state.contractInfo.priceOrderPrecision) || '- -';
        item.sideDes = getOrderType(item.type, item.side);
        item.commissionVal = (item.type === '2' || item.type === '5') && !item.price ? '- -' : commonDealNum(formula.ov(item.volume, item.price), state.contractInfo.baseShowPrecision);
        item.statusDes = item.orderType === 1 ? (orderStatu[item.status] ? orderStatu[item.status] : item.status) : conditionOrderStatus[item.status];
        item.cv = commonDealNum(formula.ov(item.dealVolume, item.avgPrice), state.contractInfo.baseShowPrecision) || '- -';
        return item;
      });
    }
    return [];
  },
  placeList (state, getters) {
    const SIDE = {
      1: i18n.t('perpetual_189'),
      2: i18n.t('perpetual_190')
    };

    let data = state.placeList;
    if (data) {
      // console.log(getters.alias, state.contractInfo.baseShowPrecision);
      return data.map(item => {
        item.symbolDes = getters.alias;
        item.sideDes = SIDE[item.side];
        item.typeDes = getOrderType1(item.type);
        item.positionAvgPriceDes = commonDealNum(item.positionAvgPrice, state.contractInfo.priceOrderPrecision);
        item.pnlAmountDes = commonDealNum(item.pnlAmount, state.contractInfo.baseShowPrecision);
        item.priceDes = commonDealNum(item.price, state.contractInfo.priceOrderPrecision);
        // console.log(getters.alias, state.contractInfo.baseShowPrecision, item.pnlAmountDes);
        return item;
      });
    }
    return [];
  },
  conditionOrders (state, getters) { // 条件委托单列表
    let data = state.conditionContractOrders;

    if (data) {
      return data.map(item => {
        item.symbolDes = getters.alias;
        item.volumeDes = commonDealNum(item.volume);
        item.priceDes = (item.type === '2' || item.type === '5') && !item.price ? i18n.t('perpetual_167') : commonDealNum(item.price, state.contractInfo.priceOrderPrecision);
        item.triggerPriceDes = commonDealNum(item.triggerPrice, state.contractInfo.priceOrderPrecision);
        item.sideDes = getOrderType(item.type, item.side);
        item.commissionVal = (item.type === '2' || item.type === '5') && !item.price ? '- -' : commonDealNum(formula.ov(item.volume, item.price), state.contractInfo.baseShowPrecision);
        // item.statusDes = orderStatu[item.status] ? orderStatu[item.status] : item.status;
        return item;
      });
    }
    return [];
  },
  ...usdt_getters
};
