export default {ru:{
  "403": "Операция не удалась. Пожалуйста, свяжитесь с обслуживанием клиентов.",
  "500": "В системе произошла ошибка",
  "5843": "Указанная Вами идентификационная информация была использована для другой учетной записи.  Пожалуйста, попробуйте еще раз.",
  "10000": "Доступ слишком занят, пожалуйста, попробуйте позже.",
  "100001": "Ошибка параметра",
  "100002": "Значение параметра неверное",
  "100003": "Ошибка request \"header",
  "100004": "Срок действия кода для подтверждения истек",
  "100005": "Неправильный код подтверждения",
  "100006": "Торговля запрещена, если у вас есть какие-либо вопросы, пожалуйста, обратитесь в службу поддержки.",
  "100007": "Позиция была заблокирована",
  "100008": "Недопустимая сумма",
  "100009": "Max Holding Position Missing",
  "100010": "Ордер не выполнен! Превышен максимальный предел позиции:",
  "100011": "Макс.количество ордеров превышено!",
  "100012": "Недостаточная маржа",
  "100013": "Не удалось обновить ордер!",
  "100014": "Ордер не существует!",
  "100015": "Нет стакана, не возможно подать ордер!",
  "100016": "Время входа в систему истекло, пожалуйста, войдите снова.",
  "100017": "Позиция для закрытия отсутствует!",
  "100018": "Неверная цена!",
  "100019": "Нет активов!",
  "100022": "Цена не может быть равна 0!",
  "200007": "Поиск по токену больше не доступен",
  "200008": "Контракт не существует!",
  "200009": "Монета еще не доступна!",
  "200010": "Десятичная сумма больше, чем позволяет эта монета",
  "200011": "Не удалось получить цитату!",
  "200012": "Такой торговой пары нет",
  "200013": "Введите название торговой пары",
  "200015": "Недостаточно средств",
  "200017": "Перевод не выполнен!",
  "200018": "Пожалуйста, прочитайте и согласитесь с «Пользовательским соглашением BitForex MT5»",
  "200024": "Операция по переводу средств ненормальная, пожалуйста, свяжитесь со Службой поддержки.",
  "200025": "Сумма, которую вы переводите, должна быть больше, чем {num}",
  "200026": "Ограничение при переводе: необходимо завершить хотя бы одну запись о переводе.",
  "300004": "Перевод не выполнен! Системная среда ненормальная",
  "300006": "Перевод не выполнен! Недостаточно средств.",
  "300007": "Пожалуйста, завершите аутентификацию безопасности",
  "1000025": "Ордер не выполнен. Ордера на закрытие позиции могут быть выполнены только при полном заполнении существующего ордера на закрытие позиции.",
  "1000026": "Ордер не выполнен.  На Вашем аккаунте обнаружена позиция по лучшей цене.",
  "ACT1000": "Ошибка параметра",
  "ACT1001": "Голосование не началось",
  "ACT1002": "Голосование завершено",
  "ACT1003": "Меньше миним. кол-ва голосов",
  "ACT1004": "Недостаточно средств",
  "ACT1005": "Повторное голосование",
  "BACK_UF019": "Аккаунт пользователя заморожен",
  "CAPP001": "Аккаунт не существует",
  "CAPP0010": "Срок действия выбранного Вами приложения истек, выберите другой проект",
  "CAPP0011": "Неправильный пароль",
  "CAPP0012": "Срок действия авторизации истёк, пожалуйста, вернитесь назад для подтверждения авторизации",
  "CAPP002": "Перевод на счет запрещён",
  "CAPP003": "Аккаунт заморожен. Пожалуйста, обратитесь в техподдержку.",
  "CAPP004": "Перевод со счета запрещён",
  "CAPP005": "Ошибка активов",
  "CAPP006": "Запрашиваемый токен/коин не поддерживается",
  "CAPP007": "Ошибка аккаунта",
  "CAPP008": "Ордер не существует",
  "CAPP009": "Срок действия ордера истёк",
  "CAPP010": "Срок действия выбранного Вами приложения истек, выберите другой проект",
  "CAPP011": "Неправильный пароль",
  "CAPP012": "Срок действия авторизации истёк, пожалуйста, вернитесь назад для подтверждения авторизации",
  "CAPP018": "Передача активов запрещена",
  "CAPP500": "Ошибка в системе",
  "CT102": "Такая торговая пара пока недоступна. Пожалуйста, следите за последними объявлениями о времени открытия.",
  "CT103": "Введенная сумма находится за пределами допустимого диапазона стоимости",
  "CT104": "На вашем счете недостаточно средств",
  "CT105": "Ордер не существует",
  "CT106": "Превышен лимит на сумму заказа",
  "CT107": "Сумма Вашего заказа меньше минимальой",
  "CT108": "Аккаунт заморожен",
  "CT110": "Превышена максимальная сумма покупки",
  "CT200": "Спот-торговля на вашем аккаунте заблокирована, пожалуйста, обратитесь в службу поддержки клиентов для получения подробной информации.",
  "CT801": "К вашему аккаунту не привязан телефон",
  "CT804": "К вашему аккаунту привязан телефон",
  "CT805": "Данный номер телефона привязан к другому аккаунту",
  "CT806": "Код верификации просрочен",
  "CT807": "Превышено ограничение на количество отправляемых SMS",
  "CT809": "Неправильный код из SMS",
  "CT810": "Настройка аутентификации безопасности не завершена",
  "CT811": "Аутентификация Google не привязана",
  "CT812": "Неправильный код подтверждения Google",
  "CT822": "Ключ шифрования Google привязан",
  "CT823": "Не существует",
  "CT824": "Не может быть изменено",
  "CT825": "Изменений нет",
  "CT826": "Пользователь установил аутентификацию безопасности",
  "CT902": "Не существует",
  "FC101": "Ордер по данному контракту приостановлен",
  "FC102": "Ваш аккаунт заблокирован",
  "FC103": "Превышен лимит по сумме ордера",
  "FC104": "Превышено количество по ордеру",
  "FC105": "На вашем счете недостаточно средств",
  "FC106": "На вашем счете недостаточно средств по открытым позициям",
  "FC108": "Отменить заказ не удалось",
  "FC109": "Недостаточное количество для размещения заказа",
  "FC200": "Функции CApp на вашем аккаунте заблокированы, пожалуйста, обратитесь в службу поддержки клиентов для получения подробной информации.",
  "FC999": "Передача активов запрещена",
  "FI105": "Текущий ордер был погашен",
  "FI109": "Ордер не существует",
  "FI110": "Текущий ордер был погашен",
  "FT002": "На Вашем счете недостаточно средств",
  "LN1000": "Не удалось увеличить/уменьшить активы",
  "LN1001": "Ошибка параметра",
  "LN1002": "Ошибка суммы займа",
  "LN1003": "Пользователь не существует",
  "LN1004": "Не удалось получить доступные активы пользователя.",
  "LN1005": "Недостаточно доступных активов пользователя",
  "LN1006": "Нет результатов запроса к этому ордеру",
  "LN1007": "Ошибка заморозки активов пользователя.",
  "LN1008": "Ошибка создания ордера",
  "LN1009": "Ошибка оплаты ордера",
  "LN1010": "Не удалось изменить LTV ордера",
  "LN1011": "Не удалось разморозить пользовательские активы.",
  "LN1012": "Цена токена изменилась, обновите страницу и повторите попытку.",
  "LN1013": "Ошибка сохранения записи корректировки LTV.",
  "LN1014": "Ошибка заморозки активов пользователя, ошибка разморозки активов пользователя",
  "LN1015": "Ошибка вычета активов пользователя",
  "LN1016": "Ошибка вычета системных активов",
  "LN1017": "Ошибка добавления активов пользователя.",
  "LN1018": "Ошибка при добавлении системных активов",
  "LN1019": "Ошибка заморозки системных активов",
  "LN1020": "Недостаточно доступных системных активов",
  "LN1021": "Не удалось получить доступные системные активы",
  "LN1022": "Неправильная сумма корректировки LTV",
  "LN1023": "Неправильный тип токена или залоговая монета уже заимствована",
  "LN1024": "Неправильный срок займа",
  "LN1025": "Неправильная заимствованная монета или залоговая монета",
  "LN1026": "Ордер выполнен, больше не нужно продолжать погашение монет",
  "LN1027": "Не удалось выпустить заемную монету",
  "LN1028": "Недостаточно доступных монет для заимствования",
  "LN1029": "Не удалось увеличить общее количество монет, доступных для заимствования",
  "LN1030": "Невозможно погасить из-за изменения статуса ордера",
  "LN1032": "Общий осмотр активов не удался",
  "LN1033": "блокировка сети, попробуйте позже.",
  "LN1034": "Для этой валюты нет ограничений, повторите попытку позже.",
  "NFT100": "Событие не существует",
  "NFT101": "Событие еще не началось",
  "NFT102": "Завершено",
  "NFT103": "Прямая покупка невозможна",
  "NFT104": "Выкуп невозможен",
  "NFT105": "Ошибка цены выкупа",
  "NFT106": "Продано",
  "NFT107": "Невозможно выставить на продажу повторяющиеся",
  "NFT108": "Ошибка NFT-активов",
  "NFT110": "Комиссия за транзакцию недостаточна",
  "NFT111": "Текущий NFT не работает",
  "NFT112": "Отмена вывода невозможна",
  "NFT113": "Неправильные данные о выводе",
  "NFT114": "Ошибка параметра листинга",
  "NFT115": "Не ниже текущей цены предложения",
  "NFT116": "Цена не может быть равна 0",
  "NFT117": "Аукц. лот не существует",
  "NFT118": "После старта торговли отмена транзакции невозможна",
  "NFT120": "Вывод приостановлен",
  "NFT121": "Депозит приостановлен",
  "NFT122": "Вывода средств с Вашего аккаунта заблокирована, пожалуйста, свяжитесь с техподдержкой клиентов для получения подробной информации.",
  "NFT123": "NFT-торговля на вашем аккаунте заблокирована, пожалуйста, обратитесь в службу поддержки клиентов для получения подробной информации.",
  "OAUTH100001": "Время ожидания платежа истекло, пожалуйста, попробуйте ещё раз",
  "PB100000": "Ошибка в активах аккаунта",
  "PB100001": "Недостаточно средств",
  "PB100002": "Сумма предварительной закупки должна быть не менее 100",
  "PB100003": "Ошибка в цене предварительной закупки",
  "PB100004": "Ошибка в категории токена",
  "PB100005": "Цена была изменена, пожалуйста, закажите еще раз",
  "PB100006": "Ошибка в сумме предварительной закупки",
  "PB110000": "Время предварительной закупки не установлено",
  "RT100": "Неправильный код подтверждения",
  "RT101": "Неправильный формат параметра",
  "RT102": "Запись не существует",
  "RT103": "Ошибка конфигурации системы",
  "RT104": "Системная среда ненормальная",
  "RT201": "Избранное достигло Макс.лимита",
  "RT301": "Ошибка сервера",
  "RT302": "Ошибка сервера",
  "RT303": "Ошибка сервера",
  "RT304": "Ошибка сервера",
  "RT398": "Ошибка сервера",
  "RT399": "Слишком высокая частота запроса к серверу",
  "RT401": "Тип перевода не поддерживается",
  "RT999": "Неопределенное исключение",
  "SL303": "блокировка сети, попробуйте позже.",
  "SL304": "Ордер не выполнен! Превышен максимальный предел позиции:",
  "SP801": "Заявка была подтверждена, невозможно изменить информацию в заявке о выпуске токена",
  "SP802": "Заявка не существует, пожалуйста сначала заполните заявку о выпуске токена",
  "SP803": "Информация по настройке параметров не может быть отредактирована",
  "SP804": "Пожалуйста, внесите депозитный взнос для выпуска токена",
  "SP805": "Недостаточно средств",
  "SP806": "Взнос для выпуска токена оплачен",
  "SP807": "Информации по настройке параметров не существует",
  "TF001": "Переводы на вашем счете зачисления запрещены, пожалуйста, свяжитесь с командой техподдержки для уточнения деталей.",
  "TF002": "Переводы на вашем спот-счете запрещены, пожалуйста, обратитесь в Центр поддержки клиентов.",
  "TF003": "Переводы на вашем  контракт-счете запрещены, пожалуйста, свяжитесь с Центром поддержки клиентов для получения подробной информации.",
  "TF004": "Переводы на вашем МТ5-счете запрещены, пожалуйста, свяжитесь с Центром поддержки клиентов для получения подробной информации.",
  "TF005": "Переводы на вашем CApp-счете запрещены, пожалуйста, свяжитесь с Центром поддержки клиентов для получения подробной информации.",
  "TS1001": "Система перегружена, повторите попытку позже.",
  "TS1002": "Система перегружена, повторите попытку позже.",
  "TS1003": "Подписки на акцию превышены",
  "TS1004": "Недостаточный баланс",
  "TS1005": "Ошибка запроса",
  "TS1006": "Ошибка подписки",
  "TS1007": "Система перегружена, повторите попытку позже.",
  "TS1008": "Система перегружена, повторите попытку позже.",
  "TS1009": "Система перегружена, повторите попытку позже.",
  "TS1010": "Система перегружена, повторите попытку позже.",
  "TS1011": "Система перегружена, повторите попытку позже.",
  "TS1012": "Система перегружена, повторите попытку позже.",
  "u100017": "Аккаунт не существует",
  "u100018": "Недостаточный баланс",
  "u100019": "Ошибка обновления активов",
  "u100020": "Позиция не найдена",
  "u100021": "Требуется больше позиций",
  "u100022": "Токены не найдены",
  "u100023": "Ошибка - токены заморожены",
  "u100024": "Торговая пара не найдена",
  "u100025": "Ошибка - несоответствие соотношения",
  "u100026": "Ошибка в количестве",
  "u100027": "Ошибка в количестве свопа",
  "u100028": "Пул пустой",
  "u100029": "Недостаточный баланс пула",
  "u100030": "Ошибка коэффициента погашения",
  "u100031": "Функция Swap Trading на вашем счете заблокирована, обратитесь в службу поддержки клиентов для получения подробной информации.",
  "u300006": "Аккаунт заблокирован, обратитесь в Службу поддержки клиентов",
  "u300007": "Ошибка активов",
  "u300008": "Перезагрузка системы",
  "u300009": "Токены уже существуют",
  "UF001": "Данные не могут быть пустыми",
  "UF002": "Не более 20 адресов для вывода монет",
  "UF003": "Такой адрес уже существует",
  "UF015": "Аккаунт заморожен",
  "UF016": "Адрес вывода не существует",
  "UF017": "Адрес вывода не принадлежит данному пользователю",
  "UF018": "Не удалось преобразовать адрес.",
  "UF019": "Вывода средств с Вашего аккаунта заблокирована, пожалуйста, свяжитесь с техподдержкой клиентов для получения подробной информации.",
  "UF020": "Превышен суточный лимит по выводу средств",
  "UF021": "Недостаточно средств на счете",
  "UF022": "Существует не подтвержденная запись о депозите",
  "UF023": "Не удалось преобразовать адрес.",
  "UF024": "Недопустимый адрес FIO.",
  "UF030": "Не удалось добавить адрес, проверьте подключение к Интернету и повторите попытку.",
  "UF034": "Ошибка отмены",
  "UF036": "Количество ниже минимальной лимита",
  "UF037": "Сумма вывода ниже минимального лимита",
  "UF038": "Для этого коина/токена вывод на адрес BitForex не поддерживается, пожалуйста, попробуйте  другой адрес",
  "UF039": "По причине техобслуживания вывод средств временно не поддерживается",
  "UF101": "Формат TXID некорректный, пожалуйста, введите еще раз",
  "UF102": "Количество запросов в течение одного часа достигло 5, пожалуйста, попробуйте попозже еще раз",
  "UF108": "Пожалуйста, внесите депозит по указанному Вами адресу",
  "UF301": "Последняя обновленная цена была изменена, пожалуйста приобретайте снова",
  "UF302": "Недостаточно средств",
  "UF303": "Количество указано не верно",
  "UF304": "Допустимый объем займа достиг предельного лимита",
  "UF305": "Операция по возврату средств временно недоступна",
  "UF307": "Количество займов пользователя достигло предельного лимита",
  "UF308": "Количество участников достигло лимита",
  "UF312": "Займы и возврат не доступны из-за принудительной ликвидации - маржин-колл",
  "UF313": "Возвращаемая сумма больше требуемой возвращаемой суммы",
  "US1752": "Номер телефона Отключен",
  "US1753": "Адрес электронной почты Отключен",
  "US1754": "Верицикация личности Отключена",
  "US1755": "Аккаунт удален",
  "US601": "Неверный формат электронной почты",
  "US602": "Пароль должен содержать не менее 8-20 символов и включать как минимум одну цифру, одну прописную и одну строчную букву без пробелов",
  "US604": "Размер загруженного файла превысил лимит",
  "US605": "Ошибка в формате загруженного файла",
  "US607": "Фотография документа, подтверждающего личность, отсутствует",
  "US608": "Отсутствует фотография в паспорте",
  "US609": "Пожалуйста, сначала пройдите верификацию",
  "US651": "Данные APIKey не принадлежат пользователю",
  "US660": "Код с картинки введён не правильно",
  "US661": "Указанная Вами идентификационная информация была использована для другой учетной записи.  Пожалуйста, попробуйте еще раз.",
  "US665": "Ошибка проверки reCAPTCHA, попробуйте ещё раз",
  "US667": "Номер телефона не совпадает",
  "US668": "Указанная Вами идентификационная информация была использована для другой учетной записи.  Пожалуйста, попробуйте еще раз.",
  "US669": "Неподдерживаемый домен электронной почты",
  "US671": "Проверка TOTP не пройдена много раз, повторите попытку позже.",
  "US701": "Пользователь с таким адресом электронной почты уже зарегистрирован",
  "US702": "Cрок действия кода подтверждения истек",
  "US703": "Некорректный код верификации почты",
  "US704": "Пользователь не существует",
  "US705": "Ваш аккаунт заблокирован. Пожалуйста, обратитесь в службу поддержки.",
  "US706": "Ваш аккаунт заблокирован. Пожалуйста, повторите попытку через 1 час.",
  "US707": "Если Вы неверно введете код 5 раз подряд, пожалуйста, отправьте запрос на новый код",
  "US708": "Пользователь с таким адресом электронной почты уже зарегистрирован",
  "US709": "Неверная почта или пароль",
  "US710": "Пользователь с таким номером телефона уже зарегистрирован",
  "US711": "Некорректный SMS код",
  "US712": "Неверный логин или пароль",
  "US715": "UID приглашающего не существует",
  "US724": "Слишком частая удаленная аутентификация пользователя, пожалуйста, попробуйте попозже еще раз",
  "US725": "Не удалось подтвердить новое местоположение",
  "US726": "Слишком частая удаленная аутентификация пользователя, пожалуйста, попробуйте попозже еще раз",
  "US727": "Верификации чужих местностей было слишком часто, пожалуйста,  попробуйте попозже еще раз",
  "US732": "Вы ввели некорректный пароль шесть раз подряд, повторите попытку через пять минут",
  "US733": "Неверный торговый пароль",
  "US750": "Пользователь привязал почту",
  "US752": "Почта пользователя не привязана",
  "US753": "Неправильный код подтверждения",
  "US754": "Неправильный код подтверждения",
  "US756": "Код аутентификации не существует или недействительный",
  "US801": "Пользователь не привязал номер телефона",
  "US802": "Отправить SMS сообщение повторно можно через 30 сек.",
  "US803": "Превышено ограничение на количество запросов SMS кодов. Пожалуйста, повторите попытку через 1 час.",
  "US804": "К вашему аккаунту уже привязан номер телефона",
  "US805": "Данный номер телефона привязан к другому аккаунту",
  "US806": "Информация о привязке аккаунта к номеру телефона временно отсутствует.",
  "US807": "Вы неверно ввели SNS код 5 раз подряд. Пожалуйста, запросите новый код",
  "US808": "Код аутентификации не существует или недействительный",
  "US809": "Пожалуйста, введите правильный SMS код",
  "US810": "Настройка аутентификации безопасности не завершена",
  "US811": "Код аутентификации не привязан",
  "US812": "Введите правильный пароль двойной аутентификации Google",
  "US813": "Информация об отвязывании старого номера телефона временно отсутствует",
  "US814": "Информация о смене привязанного номера телефона на новый номер временно отсутствует.",
  "US815": "Превышено ограничение на количество запросов SMS кодов. Пожалуйста, повторите попытку через 1 час.",
  "US816": "Введите правильный код из SMS",
  "US817": "Введите правильный код из SMS текущего мобильного телефона",
  "US818": "Превышено ограничение на количество запросов SMS кодов. Пожалуйста, повторите попытку через 1 час.",
  "US819": "Введите правильный код из SMS",
  "US820": "Введите правильный код из SMS на новый телефон",
  "US821": "Информация по отвязке номера телефона временно отсутствует",
  "US822": "К Вашему аккаунту привязан номер телефона",
  "US823": "Аутентификация безопасности\" отсутствует",
  "US824": "Аутентификация безопасности\" не может быть изменена",
  "US825": "Аутентификация безопасности\" не изменена",
  "US826": "Пользователь привязал аутентификацию безопасности",
  "US829": "Аутентификация не удалась, пожалуйста, попробуйте еще раз.",
  "US830": "Количество APIKey превзошел лимит",
  "US832": "Код аутентификации не существует или недействительный",
  "US833": "Некорректный код аутентификации",
  "US834": "Почта пользователя не привязана",
  "US835": "Неправильный код подтверждения",
  "US857": "Количество ввода почтового кода для верификации превысило макс.допустимое количество по вводу, пожалуйста, запросите этот код еще раз",
  "US901": "Название для извещения\" не существует",
  "US902": "Статус извещения не изменен",
  "UW205": "Приносим извинения,  активы на Вашем аккаунте были заморожены. Если у Вас возникли вопросы, пожалуйста, обратитесь в службу онлайн поддержки.",
  "UW208": "Верификация почты не удалась, проверьте интернет соединение и попробуйте еще раз",
  "UW209": "Отправка письма не удалась, для повторной отправки, пожалуйста, нажмите \"Отправить снова",
  "UW210": "Срок действия кода верификации почты уже истек, пожалуйста,  подайте новую заявку еще раз.",
  "UW213": "Ссылка не действительна, пожалуйста, подтвердите, что ссылка, на которую вы нажали, является последней направленной Вам в течение одного часа, при не действительности ссылки, пожалуйста, подайте заявку  еще раз",
  "UW236": "Отменяя аутентификации безопасности, пожалуйста, сначала завершите текущую аутентификацию безопасности",
  "WE101": "Пользователь не вошел на аккуант",
  "WE102": "У вас отсутствуют такие права",
  "WE103": "Превышен допустимый предел запросов с IP адреса пользователя",
  "WE104": "Превышен допустимый предел по частоте посещения",
  "WE204": "Ваш аккаунт заблокирован",
  "WE205": "Ваш аккаунт заблокирован",
  "WE206": "Превышен суточный лимит",
  "WE207": "Недостаточно средств",
  "WE209": "При отправлении почты произошла ошибка",
  "WE218": "Пользователь не завершил настройки аутентификации",
  "WE219": "Вы не указали настоящее имя в настройках аутентификации"
}}