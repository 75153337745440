import vueSelect from './vueSelect.vue';
import vueOption from './vueOption.vue';
const components = [vueSelect, vueOption];
const install = function (Vue) {
  if (install.installed) return;
  components.map(component => {
    Vue.component(component.name, component);
  });
  if (typeof window !== 'undefined' && window.Vue) {
    install(window.Vue);
  }
};
export default{
  install,
  components,
  vueSelect,
  vueOption
};
