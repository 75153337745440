export default {ptBr:{
  "403": "Operação falhou. Por favor, entre em contato com o serviço ao cliente.",
  "500": "Ocorreu um erro de sistema",
  "5843": "A identificação que você providenciou já está sendo usada por outra conta. Por favor, tente novamente.",
  "10000": "Servidores ocupados, tente mais tarde.",
  "100001": "Erro de parâmetro",
  "100002": "Parâmetro anormal",
  "100003": "Solicitar erro de \"cabeçalho\"",
  "100004": "Código de verificação expirado",
  "100005": "Erro no código de verificação",
  "100006": "Você foi proibido de negociar. Se tiver alguma dúvida, entre em contato com o serviço de atendimento ao cliente.",
  "100007": "A posição foi bloqueada",
  "100008": "Valor inválido",
  "100009": "Posição máx. de retenção ausente",
  "100010": "Falha ao fazer a ordem - limite máximo da posição de retenção excedido!",
  "100011": "Valor máximo de ordem excedido!",
  "100012": "Margem insuficiente",
  "100013": "Falha ao atualizar a ordem",
  "100014": "A ordem não existe",
  "100015": "Sem contador, impossível fazer a ordem",
  "100016": "O tempo de login expirou, faça login novamente.",
  "100017": "Sem posição para fechar!",
  "100018": "Decimal de preço incorreto!",
  "100019": "Sem ativos!",
  "100022": "O preço não pode ser 0",
  "200007": "O trading de tokens pesquisado não está mais disponível",
  "200008": "O contrato não existe!",
  "200009": "A moeda ainda não está disponível!",
  "200010": "O valor decimal é maior do que o permitido nesta moeda",
  "200011": "Falha ao obter cotação",
  "200012": "O par de trading não existe",
  "200013": "Par de trading necessário",
  "200015": "Saldo insuficiente",
  "200017": "A transferência falhou!",
  "200018": "Leia e concorde com o \"Contrato do Usuário BitForex MT5\"",
  "200024": "Erro de transferência, entre em contato com o serviço de atendimento ao cliente.",
  "200025": "O valor que você transferir deve ser superior a {num}",
  "200026": "Limitação de transferência de saída: deve concluir pelo menos um registro de transferência de entrada",
  "300004": "Falha na transferência, ambiente de sistema anormal",
  "300006": "A transferência falhou, saldo inválido",
  "300007": "Defina o método de verificação de segurança",
  "1000025": "A ordem falhou. Ordens de posição de fechamento só podem ser feitas quando a ordem de posição de fechamento existente for cumprida.",
  "1000026": "A ordem falhou. Foi detectada uma posição com um spread compra-venda melhor na sua conta.",
  "ACT1000": "Erro de parâmetro",
  "ACT1001": "A votação não começou",
  "ACT1002": "A votação acabou",
  "ACT1003": "Menos do que o valor mínimo de votos",
  "ACT1004": "Ativos insuficientes",
  "ACT1005": "Votação Duplicada",
  "BACK_UF019": "Essa conta está congelada.",
  "CAPP001": "A conta não existe",
  "CAPP0010": "O projeto que você escolheu expirou, por favor, escolha outro projeto.",
  "CAPP0011": "Senha incorreta",
  "CAPP0012": "A autorização expirou, por favor, retorne para confirmar a autorização.",
  "CAPP002": "A função transferência de recebimento está proibida.",
  "CAPP003": "Conta congelada, entre em contato com o serviço de atendimento ao cliente.",
  "CAPP004": "A função transferência de envio está proibida.",
  "CAPP005": "Erro anormal de ativo",
  "CAPP006": "O trading de token/moedas que você procura não é aceito.",
  "CAPP007": "Erro anormal de conta",
  "CAPP008": "A ordem não existe",
  "CAPP009": "Ordem expirada",
  "CAPP010": "O projeto que você escolheu expirou, escolha outro projeto.",
  "CAPP011": "Senha incorreta",
  "CAPP012": "Autorização expirada, volte para confirmar a autorização.",
  "CAPP018": "A transferência de ativos está proibida.",
  "CAPP500": "Erro anormal de sistema",
  "CT102": "O par de trading pesquisado ainda não está disponível. Fique atento aos comunicados mais recentes sobre disponibilidades.",
  "CT103": "O valor inserido está fora da faixa de preço permitida.",
  "CT104": "Fundos disponíveis insuficientes.",
  "CT105": "A ordem não existe.",
  "CT106": "O valor da ordem excede o limite máx..",
  "CT107": "A ordem é menor que o limite mín..",
  "CT108": "A conta foi congelada.",
  "CT110": "Excedeu o limite máximo.",
  "CT200": "A função de trading à vista da sua conta está bloqueada, entre em contato com a equipe de atendimento ao cliente para obter detalhes.",
  "CT801": "Número de celular não vinculado.",
  "CT804": "Número de celular vinculado.",
  "CT805": "Este número de celular já está cadastrado, tente novamente.",
  "CT806": "O código de verificação expirou.",
  "CT807": "A verificação do código de verificação de SMS excedeu o número de restrições.",
  "CT809": "Código de verificação de SMS incorreto.",
  "CT810": "Configure a autenticação de segurança.",
  "CT811": "Vincule a chave privada do Google.",
  "CT812": "Código de verificação do Google incorreto.",
  "CT822": "Chave privada do Google vinculada",
  "CT823": "Não existe.",
  "CT824": "Não pode ser modificado.",
  "CT825": "Sem alterações.",
  "CT826": "Autenticação de segurança configurada com sucesso.",
  "CT902": "Não existe.",
  "FC101": "Livro de ordens parado",
  "FC102": "A conta foi congelada.",
  "FC103": "O preço excede o limite.",
  "FC104": "O valor da ordem excede o limite máx..",
  "FC105": "Fundos insuficientes.",
  "FC106": "Posições insuficientes.",
  "FC108": "Falha ao cancelar a ordem.",
  "FC109": "O valor da ordem é inferior ao limite mín.",
  "FC200": "As funções de CApp da sua conta estão bloqueadas, entre em contato com a equipe de atendimento ao cliente para obter detalhes.",
  "FC999": "A transferência de ativos está proibida.",
  "FI105": "O pedido atual foi resgatado",
  "FI109": "A ordem não existe",
  "FI110": "O pedido atual foi resgatado",
  "FT002": "A saldo da sua conta é insuficiente.",
  "LN1000": "Falha ao aumentar/diminuir ativos",
  "LN1001": "Erro de parâmetro",
  "LN1002": "Valor do Empréstimo Incorreto",
  "LN1003": "Usuário não existe",
  "LN1004": "Falha ao obter recursos de usuário disponíveis",
  "LN1005": "Recursos de usuário disponíveis insuficientes",
  "LN1006": "Nenhum resultado de consulta para este pedido",
  "LN1007": "Falha ao congelar ativos do usuário",
  "LN1008": "Falha na criação do pedido",
  "LN1009": "Falha no reembolso do pedido",
  "LN1010": "Falha ao ajustar o LTV do pedido",
  "LN1011": "Falha ao descongelar recursos do usuário",
  "LN1012": "A taxa de token foi alterada, atualize a página e tente novamente.",
  "LN1013": "Falha ao salvar registro de ajuste de LTV",
  "LN1014": "Falha ao congelar ativos do usuário, falha ao descongelar ativos do usuário",
  "LN1015": "Falha ao deduzir recursos do usuário",
  "LN1016": "Falha ao deduzir ativos do sistema",
  "LN1017": "Falha ao adicionar recursos do usuário",
  "LN1018": "Falha ao adicionar ativos do sistema",
  "LN1019": "Falha ao congelar ativos do sistema",
  "LN1020": "Ativos de sistema disponíveis insuficientes",
  "LN1021": "Falha ao obter ativos de sistema disponíveis",
  "LN1022": "Valor de ajuste de LTV incorreto",
  "LN1023": "Tipo errado de moeda ou moeda de garantia emprestada",
  "LN1024": "Prazo de empréstimo errado",
  "LN1025": "Moeda errada emprestada ou moeda de garantia",
  "LN1026": "O pedido está completo, não há necessidade de continuar a reembolsar moedas",
  "LN1027": "Falha ao liberar moeda emprestada",
  "LN1028": "Moedas insuficientes para emprestar",
  "LN1029": "Falha ao aumentar a quantidade total de moedas disponíveis para empréstimo",
  "LN1030": "Não é possível reembolsar devido à alteração do status do pedido",
  "LN1032": "Cálculo de visão geral de ativos falhou",
  "LN1033": "Bloqueio da rede , por favor tente de novo mais tarde",
  "LN1034": "Não há limite para esta moeda, tente novamente mais tarde.",
  "NFT100": "O evento não existe",
  "NFT101": "O evento não começou",
  "NFT102": "Encerrado",
  "NFT103": "Não é permitida a compra direta",
  "NFT104": "A compra não é permitida",
  "NFT105": "O preço de compra está errado",
  "NFT106": "Vendido",
  "NFT107": "Não é possível colocar à venda repetível",
  "NFT108": "Erro de ativos NFT",
  "NFT110": "Taxa de transação insuficiente",
  "NFT111": "NFT atual não é operável",
  "NFT112": "Cancelamento de saque não é permitido",
  "NFT113": "Dados de retirada anormais",
  "NFT114": "Erro de parâmetro de listagem",
  "NFT115": "Não menos do que o preço de oferta atual",
  "NFT116": "O preço não pode ser 0",
  "NFT117": "O lote de leilão não existe",
  "NFT118": "Não revogue durante a transação",
  "NFT120": "Suspensão de Saque",
  "NFT121": "Suspensão de Depósito",
  "NFT122": "A função de saque da sua conta está bloqueada, entre em contato com a equipe de atendimento ao cliente para obter detalhes.",
  "NFT123": "A função de trading NFT da sua conta está bloqueada, entre em contato com a equipe de atendimento ao cliente para obter detalhes.",
  "OAUTH100001": "Sessão de pagamento expirada, tente novamente.",
  "OAUTH100002": "Erro de assinatura",
  "OAUTH100003": "Erro de autenticação",
  "OAUTH100004": "Erro de swap",
  "PB100000": "Valor errado de ativo.",
  "PB100001": "Ativos insuficientes.",
  "PB100002": "A pré-compra não pode ser inferior a 100.",
  "PB100003": "Preço de pré-compra incorreto.",
  "PB100004": "Tipo de token incorreto.",
  "PB100005": "O preço está atualizado, faça sua ordem novamente.",
  "PB100006": "Valor de pré-compra incorreto.",
  "PB110000": "A hora da pré-compra ainda não chegou.",
  "RT100": "Erro no código de verificação",
  "RT101": "Formato de parâmetro incorreto.",
  "RT102": "O par de trading não existe",
  "RT103": "Erro de configuração do sistema.",
  "RT104": "Ambiente anormal do sistema",
  "RT201": "Favoritos atingiram o limite Max.",
  "RT301": "Erro interno do servidor.",
  "RT302": "Erro interno do servidor.",
  "RT303": "Erro interno do servidor.",
  "RT304": "Erro interno do servidor.",
  "RT398": "Erro interno do servidor.",
  "RT399": "A frequência de solicitação do servidor é muito alta.",
  "RT401": "Tipo de transferência não suportado",
  "RT999": "Erro indefinido.",
  "SL303": "Bloqueio da rede , por favor tente de novo mais tarde",
  "SL304": "Falha ao fazer a ordem - limite máximo da posição de retenção excedido!",
  "SP801": "A inscrição de listagem foi aprovada e não pode ser alterada.",
  "SP802": "Nenhum registro de inscrição na lista. Candidate-se para a lista primeiro.",
  "SP803": "Você não pode editar os parâmetros de configuração.",
  "SP804": "Pague a taxa de listagem primeiro.",
  "SP805": "Fundos insuficientes.",
  "SP806": "A taxa de listagem foi paga.",
  "SP807": "O parâmetro de configuração não existe.",
  "TF001": "A função de transferência da sua conta carteira está proibida, entre em contato com a equipe de atendimento ao cliente para obter detalhes.",
  "TF002": "A função de transferência da sua conta à vista está bloqueada, entre em contato com a equipe de atendimento ao cliente para obter detalhes.",
  "TF003": "A função de transferência da sua conta à vista está bloqueada, entre em contato com a equipe de atendimento ao cliente para obter detalhes.",
  "TF004": "A função de transferência da sua conta à vista está proibida, entre em contato com a equipe de atendimento ao cliente para obter detalhes.",
  "TF005": "A função de transferência da sua conta de CApp está proibida, entre em contato com a equipe de atendimento ao cliente para obter detalhes.",
  "TS1001": "Sistema ocupado, tente novamente mais tarde.",
  "TS1002": "Sistema ocupado, tente novamente mais tarde.",
  "TS1003": "Ações inscritas excedidas",
  "TS1004": "Saldo insuficiente",
  "TS1005": "Falha na consulta",
  "TS1006": "Falha na assinatura",
  "TS1007": "Sistema ocupado, tente novamente mais tarde.",
  "TS1008": "Sistema ocupado, tente novamente mais tarde.",
  "TS1009": "Sistema ocupado, tente novamente mais tarde.",
  "TS1010": "Sistema ocupado, tente novamente mais tarde.",
  "TS1011": "Sistema ocupado, tente novamente mais tarde.",
  "TS1012": "Sistema ocupado, tente novamente mais tarde.",
  "u100017": "A conta não existe",
  "u100018": "Saldo inválido",
  "u100019": "Erro de atualização de ativos",
  "u100020": "Posição não encontrada",
  "u100021": "Mais posições necessárias",
  "u100022": "Tokens não encontrados",
  "u100023": "Erro de token congelado",
  "u100024": "Par de trading não encontrado",
  "u100025": "Erro de combinação de proporção",
  "u100026": "Erro de valor",
  "u100027": "Erro no valor do swap",
  "u100028": "Pool vazio",
  "u100029": "Saldo de pool inválido",
  "u100030": "Erro de taxa de resgate",
  "u100031": "A função Swap Trading em sua conta está bloqueada, entre em contato com a equipe de atendimento ao cliente para obter detalhes.",
  "u300006": "Conta congelada, entre em contrato com a equipe do serviço de atendimento ao cliente.",
  "u300007": "Erro de ativo",
  "u300008": "Sistema sobrecarregado",
  "u300009": "Os tokens já existem",
  "u400001": "O primeiro intervalo de tempo que você pode escolher é de 24 horas a partir da sua data de participação.",
  "UF001": "Os dados não podem estar vazios.",
  "UF002": "Não mais do que 20 endereços de saque.",
  "UF003": "Este endereço já existe.",
  "UF015": "A conta foi congelada.",
  "UF016": "O endereço de saque não existe.",
  "UF017": "O endereço de saque não pertence a este usuário.",
  "UF018": "Falha na resolução do endereço.",
  "UF019": "A função de saque da sua conta está bloqueada, entre em contato com a equipe de atendimento ao cliente para obter detalhes.",
  "UF020": "Excede o limite diário de saque.",
  "UF021": "Fundos insuficientes.",
  "UF022": "Há um depósito não confirmado.",
  "UF023": "Falha na resolução do endereço.",
  "UF024": "Identificador Cripto FIO inválido.",
  "UF030": "Falha ao adicionar o endereço, verifique a conexão com a rede e tente novamente.",
  "UF034": "Falha ao cancelar",
  "UF036": "A taxa de transação de saque é inferior ao limite mínimo.",
  "UF037": "O valor do saque é inferior ao limite mínimo.",
  "UF038": "Esta moeda/token não aceita saques para um endereço BitForex, tente outro endereço.",
  "UF039": "Em manutenção, temporariamente sem suporte para saques",
  "UF101": "Formato TXID incorreto, digite novamente.",
  "UF102": "Você solicitou 5 vezes em uma hora, tente novamente mais tarde.",
  "UF108": "Deposite com o endereço que você enviou.",
  "UF301": "O último preço foi alterado. Pegue o empréstimo novamente.",
  "UF302": "Fundos insuficientes.",
  "UF303": "Valor inválido.",
  "UF304": "O valor do empréstimo disponível atingiu o limite.",
  "UF305": "O resgate não está disponível no momento.",
  "UF307": "O valor do empréstimo atingiu o valor máximo.",
  "UF308": "O número de participantes atingiu o limite.",
  "UF312": "O empréstimo e a devolução não estão disponíveis devido à liquidação forçada.",
  "UF313": "O valor devolvido é maior do que o necessário.",
  "US1752": "Nº de telefone desativado",
  "US1753": "Endereço de E-mail Desativado",
  "US1754": "Autenticação de identidade desativada",
  "US1755": "Conta encerrada",
  "US601": "Formato de endereço de e-mail incorreto.",
  "US602": "Sua senha deve ter de 8 a 20 caracteres e conter letras maiúsculas, minúsculas e números.",
  "US604": "O tamanho do arquivo excede o limite.",
  "US605": "Formato de arquivo incorreto.",
  "US607": "Carregue sua carteira de identidade com foto.",
  "US608": "Carregue seu passaporte com foto.",
  "US609": "Conclua a verificação de identidade (KYC) primeiro.",
  "US651": "As informações da API não pertencem ao usuário",
  "US660": "Código de verificação de foto incorreto.",
  "US661": "A identificação que você providenciou já está sendo usada por outra conta. Por favor, tente novamente.",
  "US665": "Falha ao validar reCAPTCHA, tente novamente",
  "US667": "Digite o número de celular que você vinculou.",
  "US668": "A identificação que você enviou foi usada em outra conta. Tente novamente.",
  "US669": "Domínio de e-mail não suportado",
  "US671": "A verificação TOTP falhou muitas vezes, por favor, tente novamente mais tarde",
  "US701": "Este e-mail já existe",
  "US702": "O código de verificação expirou.",
  "US703": "O código de verificação está incorreto.",
  "US704": "Esta conta não existe.",
  "US705": "Sua conta está bloqueada, entre em contato com o serviço on-line da BitForex.",
  "US706": "Sua conta está bloqueada, tente novamente em 1 hora.",
  "US707": "Você digitou um código de verificação incorreto 5 vezes. Solicite um novo código de verificação.",
  "US708": "O e-mail não existe.",
  "US709": "Senha ou e-mail incorretos.",
  "US710": "Este e-mail já existe",
  "US711": "O código de SMS está incorreto.",
  "US712": "Usuário ou senha incorretos.",
  "US715": "O UID do emissor do convite não existe",
  "US724": "O login remoto é muito frequente, por favor, tente novamente mais tarde.",
  "US725": "O login remoto falhou.",
  "US726": "O login remoto é muito frequente, tente novamente mais tarde.",
  "US727": "O login remoto é muito frequente, tente novamente mais tarde.",
  "US732": "Você digitou uma senha incorreta 6 vezes, tente novamente em 5 minutos.",
  "US733": "Senha de trading incorreta",
  "US750": "E-mail vinculado com sucesso!",
  "US752": "Por favor, vincule seu endereço de e-mail à sua conta.",
  "US753": "Erro de código de verificação",
  "US754": "Erro de código de verificação",
  "US756": "O código de verificação não existe ou expirou.",
  "US801": "Nenhum celular vinculado.",
  "US802": "Solicite um novo código de verificação de SMS em 30s.",
  "US803": "O erro de código de SMS excedeu o número máximo de tentativas. Tente novamente em 1 hora.",
  "US804": "Número de celular vinculado.",
  "US805": "O número do celular foi vinculado.",
  "US806": "As informações temp. do celular não existem.",
  "US807": "Você digitou um código de verificação incorreto 5 vezes, solicite um novo.",
  "US808": "O código de verificação não existe ou expirou.",
  "US809": "Digite o código de SMS correto.",
  "US810": "A autenticação de segurança não foi definida.",
  "US811": "A chave privada do Google não foi definida.",
  "US812": "Digite a senha correta de autenticação de dois fatores do Google.",
  "US813": "As informações temp. de celular anteriores não existem.",
  "US814": "As novas informações temp. de celular não existem.",
  "US815": "Muitas tentativas de código de verificação de SMS, tente novamente em 1 hora.",
  "US816": "Digite o código de SMS correto.",
  "US817": "Digite o código de SMS anterior correto.",
  "US818": "Muitas tentativas de código de verificação de SMS, tente novamente em 1 hora.",
  "US819": "Digite o código de SMS correto.",
  "US820": "Digite o novo código de SMS correto.",
  "US821": "As informações temp. de desvinculação de celular não existem.",
  "US822": "Chave privada do Google vinculada.",
  "US823": "O [securityAuthenticationName] não existe.",
  "US824": "O [securityAuthenticationName] não pode ser alterado.",
  "US825": "O [securityAuthenticationName] não pode ser alterado.",
  "US826": "Autenticação de segurança configurada.",
  "US829": "Falha na verificação. Tente novamente.",
  "US830": "O valor da chave de API excede o limite.",
  "US832": "O código de verificação não existe ou expirou.",
  "US833": "Código de verificação inválido.",
  "US834": "Vincule seu endereço de e-mail à sua conta.",
  "US835": "Erro de código de verificação",
  "US857": "O código do e-mail falhou 5 vezes. Solicite um novo código de e-mail.",
  "US901": "O [notificationName] não existe.",
  "US902": "O status da notificação não foi alterado.",
  "UW205": "Desculpe, os ativos da sua conta foram congelados. Se você tiver alguma dúvida, entre em contato com o atendimento ao cliente on-line da BitForex.",
  "UW208": "A verificação de e-mail falhou, verifique a conexão com a rede e tente novamente.",
  "UW209": "O e-mail não foi enviado, tente novamente.",
  "UW210": "O código de verificação de e-mail expirou, solicite um novo.",
  "UW213": "O link expirou. Reenvie a solicitação de envio.",
  "UW236": "Conclua as configurações de segurança primeiro.",
  "WE101": "Faça login primeiro.",
  "WE102": "Sem permissão.",
  "WE103": "O endereço IP não é permitido.",
  "WE104": "A frequência de acesso do usuário é muito alta.",
  "WE204": "A conta foi bloqueada.",
  "WE205": "A conta foi congelada.",
  "WE206": "Limite diário excedido.",
  "WE207": "Fundos insuficientes.",
  "WE209": "Falha ao enviar o e-mail.",
  "WE218": "O usuário não concluiu a autenticação.",
  "WE219": "Conclua a verificação de identidade KYC."
}}