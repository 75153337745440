import Big from 'big.js';
import {
  formatNum,
  toNonExponential
} from '~/utils/util'; // formatMoney, formatTime,
import * as formula from '~/utils/formula';
import i18n from '~/locales';
const commonDealNum = (num, precision, isRound) => {
  if (!num || !(num - 0)) {
    return (num === undefined || num === null) ? '' : 0;
  } 
  num = toNonExponential(num - 0);
  if (precision || precision === 0) {
    num = formatNum(num, precision, isRound);
  }
  return new Big(num).valueOf();
};
const getOrderType = (type, side) => {
  switch (type) {
    case '1': // 限价单
    case '4': // 限价平仓单
      return side === '1' ? i18n.t('order_24') : i18n.t('order_25');
    case '2': // 市价单
    case '5': // 市价强平单
      return side === '1' ? i18n.t('usdt_32') : i18n.t('usdt_31');
    case '3': // 强平单
      // return i18n.t('perpetual_163');

      return side === '2' ? i18n.t('bill_44') : i18n.t('bill_45');
    default:
      return '';
  }
};
// 新的类型名字区分
const getOrderType1 = type => {
  switch (type) {
    case '1': // 限价单
    case '4': // 限价平仓单
      return i18n.t('perpetual_193');
    case '2': // 市价单
    case '5': // 市价强平单
      return i18n.t('perpetual_55');
    case '3': // 强平单
      return i18n.t('perpetual_195');
    default:
      return '';
  }
};
export default {
  contractList_USDT (state) { // U合约列表
    return state.contractList_USDT || [];
  },
  accountInfo_USDT (state) { // U合约用户信息
    return state.accountInfo_USDT || {};
  },
  // 仓位页面展示信息
  positionInfoList_USDT (state, getters) {
    let info = state.positionInfoList_USDT;
    for (let i = 0; i < info.length; i++) {
      // 合约币对
      info[i].symbolDes = info[i].symbol.split('-')[2].toUpperCase() + '/' + info[i].symbol.split('-')[1].toUpperCase();
      // 仓位数量
      info[i].currentPositionDes = commonDealNum(info[i].currentPosition);
      // 默认平仓数量
      info[i].orderQty = commonDealNum(info[i].currentPosition);
      // 默认平仓价格
      info[i].price = commonDealNum(info[i].markPrice, info[i].priceOrderPrecision);
      // 开仓均价
      info[i].avgCostPriceDes = commonDealNum(info[i].avgCostPrice, info[i].priceOrderPrecision);
      // 标记价格
      info[i].markPriceDes = commonDealNum(info[i].markPrice, info[i].priceOrderPrecision);
      // 强平价格
      info[i].liquidationPriceDes = info[i].liquidationPrice > 0 ? commonDealNum(info[i].liquidationPrice, info[i].priceOrderPrecision) : '- -';
      // 未实现盈亏
      info[i].unrealisedPNL = info[i].upnl;
      // info[i].unrealisedPNL = commonDealNum(formula.usdt_unrealisedPNL(info[i].avgCostPrice, info[i].markPrice, info[i].currentPosition, info[i].side === 1), 8);
      // 仓位价值
      info[i].pv = commonDealNum(info[i].positionValue, info[i].priceOrderPrecision);
      // 起始仓位保证金
      info[i].imrDes = info[i].margin;
      // 回报率
      const roe = formula.ROE(Number(info[i].unrealisedPNL), Number(info[i].imrDes));
      info[i].pp = commonDealNum(roe ? roe.times(100) : 0, 2) + '%';
      info[i].pps = Number(info[i].upnl) >= 0; // 分享时区别正负
      // 已实现盈亏
      info[i].realizedPnlDes = commonDealNum(info[i].realizedPnl, 8);
    }
    return info;
  },
  // 平仓盈亏
  placeList_USDT (state, getters) {
    const SIDE = {
      1: i18n.t('perpetual_189'),
      2: i18n.t('perpetual_190')
    };

    let data = state.placeList_USDT;
    if (data) {
      return data.map(item => {
        item.symbolDes = getters.alias;
        item.sideDes = SIDE[item.side];
        item.typeDes = getOrderType1(item.type);
        item.positionAvgPriceDes = item.positionAvgPrice;
        item.pnlAmountDes = item.pnlAmount;
        item.priceDes = item.price;
        return item;
      });
    }
    return [];
  },
  incompleteOrders_USDT (state, getters) { // 未完成委托订单
    let data = state.incompleteOrders_USDT;
    // 订单状态
    const orderStatu = {
      2: i18n.t('order_19'),
      3: i18n.t('order_18'),
      4: i18n.t('order_21'),
      7: i18n.t('order_20'),
      1: i18n.t('order_17'),
      8: i18n.t('perpetual_142')
    };

    /***
 *  委托价值
 *  合约数量/委托价格
 *  @param total  合约数量
 * @param op   委托价格
 */
// export const ov = (total, op) => {
//   if (total && op) {
//     return new Big(total).div(op);
//   }
// };
    if (data) {
      return data.map(item => {
        item.symbolDes = item.alias;
        item.volumeDes = commonDealNum(item.volume);
        item.priceDes = commonDealNum(item.price);
        item.dealVolumeDes = commonDealNum(item.dealVolume);
        item.remainVolume = item.volume ? commonDealNum(new Big(item.volume).minus(item.dealVolume).valueOf()) : '--';
        item.avgPriceDes = commonDealNum(item.avgPrice, item.priceOrderPrecision) || '- -';
        item.sideDes = getOrderType(item.type, item.side);
        item.commissionVal = item.volume ? commonDealNum(new Big(item.volume).times(item.price).valueOf(), item.baseShowPrecision) : '--';
        item.cv = item.dealVolume ? commonDealNum(new Big(item.dealVolume).times(item.avgPrice).valueOf(), item.baseShowPrecision) : '--';
        item.statusDes = orderStatu[item.status] ? orderStatu[item.status] : item.status;
        return item;
      });
    }
    return [];
  },
  conditionOrders_USDT (state, getters) { // 条件委托单列表
    let data = state.conditionContractOrders_USDT;
    // 订单类型
    const orderStatu = {
      1: i18n.t('usdt_114'),
      2: i18n.t('usdt_115'),
      3: i18n.t('usdt_116'),
      4: i18n.t('usdt_117')
    };
    if (data) {
      return data.map(item => {
        item.symbolDes = item.symbol.split('-')[2].toUpperCase() + '/' + item.symbol.split('-')[1].toUpperCase();
        item.volumeDes = commonDealNum(item.volume) + ' ' + item.symbol.split('-')[2].toUpperCase();
        item.onlyReducePositionDes = item.onlyReducePosition ? i18n.t('market_68') : i18n.t('market_69');
        item.priceDes = item.orderType === 2 || item.orderType === 5 ? i18n.t('perpetual_55') : item.price + ' ' + item.symbol.split('-')[1].toUpperCase();
        item.triggerPriceDes = (item.triggerPriceType === 1 ? i18n.t('usdt_108') : i18n.t('perpetual_124')) + ' ' + item.geOrLe + ' ' + item.triggerPrice + ' ' + item.symbol.split('-')[1].toUpperCase();
        item.profitLossMarkDes = orderStatu[item.profitLossMark];
        return item;
      });
    }
    return [];
  },
  historyConditionOrders_USDT (state, getters) { // 条件委托单历史列表
    let data = state.historyConditionOrders_USDT;
    // 订单类型
    const orderType = {
      1: i18n.t('usdt_114'),
      2: i18n.t('usdt_115'),
      3: i18n.t('usdt_116'),
      4: i18n.t('usdt_117')
    };
    // 订单状态
    const orderStatus = {
      1: i18n.t('limit_notTriggered'),
      2: i18n.t('swap_95'),
      3: i18n.t('swap_96'),
      4: i18n.t('paymentCards_27')
    };
    if (data) {
      return data.map(item => {
        item.statusDes = orderStatus[item.status];
        item.symbolDes = item.symbol.split('-')[2].toUpperCase() + '/' + item.symbol.split('-')[1].toUpperCase();
        item.volumeDes = commonDealNum(item.volume) + ' ' + item.symbol.split('-')[2].toUpperCase();
        item.priceDes = item.orderType === 2 || item.orderType === 5 ? i18n.t('perpetual_55') : item.price + ' ' + item.symbol.split('-')[1].toUpperCase();
        item.triggerPriceDes = (item.triggerPriceType === 1 ? i18n.t('usdt_108') : i18n.t('perpetual_124')) + ' ' + item.geOrLe + ' ' + item.triggerPrice + ' ' + item.symbol.split('-')[1].toUpperCase();
        item.profitLossMarkDes = orderType[item.profitLossMark];
        return item;
      });
    }
    return [];
  },
  completeOrders_USDT (state, getters) { // 已完成委托订单
    let data = state.completeOrders_USDT;
    if (data) {
      return data.map(item => {
        item.symbolDes = getters.alias;
        item.dealPriceDes = item.dealPrice;
        item.cv = commonDealNum(new Big(item.dealVolume).times(item.dealPrice).valueOf(), state.contractInfo.baseShowPrecision);
        item.feeDes = item.fee;
        item.sideDes = getOrderType(item.type, item.side);
        return item;
      });
    }
    return [];
  },
  historyContractOrders_USDT (state, getters) { // 历史委托订单
    let data = state.historyContractOrders_USDT;
    // 订单状态
    const orderStatu = {
      '2': i18n.t('order_19'), 
      '3': i18n.t('order_18'),
      '4': i18n.t('order_21'),
      '7': i18n.t('order_20')
    };
    // 委托类型
    // const orderType = [i18n.t('order_24'), i18n.t('order_25'), i18n.t('perpetual_163')];
    if (data) {
      return data.map(item => {
        item.symbolDes = getters.alias;
        item.volumeDes = commonDealNum(item.volume);
        item.priceDes = (item.type === '2' || item.type === '5') && !item.price ? i18n.t('perpetual_167') : commonDealNum(item.price, state.contractInfo.priceOrderPrecision);
        item.dealVolumeDes = commonDealNum(item.dealVolume, 8);
        item.triggerPriceDes = commonDealNum(item.triggerPrice, state.contractInfo.priceOrderPrecision) || '- -';
        item.avgPriceDes = item.avgPrice || '- -';
        item.sideDes = getOrderType(item.type, item.side);
        item.commissionVal = (item.type === '2' || item.type === '5') && !item.price ? '- -' : commonDealNum(new Big(item.volume).times(item.price).valueOf(), state.contractInfo.baseShowPrecision);
        item.statusDes = orderStatu[item.status];
        item.cv = commonDealNum(new Big(item.dealVolume).times(item.avgPrice).valueOf(), state.contractInfo.baseShowPrecision) || '- -';
        return item;
      });
    }
    return [];
  }
};
