export default {
  "3599": "Account Name",
  "3600": "Account creation in process",
  "3601": "Activate Account",
  "3602": "I have read the BitForex MT5 Terms and Service and I agree",
  "3603": "Cancel",
  "3604": "Completed",
  "3605": "Applying",
  "3606": "Transfer History",
  "3607": "MT5 Bills",
  "3608": "Activate Account",
  "3609": "MT5 Account",
  "3610": "ID",
  "3611": "Account Password",
  "3612": "Reset Password",
  "3613": "Trade Now",
  "3693": "Withdrawals are suspended for 24 hours when a new address is added",
  "3696": "New address detected, withdrawals will be available at {time}",
  "3778": "Today's date or signature is not included in the self portrait",
  "3779": "Identification is not included in the self portrait",
  "3780": "Self portrait cannot be verified. Please submit a clear image",
  "3781": "Photo of the Identification cannot be verified. Please submit a clear image",
  "3782": "Photoshop detected on the Identification",
  "3783": "Photoshop detected for the self portrait",
  "3784": "Please make sure the Identification is your own",
  "3785": "Personal information error: ID number",
  "3786": "Personal information error: ID expiration date",
  "3787": "Personal information error: Country/Region",
  "3788": "Personal information error: Full name",
  "3789": "Signature cannot be verified on the self portrait",
  "3792": "Request failed, please contact customer support",
  "3793": "MEMO error, please enter the correct MEMO",
  "3794": "The address is not activated",
  "3795": "Internal transfer within the platform is prohibited for this asset",
  "3796": "MEMO is required",
  "3797": "Withdrawal amount is required to be a integer",
  "3798": "Receiving address is offline",
  "3799": "Withdrawals of this asset is temporarily suspended",
  "3800": "Withdrawal audit failed, please contact customer support",
  "3801": "Incorrect withdrawal address",
  "3875": "Crypto-Currencies",
  "3876": "Indices",
  "3877": "Metals",
  "3878": "Commodities",
  "3879": "Forex",
  "3880": "Minimum Volume per Trade",
  "3881": "Friday close time(GMT+0)",
  "3882": "Sunday open time(GMT+0)",
  "3883": "Trading time(GMT+0)",
  "3884": "Commission(USDT/Lot)",
  "3885": "Tick sze",
  "3886": "Leverage",
  "3887": "Contract size",
  "3888": "Full name",
  "3889": "Symbol",
  "3900": "MT5 Transfer out",
  "3997": "BitForex Capital",
  "4008": "Season 1",
  "4009": "Season 2",
  "4010": "Round 1",
  "4011": "（Jan. 7th - Jan. 10th)",
  "4014": "{num} {coin} limit per transfer",
  "4015": "A 20 times limits is applied for transfer in and out for the MT5 account",
  "4016": "Please try again at 16:00 (GMT+8)",
  "4078": "Transfer History",
  "4294": "Airdrop",
  "4367": "IMPORTANT NOTICE",
  "4400": "TOKEN ALLOCATION",
  "4412": "MT5 Competition",
  "4413": "Perpetual Contract Competition",
  "4434": "Feb. 26th 19:00 (GMT+8)",
  "4435": "Feb. 27th 19:00 (GMT+8)",
  "4436": "Feb. 28th 19:00 (GMT+8)",
  "4437": "Feb. 25th - Feb. 28th 19:00 (GMT+8)",
  "4457": "Round 1",
  "4458": "Feb.19 - Feb.22",
  "4558": "（Mar. 17th - Mar. 20th）",
  "4667": "Deposits confirmations are not reached, please be patient.",
  "4680": "Equity",
  "4681": "Credit included",
  "4683": "LOGIN NOW",
  "5349": "(Apr. 14th - Apr. 17th)",
  "5361": "CApp participants are required to complete KYC, please complete your KYC in the account setting.",
  "5466": "Min. deposit amount: {0.01} BTC, deposit amount less than the min. deposit amount will not be able to be reflected on your account.",
  "5484": "Withdrawal function on your account is locked, please contact the Customer Support Team for details.",
  "5485": "Trading function on your account is locked, please contact the Customer Support Team for details.",
  "5486": "Perpetual Contract Trading function on your account is locked, please contact the Customer Support Team for details.",
  "5487": "\bCApp functions on your account is locked, please contact the Customer Support Team for details.",
  "5488": "Your {被禁止的账户} account transfer function is prohibited, please contact the Customer Support Team for details.",
  "5545": "Transfer function on your Spot account is prohibited, please check with the Customer Support Center for details.",
  "5546": "Transfer function on your Perpetual Contract account is prohibited, please check with the Customer Support Center for details.",
  "5547": "Transfer function on your MT5 account is prohibited, please check with the Customer Support Center for details.",
  "5548": "Transfer function on your CApp account is prohibited, please check with the Customer Support Center for details.",
  "5620": "(May 12th - May 15th)",
  "5666": "Optional",
  "5844": "이 이름은 다른 계정의 KYC에 사용된 이름입니다.",
  "5845": "KYC Identification has been used for another account.",
  "5865": "Single Cont. value 1 USD",
  "5940": "(August 4th - August 7th)",
  "5958": "(August 18th - August 21st)",
  "6007": "(September 15th - September 18th)",
  "6134": "Season 5",
  "6152": "(September 22nd - September 25th)",
  "6302": "(Nov. 3rd - Nov. 6th)",
  "6825": "(Nov. 17th - Nov. 20th)",
  "6848": "(Dec. 8th - Dec. 11th)",
  "8562": "Event ended",
  "8563": "Event in progress",
  "8564": "Event to start",
  "8565": "Preliminary",
  "8566": "Second round",
  "8567": "The finals",
  "8568": "To start",
  "8569": "In progress",
  "8570": "End",
  "8571": "Temporarily lead",
  "8572": "Event rules",
  "8573": "Retweets volume",
  "8574": "Trading volume",
  "8575": "Number of votes",
  "8576": "Vote",
  "8577": "Confirm",
  "8578": "Kind reminder",
  "8579": "Trade now",
  "8580": "Get it now",
  "8581": "Exclusive NFT rewards",
  "8582": "More Details",
  "8583": "Trade Now",
  "8584": "Submit",
  "8585": "Congratulations! You are eligible for NFT rewards",
  "8586": "Please fill in your wallet address below, the award will be issued to your filled address after verification. Please make sure the address is complete and correct, otherwise it will lead to award failure.",
  "8587": "Please full in your Etheruem wallet address",
  "8588": "Operation completed",
  "8589": "Unrewarded",
  "8590": "The voting results are being counted",
  "8591": "Submitted, please do not resubmit",
  "8592": "Vote successful",
  "8593": "The current number of votes has been used up. Voting for next time:",
  "8594": "The number of votes for the current IP has been used up. Voting for next time:",
  "8595": "You have not logged in, please log in first",
  "8596": "Log in",
  "8597": "It is forbidden to vote for both side",
  "8598": "Restricted voting",
  "8599": "Vote failed",
  "8600": "Not in event",
  "8601": "Too frequent",
  "8602": "Event not start yet",
  "8603": "Incorrect address format",
  "8604": "Sorry. Not eligible for exclusive NFT reward!",
  "8605": "Crypto Cup Rules",
  "8606": "1. Event Period",
  "8607": "2022/1/10 10:00 AM to 2022/1/23 09:59 AM (GMT+8).",
  "8608": "2. Competition Format",
  "8609": "Participating projects will compete in pairs. The score will be calculated by weighting three factors:\n·Trading volume on the BitForex exchange during the competition. \n·Users vote on the event page for each participating token. \n·Campaign Twitter forwarding volume.",
  "8610": "The winning token enters the next round of competition, a total of 3 rounds. The trading volume of participating tokens will be calculated as the sum of the trading volumes of all trading pairs of the token.",
  "8611": "First Round",
  "8612": "There are 8 tokens in the competition,contested in pairs. Each pair will compete for 24 hours. The winner of each group will enter the next round.",
  "8613": "Second Round",
  "8614": "The second round can only be started after all the contests of the first round finish. There are 4 tokens in the competition,contested in pairs. Each pair will compete for 48 hours. The winner of each group will enter the next round.",
  "8615": "Final Round",
  "8616": "The final round can only be started after all the contests of the second round finish. There are 1pair in the competition, they will compete for 3 days. Winner will be the champion of the event.",
  "8617": "The start time of each round is subject to the official announcement. If the announcement is not issued, it is deemed that the competition has not started.",
  "8618": "3. Contestants",
  "8619": "First Round",
  "8620": "THG vs AXS",
  "8621": "BMON vs DPET",
  "8622": "GHC vs OMI",
  "8623": "SENSO vs MYRA",
  "8624": "4. How to Get Started",
  "8625": "4.1 Vote",
  "8626": "During the match time, logged-in users and visitors both have the right to vote after entering the event page.",
  "8627": "Each logged-in user can only vote 1 per hour. There is no limit on the number of votes.",
  "8628": "In each match, the subject with voting rights can only vote for one token, not both.",
  "8629": "4.2 Twitter Forwarding",
  "8630": "After the event begins, users will be able to retweet the official Twitter poster of supported tokens. There is no limit on the number of retweet times.",
  "8631": "4.3 Trading",
  "8632": "During the match time, logged-in users will be able to trade the token they support. This will increase the transaction volume of the token during the competition.",
  "8633": "4.4 Promotions & Giveaways",
  "8634": "Users who want to enter the promotions and giveaways during the event have to join:\nBitforex official Twitter: @bitforexcom\nBitforex Official Telegram:",
  "8635": "https://t.me/BitForexEnglish",
  "8636": "5. How to Win",
  "8637": "For users ranked Top1 when trading the winning token of each competition during the event period, there will be a Diamond Mystery Box reward - 100% to get NFT!",
  "8638": "For users ranked Top2-5 when trading the winning token & Top 1-5 when trading another side token of each competition in the event period, there will be a Gold Mystery Box reward - 15% to get NFT! (85% to get a random number of tokens for the eight participating projects of CryptoCup)",
  "8639": "6.What are the Rewards that can be Obtained",
  "8640": "The Mystery Box contains the hottest NFT at the moment:The Sandbox Land, League of Kingdoms Land, Galaxy Heroes,The Doge Pound, Yakuza Cats Society, Wicked Ape Bone Club, Rogue Society Bot, ZED-RUN ...",
  "8641": "First Round: 4 Diamond + 36 Gold Mystery Boxes",
  "8642": "Second Round: 2 Diamond + 18 Gold Mystery Boxes",
  "8643": "Final Round: 1 Diamond + 9 Gold Mystery Boxes + one-week free trading fee for all trading pairs of the champion token",
  "8644": "The champion project party will get a market promotion done by BitForex worth $20,000 including social media promotions on BitForex channels, article promotions, ad displays on our website and marketing on partner websites from our exchange. This amount is the value of the whole marketing promotion and is not going to be sent to the winning token (Bitforex will make all the promotions directly).",
  "8645": "7. Terms & Conditions",
  "8646": "All event awards will be notified to the winners by Email, you will be eligible to claim it within 10 days. After that, your reward will be distributed 14 business days after the event is ended.",
  "8647": "Users who have received NFT rewards need to fill in the NFT wallet address on the event page.",
  "8648": "The valid time to fill in is 30 days after the announcement of the event over. After 30 days the fill in entrance will be closed.",
  "8649": "Any false info or incorrect info detected from the event form will lead to a disqualification from the event.",
  "8650": "BitForex reserves the right to disqualify participants from the campaign if they display any fraudulent behavior. BitForex will take a random snapshot during the campaign. Ineligible users will receive no rewards.",
  "8651": "BitForex reserves the right to cancel or amend the trading competition or trading competition rules at our sole discretion.",
  "8652": "Crypto Cup Schedule",
  "8653": "1st Round",
  "8654": "01/10 10:00 AM - 01/12 09:59 AM (GMT+8) Thetan vs Axie Infinity",
  "8655": "01/11 10:00 AM - 01/13 09:59 AM (GMT+8) Binamon vs My DeFi Pet",
  "8656": "01/12 10:00 AM - 01/14 09:59 AM (GMT+8) Galaxy Heroes Coin vs ECOMI",
  "8657": "01/13 10:00 AM - 01/15 09:59 PM (GMT+8) Senso vs Mytheria",
  "8659": "01/10 10:00 AM - 01/15 09:59 AM (GMT+8) Group 1-1 & 1-2 & 1-3 & 1-4 compete",
  "8660": "Statistics of the first round winners;",
  "8661": "Poster promotion of the winning Tokens on social media;",
  "8662": "Announcement of the second round start time and the timeline of each group competition;",
  "8663": "Token canvassing posters promotion in second round;",
  "8664": "Potential technical adjustments.",
  "8665": "Claim the price pool and distribute awards for the corresponding users in the 1st round.",
  "8666": "2nd Round",
  "8667": "01/16 10:00 AM - 01/19 09:59 PM (GMT+8) Group 2-1 & 2-2 compete",
  "8668": "Statistics of the second round winners;",
  "8669": "Poster promotion of the winning Tokens on social media;",
  "8670": "Announcement of the final round start time and the competition timeline;",
  "8671": "Token canvassing posters promotion in final round;",
  "8672": "Potential technical adjustments.",
  "8673": "Claim the price pool and distribute awards for the corresponding users in the 2nd round.",
  "8674": "Final Round",
  "8675": "01/20 10:00 AM - 01/23 09:59 PM (GMT+8) The finals",
  "8676": "Statistics of the final winners;",
  "8677": "Champion Token poster promotion (the winning project of CryptoCup event)",
  "8678": "All rewards distributing;",
  "8679": "Open the entrance for NFT fill in NFT wallet address;",
  "8680": "Claim the price pool and distribute awards for the corresponding users in the 3rd round.",
  "8681": "01/24 09:00 - 01/30 18:00 (GMT+8)",
  "8682": "One-week free transaction fee for all trading pairs of the champion token.",
  "8683": "01/15 18:00 - 02/15 18:00 (GMT+8)",
  "8684": "NFT winners fill in the address, the entrance will be closed after 18:00 on 15th February,2022.",
  "8685": "01/23 18:00 (GMT+8) Event Ending Announcement",
  "8686": "CryptoCup Event Prize",
  "8687": "Please enter the redeem code",
  "8688": "Confirm",
  "8689": "Redeem code {code} is taken",
  "8690": "Congratulation on receiving",
  "8691": "Please fill in your recipient address below the reward will be issued to your address after verification by the staff please make sure the address is filled in correctly otherwise the reward will fail.",
  "8692": "Submit",
  "8693": "The prize will be issued to your wallet within 14 working days please pay attention to check!",
  "8694": "Submitted successfully the prize will be issued to your wallet within 14 working days please pay attention to check!",
  "8696": "Invalid Redemption Code",
  "8697": "your reward will be distributed 14 business days after the event is ended.",
  "8698": "Get the failure",
  "8699": "The event will start on 2022/01/10 10:00 (GMT+8), please stay tuned",
  "9064": "Welcome to BitForex",
  "9065": "Trade at BitForex Invest in the Future",
  "9139": "Register with your email or phone",
  "9241": "sufficient",
  "9242": "Platform",
  "9243": "Buy Crypto",
  "9244": "Unsupported Email Domain",
  "9255": "Got it",
  "9258": "BitForex does not provide services to crypto jurisdictions countries or regions which including Mainland-China, Crimea, Cuba, Iran, North Korea, Sudan, Syria, etc.",
  "9353": "Please make sure you are visiting：",
  "9354": "https://<span style=\"color: #14C393;\">www.bitforex.com</span>",
  "9355": "Paxful is a linked website (Paxful.com) or service (Third-Party Service) provided by Paxful USA, Inc. (Paxful Inc.).  For your convenience we have taken steps to make Paxful more accessible to you, and easier for you to use. However, it remains a Third-Party Service.BitForex will not be responsible for any infomational, data, suggestions, comlaints, or claim errors of Paxful.",
  "9356": "AdvCash is a linked website or payment service platform (Third-Party Service) provided by Advanced Cash, an Belizean company. For your convenience we have taken steps to make AdvCash more accessible to you, and easier for you to use. However, it remains a Third-Party Service.BitForex will not be responsible for any infomational, data, suggestions, comlaints, or claim errors of AdvCash",
  "9358": "You need to use the account registered on the page after the jump to complete the purchase.",
  "9359": "Card verification can be requested by Coinify after your purcahsed with your credit card. Your payment will be returned if verifucation is not completed within the 72hrs period after the card verification is requested. Card verification will be requested from Coinify, please check the detailed info through the following link: <br><a href=\"https://help.coinify.com/hc/en-us/categories/360002499620-Buy-and-Sell-Cryptocurrency\" target=\"_blank\">https://help.coinify.com/hc/en-us/categories/360002499620-Buy-and-Sell-Cryptocurrency</a>",
  "9360": "A monthly, daily, by order limits are applied on Coinify. If you are experiencing any purchasing issues during your payment, please contact the Coinify support team.",
  "9362": "Coinify is a linked website or payment service platform (Third-Party Service) provided by Coinify ApS, a limited liability company incorporated in Denmark.For your convenience we have taken steps to make Coinify more accessible to you, and easier for you to use. However, it remains a Third-Party Service.BitForex will not be responsible for any infomational, data, suggestions, comlaints, or claim errors of Coinify.",
  "9406": "{period} days from the interest date",
  "10001": "Request timed out",
  "13960": "*Max. export record is 10,0000 each time.",
  "13961": "*Max export 5 times per month",
  "13983": "Select coin name",
  "14221": "Draw",
  "14226": "min",
  "14227": "h",
  "14228": "d",
  "14229": "w",
  "14230": "m",
  "14231": "More",
  "14232": "indicator",
  "14233": "Event Rewards",
  "14234": "Event Expenditure",
  "14235": "Reward distribution in progress",
  "14241": "MEMO is required, or you will lose your currency",
  "15926": "Future",
  "16188": "Onmeta is a linked website or service (Third-Party Service) provided by ABHIBHA TECHNOLOGIES PTE. LTD., a Singapore company. For your convenience we have taken steps to make Onmeta more accessible to you, and easier for you to use. However, it remains a Third-Party Service.BitForex will not be responsible for any infomational, data, suggestions, comlaints, or claim errors of Onmeta.",
  "100035": "Margin type cannot be empty",
  "100036": "Margin type is incorrect",
  "100037": "Below the minimum order value!",
  "100038": "Exceeded the maximum allowable position for closing!",
  "100039": "Position is exist, adjust margin type is not allowed",
  "100040": "Insufficient adjustable amount",
  "100041": "Leverage is below the allowed value: insufficient margin balance",
  "100042": "Exceeding the maximum allowed leverage ratio.",
  "100043": "Insufficient margin",
  "100044": "Leverage reduction not supported with open positions in Isolated Margin Mode.",
  "100045": "Exceeding the maximum leverage allowed for the trading pair",
  "100046": "The price precision is invalid!",
  "100047": "The order quantity precision is invalid!",
  "100050": "If the position is less than the minimum order quantity, it needs to be completely closed!",
  "100051": "Maximum leverage that can be adjusted for the current position is {num}",
  "100052": "Exceeding the maximum order quantity",
  "100053": "Failed to fetch the latest price",
  "100054": "Failed to fetch depth",
  "100055": "Order failed! The maximum position size is {amount}",
  "100056": "The maximum trading quantity for this contract is {amount}",
  "100057": "There is no risk level corresponding to the position value",
  "100058": "The minimum order quantity for this contract is {amount}",
  "500000000": "Reverse delegation order is not supported at the moment",
  "__version": 1700476202,
  "aboutUs_1": "About BitForex",
  "aboutUs_10": "Advanced Technologies",
  "aboutUs_11": "High-performance matching engine, capable of fast processing 1,600,000 orders per second",
  "aboutUs_12": "Company Introduction",
  "aboutUs_13": "BitForex is a global Top 10 cryptocurrency exchange dedicated to providing users with safe, professional, and convenient digital currency trading services.",
  "aboutUs_14": "BitForex is leading the trend of the cryptocurrency exchange industry by effectively providing a wide range of trading tools including token trading, margin trading, and derivatives —constantly adapting to new market needs with the continuous introduction of new features.",
  "aboutUs_15": "Trade in the moment, invest in the future.",
  "aboutUs_16": "The digitization of assets is the future trend, and BitForex is your best choice.",
  "aboutUs_17": "If you would like to use these brand images for any other purpose, please contact us at support@bitforex.com",
  "aboutUs_18": "Branding Rources for Download",
  "aboutUs_19": "These images have been made available for download to only be used in articles written about BitForex",
  "aboutUs_2": "BitForex,world's leading one-stop financial service platform.",
  "aboutUs_20": "Full-screen Mode",
  "aboutUs_21": "Free Registeration",
  "aboutUs_22": "Invite you to register <br> on BitForex for free!",
  "aboutUs_23": "Already had an account? Log in",
  "aboutUs_3": "Our Advantage",
  "aboutUs_4": "Multiple-token Support",
  "aboutUs_5": "300+ trading pairs, more premiere tokens coming up",
  "aboutUs_6": "High Liquidity",
  "aboutUs_7": "Abundant resources and partners provide high liquidity to the platform",
  "aboutUs_8": "Safety & Stability",
  "aboutUs_9": "Providing safe and stable trading environment and Asset Security Management Solutions.",
  "about_us_01": "Trade at BitForex",
  "about_us_02": "Invest in The <span>Future</span>",
  "about_us_03": "Our Mission",
  "about_us_04": "Our mission is to create a <b>safe, professional, and convenient</b> platform for users to be involved in the next financial generation.",
  "about_us_05": "Safe",
  "about_us_06": "BitForex provides safe and asset security management solutions to all of our users.",
  "about_us_07": "Professional",
  "about_us_08": "With high-performance matching engine, BitForex is capable of processing 1,600,000 orders per second.",
  "about_us_09": "Convenient",
  "about_us_10": "Over 300+ trading pairs on spot trading, other services like margin trading, derivatives and more are all available.",
  "about_us_11": "Who we are",
  "about_us_12": "BitForex is one of the leading cryptocurrency exchanges in the world. By providing a wide range of trading products, which includes: <b>spot trading, margin trading, and derivatives.</b> BitForex constantly lanuch new features and products by adapting to the user needs.",
  "about_us_13": "Our Team",
  "about_us_14": "We work with talents from all over the world. With people from 10 different nationalities working across <b>South Korea, Russia, Germany, United Kingdom, France and more.</b> We’re proud to be one of the world’s most international cryptocurrency teams.",
  "about_us_15": "Word From The CEO",
  "about_us_16": "With advanced trading products and services, BitForex brings broader financial prospects to the cryptocurrency industry. BitForex is committed to continuously meet new demands by promoting new features. We aim to provide a better choice for users. <b>Trade at BitForex, invest in the Future.</b>",
  "about_us_17": "Partners",
  "about_us_18": "Get in Touch",
  "about_us_19": "Contact Us",
  "about_us_20": "Token Listing",
  "about_us_21": "Join Us",
  "academy_1": "BitForex Learning Center",
  "academy_2": "Recommend",
  "academy_3": "Related Articles",
  "academy_4": "More From BitForex Learning Center",
  "academy_5": "Academy",
  "academy_6": "Resources about bitcoin,Digital Asset,cryptocurrency,blockchain,and more.",
  "active_notstart": "Event has not yet started",
  "advanced_market_01": "Vega Protocol (VEGA)",
  "advanced_market_02": "VEGA IS A PROTOCOL FOR CREATING AND TRADING MARGINED FINANCIAL PRODUCTS ON A FULLY DECENTRALISED NETWORK.",
  "advanced_market_03": "2,100 VEGA",
  "advanced_market_03_01": "BitForex Allocation :",
  "advanced_market_04": "June 18th, 2021 at 11:00 (GMT+8)",
  "advanced_market_04_01": "Trading Start Time:",
  "advanced_market_05": "VEGA/USDT",
  "advanced_market_05_01": "Trading Pairs:",
  "advanced_market_06": "Initial Price:",
  "advanced_market_07": "Start | Buy VEGA",
  "advanced_market_08": "Learn more",
  "adv_cash_01": "Estimated Amount Received",
  "adv_cash_02": "Fee Reference",
  "adv_cash_03": "In Progress...",
  "adv_cash_04": "Purchase Completed",
  "adv_cash_05": "Asset Transfer",
  "adv_cash_06": "Deposit More",
  "adv_cash_07": "Purchase Failed",
  "adv_cash_08": "Order ID:",
  "adv_cash_09": "Close",
  "adv_cash_10": "Card verification can be requested by AdvCash after your purcahsed with your credit card. Your payment will be returned if verifucation is not completed within the 72hrs period after the card verification is requested. Card verification will be requested from AdvCash, please check the detailed info through the following link: <a href=\"https://wallet.advcash.com/pages/profile/verification\" target=\"_blank\">https://wallet.advcash.com/pages/profile/verification</a>",
  "adv_cash_11": "Purchase amount has exceeded the AdvCash purchase limits.",
  "adv_cash_13": "All related fees are collected by Advcash, fee amount dipalayed on BitForex.com is for reference only.",
  "adv_cash_14": "A  monthly, daily, by order limits are applied on AdvCash. If you are experiencing any purchasing issues during your payment, please contact the AdvCash support team.",
  "adv_cash_15": "Single Order Limit",
  "adv_cash_16": "\"Know Your Customer\" (KYC) and \"Anti - Money Laundering\" (AML) regulations require the Mercuryo team to learn more about clients' identities before letting clients use their services. Please check the detailed info through the following link:<br>  <a href=\"https://help.mercuryo.io/en/articles/4519473-mercuryo-widget-faq\" target=\"_blank\">https://help.mercuryo.io/en/articles/4519473-mercuryo-widget-faq</a>",
  "adv_cash_20": "All related fees are collected by Coinify, fee amount dipalayed on BitForex.com is for reference only.",
  "adv_cash_29": "Card verification can be requested by Banxa after your purcahsed with your credit card. Your payment will be returned if verifucation is not completed within the 72hrs period after the card verification is requested. Card verification will be requested from Banxa, please check the detailed info through the following link: <br><a href=\"https://support.banxa.com/en/support/solutions/folders/44001221675\" target=\"_blank\">https://support.banxa.com/en/support/solutions/folders/44001221675</a>",
  "adv_cash_30": "A monthly, daily, by order limits are applied on Banxa. If you are experiencing any purchasing issues during your payment, please contact the Banxa support team.",
  "adv_cash_31": "Banxa is a linked website or payment service platform (Third-Party Service) provided by Banxa ApS, a limited liability company incorporated in Denmark.For your convenience we have taken steps to make Banxa more accessible to you, and easier for you to use. However, it remains a Third-Party Service.BitForex will not be responsible for any infomational, data, suggestions, comlaints, or claim errors of Banxa.",
  "adv_cash_32": "All related fees are collected by Banxa, fee amount dipalayed on BitForex.com is for reference only.",
  "adv_cash_33": "The purchase of cryptocurrency through Moonpay requires Real-name authentication. The authentication is carried out by the Moonpay platform. Please refer to the specific authentication content.<br><a href=\"https://support.moonpay.com/hc/en-gb/articles/360011931677\" target=\"_blank\">https://support.moonpay.com/hc/en-gb/articles/360011931677</a>",
  "adv_cash_34": "Moonpay has a limit on the number of purchases per transaction, per day, and per month. If you encounter problems during the payment process, please consult Moonpay customer service for solutions.",
  "adv_cash_35": "MoonPay is a linked website or payment service platform (Third-Party Service) provided by MoonPay ApS, a limited liability company incorporated in Denmark.For your convenience we have taken steps to make MoonPay more accessible to you, and easier for you to use. However, it remains a Third-Party Service.BitForex will not be responsible for any infomational, data, suggestions, comlaints, or claim errors of MoonPay.",
  "ais_1": "AIS",
  "ais_10": "Lock-up Period",
  "ais_11": "No Lock-up",
  "ais_12": "IMPORTANT NOTICE",
  "ais_13": "1. Participation in Alternative Investment and Security Exchange (AIS) subscription requires completion of KYC certification;\n2. There is no handling fee for the transaction; \n3. Individual cap per round for each account is 10,000 AIS;\n4. The minimum order amount per round for a single account is 1,000 AIS.",
  "ais_14": "Enable Verification",
  "ais_16": "REFERENCE",
  "ais_17": "AIS Official Website",
  "ais_18": "https://ais-x.io",
  "ais_19": "AIS Whitepaper",
  "ais_2": "Alternative Investment and Security Exchange",
  "ais_20": "https://ais-x.io/AIS_WhitePaper20190731.pdf",
  "ais_21": "INTRODUCTION",
  "ais_22": "About the Exchange Coin",
  "ais_23": "The AIS token is a native coin of the cryptocurrency exchange AIS-X.\nThe AIS coin will be created by Tomochain and will take advantage of the superior technology provided by Tomochain.\n\n【 Insert AIS_image01.png 】\n\nAs the exchange token, AIS has the following features:\n1. Discount of transaction, withdrawal, and listing fee\n2. Key cryptocurrency\n3. Voting rights for new ICOs",
  "ais_26": "About the Exchange",
  "ais_27": "By having AIS in the account on the exchange, the AIS holder will receive Triple Bonus derived from the various business eneterprise invested by the AIS Project. These enterprises will be comprised of :\n1. Cryptocurrency exchange\n2. Cryptocurrency mining enterprise\n3. Resource mining enterprise",
  "ais_28": "CORE FEATURES",
  "ais_29": "Bank Affiliation",
  "ais_3": "AIS-X\" the Mongolian based next-generation cryptocurrency exchange",
  "ais_30": "By affiliating with a bank in Mongolia, account activities will become possible with the bank and the cryptocurrency exchange AIS-X. \n\n【 Insert AIS_image02.png 】\n\n● Registered users of AIS-X will be able to set up a bank account with the affiliate bank\n● Purchasing of cryptocurrency（※１） using legal currency on the AIS-X exchange\n● Exchanging cryptocurrency（ ※１） retained in AIS-X exchange account to legal currency (MNT, USD)\n● Issuing a new debit card （※２） from affiliate bank\n （※１）only major currencies such as BTC and ETH\n （※２）not from multiple banks but from a single affiliated bank.",
  "ais_31": "Resource Mining",
  "ais_32": "Together with the NI Bank, the AIS Project plans to establish a new resource mining project in Mongolia with the aid of expert international mining company. \nThe project will excavate gold,bronze, and coal but will specialize in mining rare metals and rare earth. The amount of rare metals mined like Molybdenum, Nickle, and Tungsten is top ranked in the world.",
  "ais_33": "2019/08/15 21:00 (GMT+8)",
  "ais_34": "2019/08/16 9:00 (GMT+8)",
  "ais_35": "AIS Token Sale and Economics",
  "ais_4": "ROUND 1",
  "ais_44": "Token Sale Start Time",
  "ais_45": "2019/08/15 21:00 - 2019/08/16 9:00 (GMT+8)",
  "ais_46": "Token Sale Token Price",
  "ais_47": "0.1 USD",
  "ais_48": "Turbo Allocation",
  "ais_49": "5,000,000 AIS",
  "ais_5": "Subscription Time： 2019/08/15 21:00 - 2019/08/16 9:00 (GMT+8)",
  "ais_50": "Bonus",
  "ais_51": "NONE",
  "ais_52": "Total Token Supply",
  "ais_53": "2,300,000,000 AIS",
  "ais_56": "Token Sale Vesting Period",
  "ais_57": "NONE",
  "ais_58": "Individual Cap",
  "ais_59": "10,000 AIS",
  "ais_6": "Allocation: 5,000,000 AIS",
  "ais_60": "The Minimum Order Amount",
  "ais_61": "1,000 AIS",
  "ais_62": "Private Sale Token Price",
  "ais_63": "0.2",
  "ais_64": "Private Sale Allocation",
  "ais_65": "50% of Total Token Supply",
  "ais_66": "Private Sale Vesting Period",
  "ais_67": "30 days after AIS trading starts on AIS-X exchange",
  "ais_68": "Token Type",
  "ais_69": "TRC-20",
  "ais_7": "Price: 0.1 USD",
  "ais_70": "TOKEN ALLOCATION",
  "ais_71": "Public Sale 50%\nFoundation 20%\nTeam 15%\nAdvisory 10%\nBounty 5%",
  "ais_72": "AIS TEAM",
  "ais_8": "Trading Pair",
  "ais_9": "AIS/BTC",
  "alt_academy": "Academy",
  "alt_airdrop": "Crypto airdrop",
  "alt_apk_apple": "Download on the App Store",
  "alt_apk_down": "Download APK Android",
  "alt_app": "App",
  "alt_assess_nft": "Assess the Value of an NFT",
  "alt_bear": "Bear Market",
  "alt_bictory": "Bictory Finance",
  "alt_billion": "Why Billion-Dollar Brands are Joining NFTs",
  "alt_bit_loans": "Bitforex Loans",
  "alt_bounty": "Bug Bounty & White Hat",
  "alt_buycointitle": "Buy Cryptocurrency Online",
  "alt_connect": "Professional Connectivity",
  "alt_contribute": "How to contribute to Web3 without writing any code",
  "alt_cross": "cross-border settlement of crypto",
  "alt_crypto": "The future of cryptocurrency",
  "alt_dao": "How to Start a DAO",
  "alt_easy": "EasySwap",
  "alt_ethere": "Ethereum Merge",
  "alt_fifa": "FIFA World Cup Qatar 2022",
  "alt_fifa_qatar": "FIFA World Cup Qatar 2022",
  "alt_galxe": "Galxe",
  "alt_governance": "Governance Tokens",
  "alt_gplay": "Get it on Google play",
  "alt_help": "Help center",
  "alt_inflation": "Inflation",
  "alt_iota": "IOTA",
  "alt_korea": "Crypto in Korea",
  "alt_latin": "Latin America",
  "alt_latin_america": "Latin America",
  "alt_loans": "Crypto Loans",
  "alt_loanstitle": "Cryptocurrency Loan",
  "alt_lunc": "What is the LUNC Tax Burn Proposal",
  "alt_meta": "Metaverse",
  "alt_mobile": "Mobile web",
  "alt_nft": "Common Zero (CC0) NFTs",
  "alt_nfts": "NFTs",
  "alt_nft_future": "Future of NFTs",
  "alt_qrcode": "QR code",
  "alt_regula": "Crypto regulations",
  "alt_rucry": "Russia Crypto",
  "alt_scan": "Scan to download for iOS or Android",
  "alt_secure": "Top Secure Trading Platform",
  "alt_semi": "Semi-Fungible Tokens",
  "alt_shimmer": "Shimmer Network",
  "alt_soccer": "Soccer Fan Tokens",
  "alt_socia": "socialFi",
  "alt_socios": "Socios and the 2022 Soccer World Cup",
  "alt_socios_cup": "Socios and the 2022 Soccer World Cup",
  "alt_soul": "Soulbound Tokens",
  "alt_soulbound": "Soulbound Tokens",
  "alt_stable": "Stablecoins",
  "alt_web3": "Web3 to world cup",
  "alt_web3_world": "Web3 World",
  "alt_zksync": "ZKSync",
  "api_1": "API Management",
  "api_10": "Permissions",
  "api_11": "Trading",
  "api_12": "Create Date",
  "api_13": "Expiration Date",
  "api_14": "Action",
  "api_15": "View",
  "api_16": "Edit",
  "api_17": "Delete",
  "api_18": "No Data",
  "api_2": "Create API Key",
  "api_20": "Create API",
  "api_21": "Edit API",
  "api_22": "View API",
  "api_23": "Delete API",
  "api_24": "Each user can create 10 API keys maximum.",
  "api_25": "Please enter a name",
  "api_26": "Bind IP",
  "api_27": "Please bind your API Key with your IP for security purposes.",
  "api_28": "Each API Key can bind 4 IP addresses maximum.",
  "api_29": "Please enter the correct IP address.",
  "api_3": "View API documentation",
  "api_30": "Unbound API Key is only valid for 90 days.",
  "api_31": "Your API Key will be expired, if you have not bind any IP. Confirm to unbind current IP?",
  "api_32": "Confirm",
  "api_33": "Cancel",
  "api_34": "Back",
  "api_35": "QR code",
  "api_36": "Created successfully",
  "api_37": "Modified Successfully",
  "api_38": "Confirm delete the API named {name}?",
  "api_39": "Deleted",
  "api_4": "My API",
  "api_40": "API name can only contain 20 characters.",
  "api_41": "Tips",
  "api_42": "BitForex provides you with a powerful API, through which you can enjoy services such as Market Query, Automatic Trading, etc.",
  "api_43": "You can create 10 API Keys at most.API changes will take effect within 5 minutes.",
  "api_44": "Optional binding IP address to restrict this API Key,each API Key can bind 4 IP addresses at most. If there is only one IP address, you can enter it directly. If there are more than one IP address, you should separate them with halfwidth comma (e.g. 207.35.46.22,201.12.456.120).",
  "api_45": "Validity Period",
  "api_5": "Name",
  "api_6": "Status",
  "api_7": "Normal",
  "api_8": "Close to Expiration Date",
  "api_9": "Expired",
  "asset_1": "My Asset",
  "asset_10": "Coin/Token",
  "asset_11": "Total",
  "asset_12": "Available",
  "asset_13": "Frozen",
  "asset_14": "Action",
  "asset_15": "Deposit",
  "asset_16": "Withdraw",
  "asset_17": "Trade",
  "asset_18": "Bills",
  "asset_19": "Transfer",
  "asset_2": "Estimated Value",
  "asset_20": "Locked Token Included",
  "asset_21": "No Record",
  "asset_22": "Bonus included",
  "asset_23": "UID Copied",
  "asset_24": "My Favorites",
  "asset_25": "DeFi Account",
  "asset_26": "Deposit details",
  "asset_3": "Wallet",
  "asset_4": "Trading Account",
  "asset_5": "Spot Account",
  "asset_6": "Perpetual Account",
  "asset_7": "Purchase Crypto with Credit Card",
  "asset_8": "Search",
  "asset_9": "Hide 0 Balance",
  "auth_36": "Institutional Account Verification",
  "auth_37": "Country of registration",
  "auth_38": "Company name",
  "auth_39": "Company registration number",
  "auth_40": "Date of incorporation",
  "auth_41": "Please select the date of incorporation",
  "auth_42": "Registration and operation address",
  "auth_43": "Upload Files",
  "auth_44": "Certificate of Incorporation",
  "auth_45": "Shareholder Structure Chart",
  "auth_46": "Company Memorandum",
  "auth_47": "Certificate of Incumbency",
  "auth_48": "Account Opening Authorization Letter",
  "auth_49": "ID card/Passport/Driver's license of account controller",
  "auth_50": "Reasons of Rejection:",
  "auth_51": "Uploading file...",
  "auth_error2_1": "Company information incorrect: Company name",
  "auth_error2_10": "File Unrecognizable: Account Opening Authorization Letter",
  "auth_error2_11": "File Unrecognizable: ID card/Passport/Driver's license of account controller",
  "auth_error2_2": "Company information incorrect: Country of registration",
  "auth_error2_3": "Company information incorrect: Company registration number",
  "auth_error2_4": "Company information incorrect: Date of incorporation",
  "auth_error2_5": "Company information incorrect: Registration and operation address",
  "auth_error2_6": "File Unrecognizable: Certificate of Incorporation",
  "auth_error2_7": "File Unrecognizable: Shareholder Structure Chart",
  "auth_error2_8": "File Unrecognizable: Company Memorandum",
  "auth_error2_9": "File Unrecognizable: Certificate of Incumbency",
  "auth_error_1": "Photo of the Identification cannot be verified. Please submit a clear image",
  "auth_error_10": "Please make sure the Identification is your own",
  "auth_error_11": "Photoshop detected for the self portrait",
  "auth_error_12": "Photoshop detected on the Identification",
  "auth_error_13": "KYC Identification has been used for another account.",
  "auth_error_14": "KYC Identification has been used for another account.",
  "auth_error_2": "Self portrait cannot be verified. Please submit a clear image",
  "auth_error_3": "Identification is not included in the self portrait",
  "auth_error_4": "Today's date or signature is not included in the self portrait",
  "auth_error_5": "Signature cannot be verified on the self portrait",
  "auth_error_6": "Personal information error: Full name",
  "auth_error_7": "Personal information error: Country/Region",
  "auth_error_8": "Personal information error: ID expiration date",
  "auth_error_9": "Personal information error: ID number",
  "auth_上传照片": "Upload photos",
  "auth_名字": "First Name",
  "auth_国家或地区": "Country or Region",
  "auth_图片大小不得超过4M": "Image size no more than 4M",
  "auth_姓氏": "Last Name",
  "auth_护照": "Passport",
  "auth_护照-正面": "Passport-Front",
  "auth_护照号码": "Passport Number",
  "auth_暂未认证": "Not yet authenticated",
  "auth_有截止日期": "Expired On",
  "auth_有效期至": "Expires",
  "auth_正在审核": "Reviewing",
  "auth_温馨提示": "Tips",
  "auth_照片支持jpg/jpeg/png格式。": "The photo supports jpg/jpeg/png format.",
  "auth_脸部清晰可见；证件信息清晰可见；便条信息清晰可见。": "The portrait photo, ID card and note information should be clear and visible.",
  "auth_自拍": "Self Portrait",
  "auth_要求：": "Requirements",
  "auth_认证": "Authentication",
  "auth_认证信息提交后，预计1-2天处理时间，请耐心等待。": "It will take 1-2 days processing time after the certification information is submitted. Please be patient.",
  "auth_认证失败": "Failed",
  "auth_认证通过": "Passed",
  "auth_证件格式错误": "Spaces and special characters (~!@#$%^*) are not allowed. Please use only a-z, A-Z, and 0-9.",
  "auth_请上传手持证件和便条的自拍照片，手写便条内容包括BitForex、当天日期及个人签名。": "Please upload a self-portrait photo of your hand-held ID card and written notes, which should include “BitForex”, date of the day and signature.",
  "auth_请上传照片": "Please upload photos",
  "auth_请确保照片完整清晰，大小不超过4M，证件处于有效期内。": "Please make sure the photo is complete and clear, the size is no more than 4M, and the certificate is in valid period.",
  "auth_请确认证件信息真实无误，照片真实无任何修改，清晰可见避免反光。": "Please confirm the document information is true and correct, the photo is true without any modification, and it is clearly visible without reflections.",
  "auth_请选择有效截止日期": "Please select validity date",
  "auth_身份认证": "Identity Authentication",
  "auth_身份证": "ID Card",
  "auth_身份证-反面": "ID Card-Back",
  "auth_身份证-正面": "ID Card-Front",
  "auth_身份证号码": "ID Number",
  "auth_重新认证": "Re-authentication",
  "auth_长期有效": "Long Term Validity",
  "auth_驾驶证": "Driver license",
  "auth_驾驶证-反面": "Driver's license-Back",
  "auth_驾驶证-正面": "Driver's license-Front",
  "auth_驾驶证号码": "Driver's license number",
  "await_address": "Address generating , please wait...",
  "BF Token": "BF Token",
  "bftoken_5‱ ~ 8‱ (初始释放8‱)": "5‱ ~ 8‱ (Initial releasing 8‱)",
  "bftoken_BF冻结数量": "Blocked volume",
  "bftoken_BF可用数量": "Available volume",
  "bftoken_BF可用账户": "Available BF account",
  "bftoken_BF总量": "Total BF amount",
  "bftoken_中标数量": "successful amount",
  "bftoken_交易币对": "Transaction currency exchange",
  "bftoken_交易手续费": "Trading fee",
  "bftoken_交易挖矿": "Transaction mining",
  "bftoken_交易挖矿收益": "Transaction mining income",
  "bftoken_交易数量": "Transaction amount",
  "bftoken_交易方向": "Transaction direction",
  "bftoken_交易日期": "Transaction date",
  "bftoken_交易时间": "Transaction time",
  "bftoken_全部划转": "Transfer all",
  "bftoken_冻结数量": "Frozen volume",
  "bftoken_划转": "Transfer",
  "bftoken_划转数量": "Transfer volume",
  "bftoken_初始释放": "Initial releasing",
  "bftoken_剩余持仓量": "Remain volume",
  "bftoken_原始锁仓总量": "原始锁仓总量",
  "bftoken_受邀人UID": "Invitee UID",
  "bftoken_受邀人挖矿累计": "Accumulated mining of invitee",
  "bftoken_可持仓数量": "Available holding volume",
  "bftoken_可用数量": "Available BF volume",
  "bftoken_奖励持仓量": "Awarding volume",
  "bftoken_已中标": "Winning bid",
  "bftoken_已投标": "Bidding",
  "bftoken_已撤消": "Cancelled",
  "bftoken_已结算": "Settled",
  "bftoken_已释放": "Released",
  "bftoken_已释放持仓总量": "Total volume released",
  "bftoken_已释放数量": "Released volume",
  "bftoken_币种": "token",
  "bftoken_待结算": "Wait to settle",
  "bftoken_待释放": "Wait to release",
  "bftoken_待释放数量": "Volume will be released",
  "bftoken_我的BF总量": "My total BF amount",
  "bftoken_我的BF总量 = 可用数量 + 冻结数量 + 锁仓数量": "My Total BF = Available BF Amount + Frozen BF Amount + Locked BF Amount",
  "bftoken_所得收益": "Income",
  "bftoken_持仓单号": "Holding number",
  "bftoken_持仓开始时间": "Open time",
  "bftoken_持仓挖矿": "Holding mining",
  "bftoken_持仓挖矿信息确认信息": "Confirm the holding mining information",
  "bftoken_持仓挖矿收益": "Holding mining income",
  "bftoken_持仓挖矿确认信息": "Confirm the holding mining information",
  "bftoken_持仓申请数量": "Applied  holding quantity",
  "bftoken_持仓账户": "Holding account",
  "bftoken_持仓释放数量": "Released volume",
  "bftoken_持仓锁仓数量": "Blocked volume",
  "bftoken_挖矿收益": "Mining income",
  "bftoken_挖矿数量": "Mining volume",
  "bftoken_挖矿日期": "Mining date",
  "bftoken_挖矿时间": "Mining time",
  "bftoken_挖矿类型": "Mining type",
  "bftoken_操作": "Operate",
  "bftoken_收益明细": "Income detail",
  "bftoken_数量": "amount",
  "bftoken_时间": "Time",
  "bftoken_暂无记录": "No record",
  "bftoken_最多划转": "Maximum transfer",
  "bftoken_最小下单量": "Minimum order volume",
  "bftoken_最少下单量": "Minimum order volume",
  "bftoken_未中标": "Not winning bid",
  "bftoken_查看": "查看",
  "bftoken_查看持仓挖矿收益": "Check the holing mining income",
  "bftoken_查看详情": "Check the details",
  "bftoken_每日释放数量": "Daily release volume",
  "bftoken_每日释放能量": "Daily releasig energy",
  "bftoken_流通账户": "Current account",
  "bftoken_状态": "status",
  "bftoken_申请成功，已生效": "Apply successfully and come into effect",
  "bftoken_申请持仓量": "Application volume",
  "bftoken_确定": "Confirm",
  "bftoken_确认": "Confirm",
  "bftoken_类型": "Type",
  "bftoken_结算日期": "Date of settlement",
  "bftoken_结算状态": "Condition of settlement",
  "bftoken_被邀人UID": "Invitee UID",
  "bftoken_认购数量": "subscribe amount",
  "bftoken_认购记录": "subscribe history",
  "bftoken_详情": "Details",
  "bftoken_请输入划转数量": "Enter the transfer volume",
  "bftoken_请输入持仓挖矿数量": "Please enter the volume",
  "bftoken_请输入持仓数量": "Enter the holding volume",
  "bftoken_贡献总返佣": "Total contribution commission discount",
  "bftoken_返佣": "Commission discount",
  "bftoken_返佣详情": "Details of commission discount",
  "bftoken_邀请人挖矿累计": "Accumulated mining of inviter",
  "bftoken_邀请挖矿": "Invitation mining",
  "bftoken_邀请挖矿收益": "Invitation mining income",
  "bftoken_释放数量": "Release volume",
  "bftoken_释放日期": "Releasing date",
  "bftoken_释放状态": "Releasing condition",
  "bftoken_释放能量": "Releasing energy",
  "bftoken_释放详情": "Releasing details",
  "bftoken_释放速率": "Releasing rate",
  "bftoken_释放量": "Releasing volume",
  "bftoken_锁仓总量": "Total blocking volume",
  "bftoken_锁仓数量": "Blocked volume",
  "bftoken_锁仓生效日期": "Availability date of blocking",
  "bftoken_锁仓释放记录": "Blocked release record",
  "bf_1": "BitForex Token (BF), is a cryptocurrency created by BitForex.",
  "bf_10": "Burned Supply:",
  "bf_11": "4,136,544,149 BF",
  "bf_12": "Unsold Tokens：",
  "bf_13": "1,614,923,818 BF",
  "bf_14": "Market Price",
  "bf_15": "{Market Price}USD",
  "bf_16": "Marketcap",
  "bf_17": "{Market Price*circulating supply}",
  "bf_18": "Allocation",
  "bf_19": "50% Token Sale",
  "bf_2": "Join Us",
  "bf_20": "15% Team Management",
  "bf_21": "20% Reserve Fund",
  "bf_22": "15% Bounty and Airdrop",
  "bf_23": "Budget & Spending",
  "bf_24": "30% Market Management",
  "bf_25": "30% BitForex Vip Service",
  "bf_26": "25% Marketing & Awareness",
  "bf_27": "15% Reserve Fund",
  "bf_28": "Membership Privileges",
  "bf_29": "For BF Holders Only",
  "bf_3": "Token Profile",
  "bf_30": "Exclusive Benefit",
  "bf_31": "Trading bonus and other awesome events ",
  "bf_32": "BF Eco-System",
  "bf_33": "BF can be used  in upcoming features like BitForex Insight and entertainments",
  "bf_34": "Global Summit VIP Invitation",
  "bf_35": "Participate in BitForex global conference and KOL meeting",
  "bf_36": "IEO Discount",
  "bf_37": "Invest IEO with discounted rate",
  "bf_38": "Airdrop",
  "bf_39": "Coin/Token airdrop to premium BF holders",
  "bf_4": "Symbol：",
  "bf_40": "Others",
  "bf_41": "Other customized services for BF menbers",
  "bf_42": "The World Leading One Stop Digital Asset Service Platform",
  "bf_43": "180+",
  "bf_44": "Countries Supported",
  "bf_45": "300+",
  "bf_46": "Trading Pairs",
  "bf_47": "3,500,000+",
  "bf_48": "Users Served",
  "bf_49": "Start Your Brand New Digital Life Now",
  "bf_5": "BF",
  "bf_50": "Buy BF",
  "bf_6": "Total Supply:",
  "bf_7": "10,000,000,000 BF",
  "bf_8": "Circulating Supply:",
  "bf_9": "4,248,532,033 BF",
  "bill_0": "All",
  "bill_1": "Deposit",
  "bill_10": "Subscription Release",
  "bill_11": "BF Subscription",
  "bill_12": "Holding BF Release",
  "bill_13": "Trade Mining",
  "bill_14": "Collateral",
  "bill_15": "Loan",
  "bill_16": "Return",
  "bill_17": "Redeem",
  "bill_18": "Within 7 days",
  "bill_19": "Within 30 days",
  "bill_2": "Withdraw",
  "bill_20": "Within 90 days",
  "bill_21": "Time",
  "bill_22": "Token/Coin",
  "bill_23": "Type",
  "bill_24": "Before Transaction",
  "bill_25": "Amount",
  "bill_26": "Balance",
  "bill_27": "Bills",
  "bill_28": "Spot Bills",
  "bill_29": "Filter",
  "bill_3": "Buy",
  "bill_30": "In order to improve system performance, we will periodically clear the historical data of some users 3 months ago according to the memory usage.",
  "bill_31": "Time",
  "bill_32": "Type",
  "bill_33": "Cont.",
  "bill_34": "Amount",
  "bill_35": "Limit buy",
  "bill_36": "Limit sell",
  "bill_37": "Forced liquidation",
  "bill_38": "Taker fees",
  "bill_39": "Maker fees",
  "bill_4": "Sell",
  "bill_40": "Funding",
  "bill_41": "Transfer in",
  "bill_42": "Transfer out",
  "bill_43": "Perpetual Bills",
  "bill_44": "Forced liquidated Long Position",
  "bill_45": "Forced liquidated Short Position",
  "bill_46": "Transfer from Spot Account",
  "bill_47": "Transfer to Spot Account",
  "bill_48": "Transfer from Perpetual Account",
  "bill_49": "Transfer to Perpetual Account",
  "bill_5": "Fee",
  "bill_50": "Transfer out to MT5 account",
  "bill_51": "Transfer from MT5 account",
  "bill_52": "CApp >> Wallet",
  "bill_53": "Wallet >> CApp",
  "bill_54": "MT5 Reward",
  "bill_55": "MT5 Bonus",
  "bill_56": "Airdrop",
  "bill_57": "Bonus Expired/Violation",
  "bill_58": "Perpetual Bonus",
  "bill_59": "Perpetual Reward",
  "bill_6": "Referral bonus",
  "bill_60": "Transfer",
  "bill_61": "Exchange Compensation",
  "bill_62": "Internal transfer",
  "bill_63": "Wrong Deposit Deduction",
  "bill_64": "Bonus Expired/Violation",
  "bill_65": "A wrong fund deposit was detected on your account, wrong deposit has been deducted",
  "bill_66": "DeFi Bills",
  "bill_67": "From DeFi Account",
  "bill_68": "To DeFi Account",
  "bill_7": "Rewards",
  "bill_8": "Transfer from Wallet",
  "bill_9": "Transfer to Wallet",
  "borrowr_1": "Users can borrow GRAM with USDT as the collateral, and trade using the GRAM/USDT market;",
  "borrowr_10": "BitForex reserves the right to cancel or amend the event or event rules at our sole discretion.",
  "borrowr_2": "Loan Ratio: 1 GRAM = 10 USDT;",
  "borrowr_3": "Borrow GRAM (Starting time: 2019/07/10 at 12:00 GMT+8; Closing time: 1 day prior to TON mainnet launch, at 24:00 GMT+8);",
  "borrowr_4": "Redeem USDT (Opening time: 2019/7/15 at 12:00 GMT+8; Closing time: the 3rd day after TON mainnet opens at 24:00 GMT+8. We will announce the closing time after the mainnet releases the transfers);",
  "borrowr_5": "Failure to return/payback the GRAM borrowed will be considered as an abstention and the user will lose the right to redeem the USDT, GRAM borrowed will be considered as associated user's asset;",
  "borrowr_6": "Maximum amount to borrow is 10,000 GRAM per user; Minimum amount to borrow is 1 GRAM per user;",
  "borrowr_7": "Forced liquidation risk reminder, when the GRAM borrowed value >= total asset value+ USDT collateral *80%, it will be considered as forced liquidation, the platform will take over the corresponding amount of USDT collateral;",
  "borrowr_8": "0 conversion fee during the event;",
  "borrowr_9": "GRAM open price is 0.5 USDT;",
  "borrow_1": "Celebrating Telegram Open Network (GRAM)'s Listing on BitForex, 0 Conversion Fee Offering Event",
  "borrow_10": "BitForex reserves the right to cancel or amend the event or event rules at our sole discretion.",
  "borrow_11": "Please enter the desired {coin} amount to borrow",
  "borrow_12": "Amount",
  "borrow_13": "Amount available",
  "borrow_14": "Insufficient Fund",
  "borrow_15": "Please enter the desired amount to borrow",
  "borrow_16": "Return {coin}",
  "borrow_17": "Returen deadline: 24:00 (GMT+8) of the 3rd day after the mainnet opens",
  "borrow_18": "{coin} return balance",
  "borrow_19": "Please enter desired {coin} return amount",
  "borrow_2": "GRAM Futures Physical Delivery",
  "borrow_20": "Available",
  "borrow_21": "Login Now",
  "borrow_22": "All",
  "borrow_23": "Borrow",
  "borrow_24": "Return",
  "borrow_25": "Collateral",
  "borrow_26": "Redeem",
  "borrow_27": "Don't show this page again (You can access the loaning page from the main menu)",
  "borrow_28": "Borrow {coin} now",
  "borrow_29": "Maxium amount for borrowing:{amount} {coin}",
  "borrow_3": "Time",
  "borrow_30": "Event participants have reached the limitation number",
  "borrow_31": "Loaning {amount} {coin} with {price} {currency} ?",
  "borrow_32": "Redeem open time: 2019/07/15 12:00 (GMT+8)",
  "borrow_33": "Redeem closing time: 24:00 (GMT+8) of the 3rd day after the mainnet opens",
  "borrow_34": "Confirm",
  "borrow_35": "Cancel",
  "borrow_36": "Trade",
  "borrow_37": "Borrowing available amount have reached the limitation",
  "borrow_38": "Return is not availble now",
  "borrow_39": "User's borrowing limitation is reached",
  "borrow_4": "2019/07/10 12:00 (GMT+8) - 24:00 GMT+8 of the 3rd day after the mainnet opens",
  "borrow_40": "Event participants have reached the limitation number",
  "borrow_41": "Borrowing succeeded ",
  "borrow_42": "Return succeeded",
  "borrow_43": "Forced liquidation occurred",
  "borrow_44": "Comfirm to return {amount} {coin}?",
  "borrow_45": "Return comfirmed",
  "borrow_46": "Borrow and return is not available due to forced liquidation",
  "borrow_47": "Please enter return amount ",
  "borrow_48": "Insufficient {coin} fund",
  "borrow_49": "Returning amount is larger than required returning amount",
  "borrow_5": "Rules",
  "borrow_50": "GRAM FUTURE",
  "borrow_51": "If you have {coin} and wish to sell on BitForex, please contact our Business Development Team by email:",
  "borrow_52": "What is Futures Physical Delivery?",
  "borrow_53": "Partnership",
  "borrow_55": "https://support.bitforex.com/hc/en-us/articles/360030446552",
  "borrow_56": "Minimum amount {amount}",
  "borrow_6": "My Assets",
  "borrow_7": "Borrowed {coin} Amount",
  "borrow_8": "My {currency} Amount ",
  "borrow_9": "Borrow {coin}",
  "btc_prediction_1": "When Will Bitcoin Hit $20,000?",
  "btc_prediction_10": "Participation rules：",
  "btc_prediction_11": "1.Log in to your BitForex account to participate in the “When Will Bitcoin Hit $20,000?” prediction game.",
  "btc_prediction_12": "*Please note the account you logged in will be used to distribute your potential winnings.",
  "btc_prediction_13": "*Each BitForex UID can ONLY be participated once in this event. Please make sure to use your own UID accurately.",
  "btc_prediction_14": "2. The time slots you can enter are hour-long time periods (e.g. December 2  13:00 - 14:00 GMT+8). The earliest time slot you can choose is 24 hours from when you participate.",
  "btc_prediction_15": "3.  You can ONLY enter up to three time slots. You are able to update the time slots any time before this event ends. If you update your time slots, your previous slots are deemed invalid.",
  "btc_prediction_16": "4. The price of a  BTC/USDT trading pair on BitForex (shown in the chart below) will be used  to track and determine the BTC price movement.",
  "btc_prediction_17": "5. When the price of BTC/USDT on BitForex hits $20,000 for the first time (“Event Trigger”), the date and the hour will be recorded as the winning time slot.",
  "btc_prediction_18": "6. All users who predict correctly will be ranked based on the time they entered the time slots — which means you should start  predicting right away! The reward structures are as follows:",
  "btc_prediction_19": "1st place: 0.3 BTC bonus",
  "btc_prediction_2": "Make a Prediction and Get a Chance to Win 1 BTC Airdrop Bonus!",
  "btc_prediction_20": "2nd place: 0.2 BTC bonus",
  "btc_prediction_21": "3rd place: 0.1BTC bonus",
  "btc_prediction_22": "All the other users who entered the winning time slot will share 0.4 BTC airdrop bonus evenly.",
  "btc_prediction_23": "7. The prizes will be distributed to the BitForex accounts of the winning participants within five business days after the completion of the event.",
  "btc_prediction_24": "8. The BTC bonus can be used in BitForex any perpetual contract trading market, the profit part can be withdrawn.",
  "btc_prediction_25": "9. The event will end when either of the following happens first : 1) The date Bitcoin hits $20,000; 2) 0:00 January 10, 2021 (GMT+8)",
  "btc_prediction_26": "Disclaimer：",
  "btc_prediction_27": "The information about this campaign is meant for informational purposes only. It shall in no way be conceived as any means of implication, suggestion or specification that BitForex provides any form of online gambling. BitForex in no way endorses or condones, promotes or encourages that users participate in online gambling. It is your responsibility to abide by applicable local laws and regulations in your region, jurisdiction or country.",
  "btc_prediction_28": "BitForex reserves the ability to disqualify any participants engaging in fraudulent behavior and the right to change the terms, eligibility criteria and payouts for all campaigns at any time and for any reason.",
  "btc_prediction_29": "*Users from the following countries or regions will not be able to participate in this event: Belarus, Democratic Republic of Congo,Cuba, Iraq, Iran, Mainland China, North Korea, Sudan, Syria, United States of America and its territories (American Samoa, Guam, the Northern Mariana Islands, Puerto Rico, and the U.S. Virgin Islands), Zimbabwe.",
  "btc_prediction_3": "Sign up on BitForex and participate - enter the time slots when you think Bitcoin will hit $20,000.",
  "btc_prediction_31": "First Prediction",
  "btc_prediction_32": "Second Prediction",
  "btc_prediction_33": "Third Prediction",
  "btc_prediction_34": "Please login your BitForex account",
  "btc_prediction_35": "Select a date",
  "btc_prediction_36": "Make Prediction",
  "btc_prediction_37": "The earliest time slot you can choose is 24 hours from when you participate.",
  "btc_prediction_38": "Get a Chance to Win 1 BTC Airdrop Bonus",
  "btc_prediction_39": "Make a prediction when you think Bitcoin will hit $20,000.",
  "btc_prediction_4": "Step 1:",
  "btc_prediction_40": "Start Now",
  "btc_prediction_41": "No, thanks",
  "btc_prediction_5": "Login or Signup First",
  "btc_prediction_6": "Provide your UID to receive the rewards.",
  "btc_prediction_7": "Step 2:",
  "btc_prediction_8": "Your predictions",
  "btc_prediction_9": "Place up to 3 predictions, the earliest time slot you can choose is 24 hours from when you participate.",
  "buyCoins_1": "Quick Buy",
  "buyCoins_10": "Smooth Experience",
  "buyCoins_11": "Crypto assets will be credited to your account quickly if payment is made successfully.",
  "buyCoins_12": "How to Buy Crypto with a Bank Card?",
  "buyCoins_13": "Fill in the correct payment information, then submit the order.\nSelect the cryptocurrency you want and fill in the order information.",
  "buyCoins_14": "Use fiat currency to complete order payment on the platform you chose.",
  "buyCoins_15": "Once payment is confirmed, the crypto will be automatically transferred to your BitForex account.",
  "buyCoins_16": "Coin/Token",
  "buyCoins_17": "Payment channels",
  "buyCoins_18": "By clicking \"{service}\" button, I agree to",
  "buyCoins_19": "Payment methods",
  "buyCoins_2": "The World's Leading One-Stop Digital Asset Service Platform",
  "buyCoins_20": "Pay",
  "buyCoins_3": "Trade at BitForex Invest in the Future",
  "buyCoins_5": "More Convenient and More Flexible",
  "buyCoins_6": "Flexible Payment Methods",
  "buyCoins_7": "Feel free to choose how you want to be paid.",
  "buyCoins_8": "Various Asset Options",
  "buyCoins_9": "Various crypto assets that you can buy directly.",
  "calculator_1": "{symbol} Perpetual Calculator",
  "calculator_10": "Account Balance",
  "calculator_11": "Notice",
  "calculator_12": "Fees are not included in the profit",
  "calculator_13": "Closing price calculation does not include trading fees and funding rate",
  "calculator_14": "Your current account balance, position value, position margin will affect your liquidation price calculation.",
  "calculator_15": "Result",
  "calculator_16": "Calculate",
  "calculator_17": "No liquidation risk",
  "calculator_18": "No closed position price can fulfill the profitability.",
  "calculator_2": "Profit",
  "calculator_3": "Avg. Exit Price",
  "calculator_4": "Liq. Price",
  "calculator_5": "Leverage",
  "calculator_6": "Entry Price",
  "calculator_7": "Amount",
  "calculator_8": "Initial Margin",
  "calculator_9": "Profitability",
  "capital_1": "BitForex Capital",
  "capital_10": "An excellent marketing team to drive value post-investment.",
  "capital_11": "Partners",
  "capital_12": "Red Rock Limited、Ecomi (OMI)、Klaytn、CoinGecko",
  "capital_13": "Contact Us",
  "capital_14": "invest@bitforex.com",
  "capital_2": "About Us",
  "capital_3": "BitForex Capital was founded in July 2019 as the investment division of BitForex. We focus on investing and incubating startups in blockchain, cryptocurrency management, quantitative trading, and the traditional internet. It is truly committed to the development and prosperity of the global economy. With team members from different countries, we work in South Korea, Russia, Europe, and other countries. We are one of the venture capital teams with the most international investment vision.",
  "capital_4": "Our Mission",
  "capital_5": "We want to find more potential premium projects in all industries and support them in early incubation and development. We want to help the global economy become more diverse and prosperous, and provide a solid foundation for the integration, efficiency, and transparency of the global economy.",
  "capital_6": "Our Advantages",
  "capital_7": "Strong financial and technical strength to support project incubation.",
  "capital_8": "Listing opportunities through internal promotion, easily accessible to the market.",
  "capital_9": "The professional project research team carefully selected before investment.",
  "capps_1": "CApp Account",
  "capps_2": "CApp Bills",
  "capps_3": "CApp Income",
  "capps_4": "CApp Payment",
  "capp_1": "CApp Town",
  "capp_100": "Project Name",
  "capp_101": "Payment No.",
  "capp_102": "Uncleared Payment",
  "capp_103": "Pay Now",
  "capp_104": "Time out",
  "capp_105": "In process",
  "capp_106": "Completed",
  "capp_107": "Failed",
  "capp_108": "Unfilled Orders",
  "capp_109": "Order History",
  "capp_11": "Total users",
  "capp_110": "Order #",
  "capp_111": "Payment",
  "capp_112": "Profit",
  "capp_113": "Transfer-in",
  "capp_114": "Transfer-out",
  "capp_115": "Operation",
  "capp_116": "CApp Orders",
  "capp_117": "Time",
  "capp_118": "Coin/Token",
  "capp_119": "Type",
  "capp_12": "24H active users",
  "capp_120": "Hide \"Canceled\"orders",
  "capp_121": "CApp Account",
  "capp_122": "CApp Bills",
  "capp_123": "CApp Income",
  "capp_124": "CApp Payment",
  "capp_126": "Project holder transfer-in",
  "capp_127": "Project holder transfer-out",
  "capp_129": "CApp Town",
  "capp_13": "Apply for CApp",
  "capp_130": "May crypto enrich your life",
  "capp_131": "Category",
  "capp_132": "Coin",
  "capp_133": "All",
  "capp_134": "CApp",
  "capp_135": "CApp  List",
  "capp_14": "Total listed",
  "capp_15": "Coming soon...",
  "capp_16": "API Doc",
  "capp_17": "HOT",
  "capp_18": "Coin Supported",
  "capp_19": "See All",
  "capp_2": "May crypto enriches your life",
  "capp_20": "A direct way to reach your target audience",
  "capp_21": "4 million global users from our crypto market are your future customers.",
  "capp_22": "A faster way to increase your global branding awareness",
  "capp_23": "BitForex supports 9 languages and serves users in over 180 countries and regions.",
  "capp_24": "A smarter way to manage your merchant account",
  "capp_25": "Merchant admin panel empowered by comprehensive management tools and industry-leading security",
  "capp_3": "An open platform based on the BitForex Exchange that gives crypto holders access to a wide spectrum of blockchain applications. CAPP stands for Crypto Application.",
  "capp_4": "Play now",
  "capp_41": "BitForex User Agreement",
  "capp_42": "You agree to allow BitForex to take your information listed below with {name} to provide services for you.",
  "capp_43": "Get your open information (open ID, etc.)",
  "capp_44": "Users will be redirected into {name} application. All actions taken by users on the {name} will be regulated by the {name} user agreement and and {name} will held responsible with all issue and matter occur during the use of {name}.",
  "capp_45": "Please finish the authorization process before {countdown}, it will be automatically cancelled when it is pass due.",
  "capp_46": "Confirm authorization",
  "capp_47": "Project you chose is no longer available, please select a different project.",
  "capp_48": "Project you chose is frozen, please select a different project.",
  "capp_49": "Your account is frozen, please contact the customer service team.",
  "capp_5": "Finance",
  "capp_50": "Authorization pass due, please return to finish the process.",
  "capp_51": "Confirm the authorization again",
  "capp_6": "Entertainment",
  "capp_61": "Payment Confirmation",
  "capp_62": "UID",
  "capp_63": "Payment NO.",
  "capp_64": "Project account bills",
  "capp_65": "Payment Source",
  "capp_66": "Payment Amount",
  "capp_67": "CApp Account",
  "capp_68": "Your balance",
  "capp_69": "For your asset safety please enter your BitForex trading password",
  "capp_7": "Information",
  "capp_70": "Please set your trading password.",
  "capp_71": "Set trading password",
  "capp_72": "Reset trading password",
  "capp_73": "Payment confirmation",
  "capp_74": "Invalid balance, transfer funds",
  "capp_75": "Transfer",
  "capp_76": "Please finish the payment process before <span style='color:#EB6A5A'>{countdown}</span>, it will be automatically cancelled when it is pass due.",
  "capp_77": "Please check your payment details. After the payment is procesed, BitForex is no longer responsible for any potential asset damage according to the BitForex CApp user agreement. Please contact the third party project when damages occurs.",
  "capp_8": "Join now",
  "capp_81": "Please set your trading password.",
  "capp_82": "Please enter the correct trading password",
  "capp_83": "Invalid balance",
  "capp_84": "Payment time out, please return to finish the payment process.",
  "capp_85": "Payment Completed",
  "capp_9": "Visit website",
  "cap_1": "CAPP（Crypto Application Token)",
  "cap_10": "2020/04/21 12:00 (GMT+8)",
  "cap_11": "Subscription Amount",
  "cap_12": "1,000,000,000 CAPP = 200,000 USDT",
  "cap_13": "Price",
  "cap_14": "0.0002 USDT",
  "cap_15": "Subscription Trading Pair",
  "cap_16": "CAPP/BTC",
  "cap_17": "Lock-up Period",
  "cap_18": "No Lock-up",
  "cap_19": "IMPORTANT NOTICE",
  "cap_2": "May Crypto Enrich Your Life!",
  "cap_20": "1. The minimum order amount for a single account is 10 USDT = 5,000 CAPP<br>2. The maximum order amount for a single account is 500 USDT = 250,000 CAPP<br>3. There is no handling fee for the transaction<br>4. KYC is NOT required for the CAPP subscription.<br>5. There are many participating users. It is recommended to log in before 7:00 (GMT+8) and prepare in advance.",
  "cap_21": "2. The maximum order amount for a single account is 500 USDT = 2,500,000 CAPP",
  "cap_22": "3. There is no handling fee for the transaction",
  "cap_23": "4. KYC is required to participate in CAPP Turbo. To complete KYC, click here",
  "cap_24": "5. According to relevant regulations, users from the following countries/regions (according to KYC information) cannot participate in the subscription: Mainland China, Cuba, Iran, North Korea, Crimea, Sudan, Malaysia, Syria, Indonesia, Vietnam, Bangladesh, Bolivia, Ecuador, and Kyrgyzstan.",
  "cap_25": "REFERENCE",
  "cap_26": "CApp Town Website",
  "cap_27": "6. There are many participating users. It is recommended to log in before 7:00 (GMT+8) and prepare in advance.",
  "cap_28": "INTRODUCTION",
  "cap_29": "About CApp Town",
  "cap_3": "Subscription Timeline",
  "cap_30": "BitForex CApp Town is an open platform based on BitForex exchange system. It is a platform that quickly connects global businesses and crypto users. It allows cryptocurrency holders from BitForex to participate and access global blockchain applications. BitForex CApp Town (BitForex Crypto Application Town) as an intermediate platform, which provides an innovative way to connect millions of crypto holders on BitForex to various applications of blockchain and cryptocurrency. All of the users, developers and start-up businesses are all welcome to access BitForex CApp Town through the interface. It shares the global resources and attract users into their own resources, realize resource sharing idea.",
  "cap_32": "About CAPP",
  "cap_33": "Crypto Application Token (CAPP) is the global ecological rights certificate of the BitForex CApp Town. CAPP token holders can deeply participate in the future development of the BitForex CApp Town. CAPP Token will become an important part and also the cornerstone of the BitForex Capp Town.",
  "cap_34": "CAPP Token Sale",
  "cap_35": "Token Sale Start Time",
  "cap_36": "2020/4/20 12:00 - 2020/4/20 14:00 (GMT+8)",
  "cap_37": "Token Price",
  "cap_38": "0.0002 USDT",
  "cap_39": "Turbo Allocation",
  "cap_4": "Ready",
  "cap_40": "1,000,000,000 CAPP",
  "cap_41": "Bonus",
  "cap_42": "NONE",
  "cap_43": "Total Token Supply",
  "cap_44": "10,000,000,000 CAPP",
  "cap_45": "Initial Circulating Supply",
  "cap_46": "0",
  "cap_47": "Token Sale Vesting Period",
  "cap_48": "NONE",
  "cap_49": "The Maximum Order Amount",
  "cap_5": "Starting Time",
  "cap_50": "500 USDT = 2,500,000 CAPP",
  "cap_51": "The Minimum Order Amount",
  "cap_52": "10 USDT = 50,000 CAPP",
  "cap_53": "Private Sale Token Price",
  "cap_54": "NONE",
  "cap_55": "Token Type",
  "cap_56": "ERC-20",
  "cap_57": "TOKEN ALLOCATION",
  "cap_6": "2020/04/20 12:00 (GMT+8)",
  "cap_61": "Historical Highest Return",
  "cap_62": "Register Now!",
  "cap_63": "To Get The Latest Turbo Updates!",
  "cap_64": "CApp Town Platform Token",
  "cap_65": "According to relevant regulations, users from the following countries/regions (according to KYC information) cannot participate in the {token} subscription: {country}.",
  "cap_66": "According to the Turbo Rules and Regulations, only users from {country} are able to participate in {token} Turbo",
  "cap_67": "{token} participants are required to complete KYC, please complete your KYC in the account setting.",
  "cap_7": "Ending Time",
  "cap_8": "2020/04/20 14:00 (GMT+8)",
  "cap_9": "Open Trading",
  "cby_1": "CBY (CBerry)",
  "cby_10": "2020/05/19 15:00 (GMT+8)",
  "cby_11": "Subscription Amount",
  "cby_12": "100,000 CBY",
  "cby_13": "Price",
  "cby_14": "20% less than the market price on May 18th, 2021 at 09:30 (GMT+8)",
  "cby_15": "Subscription Trading Pair",
  "cby_16": "CBY/BTC",
  "cby_17": "Lock-up Period",
  "cby_18": "No Lock-up",
  "cby_19": "IMPORTANT NOTICE",
  "cby_2": "The First Content Sharing Platform on Binance Smart Chain",
  "cby_20": "1. The minimum order amount for a single account is 20 USDT",
  "cby_21": "2. The maximum order amount for a single account is 100 USDT",
  "cby_22": "3. There is no handling fee for the transaction",
  "cby_23": "4. KYC is NOT required for the CBY subscription.",
  "cby_24": "5. There are many participating users. It is recommended to log in before 7:00 (GMT+8)\r\nand prepare in advance.",
  "cby_25": "REFERENCE",
  "cby_26": "CBY Website",
  "cby_27": "https://cberry.net/",
  "cby_28": "INTRODUCTION",
  "cby_29": "About CBY",
  "cby_3": "Subscription Timeline",
  "cby_30": "CBerry incorporates a solid ecosystem that will deliver the best and most rewarding content creation reward platform on the Blockchain and has a rigid insulation against reward depreciation and long term operation difficulties that has plagued other such platforms. The CBerrry protocol was designed after studying the problems with other platforms and then examined by experienced stakeholders so that the best possible platform will be delivered.\r\nCBerry is a content sharing system on the Binance Smart Chain. Users are rewarded with CBY and CBR and can be tipped in BNB, CBY and CBR for quality contents shared. CBerry also integrates DeFiSwap and Loan infrastructure to create a solid content creation and reward ecosystem. CBerry has the sole goal of becoming the most rewarding and stable content creation reward system on the Blockchain.\r\nCBerry is a content sharing system on the Binance Smart Chain. Users are rewarded with CBY and CBR and can be tipped in BNB, CBY and CBR for quality contents shared. CBerry also integrates DeFiSwap and Loan infrastructure to create a solid content creation and reward ecosystem. CBerry has the sole goal of becoming the most rewarding and stable content creation reward system on the Blockchain.",
  "cby_34": "CBY Token Sale",
  "cby_35": "Token Sale Start Time",
  "cby_36": "2020/05/18 10:00 - 2020/05/19 12:00 (GMT+8)",
  "cby_37": "Token Price",
  "cby_38": "20% less than the market price on May 18th,2021 at 09:30 (GMT+8)",
  "cby_39": "Turbo Allocation",
  "cby_4": "Ready",
  "cby_40": "100,000 CBY",
  "cby_41": "Bonus",
  "cby_42": "NONE",
  "cby_43": "Total Token Supply",
  "cby_44": "5,000,000 CBY",
  "cby_45": "Initial Circulating Supply",
  "cby_46": "368,000 CBY",
  "cby_47": "Token Sale Vesting Period",
  "cby_48": "NONE",
  "cby_49": "The Maximum Order Amount",
  "cby_5": "Starting Time",
  "cby_50": "100 USDT",
  "cby_51": "The Minimum Order Amount",
  "cby_52": "20 USDT",
  "cby_53": "Private Sale Token Price",
  "cby_54": "NONE",
  "cby_55": "Token Type",
  "cby_56": "BSC",
  "cby_57": "TOKEN ALLOCATION",
  "cby_6": "2020/05/18 10:00 (GMT+8)",
  "cby_62": "马上注册！",
  "cby_63": "订阅最新资讯！",
  "cby_7": "Ending Time",
  "cby_8": "2020/05/19 12:00 (GMT+8)",
  "cby_9": "Open Trading",
  "christmas2_1": "Join BitForex Christmas Party!",
  "christmas2_10": "Step 3: Participate successfully! Please wait for the prize draw to share the gifts. (Each account can only participate once)",
  "christmas2_11": "The surprise gift box number {0} has been opened successfully, and the corresponding gift tokens will be released to your wallet account within five working days after the draw.",
  "christmas2_12": "You have successfully participated in the event",
  "christmas2_13": "Event Period:",
  "christmas2_14": "2020.12.24 15:00 - 2020.12.26 20:00 (GMT+8)",
  "christmas2_15": "2020.12.24 15:00 - 2020.12.26 20:00 (GMT+8)",
  "christmas2_16": "Join now",
  "christmas2_17": "Join",
  "christmas2_18": "Participated",
  "christmas2_19": "Drawing in progress",
  "christmas2_2": "Suprise Prizes to Win",
  "christmas2_20": "Succeed",
  "christmas2_21": "Event has ended",
  "christmas2_22": "Event has not started yet",
  "christmas2_23": "You have successfully participated in the event",
  "christmas2_24": "Event does not exist",
  "christmas2_25": "Event Rules",
  "christmas2_26": "The bonus will be released to the user's wallet account within 5 working days.",
  "christmas2_27": "Each user can only participate once",
  "christmas2_28": "Participation deadline: December 26, 2020 20:00:00 (GMT+8)",
  "christmas2_29": "The winning amount will be divided equally between the number of participants.",
  "christmas2_3": "Start Now",
  "christmas2_30": "Each surprise box contains tokens of varying value, with a total value of more than $100,000. Log in or register successfully to participate in the sharing of Christmas gifts.",
  "christmas2_31": "Prizes details (Each box will randomly correspond to a reward)：",
  "christmas2_32": "1. 10,000 ABBC <br>\r\n2. 800,000 ELAMA <br>\r\n3. 80,000 FUND<br>\r\n4. 50,000 BWXX <br>\r\n5. 7,500 MQL <br>\r\n6. 8,000,000 ETHBN <br>\r\n7. 50,000 CLBK <br>\r\n8. 1,900,000 XVX <br>\r\n9. 1,200 MAZ <br>\r\n10. 20,000 YOUC",
  "christmas2_33": "BitForex reserves the ability to disqualify any participants engaging in fraudulent behavior and the right to change the terms, eligibility criteria and payouts for all campaigns at any time and for any reason.",
  "christmas2_34": "No. {0} gift box",
  "christmas2_3_1": "No, thanks",
  "christmas2_4": "Christmas Surprise Gift Event",
  "christmas2_5": "Share $100,000 Christmas Surprise Gift Pack",
  "christmas2_6": "Event Countdown",
  "christmas2_7": "Please log in or register to participate in the event",
  "christmas2_8": "Step 1: Please log in or register to participate in the event",
  "christmas2_9": "Step 2: Click to select one of the surprise gift boxes on the right to participate in the sharing of bonuses",
  "coin_changeIn24h": "24h Change",
  "coin_contracts": "Contracts",
  "coin_float": "Change",
  "coin_highIn24h": "24h High",
  "coin_lastPrice": "Last Price",
  "coin_lowIn24h": "24h Low",
  "coin_market": "Market",
  "coin_more": "View more",
  "coin_name": "Name",
  "coin_pair": "Pair",
  "coin_pairs": "Pairs",
  "coin_price": "Price",
  "coin_searchAll": "Search",
  "coin_self": "Favorites",
  "coin_turnover24h": "24h Turnover",
  "coin_vol": "Volume",
  "coin_volIn24h": "24h Volume",
  "community_1": "Join BitForex Community",
  "community_2": "Get latest updates on BitForex",
  "community_3": "Official Channels",
  "community_4": "Join us and get the quickest updates!",
  "conc_10": "Congratulations!",
  "conc_11": "You have received bonus!",
  "conc_12": "My Asset",
  "conc_13": "Trade Now",
  "conc_14": "Read the bonus terms and conditions",
  "conc_15": "You can check it in your Perpetual Account now!",
  "conc_16": "You can check it in your Spot Account now!",
  "conc_20": "Spot Trading System Upgrade Notice",
  "conc_21": "The scheduled Spot Trading system maintenance at 09:30 on 12/04/2020 (GMT+8) is in progress. This upgrade will last about 30 minutes and Spot Trade will not be available during this time period.",
  "conc_22": "My Account",
  "conc_23": "Perpetual Contract Trading",
  "conc_24": "Back to Home",
  "conc_25": "More details...",
  "conc_26": "https://support.bitforex.com/hc/zh-tw/articles/360053244311",
  "conc_27": "Uncover Your Love With BitForex!",
  "congratulations": "Congratulations",
  "contactUs_1": "Contact Us",
  "contactUs_10": "E-mail",
  "contactUs_11": "Customer Support",
  "contactUs_12": "Report",
  "contactUs_13": "Feedback",
  "contactUs_14": "BitForex will never ask you to transfer money to any address for any reason, nor ask for your account password. Do not share your password with others. Please pay attention to fraud risks, and report any suspicious activity through our online customer service system or by submitting a request.",
  "contactUs_2": "Questions?",
  "contactUs_3": "Please first check:",
  "contactUs_4": "BitForex FAQ",
  "contactUs_5": "https://support.bitforex.com/hc/en-us/categories/360000607252",
  "contactUs_6": "VIEW SUPPORT DOCUMENTATION",
  "contactUs_7": "Still got questions?",
  "contactUs_8": "Please submit a request.",
  "contactUs_9": "Telegram",
  "contest_1": "Submit video now",
  "contest_10": "Upload to Youtube & Submit",
  "contest_11": "Step 3",
  "contest_12": "Share & Win",
  "contest_13": "Grand Prizes",
  "contest_14": "RUNNER UP",
  "contest_15": "CHAMPION",
  "contest_16": "THIRD PLACE",
  "contest_17": "Qualifying participants earn a <span class=\"orange\">30 USDT </span> reward!*",
  "contest_18": "Topic suggestions:",
  "contest_19": "Tutorial videos on advanced trading features",
  "contest_2": "Win up to",
  "contest_20": "Industry discussion points",
  "contest_21": "Cryptocurrency news and events",
  "contest_22": "Crypto trading 101",
  "contest_23": "Be creative! ",
  "contest_24": "Submit Your Video Here",
  "contest_25": "Rules and Regulations",
  "contest_26": "Your video must",
  "contest_27": "- Be positive in tone and message.<br>- Include the BitForex logo visibly when relevant. {fileLink}.<br>- Showcase BitForex services or features.<br>- Aim for 2-5 minute duration. No maximum.<br>- Receive a minimum of 500 views by the end date of the competition <br>- get out there and share it far and wide to increase your chances.",
  "contest_28": "<a href=\"https://event.bitforex.com/en/Media.html\">Download logo files here</a>",
  "contest_29": "Terms and Conditions:",
  "contest_3": "USDT",
  "contest_30": "- Winnings and rewards will be delivered within 5 business days following the conclusion of the event.",
  "contest_31": "- Do not use any materials (both audio or visual) that you do not have the rights to use. Services such as <a href=\"https://www.storyblocks.com/\" target=\"_blank\">storyblocks.com</a> are a great resource for video creation.",
  "contest_32": "- There is no limit to how many videos can be submitted per user.",
  "contest_33": "- Participation rewards are distributed once every 2 weeks. Duplicate videos will not receive multiple rewards.",
  "contest_34": "- You understand and agree that BitForex may use your submitted video contents for any purposes at their sole discretion.",
  "contest_35": "- BitForex reserves the right of final interpretation of activities.",
  "contest_36": "- For any questions regarding this contest, please reach out to use directly at {mailLink}",
  "contest_37": "DOWNLOAD",
  "contest_38": "BitForex Logo Materials",
  "contest_39": "MORE DETAILS",
  "contest_4": "Join the contest",
  "contest_40": "<a href=\"mailto:videocontest@bitforex.com\">videocontest@bitforex.com</a>",
  "contest_5": "Rules and Regulations",
  "contest_6": "Activity Process",
  "contest_7": "Step 1",
  "contest_8": "Create Something Special",
  "contest_9": "Step 2",
  "copy": "Copy",
  "copySuccess": "Copied",
  "defizone_1": "DeFi Zone",
  "defizone_10": "Click to fold",
  "defizone_11": "Hot",
  "defizone_12": "Trade",
  "defizone_13": "Meet BitForex DeFi Zone",
  "defizone_14": "Simple, Convenient, Fast, and Efficient",
  "defizone_2": "What is DeFi?",
  "defizone_3": "DeFi, which means \"decentralized finance\". It is the application of traditional finance to blockchain technology. DeFi's vision is that all assets can be tokenized and freely traded in the global open market. DeFi is built on the blockchain and has the characteristics of openness, transparency, automatic execution, and immutability of the blockchain.",
  "defizone_4": "What are the advantages of DeFi?",
  "defizone_5": "Compared with traditional finance, DeFi has many advantages, such as user requirements are low; financial services can be obtained through the Internet; the whole process is simple, convenient, fast, and efficient; without the participation of intermediaries, cross-border payments can significantly reduce costs; users’ assets are in their own hands; all transactions cannot be tampered with, and security is guaranteed.",
  "defizone_6": "What are the projects in DeFi?",
  "defizone_7": "There are currently thousands of DeFi projects in the world, covering payment networks, lending, stable coins, decentralized exchanges, decentralized wallets, insurance platforms, prediction markets, etc. The entire DeFi ecosystem is in a period of vigorous development.",
  "defizone_8": "About DeFi",
  "defizone_9": "Click to unfold",
  "defizone_btn_01": "Trade",
  "deposit_access": "I understand that deposits without a Tag (MEMO) will be lost.",
  "deposit_action": "Action",
  "deposit_amount": "Amount",
  "deposit_attenyion_slatepack": "Slatepack deposit method require a 8 digit MEMO, our system have created a MEMO for you account with your UID. Please make sure your MEMO is filled in correctly. Incorrect MEMO or no MEMO can cause a permanent loss of your valued asset.",
  "deposit_cancel": "Cancel",
  "deposit_cancelDepositSuccess": "Succeed",
  "deposit_copyAddressSuccess": "Copy Address Succeed",
  "deposit_copyMemoSuccess": "Copy Tag (MEMO) Succeed",
  "deposit_copyToClipboard": "Copy",
  "deposit_currencyBalance": "Current Balance",
  "deposit_currencyKeywords": "Token Keywords",
  "deposit_depositAddress": "Deposit Address",
  "deposit_depositAddressLable": "Deposit Address",
  "deposit_depositRecord": "Deposit History",
  "deposit_depositSelectlabel": "Please choose a Token",
  "deposit_depositStatus": "Status",
  "deposit_depositSuccess": "Succeed",
  "deposit_depositTips": "Please don't deposit any other digital assets except {coinName} to this address, or you may loss it.",
  "deposit_getAddress": "Address generating, please wait.",
  "deposit_getQRCodeFailed": "Generating address failed!",
  "deposit_lvtStatusName": "['Applying', 'Audit Complete', 'Deposit Succeed', 'Canceled', 'Deposit Failed']",
  "deposit_memo": "Tag (MEMO)",
  "deposit_minDeposit": "Min. Deposit Amount",
  "deposit_noData": "No Data",
  "deposit_notice": "Notice",
  "deposit_noticeItem1": "Do NOT send {coinName} to BitForex unless you know how to indicate a Tag (MEMO). If you fail to include the Tag (MEMO) with your deposit the funds will be lost forever.",
  "deposit_noticeItem2": "Please contact customer service online if you have any questions or concerns.",
  "deposit_nsgError": "The QR code failed to generate, please try again.",
  "deposit_pageTitle": "Deposit",
  "deposit_showQRCode": "QR Code",
  "deposit_statusName": "['Unconfirmed', 'Confirmed']",
  "deposit_statusName_1": "Deposit detected",
  "deposit_statusName_2": "Confirmation is almost reached, trading is available for now.",
  "deposit_statusName_3": "Completed",
  "deposit_time": "Time",
  "deposit_tipsItem1": "Less than the minimum amount of deposit will not be accounted for and can not be returned.",
  "deposit_tipsItem10": "2. Make sure you do not set any unlock conditions such as Storage Return/Expiration Unlock Condition while depositing, otherwise you will lose your assets.",
  "deposit_tipsItem11": "Please note that BitForex only supports SMR network deposits without unlocking conditions",
  "deposit_tipsItem12": "Make sure you do not set any unlock conditions such as Storage Return/Expiration Unlock Condition while depositing, otherwise you will lose your assets.",
  "deposit_tipsItem13": "Short",
  "deposit_tipsItem14": "Long",
  "deposit_tipsItem15": "I would like to invite you to join BitForex.",
  "deposit_tipsItem16": "Scan the QR code to register.",
  "deposit_tipsItem2": "Your {coinName} will arrive after {depositNum} confirmations, and it will be available to withdraw after {withdrawNum} confirmations.",
  "deposit_tipsItem3": "BitForex have finished the Klaytn mainnet swap of the {coin} token. Please make sure the {coin} you will deposit are based on Klaytn mainnet, otherwise you may lose your {coin} asset.",
  "deposit_tipsItem4": "Please make sure your computer is under safe operating environment. If this address does not match with previous address, please don't deposit into this address and contact our customer service immediately.",
  "deposit_tipsItem5": "Only {tranfer} deposit method is supported for {coin} at this moment, other deposit method may cause a failure of the deposit. Thank you for your understanding.",
  "deposit_tipsItem6": "Contract transfer or Block rewards (Coinbase) deposit method will cause the failure of deposits on your account for {coin}.",
  "deposit_tipsItem7": "Deposits confirmations are not reached, please be patient.",
  "deposit_tipsItem8": "Min. deposit amount: {num} {coin}, deposit amount less than the min. deposit amount will not be able to be reflected on your account.",
  "deposit_tipsItem9": "1. Please note that BitForex only supports SMR network deposits without unlocking conditions",
  "deposit_tip_omi": "Please note that your OMI-GO and OMI-ERC20 depositaddresses are different. Ensure the deposit address is correct before depositing, make sure you've selected the correct chain name, or you may lose your assets.",
  "deposit_tip_omi1": "Please note that your OMI-GO and OMI-ERC20 deposit addresses are different.",
  "deposit_tip_omi2": "Ensure the deposit address is correct before depositing, make sure you've selected the correct chain name, or you may lose your assets.",
  "deposit_title": "Tips",
  "deposit_token": "Token",
  "deposit_trade": "Trading",
  "deposit_txidTip": "Please choose a coin/token to see TXID (For instance: GRIN)",
  "deposit_type1": "Method 1: Copy the deposit address and MEMO",
  "deposit_type2": "Method 2: Scan the QR code directly",
  "deposit_xlmnotice": "Notice",
  "dot_1": "Celebrating Polkadot (DOT) IOU on BitForex",
  "dot_10": "Deposits open 1 hour after the launch of the Polkadot mainnet.",
  "dot_11": "Withdrawals open at 20:00 (GMT+8) on the 5th day after the launch of the Polkadot mainnet.",
  "dot_12": "For customers interested in obtaining a large amount of DOT, BitForex offers shorting, and users can borrow DOT from BitForex through USDT collateral.",
  "dot_13": "More details on the lending rules",
  "dot_14": "BitForex maintains the right to change or amend this event at its sole discretion.",
  "dot_15": "Trade Now",
  "dot_16": "Redemption opening hours are 2019/07/17 at 12:00 (GMT+8) until the 5th day at midnight after the launch of the Polkadot mainnet.",
  "dot_17": "Deadline for borrowing: midnight of the day before the launch of the Polkadot mainnet.",
  "dot_18": "Return deadline:until the 5th day at midnight after the launch of the Polkadot mainnet.",
  "dot_19": "Redeem open time: 2019/07/17 at 12:00 (GMT+8) ",
  "dot_2": "Time",
  "dot_20": "Redeem closing time: until the 5th day at midnight after the launch of the Polkadot mainnet.",
  "dot_24": "Borrow DOT",
  "dot_25": "Polkadot Official website:",
  "dot_26": "Twitter:",
  "dot_27": "Whitepaper:",
  "dot_28": "Lending Rules",
  "dot_29": "For customers interested in obtaining a large amount of DOT, BitForex offers shorting and users can borrow DOT to sell short.",
  "dot_3": "2019/07/12 at 12:00 (GMT+8) until the 5th day after Polkadot's mainnet launches, at 24:00 (GMT+8).",
  "dot_30": "BitForex offers shorting and users can borrow DOT from BitForex through USDT collateral.",
  "dot_31": "Borrowing and lending are based on a fixed amount. For every 1 DOT borrowed, 600 USDT is required as collateral (600 USDT is over-collateralized - the actual market price is much lower than 600 USDT). Maximum borrow amount is 200 DOT per user, and the minimum borrow amount is 0.2 DOT per user.",
  "dot_32": "Users can redeem at any time during the redemption period. Redemption will return the USDT that was mortgaged.",
  "dot_33": "The opening hours to borrow are 2019/07/12 at 12:00 (GMT+8) until midnight of the day before the launch of the Polkadot mainnet.",
  "dot_34": "In the event that DOT's market price should near 600 USDT (IOU ratio), user's borrowed DOT will automatically be returned to BitForex, and the USDT collateral will be returned.",
  "dot_4": "Project Introduction",
  "dot_5": "Polkadot was created by the Ethereum CTO Dr. Gavin Wood in partnership with the Web3 technical development team. Dr. Wood is also well known as a famous blockchain developer and is credited as the author of the Ethereum Yellow Paper. As a solution for different public network communication, Polkadot can provide more support for blockchain developers than similar solutions proposed by ETH 2.0.",
  "dot_6": "As the main representative project of Web3, Polkadot has been highly sought after by global enthusiasts, without even being launched yet. In order to meet user needs, BitForex has reached strategic cooperation with Polkadot private token sale participants. This gives BitForex the right to use 10,000 DOT tokens and will launch them on BitForex under the IOU program. All users who hold BitForex's DOT at maturity will receive the tokens once the Polkadot mainnet launches.",
  "dot_7": "Rules",
  "dot_8": "BitForex will launch DOT/USDT trading pairs, and users can use USDT to obtain DOT by trading DOT/USDT. After Polkadot's mainnet launch, BitForex will convert all DOT tokens owed into user's account.",
  "dot_9": "DOT opening price: 60 USDT.",
  "down_allQrcode": "Scan to download for iOS or Android",
  "down_androidDown": "Android APK",
  "down_androidQrcode": "Scan the QR code to downlaod the Android App",
  "down_downbtn": "Download",
  "down_downTips": "Trade. Anywhere.",
  "down_iosDown": "Download for iOS",
  "down_iosQrcode": "Scan the QR code to downlaod the iOS App",
  "down_iOSTips": "Please use smartphone to download.",
  "ecom_tip_01": "You are entering the ECOM trading pairs. Please note that the full project name of ECOM is Omnitude. It is not the same project as OMI, whose full project name is ECOMI. Please proceed with caution and trade only after making sure there is no error.",
  "eth2_1": "Meet BitForex Eth2.0 Staking",
  "eth2_10": "With ETH2, you can hold or trade your staked assets at any time, without having to wait until transactions are enabled on Ethereum 2.0.",
  "eth2_11": "Security",
  "eth2_12": "Keep your funds under your own custody, to always remain in full control of your assets.",
  "eth2_13": "The Big Role",
  "eth2_14": "in the BitForex Eth2.0 system",
  "eth2_15": "Stakers can stake any amount of ETH tokens (minimum 0.1 ETH) to earn staking rewards and platform fees. The staked ETH will immediately be liquid in the form of ETH2.",
  "eth2_16": "No hardware or technical knowledge is required for all users.",
  "eth2_2": "BitForex Eth2.0 Staking is a semi-decentralized protocol and platform that provides staking service, which implements elements from traditional staking with non-custodial management, BitForex Pools, instant liquidity.",
  "eth2_3": "Are you interested to stake in Eth2.0 and become a node provider?",
  "eth2_4": "Participate Now",
  "eth2_5": "Why BitForex Eth2.0?",
  "eth2_6": "BitForex Eth2.0 is built by the BitForex tech team, to provide the best user experience and highest level of safety, combined with an attractive reward mechanism and instant staking liquidity through a bond-like synthetic token called ETH2.",
  "eth2_7": "BitForex Pool",
  "eth2_8": "Stake from 0.1 ETH and automatically join other stakers in BitForex Pools!",
  "eth2_9": "Staking Liquidity",
  "export_1": "Export",
  "export_10": "Export up to 10,000 records at a time, up to 5 times per month",
  "export_11": "Time period and export type do not match",
  "export_12": "Export time is more than 365 days",
  "export_13": "Exports exceeded",
  "export_2": "*Only query and display billing records within the past 90 days, please use the \"Export\" function to view more data.",
  "export_3": "Export bills",
  "export_4": "This operation takes a long time, and the exported bill will be sent to your email inbox.",
  "export_5": "Select period",
  "export_6": "within 180 days",
  "export_7": "beyond 180 days",
  "export_8": "Export (Remaining{n}Time )",
  "export_9": "*The export feature is only available for data later than 2020-01-01. Max. export period can not longer than 1 year. Max. export record is 100,000 each time.",
  "F103": "Order creation failed",
  "fees_1": "Fees",
  "fees_10": "Taker fees:",
  "fees_11": "Deposit Fees",
  "fees_12": "Deposit: Free",
  "fees_13": "Withdrawal Fees",
  "fees_14": "The digital currency withdrawal fee depends on the network cost. The BitForex platform's withdrawal fees are as follows:",
  "fees_15": "Coin/Token",
  "fees_16": "Withdrawal Fees",
  "fees_17": "Minimum Withdrawal",
  "fees_18": "24H Withdrawal Limit",
  "fees_19": "The Fees page will updated regularly, please refer to the withdrawal page for latest transaction fees of specific coin/ token.",
  "fees_2": "Trading Fees",
  "fees_20": "BitForex has arranged a new discounted rate for Market Maker accounts. Applications can now be submitted to vip@bitforex.com, and will be reviewed on Mondays.",
  "fees_21": "A commission is a fee charged by a trader for buying or selling a product.",
  "fees_22": "Forex/Commodity/Metal/Index commission: 10 USD / Per Lot",
  "fees_23": "Cryptocurrency commission: 0.08% per trade",
  "fees_24": "BTC & ETH",
  "fees_25": "Maker fee:",
  "fees_26": "Taker fee:",
  "fees_27": "Others",
  "fees_3": "Maker",
  "fees_4": "Maker fees are paid when you add liquidity to our order book by placing a limit order below the ticker price for buy, and above the ticker price for sell.",
  "fees_5": "Taker",
  "fees_6": "Taker fees are paid when you remove liquidity from our order book by placing any order that is executed against an order on the order book.",
  "fees_7": "Maker fees:",
  "fees_8": "Taker fees:",
  "fees_9": "Maker fees:",
  "fees_allpairs": "All Pairs",
  "fees_alltoken": "All Token",
  "fees_average": "Monthly Average Holdings (USD)",
  "fees_levelone": "Level",
  "fees_login": "Log in to check your trading fee rate",
  "fees_monthly": "Monthly Trade Volume (USD)",
  "fees_or": "or",
  "fees_perpetual": "Perpetual Trading",
  "fees_rate": "Fee Rate",
  "fees_spot": "Spot Trading",
  "fees_your_level": "Your Fee Level",
  "FI101": "The sale of this financial product has been suspended",
  "FI102": "The sale of this financial product has been suspended",
  "FI103": "The sale of this financial product has been suspended",
  "FI104": "Insufficient inventory",
  "FI106": "The sale of this financial product has been suspended",
  "FI107": "协议已同意，请勿再次同意",
  "FI108": "Please try again later",
  "FI111": "Decimal digits cannot exceed 8",
  "fil_1": "Filecoin on BitForex IOU",
  "fil_10": "Filecoin is one of the most popular unlisted blockchain projects in the cryptocurrency industry to date, and is one of the core components of Web3. IPFS has been used by a large number of teams thanks to the open-source nature of the code, allowing for free downloads and implementation. It allows participants in the network to store, request and transmit protocols for verifiable data to each other; Filecoin (FIL), which complements its incentives, is the algorithmic market and lifeblood of decentralized storage networks. The BitForex founding team, as an early FIL investor, has a certain number of FIL tokens in its private reserves and is now offering 100,000 FIL to BitForex users in the form of an Initial Offering: Unbound (IOU). All participating users will see FIL tokens in their BitForex accounts become available for withdrawal following the launch of the Filecoin mainnet and the Filecoin lock-up period.",
  "fil_11": "Trading rules",
  "fil_12": "BitForex will launch FIL/USDT trading pair, and users can use USDT to obtain FIL by trading FIL/USDT. All participating users will see FIL tokens in their BitForex accounts become available for withdrawal following the launch of the Filecoin mainnet and the Filecoin lock-up period.",
  "fil_13": "FIL opening price: 0.78 USDT.",
  "fil_14": "Deposits open 1 hour after the launch of the Filecoin mainnet.",
  "fil_15": "Withdrawals open at 20:00 (GMT+8) on the 5th day after the launch of the Filecoin's mainnet and the Filecoin lock-up period.",
  "fil_16": "For customers interested in obtaining a large amount of FIL, BitForex offers to short, and users can borrow FIL from BitForex through USDT collateral.",
  "fil_17": "BitForex maintains the right to change or amend this event rules at its sole discretion.",
  "fil_18": "Trade Now",
  "fil_19": "Borrow FIL from BitForex",
  "fil_2": "Time",
  "fil_20": "Borrowing Rules",
  "fil_21": "For customers interested in obtaining a large amount of FIL, BitForex offers to short.",
  "fil_22": "Users can borrow FIL from BitForex through USDT collateral.",
  "fil_23": "Borrowing and lending are based on a fixed amount. For every 1 FIL borrowed, 25 USDT is required as collateral (25 USDT is over-collateralized - the actual market price is much lower than 25 USDT). The maximum loan limit is 5,000 FIL, and the minimum loan amount is 1 FIL.",
  "fil_24": "Users can redeem at any time during the redemption period. Redemption will return the USDT that was mortgaged.",
  "fil_25": "The opening hours to borrow are 2019/07/18 at 21:00 (GMT+8) until midnight of the day before the launch of the Filecoin mainnet.",
  "fil_26": "Redemption opening hours are 2019/07/23 at 21:00 (GMT+8) until the 5th day at midnight after the launch of the Filecoin mainnet.",
  "fil_27": "User's borrowed FIL will automatically be returned to BitForex, and the USDT collateral will be returned in the event that FIL's market price should near 25 USDT (IOU ratio).",
  "fil_28": "Deadline for borrowing: 1 day prior to the Filecoin mainnet launch, at 24:00 (GMT+8).",
  "fil_29": "Return deadline: 24:00 (GMT+8) of the 5th day after the Filecoin mainnet launch.",
  "fil_3": "From July 18th, 2019 at 21:00 (GMT+8) until the 5th day launch of the Filecoin mainnet and the Filecoin lock-up period.",
  "fil_4": "Project Introduction",
  "fil_5": "Filecoin was created by the Web3 core member Juan Benet. It’s a decentralized storage network developed by the Protocol Labs team to transform cloud storage into an algorithmic market. Miners can obtain Filecoin by providing data storage and retrieval. Meanwhile, users can obtain the storage, distribution, and retrieval of data services provided by the miners via payment. Filecoin and the well-known IPFS are complementary protocols, both created by Protocol Labs. IPFS retrieves contents by searching addresses — making Filecoin a complement to IPFS's incentives.",
  "fil_6": "Filecoin official website:",
  "fil_7": "Twitter:",
  "fil_8": "Whitepaper:",
  "fil_9": "FIL IOU",
  "footer_1": "FEATURES",
  "footer_10": "Media",
  "footer_11": "Join Us",
  "footer_12": "Community",
  "footer_13": "HELP & SUPPORT",
  "footer_14": "Terms of Service",
  "footer_15": "Fees",
  "footer_16": "API Doc",
  "footer_17": "Support Center",
  "footer_18": "Customer Service",
  "footer_19": "LANGUAGE",
  "footer_2": "Spot",
  "footer_20": "SOCIAL MEDIA",
  "footer_21": "News",
  "footer_22": "Blog",
  "footer_23": "RELATED LINK",
  "footer_24": "Submit a Request",
  "footer_25": "China",
  "footer_26": "Marginal",
  "footer_27": "TURBO",
  "footer_28": "IOU",
  "footer_29": "Option",
  "footer_3": "Perpetual",
  "footer_30": "Support",
  "footer_31": "Service",
  "footer_32": "Experience Feedback",
  "footer_33": "Token Compendium",
  "footer_34": "Buy BTC",
  "footer_35": "Business Contacts",
  "footer_36": "Announcement",
  "footer_37": "BitForex Capital",
  "footer_4": "Deposits ",
  "footer_5": "Withdrawals",
  "footer_6": "Referral",
  "footer_7": "About us",
  "footer_8": "About BitForex",
  "footer_9": "Token Listing",
  "footer_listing_application": "Listing Application",
  "form_1": "Please enter phone number",
  "form_10": "Confirm the password",
  "form_100": "Etner the verification code from your email:  {email}",
  "form_101": "Enter the verification code from your phone number:  {phone}",
  "form_102": "I have read and agreed to the",
  "form_103": "Register with your email or phone",
  "form_104": "Welcome to BitForex",
  "form_105": "Please enter a correct email address",
  "form_106": "Add Referral Code(Optional)",
  "form_107": "Return to the login page",
  "form_108": "Withdrawals will be unavailable for the next 1 hour once password is changed.",
  "form_109": "BITFOREX KNIGHT PROGRAM",
  "form_11": "Please enter email",
  "form_110": "Invite Your Friends to Get 30% Commission",
  "form_111": "Premium Knights can Get 40% Commission",
  "form_112": "Create an account and learn more",
  "form_113": "By clicking \"create account\" button, I agree to the<a href=\"https://event.bitforex.com/en/Terms.html\" class=\"spanGo\" target=\"view_window\">&lt;Terms of Service&gt;</a>",
  "form_114": "Please enter a correct phone number",
  "form_115": "Please enter 8-20 characters",
  "form_116": "The email does not exist.",
  "form_117": "Google 2FA",
  "form_118": "Mobile Varification",
  "form_12": "Please check the Terms of Service",
  "form_13": "Please enter the correct password",
  "form_14": "Your password should be 8-20 characters long, containing uppercase & lowercase characters, and numbers.",
  "form_15": "Please enter correct email",
  "form_16": "@qq.com and @foxmail.com addresses may experience delivery issues. Please use an alternative email provider.",
  "form_17": "Please enter 6-digit password",
  "form_18": "Please re-enter the 6-digit password",
  "form_19": "Please enter your new trading password",
  "form_2": "Please enter phone number/email ",
  "form_20": "Please verify your new trading password",
  "form_21": "Next",
  "form_212": "Your password should be 8-20 characters long, containing uppercase & lowercase characters, and numbers.",
  "form_213": "Create BitForex Account",
  "form_214": "Select Country Code",
  "form_215": "Please enter the correct email verification code.",
  "form_216": "Please enter the correct SMS code.",
  "form_217": "Please enter the correct Google 2FA password.",
  "form_218": "Google",
  "form_219": "SMS",
  "form_22": "Verification",
  "form_220": "Trusted by 6M+ users since 2018.",
  "form_221": "Trade with confidence",
  "form_222": "Join our Telegram Community",
  "form_223": "Ask questions, get answers, and chat with other traders to shape the crypto future together.",
  "form_23": "If you entered an incorrect trading password 6 times, you are be prohibited from trading for 5 minutes.",
  "form_24": "Tips",
  "form_25": "Safety Verification",
  "form_26": "Verification",
  "form_27": "Confirm",
  "form_28": "Reset Password",
  "form_29": "Create Password",
  "form_3": "Please enter SMS code",
  "form_30": "Reset Trading Password",
  "form_31": "The passwords you entered do not match, please try again.",
  "form_32": "Wrong password",
  "form_33": "Risk Warning",
  "form_34": "Bind Phone",
  "form_35": "Bind Email",
  "form_36": "Bind Google Authenticator",
  "form_37": "Change Login Password",
  "form_38": "Please enter",
  "form_39": "Please select",
  "form_4": "Please enter the email verification code",
  "form_40": "Phone",
  "form_41": "Email",
  "form_42": "Phone/Email",
  "form_43": "Password",
  "form_44": "New password",
  "form_45": "Confirm your new password",
  "form_46": "Please verify the new password",
  "form_47": "The passwords you entered do not match, please try again.",
  "form_48": "For the security of your assets, you are not allowed to withdraw within 1 hour after changing your login password.",
  "form_49": "Password changed successfully, please log in again.",
  "form_5": "Please enter Google verification code",
  "form_50": "Code",
  "form_51": "SMS Verification Code",
  "form_52": "Email Verification Code",
  "form_53": "Google Verification Code",
  "form_54": "Send",
  "form_55": "Resend",
  "form_56": "Sent to {user}",
  "form_57": "Invitation code (optional)",
  "form_58": "I have read and agree to the",
  "form_59": "<Terms of Service>",
  "form_6": "Please enter password",
  "form_60": "Please check the Terms of Service",
  "form_61": "Your password should be 8-20 characters long, containing uppercase & lowercase characters, and numbers.",
  "form_62": "@qq.com & @foxmail.com may experience deliverability issues, please use alternative email providers.",
  "form_63": "1. Scan the QR code on the left or enter Google Secret Key<br> 2. Enter Google 2-factor Authenticator Code<br> 3. Please save your Google Key, it will be used to restore your Google Authenticator if you should ever need to.",
  "form_64": "How to download the Google Authenticator app?",
  "form_65": "Error! Please try again.",
  "form_66": "Please complete the verification",
  "form_67": "Success",
  "form_68": "Password reset successfully",
  "form_69": "Already have an account?",
  "form_7": "Please enter new password",
  "form_70": "First use?",
  "form_71": "Please enter verification code",
  "form_72": "Old Password",
  "form_73": "Please enter the old password",
  "form_74": "Login Password",
  "form_75": "Please enter the password",
  "form_76": "Close",
  "form_77": "Verification image retrieval failed, please try again later.",
  "form_78": "Forgot Password?",
  "form_79": "Don't have an account?",
  "form_8": "Please enter the password again",
  "form_80": "We've detected a possible security risk on your account. To ensure account safety, please reset your password and log in again.",
  "form_81": "It appears that you are logging in from an unrecognized device or location. Please complete a security verification.",
  "form_82": "Register by Email",
  "form_83": "Register by Phone",
  "form_84": "Receive BitForex news and updates.",
  "form_85": "Please enter the correct phone number",
  "form_86": "SMS Verification",
  "form_87": "Google Verification",
  "form_88": "Unbind Cell Phone",
  "form_89": "Your Google 2FA has expired, please bind your 2FA to avoid any possible inconvenience during your trading",
  "form_9": "Passwords must match!",
  "form_90": "Please enter your trading password",
  "form_91": "Trading Password",
  "form_92": "Try Now",
  "form_93": "Change Google 2FA",
  "form_94": "Withdrawal function will be suspended for 24hrs after changing your Google 2FA",
  "form_95": "Can't access your phone or email?",
  "form_96": "Please enter the new verification code",
  "form_97": "Enter the Google verification code from the previous Google 2FA",
  "form_98": "Unbind",
  "form_99": "Change",
  "funding_1": "Perpetual Contract-Funding Rate",
  "funding_2": "Start Trading {symbol}",
  "funding_3": "Start Date",
  "funding_4": "End Date",
  "funding_5": "Search",
  "funding_6": "Date",
  "funding_7": "Funding Rate",
  "funding_8": "*Only 100 days funding rate history shown",
  "fzy_1": "FZY (Frenzy)",
  "fzy_10": "Lock-up Period",
  "fzy_11": "No Lock-up",
  "fzy_12": "IMPORTANT NOTICE",
  "fzy_13": "1. The minimum order amount for a single account is 10 USDT = 400 FZY<br/>2. The maximum order amount for a single account is 500 USDT = 20,000 FZY<br/>3. There is no handling fee for the transaction<br/>4. KYC is NOT required for the FZY subscription<br/>5. There are many participating users. It is recommended to log in before 7:00 (GMT+8) and prepare in advance.",
  "fzy_16": "REFERENCE",
  "fzy_17": "Frenzy Singapore Website",
  "fzy_18": "https://frenzy-singapore.xyz/",
  "fzy_19": "Frenzy Whitepaper",
  "fzy_2": "Tackling the business succession problems in Japan",
  "fzy_20": "https://frenzy-singapore.xyz/whitepaper.pdf",
  "fzy_21": "INTRODUCTION",
  "fzy_22": "About Frenzy Capital",
  "fzy_23": "Frenzy Capital aims at solving the business succession problems in Japan by Frenzy Contract, the main technology of Frenzy Capital. Single investors face high hurdle to make access to business succession companies or fund without third-party mediation. However, we believe that by utilitizing Blockchain and Smart Contract, we could clear the hurdle and create an environment allowing single investors to access to business succession companies, venture companies, and funds.In addition, our project members consist of specialist in growing company through investigation on the projects of the potential companies and hands-on investment.",
  "fzy_24": "About FZY",
  "fzy_25": "Frenzy Capital lists the potential companies, considered to be high-quality upon investigation through partner companies, on Frenzy Contract. Users are able to use FZY when they invest on the projects on Frenzy Contract.",
  "fzy_29": "About FZY",
  "fzy_3": "2020/3/27 12:00 (GMT+8)",
  "fzy_30": "Frenzy Capital lists the potential companies, considered to be high-quality upon investigation through partner companies, on Frenzy Contract. Users are able to use FZY when they invest on the projects on Frenzy Contract.",
  "fzy_35": "FZY Token Sale",
  "fzy_4": "2020/3/28 12:00 (GMT+8)",
  "fzy_44": "Token Sale Start Time",
  "fzy_45": "2020/3/27 12:00 - 2020/3/28 12:00 (GMT+8)",
  "fzy_46": "Token Price",
  "fzy_47": "0.025 USDT",
  "fzy_48": "Turbo Allocation",
  "fzy_49": "20,000,000 FZY",
  "fzy_5": "Subscription Time：2020/3/27 12:00 - 2020/3/28 12:00 (GMT+8)",
  "fzy_50": "Bonus",
  "fzy_51": "NONE",
  "fzy_52": "Total Token Supply",
  "fzy_53": "10,000,000,000 FZY",
  "fzy_56": "Initial Circulating Supply",
  "fzy_57": "0",
  "fzy_58": "Token Sale Vesting Period",
  "fzy_59": "NONE",
  "fzy_6": "Subscription Amount: 20,000,000 FZY = 500,000 USDT",
  "fzy_60": "The Maximum Order Amount",
  "fzy_61": "20,000 FZY = 500 USD",
  "fzy_62": "The Minimum Order Amount",
  "fzy_63": "400 FZY = 10 USD",
  "fzy_64": "Private Sale Token Price",
  "fzy_65": "NONE",
  "fzy_66": "Token Type",
  "fzy_67": "ERC-20",
  "fzy_7": "Price: 0.025 USDT",
  "fzy_70": "TOKEN ALLOCATION",
  "fzy_72": "FZY TEAM",
  "fzy_8": "Subscription Trading Pair",
  "fzy_9": "FZY/BTC",
  "gram_1": "Celebrating Telegram's IOU on BitForex",
  "gram_10": "Telegram Open Network’s (TON) token GRAM has attracted the attention of cryptocurrency enthusiasts around the world. BitForex has entered into strategic cooperation with TON’s GRAM token sale participants and currently has the right to use 1 million Telegram Open Network GRAM tokens. Following the launch of Initial Offerings:Unbound (IOU) on BitForex, “GRAM Futures Physical Delivery” has officially been renamed to “GRAM IOU”. All participating users will see GRAM tokens in their BitForex accounts become available for withdrawal following the launch of the TON mainnet.",
  "gram_11": "Rules",
  "gram_12": "BitForex will launch GRAM/USDT trading pairs, and users can use USDT to obtain GRAM by trading GRAM/USDT. All participating users will see GRAM tokens in their BitForex accounts become available for withdrawal following the launch of the TON mainnet.",
  "gram_13": "GRAM opening price:0.5 USDT.",
  "gram_14": "Deposits open 1 hour after the launch of the TON mainnet.",
  "gram_15": "Withdrawals open at 24:00 (GMT+8) on the 5th day after the launch of the TON mainnet. ",
  "gram_16": "For customers interested in obtaining a large amount of GRAM, BitForex offers shorting, and users can borrow GRAM from BitForex through USDT collateral.",
  "gram_17": "BitForex reserves the right to cancel or amend the event or event rules at its sole discretion.����",
  "gram_18": "Trade Now",
  "gram_19": "Borrow GRAM ",
  "gram_2": "Time",
  "gram_20": "Lending Rules",
  "gram_21": "For customers interested in obtaining a large amount of GRAM, BitForex offers shorting, and users can borrow GRAM to sell short.",
  "gram_22": "BitForex offers shorting and users can borrow GRAM from BitForex through USDT collateral.",
  "gram_23": "Borrowing and lending are based on a fixed amount. For every 1 GRAM borrowed, 10 USDT is required as collateral (10 USDT is over-collateralized - the actual market price is much lower than 10 USDT); Maximum borrow amount is 10000 GRAM per user, and the minimum borrow amount is 1 GRAM per user.",
  "gram_24": "Users can redeem at any time during the redemption period. Redemption will return the USDT that was mortgaged.",
  "gram_25": "The opening hours to borrow are 2019/07/10 at 12:00 (GMT+8) until midnight of the day before the launch of the TON mainnet.",
  "gram_26": "Redemption opening hours are 2019/07/15 at 12:00 (GMT+8) until the 5th day at midnight after the launch of the TON mainnet.",
  "gram_27": "In the event that GRAM's market price should near 10 USDT (IOU ratio), user's borrowed GRAM will automatically be returned to BitForex, and the USDT collateral will be returned.",
  "gram_28": "Deadline for borrowing: 1 day prior to the TON mainnet launch, at 24:00 (GMT+8).",
  "gram_29": "Return deadline: 24:00 (GMT+8) of the 5th day after the mainnet launch.",
  "gram_3": "2019/07/10 12:00 (GMT+8) - 24:00 (GMT+8) of the 5th day after the mainnet opens.",
  "gram_30": "Redemption starts: 2019/07/15 12:00 (GMT+8).",
  "gram_31": "Redemption stops: 24:00 (GMT+8) of the 5th day after the mainnet launch.",
  "gram_4": "Project Introduction",
  "gram_5": "The Telegram Open Network (TON) was developed by Dr. Nikolai Durov, co-founder of Telegram. It was conceived as a means of communication that subverts traditional information flow. Telegram currently serves more than 260 million active users worldwide through an simple user-friendly app.TON aims to provide a new set of speed and expansion solutions for Telegram. With the support of Nikolai Durov, the final design takes the shape of a blockchain network project that combines speed and security, integrating cryptocurrency and blockchain technology within the world's most powerful and advanced communications platform.",
  "gram_6": "TON Blockchain Test Network:",
  "gram_7": "Telegram Open Network Whitepaper:",
  "gram_8": "Explorer:",
  "gram_9": "GRAM IOU",
  "header_1": "Log in",
  "header_10": "Orders",
  "header_11": "Bills",
  "header_12": "Help",
  "header_13": "Announcement",
  "header_14": "Blog",
  "header_15": "Spot",
  "header_16": "Perpetual",
  "header_17": "Referral",
  "header_18": "Account",
  "header_19": "Spot Orders",
  "header_2": "Register",
  "header_20": "Perpetual Orders",
  "header_21": "Select Language",
  "header_22": "Switch",
  "header_23": "Event Center",
  "header_24": "Feedback",
  "header_25": "Welcome to share your feedback by email to us!",
  "header_26": "Latest News",
  "header_27": "Help Center",
  "header_28": "Event Center",
  "header_29": "MT5 Competition",
  "header_3": "View Demo",
  "header_30": "Perpetual Contract Competition",
  "header_31": "Customer Support",
  "header_32": "Community",
  "header_33": "SocialTrading",
  "header_34": "Free Bitcoin",
  "header_35": "Trade at BitForex Invest in the Future",
  "header_36": "Create account",
  "header_4": "My Assets",
  "header_5": "Log out",
  "header_6": "Security Settings",
  "header_7": "Get Verified",
  "header_8": "API Management",
  "header_9": "Assets",
  "header_buy": "Buy Crypto",
  "home_15": "IMPORTANT REMINDER",
  "home_16": "Recently, there have been BitForex impersonators contacting users with the intent of scamming, thus fraudulently obtaining user assets.",
  "home_17": "BitForex's staff and channel admins will NEVER ask for your login credentials, private wallet address, or other similar private information.",
  "home_18": "The ONLY official BitForex Telegram group is",
  "home_19": "BitForexEnglish",
  "home_20": "https://t.me/BitForexEnglish",
  "home_21": "Got it",
  "home_22": "In order to stay safe and protect yourself from scammers and misinformation, please verify the legitimacy of your chosen communication channel.",
  "home_30": "BitForex, The World's Leading One-Stop Digital Asset Service Platform",
  "home_31": "Get Started",
  "home_32": "Perpetual",
  "home_33": "Spot",
  "home_34": "Your Asset is Secured with BitForex",
  "home_35": "Insured",
  "home_36": "Crypto assets on our platform are covered by insurance",
  "home_37": "Secured",
  "home_38": "Significant portion of digital assets in cold wallet",
  "home_39": "Supportive",
  "home_40": "24/7 online customer service",
  "home_41": "Use <img src='{img}' /> to purchase",
  "home_42": "Visa and MasterCard Supported",
  "home_43": "Purchase",
  "home_44": "BitForex Mobile App",
  "home_45": "Available on iOS and Android",
  "home_46": "Download on Google Play",
  "home_47": "Download on App Store",
  "home_48": "180+",
  "home_49": "Countries Supported",
  "home_50": "300+",
  "home_51": "Trading Pairs",
  "home_52": "3,500,000+",
  "home_53": "Users Served",
  "home_54": "Partners",
  "home_55": "BitForex and Honorable Partners",
  "home_56": "Register Now",
  "home_57": "Register",
  "home_58": "Download Now and Claim your $10 Bonus for FREE! <a href='https://support.bitforex.com/hc/en-us/articles/360033765952' target='_blank'>Details</a>",
  "home_59": "We offer a variety of features that makes BitForex an ideal platform to trade digital assets.",
  "home_60": "Please enter amount",
  "home_61": "Trade",
  "home_62": "Buy",
  "home_63": "BitForex Pro <i>TestFlight</i> version can prevent the App crashing issues for all iOS users.",
  "home_64": "Mobile Web",
  "home_65": "Scan this QR code to access the mobile web",
  "home_v2_01": "The World's Leading One-Stop Digital Asset Service Platform",
  "home_v2_02": "Trade at BitForex Invest in the Future",
  "home_v2_03": "Trade Now",
  "home_v2_04": "A Leading Cryptocurrency Trading Platform",
  "home_v2_05": "BitForex's mission is to create a safe, professional, and convenient digital platform for users to be involved in the next financial generation",
  "home_v2_06": "Countries Supported",
  "home_v2_07": "Trading Pairs",
  "home_v2_08": "Users Served",
  "home_v2_09": "Insured",
  "home_v2_10": "Penetration testing report provided by Hackenproof annually",
  "home_v2_100": "Trade 24/7 Earn Anywhere",
  "home_v2_101": "Please use your Smartphone to download.",
  "home_v2_102": "BitForex Pro is Now Available on  <span style=\"color:#14C393;\">AppStore </span>and <span style=\"color:#14C393;\">GooglePlay.</span>",
  "home_v2_103": "Frequently Asked Questions",
  "home_v2_104": "Start Your Crypto Journey Now!",
  "home_v2_105": "With the BitForex App and website, trading has never been easier.",
  "home_v2_106": "Register",
  "home_v2_107": "More Community",
  "home_v2_108": "About us",
  "home_v2_109": "About Bitforex",
  "home_v2_11": "Secured",
  "home_v2_110": "Bitforex Captital",
  "home_v2_111": "Join us",
  "home_v2_112": "Community",
  "home_v2_113": "Bug Bounty",
  "home_v2_114": "Referral",
  "home_v2_115": "Fees",
  "home_v2_116": "Terms of Service",
  "home_v2_117": "Academy",
  "home_v2_118": "Products",
  "home_v2_119": "Buy Crypto",
  "home_v2_12": "Significant portion of digital assets in cold wallet",
  "home_v2_120": "Spot",
  "home_v2_121": "Perpetual Futures",
  "home_v2_122": "BitForex Earn",
  "home_v2_123": "NFT Marketplace",
  "home_v2_124": "Turbo Starter",
  "home_v2_125": "Crypto Loans",
  "home_v2_126": "Service",
  "home_v2_127": "Support Center",
  "home_v2_128": "Announcement",
  "home_v2_129": "Submit a Request",
  "home_v2_13": "Supportive",
  "home_v2_130": "API Docs",
  "home_v2_131": "FAQ",
  "home_v2_132": "Listing Application",
  "home_v2_133": "Buy",
  "home_v2_134": "Buy BF",
  "home_v2_135": "Buy Bitcoin",
  "home_v2_136": "Buy Ethereum",
  "home_v2_137": "Buy OMI",
  "home_v2_138": "Buy Dogecoin",
  "home_v2_139": "Buy DeFi",
  "home_v2_14": "24/7 online customer service",
  "home_v2_140": "Buy NFT",
  "home_v2_141": "Buy Polkadot",
  "home_v2_142": "Is BitForex safe?",
  "home_v2_143": "BitForex offers industry-leading technical measures to ensure the safety of user assets and accounts. Backed by one of the world's most sophisticated maintenance teams.",
  "home_v2_144": "What products does BitForex provide?",
  "home_v2_145": "BitForex is one of the top cryptocurrency exchanges dedicated to providing users with safe, professional, and convenient\ndigital currency trading services. On BitForex you can:\n\nManage your Crypto portfolio.\nTrade hundreds of digital assets on spot and perpetual markets.\nBuy and sell NFTs, Digital Collectibles on BitForex NFT Marketplace.\nEarn stable profits with professional asset management on BitForex Earn.\nBorrow up to 85% of the value of your collateral.",
  "home_v2_146": "What are Non-Fungible Tokens(NFTs)?",
  "home_v2_147": "A non-fungible token (NFT) is a non-interchangeable unit of data stored on a blockchain, a form of digital ledger, that can be sold and traded. Types of NFT data units may be associated with digital files such as photos, videos, and audio. Because each token is uniquely identifiable, NFTs differ from most cryptocurrencies, such as Bitcoin, which are fungible.",
  "home_v2_148": "What is Bitcoin?",
  "home_v2_149": "Bitcoin is a decentralized digital currency created in January 2009. Bitcoin offers the promise of lower transaction fees than traditional online payment mechanisms do, and unlike government- issued currencies, it is operated by a decentralized authority.",
  "home_v2_15": "Announcement:",
  "home_v2_150": "Earn",
  "home_v2_151": "Loans",
  "home_v2_152": "Video",
  "home_v2_153": "Article",
  "home_v2_154": "Futures",
  "home_v2_155": "Countries supported",
  "home_v2_156": "Trading pairs",
  "home_v2_157": "Users served",
  "home_v2_158": "Years operation",
  "home_v2_16": "Top Assets",
  "home_v2_17": "Newly Listed",
  "home_v2_18": "Top Gainers",
  "home_v2_19": "Trade 24/7 Earn Anywhere",
  "home_v2_20": "Scan to download for iOS or Android",
  "home_v2_21": "Tips",
  "home_v2_22": "Please use <span class=\"imp\">smartphone</span> to download.",
  "home_v2_23": "BitForex Pro <span class=\"imp\">TestFlight 1.2.0</span> version can prevent the App crashing issues for all iOS users.",
  "home_v2_24": "API DOC",
  "home_v2_25": "PRODUCTS",
  "home_v2_26": "Announcement",
  "home_v2_27": "Blog",
  "home_v2_28": "Buy",
  "home_v2_40": "Products",
  "home_v2_41": "Buy BF",
  "home_v2_42": "Buy Bitcoin",
  "home_v2_43": "Buy Ethereum",
  "home_v2_44": "Buy OMI",
  "home_v2_45": "Buy Dogecoin",
  "home_v2_46": "Buy DeFi",
  "home_v2_47": "Buy NFT",
  "home_v2_48": "Buy Polkadot",
  "home_v2_49": "BitForex Official Channels",
  "home_v2_50": "Join us and get the latest updates!",
  "home_v2_51": "More Channels",
  "home_v2_52": "Join the Official Channels",
  "home_v2_53": "Close",
  "home_v2_54": "Chart",
  "home_v2_55": "Please use smartphone to download.",
  "home_v2_56": "The crypto world is in the palm of your hand.",
  "home_v2_57": "All your favorite crypto assets on BitForex App you can trade effortlessly, anytime and anywhere.",
  "home_v2_80": "Hot List",
  "home_v2_81": "Innovation Zone",
  "home_v2_82": "Top Gain",
  "home_v2_83": "Explore and Earn More on BitForex",
  "home_v2_84": "Turbo",
  "home_v2_85": "Variety of initial launch modes with most premium projects in industry",
  "home_v2_86": "NFT Marketplace",
  "home_v2_87": "Explore NFTs, touch the future",
  "home_v2_88": "BitForex Earn",
  "home_v2_89": "Gain stable and high-yield profits with low threshold investment",
  "home_v2_90": "BitForex Loans",
  "home_v2_91": "Borrow up to 85% of the value of your collateral",
  "home_v2_92": "Academy",
  "home_v2_93": "Why BitForex ?",
  "home_v2_94": "Top Secure Trading Platform",
  "home_v2_95": "All your investment & asset is secured by the highest level on BitForex.",
  "home_v2_96": "Bug Bounty & White Hat",
  "home_v2_97": "High rewards for researchers who find serious vulnerabilities that affect system security",
  "home_v2_98": "Professional Connectivity",
  "home_v2_99": "24/7 support, get accurate and professional support across multiple time zones",
  "ieo_1": "Bullshit-Inu was created for the purpose of building a global Marketplace. We reward users that contribute to the Bullshit Ecosystem. We welcome creators, members, couch potatoes, and anyone that wants to join the movement. The future is here. No more TAX ON TRANSFERS! From day one you can use Bullshit-inu in Worldwide use. Big & Unique Marketplace is set to launch as soon as Q2 2022, Stay tuned!",
  "ieo_10": "Sleep Future is the world’s first blockchain-driven sleep wellness ecosystem to improve the sleep quality and health of the global community.",
  "ieo_100": "2022/08/15 18:00 (GMT+8)",
  "ieo_101": "1. The minimum order amount for a single account is 2 CUC",
  "ieo_102": "2. The maximum order amount for a single account is 1000 CUC",
  "ieo_103": "3. There is no handling fee for the transaction.",
  "ieo_104": "4. KYC is NOT required for the CUC subscription.",
  "ieo_105": "5.The subscription will stop when reaching the hard cap or reaching the end time. First come, first served.",
  "ieo_106": "About CUC",
  "ieo_107": "CUC Token Sale",
  "ieo_108": "Highest Return",
  "ieo_11": "10% release an hour before listing, followed by 10% monthly over 9 Months",
  "ieo_112": "<Turbo Service Agreement>",
  "ieo_113": "Sold out",
  "ieo_114": "Symbol",
  "ieo_115": "Distributed Token",
  "ieo_116": "Subscription Amount",
  "ieo_117": "Subscription Time",
  "ieo_118": "Confirmed Share",
  "ieo_119": "Distributed Qty",
  "ieo_12": "1. The minimum order amount for a single account is 1000 USDT<br>\n2. The maximum order amount for a single account is 50000 USDT<br>\n3. There is no handling fee for the transaction<br>\n4. KYC is NOT required for the SLEEPEE subscription.<br>\n5. The subscription will stop when reaching the hard cap or reaching the end time. First come, first served.",
  "ieo_120": "Undistributed Qty",
  "ieo_121": "Distribution Time",
  "ieo_122": "Refund Amount",
  "ieo_123": "shares",
  "ieo_124": "2. The transaction will be confirmed before {time} then the payment will be deducted and the token will be distributed.",
  "ieo_125": "Verified",
  "ieo_126": "Subscribed",
  "ieo_13": "Sleep Future is the world’s first blockchain-driven sleep wellness ecosystem to improve the sleep quality and health of the global community.Sleep Future introduces the world’s first Sleep-To-Earn mechanism to reward world-wide community to sleep and earn $SLEEPEE tokens with the dual objective of innovating sleep technology R&D and a real-world utility in the Sleep Future ecosystem.",
  "ieo_131": "IEO Refund",
  "ieo_132": "IEO Token Distribution",
  "ieo_14": "1. The minimum order amount for a single account is 1,000 SLEEPEE",
  "ieo_15": "2. The maximum order amount for a single account is 50,000 SLEEPEE",
  "ieo_16": "3. There is no handling fee for the transaction",
  "ieo_17": "4. KYC is NOT required for the SLEEPEE subscription.",
  "ieo_18": "5. The subscription will stop when reaching the hard cap or reaching the end time. First come, first served.",
  "ieo_19": "About SLEEPEE",
  "ieo_2": "Bullshit-Inu was created for the purpose of building a global Marketplace.",
  "ieo_20": "SLEEPEE Token Sale",
  "ieo_200": "Subscribed share exceeded",
  "ieo_201": "Insufficient balance",
  "ieo_21": "Followed by 10% monthly over 9 Months.",
  "ieo_22": "The Ecoinomy coin (ECM) is a utility token with a limit of 1,000,000,000 units. ECM will be created, never to be increased. ECM is a SaaS company which will provide the blockchain-based data infrastructure to power the global organic medicine industry. ECM will eventually be used as a currency in the global organic medicine market and will offer its members access to exclusive clubs and rewards via its NFT programs",
  "ieo_23": "1. The minimum order amount for a single account is 100 ECM",
  "ieo_24": "2. The maximum order amount for a single account is 500,000 ECM",
  "ieo_25": "3. There is no handling fee for the transaction.",
  "ieo_26": "4. KYC is NOT required for the ECM subscription.",
  "ieo_27": "5.The subscription will stop when reaching the hard cap or reaching the end time. First come, first served.",
  "ieo_28": "IMPORTANT NOTICE",
  "ieo_29": "About ECM",
  "ieo_3": "BULL Token Sale",
  "ieo_30": "The First 10% ECM tokens will release around September 24th, 2022, followed by 10% monthly over the next 9 months.",
  "ieo_300": "<Turbo Service Agreement>",
  "ieo_31": "The Ecoinomy coin (ECM) is a utility token with a limit of 1,000,000,000 units. ECM will be created, never to be increased. ECM is a SaaS company which will provide the blockchain-based data infrastructure to power the global organic medicine industry. ECM will eventually be used as a currency in the global organic medicine market and will offer its members access to exclusive clubs and rewards via its NFT programs",
  "ieo_32": "ECMToken Sale",
  "ieo_33": "2022/05/25 16:00 (GMT+8)",
  "ieo_34": "2022년 6월 24일 (금) 오후 5시",
  "ieo_35": "2022/05/25 16:00 (GMT+8) - 2022/06/24 16:00 (GMT+8)",
  "ieo_350": "Subscribed",
  "ieo_352": "subscription",
  "ieo_353": "Important Notice",
  "ieo_354": "CUC Coin Sale",
  "ieo_355": "IEO Subscribed",
  "ieo_356": "4. KYC is required for the CUC subscription.",
  "ieo_357": "Cuprum Coin is a commodity cryptocurrency, ie. altcoin built on the Tezos platform, classified as a utility coin. As a guarantee of value, it has behind it an estimated $60 billion very rare ultrafine copper powder that is primarily used in the aerospace industry and medicine. A complete 20 plus tons with all EU certificates are stored in high security facilities in Germany.",
  "ieo_358": "Project Introduction",
  "ieo_359": "Subscription Details",
  "ieo_36": "2022/06/24 16:00 (GMT+8)",
  "ieo_37": "WealthSecrets is a tech and innovative company geared towards developing and creating fun tech products that not only directly/indirectly solve problems but aid its patrons generate wealth.",
  "ieo_38": "2022/06/13 15:30 (GMT+8)",
  "ieo_39": "2022/06/27 15:00 (GMT+8)",
  "ieo_4": "About BULL",
  "ieo_40": "Gays-Inu looks to build a safe place for people to share their experiences without judgment, ensure freedom & spread love, diversity, individuality, and sexuality throughout the community and the world! Creating a better support network for LGBT+ youth groups and young people.",
  "ieo_41": "2022/06/10 15:00 (GMT+8)",
  "ieo_42": "2022/06/20 15:00 (GMT+8)",
  "ieo_43": "WealthSecrets is a Tech and Innovative company geared towards developing and creating fun tech products that not only directly/indirectly solve problems but aid its patrons generate wealth.",
  "ieo_44": "1. The minimum order amount for a single account is 370,830 WSC",
  "ieo_45": "2. The maximum order amount for a single account is 556,245,712 WSC",
  "ieo_46": "3. There is no handling fee for the transaction.",
  "ieo_47": "4. KYC is NOT required for the WSC subscription.",
  "ieo_48": "5.The subscription will stop when reaching the hard cap or reaching the end time. First come, first served.",
  "ieo_49": "About WSC",
  "ieo_5": "Start in",
  "ieo_50": "WSC Token Sale",
  "ieo_500": "Turbo Allocation",
  "ieo_501": "Subscription Pair",
  "ieo_502": "Subscription Price",
  "ieo_503": "Token Sale Period",
  "ieo_504": "Subscription Bonus",
  "ieo_505": "Circulating Supply",
  "ieo_506": "20% Tokens will be released at the end of IEO period, the remaining tokens will be released on a weekly basis at 5% per week.",
  "ieo_507": "subscription",
  "ieo_508": "Hide Other Pairs",
  "ieo_509": "Monthly unlock linear vesting over 24 months",
  "ieo_51": "No Lock-up",
  "ieo_510": "20% Tokens will be released at the end of IEO period, the remaining tokens will be released on a weekly basis at 10% per week.",
  "ieo_511": "50% Tokens will be released at the end of IEO period, the remaining tokens will be released on a weekly basis at 10% per week.",
  "ieo_52": "1. The minimum order amount for a single account is 10 USDT",
  "ieo_53": "2. The maximum order amount for a single account is 2000 USDT",
  "ieo_54": "3. There is no handling fee for the transaction.",
  "ieo_55": "4. KYC is NOT required for the LGBTQ subscription.",
  "ieo_56": "5.The subscription will stop when reaching the hard cap or reaching the end time. First come, first served.",
  "ieo_57": "About LGBTQ",
  "ieo_58": "LGBTQ Token Sale",
  "ieo_59": "Gays-Inu looks to build a safe place for people to share their experiences without judgment, ensure freedom & spread love, diversity, individuality, and sexuality throughout the community and the world! Creating a better support network for LGBT+ youth groups and young people.",
  "ieo_6": "1. The minimum order amount for a single account is 50 USDT",
  "ieo_60": "{coin} now is under the stage of the Initial Exchange Offerings. User can only buy {coin} at a fixed price, the sell function is not available yet.",
  "ieo_61": "Risk Warning: Cryptocurrency trading involves significant risk. Please make sure that you have fully understood the risk involved and took it into consideration.",
  "ieo_62": "2022/06/27 16:00 (GMT+8)",
  "ieo_63": "2022/07/11 16:00 (GMT+8)",
  "ieo_64": "MBD Financials: Photorealistic, Decentralized Metaverse Business District. A Decentralized District That Empowers Individuals To Create Their Own Financial Eco System.",
  "ieo_65": "1. The minimum order amount for a single account is 100 USDT",
  "ieo_66": "2. The maximum order amount for a single account is 5000 USDT",
  "ieo_67": "3. There is no handling fee for the transaction.",
  "ieo_68": "4. KYC is NOT required for the MBD subscription.",
  "ieo_69": "5.The subscription will stop when reaching the hard cap or reaching the end time. First come, first served.",
  "ieo_7": "2. The maximum order amount for a single account is 1000 USDT",
  "ieo_70": "About MBD",
  "ieo_71": "MBD Financials: Photorealistic, Decentralized Metaverse Business District. A Decentralized District That Empowers Individuals To Create Their Own Financial Eco System.",
  "ieo_72": "MBD Token Sale",
  "ieo_73": "2022/07/04 17:00 (GMT+8)",
  "ieo_74": "2022/07/06 17:00 (GMT+8)",
  "ieo_75": "Crypto assets are rapidly penetrating the world, but from a financial perspective, their prices are still unstable and volatile, and they have not yet to establish a firm position as both an asset and a currency.\nAs a solution to this problem, we have established the Asset Management Technology Token \"AMT\" with the mission of creating a new value by integrating AI and IT into Blockchain. We aim to create an ecosystem that gives real demand to crypto assets and brings \"real value\" to all participants.",
  "ieo_76": "2022/07/22 17:00 (GMT+8)",
  "ieo_77": "2022/08/05 17:00 (GMT+8)",
  "ieo_78": "1. The minimum order amount for a single account is 10,000 AMT",
  "ieo_79": "2. The maximum order amount for a single account is 400,000 AMT",
  "ieo_8": "Time to trading",
  "ieo_80": "3. There is no handling fee for the transaction.",
  "ieo_81": "4. KYC is NOT required for the AMT subscription.",
  "ieo_82": "5.The subscription will stop when reaching the hard cap or reaching the end time. First come, first served.",
  "ieo_83": "AMT Token Sale",
  "ieo_84": "About AMT",
  "ieo_85": "Raised",
  "ieo_86": "Goal",
  "ieo_87": "Latest Listed",
  "ieo_88": "Turbo Verified",
  "ieo_89": "Initial",
  "ieo_9": "4. KYC is NOT required for the BULL subscription.",
  "ieo_90": "Highest Return",
  "ieo_91": "Social Channels",
  "ieo_92": "Price per share",
  "ieo_93": "Qty. Per share",
  "ieo_94": "Max. Subscribe share",
  "ieo_95": "Subscribed share",
  "ieo_96": "1. Confirmed share will take the actual raised amount as final.",
  "ieo_97": "2. The transaction will be confirmed before 2022/5/20 at 18:00:00 then the payment will be deducted and the token will be distributed.",
  "ieo_98": "Coming Soon...",
  "ieo_99": "2022/08/08 15:00 (GMT+8)",
  "ieo_lock": "1 month",
  "iKnow": "Got it",
  "iou_1": "What is an IOU?",
  "iou_10": "24h Change",
  "iou_11": "https://support.bitforex.com/hc/en-us/articles/360030607692",
  "iou_2": "BitForex will be bringing some of the hottest projects in the blockchain space, such as GRAM and DOT to a new IOU section. IOU stands for Initial Offerings",
  "iou_3": "Why open an IOU?",
  "iou_4": "The projects selected as IOUs are up-and-coming tokens with great technical and market potential, and well-established communities with thousands of fans. Through price fluctuations, IOU products with credit endorsements and physical guarantees can provide users with more choices. IOU on BitForex also allows participants to borrow and return tokens at will, providing users with greater freedom and better protection of their rights and interests.",
  "iou_5": "Coming soon",
  "iou_6": "{day} day",
  "iou_7": "FIL IOU announcement",
  "iou_8": "Open Price",
  "iou_9": "Latest Price",
  "joinUs_1": "Join Us",
  "joinUs_10": "4. Investigate new business opportunities",
  "joinUs_11": "5. Track market dynamics and competing products, summarize and analyze the market expansion effect, and provide decision-making basis to the company's senior management.",
  "joinUs_12": "1. Good personal image",
  "joinUs_13": "2. All/Any Gender",
  "joinUs_13_1": "3. Multi-Linguistic individuals prefered. (language Preference: English, Japanese or Korean) Chinese language skills or other language is always a plus",
  "joinUs_13_2": "4. 3+ years experience in Blockchain，Crypto currency or other financial business development",
  "joinUs_13_3": "5. Strong interest and knowledge in cryptocurrencies/blockchain",
  "joinUs_13_4": "6. Excellent negotiation skills",
  "joinUs_13_5": "7. Prior experience working in a startup environment highly preferred",
  "joinUs_13_6": "8. Strong sense of self driven and entrepreneurship",
  "joinUs_13_7": "9. Ability to work independently, and possess strong organizational, time management and communication skills",
  "joinUs_15": "Risk and Internal Controls Manager",
  "joinUs_16": "1. Leading business wide engagement and support for ongoing internal controls. Responsible for risk management in the operation process;",
  "joinUs_17": "2. Analyze the user's trading behavior, account status, identify risks, and design effective risk management measures;",
  "joinUs_18": "3. Track, monitor, manage key data indicators, analyze and optimize existing control solutions, and enhance user experience;",
  "joinUs_19": "4. Evaluate new projects, new users, user transactions and transfers, analyze and identify potential risks and design and implement risk management;",
  "joinUs_2": "Together we create, build and make a difference.",
  "joinUs_20": "5. Development and delivery of coaching and training materials for each department and regularly report risk information;",
  "joinUs_21": "6. Further development of Enterprise Risk management process. Complete other related work.",
  "joinUs_23": "1. Full-time undergraduate degree or above, major in finance, economics, law, computer, etc.;",
  "joinUs_24": "2. 1-3 years of work experience, financial institution risk management, blockchain industry, digital cryptocurrency field experience is preferred;",
  "joinUs_25": "3. Familiar with relevant laws, regulations, and regulatory policies, familiar with digital cryptocurrency features, operational methods, and risk management tools;",
  "joinUs_26": "4.Have strong logical thinking ability and analytical ability, good at independent thinking and thoughtful thinking;",
  "joinUs_27": "5.Responsible, good at communication and teamwork, full of work enthusiasm, and strong project promotion ability.",
  "joinUs_28": "Operations Specialist",
  "joinUs_29": "Operations Specialist (English, Korean, Japanese, Russian, Vietnamese)",
  "joinUs_3": "Current Openings",
  "joinUs_30": "1. Develop community-level goals and plans, and be responsible for building and managing users' overseas communities (including but not limited to Facebook, BitcoinTalk, Twitter, Telegram, Medium, etc.) to serve and achieve users management and operations purpose;",
  "joinUs_31": "2.Collect user needs and promote product, technology, and operational improvements;",
  "joinUs_32": "3.Solve problems for users.",
  "joinUs_34": "1. Bachelor degree or above; Overseas study or work experience is preferred;",
  "joinUs_35": "2. 1-3 years of experience in operating the Internet community; having more than 2 years of work experience in mainstream financial media or blockchain, or digital asset vertical media is preferred;",
  "joinUs_36": "3.Familiar with overseas social media platforms such as Facebook, twitter, telegram, and medium;",
  "joinUs_37": "4.Have a certain understanding of the blockchain industry;",
  "joinUs_38": "5.Expertise in English and Chinese is required. Expertise in Korean, Japanese or Russian is required for corresponding operation specialist positions.",
  "joinUs_4": "Responsibilities and Duties:",
  "joinUs_40": "Platform Operations Specialist",
  "joinUs_41": "1. Responsible for the analysis of the daily data of the platform;",
  "joinUs_42": "2. Responsible for the tokens/coins listing work and the docking of the project holders and the business department;",
  "joinUs_43": "3.Assists in completing activities related to various cross-functional departments (for instance: customer service, marketing departments) in support of goals within the department;",
  "joinUs_44": "4.Develops, recommends and implements process improvements to improve efficiencies and services provided Serves as the liaison between departments when it becomes necessary;",
  "joinUs_45": "5.Presents proposed solutions in a clear and concise manner and Investigate problems of an unusual nature in the area of responsibility;",
  "joinUs_46": "6.Additional Position Responsibilities and related duties as assigned by manager.",
  "joinUs_49": "1.\tA Bachelor's Degree in a related field required; Overseas study or work experience is preferred;",
  "joinUs_5": "Qualifications:",
  "joinUs_50": "2. 1-3 years working experience in operations required; More than 2 years of work experience in mainstream financial media, blockchain, or digital asset vertical media is preferred;",
  "joinUs_51": "3.Strong organizational skills and learning and execution ability.",
  "joinUs_53": "Email Us",
  "joinUs_54": "Please send your resume to the email address below:",
  "joinUs_55": "The subject line of your email must be in the following format:",
  "joinUs_56": "Desired position - Available location",
  "joinUs_6": "Business Development Manager",
  "joinUs_7": "1. Develop and execute a project listing strategy",
  "joinUs_8": "2. Develop business development plans, proposals, and agreements.",
  "joinUs_9": "3. Responsible for business negotiation, contracting, management and relationship maintenance with (potential) investors and strategic partners",
  "knights_1": "Join Now",
  "knights_10": "Any BitForex User Can Apply If They",
  "knights_100": "1V1 exclusive VIP customer service",
  "knights_101": "Holiday gift",
  "knights_102": "Become Affiliate",
  "knights_103": "General Referral",
  "knights_104": "Standard commission program for more than 300 trading pairs",
  "knights_105": "<span>30%</span> Perpetual Contract Trading Commission",
  "knights_106": "<span>30%</span> Spot Trading Commission",
  "knights_107": "Daily Settlement",
  "knights_108": "Years operation",
  "knights_109": "Become BitForex Affiliate",
  "knights_11": "Hold at least 20,000 BF tokens in their BitForex wallet",
  "knights_110": "Up to 70% commission on trading fees.",
  "knights_111": "BitForex UID",
  "knights_112": "Email address",
  "knights_113": "Country",
  "knights_114": "I am a",
  "knights_115": "Social Media Influencer",
  "knights_116": "Content Creator",
  "knights_117": "Professional Affiliate Marketer",
  "knights_118": "Trader with Trading Community",
  "knights_119": "Developer / Trading Tool",
  "knights_12": "Are enthusiastic and experienced in the world of cryptocurrency",
  "knights_120": "Others",
  "knights_121": "How do you plan to promote BitForex?",
  "knights_122": "Your Details",
  "knights_123": "Apply Now",
  "knights_124": "Most popular",
  "knights_13": "Applications are open to any BitForex user",
  "knights_14": "BitForex Knights Program",
  "knights_15": "Spot ",
  "knights_16": "Level 1: 30%<br>Level 2: 10%<br>Level 3: 10%<br>",
  "knights_17": "Perpetual:",
  "knights_18": "Apply Now!",
  "knights_19": "General Referral",
  "knights_2": "Introduction",
  "knights_20": "Spot ",
  "knights_21": "Level 1: 30%<br>Level 2: 10%<br>Level 3: 10%<br>",
  "knights_22": "Perpetual: 0%",
  "knights_23": "REGISTER",
  "knights_24": "What are Level 1/2/3 friends?",
  "knights_25": "Level 1: Users who register directly through your invitation link<br>Level 2: Users who register through your Level 1 friends<br>Level 3: Users who register Through your Level 2 friends<br>",
  "knights_26": "Example",
  "knights_27": "Your Current Commission:",
  "knights_28": "LEVEL-1 friends commission",
  "knights_29": "LEVEL-2 friends commission",
  "knights_3": "Welcome to the BitForex Knights Program. Harness your enthusiasm for cryptocurrency and modern financial trading — invite users, show them the ropes, and earn rewards for introducing them to the future of personal digital finance services right here on BitForex.<br>Invite your friends to register on BitForex by sharing your exclusive invitation link. Every time a friend completes a transaction, you will get a portion of their transaction fee in commission.",
  "knights_30": "LEVEL-3 friends commission",
  "knights_31": "Apply Now!",
  "knights_32": "If you’d like to register for BitForex Knighthood, please fill the form below, and we will get back to you if you meet the criteria.",
  "knights_33": "REGISTER FOR KNIGHTHOOD",
  "knights_34": "Login to view current commission program.",
  "knights_35": "Log In",
  "knights_36": "Applications are open to any BitForex user",
  "knights_37": "Login to view current commission program.",
  "knights_38": "with one of the best digital asset exchange",
  "knights_39": "Global Coverage",
  "knights_39_1": "{num} + Countries",
  "knights_4": "Trade in the Moment— Invest in the Future ",
  "knights_40": "Commission Disturbuted",
  "knights_40_1": "{num} + BTC",
  "knights_41": "Become BitForex Partner",
  "knights_42": "My Referral",
  "knights_43": "Delicated Partnership Support",
  "knights_44": "Attractive Commission Package",
  "knights_45": "Enjoy 30% on spot transaction plus up to 30% on perpetual transaction",
  "knights_46": "Data Transparency",
  "knights_47": "Real time report with detailed information on referral data and commission details",
  "knights_48": "Daily Settlement",
  "knights_49": "Commission arrives your account within 24 hours",
  "knights_5": "BitForex Knights Have Exclusive Benefits",
  "knights_50": "Advance Referral Management System",
  "knights_51": "User control panel for detailed analysis for your referral",
  "knights_52": "Choose Your Partnership Schema",
  "knights_53": "Premium rebate schema and tailored commission package to your needs",
  "knights_54": "General Referral",
  "knights_55": "Standard rebate schema for more than 300 trading pairs ",
  "knights_56": "Daily Settlement",
  "knights_57": "30% Spot Trading Commission",
  "knights_58": "Join Us",
  "knights_59": "Affiliate",
  "knights_6": "<strong>Priority participation</strong> in organized live events (fan meetups, etc)",
  "knights_60": "Premium rebate schema and tailored commission package to your needs",
  "knights_61": "Daily Settlement",
  "knights_62": "Up to 30% Spot Trading Commission",
  "knights_63": "Extra 30% Perpetual Contract Trading Commission",
  "knights_64": "Dedicated Partnership Support",
  "knights_65": "Become BitForex Knight",
  "knights_66": "Commission Structure",
  "knights_67": "Rules and Regulations",
  "knights_68": "Partner Advantages",
  "knights_69": "Referral System",
  "knights_7": "Enjoy Up To  <strong>30%+30%</strong> on spot and perpetual transaction fees respectively.",
  "knights_70": "Upgrade",
  "knights_71": "Knighthood",
  "knights_72": "Become BtiForex Knighthood",
  "knights_73": "Earn 30% + 30% Commission",
  "knights_74": "You need to answer(*Required)",
  "knights_75": "Submit",
  "knights_76": "Waiting for verification, pleae wait...",
  "knights_77": "Verification Failed",
  "knights_78": "Please wait for the audit",
  "knights_79": "You have become a knight",
  "knights_8": "<strong>Advanced user-management</strong> control panel",
  "knights_80": "BitForex<br>Affiliate Program",
  "knights_81": "APPLY now!",
  "knights_82": "Affiliate data dashboard",
  "knights_83": "Our Benefits and Services",
  "knights_84": "Daily commissions of up to 70% of trading fees, and permanent affiliate relationships.",
  "knights_85": "Customized Marketing Campaigns",
  "knights_86": "More than 10 types of customized marketing campaigns to incentive your community.",
  "knights_87": "1-to-1 Professional VIP Account Manager",
  "knights_88": "24*7 Multilingual VIP customer support",
  "knights_89": "Fast Withdrawal Channel For Affiliate",
  "knights_9": "Build your own <strong>community</strong> and communicate with your referrals",
  "knights_90": "Daily settlement, no limit for affiliate withdrawal",
  "knights_91": "Apply to Become Affiliate",
  "knights_92": "BitForex offers a tailored commission programs for affiliates",
  "knights_93": "<span>70%</span> USDT-M Perpetual Contract Trading Commission",
  "knights_94": "60% commission for main-affiliate",
  "knights_95": "10% commission for sub-affiliate",
  "knights_96": "<span>30%</span> Spot Trading Commission",
  "knights_97": "Daily Settlement",
  "knights_98": "Affiliate data dashboard",
  "knights_99": "Bonus and exclusive promotion support",
  "leftNav_1": "Asset Management",
  "leftNav_2": "Account settings",
  "leftNav_3": "Platform Welfare",
  "level_tip": "You have reached level VIP{n}",
  "leverage_1": "Change Leverage",
  "leverage_2": "Max Cont. for Current Margin",
  "leverage_3": "Margin Required for Current Position",
  "leverage_4": "Leverage",
  "leverage_5": "{amount}{coin} will be frozen after the leverage is adjusted.",
  "leverage_6": "Frozen amount {amount}{coin} will be released after the adjustment.",
  "leverage_7": "Your current leverage is high, please be aware of the risks.",
  "leverage_8": "Invalid Balance",
  "leverage_9": "The new leverage will be used for all positions and orders of the current contract. Your available balance and liquidation price will change. Please be aware to the risk.",
  "limit_buyLoss": "Stop Loss Limit",
  "limit_buyProfit": "Take Profit Limit",
  "limit_ioc": "Immediate or cancel orders must be executed immediately. Any portion of the order that cannot be executed immediately will be canceled.",
  "limit_limitTip": "Your stop order might not be triggered due to extreme market conditions, price or position limits, insufficient balance, or suspension of tradings.<br/> A stop order becomes a limit order after it is triggered. Unfilled limit and market orders can be found in Open orders.",
  "limit_marketBuy": "Market Buy",
  "limit_marketOrderTip": "Market order is immediate buying or selling at the best available price in market.<br/>If the execution price of the market order deviates beyond the 10% price range from the last latest price, the exceeding part of your market order will be canceled.<br/>Please confirm the market depth before placing a market order.",
  "limit_marketSell": "Market Sell",
  "limit_marketTip": "Market order is immediate buying or selling at the best available price in market.\n\nIn order to avoid extreme market conditions and protect users, BitForex has implemented a price protection mechanism for the spot market.\n\nIf the execution price of the market order deviates beyond the 10% price range from the last latest price, the exceeding part of your market order will be canceled.\n\nPlease confirm the market depth before placing a market order.",
  "limit_notTriggered": "Not Triggered",
  "limit_post": "post-only orders are guaranteed to be the maker order when executed, If a post only order is matched with an existing order, this order will be canceled.",
  "limit_sellLoss": "Stop Loss Limit",
  "limit_sellProfit": "Take Profit Limit",
  "limit_stopLimit": "Stop-Limit",
  "limit_trigger": "When placing a stop-limit order, if the trigger price you set = the latest market price, your stop-limit order will be converted to a limit order automatically.",
  "limit_triggered": "Triggered",
  "limit_triggerNotAbove": "Trigger price cannot above {price}",
  "limit_triggerNotBelow": "Trigger price cannot below {price}",
  "limit_triggerTip": "When placing a stop-limit order, if the trigger price you set = the latest market price, your stop-limit order will be converted to a limit order automatically.",
  "link_aboutus": "https://event.bitforex.com/en/AboutUs.html",
  "link_api": "https://apidoc.bitforex.com",
  "link_bindgoogle": "https://support.bitforex.com/hc/en-us/articles/360006825412",
  "link_blog": "https://blog.bitforex.com",
  "link_contactus": "https://event.bitforex.com/en/ContactUs.html",
  "link_discord": "https://discord.gg/nDDrwJWYUX",
  "link_facebook": "https://www.facebook.com/bitforexcom",
  "link_help": "https://support.bitforex.com/hc/en-us/",
  "link_instagram": "https://www.instagram.com/bitforex_official/",
  "link_joinus": "https://event.bitforex.com/en/JoinUs.html",
  "link_linkedin": "https://www.linkedin.com/company/bitforexexchange/",
  "link_media": "https://event.bitforex.com/en/Media.html",
  "link_mercuryo": "https://support.bitforex.com/hc/en-us/articles/360000887871",
  "link_mt5event": "https://support.bitforex.com/hc/en-us/sections/360002130471",
  "link_mt5FAQ": "https://support.bitforex.com/hc/en-us/sections/360007616331",
  "link_request": "https://support.bitforex.com/hc/en-us/requests/new",
  "link_requestGoogle": "https://support.bitforex.com/hc/en-us/requests/new?ticket_form_id=360000241532",
  "link_telegram": "https://t.me/BitForexEnglish",
  "link_terms": "https://event.bitforex.com/en/Terms.html",
  "link_twitter": "https://twitter.com/bitforexcom",
  "link_youtube": "https://www.youtube.com/channel/UCg2lgc1Cjw1t-pSpDN4DJGw",
  "loanss_1": "Crypto Loans",
  "loanss_10": "Loan Term",
  "loanss_100": "Order Number",
  "loanss_101": "Adjust time",
  "loanss_102": "Adjust direction",
  "loanss_103": "Adjust amount",
  "loanss_104": "LTV before adjustment",
  "loanss_105": "LTV after adjustment",
  "loanss_106": "Coin Borrowed",
  "loanss_107": "Add",
  "loanss_108": "Reduce",
  "loanss_109": "Success",
  "loanss_11": "Initial LTV (Loan-to-value Ratio)",
  "loanss_110": "Fail",
  "loanss_111": "Search",
  "loanss_112": "Reset",
  "loanss_113": "Liquidation Time",
  "loanss_114": "Liquidation LTV",
  "loanss_115": "Liquidation History",
  "loanss_116": "Collateral Coin",
  "loanss_117": "Liquidation amount",
  "loanss_118": "Insurance Clear Fee",
  "loanss_119": "Coin Borrowed",
  "loanss_12": "Initial LTV",
  "loanss_120": "Total debt",
  "loanss_122": "Loan Term",
  "loanss_123": "Interest",
  "loanss_124": "Log in to borrow coins",
  "loanss_125": "Coin-borrowing History",
  "loanss_126": "LTV Adjustment History",
  "loanss_127": "Repayment History",
  "loanss_128": "Completed",
  "loanss_129": "Overdue",
  "loanss_13": "Liquidation LTV",
  "loanss_130": "Liquidated",
  "loanss_131": "LTV",
  "loanss_132": "Repaid",
  "loanss_133": "Daily Interest Rate",
  "loanss_134": "Asset insufficient",
  "loanss_135": "Crypto Loans History",
  "loanss_136": "Current Liabilities",
  "loanss_137": "Insufficient available balance in the wallet, current balance:",
  "loanss_138": "Lending",
  "loanss_139": "Borrowing",
  "loanss_14": "Total Interest Amount",
  "loanss_140": "Time left before forced liquidation:",
  "loanss_141": "Crypto loan",
  "loanss_142": "Deduct collateral upon crypto loan",
  "loanss_143": "Crypto loan repayment",
  "loanss_144": "Return collateral after crypto loan",
  "loanss_145": "Crypto loan Interest",
  "loanss_146": "Collateral Return",
  "loanss_147": "Crypto Loans",
  "loanss_148": "Principal Repayment",
  "loanss_149": "Interest Repayment",
  "loanss_15": "Repayment Amount",
  "loanss_151": "Search",
  "loanss_152": "Days",
  "loanss_153": "LTV = Loan Value/Value of Collateral Assets",
  "loanss_154": "Initial LTV",
  "loanss_155": "When the Value of Loan Assets + Accumulated Interest/Value of Collateral Assets ≥ {ltv}, the system will deduct your collateral assets to repay the loan, please top up your collateral loan to avoid liquidation",
  "loanss_156": "The interest starts from the successful borrowing of currency and is calculated once an hour.",
  "loanss_157": "Frequently Asked Questions",
  "loanss_158": "Q1、Who can borrow on BitForex Loans?",
  "loanss_159": "A1、As long as you are a registered user of BitForex, you can borrow on BitForex Loan.",
  "loanss_16": "Hourly & Daily Interest Rate",
  "loanss_160": "Q2、What crypto can I borrow on BitForex Loans?",
  "loanss_161": "A2、BitForex Loans supports the borrowing of several cryptos, including USDT, BTC, etc. You can check out the Loan Data page for more information.",
  "loanss_162": "Q3、What crypto can I use as collateral?",
  "loanss_163": "A3、BitForex Loans supports several cryptos as collateral, including ADA, FIL, OMI, JASMY, etc. You can check out the Loan Data page for more information.",
  "loanss_164": "Q4、How long are the Loan Terms?",
  "loanss_165": "A4、Loan terms of 7, 14, 30, 90 and 180 days are available. You can always repay in advance and the interest is calculated based on the hours borrowed.",
  "loanss_166": "Q5、How is the interest calculated?",
  "loanss_167": "A5、Interest is calculated hourly, and less than one hour is calculated as one hour. The interest rate is determined by the time you make the loan.",
  "loanss_168": "Q6、How do I repay the interest and principal?",
  "loanss_169": "A6、You have to repay them manually on the Order page. The interest has to be repaid before the principal.",
  "loanss_17": "Create a loan request.",
  "loanss_170": "Q7、Can I make a full repayment before the due date?",
  "loanss_171": "A7、Yes. There is no interest penalty for early repayment. When the principal is returned, the interest will be charged according to the actual borrowing hours.",
  "loanss_172": "Q8、What is LTV?",
  "loanss_173": "A8、LTV is the value of your loan to the value of your collateral. The price used here is Index Price. Different collateral coins have different initial LTVs, which means when you use different coins as collateral of the same value, the loan you make is of a different value too.",
  "loanss_174": "Q9、What happens when my LTV is too high?",
  "loanss_175": "A9、If your LTV is higher than the margin call LTV, we will inform you to add more collateral or to repay your loan. If your LTV is higher than the liquidation LTV, we will liquidate your collateral to repay your loan.",
  "loanss_176": "Q10、How can I adjust my collateral?",
  "loanss_177": "A10、You can adjust your collateral on the Order page. You can always add more collateral and when your LTV is lower than the initial LTV, you can remove some of your collateral, too.",
  "loanss_178": "Q11、What happens if my Loans repayment is overdue？",
  "loanss_179": "A11、during which you will be charged 3 times the hourly interest. If you do not repay after the overdue duration, we will liquidate your collateral to repay your loan.",
  "loanss_18": "Collateral transferred from Spot Account & Receive Loan in Spot Account.",
  "loanss_180": "Q12、Can I use the crypto I borrow to trade in Margin and Futures? Can I withdraw them?",
  "loanss_181": "A12、Yes, you can trade on BitForex using the loan you make as long as you meet the qualification for using the services. Yes, you can withdraw them as well.",
  "loanss_182": "https://test112.bitforex.com/en/loanTerms",
  "loanss_183": "Collateral Coin Price",
  "loanss_184": "Price of Coin Borrowed",
  "loanss_185": "Loan Bills",
  "loanss_186": "https://www.bitforex.com/en/loanTerms",
  "loanss_187": "There are currently insufficient loanable assets.",
  "loanss_188": "Tiered APY Description",
  "loanss_189": "Tier",
  "loanss_19": "Repay at any time.",
  "loanss_190": "Tiered APY",
  "loanss_2": "Borrow",
  "loanss_20": "Receive collateral in Spot Account.",
  "loanss_21": "Start Borrowing Now",
  "loanss_22": "7 Days",
  "loanss_23": "Order Confirmation",
  "loanss_24": "Loan Amount",
  "loanss_25": "Collateral Amount",
  "loanss_26": "Loan Term",
  "loanss_27": "Hourly Interest Rate",
  "loanss_28": "Total Interest Amount",
  "loanss_29": "Expiration Time",
  "loanss_3": "Ongoing Orders",
  "loanss_30": "Repayment Amount",
  "loanss_31": "I have read and agreed to the service agreement",
  "loanss_32": "Confirm",
  "loanss_33": "Total Debt (AKRO)",
  "loanss_34": "LTV",
  "loanss_35": "Residual Interest (AKRO)",
  "loanss_36": "Collateral Amount (USDT)",
  "loanss_37": "Remaining Principal (AKRO)",
  "loanss_38": "Expiration Time (loan term)",
  "loanss_39": "Repay",
  "loanss_4": "Asset Overview",
  "loanss_40": "Adjust LTV",
  "loanss_41": "Repayment Amount",
  "loanss_42": "Total Debt",
  "loanss_43": "Your free asset",
  "loanss_44": "Interest Repaid",
  "loanss_45": "Principal Repaid",
  "loanss_46": "Total Repayment",
  "loanss_47": "LTV after Repayment",
  "loanss_48": "Returned Collateral Amount",
  "loanss_49": "Confirm repayment",
  "loanss_5": "I want to borrow",
  "loanss_50": "Adjust LTV",
  "loanss_51": "Amount Borrowed",
  "loanss_52": "Collateral Amount",
  "loanss_53": "Initial LTV",
  "loanss_54": "Low risk",
  "loanss_55": "Liquidation LTV",
  "loanss_56": "High risk",
  "loanss_57": "Collateral Amount",
  "loanss_58": "Add",
  "loanss_59": "It is recommended that you add to the initial LTV to reduce the risk of liquidation.",
  "loanss_6": "Collateral Amount",
  "loanss_60": "Remove collateral",
  "loanss_61": "Add collateral",
  "loanss_62": "Remove",
  "loanss_63": "Loan Orders",
  "loanss_64": "Orders ID",
  "loanss_65": "Reset",
  "loanss_66": "Coin Borrowed",
  "loanss_67": "Status",
  "loanss_68": "All",
  "loanss_69": "Date Borrowed\nExpiration Time (loan term)",
  "loanss_7": "Orders",
  "loanss_70": "Accruing interest",
  "loanss_71": "Coin Borrowed",
  "loanss_72": "Remaining Principal",
  "loanss_73": "Hourly Interest",
  "loanss_74": "Total Debt",
  "loanss_75": "Residual Interest",
  "loanss_76": "Accrued Interest Period",
  "loanss_77": "Collateral Coin",
  "loanss_78": "Collateral Amount",
  "loanss_79": "LTV",
  "loanss_8": "History",
  "loanss_80": "Adjust LTV",
  "loanss_81": "Repaid",
  "loanss_83": "Total Debt Amount",
  "loanss_84": "Total Collateral Amount",
  "loanss_85": "Debt Assets",
  "loanss_86": "Collateral Assets",
  "loanss_87": "Asset",
  "loanss_88": "Amount",
  "loanss_89": "Equity Vaule (USDT)",
  "loanss_9": "No interest penalty for early repayment",
  "loanss_90": "You do not currently have a loan.",
  "loanss_91": "Order ID",
  "loanss_92": "Expiration Time (loan term)",
  "loanss_93": "Coin Borrowed",
  "loanss_94": "Total Payment",
  "loanss_95": "Remaining Principal",
  "loanss_96": "Residual Interest",
  "loanss_97": "Collateral Coin",
  "loanss_98": "Collateral Return",
  "loanss_99": "Date Borrowed",
  "loans_1": "Crypto Loans",
  "loans_10": "Loan Term",
  "loans_100": "Order Number",
  "loans_101": "Adjust time",
  "loans_102": "Adjust direction",
  "loans_103": "Adjust amount",
  "loans_104": "LTV before adjustment",
  "loans_105": "LTV after adjustment",
  "loans_106": "Coin Borrowed",
  "loans_107": "Add",
  "loans_108": "Reduce",
  "loans_109": "Success",
  "loans_11": "Initial LTV (Loan-to-value Ratio)",
  "loans_110": "Fail",
  "loans_111": "Search",
  "loans_112": "Reset",
  "loans_113": "Liquidation Time",
  "loans_114": "Liquidation LTV",
  "loans_115": "Liquidation History",
  "loans_116": "Collateral Coin",
  "loans_117": "Liquidation amount",
  "loans_118": "Insurance Clear Fee",
  "loans_119": "Coin Borrowed",
  "loans_12": "Initial LTV 65%",
  "loans_120": "Total debt",
  "loans_121": "Repay",
  "loans_13": "Liquidation LTV 83%",
  "loans_14": "Total Interest Amount",
  "loans_15": "Repayment Amount",
  "loans_16": "Hourly & Daily Interest Rate",
  "loans_17": "Create a loan request.",
  "loans_18": "Collateral transferred from Spot Account & Receive Loan in Spot Account.",
  "loans_19": "Repay at any time.",
  "loans_2": "Borrow",
  "loans_20": "Receive collateral in Spot Account",
  "loans_21": "Start Borrowing Now",
  "loans_22": "7 Days",
  "loans_23": "Order Confirmation",
  "loans_24": "Loan Amount",
  "loans_25": "Collateral Amount",
  "loans_26": "Loan Term",
  "loans_27": "Hourly Interest Rate",
  "loans_28": "Total Interest Amount",
  "loans_29": "Expiration Time",
  "loans_3": "Ongoing Orders",
  "loans_30": "Repayment Amount",
  "loans_31": "I have read and agreed to the service agreement",
  "loans_32": "Confirm",
  "loans_33": "Total Debt (AKRO)",
  "loans_34": "LTV",
  "loans_35": "Residual Interest (AKRO)",
  "loans_36": "Collateral Amount (USDT)",
  "loans_37": "Remaining Principal (AKRO)",
  "loans_38": "Expiration Time (loan term)",
  "loans_39": "Repay",
  "loans_4": "Asset Overview",
  "loans_40": "Adjust LTV",
  "loans_41": "Repayment Amount",
  "loans_42": "Total Debt",
  "loans_43": "Your free asset",
  "loans_44": "Interest Repaid",
  "loans_45": "Principal Repaid",
  "loans_46": "Total Repayment",
  "loans_47": "LTV after Repayment",
  "loans_48": "Returned Collateral Amount",
  "loans_49": "Confirm repayment",
  "loans_5": "I want to borrow",
  "loans_50": "Adjust LTV",
  "loans_51": "Amount Borrowed",
  "loans_52": "Collateral Amount",
  "loans_53": "Initial LTV",
  "loans_54": "Low risk",
  "loans_55": "Liquidation LTV",
  "loans_56": "High risk",
  "loans_57": "Collateral Amount",
  "loans_58": "Add",
  "loans_59": "It is recommended that you add to the initial LTV to reduce the risk of liquidation.",
  "loans_6": "Collateral Amount",
  "loans_60": "Remove collateral",
  "loans_61": "Add collateral",
  "loans_62": "Remove",
  "loans_63": "Loan Orders",
  "loans_64": "Orders ID",
  "loans_65": "Reset",
  "loans_66": "Coin Borrowed",
  "loans_67": "Status",
  "loans_68": "All",
  "loans_69": "Date Borrowed\nExpiration Time (loan term)",
  "loans_7": "Orders",
  "loans_70": "Accruing interest",
  "loans_71": "Coin Borrowed",
  "loans_72": "Remaining Principal",
  "loans_73": "Hourly Interest",
  "loans_74": "Total Debt",
  "loans_75": "Residual Interest",
  "loans_76": "Accrued Interest Period",
  "loans_77": "Collateral Coin",
  "loans_78": "Collateral Amount",
  "loans_79": "LTV\nRepay",
  "loans_8": "History",
  "loans_80": "Adjust LTV",
  "loans_81": "Repaid",
  "loans_83": "Total Debt Amount",
  "loans_84": "Total Collateral Amount",
  "loans_85": "Debt Assets",
  "loans_86": "Collateral Assets",
  "loans_87": "Asset",
  "loans_88": "Amount",
  "loans_89": "Equity Vaule (USD)",
  "loans_9": "No interest penalty for early repayment",
  "loans_90": "You do not currently have a loan.",
  "loans_91": "Order ID",
  "loans_92": "Expiration Time (loan term)",
  "loans_93": "Coin Borrowed",
  "loans_94": "Total Payment",
  "loans_95": "Remaining Principal",
  "loans_96": "Residual Interest",
  "loans_97": "Collateral Coin",
  "loans_98": "Collateral Return",
  "loans_99": "Date Borrowed",
  "loan_1": "Crypto Loans",
  "loan_10": "Loan Term",
  "loan_100": "Order Number",
  "loan_101": "Adjust time",
  "loan_102": "Adjust direction",
  "loan_103": "Adjust amount",
  "loan_104": "LTV before adjustment",
  "loan_105": "LTV after adjustment",
  "loan_106": "Coin Borrowed",
  "loan_107": "Add",
  "loan_108": "Reduce",
  "loan_109": "Success",
  "loan_11": "Initial LTV (Loan-to-value Ratio)",
  "loan_110": "Fail",
  "loan_111": "Search",
  "loan_112": "Reset",
  "loan_113": "Liquidation Time",
  "loan_114": "Liquidation LTV",
  "loan_115": "Liquidation History",
  "loan_116": "Collateral Coin",
  "loan_117": "Liquidation amount",
  "loan_118": "Insurance Clear Fee",
  "loan_119": "Coin Borrowed",
  "loan_12": "Initial LTV 65%",
  "loan_120": "Total debt",
  "loan_13": "Liquidation LTV 83%",
  "loan_14": "Total Interest Amount",
  "loan_15": "Repayment Amount",
  "loan_16": "Hourly & Daily Interest Rate",
  "loan_17": "Create a loan request.",
  "loan_179": "A11、during which you will be charged 3 times the hourly interest. If you do not repay after the overdue duration, we will liquidate your collateral to repay your loan.",
  "loan_18": "Collateral transferred from Spot Account & Receive Loan in Spot Account.",
  "loan_19": "Repay at any time.",
  "loan_2": "Borrow",
  "loan_20": "Receive collateral in Spot Account",
  "loan_21": "Start Borrowing Now",
  "loan_22": "7 Days",
  "loan_23": "Order Confirmation",
  "loan_24": "Loan Amount",
  "loan_25": "Collateral Amount",
  "loan_26": "Loan Term",
  "loan_27": "Hourly Interest Rate",
  "loan_28": "Total Interest Amount",
  "loan_29": "Expiration Time",
  "loan_3": "Ongoing Orders",
  "loan_30": "Repayment Amount",
  "loan_31": "I have read and agreed to the service agreement",
  "loan_32": "Confirm",
  "loan_33": "Total Debt (AKRO)",
  "loan_34": "LTV",
  "loan_35": "Residual Interest (AKRO)",
  "loan_36": "Collateral Amount (USDT)",
  "loan_37": "Remaining Principal (AKRO)",
  "loan_38": "Expiration Time (loan term)",
  "loan_39": "Repay",
  "loan_4": "Asset Overview",
  "loan_40": "Adjust LTV",
  "loan_41": "Repayment Amount",
  "loan_42": "Total Debt",
  "loan_43": "Your free asset",
  "loan_44": "Interest Repaid",
  "loan_45": "Principal Repaid",
  "loan_46": "Total Repayment",
  "loan_47": "LTV after Repayment",
  "loan_48": "Returned Collateral Amount",
  "loan_49": "Confirm repayment",
  "loan_5": "I want to borrow",
  "loan_50": "Adjust LTV",
  "loan_51": "Amount Borrowed",
  "loan_52": "Collateral Amount",
  "loan_53": "Initial LTV",
  "loan_54": "Low risk",
  "loan_55": "Liquidation LTV",
  "loan_56": "High risk",
  "loan_57": "Collateral Amount",
  "loan_58": "Add",
  "loan_59": "It is recommended that you add to the initial LTV to reduce the risk of liquidation.",
  "loan_6": "Collateral Amount",
  "loan_60": "Remove collateral",
  "loan_61": "Add collateral",
  "loan_62": "Remove",
  "loan_63": "Loan Orders",
  "loan_64": "Orders ID",
  "loan_65": "Reset",
  "loan_66": "Coin Borrowed",
  "loan_67": "Status",
  "loan_68": "All",
  "loan_69": "Date Borrowed\nExpiration Time (loan term)",
  "loan_7": "Orders",
  "loan_70": "Accruing interest",
  "loan_71": "Coin Borrowed",
  "loan_72": "Remaining Principal",
  "loan_73": "Hourly Interest",
  "loan_74": "Total Debt",
  "loan_75": "Residual Interest",
  "loan_76": "Accrued Interest Period",
  "loan_77": "Collateral Coin",
  "loan_78": "Collateral Amount",
  "loan_79": "LTV\nRepay",
  "loan_8": "History",
  "loan_80": "Adjust LTV",
  "loan_81": "Repaid",
  "loan_83": "Total Debt Amount",
  "loan_84": "Total Collateral Amount",
  "loan_85": "Debt Assets",
  "loan_86": "Collateral Assets",
  "loan_87": "Asset",
  "loan_88": "Amount",
  "loan_89": "Equity Vaule (USD)",
  "loan_9": "No interest penalty for early repayment",
  "loan_90": "You do not currently have a loan.",
  "loan_91": "Order ID",
  "loan_92": "Expiration Time (loan term)",
  "loan_93": "Coin Borrowed",
  "loan_94": "Total Payment",
  "loan_95": "Remaining Principal",
  "loan_96": "Residual Interest",
  "loan_97": "Collateral Coin",
  "loan_98": "Collateral Return",
  "loan_99": "Date Borrowed",
  "loginFirst": "Please login first",
  "look_more": "Learn more",
  "maintenance_1": "BitForex Spot Trading System will be temporarily unavailable due to the system maintenance. The estimated time is 1 hour. Please stay tuned  to the latest news for the detailed resumption time.",
  "maintenance_2": "Other trading options for you:",
  "maintenance_3": "1. <a href=\"/en/perpetual\">BitFore Perpetual Contract Trading</a> with up to X100 leverage",
  "maintenance_4": "2. <a href=\"/en/mt5/\">Bitforex MT5 Trading</a>, trade traditional financial products with crypto currencies.",
  "maintenance_5": "Thank you for you support!",
  "market_1": "Explore NFTs · Touch Future",
  "market_10": "Price",
  "market_100": "The bid does not meet the requirement",
  "market_101": "Are you sure to buy this NFT?",
  "market_102": "Are you sure to buy out this NFT?",
  "market_103": "Starts in",
  "market_104": "Data cannot be empty.",
  "market_105": "Touch Future",
  "market_106": "Explore NFTs",
  "market_107": "to",
  "market_108": "Withdrawal ongoing",
  "market_109": "Transaction fee insufficient",
  "market_11": "Status",
  "market_12": "Gaming",
  "market_13": "Entertainment",
  "market_14": "Esports",
  "market_15": "Collectibles",
  "market_153": "Creator",
  "market_154": "From {number} USDT",
  "market_155": "More",
  "market_156": "Batch Trade",
  "market_157": "Select All",
  "market_158": "<span style=\"color: #14C393 !important;vertical-align: bottom;\">{number}</span> NFTs have been selected",
  "market_159": "Select current page",
  "market_16": "Art",
  "market_160": "Are you sure you want to trade the selected <span style=\"color: #14C393 !important;vertical-align: bottom;\">{number}</span> NFTs?",
  "market_161": "Please select the NFT you want to trade.",
  "market_162": "NFT status error. Please refresh and try again.",
  "market_163": "80 ~ 300 USDT",
  "market_164": "2000",
  "market_165": "CanaBoyz",
  "market_166": "CanaBoyz is the play2earn NFT game about growing and selling virtual cannabis. Mintpass is an NFT card that will allow you to get a random 3D CanaBoyz NFT character belonging to one of the four professions and one of the four races. Owning of a character allows you to enter the CanaBoyz world, join gangs and raise your business together.",
  "market_167": "CanaBoyz is the play2earn NFT game about growing and selling virtual cannabis. Mintpass is an NFT card that will allow you to get a random 3D CanaBoyz NFT character belonging to one of the four professions and one of the four races. Owning of a character allows you to enter the CanaBoyz world, join gangs and raise your business together. \n\nWith the CanaBoyz NFT character you will be able to: \n- grow more than 3 bushes.\n- have a CanaBoyz token staking possibly. The rarer the card, the higher the percentage! \n- access to CanaBoyz closed events in metaverses with gifts and prizes.\n- use it as your avatar in metaverses.\n- Creating your own gangs (for boss characters - golden mintpass only).",
  "market_168": "BSC",
  "market_169": "2022/4/22 18:00 (GMT+8)",
  "market_17": "Others",
  "market_170": "2022/4/24 10:00 (GMT+8)",
  "market_171": "Pre-Sale",
  "market_172": "Distributed",
  "market_173": "Not-Distributed",
  "market_174": "Purchase Records",
  "market_175": "NFT Pre Sale",
  "market_176": "Please enter amount",
  "market_177": "200 USDT",
  "market_178": "250",
  "market_179": "Metapoly",
  "market_18": "Image",
  "market_180": "Metapoly - New decentralised metaverse land-bank gives you exposure to multiverse land investment, with asset backed reserve currency D33D and stablecoin $USM. The vision is to provide liquidity and enable metaverse growth.",
  "market_181": "Polygon",
  "market_182": "2022/4/28 17:00 (GMT+8)",
  "market_183": "2022/4/30 17:00 (GMT+8)",
  "market_184": "Continue buying",
  "market_185": "Failed purchase",
  "market_186": "Purchased {amount} {name}",
  "market_187": "<li> Cryptocurrency trading involves significant risk and may cost your invested capital lost. Please make sure that you fully understand the risk involved and take into consideration your level of experience, and investment objectives, and seek independent financial advice if necessary. </li>\n<li> NFTs are extremely time-sensitive and non-fungible. Therefore, once NFTs are sold, They can not be returned or exchanged. </li>\n<li> Before buying, please read the NFT description carefully. The NFT asset is subject to the above description. BitForex reserves the right to the final explanation for the activity.</li>",
  "market_188": "Purchase Instructions",
  "market_189": "This page can be overloaded, page data display delay can occur, please refresh this page before making an order to ensure the data accuracy.",
  "market_19": "Video",
  "market_190": "1.35BNB",
  "market_191": "500",
  "market_192": "METAMINTS",
  "market_193": "The Metamints Project is an eco-system of services designed for the NFTs Digital Market, powered by Blockchain technology. With this project, users will Buy, sell, and discover exclusive digital items and live new experiences, generating great residual rewards.Metamints will provide buyers and sellers with a robust suite of tools for creating and filling orders for any token. This functionality facilitates the development of third-party apps using Metamints to transfer NFTs to other blockchains, from blockchain-based games on other networks to NFT marketplaces and exchange platforms.",
  "market_194": "BSC",
  "market_195": "2022/5/10 16:00 (GMT+8)",
  "market_196": "2022/5/16 10:00 (GMT+8)",
  "market_197": "Insufficient inventory",
  "market_199": "Provenance",
  "market_2": "NFT Market",
  "market_20": "Audio",
  "market_207": "Purchased",
  "market_208": "Delist NFT",
  "market_209": "List NFT",
  "market_21": "Fixed Price",
  "market_210": "0.1 ETH",
  "market_211": "150",
  "market_212": "CryptoTanks",
  "market_213": "CryptoTanks is a Play and Earn game, created to bring casual gamers in contact with the power of NFTs in a simple yet fun way! With a retro 8-bit design and its funky music, it aims to bring to Blockchain Gaming a truly enjoyable entry, and to users unique ways to earn by destroying their opponents in the Battle Arena and upgrading their NFT tanks in battle and in the garage.",
  "market_214": "ETH",
  "market_215": "2022/06/02 18:00 (GMT+8)",
  "market_216": "2022/06/06 17:00 (GMT+8)",
  "market_217": "Metafluence enables influencers to monetize their social media power and creativity in the Metaverse. As social media (SM) is transitioning to the metaverse, our vision is to build an influencer-centric ecosystem in the metaverse where SM influencers, their audiences, and brands are easily engaged in transparent and incentivized relations. We are on a mission to convert SM Influencers into metaverse entrepreneurs (Metapreneurs) through our influence-to-earn Metafluence platform. Our solutions for influencers automatically address the brand and audience challenges, enabling all the parties to thrive in Web 3.0.",
  "market_218": "2022/6/20 17:00 (GMT+8)",
  "market_219": "2022/6/22 17:00 (GMT+8)",
  "market_22": "Live Auction",
  "market_220": "Nomad (https://nomadexiles.io/) as one of the Pride Games Studio's flagship projects is an exciting Play-to-Earn RPG game with integrated DeFi farming, NFTs, and free-to-play mechanics based on a successful mobile RPG game. Our team provides players with mind-blowing visuals and game-scripts to enjoy.",
  "market_221": "Cloudee Sleeping Club is part of the Sleep Future ecosystem where you can mint Cloudee NFTs and use it in the sleep monitoring mobile app and get rewarded with SLEEPT and SLEEPEE for sleeping well. Sleep Future is the world’s first blockchain-driven sleep wellness ecosystem to improve the sleep quality and health of the global community.",
  "market_23": "Price low - high",
  "market_24": "Price high - low",
  "market_25": "On Sale",
  "market_26": "Coming Soon",
  "market_27": "Ended",
  "market_28": "Confirm",
  "market_29": "Reset",
  "market_3": "NFT withdrawal fee",
  "market_30": "Price",
  "market_31": "Current Bid Price",
  "market_32": "Creator",
  "market_33": "Contract Address",
  "market_34": "Creator Introduction",
  "market_35": "Owner",
  "market_36": "Current Bid Price",
  "market_37": "Ends in",
  "market_38": "Place a Bid",
  "market_39": "Buyout Price",
  "market_4": "NFT Marketplace",
  "market_40": "Buy Now",
  "market_41": "Auction Ended",
  "market_42": "Sold Out",
  "market_43": "View More",
  "market_44": "My NFTs",
  "market_45": "The relevant assets will be frozen after submitting successfully. If you lose in the auction, the relevant assets will be automatically unfrozen.",
  "market_46": "Minimum Markup",
  "market_47": "Available Balance",
  "market_48": "My Bid",
  "market_49": "Confirm Bid",
  "market_5": "Marketplace",
  "market_50": "Cancel Bid",
  "market_51": "Bid Successfully",
  "market_52": "Bid Failed",
  "market_53": "Purchased Successfully",
  "market_54": "View",
  "market_55": "I confirm that I want to buyout the NFT with……",
  "market_56": "Bid History",
  "market_57": "Buyout Finished",
  "market_58": "Bid Finished",
  "market_59": "On Sale",
  "market_6": "Filters",
  "market_60": "Sold",
  "market_61": "NFT Names",
  "market_62": "Sale Types",
  "market_63": "Sale Prices",
  "market_64": "Start Time",
  "market_65": "End Time",
  "market_66": "Initial Auction Price",
  "market_67": "Support Buyout",
  "market_68": "Yes",
  "market_69": "No",
  "market_7": "Categories",
  "market_70": "Categories",
  "market_71": "1. Please send NFT asset to the address from your wallet.<br>2. Once the transaction is confirmed, the NFT asset you send will be automatically credited to your NFT Market - NFT Assets.<br>3. The deposit address can be reused.",
  "market_72": "Withdrawal Address",
  "market_73": "Bid History",
  "market_74": "Deposit History",
  "market_75": "Withdrawal History",
  "market_76": "NFT Assets",
  "market_77": "Name",
  "market_78": "Bid Amount",
  "market_79": "Deposit Address",
  "market_8": "NFT Types",
  "market_80": "Withdrawal Address",
  "market_81": "Cancel Successfully",
  "market_82": "Cancel Failed",
  "market_83": "Submit Successfully",
  "market_84": "Submit Failed",
  "market_85": "Start Time - End Time",
  "market_86": "Purchased Successfully",
  "market_88": "Please enter price",
  "market_89": "Available",
  "market_9": "Sale Types",
  "market_90": "My NFTs",
  "market_91": "TokenID",
  "market_92": "Buy NFT",
  "market_93": "Sell NFT",
  "market_94": "Transaction Fee",
  "market_95": "Buy Price",
  "market_96": "Not enough balance in you account.",
  "media_1": "Channel Verification",
  "media_10": "Before making any payments, please verify the receiving address through the official verification channel of BitForex. Wallets that have not been authenticated do not belong to BitForex!",
  "media_11": "【{name}】does not belong to the verified BitForex official channel.",
  "media_12": "Please check if the information and format you entered is correct.",
  "media_13": "Telegram：@xxxxx(With the @ symbol)",
  "media_2": "Official channel verification",
  "media_3": "Please input the correct format of information for verification.",
  "media_4": "Inquire to verify if the social accounts are legitimate BitForex representatives. (Applicable for email addresses and Telegram accounts.)",
  "media_5": "Telegram ID Verification format:",
  "media_6": "@test or t.me/test (Please replace the test with the account that you would like to inquire to proceed.)",
  "media_7": "Verification passed",
  "media_8": "The \"{name}\" you are inquiring is from BitForex \"{info}\". Please protect your private information",
  "media_9": "Verification Failed",
  "missions2_1": "Daily Ranking",
  "missions2_10": "Daily Profitability",
  "missions2_100": "（Dec. 24th - Dec. 27th)",
  "missions2_101": "Season {season}",
  "missions2_102": "Season 2",
  "missions2_103": "Round {round}",
  "missions2_104": "（Jan. 7th - Jan. 10th)",
  "missions2_105": "（Jan. 14th - Jan. 17th)",
  "missions2_106": "（Feb. 11th - Feb. 14th）",
  "missions2_107": "（Feb. 25th - Feb. 28th）",
  "missions2_108": "Dec. 9th 19:00 (GMT+8)",
  "missions2_109": "Dec. 10th 19:00 (GMT+8)",
  "missions2_11": "Total Profitability",
  "missions2_110": "Dec. 11th 19:00 (GMT+8)",
  "missions2_111": "Dec. 8th - Dec. 11th 19:00 (GMT+8)",
  "missions2_112": "（Mar. 17th - Mar. 20th）",
  "missions2_113": "（Mar. 10th - Mar. 13th）",
  "missions2_114": "(Mar. 31st - Apr. 3rd)",
  "missions2_115": "(Apr. 14th - Apr. 17th)",
  "missions2_116": "(Apr. 20th - Apr. 23rd)",
  "missions2_117": "(Apr. 27th - Apr. 30th)",
  "missions2_118": "Season 3",
  "missions2_119": "Social Trading Platform Launched!",
  "missions2_12": "Recorded Time:",
  "missions2_120": "Check Out Top Traders' Trading Strategy.",
  "missions2_121": "(May 5th - May 8th)",
  "missions2_122": "(May 12th - May 15th)",
  "missions2_123": "(May 19th - May 22nd)",
  "missions2_124": "(May 26th - May 29th)",
  "missions2_125": "(June 2nd - June 5th)",
  "missions2_126": "(June 9th - June 12th)",
  "missions2_127": "(June 16th - June 19th)",
  "missions2_128": "(June 23rd - June 26th)",
  "missions2_129": "(June 30th - July 3rd)",
  "missions2_13": "Ranking (Top 20)",
  "missions2_130": "(July 7th - July 10th)",
  "missions2_131": "(July 14th - July 17th)",
  "missions2_132": "Season 4",
  "missions2_133": "(July 21st - July 24th)",
  "missions2_134": "(July 28th - July 31st)",
  "missions2_135": "(August 4th - August 7th)",
  "missions2_136": "Participants need to complete at least 100 USD perpetual contract trading to meet  the minimum contest requirements.",
  "missions2_137": "(August 11th - August 14th)",
  "missions2_138": "(August 18th - August 21st)",
  "missions2_139": "(August 25th - August 28th)",
  "missions2_14": "Participants",
  "missions2_140": "(September 1st - September 4th)",
  "missions2_141": "(September 8th - September 11th)",
  "missions2_142": "(September 15th - September 18th)",
  "missions2_143": "Season 5",
  "missions2_144": "（September 22 -  September 25）",
  "missions2_145": "(Oct. 13th - Oct. 16th)",
  "missions2_146": "(Oct. 20th - Oct. 23rd)",
  "missions2_147": "(Oct. 27th - Oct. 30th)",
  "missions2_148": "(Nov. 3rd - Nov. 6th)",
  "missions2_149": "(Nov. 10th - Nov. 13th)",
  "missions2_15": "Profitability",
  "missions2_150": "(Nov. 17th - Nov. 20th)",
  "missions2_151": "(Nov. 24th - Nov. 27th)",
  "missions2_152": "(Dec. 1st - Dec. 4th)",
  "missions2_153": "(Dec. 8th - Dec. 11th)",
  "missions2_16": "Ranking update maybe delayed, please check on the announcement for all rankings.",
  "missions2_17": "My Ranking",
  "missions2_18": "Event Rules",
  "missions2_19": "Anyone can participate in the event by trading on the BitForex Perpetual Contract Platform or by having a long/short position. No further registration or participation is needed.",
  "missions2_2": "Total Ranking",
  "missions2_20": "The event will last for 3 days; the rewards are divided into 2 types, daily ranking rewards, and total ranking rewards.",
  "missions2_21": "Profitability will be recorded from 19:00 on the previous day to 19:00 (GMT+8) the following day. The top 20 winners from the daily rankings will be rewarded.",
  "missions2_21_2": "Profitability will be recorded from 18:00 on the previous day to 18:00 (GMT+8) the following day. The top 20 winners from the daily rankings will be rewarded.",
  "missions2_22": "The total ranking will be recorded from {date}, the top 20 winners from the total rankings will be rewarded.",
  "missions2_23": "Profitability calculation formula: Total profitability = { [account asset - (deposits to the perpetual account during the event - withdrawals out of the perpetual account during the event) - account assets when the event started] / (account assets at the time of event start + deposits in the perpetual account during the event) } * 100%",
  "missions2_24": "Reward Distribution Terms",
  "missions2_25": "Perpetual contract BTC bonus rewards will be distributed to the winner's perpetual account within 2 working days after the contest concluded.",
  "missions2_25_2": "Perpetual contract BTC bonus rewards will be distributed to the winner's perpetual account within 7 working days after the contest concluded.",
  "missions2_26": "Disclaimer",
  "missions2_27": "BitForex will ensure the smooth operation of the competition in a diligent manner - we reserve the right to disqualify any user found to be violating the competitive spirit or terms at our sole discretion, effective without warning.",
  "missions2_28": "Users are assuming full responsibility for their actions and assets. BitForex reserves the right to modify or cancel the contest process, rules, and rewards according to policies, regulations and actual conditions.",
  "missions2_29": "The following trading pairs of perpetual contracts are available for trading: BTC/USD, ETH/USD, LTC/USD, XRP/USD, EOS/USD, TRX/USD, BCH/USD, DASH/USD, BSV/USD, ATOM/USD, BNB/USD, APT/USD, SUI/USD；The earnings ratio is calculated by converting each asset to a dollar value based on the current index price.",
  "missions2_3": "Trading Competition Live Ranking",
  "missions2_30": "Ranking History - click here",
  "missions2_31": "The daily profitability will be calculated every day, and the total profitability will be determined at the conclusion of the event.",
  "missions2_32": "Event Rules - click here",
  "missions2_33": "Event Details",
  "missions2_34": "Event Reminder",
  "missions2_35": "Perpetual Contract Trading Master event is ongoing with daily rewards of up to 5,000 USD.",
  "missions2_36": "1st place",
  "missions2_37": "2nd-5th place",
  "missions2_38": "6th-20th place",
  "missions2_39": "Perpetual Contract Trading Competition Coming Soon!",
  "missions2_4": "Nickname",
  "missions2_40": "My Ranking History",
  "missions2_41": "Competition Time",
  "missions2_42": "Daily Profitability Ranking",
  "missions2_43": "Profitability",
  "missions2_44": "Check your ranking at the event center. ",
  "missions2_45": "Claim Your Airdrop Now",
  "missions2_46": "Airdrop distribution has completed.",
  "missions2_47": "Ranking will be updated hourly.",
  "missions2_48": "Next event announcement",
  "missions2_49": "Perpetual Contract Trading TOP 20 Masters",
  "missions2_5": "My Total Profitability",
  "missions2_50": "Event Time: 2020/12/8 19:00 - 2020/12/11 19:00 (GMT+8)",
  "missions2_51": "BitForex Grand Master",
  "missions2_52": "2nd Place",
  "missions2_53": "3rd Place",
  "missions2_54": "Total Profitability",
  "missions2_55": "Rewards",
  "missions2_56": "TOP 20 Masters",
  "missions2_57": "Daily Ranking History",
  "missions2_58": "Mar. 20th",
  "missions2_59": "Mar. 19th",
  "missions2_6": "Daily Profitability",
  "missions2_60": "Mar. 18th",
  "missions2_61": "Round 1 Grand Master",
  "missions2_62": "BitForex Perpetual Contract Trading Grand Master",
  "missions2_63": "On Apr. 14th, 2020 19:00 (GMT+8)",
  "missions2_64": "Friendly Reminder",
  "missions2_65": "Your Perpetual Contract account balance is {num}",
  "missions2_66": "Want to continue?",
  "missions2_67": "Tranfer in and become the Grand Master",
  "missions2_68": "Quit",
  "missions2_69": "Notification",
  "missions2_7": "Trade Now",
  "missions2_70": "Perpetual Contract Grand Master is live NOW!",
  "missions2_71": "Rewards are distributed daily, get up to $5000 rewards!",
  "missions2_72": "Deposit Now",
  "missions2_73": "Transfer Now",
  "missions2_74": "*Tranfer asset into your Perpetual Contract account and participate in the event.",
  "missions2_75": "Got it",
  "missions2_76": "Perpetual Contract Grand Master is live NOW! Trade now to receive $5000 rewards.",
  "missions2_78": "Grand Master",
  "missions2_79": "Round 1",
  "missions2_8": "Ranking",
  "missions2_80": " (Aug.15th - Aug. 19th)",
  "missions2_81": "Round 2",
  "missions2_82": " (Aug. 21st - Aug 24th)",
  "missions2_83": "Round 3",
  "missions2_84": "(Aug. 28th - Aug. 31st)",
  "missions2_85": "You ranking fell back by {num} of participants, want to climb back?",
  "missions2_86": "Deposit and try again",
  "missions2_87": "Round 4",
  "missions2_88": "（Sep. 4th - Sep. 7th)",
  "missions2_89": "Round 5",
  "missions2_9": "Trading Competition Live Ranking",
  "missions2_90": "（Sep. 17th - Sep. 20th)",
  "missions2_91": "Round 6",
  "missions2_92": "（Sep. 24th - Sep. 27th)",
  "missions2_93": "Round 7",
  "missions2_94": "（Oct. 8th - Oct. 11th)",
  "missions2_95": "Round 8",
  "missions2_96": "（Oct. 15th - Oct. 18th)",
  "missions2_97": "Round 9",
  "missions2_98": "（Dec. 17th - Dec. 20th)",
  "missions2_99": "Round 10",
  "missions2_table_1": "Ranking",
  "missions2_table_2": "1st place",
  "missions2_table_3": "2nd-5th place",
  "missions2_table_4": "6th-20th place",
  "missions2_table_5": "Equivalent BTC Rewards",
  "missions2_table_6": "Total Profitability",
  "missions2_table_7": "Daily Profitability",
  "missions_22": "Trade now",
  "missions_26": "Nickname set up completed",
  "money_management_01": "BitForex Earn",
  "money_management_02": "Selected Products with Various Options",
  "money_management_03": "My Earnings",
  "money_management_04": "Login",
  "money_management_05": "Now",
  "money_management_06": "Wealth Management",
  "money_management_07": "Cumulative Interest",
  "money_management_08": "Orders",
  "money_management_09": "Assets",
  "money_management_10": "Fixed Term",
  "money_management_100": "Earn Coins",
  "money_management_101": "Redemption Amount",
  "money_management_102": "Est. Arrival Time",
  "money_management_103": "Amount in Accruing Interest",
  "money_management_104": "Amount in Redemption",
  "money_management_105": "What's BitForex Earn ?",
  "money_management_106": "BitForex Earn is the process of holding funds in a cryptocurrency wallet to support the operations of a blockchain network.",
  "money_management_107": "What will happen when I'm holding Locked BitForex Earn?",
  "money_management_108": "We will reduce the cryptocurrency amounts which you're holding in your wallet account.",
  "money_management_109": "What will happen when you're holding Locked BitForex Earn?",
  "money_management_11": "Product",
  "money_management_110": "We have some operations of a blockchain network, and start accruing interest",
  "money_management_111": "About Interest Distribution",
  "money_management_112": "From 0:00 AM (GMT+8) on the day after staking order is confirmed to the end of the corresponding product period. The interest is accrued on a daily basis and will distribute when due.",
  "money_management_113": "When can I redeem the Locked BitForex Earn?",
  "money_management_114": "\"Flexible\" allows you to redeem your staked assets at any time; \"Fixed\" will repay the principal and interest when due automatically. The redemption period on the web page is for reference only.",
  "money_management_115": "Stake Date",
  "money_management_116": "Do not remind me today",
  "money_management_117": "Redemption confirmed",
  "money_management_118": "Est. interest",
  "money_management_119": "The agreement has been agreed",
  "money_management_12": "Reference Annual Yield",
  "money_management_120": "Visit Wallet",
  "money_management_121": "View orders",
  "money_management_122": "Select product",
  "money_management_123": "All holding assets",
  "money_management_124": "TMR Est. interest",
  "money_management_125": "Order history",
  "money_management_126": "Pending interest",
  "money_management_127": "Decimal digits cannot exceed 8",
  "money_management_129": "Coin/Token",
  "money_management_13": "Duration (days)",
  "money_management_130": "High Yield Staking",
  "money_management_131": "Stake crypto assets on BitForex, get high yield staking rewards.",
  "money_management_132": "Interests will be distribute in {coin}.",
  "money_management_133": "Get",
  "money_management_134": "Interest will be distributed to your wallet account immediately when the subscription period ends.",
  "money_management_135": "Principal and interest will be automatically distributed to your wallet account after redemption.",
  "money_management_136": "Duration",
  "money_management_14": "Remaining Amount",
  "money_management_15": "Yield to Maturity of 100 Board Lots",
  "money_management_16": "The total interest after the expiration of the 100 board lots product",
  "money_management_17": "Subscribe",
  "money_management_18": "The subscription will start after",
  "money_management_19": "View All",
  "money_management_20": "Wealth Management Product",
  "money_management_21": "Subscription",
  "money_management_22": "Term",
  "money_management_23": "Token",
  "money_management_24": "Duration (days)",
  "money_management_25": "{day} days",
  "money_management_26": "Board Lots",
  "money_management_27": "Available {max} Lots",
  "money_management_28": "Pay with wallet balance",
  "money_management_29": "Buy Now",
  "money_management_30": "Transfer",
  "money_management_31": "Lots",
  "money_management_32": "Maximum",
  "money_management_33": "Amount in a Board Lot",
  "money_management_34": "Interest in a Board Lot",
  "money_management_35": "Reference Annual Yield",
  "money_management_36": "Maximum Subscribe Limit",
  "money_management_37": "Effective on",
  "money_management_38": "at the latest",
  "money_management_39": "Redemption Date",
  "money_management_40": "{period}Days",
  "money_management_41": "Expected Interest",
  "money_management_42": "After clicking the subscribe button, you have agreed to our <a href=\"https://event.bitforex.com/en/Terms.html\" target=\"_blank\">\"Terms of Service\"</a>",
  "money_management_43": "Subscribe",
  "money_management_44": "After the redemption is successful, the principal and interest will be automatically issued to your wallet account",
  "money_management_45": "The payment failed, please try again later",
  "money_management_46": "Confirm",
  "money_management_47": "Successfully subscribed, waiting to take effect",
  "money_management_48": "Close",
  "money_management_49": "You can view details in <span>Wealth Management Orders</span>",
  "money_management_50": "All Status",
  "money_management_51": "Product",
  "money_management_52": "Token",
  "money_management_53": "Board Lot Amount",
  "money_management_54": "Amount",
  "money_management_55": "Duration (days)",
  "money_management_56": "Reference Annual Yield",
  "money_management_57": "Trading Time",
  "money_management_58": "Effective Date",
  "money_management_59": "Redemption Date",
  "money_management_60": "Status",
  "money_management_61": "Subscribed",
  "money_management_62": "In Effect",
  "money_management_63": "In Redemption",
  "money_management_64": "Redeemed",
  "money_management_65": "Purchase",
  "money_management_66": "Interest",
  "money_management_67": "Redemption of principal",
  "money_management_68": "Distributed income",
  "money_management_69": "Retract",
  "money_management_70": "The sale of this financial product has been suspended",
  "money_management_71": "Finance",
  "money_management_72": "Subscription Date",
  "money_management_73": "Interest date",
  "money_management_74": "Interest accrual end date",
  "money_management_75": "Est. APY",
  "money_management_76": "Estimated Next Distribution Amount",
  "money_management_77": "Interest Period",
  "money_management_78": "Cumulative Interest",
  "money_management_79": "Redemption Date",
  "money_management_80": "Interest Period",
  "money_management_81": "{period}days from the ending interest date",
  "money_management_82": "Yesterday's earnings",
  "money_management_83": "Min Stake Amount",
  "money_management_84": "Stake",
  "money_management_85": "Max Stake Amount",
  "money_management_86": "Lock-up Date",
  "money_management_87": "Interest Period",
  "money_management_88": "Redemption Period",
  "money_management_89": "Interest End Date",
  "money_management_90": "Est. APY",
  "money_management_91": "Redemption Arrival Date",
  "money_management_92": "Check holding assets under Assets - 『BitForex Earn』",
  "money_management_93": "I have read and agree to the <a style=\"color: #14C393\" href=\"{url}\" target=\"_blank\">《BitForex Earn Service Agreement》</a>",
  "money_management_94": "Flexible Term",
  "money_management_95": "{num} Calendar Day",
  "money_management_96": "Interest Distribution Day",
  "money_management_97": "Important Notice",
  "money_management_98": "Est. Total Assets",
  "money_management_99": "Account Valuation",
  "more": "Load more",
  "mt5event_1": "Beginner's Guide",
  "mt5event_10": "Total Ranking",
  "mt5event_101": "MT5 Trading Master Competiton is now Online",
  "mt5event_102": "4000 USDT Prize Pool, waiting for you to split!",
  "mt5event_103": "Event Details",
  "mt5event_104": "Competition is now ongoing!",
  "mt5event_106": "The ranking data is being updated, please check later.",
  "mt5event_107": "Transfer Bonus",
  "mt5event_108": "Transfer Now to Get Bonus!",
  "mt5event_109": "Round 3",
  "mt5event_11": "Daily Ranking",
  "mt5event_110": "Mar.25 - Mar. 28",
  "mt5event_111": "Round 2",
  "mt5event_112": "March 4 - March 7",
  "mt5event_113": "Round 4",
  "mt5event_114": "April 7 - April 10",
  "mt5event_12": "1st place",
  "mt5event_13": "2nd - 3rd place",
  "mt5event_14": "4th - 20th place",
  "mt5event_15": "1st place",
  "mt5event_16": "2nd - 3rd place",
  "mt5event_17": "4th - 20th place",
  "mt5event_18": "Event Details",
  "mt5event_19": "Event Rules",
  "mt5event_2": "JOIN NOW",
  "mt5event_20": "1. Anyone can participate in the event by trading in any products on the BitForex MT5 Platform. No further applications are needed.",
  "mt5event_21": "2. The event will last for 3 days; the rewards are devided into 2 types, daily ranking rewards, and total ranking rewards.",
  "mt5event_22": "3. Daily Profitability will be recorded from 16:00 on the previous day to 16:00 (GMT+8) the following day. The top 20 winners from the daily rankings will be rewarded.",
  "mt5event_23": "4. The total ranking will be the recorded from April 7 at 16:00 to April 10 at 16:00 (GMT+8), the top 20 winners from the total rankings will be rewarded.",
  "mt5event_24": "Reward Distribution Terms",
  "mt5event_25": "All rewards will be sent within two business days of the final ranking announcement, and the system will automatically issue the equivalent USDT reward to the user's wallet account.",
  "mt5event_26": "Disclaimer",
  "mt5event_27": "1. BitForex will ensure the smooth operation of the competition in a diligent manner - we reserve the right to disqualify any user found to be violating the competitive spirit or terms at our sole discretion, effective without warning.",
  "mt5event_28": "2. Users are assuming full responsibility for their actions and assets. BitForex reserves the right to modify or cancel the contest process, rules, and rewards according to policies, regulations and actual conditions.",
  "mt5event_29": "Trade Responsibly: CFDs are complex instruments and come with a high risk of losing money rapidly due to leverage. You should consider whether you understand how CFDs work and whether you can afford to take the high risk of losing your money.",
  "mt5event_3": "April 8 16:00 (GMT+8)",
  "mt5event_30": "Trading Competition Daily Ranking",
  "mt5event_31": "Daily ranking awards will be settled daily and total ranking awards will be settled after the event.",
  "mt5event_32": "Event Rules>>",
  "mt5event_33": "Daily Profitablity",
  "mt5event_34": "Total Profitability",
  "mt5event_35": "Recorded Time:",
  "mt5event_36": "Ranking (Top 20)",
  "mt5event_37": "Participants",
  "mt5event_38": "Profitability",
  "mt5event_39": "My Ranking",
  "mt5event_4": "April 9 16:00 (GMT+8)",
  "mt5event_40": "Ranking update maybe delayed, please check on the announcement for all rankings.",
  "mt5event_41": "Nickname",
  "mt5event_42": "My Total Profitability",
  "mt5event_43": "Daily Profitability",
  "mt5event_44": "Trade Now",
  "mt5event_45": "You ranking fell back by {num} of participants, want to climb back?",
  "mt5event_46": "Deposit and try again",
  "mt5event_47": "MT5 Trading Master Competition is live NOW! Trade now to split $4000 rewards.",
  "mt5event_5": "April 10 16:00 (GMT+8)",
  "mt5event_6": "April 7 - 10 16:00 (GMT+8)",
  "mt5event_60": "MT5 Trading Master Competition total profitability Top 20",
  "mt5event_61": "Event Time: April 7, 2020 at 16:00 - April 10, 2020 at 16:00 (GMT+8)",
  "mt5event_62": "TOP 20 of the competition",
  "mt5event_63": "Ranking History - click here",
  "mt5event_64": "Daily Ranking History",
  "mt5event_65": "April 8",
  "mt5event_66": "April 9",
  "mt5event_67": "April 10",
  "mt5event_68": "MT5 Trading Grand Master",
  "mt5event_69": "Trading Master",
  "mt5event_7": "DAILY RANKING",
  "mt5event_70": "2nd Place",
  "mt5event_71": "3rd Place",
  "mt5event_72": "Total Profitability",
  "mt5event_73": "Round 1",
  "mt5event_74": "Feb. 20 - Feb. 22",
  "mt5event_8": "TOTAL RANKING",
  "mt5event_9": "BitForex MT5 Trading Master Competition",
  "mt5_1": "MT5 Account",
  "mt5_10": "Account Name",
  "mt5_11": "Trade Now",
  "mt5_12": "Reset Password",
  "mt5_13": "Account Password",
  "mt5_14": "ID",
  "mt5_15": "Account Management",
  "mt5_16": "Please use account password to log in",
  "mt5_17": "The vision of BitForex MT5 is provide a platform to cryptocurrency traders with more options for trading traditional financial products, bringing the wider financial landscape to the cryptocurrency industry.",
  "mt5_18": "{coin} Account",
  "mt5_19": "Account",
  "mt5_2": "Activate",
  "mt5_20": "password",
  "mt5_21": "Reset {coin} account password",
  "mt5_22": "Your MT5 account is not yet activated",
  "mt5_23": "Activate Now",
  "mt5_24": "All MT5 account transfers will be processed at 16:00 (GMT+8) daily",
  "mt5_25": "Transfers will be processed in an hour after the daily transfer closing time. Transfer requests made after 16:00 (GMT+8) will be process on the next business day",
  "mt5_26": "For your transaction security, please complete the following security verification.",
  "mt5_27": "1. You must enable \"Mobile verification\" or \"Google security verification\";",
  "mt5_28": "Confirm",
  "mt5_29": "MT5 account transfer out history",
  "mt5_3": "Transfer History",
  "mt5_30": "Status",
  "mt5_31": "Cancelled",
  "mt5_32": "Transfer out from MT5 account when a position is not closed will increase the forced liquidation risk rate.",
  "mt5_33": "MT5 Bills",
  "mt5_34": "Total Asset",
  "mt5_35": "Notice: Because MT5 does not support four-digit trading codes, \"USDT\" will be displayed as \"UST\" in the MT5 interface.",
  "mt5_36": "{num} {coin} daily transfer limit is applied",
  "mt5_37": "A 20 times limits is applied for transfer in and out for the MT5 account",
  "mt5_38": "Please try again at 16:00 (GMT+8)",
  "mt5_39": "MT5 account details cannot be accessed during the system maintenance period.",
  "mt5_4": "Applying",
  "mt5_40": "MT5 orders cannot be accessed during the system maintenance period.",
  "mt5_41": "MT5 account cannot be created during the system maintenance period.",
  "mt5_42": "MT5 transfers are unavailable during the system maintenance period.",
  "mt5_43": "The first MT5 account password is randomly created by the system, change of the MT5 accounts password is recommended for your account safety.",
  "mt5_44": "Product Specification",
  "mt5_45": "Transfer History",
  "mt5_5": "Completed",
  "mt5_6": "Cancel",
  "mt5_7": "I have read the BitForex MT5 Terms and Service and I agree",
  "mt5_8": "Activate Account",
  "mt5_9": "Account creation in process",
  "mt5_account_1": "Create BitForex MT5 Account",
  "mt5_account_10": "Cryptocurrency",
  "mt5_account_11": "WebTrader",
  "mt5_account_12": "Download",
  "mt5_account_13": "Trade Now",
  "mt5_account_14": "Transfer",
  "mt5_account_15": "Congratulations! Your MT5 {coin} account has been created. We have sent your login information to your email.",
  "mt5_account_16": "{coin} Account ID: {id}",
  "mt5_account_17": "View My Password",
  "mt5_account_18": "Reset My Password",
  "mt5_account_19": "{coin} Account",
  "mt5_account_2": "Leverage",
  "mt5_account_20": "Account ID",
  "mt5_account_21": "Password",
  "mt5_account_22": "Reset {coin} account password",
  "mt5_account_23": "Your {coin} account has been successfully created",
  "mt5_account_24": "Password Rsset Successfully",
  "mt5_account_25": "This window will close automatically after {second}S",
  "mt5_account_26": "Learn more",
  "mt5_account_27": "You have created all cryptocurrency accounts supported by MT5",
  "mt5_account_28": "Equity",
  "mt5_account_29": "Credit included",
  "mt5_account_3": "1:200",
  "mt5_account_30": "Congratulations!",
  "mt5_account_31": "You have received {amount} {coin} from BitForex to welcome new users. Activate your account now and check it out!",
  "mt5_account_32": "Remaining time",
  "mt5_account_33": "Learn more",
  "mt5_account_34": "Transfer-in Now",
  "mt5_account_35": "No details found",
  "mt5_account_4": "Account Base Cryptocurrency",
  "mt5_account_5": "I have read and agree to the <a href='{link}' target='_blank'>BitForex MT5 User Agreement</a>",
  "mt5_account_6": "Create",
  "mt5_account_7": "Create New Account",
  "mt5_account_8": "Account ID",
  "mt5_account_9": "Balance",
  "mt5_guide_1": "Tutorial",
  "mt5_guide_10": "Here you can check the current balance of your MT5 accounts.",
  "mt5_guide_11": "Choose a Trading Platform",
  "mt5_guide_12": "Here you can choose between different MT5 mode or software for trading.",
  "mt5_guide_13": "MT5 web version",
  "mt5_guide_14": "No downloads are required, click \"Trade Now\" to open MT5 web terminal.",
  "mt5_guide_15": "Then enter your ID and password to login and start trading.",
  "mt5_guide_16": "Download MT5 for trading",
  "mt5_guide_17": "Windows desktop terminal, Android, iOS mobile terminal is available for BitForex MT5.",
  "mt5_guide_18": "Click the corresponding icon to download MT5 to finish the installation.",
  "mt5_guide_19": "After the installation is complete, open MT5 and enter your ID and password to start trading.",
  "mt5_guide_2": "Skip",
  "mt5_guide_20": "Start your first transfer",
  "mt5_guide_21": "Click the transfer button, you can transfer between your MT5 account and your wallet account freely.",
  "mt5_guide_22": "The above is a tutorial for MT5 account interface. If you still have any questions about MT5, please check the <a href='{link1}' target='_blank'>FAQ</a> or contact our <a href='{link2}' target='_blank'>online Customer Service</a>.",
  "mt5_guide_23": "Transfer Now",
  "mt5_guide_24": "Close Tutorial",
  "mt5_guide_3": "MT5 Account",
  "mt5_guide_4": "Here you can view your MT5 account details",
  "mt5_guide_5": "Create New Account",
  "mt5_guide_6": "Here you can add MT5 accounts for different cryptocurrencies options, BTC, ETH, XRP, USDT is support for MT5 account.",
  "mt5_guide_7": "Account ID and Password",
  "mt5_guide_8": "Here you can view your MT5 ID.If you forget the password, you can click {key} to view or reset the current password.",
  "mt5_guide_9": "Account Balance",
  "myo_1": "MYO (Mycro)",
  "myo_10": "Price: 0.30 USD",
  "myo_11": "Trading Pair",
  "myo_12": "MYO/ETH",
  "myo_13": "Lock-up Period",
  "myo_14": "No Lock-up",
  "myo_15": "IMPORTANT NOTICE",
  "myo_16": "1. Participation in Mycro (MYO) subscription requires completion of KYC certification;\n2. There is no handling fee for the transaction; \n3. Individual cap per round for each account is 16,000 MYO;\n4. The minimum order amount per round for a single account is 400 MYO.",
  "myo_17": "Enable Verification",
  "myo_18": "REFERENCE",
  "myo_19": "Mycro Official Website",
  "myo_2": "First global decentralized platform for simple jobs",
  "myo_20": "https://www.mycrojobs.io",
  "myo_21": "Mycro Whitepaper",
  "myo_22": "https://www.mycrojobs.io/download/Mycro-Whitepaper-EN.pdf",
  "myo_23": "INTRODUCTION",
  "myo_24": "Join the future of simple jobs by building a peer-to-peer network and mobile application, we connect people who need support with simple jobs and people who are looking for some extra money, in real time.",
  "myo_25": "CORE FEATURES",
  "myo_26": "Mycro brings together users for something greater than money: Time!",
  "myo_27": "Mycro solves the following problems to give people back control over their time:\n1. Non-existence of a worldwide platform: Currently, there is no global in-person platform that unites demand and supply for home, delivery and skilled services under one roof.\n2. High fees: Platforms such as TaskRabbit and Airtasker charge a commission up to 30%. These high fees limit the number of tasks that can be outsourced.\n3. Centralization: User ratings have become one of the most valuable assets, but dominant players such as Airbnb and Uber restrict users from taking their own ratings to other platforms.",
  "myo_28": "Mycro: a blockchain platform with working technology",
  "myo_29": "Our technology is more than just an idea. We have a prototype that works.\nThe Mycro application helps you turn your to-do’s into done. Users can offer or apply for any short-term job, ranging from home, delivery, virtual and skilled services.\n1. Low fees: By leveraging the blockchain, the middleman is eliminated, which results in a commission fee of only 0% to 5%. \n2. Fast and easy to use: Available worldwide. Find help and make money wherever you are.\n3. Job matching in real time: By using Mycro’s intelligent and self-learning matching algorithms that have been adopted from popular dating platforms, job providers are connected with suitable candidates, matching vacant jobs with the right person in real time.\n4. Instant payment: Mycro puts your earnings in your pocket the moment you complete the task, not weeks later. Trustless, instant payouts on the blockchain.",
  "myo_3": "ROUND 1",
  "myo_30": "Mycro Protocol the underlying magic",
  "myo_31": "The Mycro Protocol acts as the confidential net- working layer for all integrated decentralized ap- plications, creating peer-to-peer (P2P) connec- tions and providing an efficient way for users to enter trustless transactions over the network. The Mycro Protocol consists of client-side APIs and services that allow connected applications to confidentially perform communication functions with other users on the network.\n\nWith the Mycro protocol we ensure:\n1. Complete trust: Decentralized rating systems and user verification options are executed by smart contracts, allowing both parties to enter a ‘trustless’ transaction. All users are verified through a implemented KYC process.\n2. Secure payments: Payments are held by a decentralized, escrow smart contract while the job is performed, preventing retrieval access from either party until a specific job is complete.\n3. Democratized ratings: Mycro is giving the no. 1 asset in the online world back to the user. By using IPFS, we allow users to have full control over their detailed rating information. With 100% ownership, they are free to take their ratings to other platforms.",
  "myo_32": "MYO Token Sale and Economics",
  "myo_33": "1st Round Token Sale Start Time",
  "myo_34": "2019/06/11 21:00 - 2019/06/12 9:00 (GMT+8)",
  "myo_35": "1st Round Token Sale Token Price",
  "myo_36": "0.25 USD",
  "myo_37": "1st Round Turbo Allocation",
  "myo_38": "800,000 MYO (0.8% of the total supply)",
  "myo_39": "1st Round Bonus",
  "myo_4": "Time: 2019/06/11 21:00 - 2019/06/12 9:00 (GMT+8)",
  "myo_40": "10% bonus (bonus will be distributed 7 days after Turbo)",
  "myo_41": "2nd Round Token Sale Start Time",
  "myo_42": "2019/06/12 21:00 - 2019/06/14 21:00 (GMT+8)",
  "myo_43": "2nd Round Token Sale Token Price",
  "myo_44": "0.3 USD",
  "myo_45": "2nd Round Turbo Allocation",
  "myo_46": "2,000,000 MYO (2% of the total supply)",
  "myo_47": "2nd Round Bonus",
  "myo_48": "NONE",
  "myo_49": "Total Token Supply",
  "myo_5": "Allocation: 800,000 MYO",
  "myo_50": "100,000,000 MYO",
  "myo_51": "Initial Circulating Supply",
  "myo_52": "2,800,000 MYO",
  "myo_53": "Token Sale Vesting Period",
  "myo_54": "NONE",
  "myo_55": "Individual Cap",
  "myo_56": "16,000 MYO",
  "myo_57": "The Minimum Order Amount",
  "myo_58": "400 MYO",
  "myo_59": "Private Sale Token Price",
  "myo_6": "Price: 0.25 USD",
  "myo_60": "0.1675 USD",
  "myo_61": "Private Sale Allocation",
  "myo_62": "11% of Total Token Supply",
  "myo_63": "Private Sale Vesting Period",
  "myo_64": "14 days after MYO trading starts (2019/06/30)",
  "myo_65": "Token Type",
  "myo_66": "ERC-20",
  "myo_67": "TOKEN ALLOCATION",
  "myo_68": "11% Public Sale\n11% Private Sale\n34% Ecosystem fund\n12% Reward Pool\n10% Company Reserve\n9% Team & Founder\n8% Bonus & Advisors\n5% Bounty Program",
  "myo_69": "MYO TEAM",
  "myo_7": "ROUND 2",
  "myo_70": "2019/06/12 21:00  (GMT+8)",
  "myo_71": "2019/06/14 21:00  (GMT+8)",
  "myo_8": "Time: 2019/06/12 21:00 - 2019/06/14 21:00 (GMT+8)",
  "myo_9": "Allocation: 2,000,000 MYO",
  "news_1": "Latest News",
  "news_2": "Events",
  "news_3": "New Listings",
  "newyear_act_01": "Your BitForex UID",
  "newyear_act_02": "Provide your UID to receive the rewards.",
  "newyear_act_03": "Your Prediction",
  "newyear_act_04": "You can ONLY enter ONE time slot. You are able to update the time slots any time before this event ends. If you update your time slots, your previous slots are deemed invalid.",
  "newyear_act_05": "Event Countdown",
  "newyear_act_06": "Please login first",
  "newyear_act_07": "Your Prediction",
  "newyear_act_08": "Please login your BitForex account",
  "newyear_act_09": "Enter a price prediction",
  "newyear_act_10": "Make Prediction",
  "newyear_act_11": "Enter Your Peice Prediction",
  "newyear_act_12": "Event has ended",
  "newyear_act_13": "Event has not started yet",
  "newyear_act_14": "Event does not exist",
  "newyear_act_15": "Get a Chance to Win 1 BTC Airdrop Bonus",
  "newyear_act_16": "Make a prediction of what would the Bitcoin price be on December 31st, 2020 at 23:59 (GMT+8)",
  "newyear_act_17": "Start Now",
  "newyear_act_18": "No, thanks",
  "newyear_act_19": "New Years BTC Predictions",
  "newyear_act_20": "New Years BTC Predictions",
  "newyear_act_21": "Make a Prediction and Get a Chance to Win 1 BTC Airdrop Bonus!",
  "newyear_act_22": "Sign up on BitForex and participate - enter your prediction of what would the Bitcoin price be on December 31st, 2020 at 23:59 (GMT+8)",
  "newyear_act_23": "Step 1:",
  "newyear_act_24": "Login or Signup First",
  "newyear_act_25": "Provide your UID to receive the rewards.",
  "newyear_act_26": "Step 2:",
  "newyear_act_27": "Your predictions",
  "newyear_act_28": "Enter the price prediction here. You are able to update the price any time before 2021/12/31 18:00 (GMT+8). If you update your prediction, your previous ones are deemed invalid.",
  "newyear_act_29": "Participation rules：",
  "newyear_act_30": "Log in to your BitForex account to participate in the “New Years BTC Event”.",
  "newyear_act_31": "*Please note the account you logged in will be used to distribute your potential winnings.",
  "newyear_act_32": "*Each BitForex UID can ONLY be participated once in this event. Please make sure to use your own UID accurately.",
  "newyear_act_33": "The price slots you can enter is any price you think BTC would be by the end of the event period.",
  "newyear_act_34": "You are able to update the price any time before 2021/12/31 18:00 (GMT+8). If you update your prediction, your previous ones are deemed invalid.",
  "newyear_act_35": "The price of a  BTC/USDT trading pair on BitForex (shown in the chart below) will be used  to track and determine the BTC price movement.",
  "newyear_act_36": "The price of BTC/USDT on BitForex on January 1st, 2022 00:00 (GMT+8) is considered as the final event price; users who have the closest prediction will win the prize.",
  "newyear_act_37": "All users who predicted the same price and it is the closest to the final event price will be ranked based on the time they entered the time slots — which means you should start predicting right away!",
  "newyear_act_38": "1st place: $6000 worth of BTC bonus",
  "newyear_act_39": "2nd place: $4000 worth of BTC bonus",
  "newyear_act_40": "3rd place: $2000 worth of BTC bonus",
  "newyear_act_41": "The top 4-50 users closest to the final event price will share $8000 worth of BTC airdrop bonus evenly.",
  "newyear_act_42": "The prizes will be distributed to the BitForex accounts of the winning participants within 14 working days after the completion of the event.",
  "newyear_act_43": "The BTC bonus can be used in BitForex any perpetual contract trading market, the profit part can be withdrawn.",
  "newyear_act_44": "Disclaimer：",
  "newyear_act_45": "The information about this campaign is meant for informational purposes only. It shall in no way be conceived as any means of implication, suggestion or specification that BitForex provides any form of online gambling. BitForex in no way endorses or condones, promotes or encourages that users participate in online gambling. It is your responsibility to abide by applicable local laws and regulations in your region, jurisdiction or country.",
  "newyear_act_46": "BitForex reserves the ability to disqualify any participants engaging in fraudulent behavior and the right to change the terms, eligibility criteria and payouts for all campaigns at any time and for any reason.",
  "newyear_act_47": "*Users from the following countries or regions will not be able to participate in this event: Belarus, Democratic Republic of Congo,Cuba, Iraq, Iran, Mainland China, North Korea, Sudan, Syria, United States of America and its territories (American Samoa, Guam, the Northern Mariana Islands, Puerto Rico, and the U.S. Virgin Islands), Zimbabwe.",
  "newyear_act_48": "Succeed",
  "newyear_act_49": "Make a Prediction and Get a Chance to Win $20,000 Worth of BTC Airdrop Bonus!",
  "newyear_act_50": "Sign up on BitForex and participate - enter your prediction of what would the Bitcoin price be on January 1st, 2022 at 00:00 (GMT+8)",
  "new_year2021_01": "Event Countdown",
  "new_year2021_02": "This Event is limited to New Users",
  "new_year2021_03": "Event has ended",
  "new_year2021_04": "Register now!",
  "new_year2021_06": "Congratulations!\r\nRegistration is successful.\r\nDeposit now!",
  "new_year2021_07": "The more you deposit, the more bonus you get!",
  "new_year2021_08": "KYC Certification",
  "new_year2021_09": "KYC Certification Passed",
  "new_year2021_12": "New Year Event!<br>\r\nRegister BitForex Account and Share<span> 3 BTC</span> Bonus Pool!",
  "new_year2021_13": "Click register on the landing page to participate the event.",
  "new_year2021_14": "Start Now",
  "new_year2021_15": "No, thanks",
  "new_year2021_16": "New Year Event!\r\nRegister BitForex Account and Share 3 BTC Bonus Pool!",
  "new_year2021_17": "New Year Event!\r\nRegister BitForex Account and Share 3 BTC Bonus Pool!",
  "new_year2021_18": "Event Period:",
  "new_year2021_19": "Jan 26, 2021 at 16:00 ~ Feb 4, 2021 at 16:00 (GMT+8)",
  "new_year2021_20": "Participation rules：",
  "new_year2021_21": "During the event time, a new user who successfully registered through the <span>2404014</span> invitation code and has a deposit <span>≥50USD</span> until the end of the event will be deemed to have successfully participated the event.",
  "new_year2021_22": "KYC is REQUIRED to participate in this event.",
  "new_year2021_23": "Total Bonus Pool: <span>3 BTC</span>\r\nThe more you deposit, the more bonus you get!",
  "new_year2021_24": "The bonus will be issued to the perpetual contract account on Feb 5, 2021.",
  "new_year2021_25": "Participants will automatically lose their eligibility to participate if they swipe their orders.",
  "new_year2021_26": "BitForex have the right to suspend, terminate and reserve all rights to interpret the event.",
  "new_year2021_28": "Disclaimer：",
  "new_year2021_29": "The information about this campaign is meant for informational purposes only. It shall in no way be conceived as any means of implication, suggestion or specification that BitForex provides any form of online gambling. BitForex in no way endorses or condones, promotes or encourages that users participate in online gambling. It is your responsibility to abide by applicable local laws and regulations in your region, jurisdiction or country.",
  "new_year2021_30": "BitForex reserves the ability to disqualify any participants engaging in fraudulent behavior and the right to change the terms, eligibility criteria and payouts for all campaigns at any time and for any reason.",
  "new_year2021_31": "*Users from the following countries or regions will not be able to participate in this event: Belarus, Democratic Republic of Congo,Cuba, Iraq, Iran, Mainland China, North Korea, Sudan, Syria, United States of America and its territories (American Samoa, Guam, the Northern Mariana Islands, Puerto Rico, and the U.S. Virgin Islands), Zimbabwe.",
  "new_year2021_32": "Unfortunately, you do not meet the participation rules.",
  "new_year2021_6_1": "Deposit now!",
  "new_year2021_6_2": "Congratulations on your successful participation.",
  "nftBlindBoxs_100": "The more friends you successfully invite to participate and complete giveaway tasks, the higher the chance of you getting a mystery box is.",
  "nftBlindBoxs_101": "Users have unlimited chance to participate in giveaway tasks, the more tasks you complete, the more chances you will have to get mystery boxes.",
  "nftBlindBoxs_102": "All rewards will be presented with redeem codes by email. You will be eligible for the lucky draw on the Mystery Box Event Page (link) by inputting the redeem code.",
  "nftBlindBoxs_103": "Note",
  "nftBlindBoxs_104": "All awards will only be valid for 30 days after the code is released;",
  "nftBlindBoxs_105": "BitForex reserves the right to disqualify participators from the event if they display any fraudulent behavior. BitForex will take a random snapshot during the campaign. Ineligible users will receive no rewards.",
  "nftBlindBoxs_106": "Coming soon",
  "nftBlindBoxs_107": "NFT Market",
  "nftBlindBoxs_108": "The current page is overloaded, please try again later",
  "nftBlindBoxs_109": "Congratulations! You have won the",
  "nftBlindBoxs_50": "Happy 4th Anniversary",
  "nftBlindBoxs_51": "Get 1 BTC Worth Mystery Boxes!",
  "nftBlindBoxs_52": "1/15 18:00 - 2/15 18:00 (GTM+8)",
  "nftBlindBoxs_53": "BitForex Mystery Box",
  "nftBlindBoxs_54": "Mystery Box",
  "nftBlindBoxs_55": "My Award",
  "nftBlindBoxs_56": "2022/01/15 - 2022/02/15",
  "nftBlindBoxs_57": "Join the CryptoCup Competition, follow the rule to win the Mystery Box, enter the draw to win rewards!",
  "nftBlindBoxs_58": "Free Lottery",
  "nftBlindBoxs_59": "Lucky Draw",
  "nftBlindBoxs_60": "Remaining Times",
  "nftBlindBoxs_61": "lucky box",
  "nftBlindBoxs_62": "Win Rate",
  "nftBlindBoxs_63": "in total",
  "nftBlindBoxs_64": "Congratulations, you have won a chance to draw! please go to the event page<a href=\"https://www.bitforex.com/en/NFT-BlindBox\"> www.bitforex.com/en/NFT-BlindBox</a> to draw your reward!",
  "nftBlindBoxs_65": "Invalid Redemption Code",
  "nftBlindBoxs_66": "Event address:<a href=\"https://www.bitforex.com/en/NFT-BlindBox\"> https://www.bitforex.com/en/NFT-BlindBox</a>",
  "nftBlindBoxs_67": "Mystery Box Eligibility Redemption",
  "nftBlindBoxs_68": "Mystery Box Eligibility Redemption",
  "nftBlindBoxs_69": "Please enter the redemption code",
  "nftBlindBoxs_70": "OK",
  "nftBlindBoxs_71": "Got it",
  "nftBlindBoxs_72": "Unfortunately, the prize has passed you by.",
  "nftBlindBoxs_73": "Lucky Draw",
  "nftBlindBoxs_74": "Open the blind box",
  "nftBlindBoxs_75": "View Prize",
  "nftBlindBoxs_76": "Remaining draws",
  "nftBlindBoxs_77": "About to start",
  "nftBlindBoxs_78": "Ended",
  "nftBlindBoxs_79": "No more lucky draws",
  "nftBlindBoxs_80": "No prizes at this time",
  "nftBlindBoxs_81": "Blind Box Contents",
  "nftBlindBoxs_83": "BitForex 4th Anniversary Mystery Box Giveaway - 100% to Win! \nHot pop NFTs are Waiting for You to Draw!",
  "nftBlindBoxs_84": "BitForex Mystery Box Drawing rules",
  "nftBlindBoxs_85": "Event detail",
  "nftBlindBoxs_86": "Event Period",
  "nftBlindBoxs_87": "2022/01/15 18:00 - 2022/02/15 18:00 (GTM+8)",
  "nftBlindBoxs_88": "Event Rules",
  "nftBlindBoxs_89": "There are 3 rounds of the CryptoCup event, including four groups of competition in the first round; two groups of competition in the second round and one group of competition in the final round. In total of 7 groups of competition. (Detailed CryptoCup schedule: link)",
  "nftBlindBoxs_90": "BitForex will run giveaway events during each group of competition in each round. Users need to complete the task through the giveaway event link to obtain the mystery box qualification and increase the winning weight for the supported projects.",
  "nftBlindBoxs_91": "Giveaway event links will be shared on BitForex official Telegram channel and Twitter Channel.",
  "nftBlindBoxs_92": "In each round of the CryptoCup, winners will be randomly selected from the users who complete the task and get the mystery box qualification.",
  "nftBlindBoxs_93": "Rewards:",
  "nftBlindBoxs_94": "- 250 Mystery Boxes for the 1st Round",
  "nftBlindBoxs_95": "- 180 Mystery Boxes for the 2nd Round",
  "nftBlindBoxs_96": "- 90 Mystery Boxes for the 3rd Round",
  "nftBlindBoxs_97": "The prize in the mystery box include:",
  "nftBlindBoxs_98": "-A Hottest NFT: The Sandbox Land, League of Kingdoms Land, Galaxy Heroes,The Doge Pound, Yakuza Cats Society, Wicked Ape Bone Club, Rogue Society Bot, ZED-RUN ...",
  "nftBlindBoxs_99": "A random number of tokens for the eight participating projects of CryptoCup",
  "nft_auction_01": "Premium projects selected by BitForex to create the unique NFT.",
  "nft_auction_02": "BitForex NFT acution adopted the Dutch Auction method.",
  "nft_auction_03": "<li>\nAuction Fund: USDT balance on your Wallet Account can be used to place your bid. Bids will not be available for you when your Wallet Account USDT balance is less than the auction price, and you may lose your biding opportunity. Please refer to the initial biding price of the auction and the amount you would like to obtain to deposit USDT before the auction starts.</li><li>\nEnter the Auction Rounds to participate: One NFT can have multiple rounds of auctions. Participants can enter the auction page at anytime but can only place a bid when the auction started and before it’s ended. Auction prices will degrade at certain time from the initial auction price, participants can place a bid to obtain the NFT at anytime with the current price listed on the auction page.</li><li> \nPayment: Participants can place a bid/order at anytime with the current price during the auction time and payment amount will be deducted from your Wallet Account balance. Invalid balance for the NFT bidding or invalid auction amount can cause the fail to place an order.</li><li>\nAuction End Time: Each auction round will end when NFT product are sold out or when the auction ending time is reached.</li>",
  "nft_auction_03_1": "BitForex NFT Auction adopted the Dutch Auction Method.",
  "nft_auction_04": "Auction Process Guide",
  "nft_auction_05": "Learn more",
  "nft_auction_06": "Got it",
  "nft_auction_07": "My Action Account",
  "nft_auction_08": "Available USDT Balance",
  "nft_auction_09": "USDT in your wallet account will be used on the auction",
  "nft_auction_10": "Transfer",
  "nft_auction_11": "{n} NFT Collectibles",
  "nft_auction_12": "Auction Order",
  "nft_auction_13": "Auction Order",
  "nft_auction_14": "All",
  "nft_auction_15": "Auction in progress",
  "nft_auction_16": "Coming soon",
  "nft_auction_17": "Enter",
  "nft_auction_17_1": "Ended",
  "nft_auction_17_2": "Starting soon",
  "nft_auction_18": "Auction in progress",
  "nft_auction_19": "Auction Product Value",
  "nft_auction_20": "Initial Price",
  "nft_auction_21": "Last Price",
  "nft_auction_22": "Auction Notice",
  "nft_auction_23": "<li>Cryptocurrency trading involves significant risk and may cost your invested capital lost. Please make sure that you fully understand the risk involved and take it into consideration your level of experience, investment objectives, and seek independent financial advice if necessary.</li><li>\nNFT auctions are extremely time-sensitive and non-repeatable. Therefore, once NFT lots are sold, they cannot be returned or exchanged.</li><li>\nBefore bidding, please read the auction description and lot description carefully. The NFT asset amount and reward amount are subject to the above description. BitForex reserves the right of final explanation for the activity.</li>",
  "nft_auction_24": "I read and agree",
  "nft_auction_25": "Auction List",
  "nft_auction_26": "Current Price",
  "nft_auction_26_1": "Initial Price",
  "nft_auction_27": "Price Deduction Gradient",
  "nft_auction_28": "Last Price",
  "nft_auction_29": "Place My Bid",
  "nft_auction_30": "Starting soon",
  "nft_auction_31": "Raised/Total",
  "nft_auction_32": "Available Products",
  "nft_auction_33": "Amount",
  "nft_auction_34": "Available Balance (Wallet Account)",
  "nft_auction_35": "Price reduced {n}",
  "nft_auction_36": "Count Down",
  "nft_auction_37": "{x}{m} sold to {a} with {n} USDT",
  "nft_auction_38": "My NFT",
  "nft_auction_39": "{n} products in auction",
  "nft_auction_40": "Filter by Round #",
  "nft_auction_41": "Rewards",
  "nft_auction_41_1": "Rewards Redeemed",
  "nft_auction_42": "Transfer NFT",
  "nft_auction_43": "Not available utill the starting date.",
  "nft_auction_44": "Redeem Rewards",
  "nft_auction_45": "I read and agree",
  "nft_auction_46": "Log in first",
  "nft_auction_47": "Auction List",
  "nft_auction_48": "Current Price/ Initial Price",
  "nft_auction_49": "Price Deduction Gradient",
  "nft_auction_50": "Last Price",
  "nft_auction_51": "Place My Bid",
  "nft_auction_52": "Starting soon",
  "nft_auction_53": "Auction Balance",
  "nft_auction_54": "Available Products",
  "nft_auction_55": "Amount",
  "nft_auction_56": "Available Balance (Wallet Account)",
  "nft_auction_57": "Price reduced {n}",
  "nft_auction_58": "Count Down",
  "nft_auction_59": "{x}{m} sold to {a} with {n} USDT",
  "nft_auction_60": "Notice",
  "nft_auction_61": "Auction page can be overloaded, page data display delay can occur, please refresh this page before making an order to ensure the data accuracy",
  "nft_auction_62": "My NFT",
  "nft_auction_63": "{n} products in auction",
  "nft_auction_64": "Filter by Round #",
  "nft_auction_65": "Rewards/Rewards Redeemed",
  "nft_auction_66": "Transfer NFT",
  "nft_auction_67": "Not available utill the starting date.",
  "nft_auction_68": "Redeem Rewards",
  "nft_auction_69": "Order Confirmation",
  "nft_auction_70": "Payment Total {x}",
  "nft_auction_71": "Confirm",
  "nft_auction_72": "SOLD",
  "nft_auction_73": "Purchased {amount} {name}",
  "nft_auction_74": "My Collection",
  "nft_auction_75": "My Orders",
  "nft_auction_76": "Continue",
  "nft_auction_77": "Order Failed",
  "nft_auction_78": "Confirm",
  "nft_auction_79": "Auction History",
  "nft_auction_80": "Notice",
  "nft_auction_81": "Auction page can be overloaded, page data display delay can occur, please refresh this page before making an order to ensure the data accuracy",
  "nft_auction_82": "Activity Name",
  "nft_auction_83": "Aution Name",
  "nft_auction_84": "Unit Price",
  "nft_auction_85": "Order Amount",
  "nft_auction_86": "Order Total",
  "nft_auction_87": "Time",
  "nft_auction_88": "NFT Auction",
  "nft_auction_89": "Ended",
  "nft_auction_90": "Log in first",
  "nft_auction_colon": ":",
  "noData": "No Data",
  "notFind_address": "Go to",
  "notFind_addressCsc": "Fiat to Token",
  "notFind_addressHome": "Home",
  "notFind_addressTradeBB": "Token Trading",
  "notFind_addressTradeQH": "Contact",
  "notFind_title": "Sorry! The page could not be reached.",
  "notFind_why": "The could be because:",
  "notFind_why1": "The URL has an error",
  "notFind_why11": "> Please check if the address is complete or if there are extra characters",
  "notFind_why2": "The URL has expired",
  "notFind_why21": "> The page you are looking for no longer exists, or has moved to a new location.",
  "novice_close": "Close",
  "novice_content1": "Choose the token you want to trade<br><br>In USDT, BTC, or ETH pricing; Click the star icon on trading pairs to add them to your favorites.",
  "novice_content2": "Latest orders and trading records shown on this page may help you with decision making during trading.",
  "novice_content3": "Place your order here, set the price and quantity you want to trade. Deals will be made once meeting or exceeding desired amount.",
  "novice_content4": "View account balance of current trading pair here.",
  "novice_content5": "View current authorization and authorization history here.",
  "novice_content6": "Congratulations! You've completed the platform overview.",
  "novice_content7": "You can transfer the currency assets here that need to be traded to the spot trading account.",
  "novice_next": "Next Step",
  "novice_title1": "Choose Trading Pair",
  "novice_title2": "Quotations",
  "novice_title3": "Place order",
  "novice_title4": "Current Balance",
  "novice_title5": "Order Review",
  "novice_title6": "Complete",
  "order_0": "Spot Orders",
  "order_1": "Open Orders",
  "order_10": "Order Amount",
  "order_11": "Filled Amount",
  "order_12": "Price",
  "order_13": "Avg. Filled Price",
  "order_14": "Order Status",
  "order_15": "Orders",
  "order_16": "View ID",
  "order_17": "Not Filled",
  "order_18": "Partially Filled",
  "order_19": "Filled",
  "order_2": "Order History",
  "order_20": "Partially Cancelled",
  "order_21": "Canceled",
  "order_22": "Cancel",
  "order_23": "Cancel All",
  "order_24": "Limit Buy",
  "order_25": "Limit Sell",
  "order_26": "Confirm cancel all orders?",
  "order_27": "Copy",
  "order_28": "Hide \"Cancelled\"",
  "order_29": "Details  ",
  "order_3": "Trade History",
  "order_30": "Time",
  "order_31": "Price",
  "order_32": "Amount",
  "order_33": "Filled Value",
  "order_34": "Fee",
  "order_35": "Show only the latest 30 records",
  "order_36": "All",
  "order_37": "Copied",
  "order_38": "Cancelled",
  "order_39": "Inquire",
  "order_4": "All",
  "order_40": "No Data",
  "order_41": "Buy",
  "order_42": "Sell",
  "order_43": "Load more",
  "order_44": "Confirm",
  "order_45": "Cancel",
  "order_46": "Perpetual Orders",
  "order_47": "To improve system performance, BitForex will delete historical data older than 3 months at regular intervals based on memory usage.",
  "order_48": "Perpetual (Simulated Trading)",
  "order_49": "Order History (Before2020/10/30)",
  "order_5": "Buy",
  "order_50": "Download CSV",
  "order_50111": "Download CSV",
  "order_51": "The download option is only available for current page of data.If you need to download more data, please check the amount of data you need to download in the \"Lines\" selection box below. (Up to 5,000 Lines/Page)",
  "order_6": "Sell",
  "order_7": "Time",
  "order_8": "Pair",
  "order_9": "Order Type",
  "order_show7": "Only display records within 7 days",
  "pagination_curPage": "Current Page {cur}",
  "pagination_lines": "Lines",
  "pagination_nextPage": "Next Page",
  "pagination_prePage": "Previous Page",
  "pagination_total": "A total of {total}",
  "paymentCards_1": "Payment Cards",
  "paymentCards_10": "Amount",
  "paymentCards_11": "Min.",
  "paymentCards_12": "Max.",
  "paymentCards_13": "Deposit address",
  "paymentCards_14": "I read and agree to",
  "paymentCards_15": "<BitForex {service} User Agreement>",
  "paymentCards_16": "Continue",
  "paymentCards_17": "Deposit History",
  "paymentCards_18": "Please choose a deposit method",
  "paymentCards_19": "Your account is frozen, please contact the customer suppor team.",
  "paymentCards_2": "Cryptocurrency",
  "paymentCards_20": "Transaction Completed",
  "paymentCards_21": "Please confirm",
  "paymentCards_22": "{name} is a linked website or service (Third-Party Service) provided by MoneySwap OÜ, an Estonian company (MoneySwap). For your convenience we have taken steps to make {name} more accessible to you, and easier for you to use. However, it remains a Third-Party Service.BitForex will not be responsible for any infomational, data, suggestions, comlaints, or claim errors of {name}.",
  "paymentCards_23": "Next time",
  "paymentCards_24": "Buy Now",
  "paymentCards_25": "new",
  "paymentCards_26": "pending",
  "paymentCards_27": "cancelled",
  "paymentCards_28": "paid",
  "paymentCards_29": "order_failed",
  "paymentCards_3": "Optional",
  "paymentCards_30": "Back to Payment Cards page",
  "paymentCards_31": "Coin/Token",
  "paymentCards_32": "Credit / Debit Card",
  "paymentCards_33": "Please choose a coin and payment method",
  "paymentCards_34": "Payment Details",
  "paymentCards_35": "Coin/Token",
  "paymentCards_36": "Select a payment method",
  "paymentCards_37": "Fees",
  "paymentCards_38": "Instant Transfer",
  "paymentCards_39": "Purchase had been completed, cancellation is not supported.",
  "paymentCards_4": "Activity",
  "paymentCards_40": "Canceled",
  "paymentCards_41": "Confirmed",
  "paymentCards_42": "Order has been canceled",
  "paymentCards_43": "Cancel",
  "paymentCards_44": "In progress..",
  "paymentCards_45": "complete",
  "paymentCards_46": "canceled",
  "paymentCards_47": "disputed",
  "paymentCards_48": "Receiving amount will have a small difference with the purchased amount due to the unpredictable mining fee.",
  "paymentCards_49": "The estimated fee for {selectCoin} purchase is {fees} {selectCoin}, {fees} {selectCoin} will be charged  when the fee calculations with the rate of {configFee}% is lower than {fees} {selectCoin}",
  "paymentCards_5": "Service provider",
  "paymentCards_50": "Estimated Fee",
  "paymentCards_51": "Refund",
  "paymentCards_52": "Topping up",
  "paymentCards_53": "purchase record",
  "paymentCards_6": "Payment method",
  "paymentCards_60": "waitingPayment",
  "paymentCards_61": "waitingAuthorization",
  "paymentCards_62": "order_failed",
  "paymentCards_63": "1. You need to use the account registered on the page after the jump to complete the purchase.",
  "paymentCards_64": "2. The purchase of cryptocurrency through Onmeta requires Real-name authentication. The authentication is carried out by the Onmeta platform. Please refer to the specific authentication content.<br><a href=\\\"https://docs.onmeta.in/widget/user-faqs \" target=\\\"_blank\\\">https://docs.onmeta.in/widget/user-faqs </a>",
  "paymentCards_65": "3. Onmeta has a limit on the number of purchases per transaction, per day, and per month. If you encounter problems during the payment process, please consult Onmeta customer service for solutions.",
  "paymentCards_66": "You need to use the account registered on the page after the jump to complete the purchase.",
  "paymentCards_67": "The purchase of cryptocurrency through Onmeta requires Real-name authentication. The authentication is carried out by the Onmeta platform. Please refer to the specific authentication content.<br><a href=\"https://docs.onmeta.in/widget/user-faqs\" target=\"_blank\">https://docs.onmeta.in/widget/user-faqs </a>",
  "paymentCards_68": "Onmeta has a limit on the number of purchases per transaction, per day, and per month. If you encounter problems during the payment process, please consult Onmeta customer service for solutions.",
  "paymentCards_69": "You need to use the account registered on the page after the jump to complete the purchase.",
  "paymentCards_7": "Available coins",
  "paymentCards_70": "The purchase of cryptocurrency through Onmeta requires Real-name authentication. The authentication is carried out by the Onmeta platform. Please refer to the specific authentication content.<br><a href=\"https://docs.onmeta.in/widget/user-faqs\" target=\"_blank\">https://docs.onmeta.in/widget/user-faqs </a>",
  "paymentCards_71": "Onmeta has a limit on the number of purchases per transaction, per day, and per month. If you encounter problems during the payment process, please consult Onmeta customer service for solutions.",
  "paymentCards_8": "Activity",
  "paymentCards_9": "Choose coin to purchase",
  "PERPETUALUP001": "system error",
  "PERPETUALUP002": "param error",
  "PERPETUALUP003": "symbol not exist",
  "PERPETUALUP005": "operation timed out",
  "PERPETUALUP006": "marginType type error",
  "perpetual_1": "Account Info ",
  "perpetual_10": "Unrealised PNL",
  "perpetual_100": "Remaining",
  "perpetual_101": "Fill Price",
  "perpetual_102": "Order Value",
  "perpetual_103": "Filled Value",
  "perpetual_104": "Order Type",
  "perpetual_105": "Status",
  "perpetual_106": "New Orders",
  "perpetual_107": "Filled",
  "perpetual_108": "Canceled",
  "perpetual_109": "Partially Cancelled",
  "perpetual_11": "Position Margin",
  "perpetual_110": "Partially Filled",
  "perpetual_111": "Order ID",
  "perpetual_112": "Fees",
  "perpetual_113": "Cancel All",
  "perpetual_114": "Cancel",
  "perpetual_115": "View All",
  "perpetual_116": "Limit",
  "perpetual_117": "Buy",
  "perpetual_118": "Sell",
  "perpetual_119": "Buy {Symbol} {Amount} cont at {Price}",
  "perpetual_12": "Order Margin",
  "perpetual_120": "Sell {Symbol} {Amount} cont at {Price}",
  "perpetual_121": "Order Price",
  "perpetual_122": "Cost",
  "perpetual_123": "Available Balance",
  "perpetual_124": "Mark Price",
  "perpetual_125": "Estimated Liq. Price",
  "perpetual_126": "Hide",
  "perpetual_127": "Cancel",
  "perpetual_128": "Please log in first",
  "perpetual_129": "Please enter price",
  "perpetual_13": "Margin Rate",
  "perpetual_130": "Please enter amount",
  "perpetual_131": "The contract min. trading amount is {Amount}.",
  "perpetual_132": "Order failed! The max. Cont of single contract order is {Num} Cont",
  "perpetual_133": "The quantity must be an integer multiple of the minimum trading unit {Num}.",
  "perpetual_134": "Fail to place order! Buying price should not exceed than {price}.",
  "perpetual_135": "Failed to place order! Buying price should not be less than {price}.",
  "perpetual_136": "Fail to place order! Selling price should not exceed {price}.",
  "perpetual_137": "Fail to place order! Selling price should not be less than {price}.",
  "perpetual_138": "Place order successfully",
  "perpetual_139": "Back",
  "perpetual_14": "Margin Rate = (Position Margin + Order Margin) / Equity",
  "perpetual_140": "Please enter price",
  "perpetual_141": "Fill Price",
  "perpetual_142": "Cancel failed",
  "perpetual_143": "Cancel succeed",
  "perpetual_144": "Action",
  "perpetual_145": "The price must be an integer multiple of the minimum price unit {Num}",
  "perpetual_146": "Simulated Trading",
  "perpetual_147": "Check ",
  "perpetual_148": "Leaderboard Ranking",
  "perpetual_149": "Event Center",
  "perpetual_15": "Asset Transfer",
  "perpetual_150": "Receive Virtual BTC",
  "perpetual_151": "{num} Hours",
  "perpetual_152": "(Cross)",
  "perpetual_153": "Realised PNL of current position ",
  "perpetual_154": "Realized PNL cumulative value",
  "perpetual_155": "Max. Position Quantity",
  "perpetual_156": "Order failed! Your max. position is {num}",
  "perpetual_157": "How to close a position?",
  "perpetual_158": "Users can place a inverse position order to close a position on BitForex Perpetual Contract trading. If your inverse position order amount is greater than your current position, the amount left will be placed as a new order.",
  "perpetual_159": "Your demo account balance is running low. We're providing you with an additional 1 Virtual BTC for you to try again.",
  "perpetual_16": "Symbol",
  "perpetual_160": "Max. open",
  "perpetual_161": "Type",
  "perpetual_162": "Limit",
  "perpetual_163": "Forced Liquidation",
  "perpetual_164": "Funding",
  "perpetual_165": "The position is forced liquidated at {liq} USD .It is taken over and settled at the bankruptcy price {brp} USD.",
  "perpetual_166": "IMR",
  "perpetual_167": "BBO",
  "perpetual_168": "BBO(5) is a special limit order. The order will be placed at the best price in the order book (Normally it will be the five-level market depth of bid/ask orders.)",
  "perpetual_169": "Conditional",
  "perpetual_17": "Expiry Date",
  "perpetual_170": "Trigger Price",
  "perpetual_171": "Close Position",
  "perpetual_172": "Close {amount} contract(s) at {price} USD",
  "perpetual_173": "Order failed! This order can only reduce {amount} current position. Because there are active orders with better price exist",
  "perpetual_174": "Sell {amount} of {contract} at Best-Bid-Price",
  "perpetual_175": "Buy {amount} of {contract} at Best-Bid-Price",
  "perpetual_176": "Estimated Cost",
  "perpetual_177": "Buy BBO",
  "perpetual_178": "Sell BBO",
  "perpetual_179": "Close {amount} at Best-Bid-Price",
  "perpetual_18": "Initial Margin",
  "perpetual_180": "Closing order canceled! This order can only reduce current position. Since there are active orders with better price exist, the closing order exceeds the number of positions that can be closed.",
  "perpetual_181": "Qty.",
  "perpetual_182": "Closing Direction",
  "perpetual_183": "Closing Type",
  "perpetual_184": "Avg. Entry Price",
  "perpetual_185": "Closing Price",
  "perpetual_186": "Avg. closing price",
  "perpetual_187": "Closed P&L",
  "perpetual_188": "Close Position Time",
  "perpetual_189": "Closed Short",
  "perpetual_19": "Maint. Margin",
  "perpetual_190": "Closed Long",
  "perpetual_191": "Forced Close Short",
  "perpetual_192": "Forced Close Long",
  "perpetual_193": "Limit",
  "perpetual_194": "BBO",
  "perpetual_195": "Forced Liquidation",
  "perpetual_196": "Please enter the Trigger Price",
  "perpetual_197": "Close Position only",
  "perpetual_198": "Close Position Order will only reduce your holding position. this order amount will reduce or cancel to prevent openning new position, when Conditional Order is executed and holding position is insufficient to be closed.",
  "perpetual_199": "Order failed! Order price cannot be higher than forced liquidation price {current}",
  "perpetual_2": "Chart",
  "perpetual_20": "{base} Lending Rate",
  "perpetual_200": "Order failed! Order price cannot be less than forced liquidation price {current}",
  "perpetual_201": "Conditional",
  "perpetual_202": "Order fail! Every contract can have no more than 10 unexecuted conditional orders. ",
  "perpetual_203": "Click here to edit",
  "perpetual_204": "Single Cont. value 1 USD",
  "perpetual_205": "BBO (5)",
  "perpetual_21": "Open Taker Fee",
  "perpetual_22": "Close Taker Fee ",
  "perpetual_23": "Funding Rate",
  "perpetual_24": "{price} Lending Rate",
  "perpetual_25": "Funding Interval",
  "perpetual_26": "Next Funding",
  "perpetual_27": "Mark Price",
  "perpetual_28": "Auto-Deleveraging Enabled",
  "perpetual_29": "YES",
  "perpetual_3": "Contract Info ",
  "perpetual_30": "NO",
  "perpetual_31": "Risk Limit ",
  "perpetual_32": "Open Contracts",
  "perpetual_33": "24h Turnover",
  "perpetual_34": "Total Volume",
  "perpetual_35": "Contract Size",
  "perpetual_36": "Min. Price Increment",
  "perpetual_37": "Max. Order Quantity",
  "perpetual_38": "Min. Order Quantity",
  "perpetual_39": "Fees",
  "perpetual_4": "Equity",
  "perpetual_40": "Maker Fee",
  "perpetual_41": "Taker Fee",
  "perpetual_42": "Index Price",
  "perpetual_43": "Current Index Price Composition",
  "perpetual_44": "Mark Price",
  "perpetual_45": "24h Change",
  "perpetual_46": "24h Volume",
  "perpetual_47": "Funding",
  "perpetual_48": "Next Funding",
  "perpetual_49": "Current Funding Rate",
  "perpetual_5": "Equity = Transfer in - Transfer out + Realised PNL + Unrealised PNL",
  "perpetual_50": "Current Direction",
  "perpetual_51": "Longs pay to shorts",
  "perpetual_52": "Shorts pay to longs",
  "perpetual_53": "Orders",
  "perpetual_54": "Limit ",
  "perpetual_55": "Market",
  "perpetual_56": "Qty",
  "perpetual_57": "Cont ",
  "perpetual_58": "Price",
  "perpetual_59": "Risk Rate",
  "perpetual_6": "Available Balance",
  "perpetual_60": "Risk Rate = (Maint. Margin / Equity) * 100%",
  "perpetual_61": "When the risk rate reaches 100%, the position will be forced liquidated.",
  "perpetual_62": "Order Value",
  "perpetual_63": "Available Balance",
  "perpetual_64": "Buy/Long",
  "perpetual_65": "Sell/Short",
  "perpetual_66": "Cost",
  "perpetual_67": "Order Confirm",
  "perpetual_68": "Check to allow order confirmation pop-up.",
  "perpetual_69": "Order Book",
  "perpetual_7": "Available Balance = Transfer in - Transfer out + Realised PNL - Unrealised PNL - Position Margin - Order Margin",
  "perpetual_70": "Spread",
  "perpetual_71": "Total",
  "perpetual_72": "Qty",
  "perpetual_73": "Price",
  "perpetual_74": "More",
  "perpetual_75": "Trades",
  "perpetual_76": "Side",
  "perpetual_77": "Price",
  "perpetual_78": "Qty",
  "perpetual_79": "Time",
  "perpetual_8": "Realised PNL",
  "perpetual_80": "Positions",
  "perpetual_81": "Open Orders",
  "perpetual_82": "Fills",
  "perpetual_83": "Order History",
  "perpetual_84": "Symbol",
  "perpetual_85": "Qty",
  "perpetual_86": "Value",
  "perpetual_87": "Entry Price",
  "perpetual_88": "Mark Price",
  "perpetual_89": "Liq. Price",
  "perpetual_9": "Realized PNL can be used for margin trading, and it cannot be transferred between your Spot Account and Perpetual Account before it is settled. Settlement time: 16:00:00 GMT+8 every day.",
  "perpetual_90": "Margin",
  "perpetual_91": "PNL",
  "perpetual_92": "ROE %",
  "perpetual_93": "Realised PNL",
  "perpetual_94": "Close",
  "perpetual_95": "Limit Close",
  "perpetual_96": "Market Close",
  "perpetual_97": "Order Qty",
  "perpetual_98": "Order Price",
  "perpetual_99": "Filled Qty",
  "perpetual_bbo": "BBO (5)",
  "phonecode_0": "Albania",
  "phonecode_1": "Andorra",
  "phonecode_10": "Alaska(U.S.A)",
  "phonecode_100": "Iran",
  "phonecode_101": "Israel",
  "phonecode_102": "India",
  "phonecode_103": "Indonesia",
  "phonecode_104": "Jamaica",
  "phonecode_105": "Japan",
  "phonecode_106": "Jordan",
  "phonecode_107": "Kiribati",
  "phonecode_108": "Kenya",
  "phonecode_109": "Korea(dpr of)",
  "phonecode_11": "Armenia",
  "phonecode_110": "Kazakhstan",
  "phonecode_111": "Korea(republic of)",
  "phonecode_112": "Kyrgyzstan",
  "phonecode_113": "Kampuchea",
  "phonecode_114": "Kuwait",
  "phonecode_115": "Latvia",
  "phonecode_116": "Lithuania",
  "phonecode_117": "Liechtenstein",
  "phonecode_118": "Luxembourg",
  "phonecode_119": "Lesotho",
  "phonecode_12": "Anguilla I.",
  "phonecode_120": "Liberia",
  "phonecode_121": "Libya",
  "phonecode_122": "Laos",
  "phonecode_123": "Lebanon",
  "phonecode_124": "Malta",
  "phonecode_125": "Macedonia",
  "phonecode_126": "Moldova",
  "phonecode_127": "Monaco",
  "phonecode_128": "Marshall Is.",
  "phonecode_129": "Micronesia",
  "phonecode_13": "Aruba I.",
  "phonecode_130": "Madagascar",
  "phonecode_131": "Malawi",
  "phonecode_132": "Mali",
  "phonecode_133": "Mauritius",
  "phonecode_134": "Mauritania",
  "phonecode_135": "Morocco",
  "phonecode_136": "Mozambique",
  "phonecode_137": "Mexico",
  "phonecode_138": "Macao",
  "phonecode_139": "Maldive",
  "phonecode_14": "Ascension",
  "phonecode_140": "Malaysia",
  "phonecode_141": "Mongolia",
  "phonecode_142": "Myanmar",
  "phonecode_143": "Mariana Is.",
  "phonecode_144": "Martinique",
  "phonecode_145": "Mayotte I.",
  "phonecode_146": "Micronesia",
  "phonecode_147": "Midway I.",
  "phonecode_148": "Montserrat I.",
  "phonecode_149": "Netherlands",
  "phonecode_15": "Belarus",
  "phonecode_150": "Norway",
  "phonecode_151": "Nauru",
  "phonecode_152": "New Zealand",
  "phonecode_153": "Namibia",
  "phonecode_154": "Niger",
  "phonecode_155": "Nigeria",
  "phonecode_156": "Nicaragua",
  "phonecode_157": "Nepal",
  "phonecode_158": "Netherlandsantilles Is.",
  "phonecode_159": "New Caledonia Is.",
  "phonecode_16": "Bulgaria",
  "phonecode_160": "Niue I.",
  "phonecode_161": "Norfolk I",
  "phonecode_162": "Oman",
  "phonecode_163": "Poland",
  "phonecode_164": "Portugal",
  "phonecode_165": "Papua New Guinea",
  "phonecode_166": "Palau",
  "phonecode_167": "Paraguay",
  "phonecode_168": "Panama",
  "phonecode_169": "Peru",
  "phonecode_17": "Belgium",
  "phonecode_170": "Pakistan",
  "phonecode_171": "Palestinian",
  "phonecode_172": "Philippines",
  "phonecode_173": "Palau",
  "phonecode_174": "Puerto Rico",
  "phonecode_175": "Qatar",
  "phonecode_176": "Russia",
  "phonecode_177": "Romania",
  "phonecode_178": "Rwanda",
  "phonecode_179": "Reunion I.",
  "phonecode_18": "Bosnia",
  "phonecode_180": "Serbia",
  "phonecode_181": "Sweden",
  "phonecode_182": "Switzerland",
  "phonecode_183": "San Marino",
  "phonecode_184": "Slovak",
  "phonecode_185": "Slovenia",
  "phonecode_186": "Spain",
  "phonecode_187": "Samoa",
  "phonecode_188": "Solomon Is.",
  "phonecode_189": "South Africa",
  "phonecode_19": "Benin",
  "phonecode_190": "Sierra Leone",
  "phonecode_191": "Senegal",
  "phonecode_192": "Seychelles",
  "phonecode_193": "San.Tome And Principe",
  "phonecode_194": "Swaziland",
  "phonecode_195": "Sudan",
  "phonecode_196": "Somali",
  "phonecode_197": "St.Christopher and Nevis",
  "phonecode_198": "St.Lucia",
  "phonecode_199": "Saint Vincent and the Grenadines",
  "phonecode_2": "Austria",
  "phonecode_20": "Botswana",
  "phonecode_200": "Suriname",
  "phonecode_201": "Saudi Arabia",
  "phonecode_202": "Sri Lanka",
  "phonecode_203": "Singapore",
  "phonecode_204": "Syria",
  "phonecode_205": "Samoa,Eastern",
  "phonecode_206": "Samoa,Western",
  "phonecode_207": "San.Marino",
  "phonecode_208": "San.Pierre And Miquelon I.",
  "phonecode_209": "St.Christopher and Nevis",
  "phonecode_21": "Burkina Faso",
  "phonecode_210": "St.Helena",
  "phonecode_211": "St.Vincent I.",
  "phonecode_212": "Taiwan",
  "phonecode_213": "Tonga",
  "phonecode_214": "Tuvalu",
  "phonecode_215": "Togo",
  "phonecode_216": "The Democratic Republic of Congo",
  "phonecode_217": "Tanzania",
  "phonecode_218": "Tunisia",
  "phonecode_219": "Trinidad and Tobago",
  "phonecode_22": "Burundi",
  "phonecode_220": "The United Arab Emirates",
  "phonecode_221": "Timor Leste",
  "phonecode_222": "Tajikistan",
  "phonecode_223": "Thailand",
  "phonecode_224": "Turkey",
  "phonecode_225": "Turkmenistan",
  "phonecode_226": "Tokelau Is.",
  "phonecode_227": "Turks and Caicos Is.",
  "phonecode_228": "Ukraine",
  "phonecode_229": "United Kingdom",
  "phonecode_23": "Barbados",
  "phonecode_230": "Uganda",
  "phonecode_231": "U.S.A",
  "phonecode_232": "Uruguay",
  "phonecode_233": "Uzbekistan",
  "phonecode_234": "Vatican",
  "phonecode_235": "Vanuatu",
  "phonecode_236": "Venezuela",
  "phonecode_237": "Vietnam",
  "phonecode_238": "Virgin Is.",
  "phonecode_239": "Virgin Is. and St.Croix I.",
  "phonecode_24": "Brazil",
  "phonecode_240": "Western sahara",
  "phonecode_241": "Wake I.",
  "phonecode_242": "Wallis And Futuna Is.",
  "phonecode_243": "Yemen",
  "phonecode_244": "Yugoslavia",
  "phonecode_245": "Zimbabwe",
  "phonecode_246": "Zambia",
  "phonecode_247": "Zaire",
  "phonecode_248": "Zanzibar",
  "phonecode_249": "Dominican",
  "phonecode_25": "Bolivia",
  "phonecode_26": "Belize",
  "phonecode_27": "Bahrain",
  "phonecode_28": "Bhutan",
  "phonecode_29": "Bangladesh",
  "phonecode_3": "Australia",
  "phonecode_30": "Brunei Darussalam",
  "phonecode_31": "Bermuda Is.",
  "phonecode_32": "Bosnia And Herzegovina",
  "phonecode_33": "Czech",
  "phonecode_34": "Croatia",
  "phonecode_35": "Cook Islands",
  "phonecode_36": "Cape Verde",
  "phonecode_37": "Congo",
  "phonecode_38": "Cameroon",
  "phonecode_39": "Comoro",
  "phonecode_4": "Algeria",
  "phonecode_40": "Chad",
  "phonecode_41": "Central Africa",
  "phonecode_42": "Commonwealth of The Bahamas",
  "phonecode_43": "Colombia",
  "phonecode_44": "Costa Rica",
  "phonecode_45": "Cuba",
  "phonecode_46": "Canada",
  "phonecode_47": "Chile",
  "phonecode_48": "Cyprus",
  "phonecode_49": "China",
  "phonecode_5": "Angola",
  "phonecode_50": "Canaries Is.",
  "phonecode_51": "Cayman Is.",
  "phonecode_52": "Christmas I.",
  "phonecode_53": "Cocos I.",
  "phonecode_54": "Commonwealth of Dominica",
  "phonecode_55": "Cook IS.",
  "phonecode_56": "Denmark",
  "phonecode_57": "Djibouti",
  "phonecode_58": "Dominica",
  "phonecode_59": "Dominican Republic",
  "phonecode_6": "Argentina",
  "phonecode_60": "Diego Garcia I.",
  "phonecode_61": "Estonia",
  "phonecode_62": "Egypt",
  "phonecode_63": "Ethiopia",
  "phonecode_64": "Equatorial Guinea",
  "phonecode_65": "Eritrea",
  "phonecode_66": "Ecuador",
  "phonecode_67": "El Salvador",
  "phonecode_68": "France",
  "phonecode_69": "Finland",
  "phonecode_7": "Antigua and Barbuda",
  "phonecode_70": "Fiji",
  "phonecode_71": "Falkland Is.",
  "phonecode_72": "Faroe Is.",
  "phonecode_73": "French Guiana",
  "phonecode_74": "French Polynesia",
  "phonecode_75": "Germany",
  "phonecode_76": "Greece",
  "phonecode_77": "Gambia",
  "phonecode_78": "Guinea",
  "phonecode_79": "Guinea-Bissau",
  "phonecode_8": "Afghanistan",
  "phonecode_80": "Ghana",
  "phonecode_81": "Gabon",
  "phonecode_82": "Grenada",
  "phonecode_83": "Guyana",
  "phonecode_84": "Guatemala",
  "phonecode_85": "Georgia",
  "phonecode_86": "Gibraltar",
  "phonecode_87": "Greenland",
  "phonecode_88": "Guadeloupe I.",
  "phonecode_89": "Guam",
  "phonecode_9": "Azerbaijan",
  "phonecode_90": "Hong Kong",
  "phonecode_91": "Hungary",
  "phonecode_92": "Haiti",
  "phonecode_93": "Honduras",
  "phonecode_94": "Hawaii",
  "phonecode_95": "Ireland",
  "phonecode_96": "Iceland",
  "phonecode_97": "Italy",
  "phonecode_98": "Ivory Coast",
  "phonecode_99": "Iraq",
  "plogin_1": "Welcome to BitForex Perpetual!",
  "plogin_2": "Please <a href='{login}'> Login </a> or <a href='{register}'> Register </a> first to trade the Perpetual Contract",
  "plogin_3": "Try <a href='{link}'> Perpetual Simulation&gt;&gt; </a> now! No login needed",
  "plogin_4": "Trade on <a href='{link}'> Perpetual Simulation&gt;&gt; </a>",
  "plogin_5": "Check on <a href='https://img-cdn.bitforex.vip/perpetual-tutorial-en.pdf' target='_blank'>BitForex Beginner's Guide&gt;&gt;</a>",
  "plogin_6": "Beginner's Guide",
  "polkadot_desc_01": "Polkadot is built to connect private and consortium chains, public and permissionless networks, oracles, and future technologies that are yet to be created. Polkadot facilitates an internet where independent blockchains can exchange information and transactions in a trustless way via the Polkadot relay chain.",
  "polkadot_desc_02": "Web3 is working with researchers from Inria Paris and ETH Zurich, developers from Parity Technologies, and capital partners such as Polychain Capital to develop a superlative realization of Web3, with Polkadot at its core.",
  "polkadot_desc_03": "Blockchain games are any games that include blockchain technology in their backend or mechanics in general.",
  "polkadot_title": "Polkadot",
  "pto_1": "Premium Token Sale Round 2",
  "pto_2": "Time: 2020/05/25 17:00 - 2020/05/27 17:00 (GMT+8)",
  "p_m_1": "Perpetual Contract Trading System Maintenance Notice",
  "p_m_2": "An unexpected Perpetual Contract Trading system maintenance is in progress. This maintenence will last about 10 minutes and Perpetual Contract Trading will not be available during this time period.",
  "p_m_3": "My Account",
  "p_m_4": "Spot Trading",
  "p_m_5": "Back to Home",
  "p_m_6": "Read more",
  "p_m_7": "My Account",
  "p_m_8": "Back to Home",
  "rebate2_1": "Send the invitation link to your friends",
  "rebate2_10": "Spot commission",
  "rebate2_11": "Total commission (BTC)",
  "rebate2_12": "Invitees",
  "rebate2_13": "My commission",
  "rebate2_14": "My invites",
  "rebate2_15": "Commision tokens",
  "rebate2_16": "Last week's commission",
  "rebate2_17": "Total",
  "rebate2_18": "My invitation code",
  "rebate2_19": "Save image and share it with firnds",
  "rebate2_2": "Invite your friends to register and trade on BitForex spot & perpetual contract",
  "rebate2_20": "Save image",
  "rebate2_21": "Copy",
  "rebate2_22": "Expiration Date: 2019-08-28",
  "rebate2_23": "TOTAL COMMISSION (USD)",
  "rebate2_24": "INVITES",
  "rebate2_25": "Knight",
  "rebate2_26": "General",
  "rebate2_27": "until",
  "rebate2_28": "Search",
  "rebate2_29": "Day",
  "rebate2_3": "Get commissions.",
  "rebate2_30": "Month",
  "rebate2_31": "Date",
  "rebate2_32": "Invites/person",
  "rebate2_33": "Total Turnover",
  "rebate2_34": "Trading fee",
  "rebate2_35": "Net Profit",
  "rebate2_36": "Commission Spent",
  "rebate2_37": "Net Commision Income",
  "rebate2_38": "Operation",
  "rebate2_39": "Details",
  "rebate2_4": "Share",
  "rebate2_40": "Go back",
  "rebate2_41": "Register Time",
  "rebate2_42": "User Type",
  "rebate2_43": "Trading Turnover",
  "rebate2_44": "Commission Rate",
  "rebate2_45": "Commission Spent Rate",
  "rebate2_46": "Your Commission",
  "rebate2_47": "Register on BitForex",
  "rebate2_48": "Welcome to BitForex",
  "rebate2_49": "I would like to invite you to join BitForex. Scan the QR code to register.",
  "rebate2_5": "My invitation code",
  "rebate2_6": "My invitarion link",
  "rebate2_7": "Download invitation page",
  "rebate2_8": "Commission Ranking",
  "rebate2_9": "Apply Now",
  "rebate3_1": "COIN-M Contracts Trading Commission",
  "rebate3_10": "Accumulated Commission",
  "rebate3_11": "Ambassador Platform",
  "rebate3_12": "Data analysis",
  "rebate3_13": "Overview",
  "rebate3_14": "Commission record query",
  "rebate3_15": "Partner list",
  "rebate3_16": "All order query",
  "rebate3_17": "Deposit and withdrawal statistics",
  "rebate3_18": "Update time",
  "rebate3_19": "New Deposit yesterday(Team)",
  "rebate3_2": "USDT-M Contracts Trading Commission",
  "rebate3_20": "New withdrawal yesterday(Team)",
  "rebate3_21": "Trading volume yesterday (Team)",
  "rebate3_22": "Overview trading fee yesterday (Team)",
  "rebate3_23": "Active users yesterday (Futures)",
  "rebate3_24": "Yesterday's commission (Team)",
  "rebate3_25": "Accumulated deposit (Team)",
  "rebate3_26": "Accumulated withdrawal (Team)",
  "rebate3_27": "New registrations (Team)",
  "rebate3_28": "Total trading fee overview (Team)",
  "rebate3_29": "Commission amount (Team)",
  "rebate3_3": "Yesterday",
  "rebate3_30": "Trading volume (Team)",
  "rebate3_31": "Futures trading users (Team)",
  "rebate3_32": "Commission summary",
  "rebate3_33": "Affiliate team's trading fee",
  "rebate3_34": "User’s UID",
  "rebate3_35": "Team's trading fee",
  "rebate3_36": "Team's contribution commision",
  "rebate3_37": "Commission distribution time",
  "rebate3_38": "Expected commission amount",
  "rebate3_39": "Commission amount",
  "rebate3_4": "Past 7 days",
  "rebate3_40": "Arrival time",
  "rebate3_41": "Whether to distribute",
  "rebate3_42": "Total commission",
  "rebate3_43": "Total deposit",
  "rebate3_44": "Total withdrawal",
  "rebate3_45": "Contract name",
  "rebate3_46": "Close long position",
  "rebate3_47": "Close short position",
  "rebate3_48": "withdrawal",
  "rebate3_49": "deposit",
  "rebate3_5": "Past 30 days",
  "rebate3_51": "Today",
  "rebate3_52": "total handling fee",
  "rebate3_53": "Rebate Record",
  "rebate3_54": "Accumulate Invitee",
  "rebate3_55": "Invitee Deposit Amount(In 7 days)",
  "rebate3_56": "Invitee Withdrawal Amount(In 7 days)",
  "rebate3_57": "General Referral",
  "rebate3_58": "Invited-Affiliate",
  "rebate3_59": "Trading fee (by normal referral)",
  "rebate3_6": "Commision",
  "rebate3_60": "Commission (by normal referral)",
  "rebate3_61": "Trading fee (by invited-affiliate)",
  "rebate3_62": "Commission (by invited-affiliate)",
  "rebate3_63": "User",
  "rebate3_64": "UDST",
  "rebate3_65": "Default",
  "rebate3_66": "Ascending order",
  "rebate3_67": "Descending order",
  "rebate3_68": "Sort",
  "rebate3_69": "Affiliate Identity",
  "rebate3_7": "User List",
  "rebate3_70": "All Invitee Amount",
  "rebate3_71": "Deposited Invitee Amount",
  "rebate3_8": "Yesterday Trading Volume",
  "rebate3_9": "Accumulated Trading Volume",
  "rebate_1": "Spot Trading",
  "rebate_10": "Users",
  "rebate_11": "Invitation Details",
  "rebate_12": "Search",
  "rebate_13": "Daily",
  "rebate_14": "Monthly",
  "rebate_15": "Date",
  "rebate_16": "Number of Rebates",
  "rebate_17": "Total Volume",
  "rebate_18": "Fees Produced",
  "rebate_19": "Net Profit",
  "rebate_2": "Perpetual Trading",
  "rebate_20": "Shared Commission",
  "rebate_21": "My Commission",
  "rebate_22": "Action",
  "rebate_23": "Registration Time",
  "rebate_24": "User Type",
  "rebate_25": "Trading Volume",
  "rebate_26": "Fees Produced",
  "rebate_27": "My Commission Rate",
  "rebate_28": "Net Profit",
  "rebate_29": "Shared Rate",
  "rebate_3": "Invitation Link",
  "rebate_30": "Shared Amount",
  "rebate_31": "My Commission",
  "rebate_32": "Level 1",
  "rebate_33": "Level 2",
  "rebate_34": "Level 3",
  "rebate_35": "Total",
  "rebate_36": " Back ",
  "rebate_37": " Token  ",
  "rebate_38": " Commission of Last Week  ",
  "rebate_39": " Total  ",
  "rebate_4": "Invitation Code",
  "rebate_40": " Username  ",
  "rebate_41": " Rules  ",
  "rebate_42": " Invite your friends to register on BitForex by sharing your exclusive invitation link. Every time a friend completes a transaction, you will get multiple trading fees commission.  ",
  "rebate_43": " Invite first level friends, you can get 30% trading fees commission.  ",
  "rebate_44": " Invite second level friends, you can get 10% trading fees commission.  ",
  "rebate_45": " Invite third level friends, you can get 10% trading fees commission.  ",
  "rebate_46": " FAQ  ",
  "rebate_47": " What are \"LEVEL-1/2/3 friends\"?  ",
  "rebate_48": " LEVEL-1 friends:who register via your invitation link;  ",
  "rebate_49": " LEVEL-2 friends: who register via your \"level 1 friends\" invitation links;  ",
  "rebate_5": "Total Commission",
  "rebate_50": " LEVEL-3 friends:who register via your \"level 2 friends\" invitation links;  ",
  "rebate_51": " Examples  ",
  "rebate_52": " Trading Fees Commission Rate  ",
  "rebate_53": "Your Current Commission",
  "rebate_54": " LEVEL-1 friends commission  ",
  "rebate_55": " LEVEL-2 friends commission  ",
  "rebate_56": " LEVEL-3 friends commission  ",
  "rebate_57": " Invite Friends - Get multiple Trading Commssion Rewards  ",
  "rebate_58": " Share my invitation link to  ",
  "rebate_59": " Where can I find trading fees for different tokens/coins?  ",
  "rebate_6": "Contract Expiry",
  "rebate_60": " You can find you by clicking \"Fees\" on the bottom of the page.  ",
  "rebate_61": " Here is only reflecting the level one referral users ",
  "rebate_62": "Details",
  "rebate_63": "Invitation Commissions",
  "rebate_64": "My commissions",
  "rebate_65": "My Invitation",
  "rebate_66": "Platform",
  "rebate_67": "Commission Produced Invitees",
  "rebate_68": "Total",
  "rebate_69": "Total {number} invitee(s) on {Date}",
  "rebate_7": "Invitee",
  "rebate_70": "Manager's Invitees",
  "rebate_71": "Managers",
  "rebate_72": "# of users invited",
  "rebate_73": "Data updated on",
  "rebate_74": "View More",
  "rebate_75": "Invite Your Friend By Sharing Link Or Code",
  "rebate_76": "Your Commission Summary",
  "rebate_77": "My Commission",
  "rebate_78": "My Invitation",
  "rebate_79": "Commission Details",
  "rebate_8": "Manager",
  "rebate_80": "On {day}，{rakeBackCount} out of {inviteCount} invitee(s) contributed {commissionExpenditure} {coin}",
  "rebate_81": "Status",
  "rebate_82": "Activated",
  "rebate_83": "Suspended",
  "rebate_84": "Commission Rank",
  "rebate_85": "Ranking",
  "rebate_86": "Inviter Account",
  "rebate_87": "Total Commision",
  "rebate_88": "Rebate ratio",
  "rebate_9": "Managers/Invitees",
  "registered_1": "Greetings! You have registered successfully!",
  "registered_10": "Check your {coin} bonus on the Asset page under Perpetual account.",
  "registered_11": "How to check your Perpetual Contract Bonus?",
  "registered_12": "Click the \"Trade\" button, to start your first perpetual contract.",
  "registered_13": "Check you bonus",
  "registered_2": "Claim your virtual BTC",
  "registered_3": "Go Deposit",
  "registered_4": "Here's your virtual BTC!",
  "registered_5": "Experience Simulated Trading ",
  "registered_6": "Start Your Journey Of Perpetual Contract",
  "registered_7": "You have acquired your virtual capital .",
  "registered_8": "Hello, you have successfully registered and received a $ {amount} {coin} bonus of perpetual contract!",
  "registered_9": "How to check your Perpetual Contract Bonus?",
  "ron_1": "RON",
  "ron_10": "Lock-up Period",
  "ron_11": "No Lock-up",
  "ron_12": "IMPORTANT NOTICE",
  "ron_13": "1. There is no handling fee for the transaction; \n2. The maximum order amount for each account is 200,000 RON;\n3. The minimum order amount for each account is 2,000 RON.",
  "ron_14": "Enable Verification",
  "ron_16": "REFERENCE",
  "ron_17": "RON Official Website",
  "ron_18": "https://ron-influencers.com/",
  "ron_19": "RON Whitepaper",
  "ron_2": "Top project by Japanese people",
  "ron_20": "https://ron-influencers.com/doc/whitepaper_001.pdf",
  "ron_21": "INTRODUCTION",
  "ron_22": "About RON Project",
  "ron_23": "Influencer Marketing 2.0 in 2018, the era of influencers such as YouTube and Instagram has\nentered the new phase.\nFor making your Influencer marketing successful, you have to think of how to tell your story to\nthe consumers. It is part of communication, where your content is being spread via media.\nCurrently, the market size of individual influencers is unsure yet, and the price is unclear, so it\nhas not been recognized as a publicity market.\nRON will be a platform for all influencers and followers (Sponsor and fan base) and will build a\nreal publication. Everyone can calculate content’s value.\nThe era of influencer marketing 3.0, Influencer Economy becomes a part of Sharing\nEconomy. Expect RON as it leads token economy in the real world.",
  "ron_26": "About Crypto currency “ RON ”",
  "ron_27": "The RON token (RonCoin), in conjunction with PATRON applications, is issued for “influencers\n” around the world and for“ followers ” who are the sponsors and fan base of influencers.\nThere are 4.38 billion people, who use SNS every day. This big community is creating the\ncashless society and it is rapidly progressing. It is issued in order to function as a circulating\ncurrency in the economic sphere of influencers where the influencers are mainly people who\nhave a better influence on the society in the modern age where individuals are becoming the\nleading player",
  "ron_29": "About Wallet App “ RON ”",
  "ron_30": "RON releases a payment wallet. Here, to trading the RON itself, it is possible to carry the RON with a charge and use it as a legal currency at the selected store. Also, while RON points are used in all transactions within PATRON applications, influencers who receive RON points as compensation will not only get paid in the legal currency, but will also receive special benefits and benefits based on their Influencer rank (class) through the \"WALLET APP RON\"\nYou get twice as much RON coins as the influencers earn. Of course, you can also charge RON coins to make them legal currency (HKD, USD, etc.), and that money can be deposited in a variety of digital cards, debit cards, and prepaid cards that you pre-register with the Wallet app.\nIn the future, it will be possible to transfer money to a linked bank account with one tap. In addition, we will secure the status of the currency in circulation by providing various benefits, such as additional privileges that are more significant than returning the legal currency as points.\nRON serves as a payment wallet. At RON Wallet, not only you can buy and sell RON coins, but you can also charge your RON to carry around and use it as a legal currency in selected stores around the world.\nCurrently, RON coin is affiliated with MASTERCARD. You can withdraw money in the selected ATM in the world.",
  "ron_35": "RON Token Sale and Economics",
  "ron_44": "Token Sale Start Time",
  "ron_45": "2019/10/08 17:00 - 2019/10/08 20:00 (GMT+8)",
  "ron_46": "Token Price",
  "ron_47": "0.025 USDT",
  "ron_48": "Turbo Allocation",
  "ron_49": "40,000,000 RON",
  "ron_5": "Subscription Time：2019/10/08 17:00 - 2019/10/08 20:00 (GMT+8)",
  "ron_50": "Bonus",
  "ron_51": "NONE",
  "ron_52": "Total Token Supply",
  "ron_53": "100,000,000,000 RON",
  "ron_56": "Token Sale Vesting Period",
  "ron_57": "NONE",
  "ron_58": "The Maximum Order Amount",
  "ron_59": "200,000 RON",
  "ron_6": "Subscription Amount: 40,000,000 RON",
  "ron_60": "The Minimum Order Amount",
  "ron_61": "2,000 RON",
  "ron_62": "Private Sale Token Price",
  "ron_63": "NONE",
  "ron_64": "2019/10/08 17:00 (GMT+8)",
  "ron_65": "2019/10/08 20:00 (GMT+8)",
  "ron_68": "Token Type",
  "ron_69": "ERC-20",
  "ron_7": "Price: 0.025 USDT",
  "ron_70": "TOKEN ALLOCATION",
  "ron_72": "RON TEAM",
  "ron_8": "Subscription Trading Pair",
  "ron_9": "RON/BTC",
  "salecoin_1": "Sell",
  "salecoin_10": "Sorry, we are currently unavailable in your region",
  "salecoin_11": "Thank you for choosing Bitforex.com (the ‘Website’), which is a platform dedicated to the transaction of cryptocurrency exchanging and the provision of related services (the “Service”). The website and trading platform owned and operated by Bitforex Limited, the Website and the Company are referred to “we,” “us” or “our”. or other applicable forms of first person pronouns in this Agreement. All individuals who create a Bitforex account are referred to “You” or “Your”. You should read, agree and comply with the terms and conditions of use stated bellow (“Terms of Use”) carefully.",
  "salecoin_12": "You are about to leave BitForex and go to <a href=\"https://www.moonpay.com/\"  target=\"_blank\">www.moonpay.com</a>. MoonPay is a fiat currency and cryptocurrency trading platform independently operated by a third party, and all related services are provided by MoonPay. For any questions about transactions, please contact MoonPay customer service. BitForex is not responsible for any loss or damage suffered from the use of this service.",
  "salecoin_13": "Network blocking，please try again later.",
  "salecoin_14": "waitingForDeposit",
  "salecoin_15": "Sell Now",
  "salecoin_16": "Buy",
  "salecoin_17": "Please check your inbox for Moonpay's email if your withdrawal asset has not been confirmed on chain within 20 min. Your order can be activated again in this case.",
  "salecoin_18": "Note:",
  "salecoin_2": "Sell Quantity",
  "salecoin_3": "Account Balance",
  "salecoin_4": "Service Channel",
  "salecoin_5": "Receive Amount",
  "salecoin_6": "Sale Record",
  "salecoin_7": "Sale Detail",
  "salecoin_8": "Please select currency and method of sale",
  "salecoin_9": "Your region is not supported",
  "sbcommon_nameList": "['Application Submission', 'Project Verification', 'Materials Supplementation', 'Payment', 'Currency-launching Confirmation']",
  "sbcommon_questionLink": "https://support.bitforex.com/hc/en-us/articles/360015527192",
  "sbcommon_ruleLink": "https://support.bitforex.com/hc/en-us/articles/360015725411",
  "sbcommon_上币介绍": "Introduce",
  "sbcommon_感谢您对BitForex的支持与信任，如有疑问请联系": "Thank for your support and trust, please feel free to contact us",
  "sbcommon_敬语": "0",
  "sbcommon_请输入": "Please enter",
  "sbcommon_请选择时间": "Please select the time",
  "sbintroduce_上币常见问题": "Common problems in Token Listing",
  "sbintroduce_如有问题请点击": "Any questions please click",
  "sbintroduce_活动介绍": "To promote the innovative development of the Blockchain industry and support more innovative projects of Blockchain, and also provide more Blockchain technology and digital token services for BitForex users. So BitForex launching the new token zone for self-service Token listing now. Our digital token holder can apply for Token listing thought our website and initiate the process of currency listing. Please click the “Token Application” button for complete the token listing docs, understand audit results progress in time and promote the process of token listing quickly.",
  "sbintroduce_申请上币": "Token Listing",
  "sbintroduce_申请人姓名": "Applicant's name",
  "sbintroduce_自助上币说明": "Please prepare basic materials and ensure the authenticity, effectiveness and accuracy of the materials before applying for self-service Token Listing . Please do not provide Token address, fund transfer, and important project information to any unofficial channels when you make self-service Token Listing. The communication method is subject to the official channel of BitForex. Meanwhile, to protect the benefit of investors, Digital token holder shall ensure that the project without any policy risks, meets the rules requirements, and abides by the Delisting Rules Announcement of the innovation zone. If corresponding rules are triggered, the project will be delisted.",
  "sbstep1_BitForex": "Application form of BitForex Token Listing",
  "sbstep1_Bitforex 已有主链": "Existing main link of BitForex",
  "sbstep1_Telegram社群号（人数）": "Telegram community number (number of people)",
  "sbstep1_代币是否经过私募": "Whether the token has been placed privately or not?",
  "sbstep1_代币解锁时间": "Unlock time of the token",
  "sbstep1_例如25": "E.g: bitforex（212400 people）",
  "sbstep1_例如32": "E.g: Bitcoin (BTC)",
  "sbstep1_例如33": "E.g: https//img-cdn.bitforex.vip/BF_icon.png",
  "sbstep1_例如35": "E.g: 3",
  "sbstep1_例如36": "E.g: coin",
  "sbstep1_例如38": "1BF＝0.053964 USDT, 1QTUM＝0.01499056 ETH",
  "sbstep1_其它智能合约": "Other intelligent contracts",
  "sbstep1_否": "No",
  "sbstep1_备注（可不填）": "Remarks (optional)",
  "sbstep1_币夫创新区上币规则": "Token Listing in the innovative zone of BitForex Rules",
  "sbstep1_您的其它上币信息说明": "Youe other Token Listing information",
  "sbstep1_您的钱包种类（单选）": "Your encryption currency type(single choice)",
  "sbstep1_我已明确知悉并同意创新区退市规则，承诺所填信息真实有效。": "I have clearly understood and agreed to the delisting rules of the innovation zone and promised that the information provided is true and valid.",
  "sbstep1_我已阅读并同意": "I have read and agreed",
  "sbstep1_所上其它交易所：": "Other exchanges",
  "sbstep1_持币地址个数（或预计分发后持币链接）": "Number of currency holding adress(or currency holding link after estimated distribution)",
  "sbstep1_提交": "Submit",
  "sbstep1_是": "Yes",
  "sbstep1_是否做市？（单选）": "Make a market or not?(Single choice)",
  "sbstep1_是否首发？（单选）": "First launching or not?(Single choice)",
  "sbstep1_最低私募价格：": "Minimum private placement price",
  "sbstep1_未编辑": "Unedited",
  "sbstep1_机构选择": "Select the institution",
  "sbstep1_申请人姓名": "Applicant's name",
  "sbstep1_申请人手机号码（区号+号码）": "Applicant's telephone number(area code + number)",
  "sbstep1_申请人电子邮件": "Applicant's E-mail adress",
  "sbstep1_白皮书链接": "Interlinkage of white paper",
  "sbstep1_继续编辑": "Continue to edit",
  "sbstep1_规则": "Rules",
  "sbstep1_请在下方输入：": "Please enter below",
  "sbstep1_请完成所有操作": "Please complete all operations",
  "sbstep1_请确认": "Please confirm",
  "sbstep1_请输入": "Please enter",
  "sbstep1_请输入正确的内容": "Please enter the correct content",
  "sbstep1_请输入种类：": "Please input the type",
  "sbstep1_请选择": "Please select",
  "sbstep1_请选择推荐机构——币夫合作机构推荐的项目可加快审核进度（如果没有，请选择无）": "Please select the recommended institution - the project recommended by the BitForex cooperation agency can speed up the review process (if not, please select none)",
  "sbstep1_请选择种类": "Please select the type",
  "sbstep1_钱包全称（简称）": "Full name of encryption currency(abbreviated form)",
  "sbstep1_钱包合约链接": "Contract link of encryption currency",
  "sbstep1_钱包图标文件链接（如果没有请填写官网链接）": "Link for icon file of encryption currency (if there isn't, please input the official website)",
  "sbstep1_项目全称": "Full name of the project",
  "sbstep1_项目官方网站": "Official website of the project",
  "sbstep1_项目简介（200字以上）": "General introduction of the project(more that 200 words)",
  "sbstep2_修改提交申请": "You can modify ‘Application Submission",
  "sbstep2_审核中": "The verification is in progress.Please check later.",
  "sbstep2_审核未通过": "Verification failed",
  "sbstep2_审核通过": "Verified",
  "sbstep2_有问题请联系": "After modifying the questions below, please contact",
  "sbstep2_编辑补充材料": "Edit‘Materials Supplementation",
  "sbstep2_返回": "Back",
  "sbstep3_ETH交易对": "ETH pair",
  "sbstep3_USDT交易对": "USDT pair",
  "sbstep3_上币参数确认": "Confirm currency parameters",
  "sbstep3_交易对及期望价格": "Transaction pairs & price",
  "sbstep3_例如101": "E.g: 1 BF ＝ 0.053964 USDT",
  "sbstep3_例如102": "E.g: 1 QTUM ＝ 0.01499056 ETH",
  "sbstep3_例如12": "E.g：10000000 USD",
  "sbstep3_例如13": "E.g：1000000000 BF=1000000000 USD",
  "sbstep3_做市账户": "Market-making Account",
  "sbstep3_可不填": "Optional",
  "sbstep3_在本交易所开放交易时间": "The opening time of trading service in this exchange",
  "sbstep3_在本交易所开放充值时间": "The opening time of recharge service in this exchange",
  "sbstep3_在本交易所开放提现时间": "The opening time of withdrawal service in this exchange",
  "sbstep3_开盘价格": "Opening Price: If it is empty, the price of the private placement will be referred to(E.g: 1 BF=0.00000001 BTC)",
  "sbstep3_当前私募所募集到的额度": "Current Amount of Private Placement (leave it empty if there is no private placement)",
  "sbstep3_总流通量流通市值": "Total Liquidity",
  "sbstep3_提交": "Submit",
  "sbstep3_未编辑": "Unedited",
  "sbstep3_请完成所有操作": "Please complete all operations",
  "sbstep3_请输入": "Please enter",
  "sbstep3_请选择时间": "Please select the time",
  "sbstep4_上币所需支付费用": "Required payment for Token Listing",
  "sbstep4_上币确认": "Token listing Confirmation",
  "sbstep4_上币费用支付": "Pay for Token Listing",
  "sbstep4_充值 BTC": "BTC recharge",
  "sbstep4_已支付": "Paid",
  "sbstep4_币夫创新区上币规则": "Token Listing in the innovative zone of BitForex Rules",
  "sbstep4_当前账户余额": "Current account balance",
  "sbstep4_您的BTC账户余额不足": "Your BTC account balance is insufficient",
  "sbstep4_我已阅读并同意": "I have read and agreed",
  "sbstep4_支付": "Pay",
  "sbstep4_支付成功": "Paid successfully",
  "sbstep4_温馨提示": "Tips",
  "sbstep4_规则": "Rules",
  "sbstep4_跳转到": "Redirect to",
  "sbstep5_不要担心，我们正在对您的信息进行审核": "Don't worry, we are verifying your information.",
  "sbstep5_信息确认中…": "Information confirming…",
  "sbstep5_如有疑问，请联系：": "Any questions please contact：",
  "sbstep5_审核完成后将将在第一时间通知您，并在当前页面显示上币相关参数信息。": "After the verifection is completed, you will be notified at the first time, and the relevant information of the currency will be displayed on the current page.",
  "sbstep5_币种名称(简称)：": "Currency name (abbreviated name)",
  "sbstep5_开放交易时间：": "Open trade time",
  "sbstep5_开放充值时间：": "Open recharge time",
  "sbstep5_开放提现时间：": "Open withdraw time",
  "sbstep5_开盘价格：": "Opening price：",
  "sbstep5_开盘市场：": "Token listing Confirmation",
  "sbstep5_电话：": "Telephone number",
  "sbstep5_确认上币": "Confirm Token Listing",
  "search": "Search",
  "sector_1": "BF Sector",
  "sector_10": "Polkadot aims to be the king of cross-chain with achieveing the intercommunication between blockchains of different architectures. Polkadot is built to connect private and consortium chains, public and permissionless networks, oracles, and future technologies that are yet to be created. Polkadot facilitates an internet where independent blockchains can exchange information and transactions in a trustless way via the Polkadot relay chain.",
  "sector_11": "Games",
  "sector_2": "NFT",
  "sector_3": "NFT",
  "sector_4": "NFT - Non-fungible tokens are used to create verifiable digital scarcity, as well as digital ownership, and the possibility of asset interoperability across multiple platforms. ERC721 and ERC1155 are two standards  written for non-fungible tokens. NFT has opened up a whole new way for blockchain technology beyond traditional financial applications. By representing tangible assets in the digital world, NFT may become an important part of the blockchain ecosystem and even the entire economy.",
  "sector_5": "DeFi Zone",
  "sector_6": "DeFi",
  "sector_7": "DeFi, which means \"decentralized finance\". It is the application of traditional finance to blockchain technology. DeFi's vision is that all assets can be tokenized and freely traded in the global open market. DeFi is built on the blockchain and has the characteristics of openness, transparency, automatic execution, and immutability of the blockchain.",
  "sector_8": "Polkadot ECO",
  "sector_9": "Polkadot",
  "security_1": "Email",
  "security_10": "Bind Now",
  "security_11": "Bound",
  "security_12": "Go to settings",
  "security_13": "Reset",
  "security_14": "Change",
  "security_15": "Everytime",
  "security_16": "Hourly",
  "security_17": "Never",
  "security_18": "Login-Security Verification",
  "security_19": "Please submit a request if you would like to unbind your account.",
  "security_2": "For receiving notifications, prompts, etc.",
  "security_20": "Please bind your Mobile or Google Authenticator",
  "security_21": "For your transaction security, please complete the following security verification.",
  "security_22": "You must enable \"Mobile verification\" and/or \"Google security verification\".",
  "security_23": "Enable Email Verification",
  "security_24": "Setup Trading Password",
  "security_25": "Already setup",
  "security_26": "Not setup yet",
  "security_27": "Go to settings",
  "security_28": "Reset Trading Password",
  "security_29": "Last login",
  "security_3": "Mobile Phone",
  "security_30": "Time",
  "security_31": "Device",
  "security_32": "IP address",
  "security_33": "Status",
  "security_34": "Success",
  "security_35": "Incorrect Password",
  "security_36": "2FA Verification Fail",
  "security_37": "SMS Verification Fail",
  "security_38": "Google Verification Fail",
  "security_39": "Remote Login Verification Fail",
  "security_4": "Google Authenticator",
  "security_40": "Double Verification Fail",
  "security_41": "BitForex Email Promotion",
  "security_42": "Receive BitForex news and updates.",
  "security_43": "Please bind your email.",
  "security_44": "Subscription",
  "security_45": "Please finish all verifications for your account security.",
  "security_46": "Enable Google 2FA or Mobile Varification.",
  "security_47": "Bind your email;",
  "security_48": "Set Transaction Password",
  "security_49": "Not Enabled",
  "security_5": "For login, withdrawal, changing password, changing security settings and verification for managing API.",
  "security_50": "Enabled",
  "security_51": "If you cannot recieve verification code, please submit a request and contact customer service immediately>>",
  "security_52": "Unbind",
  "security_54": "Anti-Phishing Code",
  "security_55": "In order to prevent fake emails, all emails sent by BitForex will include the anti-phishing code you created.",
  "security_56": "Security Reminder",
  "security_57": "You haven't bound an email to your account, please bind the email first.",
  "security_58": "Create Anti-Phishing Code",
  "security_59": "Enter letters, numbers, underscores 6-20 characters.",
  "security_6": "Trading Password",
  "security_60": "Once you have enabled the anti-phishing code, it will be included in all genuine emails sent to you from BitForex.",
  "security_61": "The anti-phishing code has at least 6 characters, must include numbers, letters, underscores, and no special symbols.",
  "security_62": "SMS Verification Code",
  "security_63": "Device Management",
  "security_64": "Manage",
  "security_65": "Used to manage accounts that have been authorized to log in.",
  "security_66": "Modify Anti-Fishing Code",
  "security_67": "Please enter the Anti-Fishing Code.",
  "security_68": "Add device management",
  "security_69": "Action",
  "security_7": "For trading verification.",
  "security_70": "Are you sure you want to delete the selected trusted device？",
  "security_71": "Device:",
  "security_8": "Login Password",
  "security_9": "For login BitForex account.",
  "share_1": "Share",
  "share_10": "Download and share your result to your friends!",
  "share_11": "Press to download and share your result to your friends!",
  "share_2": "Download Poster",
  "share_3": "{token} Long position {num} X",
  "share_4": "{token} Short position {num} X",
  "share_5": "Profitability",
  "share_6": "Entry Price",
  "share_7": "Current Price",
  "share_8": "Scan QR Code To Download App",
  "share_9": "Download Success",
  "simplex_1": "Purchase Crypto with your Credit Card",
  "simplex_10": "Cryptocurrency price is based on the current price and does not represent the final price. You will receive a trading reset confirmation from Simplex when the price change is over +/-2.5%. ",
  "simplex_11": "You can <a href=\"https://payment-status.simplex.com/\" target=\"_blank\">check your transaction status</a> after you complete your payment.",
  "simplex_12": "Please contact Simplex Customer Support if you have any questions. (support@simplex.com) ",
  "simplex_13": "Flash Trade ",
  "simplex_14": "Only takes UP to 30 minutes",
  "simplex_15": "Reasonable Transaction Fee",
  "simplex_16": "only 5% transaction fee will be charged for each transaction ($10 minimum )",
  "simplex_17": "Order Confirmation ",
  "simplex_18": "Order Amount ",
  "simplex_19": "Deposit Account ",
  "simplex_2": "Now supporting Visa and Mastercard transactions",
  "simplex_20": "Total Due (handling fee included) ",
  "simplex_21": "Disclaimer ",
  "simplex_22": "Disclaimer ",
  "simplex_23": "Simplex is a third party platform that provides FIAT exchange services, all related services are provided by Simplex. Please read the <a href=\"https://www.simplex.com/terms-of-use/\" target=\"_blank\">Simplex Users Agreement</a> before you start your transaction. Please contact Simplex Customer Support if you have any questions via email support@simplex.com. BitForex will not be responsible for any losses or asset damage that may occur.  ",
  "simplex_24": "I have read and agreed",
  "simplex_25": "Cancel ",
  "simplex_26": "Payment page ",
  "simplex_27": "Simplex FAQ ",
  "simplex_28": "Amount",
  "simplex_29": "Price ",
  "simplex_3": "Purchase",
  "simplex_30": "Total Charge ",
  "simplex_31": "Purchase Now",
  "simplex_32": "The min. purchase amount is {amount} {coin}",
  "simplex_33": "The max. purchase amount is {amount} {coin}",
  "simplex_34": "Successfully make a deposit during the event to receive a $1-$10 ",
  "simplex_35": "Event Details",
  "simplex_36": "From Now until Round 3 of Perpetual Contract Trading Master",
  "simplex_37": "During the event, users who have successfully made a deposit by credit card can participate in this event. No further applications are needed. ",
  "simplex_38": "Every user who successfully uses a credit card to deposit will receive a random BTC airdrop reward worth $1-10, which can be used directly in the BTC perpetual contract trading.",
  "simplex_39": "The Airdrop reward will be automatically issued to your Perpetual Contract Account in the form of BTC equivalent before Round 3 of the BitForex Perpetual Contract Trading Competition.",
  "simplex_4": "Login and Purchase ",
  "simplex_40": "Each participant has only one chance to receive an airdrop reward. ",
  "simplex_41": "BitForex reserves the right to cancel or amend the Campaign or Campaign Rules at our sole discretion.",
  "simplex_42": "airdrop bonus",
  "simplex_44": "Event Period",
  "simplex_45": "Max",
  "simplex_46": "Amount",
  "simplex_47": "Fee",
  "simplex_48": "Total",
  "simplex_5": "Technical Support ",
  "simplex_6": "Limit per Transaction",
  "simplex_7": "Daily Transaction Limit ",
  "simplex_8": "Monthly Transaction Limit ",
  "simplex_9": "Purchase Guide",
  "simulation_58": "Due to the large number of participants, it may cause abnormal data and update delay in the leaderboard.",
  "social_1": "Trade ＆ Invest",
  "social_10": "Choose talented traders from over 180 + countries!\n<br>\nFollow their trading trends by using your social trading account.",
  "social_11": "Start Now",
  "social_12": "Only invest With The Best!",
  "social_13": "No Extra Cost!",
  "social_14": "BitForex will not take any extra fees when you follow or Copy trading strategies.",
  "social_15": "Start Trading",
  "social_16": "AutoTrade Cryptos",
  "social_17": "Join the fast-growing market by following All-Star Crypto Traders from all over the world. \n<br>\nWhen you follow the trader on Social trading, it will automatically generate a BitForex perpetual contract account, which has this follow detail.",
  "social_18": "Start Now",
  "social_19": "TRADES OPENED ON BITFOREX",
  "social_2": "Social Trading Platform",
  "social_20": "Data from BitForex {date}. Past performance is not an indication of future results. Your capital is at risk.",
  "social_21": "Copy Trade With Top Investors",
  "social_22": "You can explore Social Trading’s top traders by using the leader-board. With that, you could easily choose the different traders you want to copy.",
  "social_23": "Total Profit",
  "social_24": "RISK",
  "social_25": "Trade Cryptocurrency With \nUp To 100x Leverage",
  "social_26": "We are now support 12 cryptocurrencies, includes BTC, LTC, EOS, ETC and more. We’re working on more currency pairs, and trading pairs to offer soon!",
  "social_27": "Specification: <br>\n- No expiry date. <br>\n- Up to 100x leverage.",
  "social_28": "Start Trading",
  "social_29": "Perpetual contract is an innovative derivatives product that is in between Spot margin-trading and Futures trading.",
  "social_3": "As a follower, you can find global traders who share their day trading strategies and investment conditions. Those strategies offer you new insights that could help you with more accurate trading decisions.\n<br>\nAs an investor, you can share your trading situation and get additional income from your subscription.",
  "social_30": "Let's explore more trading opportunities together!",
  "social_31": "Register now and get more features!",
  "social_32": "Join for free",
  "social_33": "Trading Strategy is great.",
  "social_34": "I am very satisfied with the services BitForex Trading Strategy platform provides.",
  "social_35": "Robert Nguyen",
  "social_4": "Join Now",
  "social_5": "What is Copy Trading?",
  "social_6": "When you activate your copy trading, your portfolio will link to the portfolio of the trader(s) you are following at the BitForex social trading platform. In other words, the trader made by the trader(s) you are following are automatically copied to your portfolio while using Copy Trading.",
  "social_7": "Start Now",
  "social_8": "Copy Trading",
  "social_9": "Follow & Copy The Best Trading strategy",
  "succeed": "Success",
  "support_1": "BitForex Customer Support Center",
  "support_10": "Download BitForex App",
  "support_11": "Trade with BitForex anytime, anywhere.",
  "support_12": "BitForex MT5 Guide",
  "support_13": "We offer our users most options for your crypoto assets. Trade traditional financial products with BitForex MT5.",
  "support_14": "Join the Community",
  "support_15": "Find us on all social medias and become spart of the community.",
  "support_16": "Get started with BitForex",
  "support_17": "Guideline for all users to get started with BitForex and Cryptocurrency",
  "support_18": "BitForex 2FA Guide",
  "support_19": "Verify your account with 2FA verification for more security of your account.",
  "support_2": "BitForex Customer Service Team is online 24/7 to assist with your needs",
  "support_20": "How to Deposit on BitForex",
  "support_21": "Make a deposit before you start your trading.",
  "support_22": "BitForex Perpetual Contract Trading",
  "support_23": "Trade upto X100 leverage with BitForex.",
  "support_24": "Create an Account on BitForex",
  "support_25": "Become a trader on BitForex and enjoy the most optoins for your crypto assets.",
  "support_26": "Become BitForex Knight",
  "support_27": "Enjoy upto 50% Spot trading commission and 40% Perpetual trading commission in the Knighthood.",
  "support_28": "Latest News on BitForex",
  "support_29": "Stay tuned to the latest news for BitForex",
  "support_3": "We're here to help",
  "support_30": "https://support.bitforex.com/hc/en-us/categories/360000607252-FAQ",
  "support_31": "https://support.bitforex.com/hc/en-us/requests/new",
  "support_32": "https://support.bitforex.com/hc/en-us/sections/360001542012-Get-Started",
  "support_33": "https://m.bitforex.com/en/download",
  "support_34": "https://support.bitforex.com/hc/en-us/sections/360007616331-BitForex-MT5",
  "support_35": "https://www.bitforex.com/en/ContactUs",
  "support_36": "https://support.bitforex.com/hc/en-us/articles/360006825412",
  "support_37": "https://support.bitforex.com/hc/en-us/articles/360006824032",
  "support_38": "https://support.bitforex.com/hc/en-us/sections/360004930752",
  "support_39": "https://support.bitforex.com/hc/en-us/articles/360007106691",
  "support_4": "FAQ",
  "support_40": "https://www.bitforex.com/en/knightpage",
  "support_41": "https://support.bitforex.com/hc/en-us/sections/360001541712",
  "support_5": "Got a question? We probably got the answer for you here.",
  "support_6": "Submit a Request",
  "support_7": "Your ticket will be responded within a 24 hour period.",
  "support_8": "Beginner's Guide",
  "support_9": "New to BitForex? We got all the guides to all you need to know to begin your journey with BitForex.",
  "swap_1": "SWAP",
  "swap_10": "Price Impact",
  "swap_100": "Evaluation usually takes up to 2 business days, please do not resubmit.",
  "swap_101": "Return to EazySwap​​​",
  "swap_102": "Token you submitted is under evaluation, which usually takes up to 2 business days, please do not resubmit.",
  "swap_103": "Token requested has not been listed on BitForex, EazySwap trading for this token cannot be supported at the moment.",
  "swap_11": "The difference between the market price and estimated price due to trade size.",
  "swap_12": "Liquidity Provider Fee",
  "swap_13": "A portion of each trade (0.3%) goes to liquidity providers as a protocol incentive.",
  "swap_14": "Slippage Limit",
  "swap_15": "Your transaction will revert if the price changes unfavorably by more than this percentage.",
  "swap_16": "Swap History",
  "swap_17": "Confirm Swap",
  "swap_18": "Cancel",
  "swap_19": "Price",
  "swap_2": "From",
  "swap_20": "Select Token",
  "swap_21": "Search name or paste address",
  "swap_22": "Token Name",
  "swap_23": "Find a token by searching for its name, symbol, or by pasting its address below.",
  "swap_24": "Having trouble finding a token?",
  "swap_25": "Click to enter the new token process",
  "swap_26": "Token cannot be found, please enter an ERC-20 token name",
  "swap_27": "Insufficinet liquidity for {coin} trade",
  "swap_28": "Invalid balance for {coin}",
  "swap_29": "please make a transfer-in",
  "swap_3": "Balance",
  "swap_30": "Completed, please allow a moment for the system to process.",
  "swap_31": "Add more token",
  "swap_32": "Add Now",
  "swap_33": "Trading Info",
  "swap_34": "See Now",
  "swap_35": "FAQ",
  "swap_36": "More",
  "swap_37": "Pool",
  "swap_38": "Input",
  "swap_39": "Balance",
  "swap_4": "All",
  "swap_40": "Prices and pool share",
  "swap_41": "Share of Pool",
  "swap_42": "No liquidity at the moment",
  "swap_43": "{amount} token(s) add to liquidity",
  "swap_44": "{BF代币名} per {ETH}",
  "swap_45": "{ETH} per {BF代币名}",
  "swap_46": "Liquidity Amount",
  "swap_47": "+",
  "swap_48": "Confirm",
  "swap_49": "You are the first liquidity provider.",
  "swap_5": "To",
  "swap_50": "The ratio of tokens you add will set the price of this pool.Once you are satisfied with the price, please confirm the mortgage.",
  "swap_51": "Invalid balance for {coin} to complete the mortage, please make a transfer-in",
  "swap_52": "Trading History",
  "swap_53": "{amount} token(s) add to liquidity",
  "swap_54": "Amount",
  "swap_55": "Amount ETH",
  "swap_56": "Share of Pool",
  "swap_57": "Profit",
  "swap_58": "Redemption Ratio",
  "swap_59": "Redeem",
  "swap_6": "{coin} Smart Contract",
  "swap_60": "Pool History",
  "swap_61": "Type",
  "swap_62": "Pool",
  "swap_63": "Redeem",
  "swap_64": "Time",
  "swap_65": "Swap History",
  "swap_66": "Tokens",
  "swap_67": "Fee",
  "swap_68": "Status",
  "swap_69": "Completed",
  "swap_7": "Copy",
  "swap_70": "In Progress",
  "swap_71": "Pool History",
  "swap_72": "Add more token on <i>EazySwap</i>",
  "swap_73": "STEP 1",
  "swap_74": "STEP 2",
  "swap_75": "Verify Address",
  "swap_76": "Add Address",
  "swap_77": "Token Contract Address",
  "swap_78": "Only ERC-20 address can be added.",
  "swap_79": "Verify Address",
  "swap_8": "Minimum received",
  "swap_80": "Verifying",
  "swap_81": "{coin} is added",
  "swap_82": "ERC-20 Contract Address",
  "swap_83": "Token Name {coin}",
  "swap_84": "Trade Now",
  "swap_85": "Deposit {coin}",
  "swap_86": "Please enter the correct ERC-20 contract address",
  "swap_87": "Estimated time: 1~2 minutes",
  "swap_88": "Confirm",
  "swap_89": "Current slippage is greater than the slippage value you would like to enter.",
  "swap_9": "Your transaction will reverted if there is a large, unfavorable price movement before it is confirmed",
  "swap_90": "Note: The estimated amount can differ from the actual receiving amount for the Swap due to the difference of the calculation time and the execution time.",
  "swap_91": "Note: The estimated amount can differ from the actual receiving amount for the redemption due to the difference of the calculation time and the execution time.",
  "swap_92": "Evaluation Request is Completed",
  "swap_93": "Anyone can create an ERC20 token on Ethereum with any name, including creating fake versions of existing tokens and tokens that claim to represent projects that do not have a token.",
  "swap_94": "This interface can load arbitrary tokens by token addresses. Please take extra caution and do your research when interacting with arbitrary ERC20 tokens.If you purchase an arbitrary token, you may be unable to sell it back.",
  "swap_95": "Succeeded",
  "swap_96": "Failed",
  "swap_97": "Evaluation",
  "swap_98": "In progress...",
  "swap_99": "Swap and Pool will be available after the evaluation is completed.",
  "swap_info_1": "All Info",
  "swap_info_10": "Contrac Address",
  "swap_info_11": "Copy",
  "swap_info_12": "Trade Now",
  "swap_info_13": "{token} Info",
  "swap_info_14": "Liquidity Pool",
  "swap_info_15": "Tokens in the Pool",
  "swap_info_16": "24H Volume",
  "swap_info_17": "24H Transactions",
  "swap_info_18": "Recent Trade",
  "swap_info_19": "All",
  "swap_info_2": "Pool Rank",
  "swap_info_20": "Time",
  "swap_info_21": "Amount {token}",
  "swap_info_22": "Token Amount",
  "swap_info_3": "Change Rank",
  "swap_info_4": "New Listing",
  "swap_info_5": "Name/Pool",
  "swap_info_6": "Price",
  "swap_info_7": "Price Change",
  "swap_info_8": "High",
  "swap_info_9": "Low",
  "swap_intro_1": "One of the most professional and advanced semi-decentralized matching Protocols",
  "swap_intro_10": "ZERO Gas Fees",
  "swap_intro_11": "Instant Matching",
  "swap_intro_12": "Low Slippage",
  "swap_intro_13": "EazySwap will be listed on",
  "swap_intro_14": "Sept. 21st, 2020",
  "swap_intro_15": "Trade on EazySwap",
  "swap_intro_2": "EazySwap, Sub-Platform of BitForex Cryptocurrency Exchange. All the trading records and details are available on the ERC20 Blockchain system.",
  "swap_intro_3": "EazySwap provides the most user-friendly structure designed to solve the \"High Gas Fee\" problem. In the meantime, it remains the decentralization advantage. ZERO gas fees are available for any transaction in the system.",
  "swap_intro_4": "The liquidity pool provider of EazySwap can receive the reward of up to 100% of the trading fees.",
  "swap_intro_5": "EazySwap is currently supporting the ERC20-based tokens, and mainnet tokens will be supported soon.",
  "swap_intro_6": "Why Choose <strong>EazySwap</strong>?",
  "swap_intro_7": "*With low barrier of listing requirements for the project",
  "swap_intro_8": "*Unique rewarding system for the user and liquidity provider",
  "swap_intro_9": "*One of the most professional and advanced semi-decentralized matching Protocols with 100% trading data on-chain structure",
  "tax": "Deposit Tax",
  "tax_info": "Due to {n} on-chain transfers will occur a {a}% transfer tax, BitForex will charge this part as a token merging cost to the hot wallet, the actual amount credited will be {b}% of the deposit amount.",
  "terms_1": "BitForex Terms of Service",
  "time_h": "hour",
  "time_m": "minute",
  "time_s": "second",
  "tips": "Tips",
  "tmcn_1": "TMCN (TimeCoinProtocol)",
  "tmcn_10": "2020/11/12 15:00 (GMT+8)",
  "tmcn_11": "Subscription Amount",
  "tmcn_12": "160,000 TMCN",
  "tmcn_13": "Price",
  "tmcn_14": "0.7 USDT",
  "tmcn_15": "Subscription Trading Pair",
  "tmcn_16": "TMCN/BTC",
  "tmcn_17": "Lock-up Period",
  "tmcn_18": "No Lock-up",
  "tmcn_19": "IMPORTANT NOTICE",
  "tmcn_2": "An individual's potential contributes to someone's value",
  "tmcn_20": "1. The minimum order amount for a single account is 50 USDT<br>2. The maximum order amount for a single account is 100 USDT<br>3. There is no handling fee for the transaction<br>4. KYC is NOT required for the TMCN subscription.<br>5. There are many participating users. It is recommended to log in before 7:00 (GMT+8) and prepare in advance.",
  "tmcn_21": "2. The maximum order amount for a single account is 100 USDT",
  "tmcn_22": "3. There is no handling fee for the transaction",
  "tmcn_23": "4. KYC is NOT required for the TMCN subscription.",
  "tmcn_24": "5. There are many participating users. It is recommended to log in before 7:00 (GMT+8) and prepare in advance.",
  "tmcn_25": "REFERENCE",
  "tmcn_26": "TMCN Website",
  "tmcn_27": "https://twitter.com/_TimeCoin_",
  "tmcn_28": "INTRODUCTION",
  "tmcn_29": "About TMCN",
  "tmcn_3": "Subscription Timeline",
  "tmcn_30": "TimeCoinProtocol is decentralized sharing economy protocol using the Ethereum blockchain. Individual or Corporation can build and operate their own sharing economy application, dApp running on TimeCoinProtcol. dApp users can securely share their own data across the platform to get better services from dApp providers. TimeCoinProtocol plans to release the two dApps, “eSportStars” and “TimeTicket”. eSportStars is for everyone including professional esports players, fans and players aiming to become professionals around the world. It’s an unprecedented service that offers all players the opportunity to find suitable opponents to play against as well as hosting tournaments and competing in popular esports games beyond geographical boundaries. TimeTicket is a popular application that connects people who wish to buy and sell skills and exchange knowledge. The existing TimeTicket, premier sharing economy service in Japan has more than 300,000 users and the sales has grown 30 times over 3 years. The dApp version of TimeTicket will be released targeting Chinese, India, and other regions. The dApp users will be rewarded with TimeCoin(TMCN) by playing esports games on eSportStars called “esports Mining” and purchasing services on TimeTicket called “Time Mining”. TimeCoin will be used as the payment token for dApps.",
  "tmcn_31": "Sold Out",
  "tmcn_32": "Please go to the PC terminal to purchase",
  "tmcn_33": "Puzzle Fantasy's NFT is a character used in P2E games and is 169 characters NFT.The characteristics of NFT include additional compensation such as mining in games and interest rates in Defi, and a portion of the foundation's profits can be compensated.",
  "tmcn_34": "TMCN Token Sale",
  "tmcn_35": "Token Sale Start Time",
  "tmcn_36": "2020/11/11 15:00 - 2020/11/11 18:00 (GMT+8)",
  "tmcn_37": "Token Price",
  "tmcn_38": "0.7 USDT",
  "tmcn_39": "Turbo Allocation",
  "tmcn_4": "Ready",
  "tmcn_40": "160,000 TMCN",
  "tmcn_41": "Bonus",
  "tmcn_42": "NONE",
  "tmcn_43": "Total Token Supply",
  "tmcn_44": "100,000,000 TMCN",
  "tmcn_45": "Initial Circulating Supply",
  "tmcn_46": "0",
  "tmcn_47": "Token Sale Vesting Period",
  "tmcn_48": "NONE",
  "tmcn_49": "The Maximum Order Amount",
  "tmcn_5": "Starting Time",
  "tmcn_50": "100 USDT",
  "tmcn_51": "The Minimum Order Amount",
  "tmcn_52": "50 USDT",
  "tmcn_53": "Private Sale Token Price",
  "tmcn_54": "NONE",
  "tmcn_55": "Token Type",
  "tmcn_56": "ERC-20",
  "tmcn_57": "TOKEN ALLOCATION",
  "tmcn_58": "Sold Out",
  "tmcn_59": "Buy Now",
  "tmcn_6": "2020/11/11 15:00 (GMT+8)",
  "tmcn_60": "Detailed Info",
  "tmcn_61": "Historical Highest Return",
  "tmcn_62": "Register Now!",
  "tmcn_63": "To Get The Latest Turbo Updates!",
  "tmcn_64": "ID verification(KYC) is required to participate in this token sale",
  "tmcn_65": "KYC Status",
  "tmcn_66": "Not yet verified",
  "tmcn_67": "Get verified",
  "tmcn_68": "Due to the request of the project party, this token sale is unavailable for your region. More IEOs will come soon, Please stay tuned!",
  "tmcn_7": "Ending Time",
  "tmcn_8": "2020/11/11 18:00 (GMT+8)",
  "tmcn_9": "Open Trading",
  "trade_1": "Charts",
  "trade_10": "Change",
  "trade_11": "Last Price",
  "trade_12": "24h Change",
  "trade_13": "24h High",
  "trade_14": "24h Low",
  "trade_15": "24h Turnover",
  "trade_16": "Success",
  "trade_17": "Cancel success",
  "trade_18": "Order Book",
  "trade_19": "Amount",
  "trade_2": "Depth",
  "trade_20": "Total",
  "trade_21": "Spread",
  "trade_22": "Back",
  "trade_23": "Recent Trades",
  "trade_24": "Side",
  "trade_25": "Time",
  "trade_26": "Limit Order",
  "trade_27": "Market",
  "trade_28": "{coin} Total / AVL",
  "trade_29": "Amount",
  "trade_3": "Coins/Tokens Introduction",
  "trade_30": "Buy",
  "trade_31": "Sell",
  "trade_32": "Price",
  "trade_33": "Price",
  "trade_34": "Amount",
  "trade_35": "Amount",
  "trade_36": "Total",
  "trade_37": "Buy {coin}",
  "trade_38": "Sell {coin}",
  "trade_39": "Total",
  "trade_40": "AVL",
  "trade_42": "Price",
  "trade_43": "Min. {minAmount}",
  "trade_44": "Insufficient balance",
  "trade_45": "Buying price cannot be higher than {price}",
  "trade_46": "Selling price cannot be lower than {price}",
  "trade_47": "Incorrect password, you have {num} attempts remaining.",
  "trade_48": "Order Success!",
  "trade_49": "{symbol} Spot - BitForex exchange",
  "trade_5": "Quotes",
  "trade_50": "{symbol} Perpetual Contract - BitForex exchange",
  "trade_51": "Switch to 24h Turnover",
  "trade_52": "Switch to 24h Change",
  "trade_53": "Price",
  "trade_54": "Amount",
  "trade_55": "Time",
  "trade_6": "Favorites",
  "trade_7": "Pair",
  "trade_8": "Market",
  "trade_9": "Price",
  "trade_buyLoss": "Stop Loss Limit",
  "trade_buyProfit": "Take Profit Limit",
  "trade_ioc": "Immediate or cancel orders must be executed immediately. Any portion of the order that cannot be executed immediately will be canceled.",
  "trade_limitTip": "Your stop order might not be triggered due to extreme market conditions, price or position limits, insufficient balance, or suspension of tradings. A stop order becomes a limit order after it is triggered. Unfilled limit and market orders can be found in Open orders.",
  "trade_marketBuy": "Market Buy",
  "trade_marketSell": "Market Sell",
  "trade_notTriggered": "Not Triggered",
  "trade_post": "post-only orders are guaranteed to be the maker order when executed, If a post only order is matched with an existing order, this order will be canceled.",
  "trade_sellLoss": "Stop Loss Limit",
  "trade_sellProfit": "Take Profit Limit",
  "trade_stopLimit": "Stop-Limit",
  "trade_triggered": "Triggered",
  "trade_triggerNotAbove": "Trigger price cannot above {price}",
  "trade_triggerNotBelow": "Trigger price cannot below {price}",
  "trade_triggerPrice": "Trigger price",
  "transfer_1": "From",
  "transfer_10": "Perpetual Account",
  "transfer_11": "Transfer All",
  "transfer_12": "Available",
  "transfer_13": "Important Notice",
  "transfer_14": "Perpetual Contract Trading can be a tool to produce significant profits, but also constitutes a high risk. You may lose all your assets in the event of unusual market changes.",
  "transfer_15": "Please ensure your full understanding of all related risks before you start trading.",
  "transfer_16": "Check Perpetual Contract Trading Tutorial",
  "transfer_17": "I have read and agreed to the {link}",
  "transfer_18": "<a style=\"color:var(--color-color-brand-600-primary)\" href='https://event.bitforex.com/en/perpetual-terms.html' target='_blank'>&lt;BitForex Perpetual Contract User Agreement&gt;</a>",
  "transfer_19": "Activate my Perpetual Contract Trading Account",
  "transfer_2": "To",
  "transfer_20": "Realized PNL can be transfered after 16:00 (GMT+8) daily.",
  "transfer_21": "{amount {symbol} of bonus will be retreived after you successfully transfer your profit.",
  "transfer_22": "To process the transfer, you must meet the minimum deposit requirement: {amount}{coin}",
  "transfer_23": "Transfer failed",
  "transfer_24": "Pending",
  "transfer_25": "Transfer type not supported",
  "transfer_26": "Additional 5% bonus for new users: New users will receive an additional 5% bonus if you transfer within 24 hours after activated your MT5 account and reach the amount above.",
  "transfer_27": "During the period from 10:00 on February 18, 2020 to 16:00 on February 21, 2020 (GMT+8),the first deposit amount you transferred to the MT5 account is ≥ 100USDT/0.01BTC/0.5ETH/360XRP, we will give you 10% of your deposit amount as a bonus. (Maximum bonus: 5000USDT/0.5BTC/25ETH/18000XRP)",
  "transfer_28": "The amount you transfer must be more than {num}",
  "transfer_29": "CApp Transfer-out Process Time Guide",
  "transfer_3": "Transfer succeed!",
  "transfer_30": "The daily transfer-out closing time is 16:00 (GMT+8). Transfer-out requested before the daily closing time will be processed within 1 hour period of time. Transfer-out requests made after the daily closing time will be processed the <span class=\"red\">next day</span>.",
  "transfer_4": "Please enter the amount",
  "transfer_5": "The amount cannot be 0",
  "transfer_6": "{coinName} Insufficient balance",
  "transfer_7": "Transfer",
  "transfer_8": "Coin/Token",
  "transfer_9": "Spot Account",
  "turbo_1": "Premium Project Accelerator",
  "turbo_10": "Start | Buy {coin}",
  "turbo_13": "Load more",
  "turbo_14": "Allocation",
  "turbo_15": "ROUND 1",
  "turbo_16": "ROUND 2",
  "turbo_17": "Trade Now",
  "turbo_18": "Completed",
  "turbo_19": "Subscribe Now",
  "turbo_2": "COMING SOON",
  "turbo_20": "Coming Soon",
  "turbo_21": "Starting Time",
  "turbo_22": "Ending Time",
  "turbo_4": "Token Sale Has Ended",
  "turbo_8": "Subscription Time",
  "turbo_9": "Trading Pairs",
  "und_1": "UND (Unification)",
  "und_10": "0.05 USD",
  "und_11": "Trading Pairs:",
  "und_12": "UND/ETH trading through market orders",
  "und_13": "Important Notice",
  "und_14": "1.Token sale participants are required to complete the ID verification (KYC) on their account and also meet the BitForex Turbo eligibility requirements.",
  "und_15": "Enable Verification",
  "und_16": "2.15 ETH equivalent UND for each account",
  "und_17": "3. Minimum Purchase: 1 ETH equivalent UND for each account",
  "und_18": "Start",
  "und_19": "Reference",
  "und_2": "The Public/Private Hybrid Blockchain Built for Enterprise",
  "und_20": "UND (unification) x BitForex",
  "und_21": "UND (Unification) Website",
  "und_22": "https://unification.com/foundation/workchain",
  "und_23": "UND (Unification) Whitepaper",
  "und_24": "https://s3.us-east-2.amazonaws.com/unification-wp-content-1528371022/wp-content/uploads/documents/whitepaper/UnificationWhitePaper3.pdf",
  "und_25": "Introduction",
  "und_26": "A Public/Private Blockchain ecosystem that allows deployment of WRKChains. WRKChains replace “DAPPS” and are operated independently from the Mainchain with a fixed UND price.. This allows predictable cost\nmetrics with unlimited horizontal scalability and 100% interoperability across various WRKChains. Block rewards for Mainchain are issued to the top 96 MasterStakers.",
  "und_27": "Core Features",
  "und_28": "The Unification Ecosystem has two parts:\nMainchain and WRKChains. Each with different responsibilities and benefits.",
  "und_29": "Mainchain",
  "und_3": "Start Time",
  "und_30": "Mainchain consists of Block Validators who operate via a Distributed Stake Governance (DSG) Consensus mechanism and is powered by the United Network Distribution (UND) Token\n\nIn order to fully optimize and focus resources, Mainchain can only execute three tasks.\n1. UND - UND wallet transfers\n2. Executing “Expansion Logs” which power and enable WRKChains\n3. Maintaining a log of voting and whitelist for allowed validators on the network.",
  "und_31": "WRKCHAINS",
  "und_32": "WRKChains can be thought of as independent blockchains deployed via an expansion log.\nThere are numerous configuration options available which allow enterprises and developers to customize according to their needs. WRKChains will “check in” with Mainchain on every produced block with the headers of this block. \nThis by default creates a situation where by maintaining their own chain/coin/consensus mechanism -the deployers of the WRKChain can control cost and scalability while by broadcasting the headers of that block for the immutable Mainchain to record, they share the inherent public trust of Mainchain and will not be able to reorganize previous blocks due to a merkle tree being included in the header.",
  "und_33": "TOKEN ALLOCATION",
  "und_34": "Enterprise - 32%\nFoundation Incentive - 20%\nTeam -12%\nAdvisory - 5%\nEquity Investors - 13.50%\nInvestors - 1.5%\nMarket Support - 1.5%\nFoundation distribution pool - 14.5%",
  "und_35": "UND Token Sale and Economics",
  "und_36": "Token Sale Start Time",
  "und_37": "Token Sale End Time",
  "und_38": "Hard Cap",
  "und_39": "Total Token Supply",
  "und_4": "2019/04/10 21:00:00 (GMT+8)",
  "und_40": "Initial Circulating Supply",
  "und_41": "Token Sale Token Price",
  "und_42": "Turbo Allocation",
  "und_43": "Token Sale Vesting Period",
  "und_44": "Individual Cap",
  "und_45": "The minimum order amount",
  "und_46": "Private Sale Token Price",
  "und_47": "Private Sale Allocation",
  "und_48": "Private Sale Vesting Period",
  "und_49": "Algorithmic Token Distribution",
  "und_5": "End Time",
  "und_50": "Token Type",
  "und_51": "2019/04/10 21:00 (GMT+8)",
  "und_52": "2019/04/12 21:00 (GMT+8)",
  "und_53": "400,000 USD equivalent ETH",
  "und_54": "1,000,000,000 UND",
  "und_55": "2.9% of Total Token Supply",
  "und_56": "0.05 USD (price in ETH will be determined 1 day prior)",
  "und_57": "8,000,000 UND (0.8% of Total Token Supply)",
  "und_58": "No lockup",
  "und_59": "15ETH",
  "und_6": "2019/04/12 21:00:00 (GMT+8)",
  "und_60": "1ETH",
  "und_61": "1 UND = 0.05 USD",
  "und_62": "2.1% of Total Token Supply",
  "und_63": "ATD (Algorithmic Token Distribution)",
  "und_64": "ERC-20",
  "und_65": "The team will set a number between 0–6% of the total daily REAL VOLUME and slowly sell that amount into the market at the current market rate following certain algorithmic principles that allows this to happen without creating unhealthy market conditions. This number is set daily and typically is around 3% but will be adjusted to 0% as needed during strong climates. Target is to maintain at minimum 20% of the token supply for foundation reserves to align long term incentives.",
  "und_7": "UND Turbo Allocation",
  "und_8": "8,000,000 UND",
  "und_9": "Price",
  "usdt_1": "Adjust Order",
  "usdt_10": "Sell {Symbol} {Amount} {Coin} at {Price}",
  "usdt_100": "Get in touch with your key account manager for more platform benefits such as USDT bonuses.",
  "usdt_101": "Get in touch now",
  "usdt_102": "Pink up",
  "usdt_103": "Pink down",
  "usdt_104": "Red up",
  "usdt_105": "Green down",
  "usdt_106": "TP/SL",
  "usdt_107": "Mark",
  "usdt_108": "Last",
  "usdt_109": "Market",
  "usdt_11": "Buy {Amount} {Coin} of {Symbol} at market price",
  "usdt_110": "Limit",
  "usdt_111": "Order placement failed. The maximum number of existing TP/SL orders is 30.",
  "usdt_112": "Stop order might not trigger due to extreme market fluctuations, price or position limits, insufficient margin, trading suspension, or system errors. A stop order becomes a limit or market order after it is triggered. Once triggered but not executed, unfilled limit and market orders can be found in open orders.When a stop order is triggered and the reverse order exceeds the current position, the entire position will be closed and the system will open a reverse position through the excess orders.",
  "usdt_113": "No longer remind",
  "usdt_114": "Take Profit Buy",
  "usdt_115": "Take Profit Sell",
  "usdt_116": "Stop Loss Buy",
  "usdt_117": "Stop Loss Sell",
  "usdt_12": "Sell {Amount} {Coin} of {Symbol} at market price",
  "usdt_13": "If it failed 6 times, your trading will be banned for 5 minutes",
  "usdt_14": "Positions or pending orders are exist, adjusting margin mode is unavailable",
  "usdt_15": "When there is a position in the isolated margin, only the leverage ratio can be increased Please note the impact of leverage adjustment on the position",
  "usdt_16": "USDT-M Account",
  "usdt_17": "Perpetual",
  "usdt_18": "USDT-M Contract",
  "usdt_19": "Add",
  "usdt_2": "Cross",
  "usdt_20": "Remove",
  "usdt_21": "Max addable",
  "usdt_22": "Currently Margin for {symbol} Perpetual",
  "usdt_23": "Max removable",
  "usdt_24": "Expected adding",
  "usdt_25": "Expected removing",
  "usdt_26": "Est.Liq.Price after increase",
  "usdt_27": "Best-Bid-Offer is a special limit order. By using BBO, orders will be placed at the best price in the order book (Normally it will be the Bid 1/ Ask 1 price).",
  "usdt_28": "Transferable balance",
  "usdt_29": "Margin balance",
  "usdt_3": "Isolated",
  "usdt_30": "Available Balance = Transfer in - Transfer out + Realised PNL + Unrealised PNL - Position Margin - Order Margin",
  "usdt_31": "Market Sell",
  "usdt_32": "Market Buy",
  "usdt_33": "COIN-M Bills",
  "usdt_34": "USDT-M Bills",
  "usdt_35": "COIN-M Orders",
  "usdt_36": "USDT-M Orders",
  "usdt_37": "The position was forced liquidated at {coin} {liq}, and the position was taken over entirely and settled at bankruptcy price of {brp} {coin}",
  "usdt_38": "Maintain margin rate",
  "usdt_39": "Trading pair",
  "usdt_4": "{symbol} Perpetual Margin Mode",
  "usdt_40": "No longer remind. You can reset in [Security Settings - Preferences].",
  "usdt_41": "Preference settings",
  "usdt_42": "Order confirmation",
  "usdt_43": "After enabling the order confirmation function, a secondary confirmation is required every time an order is submitted.",
  "usdt_44": "Bankruptcy price",
  "usdt_45": "Insurance Clearance",
  "usdt_46": "Insurance clearance fee",
  "usdt_47": "Forced liquidation PNL",
  "usdt_48": "Trading is not supported!",
  "usdt_49": "The index price is a comprehensive price index obtained by taking a weighted average of the prices of a basket of major spot trading markets, based on their trading volumes.",
  "usdt_5": "Margin Balance",
  "usdt_50": "This marked price will be used to calculate unrealized profits and losses and margin, and may deviate from the latest transaction price of the contract to avoid price manipulation. The calculation of the marked price is based on the index price.",
  "usdt_51": "The funding rate that long and short positions need to exchange at the next funding rate time. If the rate is positive, long positions pay short positions. If the rate is negative, short positions pay long positions.",
  "usdt_52": "When the trading unit of USDT-M contracts is stable coin units such as USDT, the displayed position quantity is converted based on the actual underlying asset quantity, and the value changes with the latest contract price.",
  "usdt_53": "The average buying/sellin price of the position.",
  "usdt_54": "If the marked price of a long position is lower than the liquidation price, or the marked price of a short position is higher than the liquidation price, your position will be liquidated.",
  "usdt_55": "Margin for position occupancy",
  "usdt_56": "Unrealized PNL calculated using the most recent marked price, as well as the rate of return.",
  "usdt_57": "Actual number of targets",
  "usdt_58": "Transfer to USDT-M Account",
  "usdt_59": "Transfer from USDT-M Account",
  "usdt_6": "Switch Margin Mode",
  "usdt_60": "System Rewards",
  "usdt_61": "Countdown",
  "usdt_62": "Select contract",
  "usdt_63": "Select the contract you want to trade, such as BTC/USDT.",
  "usdt_64": "The USDT-M contract is a forward contract that uses USDT as collateral and settles profits and losses in USDT.",
  "usdt_65": "COIN-M contract is a reverse contract that uses corresponding digital currencies such as BTC as collateral, and settles profits and losses using BTC as well.",
  "usdt_66": "Set margin mode and adjust leverage ratio",
  "usdt_67": "Margin mode: Cross and Isolated.",
  "usdt_68": "Leverage ratio: The size of the leverage ratio depends on the size of the position. The larger the nominal value of the position, the lower the leverage ratio that can be obtained, and the higher the leverage ratio, the greater the risk.",
  "usdt_69": "Transfer funds to USDT-M account",
  "usdt_7": "Failed to place order! The maximum limit for a single order is {Num}{Coin}",
  "usdt_70": "If you want to trade USDT-M contracts, please transfer to the USDT-M account. If you want to trade COIN-M contracts, please transfer to the COIN-M account.",
  "usdt_71": "Place order to open position",
  "usdt_72": "This is the ordering area. BitForex supports multiple types of orders, and the methods for different types of orders can be viewed in the Help Center.",
  "usdt_73": "Simply set the price and quantity you want to trade, and once the market price reaches or exceeds your set price, the transaction can be executed.",
  "usdt_74": "Assets and margin",
  "usdt_75": "You can view account assets and margin.",
  "usdt_76": "Reduce the risk of forced liquidation by closing positions in a timely manner or increasing margin.",
  "usdt_77": "Your positions will all be displayed here, and you can view real-time estimated liquidation prices, margin, and unrealized profits and losses of your current positions.",
  "usdt_78": "Close position: You can directly close your position at market price or limit price.",
  "usdt_79": "Steps",
  "usdt_8": "Close position at market price {amount} {Coin}",
  "usdt_80": "USDT-M Perpetual",
  "usdt_81": "COIN-M Perpetual",
  "usdt_82": "USDT-M Contract",
  "usdt_83": "COIN-M Contract",
  "usdt_84": "Contract value",
  "usdt_85": "Contract Orders",
  "usdt_86": "Wallet Balance",
  "usdt_87": "Est. Liq. price",
  "usdt_88": "Theme",
  "usdt_89": "Quote Color",
  "usdt_9": "Buy {Amount} {Coin} of {Symbol} at {Price}",
  "usdt_90": "Up/Down Colors",
  "usdt_91": "Green up",
  "usdt_92": "Red Down",
  "usdt_93": "Choose a currency",
  "usdt_94": "Language",
  "usdt_95": "Back",
  "usdt_96": "More",
  "usdt_97": "Cost",
  "usdt_98": "Max",
  "usdt_99": "Adjust Margin",
  "usercenter_1": "ID verification(KYC) is required to participate in this token sale",
  "usercenter_10": "Ensure the network you choose to deposit matches the withdrawal network, or assets may be lost.",
  "usercenter_11": "Withdraw Details",
  "usercenter_12": "Risk tip",
  "usercenter_13": "Please carefully check the information of the withdrawal address. Once the withdrawal is completed, the assets will not be recovered.",
  "usercenter_14": "Scan this QR code on the withdrawal page of the Trading Platform App or Wallet App",
  "usercenter_15": "(1~2 Day)",
  "usercenter_16": "Devices and Activities",
  "usercenter_17": "Ensure the network matches the withdrawal address and the deposit platform supports it, or assets may be lost.",
  "usercenter_2": "KYC Status",
  "usercenter_3": "Not yet verified",
  "usercenter_4": "Get verified",
  "usercenter_5": "Due to the request of the project party, this token sale is unavailable for your region. More IEOs will come soon, Please stay tuned!",
  "usercenter_6": "Two-Factor Authentication(2FA)",
  "usercenter_7": "Advanced Security",
  "usercenter_8": "Account Activity",
  "usercenter_9": "Choose Coin/Token",
  "valentine_act_01": "Uncover Your Love With BitForex!",
  "valentine_act_02": "Click register on the landing page to participate the event.",
  "valentine_act_03": "Start Now",
  "valentine_act_04": "No, thanks",
  "valentine_act_05": "Uncover Your Love With BitForex!",
  "valentine_act_06": "Uncover Your Love With BitForex!",
  "valentine_act_07": "Event Countdown",
  "valentine_act_08": "This Event is limited to New Users",
  "valentine_act_09": "Register Now!",
  "valentine_act_10": "Event has ended",
  "valentine_act_11": "Unfortunately, you do not meet the participation rules.",
  "valentine_act_12": "Congratulations on your successful registration! Download the poster now and share it on Twitter and @BitForex official Twitter to join the event.",
  "valentine_act_13": "BitForex official Twitter:",
  "valentine_act_14": "https://twitter.com/bitforexcom",
  "valentine_act_15": "Copy successfully",
  "valentine_act_16": "Download the poster here",
  "valentine_act_17": "Save",
  "valentine_act_18": "KYC Certification",
  "valentine_act_19": "KYC Certification Passed",
  "valentine_act_20": "Upload Photo With BitForex Poster",
  "valentine_act_22": "Event Period:",
  "valentine_act_23": "February 8, 2021 at 16:00 ~ February 10, 2021 at 16:00 (GMT+8)",
  "valentine_act_24": "Participation rules",
  "valentine_act_25": "During the event time, new users register with the invitation code <span>[2404014]</span> and use BitForex design posters to share Valentine's Day photos on Twitter. At the same time, <span>@BitForex official Twitter</span> will be regarded as a successful participation in the event.",
  "valentine_act_26": "Participants of the event need to fill in the BitForex UID in the <span>Google form</span>, share photos, and tweet links.",
  "valentine_act_27": "KYC is REQUIRED to participate in this event.",
  "valentine_act_28": "Total prize pool: <span>10,000 USDT</span>! <b>As of the end of the event, the more likes or reposts, the higher the bonus!</b>",
  "valentine_act_29": "The bonus will be issued to the user's perpetual trading account on February 10, 2021.",
  "valentine_act_30": "Participants will automatically lose their eligibility to participate if they swipe their orders.",
  "valentine_act_31": "BitForex has the right to suspend, terminate and reserve all rights to interpret the event.",
  "valentine_act_32": "Disclaimer",
  "valentine_act_33": "The information about this campaign is meant for informational purposes only. It shall in no way be conceived as any means of implication, suggestion or specification that BitForex provides any form of online gambling. BitForex in no way endorses or condones, promotes or encourages that users participate in online gambling. It is your responsibility to abide by applicable local laws and regulations in your region, jurisdiction or country.",
  "valentine_act_34": "BitForex reserves the ability to disqualify any participants who engage in fraudulent behavior and the right to change the terms, eligibility criteria, and payouts for all campaigns at any time and for any reason.",
  "valentine_act_35": "*Users from the following countries or regions will not be able to participate in this event: Belarus, Democratic Republic of Congo,Cuba, Iraq, Iran, Mainland China, North Korea, Sudan, Syria, United States of America and its territories (American Samoa, Guam, the Northern Mariana Islands, Puerto Rico, and the U.S. Virgin Islands), Zimbabwe.",
  "valentine_act_36": "Share the 10,000 USDT Prize Pool!",
  "vulnerability_1": "Bug Bounty",
  "vulnerability_10": "To receive bug bounty payouts, you must register a BitForex account and bind the email you used to report bugs. Please don't forget to include your BitForex account UID in the reports. The rewards will proceed in USDT and be distributed to the account you provided.",
  "vulnerability_11": "Asking for payout in exchange for vulnerability details will result in immediate ineligibility of bounty payments.",
  "vulnerability_12": "Please provide as detailed a report as possible so that we can reproduce your findings. Otherwise, maybe you will miss rewards.",
  "vulnerability_13": "For combined exploitable vulnerabilities, we will only pay for the highest level of vulnerabilities. For the same vulnerabilities, we will only pay for the first one that includes enough details in the report.",
  "vulnerability_14": "BitForex reserves the right to cancel or amend the bug bounty rules at our sole discretion.",
  "vulnerability_15": "Program Scopes",
  "vulnerability_16": "In Scope",
  "vulnerability_17": "URL",
  "vulnerability_18": "Type",
  "vulnerability_19": "Out of Scope",
  "vulnerability_2": "Earn Rewards for Reporting Bugs",
  "vulnerability_20": "Vulnerabilities found in out-of-scope resources are unlikely to be rewarded unless they present a serious business risk (Assessed by BitForex).",
  "vulnerability_21": "Best practices concerns",
  "vulnerability_22": "Self-XSS & HTML injection",
  "vulnerability_23": "Unreproducible vulnerabilities",
  "vulnerability_24": "User enumeration vulnerability",
  "vulnerability_25": "Version leak issues such as Nginx, etc.",
  "vulnerability_26": "Vulnerabilities in third-party applications",
  "vulnerability_27": "Vulnerabilities requiring victim interaction",
  "vulnerability_28": "CSRF issues for non-sensitive operations",
  "vulnerability_29": "Web pages lack CSP and SRI security policies",
  "vulnerability_3": "About Us",
  "vulnerability_30": "Some functional bugs that do not pose a security risk issue",
  "vulnerability_31": "Social engineering, phishing, physical attack, email spoofing, or other fraud activities",
  "vulnerability_32": "Some problems such as changing the size of the image and causing slow requests, etc.",
  "vulnerability_33": "Recently (less than 30 days) disclosed 0day vulnerabilities, for example, the log4j2 vulnerability",
  "vulnerability_34": "Vulnerabilities that require any third-party applications (including malware) to be installed on the victim’s device",
  "vulnerability_35": "A separate issue about Android app android:allowBackup=”true” , and the service is denied locally, etc. (unless in-depth use)",
  "vulnerability_36": "Severity & Rewards",
  "vulnerability_37": "Critical：1500 - 2500 USDT",
  "vulnerability_38": "High：500 - 1000 USDT",
  "vulnerability_39": "Medium：200 - 300 USDT",
  "vulnerability_4": "BitForex is one of the top cryptocurrency exchanges dedicated to providing users with safe, professional, and convenient digital currency trading services. BitForex is leading the trend of the cryptocurrency exchange industry by effectively providing a wide range of trading tools including token trading, margin trading, and derivatives constantly adapting to new market needs with the continuous introduction of new features.",
  "vulnerability_40": "Low：50 - 100 USDT",
  "vulnerability_41": "Statistics",
  "vulnerability_42": "22 closed reports in the last half-year",
  "vulnerability_43": "7000 USDT payout in the last half-year",
  "vulnerability_44": "Vulnerability Severity",
  "vulnerability_45": "Critical Vulnerability",
  "vulnerability_46": "A critical vulnerability refers to the vulnerability that occurs in the core business system (the core control system, field control, business distribution system, fortress machine, and other control systems that can manage a large number of systems). It can cause a severe impact, gain business system control access (depending on the actual situation), gain core system management staff access, and even control the core system.",
  "vulnerability_47": "It includes but is not limited to:",
  "vulnerability_48": "Multiple devices access in the internal network;",
  "vulnerability_49": "Gain core backend super administrator access, leak enterprise core data, and cause a severe impact;",
  "vulnerability_5": "The company is headquartered in Hong Kong, registered in the Republic of Seychelles in 2017, independent operating teams have located in Germany, South Korea, Singapore, Russia, and more.",
  "vulnerability_50": "Smart contract overflow and conditional competition vulnerability.",
  "vulnerability_51": "High Vulnerability",
  "vulnerability_52": "Gain system access (getshell, command execution, etc.).",
  "vulnerability_53": "System SQL injection (backend vulnerability degradation, prioritization of package submission as appropriate).",
  "vulnerability_54": "Gain unauthorized access to the sensitive information, including but not limited to the direct access to the management background by bypassing authentication, brute force attackable backend passwords, obtaining SSRF of sensitive information in the internal network, etc.",
  "vulnerability_55": "Arbitrarily document reading.",
  "vulnerability_56": "XXE vulnerability that can access any information.",
  "vulnerability_57": "The unauthorized operation involves money, payment logic bypassing (need to be successfully utilized).",
  "vulnerability_58": "Severe logical design defects and process defects. This includes but is not limited to any user login vulnerability, the vulnerability of batch account password modification, logic vulnerability involving enterprise core business, etc., except for verification code explosion.",
  "vulnerability_59": "Other vulnerabilities that affect users on a large scale. This includes but is not limited to the storage XSS that can be automatically propagated on the critical pages. The storage XSS can access administrator authentication information and be successfully utilized.",
  "vulnerability_6": "Until October 2021, BitForex serves over 5 million users from more than 200 countries and regions. The maximum daily active users have reached 160,000.",
  "vulnerability_60": "Leakage of a lot of source code.",
  "vulnerability_61": "The permission control defects in the smart contract.",
  "vulnerability_62": "Medium Vulnerability",
  "vulnerability_63": "The vulnerability that can affect users by the interaction part. It includes but is not limited to the storage XSS on general pages, CSRF involving core business, etc.",
  "vulnerability_64": "General unauthorized operation. It includes but is not limited to modifying user data and performing user operations by bypassing restrictions.",
  "vulnerability_65": "Denial-of-service vulnerabilities. It includes but is not limited to the remote denial-of-service vulnerabilities caused by denial-of-service of web applications.",
  "vulnerability_66": "The vulnerabilities caused by a successful explosion with the system sensitive operation, such as any account login and password access, etc., due to verification code logic defects.",
  "vulnerability_67": "The leakage of locally-stored sensitive authentication key information, which needs to be able to use effectively.",
  "vulnerability_68": "Low Vulnerability",
  "vulnerability_69": "Local denial-of-service vulnerabilities. It includes but is not limited to the local client denial-of-service (parsing file formats, crashes generated by network protocols), problems caused by Android component permission exposure, general application access, etc.",
  "vulnerability_7": "Program Rules",
  "vulnerability_70": "General information leakage. This includes but is not limited to Web path traversal, system path traversal, directory browsing, etc.",
  "vulnerability_71": "Reflective type XSS (including DOM XSS/Flash XSS).",
  "vulnerability_72": "General CSRF.",
  "vulnerability_73": "URL skip vulnerability.",
  "vulnerability_74": "SMS bombs, mail bombs (each system only accepts one type of this vulnerability).",
  "vulnerability_75": "Other less harmful vulnerabilities cannot be proven dangerous (such as CORS vulnerability that cannot access sensitive information).",
  "vulnerability_76": "No return value and no in-depth utilization of successful SSRF.",
  "vulnerability_77": "Prohibited Behaviors",
  "vulnerability_78": "Network terminals and abnormal service access caused by the below behaviors will be handled in accordance with relevant laws and regulations:",
  "vulnerability_79": "Without permission from BitForex, it is forbidden to disclose the details of any discovered vulnerabilities.",
  "vulnerability_8": "All bug reports must be submitted to  <span class=\"span\" style=\"text-decoration: underline; color: #14C393;\">https://hackenproof.com/bitforex/bitforex</span>",
  "vulnerability_80": "It is forbidden to abuse Dos/DDoS vulnerabilities, social engineering attacks, spam, phishing attacks, etc.",
  "vulnerability_81": "It is forbidden to use web/port automatic scanners and other behaviors that may cause many traffic requests.",
  "vulnerability_82": "All vulnerability tests should use your own accounts. It is forbidden to obtain other user accounts in any form for testing/intrusion operations.",
  "vulnerability_83": "Avoid possible impacts or restrictions including but not limited to the availability of business, products, architecture, etc.",
  "vulnerability_84": "Critical",
  "vulnerability_85": "High",
  "vulnerability_86": "Medium",
  "vulnerability_87": "Low",
  "vulnerability_88": "Web",
  "vulnerability_89": "Android",
  "vulnerability_9": "All bug reports are rated by BitForex and paid out based on vulnerability severity.",
  "warning": "Turbo's First Premium Project: UND",
  "warn_1": "The trading pair is not available.",
  "warn_10": "The mobile phone, email, or ID you used came from the Chinese Mainland. To comply with local laws and regulations, we stopped the trading service of your account. The withdrawal service is available. If you are not a Chinese Mainland citizen, you can contact our CS to assist.",
  "warn_2": "Risk Warning",
  "warn_3": "Risk Warning: High fluctuation on new trading pairs.",
  "warn_4": "{coin} now is under the stage of the Premium Token Offering. User can only buy {coin} at a fixed price, the sell function is not available yet.",
  "warn_5": "Risk Warning: Cryptocurrency trading involves significant risk. Please make sure that you have fully understood the risk involved and took it into consideration.",
  "warn_6": "Tips",
  "warn_7": "{coin} is now on BitForex Turbo. User can only buy {coin} at a fixed price, the sell function is not available yet.",
  "warn_8": "Min. Vol",
  "warn_9": "Max. Vol",
  "week_1": "Sunday",
  "week_2": "Monday",
  "week_3": "Tuesday",
  "week_4": "Wednesday",
  "week_5": "Thursday",
  "week_6": "Friday",
  "week_7": "Saturday",
  "well_1": "WELL (Wellness Token Economy)",
  "well_10": "Lock-up Period",
  "well_11": "No Lock-up",
  "well_12": "IMPORTANT NOTICE",
  "well_13": "1. The minimum order amount for a single account is 10 USDT = 3,333 WELL<br>2. The maximum order amount for a single account is 500 USDT = 166,667 WELL<br>3. There is no handling fee for the transaction<br>4. KYC is NOT required for the WELL subscription.<br>5. There are many participating users. It is recommended to log in before 7:00 (GMT+8) and prepare in advance.",
  "well_16": "REFERENCE",
  "well_17": "WELL (Wellness Token Economy) Website",
  "well_18": "https://wellness-token.com/",
  "well_19": "WELL (Wellness Token Economy) Whitepaper",
  "well_2": "Bringing cutting-edge Japanese technology to your health",
  "well_20": "https://wellness-token.com/_pdf/whitepaper.pdf",
  "well_21": "INTRODUCTION",
  "well_22": "About WellnessToken Project",
  "well_23": "WellnessToken aims to solve social health problems by combining cutting-edge blood testing technology from Japan with blockchain and token economy.\n\nThis project has a unique premise based on the universal motivation that “everyone wants to be healthy” for the item of building an economic zone, which is a major issue for all blockchain projects. WELL is trying to create its own Token Economy.",
  "well_26": "Core Feautures",
  "well_27": "Currently, the use of micro blood sampling devices (patented) handled by the WellnessToken project enables simple and pain-free blood collection, which is compared to conventional blood collection by doctors and nurses. With a blood collection volume of / 100 or less, it is possible to perform the same high-precision test as medical treatment. In this test, 13 items such as cholesterol level, glucose metabolism, liver function and kidney function can be tested with as little as 0.06 ml of blood, and various tumor markers, antibodies, hormones and other immune items can be tested.",
  "well_29": "About WELL Token",
  "well_3": "2019/12/13 12:00 (GMT+8)",
  "well_30": "WELL has made it possible to visualize health that was difficult until now. If you contribute to your wellness through health activities, you will receive WELL. The accumulation of WELL will make you healthy naturally. Saved WELL can be used as a service to become healthier. Companies will improve their health awareness by investing in well-promoting activities. This ring of Wellness Tokens creates a new super-circulating society and economy.",
  "well_33": "WELL (Wellness Token Economy) Telegram",
  "well_34": "https://t.me/wellness_token_official",
  "well_35": "WELL Token Sale and Economics",
  "well_4": "2019/12/14 12:00 (GMT+8)",
  "well_44": "Token Sale Start Time",
  "well_45": "2019/12/13 12:00 - 2019/12/14 12:00 (GMT+8)",
  "well_46": "Token Price",
  "well_47": "0.003 USDT",
  "well_48": "Turbo Allocation",
  "well_49": "333,333,333 WELL",
  "well_5": "Subscription Time：2019/12/13 12:00 - 2019/12/14 12:00 (GMT+8)",
  "well_50": "Bonus",
  "well_51": "NONE",
  "well_52": "Total Token Supply",
  "well_53": "10,000,000,000 WELL",
  "well_56": "Initial Circulating Supply",
  "well_57": "0",
  "well_58": "Token Sale Vesting Period",
  "well_59": "NONE",
  "well_6": "Subscription Amount: 333,333,333 WELL = 1,000,000 USDT",
  "well_60": "The Maximum Order Amount",
  "well_61": "166,667 WELL = 500 USD",
  "well_62": "The Minimum Order Amount",
  "well_63": "3,333 WELL = 10 USD",
  "well_64": "Private Sale Token Price",
  "well_65": "NONE",
  "well_66": "Token Type",
  "well_67": "Stellar",
  "well_7": "Price: 0.003 USDT",
  "well_70": "TOKEN ALLOCATION",
  "well_71": "System Development 20%\nProject Management 20%\nMarketing 20%\nBounty 10%\nReserve Fund 30%",
  "well_72": "WELL TEAM",
  "well_8": "Subscription Trading Pair",
  "well_9": "WELL/BTC",
  "withDraw_action": "Action",
  "withDraw_add": "Add",
  "withDraw_addAddressTitle": "Add {coinName} Address",
  "withDraw_addchoose": "Add from address book selection",
  "withDraw_address": "Address",
  "withDraw_addressInputTips": "Please enter withdrawal address",
  "withDraw_addressInputTips2": "The address format is wrong,please recheck and enter the correct address.",
  "withDraw_addressName": "Name",
  "withDraw_addressNameTips": "Please enter a name",
  "withDraw_addressWithdraw": "Withdrawal Address",
  "withDraw_addressWithdrawMemo": "{coin} Withdrawal Tag(MEMO)",
  "withDraw_addressWithdrawNeedMemo": "{coin} Withdrawal needs to enter Tag(MEMO)",
  "withDraw_address_1": "Withdrawals are suspended for 1 hours when a new address is added",
  "withDraw_address_2": "New address detected, withdrawals will be available at {time}",
  "withDraw_addSuccessTips": "Succeed",
  "withDraw_amount": "Amount",
  "withDraw_applying": "Applying",
  "withDraw_applySuccessTips": "Succeed",
  "withDraw_auditComplete": "Audit Completed",
  "withDraw_availableBalance": "Available",
  "withDraw_bigTip": "When the amount in a single withdrawal transaction exceeds the equivalent of $10,000 USD, we will process the withdrawal with extra precautions. We implement multiple audit mechanisms to ensure the safety of your assets. Your withderal may take up to 24 hours to process. Please be patient as we process your request. Thank you for your continued support and understanding.",
  "withDraw_bindEmail": "Please bind email",
  "withDraw_bindingTips": "In order to improve the security of your account, you need to open \"Message Authentication\" or \"Google Authentication\". Please enable one of them at Security Settings.",
  "withDraw_bindingTips2": "In order to improve the security of your account, you need to open “Email verification",
  "withDraw_cancel": "Cancel",
  "withDraw_canceled": "Canceled",
  "withDraw_canceleSuccessTips": "Succeed",
  "withDraw_choose": "Select from address book",
  "withDraw_cmompleted": "Done",
  "withDraw_comfirm": "Confirm",
  "withDraw_comfirmDelete": "Confirm Delete",
  "withDraw_copy": "Copy",
  "withDraw_currencyKeywords": "Token Keywords",
  "withDraw_delelteAddress": "Delete address",
  "withDraw_delete": "Delete",
  "withDraw_deleteAddressSuccessTips": "Succeed",
  "withDraw_failed": "Failed",
  "withDraw_fio_1": "Please input FIO Crypto Handle",
  "withDraw_fio_10": "Please get the withdrawal address",
  "withDraw_fio_11": "The address format is wrong.",
  "withDraw_fio_2": "Wrong address format",
  "withDraw_fio_3": "Resolving FIO Crypto Handle...",
  "withDraw_fio_4": "Invalid FIO Crypto Handle.",
  "withDraw_fio_5": "Resolving address failed.",
  "withDraw_fio_6": "FIO Crypto Handle",
  "withDraw_fio_7": "Confirm",
  "withDraw_fio_8": "Use FIO Crypto Handle",
  "withDraw_fio_9": "When you are using the FIO Crypto Handle to withdraw your assets, the system will resolve it to get the wallet's public key.\n\nPlease make sure you have inputted the correct FIO Crypto Handle. \n\nIf you used the wrong FIO Crypto Handle, your withdrawal might be failed or caused assets lost.",
  "withDraw_frozenTip": "Estimated resume after {time}",
  "withDraw_getGoogleAuthenticatorCode": "Google verification code",
  "withDraw_getGoogleAuthenticatorCodeTips": "Please enter google verification code",
  "withDraw_grin_1": "Important Notice:",
  "withDraw_grin_2": "{token} withdrawal requires both your BitForex wallet and destination wallet to be online to successfully finish the transaction. Please make sure both wallets are online, or the withdrawal may not process.",
  "withDraw_grin_3": "In the event of a failed transaction, BitForex will return your assets and reset the transfer status after a 48-hour period.",
  "withDraw_identify": "Label each address for easy identification.",
  "withDraw_inProcess": "In process",
  "withDraw_knowWarning": "I have checked the withdrawal information and know the risk.",
  "withDraw_lineName": "Chain Name",
  "withDraw_minerFee": "Transaction Fee:",
  "withDraw_minerFeeInputTips": "Please enter the transaction fee",
  "withDraw_minerFeeMinTips": "The minimum fee of transaction is",
  "withDraw_minerFeeWarn1": "The transaction fee you entered is exceeds the number of coins, Please confirm.",
  "withDraw_minerFeeWarn2": "The transaction fee you entered is more than the twice of minimum transaction fee, Please confirm.",
  "withDraw_minerFeeWarn3": "The sum of \"Transaction Fee\" and \"Withdrawal Amount\" cannot be greater than \"Available Balance",
  "withDraw_minWithDraw": "Withdrawal must be at least {minWithDraw}",
  "withDraw_moneyaddress": "Withdrawal address",
  "withDraw_moneyconfirm": "Confirmation of currency withdrawal information",
  "withDraw_msgAuthenticationCode": "Message verification code",
  "withDraw_msgAuthenticationCodeTips": "Please enter verification code",
  "withDraw_mustIntTip": "{coinName} only supports integer withdrawals, please enter an integer.",
  "withDraw_name": "Name",
  "withDraw_nameInputTips": "Please enter a name",
  "withDraw_newaddress": "New address",
  "withDraw_noData": "No Data",
  "withDraw_noRecord": "No Data",
  "withDraw_nsgTip1": "Withdraw to AmicoPay",
  "withDraw_nsgTip2": "Correct ID required, or your assets can't be retrieved",
  "withDraw_nsgTip3": "Please enter AmicoPay ID",
  "withDraw_nsgTip4": "Please enter the correct AmicoPay ID (only numbers are supported)",
  "withDraw_other": "Others",
  "withDraw_pageTitle": "Withdrawals",
  "withDraw_realAmount": "Amount Received",
  "withDraw_receiveAmount": "Amount Received",
  "withDraw_recommend": "(Recommended)",
  "withDraw_recordTips": "To improve system performance, BitForex will delete historical data older than 3 months at regular intervals based on memory usage.",
  "withDraw_reject_1": "Incorrect withdrawal address",
  "withDraw_reject_10": "Request failed, please contact customer support",
  "withDraw_reject_11": "Due to blockchain issue, please apply again",
  "withDraw_reject_2": "Withdrawal audit failed, please contact customer support",
  "withDraw_reject_3": "Withdrawals of this asset is temporarily suspended",
  "withDraw_reject_4": "Receiving address is offline",
  "withDraw_reject_5": "Withdrawal amount is required to be a integer",
  "withDraw_reject_6": "MEMO is required",
  "withDraw_reject_7": "Internal transfer within the platform is prohibited for this asset",
  "withDraw_reject_8": "The address is not activated",
  "withDraw_reject_9": "MEMO error, please enter the correct MEMO",
  "withDraw_repson": "Cause of Failed Request",
  "withDraw_reSend": "Send again",
  "withDraw_riskWarning": "Risk tip: please carefully check the information of the withdrawal address. Once the withdrawal is completed, the assets will not be recovered.",
  "withDraw_safeTip1": "For you account safety, withdrawals will be suspended for {hour} hour(s) after a mobile phone is binded.",
  "withDraw_safeTip2": "For you account safety, withdrawals will be suspended for {hour} hour(s) after the Google 2FA is binded.",
  "withDraw_safeTip3": "For your safety, withdrawal will be suspended for {hour} hour after completing any changes as following: password reset, SMS verification unbind, or unusual IP address detected.",
  "withDraw_safeTip4": "For you account safety, withdrawals will be suspended for {hour} hour(s) when a unusual IP address is detected.",
  "withDraw_safeTip5": "Withdrawal verifications have failed 3 times. For your account safety, withdrawals will be suspended for 1 hour.",
  "withDraw_safeTip6": "Withdrawals will be suspended for 24 hours when a new withdrawal address is added.",
  "withDraw_safeTip8": "For your account safety, withdrawal functions are prohibited for {hour} hours after the Google 2FA is changed.",
  "withDraw_savechoose": "Select save from the address book, which can be quickly selected during currency withdrawal.",
  "withDraw_securityAuthenticator": "Security authentication",
  "withDraw_sendSms": "Send",
  "withDraw_settingButton": "Go Settings",
  "withDraw_status0": "Auditing",
  "withDraw_status1": "Audit Completed",
  "withDraw_status10": "Failed, please contact the customer support team.",
  "withDraw_status11": "In progress",
  "withDraw_status12": "Failed, please contact the customer support team.",
  "withDraw_status13": "Failed, please contact the customer support team.",
  "withDraw_status2": "In progress",
  "withDraw_status3": "Completed",
  "withDraw_status4": "Failed, {reason}",
  "withDraw_status5": "Failed, please contact the customer support team.",
  "withDraw_status6": "Failed, please contact the customer support team.",
  "withDraw_status7": "Canceled",
  "withDraw_status8": "Audit Completed",
  "withDraw_status9": "In progress",
  "withDraw_submit": "Submit",
  "withDraw_submits": "Submit",
  "withDraw_time": "Time",
  "withDraw_tipmode": "Transaction Fee",
  "withDraw_tipsItem3": "BitForex have finished the Klaytn mainnet swap of the ISR token. Please make sure your withdrawal address supports the Klaytn mainnet before you proceed the withdrawal.",
  "withDraw_tipsItem4": "The transaction fee is preferentially deducted from the “available balance” and the short part is deducted from the “withdrawal amount”.",
  "withDraw_tipsItem5": "WBL transactions require to pay fees to miners,WBL Transaction Fee=Withdrawal Amount*0.15%. BitForex not collect any extra fees.",
  "withDraw_tip_03": "To help users reduce the withdrawal cost, BitForex uses the dynamic calculation for withdrawal fee and the withdrawal fee will be updated every hour.",
  "withDraw_tip_04": "The withdrawal fee has been changed, Plesae reconfirm.",
  "withDraw_tip_05": "The official smart contract of the crypto-currency will charge an additional {p1}% for each transfer, which is not charged by BitForex. BitForex will charge an additional {p2}%  as a wallet collection fee for this withdrawal. So the final withdrawal is subject to the actual amount received.",
  "withDraw_title": "Tips",
  "withDraw_token": "Token",
  "withDraw_wblFun": "Transaction Fee = Withdrawal Amount * 0.15%",
  "withDraw_withDrawAddress": "Withdrawal Address",
  "withDraw_withDrawAddressInputTips": "Please choose withdrawal address",
  "withDraw_withDrawAddressRemark1": "You can",
  "withDraw_withDrawAddressRemark2": "Add frequently used address",
  "withDraw_withDrawAddressRemark3": "This function is upgrading. Adding a new address is temporarily unavailable.",
  "withDraw_withDrawAddressSelectTips": "No Data",
  "withDraw_withDrawAmount": "Withdrawal Amount",
  "withDraw_withDrawAmountOver": "Not enough balance in you account.",
  "withDraw_withDrawAmountRemark": "Please enter the withdrawal amount",
  "withDraw_withDrawDailyLimit": "24h withdrawal limit",
  "withDraw_withDrawMemo": "Tag (MEMO)",
  "withDraw_withDrawMemoRemark": "The Memo only includes English letters, numbers and underline.",
  "withDraw_withDrawMemoTips": "Please notice the Tag (MEMO)",
  "withDraw_withDrawMemoTips2": "MEMO must be a positive integer > 0.",
  "withDraw_withDrawSelectlabel": "Please choose a Token",
  "withDraw_withDrawStatus": "Status",
  "withDraw_withDrawTips": "Please enter withdrawal address",
  "withDraw_withDrawTitle": "Withdrawal History",
  "withDraw_wrmoneyaddress": "Please enter the withdrawal address",
  "withDrwa_tip_02": "{coinCode} has a fixed {rate1}% transaction fee for each on-chain transaction. We will charge an extra {rate2}% withdrawal fee to cover the deposit collection costs.",
  "withDrwa_ztip_01": "The mainnet for this token is GoChain, please check your address before you proceed your withdrawal.",
  "worldcup_1": "FIFA World Cup Qatar 2022",
  "worldcup_10": "Semi-Finals",
  "worldcup_11": "Third Place",
  "worldcup_12": "Final Match",
  "worldcup_13": "Voting ends at",
  "worldcup_14": "Match Voting",
  "worldcup_15": "Result Pending",
  "worldcup_16": "Match Ended",
  "worldcup_17": "Home Win Odds",
  "worldcup_18": "Away Win Odds",
  "worldcup_19": "Until the end of voting period",
  "worldcup_2": "Voting Rules",
  "worldcup_20": "Start Voting",
  "worldcup_21": "Vote Team",
  "worldcup_22": "Vote Amount",
  "worldcup_23": "Minimum Vote Amount",
  "worldcup_24": "Voting Confirmation",
  "worldcup_25": "I have read and agree to the <a href=\"https://event.bitforex.com/en/Word.html\" target=\"_blank\"class=\"text-[var(--color-color-brand-600-primary)]\">《FIFA World Cup Qatar 2022》</a>",
  "worldcup_26": "Voting Success",
  "worldcup_27": "Continue",
  "worldcup_28": "View Record",
  "worldcup_29": "Click on the flag to choose the champion in your mind",
  "worldcup_3": "Match Schedule",
  "worldcup_31": "Current Prize Pool",
  "worldcup_32": "Please wait for the results of the match.",
  "worldcup_33": "Profits",
  "worldcup_34": "Congratulations you've voted the win team! the profits and voting funds have been distributed to your wallet account.",
  "worldcup_35": "{name} is the champion of the FIFA World Cup 2022",
  "worldcup_36": "Match",
  "worldcup_37": "Voted Time",
  "worldcup_38": "Voted Team",
  "worldcup_39": "Voted Amount",
  "worldcup_4": "Single Match",
  "worldcup_40": "Current Status",
  "worldcup_41": "World Cup Voting",
  "worldcup_42": "World Cup Rewards",
  "worldcup_43": "Please select a team to vote.",
  "worldcup_44": "Vote with your wallet account balance",
  "worldcup_45": "Single Minimum Vote",
  "worldcup_46": "Vote the champion team",
  "worldcup_47": "Not selected",
  "worldcup_48": "Win",
  "worldcup_49": "Waiting for distribution",
  "worldcup_5": "Championship Prediction",
  "worldcup_50": "In distribution",
  "worldcup_51": "Distributed",
  "worldcup_52": "Continue",
  "worldcup_53": "Cancel",
  "worldcup_54": "Go Back",
  "worldcup_55": "View more",
  "worldcup_56": "Please enter the voting amount",
  "worldcup_57": "System error occurred",
  "worldcup_6": "Voting Records",
  "worldcup_7": "Group Matches",
  "worldcup_8": "Round of 16",
  "worldcup_9": "Quarter-Finals",
  "worldcup_banner": "FIFA World Cup Qatar 2022",
  "worldcup_trans": "Transfer",
  "xmas_1": "\"BitForex Christmas Party-- A present for yourself!\"",
  "xmas_10": "How to participate?",
  "xmas_11": "1. Sign up on BitForex (For new users)",
  "xmas_12": "2. Fill out the Google form and select the event you want to take part in",
  "xmas_13": "3. Complete the requirements of the competition and get your rewards",
  "xmas_14": "Event Details",
  "xmas_15": "Sharing Reward",
  "xmas_16": "Login Reward",
  "xmas_17": "Perpetual Contract Trading Reward",
  "xmas_18": "Reward Distribution Terms:",
  "xmas_19": "1. All participation is based on the Google event form. Participation without filling out the event form will be invalid;",
  "xmas_2": "\"Event Period: December 24th, 2019 19:00 - December 31st, 2019 19:00 (GMT+8)\"",
  "xmas_20": "2. The order of rewards is based on the time of submission Google form. Places are limited, first come first served;",
  "xmas_21": "3. Rewards will be distributed within 3 business days after the conclusion of the event;",
  "xmas_22": "4. If there is malicious brush volume behavior, the participant will be disqualified;",
  "xmas_23": "5. BitForex reserves the right to change, update or cancel this promotion without preliminary notice.",
  "xmas_24": "During the event time, users who successfully complete ALL required missions on Twitter, such as Share/Like/Comment, will be rewarded 50 BF tokens.",
  "xmas_25": "Places: 5000",
  "xmas_26": "Click the link to complete missions: Sharing Reward",
  "xmas_27": "During the event time, users who complete logging in at least 5 consecutive days will be rewarded 150 BF tokens.",
  "xmas_28": "Places: 5000",
  "xmas_29": "Snapshot time: everyday 0:00-24:00 (GMT+8) during the event time (The system will automatically count all qualified users)",
  "xmas_3": "Prize Pool",
  "xmas_30": "During the event time, users who trade perpetual contract more than 50,000 cont., will be rewarded 2500 BF tokens.",
  "xmas_31": "Trade Now",
  "xmas_32": "Places: 3000",
  "xmas_33": "Details",
  "xmas_34": "Xmas Airdrop",
  "xmas_4": "50 BF",
  "xmas_5": "Sharing Reward",
  "xmas_6": "150 BF",
  "xmas_7": "Login Reward",
  "xmas_8": "2500 BF",
  "xmas_9": "Perpetual Contract Trading Reward"
}