<template>
  <div class="down-fixed" v-if="isMobile&&showFixedTip">
    <div><img class="logo-img" src="https://img-cdn.bitforex.vip/favicons.png">
      <i class="dia-close" @click="showFixedTip=false"><img src="../../images/close_en.png"> </i>
    </div>
    <div class="center-con"><strong>BitForex App</strong><br>{{$t('down_downTips')}}</div>
    <a class="down-btn" :href="`https://m.bitforex.com/${locale}/download`" target="_blank">{{$t('down_downbtn')}}</a>
  </div>
</template>
<script>
import { isMobile } from '../../../../utils/util';
import {mapState} from 'vuex';
export default {
  data () {
    return {
      showFixedTip: true,
      isMobile: false
    };
  },
  computed: {
    ...mapState({
      locale: state => state.common.locale,
    }),
  },
  mounted () {
    this.isMobile = isMobile();
  }
};
</script>
<style lang="scss" scoped>

.down-fixed {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  padding: 10px 15px;
  display: flex;
  z-index: 9999999999;
  box-shadow: 0px -6px 45px 0px rgba(103, 151, 255, 0.11);
  .logo-img {
    width: 40px;
    height: 40px;
    border-radius: 5px;
  }
  > .center-con {
    margin-left: 10px;
    font-size: 12px;
    line-height: 20px;
    color: var(--COLOR-87);
    flex-grow: 1;
    text-align: left;
    > strong {
      font-size: 16px;
      font-weight: bold;
    }
  }
  > .down-btn {
    min-width: 103px;
    height: 40px;
    background: #14c393;
    color: #fff;
    font-size: 14px;
    border: none;
    text-align: center;
    line-height: 40px;
  }
  .dia-close {
    position: absolute;
    right: initial;
    left: 15px;
    transform: translateY(-100%);
    top: 10px;
    > img {
      width: 100%;
    }
  }
}

</style>
