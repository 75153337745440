import axios from 'axios';
import Vue from 'vue';
import Common from './common';
import md5 from 'js-md5';
import {
  errorCode
} from '../commonFun/errorCode.js';
import {
  getCookie
} from './util';
import Cookies from 'js-cookie';
import eventBus from './eventBus';
const CancelToken = axios.CancelToken;
const lang = window.location.pathname.split('/')[1];
let connecting = {}; // 正在链接中的请求
/**
 *  对URL里的变量进行替换处理
 * @param url
 * @param params
 * @returns {*}
 */
const transformUrl = (url, params = {}) => {
  const reg = /{([^}]*)}/g;
  return url.replace(reg, ($1, $2) => {
    if (params[$2]) {
      let val = params[$2];
      delete params[$2];
      return val;
    }
    return '';
  });
};

const getParam = (data) =>{
  let url = '';
  for(var k in data){
    let value = data[k] !==undefined ? data[k] : '';
    url += `&${k}=${encodeURIComponent(value)}`
  }
  return url ? url.substring(1) : ''
}

const http = axios.create({
  baseURL: '/'
});

// 添加请求拦截器
http.interceptors.request.use(function (config) {
  if (Cookies.get('crtoken')) {
    config.headers['crtoken'] = Cookies.get('crtoken');
  }
  // 在发送请求之前做些什么
  if(Common.headerData) {
    config.headers = Common.headerData;
  }
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
http.interceptors.response.use(function (response) {
  const bfusid = getCookie('bfusid');
  // 将crtoken存储在cookie
  if ( response.headers.crtoken) {
    Cookies.set('crtoken', md5(bfusid + response.headers.crtoken));
  }
  if (response.headers.sessiontoken) {
    Cookies.set('bf-sessionToken', headers.sessiontoken)
  }
  // 对响应数据做点什么
  if (response.status === 200 && response.data && (response.data.code === '200' || response.data.code === 200 || response.data.code == 0)) {
    return response.data.data;
  } else {
    
    let message = errorCode(response.data.code, response.data.message, lang || getCookie('lang') || 'en') || errorCode(response.data.code, response.data.message, 'en');
    // message || (message = response.data.message);
    if (response.data.code == 'WE101' || response.data.code == '100016' || response.data.code == 'US705') {
      eventBus.$emit('auth', message);
      return
    }

    message && Vue.prototype.$alert(message);

    return Promise.reject(response.data);
  }
}, function (err) {
  // 对响应错误做点什么
  // console.log('perpetual err', err.request);
  if (err.request) {
    // window.sensors.track('ApiError', {
    //   url: err.request.responseURL,
    //   message: `${err.request.status}_${err.request.statusText}`
    // }, () => Vue.prototype.$log('ApiError'));
  }
  return Promise.reject(err);
});

/**
 * 创建HTTP请求工厂函数
 * @param url
 * @param method
 * @param headers
 * @param host url的域名（可选）
 * @returns {Function} 返回一个axja请求
 */
export default function creatHttp (url, method = 'get', host = '') {
  const methodsArray = ['get', 'delete', 'head', 'post', 'put', 'patch'];
  if (!methodsArray.includes(method)) {
    method = 'get';
  }
  return (params, recove = false) => {
    const __params = {...params}
    const _url = transformUrl(url, params);
    if (method.toLocaleLowerCase() === 'get') {
      params || (params = {});
      params.v = Date.now();
    }
  
    const key = method == 'get' ?  _url + '&' + getParam(__params) + '&' + method : _url + '&' + method;
    if (connecting[key]) {
      if (recove) {
        connecting[key].cancel();
      } else {
        return connecting[key].http;
      }
    }
   
    connecting[key] = {};
    const cancelToken = new CancelToken(function executor (c) {
      // executor 函数接收一个 cancel 函数作为参数
      connecting[key].cancel = c;
    });
   
    let config = {
      url: `${host}${_url}`,
      method,
      withCredentials: true,
      data: params,
      cancelToken
    };
    method === 'get' ? (config.params = params) : (config.data = params);
    connecting[key].http = http.request(config).then(data => {
      connecting[key] = null;
      delete connecting[key];
      return data;
    }).catch(err => {
      // 对403错误码进行提示
      if (err && err.response && err.response.status === 403) {
        Vue.prototype.$alert(errorCode('403', '', lang || getCookie('lang') || 'en'));
      }
      connecting[key] = null;
      delete connecting[key];
      return Promise.reject(err);
    });
    return connecting[key].http;
  };
}
