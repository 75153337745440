<template>
  <div class="right-enter" v-show="show">
    <div v-bind:title="locale==='hk'?'幫助中心':'Support'" class="item" @click="open(`https://www.bitforex.com/${locale}/Support`)">
      <svg width="26" height="32" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 3C1 1.89543 1.89543 1 3 1H25V31H3C1.89543 31 1 30.1046 1 29V3Z"   stroke-width="2"/>
        <path d="M1.92871 28C1.92871 26.3431 3.27186 25 4.92871 25H25.0001V31H4.92871C3.27186 31 1.92871 29.6569 1.92871 28Z"  stroke-width="2"/>
        <path d="M8.765 13.3131L5.64307 15.2182V1H12.9288V15.2182L9.80684 13.3131L9.28592 12.9952L8.765 13.3131Z"   stroke-width="2"/>
      </svg>
    </div>
    <div class="item download">
      <svg width="28" height="38" viewBox="0 0 28 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 3C1 1.89543 1.89543 1 3 1H25C26.1046 1 27 1.89543 27 3V35C27 36.1046 26.1046 37 25 37H3C1.89543 37 1 36.1046 1 35V3Z"  stroke-width="2"/>
        <path d="M7 32C7 31.4477 7.44772 31 8 31H19.9997C20.552 31 20.9997 31.4477 20.9997 32C20.9997 32.5523 20.552 33 19.9997 33H8C7.44772 33 7 32.5523 7 32Z" fill="#14C393"/>
        <path d="M10 12C10 10.8954 10.8954 10 12 10H16.0003C17.1049 10 18.0003 10.8954 18.0003 12V16C18.0003 17.1046 17.1049 18 16.0003 18H12C10.8954 18 10 17.1046 10 16V12Z"   stroke-width="2"/>
      </svg>
      <div class="download-code">
        <img :src="downloadImg" width="100%" alt="">
        <p v-html="$t('down_allQrcode')"></p>
      </div>
    </div>
    <div class="item" @click="toTop">
      <svg width="31" height="16" viewBox="0 0 31 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 14.5L13.3787 2.12132C14.5503 0.949747 16.4497 0.949748 17.6213 2.12132L30 14.5"   stroke-width="2"/>
      </svg>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'RightEnter',
  data () {
    return {
      show: true
    };
  },
  computed: {
    ...mapState({
      locale: state => state.common.locale,
      mainLocales: state => state.common.mainLocales
    }),
    downloadImg () {
      if (this.mainLocales.includes(this.locale)) {
        // return require(`../../pages/home/components/homeDown/imgs/img_android_down_${this.locale}.jpg`);
        return require(`../../pages/home/components/homeDown/imgs/img_android_down_${this.locale}_2.png`);
      } else {
        // return require(`../../pages/home/components/homeDown/imgs/img_android_down_en.jpg`);
        return require(`../../pages/home/components/homeDown/imgs/img_android_down_en_2.png`);
      }
    }
  },
  methods: {
    toTop () {
      document.body.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    },
    open (url) {
      window.open(url);
    },
    checkRoute (path) {
      const paths = ['spot', 'perpetual', 'simulation-perpetual', 'login', 'register', 'reset', 'eazyswap', 'trading-info'];
      this.show = !paths.includes(path.name);
    }
  },
  watch: {
    '$route': {
      handler (curVal, oldVal) {
        curVal && this.checkRoute(curVal);
      },
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>

.right-enter {
  display: none;
  position: fixed;
  right: 0;
  top: 50%;
  margin-top: -85px;
  height: fit-content;
  // display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: column;
  z-index: 20;
  .item {
    transition: all 0.3s ease-in-out;
    width: 60px;
    height: 60px;
    background: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #E8E8E8;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    cursor: pointer;
    margin-bottom: 23px;
    svg{
      transition: all 0.3s ease-in-out;
      stroke:  var(--COLOR-brand);
    }
    &:hover{
      background: var(--COLOR-brand);
      svg{
        stroke: white;
      }
    }
    &.download{
      position: relative;
      &:hover{
        .download-code{
          display: block;
        }
      }
      .download-code {
        display: none;
        position: absolute;
        left: -254px;
        top: 50%;
        width: 234px;
        padding: 20px;
        transform: translateY(-50%);
        box-shadow: 0px 5px 22px rgba(0, 0, 0, 0.05);
        background: #fff;
        border-radius: 4px;
        p {
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          color: #949494;
          opacity: 0.8;
        }
      }
    }

  }
}

</style>
