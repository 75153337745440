module.exports = {
  uniswapExchange: '/uniswap/exchange', // 兑换
  uniswapExchangeList: '/uniswap/exchange/list', // 兑换记录
  uniswapMortgage: '/uniswap/mortgage', // 抵押
  uniswapMortgageList: '/uniswap/mortgage/list', // 抵押记录
  uniswapPositionList: '/uniswap/position/list', // 持仓列表(正在抵押)
  uniswapCoinAdd: '/uniswap/coin/addCoin', // 添加新币种
  uniswapCoinGet: '/uniswap/coin/getCoin', // 添加新币种
  uniswapCoinAll: '/uniswap/coin/getAllCoin', // 根据合约地址获取币种
  uniswapContractAll: '/uniswap/coin/getAllContract', // 获取所有币对信息
  uniswapRedeem: '/uniswap/redeem', // 赎回
  uniswapAssetsAll: '/uniswap/assets/getAllUserAsset', // 获取所有币种资产
  uniswapAssetsAllSum: '/uniswap/assets/sumAssets', // 获取所有币种资产折合btc
  uniswapSumAssetsMap: '/uniswap/assets/sumAssetsMap', // 获取所有币种资产折合usdt
  // uniswapPool: /uniswap/pool/detail/{mainChainCode}/{tokenCode} // 资金池
  uniswapUserAssetBySymbol: '/uniswap/assets/getUserAssetBySymbol', // 获取特定币对资产
  uniswapUserAssetByCode: '/uniswap/assets/getUserAssetByCode', // 获取特定币种资产
  uniswapUserAssetRecord: '/uniswap/assets/pageAssetChangeRecord', // 获取资产流水,分页显示
  uniswapMarketPoolPrices: '/uniswap/market/getPoolPrices', // 获取所有币对行情
  uniswapMarketPoolTicker: '/uniswap/market/getPoolTicker', // 获取24H交易量
  uniswapMarketKline: '/uniswap/market/getKline', // 获取币对Kline
  uniswapMarketTradeRecord: '/uniswap/market/pageTradeRecord', // 获取交易记录
  btcPredictionGetGuess: '/uniswap/act/getGuess', // 获取用户竞猜
  btcPredictionPostGuess: '/uniswap/act/updateGuess', // 新增或修改用户竞猜
  newYearUpdateGuess: '/uniswap/act/newYearUpdateGuess', // 新增或修改用户竞猜
  isConformToActTheSpringFestivalRule: '/uniswap/act/isConformToActTheSpringFestivalRule', // 获取用户信息（春节）
  isJoinActTheSpringFestival: '/uniswap/act/isJoinActTheSpringFestival', // 是否可以参加春节活动
  isJoinActTheValentineDay: '/uniswap/act/isJoinActTheValentineDay', // 是否可以参加情人节活动
  // 圣诞活动
  uniswapActList: '/uniswap/act/getActInfo', // 获取活动列表
  uniswapBannerList: '/uniswap/banner/list1?sceneType=pc&size=1000', // 获取宣传列表
  uniswapHomwBannerList: '/uniswap/banner/list?sceneType=pc', // 获取首页banner列表
  uniswapActJoinSimpleAct: '/uniswap/act/joinSimpleAct', // 加入活动
  uniswapActJHasJoined: '/uniswap/act/hasJoined', // 加入活动
  maintenanceConfigList: '/uniswap/maintenanceConfig/ongoingList', // 进行中的维护列表
  maintenanceInfo: '/uniswap/maintenanceConfig/configDetail/'
};
