function event (name) {
  var evt = document.createEvent('Event');
  evt.initEvent(name, true, true);
  return evt;
}
function formatInput (val, precision) {
  if (!val) { // null,undefind
    val = '';
  }
  // 转成字符串
  if (String(val).includes('e')) { // 科学计数法
    val = Number(val).toFixed(Number(precision) + 2);
  } else {
    val = String(val);
  }
  // 先把非数字的都替换掉，除了数字和.
  val = val.replace(/[^\d.]/g, '');
  // 必须保证第一个为数字而不是.
  val = val.replace(/^\./g, '');
  // 保证只有出现一个.而没有多个.
  val = val.replace(/\.{2,}/g, '.');
  // 保证.只出现一次，而不能出现两次以上
  val = val.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
  // 只能输入小数点后几位
  if (Number(precision) === 0) {
    val = val.split('.')[0];
  } else {
    const reg = '^(-)*(\\d+)\\.(\\d{' + precision + '}).*$';
    val = val.replace(new RegExp(reg), '$1$2.$3');
  }
  return val;
};

export default {
  bind: function (el, binding, vnode, oldVnode) {
    if (el.tagName.toLowerCase() !== 'input') {
      throw new Error('v-precision requires 1 input, found ' + el.tagName);
    }
    const num = formatInput(el.value, binding.value);
    el.value = num;
    el.dispatchEvent(event('input'));
  },
  update: function (el, binding, vnode, oldVnode) {
    const num = formatInput(el.value, binding.value);
    el.value = num;
    el.dispatchEvent(event('input'));
  }
};
