<template>
  <ul
    class="pagination"
    :class="[size && `pagination-${size}`, align && `justify-content-${align}`]"
  >
    <li class="page-item prev-page" :class="{disabled: value === 1}">
      <a class="page-link" aria-label="Previous" @click="prevPage">
        <span aria-hidden="true">
          <svg class="ficon" aria-hidden="true">
            <use xlink:href="#bf-icon_level" />
          </svg>
        </span>
      </a>
    </li>
    <li
      v-for="item in range(minPage, maxPage)"
      :key="item"
      class="page-item"
      :class="{active: value === item}"
    >
      <a class="page-link" @click="changePage(item)">{{ item }}</a>
    </li>
    <li class="page-item next-page" :class="{disabled: value === totalPages}">
      <a class="page-link" aria-label="Next" @click="nextPage">
        <span aria-hidden="true">
          <svg class="ficon" aria-hidden="true">
            <use xlink:href="#bf-icon_level" />
          </svg>
        </span>
      </a>
    </li>
  </ul>
</template>
<script>
export default {
  name: 'BasePagination',
  props: {
    pageCount: {
      type: Number,
      default: 0,
      description: 'Pagination page count. This should be specified in combination with perPage'
    },
    perPage: {
      type: Number,
      default: 10,
      description: 'Pagination per page. Should be specified with total or pageCount'
    },
    total: {
      type: Number,
      default: 0,
      description: 'Can be specified instead of pageCount. The page count in this case will be total/perPage'
    },
    value: {
      type: Number,
      default: 1,
      description: 'Pagination value'
    },
    size: {
      type: String,
      default: '',
      description: 'Pagination size'
    },
    align: {
      type: String,
      default: '',
      description: 'Pagination alignment (e.g center|start|end)'
    }
  },
  data () {
    return {
      defaultPagesToDisplay: 5
    };
  },
  computed: {
    totalPages () {
      if (this.pageCount > 0) {
        return this.pageCount;
      }
      if (this.total > 0) {
        return Math.ceil(this.total / this.perPage);
      }
      return 1;
    },
    pagesToDisplay () {
      if (this.totalPages > 0 && this.totalPages < this.defaultPagesToDisplay) {
        return this.totalPages;
      }
      return this.defaultPagesToDisplay;
    },
    minPage () {
      if (this.value >= this.pagesToDisplay) {
        const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
        const newMaxPage = pagesToAdd + this.value;
        if (newMaxPage > this.totalPages) {
          return this.totalPages - this.pagesToDisplay + 1;
        }
        return this.value - pagesToAdd;
      } else {
        return 1;
      }
    },
    maxPage () {
      if (this.value >= this.pagesToDisplay) {
        const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
        const newMaxPage = pagesToAdd + this.value;
        if (newMaxPage < this.totalPages) {
          return newMaxPage;
        } else {
          return this.totalPages;
        }
      } else {
        return this.pagesToDisplay;
      }
    }
  },
  watch: {
    perPage () {
      this.$emit('input', 1);
    },
    total () {
      this.$emit('input', 1);
    }
  },
  methods: {
    range (min, max) {
      const arr = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },
    changePage (item) {
      this.$emit('input', item);
    },
    nextPage () {
      if (this.value < this.totalPages) {
        this.$emit('input', this.value + 1);
      }
    },
    prevPage () {
      if (this.value > 1) {
        this.$emit('input', this.value - 1);
      }
    }
  }
};
</script>

<style lang="scss" scoped>

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.375rem;
  height: 36px;
  &.justify-content-end {
    justify-content: flex-end;
  }
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #8898aa;
  background-color: #fff;
  border: 1px solid #dee2e6;

  &:hover {
    z-index: 2;
    color: #8898aa;
    text-decoration: none;
    background-color: #dee2e6;
    border-color: #dee2e6;
  }

  &:focus {
    z-index: 2;
    outline: 0;
    box-shadow: none;
  }
}

.page-item {
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
  }
  &.prev-page {
    .ficon {
      transform: rotateZ(180deg);
    }
  }
  .ficon {
    color: #8898aa;
    width: 12px;
    height: 12px;
  }
  &:first-child {
    .page-link {
      margin-left: 0;
      border-radius: 50%;
    }
  }
  &:last-child {
    .page-link {
      border-radius: 50%;
    }
  }

  &.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: var(--COLOR-brand);
    border-color: var(--COLOR-brand);
  }

  &.disabled .page-link {
    color: #8898aa;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
  }
}

.page-item {
  &.active .page-link {
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }

  .page-link,
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0 3px;
    border-radius: 50% !important;
    width: 36px;
    height: 36px;
    font-size: 0.875rem;
  }
}

// Size variations

.pagination-lg {
  .page-item {
    .page-link,
    span {
      width: 46px;
      height: 46px;
      line-height: 46px;
    }
  }
}

.pagination-sm {
  .page-item {
    .page-link,
    span {
      width: 30px;
      height: 30px;
      line-height: 30px;
    }
  }
}

</style>
