import { filterCommodity } from '~/utils/util';
export default {
  // 计算属性
  combineState (state) {
    return `${state.data1} -> ${state.data2}`;
  },
  commodityCode (state) { // 数字货币
    return state.symbol ? state.symbol.split('-')[2].toUpperCase() : '';
  },
  commodityName (state) { // 数字货币显示
    return state.symbol ? filterCommodity(state.symbol.split('-')[2].toUpperCase()) : '';
  },
  currencyCode (state) { // 法币
    return state.symbol ? state.symbol.split('-')[1].toUpperCase() : '';
  },
  curTicker (state) { // 当前交易对的最新ticker
    return state.tickers[state.symbol] || {};
  }
};
