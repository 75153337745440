export default {pt:{
  "BACK_UF019": "A conta do usuário foi congelada!",
  "CT102": "Negócio correspondente está fechado",
  "CT103": "O preço preenchido está fora do alcance de preço permitido",
  "CT104": "O usuário tem ativos disponíveis insuficientes",
  "CT105": "Pedido não existe",
  "CT106": "Montante do pedido excede o limite.",
  "CT107": "O usuário pede menos que a quantidade mínima de pedido",
  "CT108": "Usuário está congelado",
  "CT110": "Limite de compra máximo excedido",
  "CT801": "O usuário não está vinculado ao telefone",
  "CT804": "O usuário se vinculou ao telefone",
  "CT805": "O número de telefone já está em uso",
  "CT806": "Informação temporária de vinculação de telefone não existe",
  "CT807": "Verificação de código de verificação SMS excedeu o número de restrições",
  "CT809": "Erro de código de verificação móvel",
  "CT810": "Sem conjunto de certificação de segurança",
  "CT811": "Desvincular chave privada Google",
  "CT812": "Erro de código de verificação Google",
  "CT822": "Usuário já vinculou chave privada Google",
  "CT823": "não existe",
  "CT824": "Não pode ser modificado",
  "CT825": "Sem alteração",
  "CT826": "O usuário configurou a autenticação de segurança",
  "CT902": "não existe",
  "FC101": "Livropedido parado",
  "FC102": "conta está bloqueada",
  "FC103": "preço excede o limite",
  "FC104": "montante excede o limite",
  "FC105": "subFundo insuficiente",
  "FC106": "lojaUsuário insuficiente",
  "FC108": "falha ao cancelar pedido",
  "FC109": "montante é demasiado baixo",
  "FC999": "transferência proibida",
  "PB100000": "Ativo Errado",
  "PB100001": "Ativo insuficiente",
  "PB100002": "Pelo menos 100 para reservar",
  "PB100003": "Preço de reserva errado",
  "PB100004": "Ficha Errada",
  "PB100005": "Preço alterado; faça o pedido novamente",
  "PB100006": "Dinheiro de reserva errado",
  "PB110000": "Não é hora de compra",
  "RT101": "O formato do parâmetro está errado",
  "RT102": "Registro não existe",
  "RT103": "Erro de configuração de sistema",
  "RT104": "Ambiente do sistema está anormal",
  "RT301": "Erro de Servidor",
  "RT302": "Erro de Servidor",
  "RT303": "Erro de Servidor",
  "RT304": "Erro de Servidor",
  "RT398": "Erro de Servidor",
  "RT399": "Erro de Servidor",
  "RT999": "Exceção indefinida",
  "SP801": "Impossível alterar formulário",
  "SP802": "Adicione formulárioAplicação primeiro",
  "SP803": "Impossível editar Informação de config",
  "SP804": "Pague a taxa de depósito",
  "SP805": "Fundo insuficiente",
  "SP806": "Taxa foi paga",
  "SP807": "Informação Config não existe",
  "UF001": "Entrada de dados não pode estar vazia",
  "UF002": "Endereço de Retirada não pode exceder 5.",
  "UF003": "endereço já existe",
  "UF015": "O usuário foi congelado!",
  "UF016": "Endereçopegar não existe!",
  "UF017": "Endereçopegar não pertence ao usuário!",
  "UF020": "Excede o limite diário!",
  "UF021": "Atividades de fundos insuficientes!",
  "UF022": "Existe um registro de recarregamento como estado não confirmado!",
  "UF030": "falha ao adicionar o endereço; verifique a conexão à rede e volte a tentar.",
  "UF036": "Quantidade é mais pequena que o limite mínimo",
  "UF037": "O montante de retirada é inferior ao limite máximo",
  "UF101": "Erro de formato TXID; digite novamente",
  "UF102": "Já pediu 5 vezes em uma hora; tente novamente mais tarde",
  "UF108": "Deposite com o endereço de enviou",
  "UF301": "O preço mais recente alterou. Peça emprestado novamente.",
  "UF302": "Fundos Insuficientes",
  "UF303": "Montante não permitido",
  "UF304": "Montante de pedido emprestado alcançou o limite",
  "UF305": "Retorno não disponível agora",
  "UF307": "Limite de pedido emprestado alcançado",
  "UF308": "Participantes de evento alcançaram o número limite",
  "UF312": "Pedido emprestado e retorno não disponíveis devido a liquidação forçada",
  "UF313": "Montante de retorno é maior que o montante de retorno necessário",
  "US601": "Erro de formato de endereço de email",
  "US604": "O tamanho do arquivo excede o limite “n”!",
  "US605": "Formato do arquivo incorreto",
  "US607": "Fotografia de CartãoId está vazia",
  "US608": "Fotografia de passaporte está vazia",
  "US609": "Complete a verificação ID (KYC) primeiro",
  "US651": "A InfoAPI não pertence ao usuário",
  "US660": "Erro de código de verificação de imagem",
  "US701": "O email já está cadastrado!",
  "US702": "Código de verificação expirou",
  "US703": "Erro no captcha de email!",
  "US704": "Usuário não existe",
  "US705": "A sua conta está bloqueada. Contacte o serviço online.",
  "US706": "A sua conta está bloqueada.; tente novamente dentro de 1 hora.",
  "US707": "Você digitou o código de verificação errado 5 vezes; obtenha um código de verificação novo.",
  "US708": "O email não está cadastrado.",
  "US709": "Email ou senha inválido.",
  "US710": "O telefone já está cadastrado!",
  "US711": "smsCaptcha não existe!",
  "US712": "nome de usuário ou senha inválidos",
  "US724": "Freqüência sobrecarregada. Tente novamente mais tarde",
  "US725": "Falha na verificação de localização nova",
  "US726": "Freqüência sobrecarregada. Tente novamente mais tarde",
  "US727": "Freqüência sobrecarregada. Tente novamente mais tarde",
  "US732": "Você digitou 6 vezes a senha incorreta. Tente novamente daqui a cinco minutos.",
  "US750": "O usuário já vinculou email!",
  "US801": "O usuário não está vinculado ao telefone",
  "US802": "Tem 30 segundos para enviar SSM da próxima vez!",
  "US803": "Erro de Código SMS excede as vezes máximas. Tente 1 hora mais tarde",
  "US804": "O usuário já vinculou o telefone!",
  "US805": "O número de telefone já está sendo usado.",
  "US806": "InfoTempVincularTelefone não existe!",
  "US807": "Você digitou o código de verificação errado 5 vezes; obtenha um código de verificação novo.",
  "US809": "Digite o Código SMS correto.",
  "US810": "Autenticaçãosegurança não definida!",
  "US811": "ChavePrivadagoogle não vinculada!",
  "US812": "Digite a ficha 2FA Google correta.",
  "US813": "InfoTempModificarTelefoneAntigoTelefone não existe!",
  "US814": "InfoTempModificarTelefoneNovoTelefone não existe!",
  "US815": "Erro de Código SMS excede as vezes máximas. Tente 1 hora mais tarde.",
  "US816": "Digite o Código SMS correto.",
  "US817": "Digite o Código SMS antigo correto.",
  "US818": "Erro de Código SMS excede as vezes máximas. Tente 1 hora mais tarde.",
  "US819": "Digite o Código SMS correto.",
  "US820": "Digite o Código SMS novo correto.",
  "US821": "InfoTempDesvincularTelefone não existe!",
  "US822": "O usuário já vinculou a chavePrivadagoogle!",
  "US823": "[securityAuthenticationName] não existe!",
  "US824": "O [securityAuthenticationName] não pode alterar!",
  "US825": "O [securityAuthenticationName] não está alterado!",
  "US826": "O usuário definiu Autenticaçãosegurança!",
  "US829": "identificador é inválido!",
  "US830": "contagem chaveapi excede o limite",
  "US832": "Código de verificação não existe ou expirou",
  "US833": "Código de verificação inválido",
  "US834": "Usuário não tem email vinculado",
  "US857": "Erro de digitação de código de email mais de 5 vezes; volte a enviar o código",
  "US901": "O [notificationName] não existe!",
  "US902": "O estado de notificação não está alterado!",
  "UW205": "O seu ativo está congelado. Se tiver alguma questão, contacte o serviço online.",
  "UW208": "Falha ao confirmar email; verifique a rede e volte a tentar",
  "UW209": "Falha ao enviar email. Clique em voltar a enviar para enviar novamente",
  "UW210": "A confirmação de email expirou. Volte a enviar o seu pedido",
  "UW213": "Certifique-se que clicou no último link enviado para o seu email dentro de 1 hora e volte a enviar o seu pedido se o email exceder o tempo de 1 hora.",
  "UW236": "Segurança de desvinculação; verifique a segurança primeiro!",
  "WE101": "Usuário Sem Sessão Iniciada",
  "WE102": "Usuário não tem permissão",
  "WE103": "Freqüência de acesso do usuário demasiado alta",
  "WE104": "Freqüência de acesso do usuário demasiado alta",
  "WE204": "O usuário está congelado",
  "WE205": "O usuário está congelado",
  "WE206": "Excedeu o limite diário",
  "WE207": "Atividades não são saldo suficiente",
  "WE209": "Falha ao enviar email",
  "WE218": "O usuário não completou a autenticação",
  "WE219": "Você ainda não tem um nome real"
}}