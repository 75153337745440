<template>
  <div :class="status">
    <div class="vue-input-box">
      <slot>
        <label class="vue-label" :class="{'focus':focus||val!==''}">{{label}}</label><input class="vue-input input-effect" v-model="val" :type="type"  @focus="focus=true;status=''" :disabled="disabled" @blur="blurFn" @input="change">
      </slot>
    </div>
    <transition>
      <div v-if="focus&&!status" class="tip">{{this.placeholder}}</div>
      <div v-if="status==='error'" class="err-msg">{{errMsg}}</div>
    </transition>
  </div>
</template>

<script>
  import validata from './validate';
  export default {
    name: 'vueInput',
    inject: ['vueForm'],
    props: {
      label: String,
      type: {
        default: 'text'
      },
      value: {
        require: true
      },
      disabled: {
        default: false
      },
      prop: String,
      placeholder: String,
      rule: [Object, Array]
    },
    data () {
      return {
        focus: false,
        val: '',
        status: '',
        errMsg: ''
      };
    },
    computed: {
      rules () {
        if (this.rule) {
          return Object.prototype.toString.call(this.rule) === '[object Object]' ? [this.rule] : this.rule;
        } else {
          return (this.vueForm && this.vueForm.rules && this.vueForm.rules[this.prop]) || [];
        }
      }
    },
    created () {
      this.init();
    },
    methods: {
      init () {
        this.val = this.value;

        if (this.rules.length && this.vueForm) {
          this.vueForm.setValidateValue(this.prop, false);
          this.vueForm.$on('vueInput.validate', this.blurFn);
        }
      },
      change () {
        this.$emit('input', this.val);
      },
      blurFn () {
        this.focus = false;
        if (this.rules) {
          const _this = this;
          validata(this.val, this.rules, data => {
            if (data.result) {
              if (data.asyncResult && data.asyncResult.then) {
                return data.asyncResult.then((_data, asyncMessage) => {
                  _this.setStatus(_data, asyncMessage);
                });
              }
            }
            _this.setStatus(data.result, data.message);
          });
        }
      },
      setStatus (bool, message) {
        this.status = bool ? 'success' : 'error';
        bool || (this.errMsg = message);
        this.vueForm && this.vueForm.setValidateValue(this.prop, bool);
      }
    },
    beforeDestroy () {
      this.vueForm && this.vueForm.removeValidateValue(this.prop);
    },
    watch: {
      value (n) {
        this.val = n;
      }
    }
  };
</script>

<style lang="scss" scoped>

  .vue-input-wrap{

  }
  .vue-input-box{
    position:relative;
  }
  .vue-label{
    position:absolute;
    left:10px;
    top:6px;
    right: initial;
    font-size:14px;
    font-weight:400;
    color:var(--COLOR-aaa);
    line-height:22px;
    transform-origin: top left;
    white-space: nowrap;
    pointer-events: none;
    min-height: 8px;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    &.focus{
      transform: translateY(-18px) scale(0.75);
    }
  }
  .vue-input{
    line-height: 20px;
    padding: 8px 10px;
    max-width: 100%;
    min-width: 0px;
    width: 100%;
    outline: none;
  }
  .err-msg{
    font-size:12px;
    color:var(--COLOR-red)
  }
  .tip{
    font-size:12px;
    color:var(--COLOR-50)
  }

</style>
