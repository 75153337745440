import {
  handleRequest,
  request,
  setQuery
} from '../utils/fetch';
const apiConfig = require('../config/api.config');

// 获取所有交易对信息
export const getBusiTypeInfos = () => {
  return handleRequest(request.post(apiConfig.getBusiTypeInfos));
};
// 获取单个交易对信息
export const getBusiTypeInfo = (params) => {
  return handleRequest(request.post(apiConfig.getBusiTypeInfo + setQuery(params)));
};

// 获取单个交易对信息
export const getTickerInfoByBusitype = (params) => {
  return handleRequest(request.post(apiConfig.getTicker + setQuery(params)));
};

export const getDeepDataByBusiType = (params) => {
  return handleRequest(request.post(apiConfig.getDeepData, params));
};

// 收藏币币
export const doColletTicker = (params) => {
  return handleRequest(request.post(apiConfig.doCollectTicker + setQuery(params)));
};

// 获取用户在某个交易对中的委托中订单列表
export const unCompleteOrders = (params) => {
  return handleRequest(request.post(apiConfig.getUnCompleteOrders + setQuery(params)));
};

// 获取用户在某个交易对中的历史订单列表
export const completeOrders = (params) => {
  return handleRequest(request.post(apiConfig.getCompleteOrders + setQuery(params)));
};
// 获取币币订单列表
export const getBibiOrders = (params) => {
  return handleRequest(request.post(apiConfig.getCompleteOrdersForEndTime + setQuery(params)));
};

export const getOrdersBySearchCondition = (params) => {
  return handleRequest(request.post(apiConfig.getOrdersBySearchCondition + setQuery(params)));
};

export const getOldOrdersBySearchCondition = (params) => {
  return handleRequest(request.post(apiConfig.getOldOrdersBySearchCondition + setQuery(params)));
};

// 下买单
export const buyOrder = (params) => {
  return handleRequest(request.post(apiConfig.placeBuyOrder + setQuery(params)));
};

// 止盈止损单
export const placeStopLimitOrder = (params) => {
  return handleRequest(request.post(apiConfig.placeStopLimitOrder + setQuery(params)));
};

// 币币交易白名单
export const spotWhiteList = () => {
  return handleRequest(request.get(apiConfig.spotWhiteList));
};

// 下卖单
export const sellOrder = (params) => {
  return handleRequest(request.post(apiConfig.placeSellOrder + setQuery(params)));
};

export const cancelOrderByID = (params) => {
  return handleRequest(request.post(apiConfig.cancelOrder + setQuery(params)));
};

export const cancelStopLimitOrder = (params) => {
  return handleRequest(request.post(apiConfig.cancelStopLimitOrder + setQuery(params)));
};

export const cancelAllOrder = (params) => {
  return handleRequest(request.post(apiConfig.cancelAllOrder + setQuery(params)));
};

export const getOrderTradeInfo = (params) => {
  return handleRequest(request.post(apiConfig.getOrderTradeInfo + setQuery(params)));
};

// 获取websocket getGroupName
export function getWSGroupName (params) {
  return handleRequest(request.post(apiConfig.getGroupName + setQuery(params)));
};
// 获取预售倒计时
export function getContractListingTime (params) {
  return handleRequest(request.get(apiConfig.getContractListingTime + setQuery(params)));
};
// 获取国家ID
export const getCountryId = (params) => {
  return handleRequest(request.get(`/napi/getCountryId?ip=${params.ip}&v=${Date.now()}`));
};

// 获取首页交易对信息
export const getHomeTickers = (params) => {
  return handleRequest(request.post(apiConfig.getMarketTickers + setQuery(params)));
};

// 获取币币交易对树结构
export const getSymbolsJson = (params) => {
  return handleRequest(request.post(apiConfig.getSymbolsJson + setQuery(params)));
};
// 查询所有交易对ticker接口 (new)
export const searchTickers = (params) => {
  return handleRequest(request.post(apiConfig.searchTickers + setQuery(params)));
};
// 查询交易对ticker （主流币，创新区，涨幅榜）
export const searchTickersV2 = (params) => {
  return handleRequest(request.get(apiConfig.searchTickersV2 + setQuery(params)));
};
// 查询所有交易对ticker接口 (newV3)
export const searchTickersV3 = (params) => {
  return handleRequest(request.get(apiConfig.searchTickersV3 + setQuery(params)));
};
// 获取所有现货交易对的ticker信息
export const getAllTicker = (params) => {
  return handleRequest(request.post(apiConfig.getAllTicker + setQuery(params)));
};

// 获取汇率(&src=krw&target=usd)
export const getExchangeRateBySrc = (params) => {
  return handleRequest(request.post(apiConfig.getExchangeRateBySrc + setQuery(params)));
};

export const getUsdtUsdExchangeRate = () => {
  return handleRequest(request.get(apiConfig.getUsdtUsdExchangeRate));
};
// 获取加密货币对法币的汇率
export const getECurrencyExchangeRate = (params) => {
  return handleRequest(request.get(apiConfig.getECurrencyExchangeRate + setQuery(params)));
};

// 校验交易密码
export const checkTradePwd = (params) => {
  return handleRequest(request.post(apiConfig.checkTradePwd, params));
};
// 判断是否需要校验交易密码
export const validateTradePwd = (params) => {
  return handleRequest(request.post(apiConfig.validateTradePwd, params));
};
// 获取banner
export const getBannerList = (params) => {
  return handleRequest(request.post(apiConfig.getBanner + setQuery(params)));
};
// 获取首页展示交易对里列表
export const showIndexSymbols = (params) => {
  return handleRequest(request.post(apiConfig.showIndexSymbols + setQuery(params)));
};
// 获取认购交易对
export const getSubscribeSymbools = (params) => {
  return handleRequest(request.post(apiConfig.getSubscribeSymbools + setQuery(params)));
};
// 获取板块信息
export const getBlockCoinList = (params) => {
  return handleRequest(request.get(apiConfig.getBlockCoinList + setQuery(params)));
};
// 获取板块列表
export const getBlockList = (params) => {
  return handleRequest(request.get(apiConfig.getBlockList + setQuery(params)));
};
// 币币历史获取筛选币对数组
export const getUserBusiTypeInfos = (params) => {
  return handleRequest(request.get(apiConfig.getUserBusiTypeInfos, { params }));
};
// 判断是否是对应项目方用户
export const checkIsProjectUsers = (params) => {
  return handleRequest(request.post(apiConfig.checkIsProjectUsers + setQuery(params)));
};
// 项目方委托认购卖单
export const placeSubscribeSellOrder = (params) => {
  return handleRequest(request.post(apiConfig.placeSubscribeSellOrder + setQuery(params)));
};
// 用户委托认购买单
export const placeSubscribeBuyOrder = (params) => {
  return handleRequest(request.post(apiConfig.placeSubscribeBuyOrder + setQuery(params)));
};
// k线图
export const getKLinV1 = (busitype, ktype, size) => {
  return handleRequest(request.get(`${apiConfig.getKLinV1}?symbol=${busitype}&ktype=${ktype}&size=${size}`));
};
// 获取业务用户引导配置
export const GetUserGuide = (params) => {
  return handleRequest(request.get(apiConfig.GetUserGuideApi + setQuery(params)));
};
// 获取tokeninsight的货币等级
export const getCoinRate = (params) => {
  return handleRequest(request.get(apiConfig.getCoinRate, {params}));
};
// 设置业务用户引导配置已提醒
export const UserGuideSet = (params) => {
  return handleRequest(request.post(apiConfig.UserGuideSetApi + setQuery(params)));
};

// 永续订单：获取当月剩余导出次数
export const exportPerpetualNumber = (uid) => {
  return handleRequest(request.get(`${apiConfig.exportOrder.exportPerpetualNumber}/${uid}`));
};
// 永续订单：导出用户历史账单
export const exportPerpetualAction = (params) => {
  return handleRequest(request.get(apiConfig.exportOrder.exportPerpetualAction, { params }));
};
// 币币订单：获取当月剩余导出次数
export const exportSpotNumber = () => {
  return handleRequest(request.get(apiConfig.exportOrder.exportSpotNumber));
};
// 币币订单：导出用户历史账单
export const exportSpotAction = (params) => {
  return handleRequest(request.get(apiConfig.exportOrder.exportSpotAction, { params }));
};
// U合约订单：导出用户历史账单
export const exportUsdtPerpetualAction = (params) => {
  return handleRequest(request.get(apiConfig.exportOrder.exportUsdtPerpetualAction, { params }));
};
// U合约订单：获取当月剩余导出次数
export const exportUsdtPerpetualNumber = (uid) => {
  return handleRequest(request.get(`${apiConfig.exportOrder.exportUsdtPerpetualNumber}/${uid}`));
};
// 获取所有币种对USDT价格
export const getAllCoin = (params) => {
  return handleRequest(request.get(apiConfig.getAllCoin, { params }));
};