import * as types from '../../../types';
import store from 'store';
export default {
  [types.ISAPP] (state, status) {
    state.isApp = status;
  },
  [types.FROM_CLIENT] (state, status) {
    state.fromClient = status;
  },
  [types.LOCALE] (state, status) {
    state.locale = status;
  },
  [types.CURRENCY] (state, status) {
    state.currency = status;
  },
  [types.CURRENT_CURRENCY] (state, status) {
    state.currentCurrency = status;
  },
  [types.CURRENT_VAL] (state, status) {
    state.currentVal = status;
  },
  [types.USDT_PERPETUAL_SHOW] (state, status) {
    state.usdtPerpetualShow = status;
  },
  [types.REBATE_SHOW] (state, status) {
    state.rebateShow = status;
  },
  [types.CURRENT_UNIT] (state, status) {
    state.currentUnit = status;
  },
  [types.THEME] (state, status) {
    state.theme = status;
    // store.set('theme', status);
    // window.sensors.registerPage({
    //   view_mode: status === 'white' ? '日模式' : '夜模式'
    // });
  },
  [types.COLORS] (state, status) {
    state.colors = status;
  },
  [types.COUNTRY_ID] (state, status) {
    state.countryId = status;
  },
  [types.IS_TRADE_PAGE] (state, status) {
    state.isTradePage = status;
  },
  [types.IS_RELOAD] (state, status) {
    state.isReload = status;
  },
  [types.CURRENCY_CODE_SIGN] (state, status) {
    state.currencyCodeSign = status;
  },
  [types.INVITERID_TYPE] (state, status) {
    state.inviterIdType = status;
  },
  [types.VALUATION_CURRENCY] (state, status) {
    state.valuationCurrency = status;
  },
  [types.VALUATION_SYMBOL] (state, status) {
    state.valuationSymbol = status;
  },
  [types.FRATES] (state, status) {
    state.frates = status;
  },
  [types.RATES] (state, status) {
    state.rates = status;
  },
  [types.SET_ACADEMYS] (state, status) {
    state.academys = status;
  },
  [types.SET_ACADEMYS_COUNT] (state, status) {
    state.academys_count = status;
  },
  [types.SET_CATEGORIES] (state, status) {
    state.categories = status;
  },
  [types.SET_RECOMMENDS] (state, status) {
    state.recommends = status;
  },
  [types.SET_RELATED] (state, status) {
    state.related = status;
  },
  [types.MAINTENANCE_LIST] (state, status) {
    state.maintenanceList = status;
  },
  [types.MAINTENANCE_OBJ] (state, status) {
    state.currentMaintenance = status;
  },
  [types.TOP_STICKY] (state, status) {
    state.topSticky = status;
  }
};
