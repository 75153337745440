import actions from './actions/actions';
import getters from './getters/getters';
import mutations from './mutations/mutations';
import state from './state/state';

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
