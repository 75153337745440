export default {
  // 永续模拟接口
  // 合约资产
  /**
   *  params:
   *  {
   *    coinType 转账币种
   *    direction 转账方向0:当前->另一账户,1是反向
   *    number 划转数量
   *    otherAccount 另一账户
   *  }
   */
  accountTransfer: { // 资产划转
    url: '/account/transfer',
    method: 'post'
  },
  userContractAsset: { // 用户合约资产
    url: '/account/sumFound',
    method: 'post'
  },
  /**
   * params:
   *  symbol 合约代码
   */
  userContractInfo: { // 账户信息
    url: '/account/{symbol}'
  },
  /**
   * params:
   * symbol 合约代码
   */
  contractBaseInfo: { // 合约公共信息
    url: '/contract/commonInfo/{symbol}'
  },
  /**
   * params:
   * symbol 合约代码
   */
  contractDetailInfo: { // 合约信息明细
    url: '/contract/contractDetail/{symbol}'
  },
  /**
   * params:
   */
  contractAllInfo: { // 全部合约信息明细
    url: '/contract/listAll'
  },
  /**
   * 合约列表
   * params:
   */
  contractList: {
    url: '/contract/list'
  },
  /**
   * 获取用户历史委托列表
   * params:
   */
  historyContractOrders: {
    // url: '/order/history/{symbol}'
    url: '/history/order/{symbol}'
  },
  /**
   * params:
   */
  incompleteOrders: { // 获取用户当前委托列表
    url: '/order/list/{symbol}'
  },
  /**
   * params:
   */
  completeOrders: { // 获取用户已成交列表
    url: '/order/trade/{symbol}'
  },
  /**
   * params:
   */
  position: { // 用户持仓
    url: '/position/{symbol}'
  },
  /**
   * params:
   */
  conditionOrders: { // 用户条件单
    url: '/condition/list/{symbol}'
  },
  /**
   * params:
   */
  getPnl: { // 获取用户平仓盈亏
    url: '/trade/pnl/{symbol}'
  },
  /**
   * params:
   */
  getPnlDetail: { // 获取用户平仓盈亏详情
    url: '/trade/pnl/details/{symbol}'
  },

  /**
   * 限价下单
   * params:
   * {
   *   side 1，买 2，卖
   *   future 订单特色，负责订单时候使用，预留
   *   orderQty 下单数量
   *   price 下单价格
   *   symbol 币对
   *   type 1,limit 2,market
   * }
   */
  contractOrder: {
    url: '/order',
    method: 'post'
  },
  /**
   * 条件下单
   * params:
   * {
   *   side 1，买 2，卖
   *   direction 价格方向:1:大于或等于，2:小于或等于
   *   fixedPrice 基准价格
   *   priceType 基准价格类型：1:市场价格，2:标记价格，3:指数价格
   *   volume 下单数量
   *   price 下单价格
   *   triggerPrice 触发价格
   *   symbol 币对
   *   type 1 limit 2 market 4 limit_close 5 market_close
   *   source 订单来源：1web，2app，3api
   * }
   */
  conditionOrder: {
    url: '/condition/create',
    method: 'post'
  },
  /**
   * 撤单
   * params:
   * {
   *   orderId 订单ID
   *   source 订单来源：1web，2app，3api
   *   symbol 交易对
   * }
   */

  cancleContractOrder: {
    url: '/order',
    method: 'delete'
  },
  /**
   * 撤销全部订单
   * params:
   * {
   *   source 订单来源：1web，2app，3api
   *   symbol 交易对
   * }
   */
  cancelAllContractOrder: {
    url: '/order/all',
    method: 'delete'
  },
  /**
   * 条件单撤单
   * params:
   * {
   *   orderId 订单ID
   *   source 订单来源：1web，2app，3api
   *   symbol 交易对
   * }
   */

  cancleConditionContractOrder: {
    url: '/condition/delete',
    method: 'delete'
  },
  /**
   * 条件单撤销全部订单
   * params:
   * {
   *   source 订单来源：1web，2app，3api
   *   symbol 交易对
   * }
   */
  cancelAllConditionContractOrder: {
    url: '/condition/deleteAll',
    method: 'delete'
  },
  /**
   * 平仓
   * params:
   * {
   *   symbol
   *   price
   *   source
   * }
   */
  closePosition: {
    url: '/position',
    method: 'post'
  },
  /**
   * 修改永续订单
   * params:
   * {
   *   orderId 要修改的订单id
   *   orderQty 修改后的数量
   *   price 修改后价格
   *   source 订单来源：1web，2app，3api
   *   symbol 币对
   *   transactionPin 交易密码,可空
   * }
   */
  updatePerpetualOrder: {
    url: '/order/update',
    method: 'post'
  },
  /**
   *  活动信息
   */
  actInfo: {
    url: '/act/actInfo'
  },
  /**
   * 活动实时排名信息
   */
  actTopRank: {
    url: '/act/getThirdActRankList'
  },
  /**
   * 是否可以抽奖
   */
  canExtract: {
    url: '/act/canExtract'
  },
  /**
   * 统计活动交易量和参与人数
   */
  countTrade: {
    url: '/act/countTrade'
  },
  /**
   * 开始抽奖
   */
  extractUSDT: {
    url: '/act/extractUSDT'
  },
  /**
   * 参与反馈的获奖人,有多少查多少，应该不到200个
   */
  reward: {
    url: '/act/feedBack/reward'
  },
  /**
   * 个人排名查询
   */
  personalRank: {
    url: '/act/personalRank'
  },
  /**
   * 实盘活动个人排名查询
   */
  personRankList: {
    url: '/act/getThirdActRank'
  },
  /**
   * 中奖的人名
   */
  searchExtractReward: {
    url: '/act/searchExtractReward/{actName}'
  },
  /**
   * 活获得签到记录
   */
  searchSignRecord: {
    url: '/act/searchSignRecord'
  },
  /**
   * 签到
   */
  signReward: {
    url: '/act/signReward'
  },
  /**
   * 修改昵称
   */
  updateNick: {
    url: '/act/updateNick'
  },
  /**
   * 分享
   */
  share: {
    url: '/act/share'
  },
  /**
   * 资产划转
   * direction 转账方向1:主账户->当前,2是反向
   * number 划转数量
   * symbol 转账币种,默认为swap-usd-btc
   */
  transfer: {
    url: '/account/transfer'
  },
  // 分佣
  getChannelUser: {
    url: '/commission/getChannelUser'
  },
  /**
   * 日/月汇总统计列表
   */
  getStatisticsDay: {
    url: '/commission/getStatisticsDay'
  },
  /**
   *日/月汇总统计详情
   */
  getStatisticsDayDetail: {
    url: '/commission/getStatisticsDayDetail'
  },
  /**
   * 获取统计数据汇总
   */
  getUserStatistics: {
    url: '/commission/getUserStatistics'
  },
  /**
   * 邀请用户转换Uid
   * @param suffix 邀请链接后缀
   */
  getUid: {
    url: '/commission/getUid'
  },
  /**
   * 获取协议状态
   */
  perpetualGetAgreement: {
    url: '/account/getAgreement'
  },
  /**
   * 同意协议
   */
  perpetualAgreement: {
    url: '/account/agreement'
  },
  /**
   * 送1BTC
   */
  sendOneCoin: {
    url: '/account/sendOneCoin/{symbol}'
  },
  /**
   * 获取用户永续账单
   */
  getPerBill: {
    url: '/order/historicalBill/{symbol}'
  },
  /**
   * 代理邀请统计
   */
  inviteDetail: {
    url: '/commission/statistics/agent/invite'
  },
  /**
   * 是否空投邀请用户
   */
  isInvitePeople: {
    url: '/tempActivity/loginPopup'
  },
  /**
   * 当前合约币对是否空投需要弹框
   */
  isInviteSymbol: {
    url: '/tempActivity/popup/{symbol}'
  },
  /**
   * 空投用户点击确认
   */
  sureInvite: {
    url: '/tempActivity/confirm/{symbol}'
  },
  /**
   * 杠杆倍数计算保证金变化
   */
  leverageCalculate: {
    url: '/leverage/calculate/{symbol}'
  },
  /**
   * 获取用户当前杠杆倍数及最大委托单数
   */
  leverageFind: {
    url: '/leverage/find/{symbol}'
  },
  /**
   * 修改用户杠杆倍数
   */
  leverageUpdate: {
    url: '/leverage/update/{symbol}'
  },
  /**
   * 获取合约资金费用日志
   */
  collectFund: {
    url: '/contract/collectFund/{symbol}'
  },
  // 赠金活动和空投活动
  couponMessage: {
    url: '/coupon/message'
  },
  // 提交问卷
  questionSubmit: {
    url: '/question/submit',
    method: 'post'
  },
  // 获取问答问卷
  questionGetScene: {
    url: '/question/getScene'
  },
  // 提交法币订单
  mercuryoOrderAdd: {
    method: 'post',
    url: '/mercuryo/order/add'
  },
  // 获取法币订单列表
  fiatOrderList: {
    url: '/fiat/order/list'
  },
  // 获取卖币订单列表
  sellOrderList: {
    url: '/fiat/order/sell/list'
  },
  // 获取法币运营商信息
  fiatConfig: {
    url: '/fiat/config'
  },
  // 获取卖币法币运营商信息
  sellConfig: {
    url: '/fiat/sellConfig'
  },
  // paxful用户订单信息添加
  paxfulAdd: {
    url: '/fiat/paxful/add'
  },
  // paxful签名
  paxfulEncrypt: {
    url: '/fiat/paxful/encrypt'
  },
  // banxan内嵌地址
  getCheckoutUrl: {
    url: '/fiat/banxa/getCheckoutUrl',
    method: 'post'
  },
  // 永续大赛活动时间列表
  actConfig: {
    url: '/act/config'
  },
  // 永续大赛活动结果列表
  actRank: {
    url: '/act/rank'
  },
  // 永续大赛活动信息
  actInfos: {
    url: '/act/getAct'
  },
  // 永续大赛活动名人堂
  actAllRank: {
    url: '/act/getAllRank'
  },
  advCreateOrder: {
    url: '/fiat/order/createAdvcashOrder'
  },
  queryAdvcashOrder: {
    url: '/fiat/order/queryAdvcashOrder'
  },
  
};
