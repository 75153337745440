export default {vn:{
  "BACK_UF019": "Tài khoản người dùng đã bị đóng băng!",
  "CT102": "Không hỗ trợ",
  "CT103": "Giá này nằm ngoài phạm vi giá cho phép",
  "CT104": "Người dùng không đủ tài sản",
  "CT105": "Lệnh không tồn tại",
  "CT106": "Số lượng đặt vượt quá giới hạn",
  "CT107": "Người dùng đặt ít hơn số lượng tối thiểu",
  "CT108": "Người dùng bị đóng băng",
  "CT110": "Vượt quá giới hạn mua tối đa",
  "CT801": "Người dùng không bắt buộc phải dùng điện thoại",
  "CT804": "Người dùng buộc phải dùng điện thoại",
  "CT805": "Số điện thoại đã được sử dụng",
  "CT806": "Mã xác minh đã hết hạn",
  "CT807": "Mã xác minh SMS vượt quá số lượng",
  "CT809": "Lỗi mã xác minh số điện thoại",
  "CT810": "Không đảm bảo an toàn",
  "CT811": "Mở mã Google",
  "CT812": "Lỗi mã xác minh Google",
  "CT822": "Người dùng đã mở mã Google",
  "CT823": "không tồn tại",
  "CT824": "Không thể sửa đổi",
  "CT825": "Không đổi",
  "CT826": "Người dùng đã thiết lập xác thực bảo mật",
  "CT902": "không tồn tại",
  "FC101": "lệnh đã tạm dừng",
  "FC102": "tài khoản bị khoá",
  "FC103": "giá vượt quá giới hạn",
  "FC104": "số lượng vượt quá giới hạn",
  "FC105": "Không đủ số dư",
  "FC106": "Không đủ vị thế",
  "FC108": "hủy đơn hàng thất bại",
  "FC109": "số lượng quá ít",
  "FC999": "Cấm chuyển tiền",
  "PB100000": "Tài sản sai",
  "PB100001": "Tài sản không đủ",
  "PB100002": "Ít nhất 100 để đặt lệnh trước",
  "PB100003": "Sai giá đặt lệnh trước",
  "PB100004": "Mã thông báo sai",
  "PB100005": "Giá đã thay đổi, xin vui lòng đặt lệnh một lần nữa",
  "PB100006": "Sai tiền đặt lệnh trước",
  "PB110000": "Chưa đến thời điểm mở bán",
  "RT101": "Định dạng sai chữ số",
  "RT102": "Bản lưu trữ không tồn tại",
  "RT103": "Lỗi cấu hình hệ thống",
  "RT104": "Hệ thống phát hiện bất thường",
  "RT301": "Lỗi máy chủ",
  "RT302": "Lỗi máy chủ",
  "RT303": "Lỗi máy chủ",
  "RT304": "Lỗi máy chủ",
  "RT398": "Lỗi máy chủ",
  "RT399": "Lỗi máy chủ",
  "RT999": "Ngoại lệ không xác định",
  "SP801": "Không thể thay đổi hình thức",
  "SP802": "Vui lòng thêm applicationForm trước",
  "SP803": "Không thể chỉnh sửa thông tin cấu hình",
  "SP804": "Vui lòng trả phí đặt cọc",
  "SP805": "Quỹ không đủ",
  "SP806": "Phí đã trả",
  "SP807": "Thông tin cấu hình không tồn tại",
  "UF001": "Nhập dữ liệu không thể để trống",
  "UF002": "Địa chỉ rút tiền không thể vượt quá 5.",
  "UF003": "Địa chỉ đã thoát",
  "UF015": "Người dùng đã bị đóng băng!",
  "UF016": "PickAdress không tồn tại",
  "UF017": "PickAdress không thuộc về người dùng!",
  "UF020": "Vượt quá giới hạn hàng ngày",
  "UF021": "Hoạt động của các quỹ không đủ",
  "UF022": "Có một hồ sơ nạp tiền là một trạng thái chưa được xác nhận!",
  "UF030": "Không thể thêm địa chỉ, vui lòng kiểm tra kết nối mạng và thử lại.",
  "UF036": "Số lượng nhỏ hơn giới hạn tối thiểu",
  "UF037": "Số tiền rút nhỏ hơn giới hạn tối thiểu",
  "UF101": "Lỗi định dạng TXID, vui lòng nhập lại",
  "UF102": "Đã yêu cầu 5 lần trong một giờ, vui lòng thử lại sau",
  "UF108": "Vui lòng gửi tiền với địa chỉ bạn đã gửi",
  "UF301": "Giá mới nhất đã thay đổi. Vui lòng cập nhật lại.",
  "UF302": "Quỹ không đủ",
  "UF303": "Số tiền không được phép",
  "UF304": "Vay mượn số lượng có sẵn đã đạt đến giới hạn",
  "UF305": "Số tiền được trả lại hiện sẽ không có sẵn bây giờ",
  "UF307": "Đạt đến giới hạn vay của người dùng",
  "UF308": "Người tham gia sự kiện đã đạt đến số lượng giới hạn",
  "UF312": "Việc vay và trả lại không có sẵn vì lý do thanh lý bắt buộc",
  "UF313": "Số tiền trả lại lớn hơn số tiền trả lại được yêu cầu",
  "US601": "Lỗi định dạng địa chỉ email",
  "US604": "Kích thước tập tin vượt quá giới hạn \"n",
  "US605": "Định dạng tệp sai",
  "US607": "Ảnh IDCard trống",
  "US608": "Ảnh hộ chiếu trống",
  "US609": "Vui lòng hoàn tất xác minh ID (KYC) trước",
  "US651": "APIInfo không thuộc về người dùng",
  "US660": "Lỗi mã xác minh hình ảnh",
  "US701": "Email này đã được đăng ký!",
  "US702": "Mã xác minh đã hết hạn",
  "US703": "Email captcha lỗi!",
  "US704": "Người dùng không tồn tại",
  "US705": "Tài khoản của bạn bị khóa. Vui lòng liên hệ dịch vụ hỗ trợ trực tuyến.",
  "US706": "Tài khoản của bạn đã bị khóa, vui lòng thử lại 1 giờ sau.",
  "US707": "Bạn đã nhập mã xác minh lỗi 5 lần, nhận mã xác minh mới.",
  "US708": "Các email chưa được đăng ký.",
  "US709": "Email hoặc mật khẩu không hợp lệ.",
  "US710": "Số điện thoại này đã được đăng ký!",
  "US711": "SMS Captcha không tồn tại!",
  "US712": "Sai username hoặc password",
  "US724": "Tần suất quá tải, vui lòng thử lại sau",
  "US725": "Xác minh vị trí mới không thành công",
  "US726": "Tần suất quá tải, vui lòng thử lại sau",
  "US727": "Tần suất quá tải, vui lòng thử lại sau",
  "US732": "Bạn đã nhập mật khẩu không chính xác trong sáu lần, vui lòng thử lại sau năm phút",
  "US750": "Người dùng đã liên kết email!",
  "US801": "Người dùng không liên kết với số điện thoại",
  "US802": "Có 30 giây để có thể tiếp tục gửi SMS lần nữa!",
  "US803": "Lỗi mã SMS vượt quá thời gian tối đa. Vui lòng thử 1 giờ sau",
  "US804": "Người dùng đã liên kết số điện thoại!",
  "US805": "Số điện thoại đã được sử dụng.",
  "US806": "BindPhoneTempInfo không tồn tại!",
  "US807": "Bạn đã nhập mã xác minh lỗi 5 lần, nhận mã xác minh mới.",
  "US809": "Vui lòng nhập mã SMS chính xác.",
  "US810": "Không thiết lập bảo mật Authenticate!",
  "US811": "Không liên kết google PrivateKey!",
  "US812": "Vui lòng nhập Google 2FA chính xác.",
  "US813": "ModifyPhoneOldPhoneTempInfo không tồn tại!",
  "US814": "ModifyPhoneNewPhoneTempInfo không tồn tại!",
  "US815": "Lỗi mã SMS vượt quá thời gian tối đa. Vui lòng thử 1 giờ sau.",
  "US816": "Vui lòng nhập mã SMS chính xác.",
  "US817": "Vui lòng nhập mã SMS cũ chính xác.",
  "US818": "Lỗi mã SMS vượt quá thời gian tối đa. Vui lòng thử 1 giờ sau.",
  "US819": "Vui lòng nhập mã SMS chính xác.",
  "US820": "Vui lòng nhập mã SMS mới chính xác.",
  "US821": "UnBindPhoneTempInfo không tồn tại!",
  "US822": "Người dùng đã liên kết googlePrivateKey!",
  "US823": "[SecurityAuthenticationName] không tồn tại!",
  "US824": "[SecurityAuthenticationName] không thể thay đổi!",
  "US825": "[SecurityAuthenticationName] không thay đổi!",
  "US826": "Người dùng đã thiết lập bảo mật!",
  "US829": "Định danh không hợp lệ!",
  "US830": "Số lượng apikey vượt quá giới hạn",
  "US832": "Mã xác minh không tồn tại hoặc đã hết hạn",
  "US833": "Mã xác minh không hợp lệ",
  "US834": "Người dùng không liên kết email",
  "US857": "Lỗi nhập mã email hơn 5 lần, vui lòng gửi lại mã",
  "US901": "[Tên thông báo] không tồn tại!",
  "US902": "Trạng thái thông báo không thay đổi!",
  "UW205": "Chúng tôi rất tiếc khi thông báo tài sản của bạn đã bị đóng băng. Nếu bạn có bất kỳ câu hỏi nào, vui lòng liên hệ với dịch vụ hỗ trợ trực tuyến.",
  "UW208": "Xác nhận email không thành công ， vui lòng kiểm tra đường truyền mạng và thử lại",
  "UW209": "Email đã gửi không thành công, vui lòng nhấp vào gửi lại để gửi lại",
  "UW210": "Xác nhận email đã hết hạn, vui lòng gửi lại yêu cầu của bạn",
  "UW213": "Vui lòng đảm bảo rằng bạn đã nhấp vào liên kết mới nhất được gửi qua email trong vòng 1 giờ và gửi lại yêu cầu của bạn nếu email vượt quá thời gian chờ là 1 giờ.",
  "UW236": "Không liên kết bảo mật, vui lòng kiểm tra bảo mật trước",
  "WE101": "Người dùng không đăng nhập",
  "WE102": "Người dùng không được phép",
  "WE103": "IP truy cập không cho phép",
  "WE104": "Tần suất truy cập của người dùng quá cao",
  "WE204": "Tài khoản người dùng bị đóng băng",
  "WE205": "Tài khoản người dùng bị đóng băng",
  "WE206": "Vượt quá giới hạn hàng ngày",
  "WE207": "Không đủ số dư",
  "WE209": "E-mail gửi không thành công",
  "WE218": "Người dùng không hoàn thành xác thực",
  "WE219": "Bạn vẫn chưa có tên thật"
}}