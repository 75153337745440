<template>
  <form>
    <slot></slot>
  </form>
</template>

<script>
  export default {
    name: 'vueForm',
    provide () {
      return {
        vueForm: this
      };
    },
    props: {
      model: Object,
      rules: Object
    },
    data () {
      return {
        validateValue: {}
      };
    },
    methods: {
      validate (callback) {
        this.$emit('vueInput.validate');
        Object.keys(this.validateValue).every(key => this.validateValue[key]) && callback && callback();
      },
      setValidateValue (name, result) {
        this.validateValue[name] = result;
      },
      removeValidateValue (name) {
        if (this.validateValue.hasOwnProperty(name)) {
          delete this.validateValue[name];
        }
      }
    }
  };
</script>

<style scoped>



</style>
