import * as types from '../../../types';
export default {
  [types.DEFI_SYMBOL] (state, payload) {
    state.curSymbol = payload;
  },
  [types.DEFI_SYMBOLS_INFO] (state, payload) {
    state.symbolsInfo = payload;
  },
  [types.DEFI_POOLS] (state, payload) {
    state.pools = payload;
  },
  [types.DEFI_FKLINES] (state, payload) {
    if (payload.length > 20) {
      payload = payload.slice(payload.length - 20);
    }
    state.klines = payload;
  },
  [types.DEFI_KLINES] (state, payload) {
    if (state.klines.length < 20) {
      state.klines.push(payload);
    } else {
      state.klines.shift();
      state.klines.push(payload);
    }

    const _index = state.pools.findIndex(item => {
      return item.tokenCode === state.curSymbol.split('-')[2];
    });
    state.pools[_index].priceFloat = 1 / payload.price;
  }
};
