export default {cn:{
  "403": "操作失败，请联系客服",
  "500": "系统异常，请稍后再试",
  "5843": "该证件已被其他账户使用，请重新录入证件信息。",
  "10000": "访问过于频繁，请稍后访问。",
  "100001": "参数错误",
  "100002": "参数值异常",
  "100003": "请求header异常",
  "100004": "验证码过期",
  "100005": "验证码错误",
  "100006": "您已被禁止交易，如有疑问，请联系客服",
  "100007": "持仓被锁定",
  "100008": "下单数量不合法",
  "100009": "最大持仓数未配置",
  "100010": "委托失败! 超过最大持仓上限！",
  "100011": "超过最大下单价格限制！",
  "100012": "保证金不足！",
  "100013": "订单更新失败！",
  "100014": "订单不存在！",
  "100015": "无卖盘，无法下单！",
  "100016": "登录超时，请重新登录！",
  "100017": "无仓位，不能平仓！",
  "100018": "价格精度不对！",
  "100019": "无资产！",
  "100022": "价格不可为0！",
  "200007": "该币种暂停交易。",
  "200008": "合约不存在",
  "200009": "合约暂不支持该币种",
  "200010": "金额精度大于币种最大精度",
  "200011": "获取行情异常",
  "200012": "币对不存在",
  "200013": "币对不能为空",
  "200015": "余额不足",
  "200017": "划转失败！",
  "200018": "您未同意转账授权协议",
  "200024": "划转异常，请与客服联系。",
  "200025": "您转入的资金需大于{num}",
  "200026": "转出限制：需要完成至少一次的转入记录",
  "300004": "划转失败，环境异常",
  "300006": "划转失败，您的金额不足",
  "300007": "请设置安全认证",
  "1000025": "下单失败！平仓单已经存在",
  "1000026": "下单失败！存在更优价格的反向委托单",
  "ACT1000": "参数错误",
  "ACT1001": "投票未开始",
  "ACT1002": "投票已结束",
  "ACT1003": "小于最小投票金额",
  "ACT1004": "资产不足",
  "ACT1005": "重复投票",
  "BACK_UF019": "用户账号已被冻结",
  "CAPP001": "账户不存在",
  "CAPP0010": "该项目已下架，请选择其他项目",
  "CAPP0011": "密码错误",
  "CAPP0012": "授权超时，请重新返回操作",
  "CAPP002": "禁止转入",
  "CAPP003": "你的账户已被冻结，请联系客服",
  "CAPP004": "禁止转出",
  "CAPP005": "资产异常",
  "CAPP006": "不支持该币种交易",
  "CAPP007": "账户异常",
  "CAPP008": "订单不存在",
  "CAPP009": "支付超时，请重新返回操作",
  "CAPP010": "该项目已下架，请选择其他项目",
  "CAPP011": "密码错误",
  "CAPP012": "授权超时，请重新返回操作",
  "CAPP018": "禁止资金划转",
  "CAPP500": "系统异常",
  "CT102": "该交易尚未开启，交易时间请参考最新公告",
  "CT103": "所填价格超出允许价格范围",
  "CT104": "用户可用资产不足",
  "CT105": "订单不存在",
  "CT106": "下单量大于最大限制",
  "CT107": "下单量小于最小限制",
  "CT108": "用户账号已被冻结",
  "CT110": "超出最大购买量",
  "CT200": "您已被禁止币币交易，若有有疑问请联系客服",
  "CT801": "用户未绑定手机",
  "CT804": "用户已绑定手机",
  "CT805": "该手机号已经被使用",
  "CT806": "验证码已过期",
  "CT807": "短信验证码校验超出次数限制",
  "CT809": "手机验证码错误",
  "CT810": "未设置安全认证",
  "CT811": "未绑定Google私钥",
  "CT812": "Google验证码错误",
  "CT822": "用户已经绑定Google私钥",
  "CT823": "不存在",
  "CT824": "不能修改",
  "CT825": "没有改变",
  "CT826": "用户已经设置安全验证",
  "CT902": "不存在",
  "FC101": "当前合约暂停用户下单",
  "FC102": "用户账号被冻结",
  "FC103": "下单价格超出限制",
  "FC104": "下单量大于最大限制",
  "FC105": "用户资金不足",
  "FC106": "用户持仓量不足",
  "FC108": "用户撤单失败",
  "FC109": "下单量小于最小限制",
  "FC200": "您已被禁止CApp交易，若有有疑问请联系客服",
  "FC999": "禁止资金划转",
  "FI105": "当前订单已赎回",
  "FI109": "订单不存在",
  "FI110": "当前订单已赎回",
  "FT002": "账户余额不足",
  "LN1000": "增减资产流程失败",
  "LN1001": "参数错误",
  "LN1002": "借币数量错误",
  "LN1003": "用户不存在",
  "LN1004": "获取用户可用资产失败",
  "LN1005": "用户可用资产不足",
  "LN1006": "查询不到该订单",
  "LN1007": "冻结用户资产失败",
  "LN1008": "订单创建失败",
  "LN1009": "订单还款失败",
  "LN1010": "订单调整质押率失败",
  "LN1011": "解冻用户资产失败",
  "LN1012": "当前汇率已发生变动，请刷新頁面后再试",
  "LN1013": "保存调整质押率记录失败",
  "LN1014": "冻结用户资产失败，解冻系统资产失败",
  "LN1015": "扣减用户资产失败",
  "LN1016": "扣减系统资产失败",
  "LN1017": "增加用户资产失败",
  "LN1018": "增加系统资产失败",
  "LN1019": "冻结系统资产失败",
  "LN1020": "系统可用资产不足",
  "LN1021": "获取系统可用资产失败",
  "LN1022": "调整质押率数量错误",
  "LN1023": "借币币种或质押币种类型错误",
  "LN1024": "借币期限错误",
  "LN1025": "借币币种或质押币种错误",
  "LN1026": "订单已完成，无需继续还币",
  "LN1027": "借币币种发放失败",
  "LN1028": "该币种总可借数量不足",
  "LN1029": "增加币种总可借数量失败",
  "LN1030": "订单状态有变化不能进行还款操作",
  "LN1032": "资产总览计算失败",
  "LN1033": "网络拥堵，请稍后再试",
  "LN1034": "此币种暂无可抵押额度，请稍后再试。",
  "NFT100": "活动不存在",
  "NFT101": "活动未开始",
  "NFT102": "活动已结束",
  "NFT103": "此类型不允许直接购买",
  "NFT104": "此类型不允许买断",
  "NFT105": "买断金额错误",
  "NFT106": "已卖出",
  "NFT107": "不可重复上架",
  "NFT108": "NFT资产错误",
  "NFT109": "资产不足",
  "NFT110": "提现手续费不足",
  "NFT111": "当前NFT不可操作",
  "NFT112": "当前数据不可以取消提现",
  "NFT113": "提现数据异常",
  "NFT114": "上架参数错误",
  "NFT115": "出价金额错误不能小于当前最新出价金额",
  "NFT116": "价格不能为0",
  "NFT117": "拍品不存在",
  "NFT118": "交易中，无法撤销",
  "NFT120": "暂停提现",
  "NFT121": "暂停充值",
  "NFT122": "您的提现已被冻结，若有疑问请联系客服",
  "NFT123": "您已被禁止NFT交易，若有有疑问请联系客服",
  "OAUTH100001": "订单已超时，请重新下单",
  "OAUTH100002": "签名错误",
  "OAUTH100003": "认证错误",
  "OAUTH100004": "合约错误",
  "PB100000": "帐户资产错误",
  "PB100001": "帐户资产不足",
  "PB100002": "预购金额不能小于100",
  "PB100003": "错误的预购价格",
  "PB100004": "错误的币种类型",
  "PB100005": "价格有变动，请重新下单购买",
  "PB100006": "预购金额有误",
  "PB110000": "未到预购时间",
  "RT100": "验证码错误",
  "RT101": "参数格式有误",
  "RT102": "交易对不存在",
  "RT103": "系统配置错误",
  "RT104": "系统环境异常",
  "RT201": "收藏已达上限",
  "RT301": "服务器环境异常",
  "RT302": "服务器环境异常",
  "RT303": "服务器环境异常",
  "RT304": "服务器环境异常",
  "RT398": "服务器环境异常",
  "RT399": "服务器请求频率过高",
  "RT401": "不支持该划转类型",
  "RT999": "未定义异常",
  "SL303": "网络拥堵，请稍后再试",
  "SL304": "委托失败! 超过最大持仓上限！",
  "SP801": "已审核通过,不能修改上币申请信息",
  "SP802": "申请表不存在，请先填写上币申请",
  "SP803": "不能编辑参数配置信息",
  "SP804": "上币费未支付，请先支付上币费",
  "SP805": "可用资金不足",
  "SP806": "上币费已经支付",
  "SP807": "配置参数不存在",
  "TF001": "您已被禁止钱包账户划转，若有疑问请联系客服",
  "TF002": "您已被禁止币币账户划转，若有疑问请联系客服",
  "TF003": "您已被禁止永续账户划转，若有疑问请联系客服",
  "TF004": "您已被禁止 MT5 账户划转，若有疑问请联系客服",
  "TF005": "您已被禁止 CApp 账户划转，若有疑问请联系客服",
  "TS1001": "系统繁忙，请稍后再试",
  "TS1002": "系统繁忙，请稍后再试",
  "TS1003": "认购份额超限",
  "TS1004": "可用余额不足",
  "TS1005": "查询失败",
  "TS1006": "认购失败",
  "TS1007": "系统繁忙，请稍后再试",
  "TS1008": "系统繁忙，请稍后再试",
  "TS1009": "系统繁忙，请稍后再试",
  "TS1010": "系统繁忙，请稍后再试",
  "TS1011": "系统繁忙，请稍后再试",
  "TS1012": "系统繁忙，请稍后再试",
  "u100017": "资产账号不存在",
  "u100018": "资产余额不足",
  "u100019": "资产更新冻结错误",
  "u100020": "持仓不存在",
  "u100021": "持仓不足",
  "u100022": "币种不存在",
  "u100023": "冻结异常",
  "u100024": "交易对不存在",
  "u100025": "抵押比列不一致",
  "u100026": "抵押参数数量错误",
  "u100027": "兑换参数数量错误",
  "u100028": "资金池为空",
  "u100029": "资金池金额不足",
  "u100030": "赎回比列错误",
  "u100031": "您已被禁止Swap交易，若有有疑问请联系客服。",
  "u300006": "你的账户已被冻结，请联系客服",
  "u300007": "资产异常",
  "u300008": "系统繁忙",
  "u300009": "该币种已存在",
  "UF001": "数据不能为空",
  "UF002": "提币地址不可超过20个",
  "UF003": "充值地址已存在",
  "UF015": "用户被冻结",
  "UF016": "提现地址不存在",
  "UF017": "提现地址不属于本用户",
  "UF018": "解析提币地址失败",
  "UF019": "您的提现已被冻结，若有有疑问请联系客服",
  "UF020": "超过每日提现",
  "UF021": "用户资产不足",
  "UF022": "存在未确认充值记录",
  "UF023": "解析提币地址失败",
  "UF024": "无效的FIO地址",
  "UF030": "添加地址失败，请检查网络连接后重试",
  "UF034": "撤销失败",
  "UF036": "提现手续费低于最低限额",
  "UF037": "提现数量低于最低限额",
  "UF038": "此币种不支持提现到 BitForex 充值地址，请使用其他地址",
  "UF039": "维护中，暂不支持提币",
  "UF101": "TXID格式错误，请重新输入",
  "UF102": "本小时已提交5次，请稍后再试",
  "UF108": "请使用您提交的地址进行充值",
  "UF301": "最新价已变化，请重新借入。",
  "UF302": "余额不足",
  "UF303": "提交数量不合法",
  "UF304": "借入额度已达到限额",
  "UF305": "业务未开启",
  "UF307": "用户的借入数量达到限额",
  "UF308": "参与用户数达到限额",
  "UF312": "您已爆仓，无法借入和归还",
  "UF313": "归还数量必须小于可归还数量",
  "US1752": "手机号码已注销",
  "US1753": "邮箱地址已注销",
  "US1754": "实名认证信息已注销",
  "US1755": "账号已注销",
  "US601": "邮箱格式错误",
  "US602": "密码至少8-20位，包含大写、小写字母、数字，且无空格",
  "US604": "上传文件大小超过限制",
  "US605": "上传文件格式错误",
  "US607": "请上传身份证照片",
  "US608": "请上传护照照片",
  "US609": "请先完成身份认证",
  "US651": "此APIKey不属于操作用户",
  "US660": "图片验证码错误",
  "US661": "该证件已被其他账户使用，请重新录入证件信息。",
  "US665": "人机验证失败，请重试",
  "US667": "手机号不正确",
  "US668": "该证件已被其他账户使用，请重新录入证件信息。",
  "US669": "不支持的电子邮件域名",
  "US671": "totp失败次数过多，请稍后再试",
  "US701": "该邮箱已被注册",
  "US702": "验证码不存在或已过期",
  "US703": "邮箱验证码错误",
  "US704": "此帐号不存在",
  "US705": "您的账户已被锁定，请联系在线客服。",
  "US706": "您的账户已被锁定，请1小时后再试",
  "US707": "您已连续5次输入错误，请重新获取",
  "US708": "该电子邮件未注册",
  "US709": "邮箱或密码错误",
  "US710": "该手机号已被注册",
  "US711": "短信验证码不存在",
  "US712": "用户名或密码错误",
  "US715": "邀请人UID不存在",
  "US724": "用户异地认证过于频发，请稍后重试",
  "US725": "异地登陆认证失败",
  "US726": "用户异地认证过于频发，请稍后重试",
  "US727": "用户异地认证过于频发，请稍后重试",
  "US732": "您已连续6次密码输入错误，请五分钟后再试",
  "US733": "交易密码输入错误",
  "US750": "已成功绑定邮箱",
  "US752": "请绑定邮箱",
  "US753": "设置防钓鱼码临时验证信息不存在",
  "US754": "设置防钓鱼码安全验证类型不存在",
  "US756": "验证码不存在或已失效",
  "US801": "用户未绑定手机",
  "US802": "还有30秒发送下一条短信",
  "US803": "短信验证码错误次数超出限制，请1小时后再试。",
  "US804": "用户已经绑定手机",
  "US805": "该手机号已被绑定",
  "US806": "绑定手机的临时信息不存在",
  "US807": "您已连续5次输入错误，请重新获取",
  "US808": "验证码不存在或已失效",
  "US809": "请输入正确的短信验证码。",
  "US810": "未设置安全认证。",
  "US811": "未绑定Google私钥",
  "US812": "请输入正确的Google双重验证口令。",
  "US813": "换绑旧手机的临时信息不存在",
  "US814": "换绑新手机的临时信息不存在。",
  "US815": "短信验证码错误次数超出限制，请1小时后再试。",
  "US816": "请输入正确的短信验证码。",
  "US817": "请输入正确旧的短信验证码。",
  "US818": "短信验证码错误次数超出限制，请1小时后再试。",
  "US819": "请输入正确的短信验证码。",
  "US820": "请输入正确新的的短信验证码。",
  "US821": "解绑手机的临时信息不存在",
  "US822": "用户已经绑定Google私钥",
  "US823": "【安全验证】不存在",
  "US824": "【安全验证】不能修改",
  "US825": "【安全验证】没有改变",
  "US826": "用户已经绑定了安全认证",
  "US829": "验证失败，请重试。",
  "US830": "用户APIKey数量超出限制",
  "US832": "验证码不存在或已失效",
  "US833": "验证码不正确",
  "US834": "请绑定邮箱",
  "US835": "防钓鱼码只允许数字，字母，下划线",
  "US857": "邮件验证码超过最大错误次数，请重新获取邮件验证码",
  "US901": "【通知名称】不存在",
  "US902": "通知状态没有改变",
  "UW205": "对不起，您的账户资产已被冻结，如有疑问，请联系在线客服。",
  "UW208": "邮箱验证失败，请检查网络连接后重试",
  "UW209": "邮件发送失败，请点击“再次发送”进行重发",
  "UW210": "邮箱验证码已失效，请重新发送",
  "UW213": "链接已失效，请重新提交发送请求。",
  "UW236": "没有绑定安全认证，请先进行安全认证",
  "WE101": "用户未登录",
  "WE102": "用户无权限",
  "WE103": "用户IP不在允许范围内",
  "WE104": "用户访问频率过高",
  "WE204": "用户已经被冻结",
  "WE205": "用户账户被冻结",
  "WE206": "超出每日限额",
  "WE207": "资金不足",
  "WE209": "邮箱发送失败",
  "WE218": "用户未完成身份认证",
  "WE219": "您还没有实名认证"
}}