import { handleRequest, request } from '../utils/fetch';
const apiConfig = require('../config/api.config');

// 获取文章
export const getAcademys = (params) => {
  return handleRequest(request.get(apiConfig.getAcademys, { params }));
};

// 获取文章分类列表
export const getCategories = (params) => {
  return handleRequest(request.get(apiConfig.getCategories, { params }));
};

// 获取首页文章
export const getArticlesOfIndex = (params) => {
  return handleRequest(request.get(apiConfig.getArticlesOfIndex, { params }));
};
// 获取分类文章
export const getArticlesOfCategories = (params) => {
  return handleRequest(request.get(apiConfig.getArticlesOfCategories, { params }));
};
// 获取文章详情
export const getArticleDetail = (params) => {
  return handleRequest(request.get(apiConfig.getArticleDetail, { params }));
};
// 获取推荐文章列表 (底部)
export const getArticleRecommend = (params) => {
  return handleRequest(request.get(apiConfig.getArticleRecommend, { params }));
};
// 获取相关文章列表 (左侧)
export const getArticleRelated = (params) => {
  return handleRequest(request.get(apiConfig.getArticleRelated, { params }));
};
// 获取Meta
export const getMeta = (params) => {
  return handleRequest(request.get(apiConfig.getMeta, { params }));
};
// 获取DeFi
export const getDefizoneHots = (params) => {
  return handleRequest(request.get(apiConfig.getDefizoneHots, { params }));
};

// 获取banner
export const functionalBanner = (params) => {
  return handleRequest(request.get(apiConfig.functionalBanner, { params }));
};

// 查询分类下教程
export const getCourseList = (params) => {
  return handleRequest(request.get(apiConfig.getCourseList, { params }));
};

// 查询首页新手导航分类
export const getCourseListType = (params) => {
  return handleRequest(request.get(apiConfig.getCourseListType, { params }));
};
// 理财引流图片24h不再展示
export const adBannerClose = (params) => {
  return handleRequest(request.get(apiConfig.adBannerClose, { params }));
};
// 获取理财引流图片
export const adBannerInfo = (params) => {
  return handleRequest(request.get(apiConfig.adBannerInfo, { params }));
};
// 提交骑士信息
export const applyKnight = (params) => {
  return handleRequest(request.post(apiConfig.applyKnight, params));
};
