import * as types from '../../../types';
import * as userModel from '~/model/clientUserModel';
import * as financingModel from '~/model/clientFinancingModel';
import * as userAccessModel from '~/model/clientUserAccessModel';
import * as mt5Model from '~/model/clientMt5Model';
import * as cappsModel from '~/model/clientCappsModel';
import * as defiModel from '~/model/clientSwapModel';
import perpetualModel from '~/model/clientPerpetualModel';
import usdtModel from '~/model/clientUsdtModel';
import eventBus from '~/utils/eventBus';
import {
  filterCommodity
} from '~/utils/util';

const handleError = (err) => {
  // handle error
  if (err.code === 401) {
    eventBus.$emit('auth');
  }
};

export default {
  // 登录2
  async doLogin ({
    dispatch,
    commit
  }, params) {
    let res;
    await userModel.login2(params).then(data => {
      res = data;
      if (res.success && res.data.execute) {
        commit(types.IS_LOGIN, res.data.execute);
      }
    });
    return res;
  },
  // 退出登录
  async doLogout ({
    dispatch,
    commit
  }, params) {
    let res;
    await userModel.logout(params).then(data => {
      res = data;
      if (res.success) {
        commit(types.IS_LOGIN, false);
        commit(types.USERBASEINFO, {});
        commit(types.USERSAFEINFO, {});
        commit(types.USER_TOTAL_ASSET, '');
        commit(types.USERSAFEINFO, '');
        commit(types.MAIN_ASSETS_LIST, []);
      }
    });
    return res;
  },
  // 判断登录
  async checkLogin ({
    dispatch,
    commit
  }, params) {
    return userModel.checkLogin(params).then((res) => {
      commit(types.IS_LOGIN, res.data);
    }).catch(err => {
      handleError(err);
    });
  },
  // 一个处理用户认证的例子
  async getUserBaseInfo ({
    dispatch,
    commit
  }, params) {
    let ret;
    await userModel.getUserBaseInfo(params).then((res) => {
      ret = res;
      if (res.success) {
        commit(types.USERBASEINFO, res.data);
      }
    }).catch(err => {
      handleError(err);
    });
    return ret;
  },
  // 获取银行卡绑定信息
  getUserVerificationInfos ({
    dispatch,
    commit
  }, params) {
    let res;
    return userModel.getUserVerificationInfo(params).then((data) => {
      res = data;
      if (res.success) {
        commit(types.AUTHVERIFY, res.data);
      } else {
        commit(types.BANKBINDING, {
          verificationStatus: 0
        }); // 返回一个默认值
      }
    }).catch(err => {
      handleError(err);
    });
  },
  // 获取银行卡绑定信息
  getUserBandingRechargeCard ({
    dispatch,
    commit
  }, params) {
    // return model.getUserBandingRechargeCard(params).then((data) => {
    //   // balabala
    //   console.log('bank', data);
    //   if (data.success) {
    //     commit(types.BANKBINDING, data);
    //   } else {
    //     commit(types.BANKBINDING, { status: 0 }); // 返回一个默认值
    //   }
    // }).catch(err => {
    //   handleError(err);
    // });
  },

  // 获取用户当前交易对双币资产信息
  async getCurSymbolAssets ({
    dispatch,
    commit
  }, params) {
    let ret = await userModel.getCurSymbolAssets(params).then(res => {
      let curSymbolAssetsInfo = {
        commodity: {},
        currency: {}
      };
      curSymbolAssetsInfo.commodity = res[0].data;
      curSymbolAssetsInfo.currency = res[1].data;
      commit(types.CUR_SYMBOL_ASSETS, curSymbolAssetsInfo);
    }).catch(err => {
      handleError(err);
    });
    return ret;
  },
  // 获取用户当前交易对双币trade资产信息
  async getTradeAssetBySymbol ({
    dispatch,
    commit
  }, params) {
    let ret = await userModel.getTradeAssetBySymbol(params).then(res => {
      commit(types.CUR_SYMBOL_ASSETS, res.data);
    }).catch(err => {
      handleError(err);
    });
    return ret;
  },
  // 获取用户安全信息
  async getUserSafeInfo ({
    dispatch,
    commit,
    rootState
  }, params) {
    let ret;
    await userModel.getUserSafeInfos(params).then((res) => {
      ret = res;
      if (res.success === true) {
        commit(types.USERSAFEINFO, res.data);
        if (res.data.needBindGoogle) {
          eventBus.$emit('needBindGoogle');
        }
        // if (res.data.lang) {
        //   commit('common/LOCALE', res.data.lang, {
        //     root: true
        //   });
        // } else {
        //   dispatch('updateLang', {
        //     lang: rootState.common.locale
        //   });
        // }
      }
    }).catch(err => {
      handleError(err);
    });
    return ret;
  },

  // 获取用户安全信息
  async updateLang ({
    dispatch,
    commit
  }, params) {
    let ret;
    await userModel.updateLang(params).then((res) => {
      ret = res;
      if (res.success === true) {
        commit('common/LOCALE', params.lang, {
          root: true
        });
      }
    }).catch(err => {
      handleError(err);
    });
    return ret;
  },

  // 获取用户所有币种的main资产信息
  async getUserMainAssetsList ({
    dispatch,
    commit
  }, params) {
    let ret;
    await userModel.getUserMainAssetsList(params).then((res) => {
      ret = res;
      if (res.success === true) {
        res.data.forEach(item => {
          item.showName = filterCommodity(item.coinCode.toUpperCase());
        });
        commit(types.MAIN_ASSETS_LIST, res.data);
      }
    }).catch(err => {
      handleError(err);
    });
    return ret;
  },
  // 获取用户所有币种的trade资产信息
  async getUserTradeAssetsList ({
    dispatch,
    commit
  }, params) {
    let ret;
    await userModel.getUserTradeAssetsList(params).then((res) => {
      ret = res;
      if (res.success === true) {
        res.data.forEach(item => {
          item.showName = filterCommodity(item.coinCode.toUpperCase());
        });
        commit(types.TRADE_ASSETS_LIST, res.data);
      }
    }).catch(err => {
      handleError(err);
    });
    return ret;
  },
  // 获取用户U本位资产信息
  async getUserUsdtAssetsList ({
    state,
    commit
  }, params) {
    let ret;
    await usdtModel.assetSumInfo().then((res) => {
      ret = res;
      let all = state.totalAsset;
      // u本位 钱包余额(USDT)
      all.usdt_totalAmount = res.totalAmountSum || 0;
      // u本位 未实现盈亏
      all.usdt_unrealisedPNL = res.unrealisedPNLSum || 0;
      // u本位 账户余额
      all.usdt_equitySum = res.equitySum || 0;
      // u本位 可提现金额
      all.usdt_canWithDrawBalance = res.canWithDrawBalanceSum || 0;
      commit(types.USER_TOTAL_ASSET, all);
      commit(types.USDT_ASSETS_LIST, res.assetDTO || []);
    }).catch(err => {
      handleError(err);
    });
    return ret;
  },
  // 获取用户所有币种的perpetual资产信息
  async getUserPerpetualAssetsList ({
    dispatch,
    commit
  }, params) {
    let ret;
    await perpetualModel.userContractAsset(null, false).then((res) => {
      ret = res;
      let _perpetualAssets = [];
      for (let k in res.accounts) {
        _perpetualAssets.push(Object.assign({}, res.accounts[k], {
          coin: k.split('-')[2]
        }));
      }
      commit(types.PERPETUAL_ASSETS_LIST, _perpetualAssets);
    }).catch(err => {
      handleError(err);
    });
    return ret;
  },
  // 获取用户所有币种的mt5资产信息
  async getUserMt5AssetsList ({
    dispatch,
    commit
  }, params) {
    let ret;
    await mt5Model.mt5SumAssets(params).then((res) => {
      ret = res;
      if (res.code === '200') {
        commit(types.MT5_ASSETS_LIST, res.data.assetsInfos || []);
      }
    }).catch(err => {
      handleError(err);
    });
    return ret;
  },
  // 获取用户所有币种的capps资产信息
  async getUserCappsAssetsList ({
    dispatch,
    commit
  }, params) {
    let ret;
    await cappsModel.getCappsAllUserAsset(params).then((res) => {
      ret = res;
      if (res.success === true) {
        res.data.forEach(item => {
          item.showName = filterCommodity(item.coinCode.toUpperCase());
        });
        commit(types.CAPPS_ASSETS_LIST, res.data || []);
      }
    }).catch(err => {
      handleError(err);
    });
    return ret;
  },
  // 获取用户所有币种的capps资产信息
  async getUserDefiAssetsList ({
    dispatch,
    commit
  }, params) {
    let ret;
    await defiModel.uniswapAssetsAll(params).then((res) => {
      ret = res;
      if (res.success === true) {
        res.data.forEach(item => {
          if (item.coinCode === 'eth') {
            item.showName = item.coinCode.toUpperCase();
          } else {
            item.showName = filterCommodity(item.coinCode.toUpperCase());
          }
        });
        commit(types.DEFI_ASSETS_LIST, res.data || []);
      }
    }).catch(err => {
      handleError(err);
    });
    return ret;
  },
  // 获取用户所有账户总资产
  async getUserTotalAsset ({
    dispatch,
    commit
  }, params) {
    let all = {
      total: 0,
      main_total: 0,
      trade_total: 0,
      perpetual_total: 0,
      financing_total: 0
    };
    // 币合约资产
    const userContractAsset = new Promise(resolve => {
      perpetualModel.userContractAsset(null, false).then((res) => {
        resolve(res);
      }).catch(() => {
        resolve('');
      });
    });
    // u合约资产
    const assetSumInfo = new Promise(resolve => {
      usdtModel.assetSumInfo().then((res) => {
        resolve(res);
      }).catch(() => {
        resolve('');
      });
    });
    try {
      Promise.all([
        userModel.getUserMainTotalAssets(),
        userModel.getUserTradeTotalAssets(),
        userContractAsset,
        // mt5Model.mt5SumAssets(),
        cappsModel.cappsSumAssets(),
        defiModel.uniswapAssetsAllSum(),
        financingModel.getFinancingAsset(),
        defiModel.uniswapSumAssetsMap(),
        assetSumInfo
      ]).then(res => {
        all.main_total = res[0].success ? res[0].data.btc * 1 : 0;
        all.main_usdt = res[0].success ? res[0].data.usdt * 1 : 0;
        all.trade_total = res[1].success ? res[1].data.btc * 1 : 0;
        all.trade_usdt = res[1].success ? res[1].data.usdt * 1 : 0;
        all.perpetual_total = res[2].equitySum * 1 || 0;
        all.perpetual_usdt = res[2].equitySumUsdt * 1 || 0;
        // all.mt5_total = res[3].data ? res[3].data.sumAssets * 1 : 0;
        all.capps_total = res[3].success ? res[3].data.btc * 1 : 0;
        all.capps_usdt = res[3].success ? res[3].data.usdt * 1 : 0;
        all.defi_total = res[4].success ? res[4].data * 1 : 0;
        all.defi_usdt = res[6].success ? res[6].data.usdt * 1 : 0;
        all.financing_total = res[5].success ? res[5].data.btcAmount * 1 : 0;
        all.financing_usdt = res[5].success ? res[5].data.usdAmount * 1 + res[5].data.issuedUsdAmount * 1 : 0;
        // u本位 钱包余额
        all.usdt_totalAmount = res[7].totalAmountSum || 0;
        // u本位 账户余额(BTC)
        all.usdt_equitySumBtc = res[7].equitySumBtc || 0;
        // u本位 未实现盈亏
        all.usdt_unrealisedPNL = res[7].unrealisedPNLSum || 0;
        // u本位 账户余额(USDT)
        all.usdt_equitySum = res[7].equitySum || 0;
        // u本位 可提现金额
        all.usdt_canWithDrawBalance = res[7].canWithDrawBalanceSum || 0;
        // 永续总资产
        all.perpetual_total_all = all.perpetual_total + all.usdt_equitySumBtc * 1;
        all.perpetual_usdt_all = all.perpetual_usdt + all.usdt_equitySum * 1;
        all.total = all.main_total + all.trade_total + all.perpetual_total_all + all.capps_total + all.defi_total + all.financing_total;
        all.usdt = all.main_usdt + all.trade_usdt + all.perpetual_usdt_all + all.capps_usdt + all.defi_usdt + all.financing_usdt;
        commit(types.USER_TOTAL_ASSET, all);
        commit(types.USDT_ASSETS_LIST, res[7].assetDTO || []);
      }).catch(err => {
        commit(types.USER_TOTAL_ASSET, all);
        handleError(err);
      });
    } catch (err) {
      commit(types.USER_TOTAL_ASSET, all);
      handleError(err);
    }
  },
  // 获取系统所有数字货币币种信息
  async getDigitalCoinInfos ({
    dispatch,
    commit
  }, params) {
    let ret;
    await userAccessModel.getDigitalCoinInfos(params).then((res) => {
      ret = res;
      if (res.success === true) {
        commit(types.COINS_INFO, res.data);
      }
    }).catch(err => {
      handleError(err);
    });
    return ret;
  },
  // 获取安全设置列表
  async getUserAuthenticationSecuritySet ({
    dispatch,
    commit
  }, params) {
    let ret;
    await userModel.getUserAuthenticationSecuritySetState(params).then((res) => {
      ret = res;
      if (res.success === true) {
        commit(types.USER_SECURITY_SETTINGS, res.data);
      }
    }).catch(err => {
      handleError(err);
    });
    return ret;
  },
  // 首次获取交易对兑法币汇率
  async getAllFunConifigInfo ({dispatch, commit}, params) {
    let ret;
    await userModel.getAllFunConifigInfo().then(res => {
      ret = res;
      if (res.success) {
        commit(types.FUN_CONFIG, res.data);
      }
    }).catch(err => {
      handleError(err);
    });
    return ret;
  },
  // 获取阶梯手续费vip等级
  async getVipLevel ({
    dispatch,
    commit
  }, params) {
    // let ret;
    // await userAccessModel.getVipLevel(params).then((res) => {
    //   ret = res;
    //   if (res.success === true) {
    //     const __data =  res.data
    //     commit(types.VIPLEVELS, {
    //       id: __data.id,
    //       level: __data.level,
    //       name:  __data.name,
    //     });
    //   }
    // }).catch(err => {
    //   handleError(err);
    // });
    // return ret;
    return {}
  },
  // 是否显示阶梯手续费弹窗
  async showVipLevel ({
    dispatch,
    commit
  }, params) {
    // let ret;
    // await userAccessModel.showVipLevel(params).then((res) => {
    //   ret = res;
    // }).catch(err => {
    //   handleError(err);
    // });
    // return ret;
    return {}
  },
};
