import { handleRequest, request, setQuery } from '../utils/fetch';

// 最新公告
export const getListAnnouncement = (params) => {
  let uri = '';
  for (let key in params) {
    uri += '&' + key + '=' + params[key];
  }
  return handleRequest(request.get('/napi/annnouncess?sort_by=edited_at&sort_order=asc&limit=5' + uri));
};

// 获取汇率 （旧）
export const getAllRate = () => {
  return handleRequest(request.get(`/napi/getRate?v=${Date.now()}`));
};
// 获取汇率 （新）
export const getRates = () => {
  return handleRequest(request.get(`/napi/getRate2?v=${Date.now()}`));
};
// 获取法币汇率 （新）
export const getFrates = () => {
  return handleRequest(request.get(`/napi/getFrates?v=${Date.now()}`));
};
// 获取M类型最大值最小值
export const getM = params => {
  return handleRequest(request.get(`api.mercuryo.io/v1.6/public/currency-limits?` + setQuery(params)));
};
// 获取手续费
export const getMercuryoFee = params => {
  return handleRequest(request.get(`/contract/swap/fiat/mercuryo/getMercuryoFee?` + setQuery(params)));
};
// 获取在线翻译数据
export const getOnlineLang = params => {
  return handleRequest(request.get(`/napi/online?` + setQuery(params)));
};
// 获取Mercuryo汇率数据
export const getMercuryoRate = params => {
  return handleRequest(request.get(`/napi/getMercuryoRate`));
};
// 获取在线翻译数据
export const getPaxfulRate = params => {
  return handleRequest(request.get(`/napi/getPaxfulRate`));
};
// 获取RSS
export const getRSS = params => {
  return handleRequest(request.get(`/napi/getRSS`));
};
