import api from '~/model/clientContractModel.js';
import mkapi from '~/model/clientMkapiContractModel.js';

const dateFomat = (key, data, method, index) => {
  return {
    key,
    data,
    method,
    index
  };
};
export default {
  // 获取永续合约对列表
  async getContractList ({
    state,
    commit
  }) {
    return api.contractList().then(res => {
      commit('commonSet', dateFomat('contractList', res));
      return res;
    });
  },
  // 获取永续合约全部币对配置信息
  async getContractAllInfo ({
    state,
    commit
  }) {
    return api.contractAllInfo().then(res => {
      commit('commonSet', dateFomat('contractList', res));
      return res;
    });
  },
  // 获取永续合约全部币对ticker
  async getContractAllTickers ({
    state,
    commit
  }) {
    return mkapi.contractAllTickers().then(res => {
      commit('commonSet', dateFomat('contract_all_tickers', res));
      commit('commonSet', dateFomat('contract_tickers', res[state.symbol] || {}));
      return res;
    });
  },
  // 获取用户永续账户信息
  async getUserContractInfo ({
    state,
    commit
  }, params) {
    await api.userContractInfo(params).then(res => {
      commit('commonSet', dateFomat('accountInfo', res, 'update'));
    });
  },
  // 获取合约公共信息
  async getContractBaseInfo ({
    state,
    commit
  }, params) {
    let res = await api.contractBaseInfo(params);
    commit('commonSet', dateFomat('contractBaseInfo', res));
  },
  // 获取合约详细信息
  async getContractDetailInfo ({
    state,
    commit
  }, params) {
    let res = await api.contractDetailInfo(params);
    commit('commonSet', dateFomat('contractInfo', res));
    return res;
  },
  // 获取当前合约币对杠杆信息
  async getLeverageInfo ({
    state,
    commit
  }, params) {
    let res = await api.leverageFind(params);
    commit('commonSet', dateFomat('leverageInfo', res));
    return res;
  },
  // 修改杠杆倍数
  async leverageUpdate ({
    state,
    commit
  }, params) {
    let res = await api.leverageUpdate(params);
    commit('commonSet', dateFomat('leverageInfo', res));
    return res;
  },
  // 获取仓位信息
  async getPosition ({
    state,
    commit
  }) {
    commit('commonSet', dateFomat('positionInfo', {}));
    let res = await api.position({
      symbol: state.symbol
    }, false);
    commit('commonSet', dateFomat('positionInfo', res || {}));
    return res;
  },
  // 修改当前订单
  async updatePerpetualOrder ({
    state,
    commit
  }, params) {
    return api.updatePerpetualOrder(params);
  },
  // 获取当前委托列表
  async getIncompleteOrders ({
    state,
    commit
  }, params) {
    commit('commonSet', dateFomat('incompleteOrders', []));
    return api.incompleteOrders(params).then(res => {
      commit('commonSet', dateFomat('incompleteOrders', res.pageData));
      commit('commonSet', dateFomat('accountInfo', {
        buyCount: res.buyCount,
        sellCount: res.sellCount
      }, 'update'));
      return res;
    });
  },
  // 获取已成交委托列表
  async getCompleteOrders ({
    state,
    commit
  }, params) {
    commit('commonSet', dateFomat('completeOrders', []));
    return api.completeOrders(params).then(res => {
      commit('commonSet', dateFomat('completeOrders', res.pageData));
      return res;
    });
  },
  // 获取当前委托列表
  async getHistoryContractOrders ({
    state,
    commit
  }, params) {
    commit('commonSet', dateFomat('historyContractOrders', []));
    return api.historyContractOrders(params).then(res => {
      commit('commonSet', dateFomat('historyContractOrders', res.pageData));
      return res;
    });
  },
  // 获取条件委托列表
  async getConditionContractOrders ({
    state,
    commit
  }, params) {
    commit('commonSet', dateFomat('conditionContractOrders', []));
    return api.conditionOrders(params).then(res => {
      commit('commonSet', dateFomat('conditionContractOrders', res.pageData));
      return res;
    });
  },
  // 获取平仓盈亏列表
  async getPlaceList ({
    state,
    commit
  }, params) {
    commit('commonSet', dateFomat('placeList', []));
    return api.getPnl(params).then(res => {
      commit('commonSet', dateFomat('placeList', res.pageData));
      return res;
    });
  },
  // 下单
  async addContractOrder ({
    state,
    commit
  }, params) {
    return api.contractOrder(params);
    // commit('commonSet', dateFomat('incompleteOrders', res, 'add'));
  },
  // 下条件委托单
  async addConditionOrder ({
    state,
    commit
  }, params) {
    return api.conditionOrder(params);
  },
  // 撤单
  async cancelOrder ({
    state,
    commit
  }, params) {
    const {
      index
    } = params;
    delete params['index'];
    return api.cancleContractOrder(params).then(res => {
      commit('commonSet', dateFomat('incompleteOrders', res, 'del', index));
    });
  },
  // 全部撤单
  async cancelAllOrder ({
    state,
    commit
  }, params) {
    return api.cancelAllContractOrder(params).then(res => {
      commit('commonSet', dateFomat('incompleteOrders', []));
    });
  },
  // 条件单撤单
  async cancelConditionOrder ({
    state,
    commit
  }, params) {
    const {
      index
    } = params;
    delete params['index'];
    return api.cancleConditionContractOrder(params).then(res => {
      commit('commonSet', dateFomat('conditionContractOrders', res, 'del', index));
    });
  },
  // 条件单全部撤单
  async cancelAllConditionOrder ({
    state,
    commit
  }, params) {
    return api.cancelAllConditionContractOrder(params).then(res => {
      commit('commonSet', dateFomat('conditionContractOrders', []));
    });
  },
  // 平仓
  async closePosition ({
    state,
    commit
  }, params) {
    return api.closePosition(params);
    // commit('commonSet', dateFomat('incompleteOrders', []));
  }
};
