<template>
  <bf-dialog :visible="show" :showClose='false' class="pto-banner">
    <i class="dia-close" @click="show=false"><img src="./imgs/close_en.png"> </i>
    <div class="pto-con">
      <p class="title">{{$t('pto_1')}}</p>
      <p class="date">{{$t('pto_2')}}</p>
      <div class="pto-btn" v-if="isLogin" @click="show=false">{{$t('missions2_75')}}</div>
      <router-link v-else class="pto-btn" :to="{name:'login'}" @click="show=false">{{$t('mt5event_2')}}</router-link>
    </div>
  </bf-dialog>
</template>
<script>
// PTO认购弹窗
import { mapState } from 'vuex';
export default {
  data () {
    return {
      isTargetPage: false,
      show: false,
      timePeriod: [1590336000000, 1590404400000] // PTO认购时间：2020/05/25 00:00-2020/05/25 19:00
    };
  },
  mounted () {
  },
  computed: {
    ...mapState({
      isLogin: state => state.user.isLogin
    })
  },
  methods: {
    async isShow () {
      const now = Date.now();
      if (now >= this.timePeriod[0] && now <= this.timePeriod[1] && !window.localStorage.getItem('ptoETHBN')) {
        this.show = true;
        window.localStorage.setItem('ptoETHBN', 1);
      }
    }
  },
  watch: {
    $route: {
      handler (curVal, oldVal) {
        const isTargetPage = ['perpetual', 'spot'].includes(curVal.name);
        if (isTargetPage) {
          this.isShow();
        } else {
          this.show = false;
        }
      },
      immediate: true
    }
  }
};
</script>
<style lang="scss" scoped>

.pto-banner /deep/ {
  .bf-dialog {
    background-image: url("./imgs/dog_bg.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% 100%;
    width: 680px !important;
    min-height: 400px;
    text-align: center;
    overflow: inherit !important;
    .pto-con {
      padding-top: 150px;
      > .title {
        font-size: 35px;
        font-weight: bold;
        color: #fff;
        line-height: 40px;
      }
      .date {
        font-size: 14px;
        color: #fff;
        line-height: 20px;
        margin: 25px 0 40px;
      }
      > .pto-btn {
        display: block;
        padding: 0 20px;
        min-width: 200px;
        text-align: center;
        height: 44px;
        color: #fff;
        font-size: 16px;
        line-height: 44px;
        background: var(--COLOR-brand);
        border-radius: 3px;
        display: inline-block;
        margin: 0 auto;
      }
    }
    .dia-close {
      position: absolute;
      right: 0;
      top: 0;
      transform: translateX(60%) translateY(-60%);
      cursor: pointer;
    }
  }
}

</style>
