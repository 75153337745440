<template>
  <div class="manageMask" v-if="maskShow">
    <div class="content">
      <img :src="info.imgUrl" @click="open(info.jumpUrl)"/>
      <div class="line"></div>
      <img src="./imgs/manageClose.svg" class="close" @click="close"/>
      <div class="tip" v-if="isLogin">
        <div class="check" @click="dayShow = !dayShow">
          <svg aria-hidden="true" v-if="dayShow">
            <use xlink:href="#bf-check-line"></use>
          </svg>
        </div>
        {{$t('money_management_116')}}
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import {adBannerClose, adBannerInfo} from '~/model/clientAcademyModel.js';
export default {
  data () {
    return {
      info: {},
      maskShow: false,
      dayShow: false,
      list: ['spot', 'perpetual', 'tokens', 'userWithdraw'] // 需要展示的页面（币币, 永续, 充值, 提现）
    };
  },
  mounted () {
    if (this.list.includes(this.$route.name)) {
      this.getBanner();
    }
  },
  methods: {
    // 请求banner，若无banner则不展示
    async getBanner () {
      let res = await adBannerInfo({
        displayPage: 9
      });
      if (res.success && JSON.stringify(res.data) !== '{}') {
        let locale = this.mainLocales.includes(this.locale) ? this.locale : 'en';
        if (locale === 'kr') locale = 'ko';
        if (locale === 'pt-br') locale = 'pt';
        if (res.data[locale]) {
          this.info = res.data[locale][0];
          this.maskShow = true;
        }
      }
    },
    // 请求24小时不再展示的接口
    async adBannerClose () {
      await adBannerClose();
    },
    open (url) {
      if (url) {
        if (this.isLogin) {
          this.adBannerClose();
        }
        if (url.indexOf('https://www.bitforex.com/') !== -1) {
          window.location.href = url;
        } else {
          window.open(url);
        }
        this.dayShow = false;
      }
    },
    // 点击关闭icon
    close () {
      if (this.dayShow) {
        this.adBannerClose();
        this.maskShow = false;
      } else {
        this.maskShow = false;
      }
      this.dayShow = false;
    }
  },
  watch: {
    // 监听cur的变化
    $route (val) {
      if (this.list.includes(val.name)) {
        this.getBanner();
      }
    }
  },
  computed: {
    ...mapState({
      locale: state => state.common.locale,
      isLogin: state => state.user.isLogin,
      mainLocales: state => state.common.mainLocales
    })
  }
};
</script>
<style lang="scss" scoped>

.manageMask{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2001;
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  .content{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    img{
      display: block;
      margin: auto;
      border-radius: 24px;
      width: 290px;
      height: auto;
    }
    .line{
      height: 40px;
      width: 2px;
      background: #fff;
      margin: auto;
    }
    .close{
      cursor: pointer;
      width: 29px;
      height: 29px;
    }
    .tip{
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #FFFFFF;
      margin-top: 16px;
      .check{
        width: 15px;
        height: 15px;
        border: 1px solid #FFFFFF;
        margin-right: 6px;
        display: flex;
        justify-content: center;
        align-content: center;
        cursor: pointer;
        svg{
          width: 15px;
          height: 15px;
          fill: currentColor;
        }
      }
    }
  }
}

</style>
