export default {hk:{
  "403": "操作失敗，請聯繫客服",
  "500": "系統異常，請稍後再試",
  "5843": "該證件已被其他帳戶使用，請重新錄入證件資訊。",
  "10000": "訪問過於頻繁，請稍後訪問。",
  "100001": "參數錯誤",
  "100002": "參數值異常",
  "100003": "請求header異常",
  "100004": "驗證碼過期",
  "100005": "驗證碼錯誤",
  "100006": "您已被禁止交易，如有疑問，請聯繫客服",
  "100007": "持倉被鎖定",
  "100008": "下單數量不合法",
  "100009": "最大持倉數未配置",
  "100010": "委託失敗! 超過最大持倉上限！",
  "100011": "超過最大下單價格限制！",
  "100012": "保證金不足！",
  "100013": "訂單更新失敗！",
  "100014": "訂單不存在！",
  "100015": "無賣盤，無法下單！",
  "100016": "登錄超時，請重新登錄！",
  "100017": "無倉位，不能平倉！",
  "100018": "價格精度不對！",
  "100019": "無資產！",
  "100022": "價格不可為0！",
  "200007": "該幣種已暫停交易。",
  "200008": "合約不存在",
  "200009": "合約暫不支持該幣種",
  "200010": "金額精度大於幣種最大精度",
  "200011": "獲取行情異常",
  "200012": "幣對不存在",
  "200013": "幣對不能為空",
  "200015": "餘額不足",
  "200017": "劃轉失敗！",
  "200018": "您未同意轉賬授權協議",
  "200024": "劃轉異常，請與客服聯繫。",
  "200025": "您轉入的資金需大於{num}",
  "200026": "轉出限制：需要完成至少一次的轉入記錄",
  "300004": "劃轉失敗，環境異常",
  "300006": "劃轉失敗，您的金額不足",
  "300007": "請設置安全認證",
  "1000025": "下單失敗！平倉單已經存在",
  "1000026": "下單失敗！存在更優價格的反向委託單",
  "ACT1000": "參數錯誤",
  "ACT1001": "投票未開始",
  "ACT1002": "投票已結束",
  "ACT1003": "小於最小投票金額",
  "ACT1004": "資產不足",
  "ACT1005": "重複投票",
  "BACK_UF019": "用戶帳號已被凍結",
  "CAPP001": "帳戶不存在",
  "CAPP0010": "該項目已下架，請選擇其他專案",
  "CAPP0011": "密碼錯誤",
  "CAPP0012": "授權超時，請重新返回操作",
  "CAPP002": "禁止轉入",
  "CAPP003": "你的帳戶已被凍結，請聯繫客服",
  "CAPP004": "禁止轉出",
  "CAPP005": "資產異常",
  "CAPP006": "不支持該幣種交易",
  "CAPP007": "帳戶異常",
  "CAPP008": "訂單不存在",
  "CAPP009": "支付超時，請重新返回操作",
  "CAPP010": "該項目已下架，請選擇其他專案",
  "CAPP011": "密碼錯誤",
  "CAPP012": "授權超時，請重新返回操作",
  "CAPP018": "禁止資金劃轉",
  "CAPP500": "系統異常",
  "CT102": "該交易尚未開啟，交易時間請參考最新公告",
  "CT103": "所填價格超出允許價格範圍",
  "CT104": "用戶可用資產不足",
  "CT105": "訂單不存在",
  "CT106": "下單量大於最大限制",
  "CT107": "下單量小於最小限制",
  "CT108": "用戶帳號已被凍結",
  "CT110": "超出最大購買量",
  "CT200": "您已被禁止幣幣交易，若有有疑問請聯系客服",
  "CT801": "用戶未綁定手機",
  "CT804": "用戶已綁定手機",
  "CT805": "該手機號已經被使用",
  "CT806": "驗證碼已過期",
  "CT807": "短信驗證碼校驗超出次數限制",
  "CT809": "手機驗證碼錯誤",
  "CT810": "未設置安全認證",
  "CT811": "未綁定Google私鑰",
  "CT812": "Google驗證碼錯誤",
  "CT822": "用戶已經綁定Google私鑰",
  "CT823": "不存在",
  "CT824": "不能修改",
  "CT825": "沒有改變",
  "CT826": "用戶已經設置安全驗證",
  "CT902": "不存在",
  "FC101": "當前合約暫停用戶下單",
  "FC102": "用戶帳號被凍結",
  "FC103": "下單價格超出限制",
  "FC104": "下單量大於最大限制",
  "FC105": "用戶資金不足",
  "FC106": "用戶持倉量不足",
  "FC108": "用戶撤單失敗",
  "FC109": "下單量小於最小限制",
  "FC200": "您已被禁止CApp交易，若有有疑問請聯系客服",
  "FC999": "禁止資金劃轉",
  "FI105": "當前訂單已贖回",
  "FI109": "訂單不存在",
  "FI110": "當前訂單已贖回",
  "FT002": "帳戶餘額不足",
  "LN1000": "增减資產流程失敗",
  "LN1001": "參數錯誤",
  "LN1002": "借幣數量錯誤",
  "LN1003": "用戶不存在",
  "LN1004": "獲取用戶可用資產失敗",
  "LN1005": "用戶可用資產不足",
  "LN1006": "査詢不到該訂單",
  "LN1007": "凍結用戶資產失敗",
  "LN1008": "訂單創建失敗",
  "LN1009": "訂單還款失敗",
  "LN1010": "訂單調整質押率失敗",
  "LN1011": "解凍用戶資產失敗",
  "LN1012": "當前匯率已發生變動，請刷新頁面後再試",
  "LN1013": "保存調整質押率記錄失敗",
  "LN1014": "凍結用戶資產失敗，解凍系統資產失敗",
  "LN1015": "扣减用戶資產失敗",
  "LN1016": "扣减系統資產失敗",
  "LN1017": "新增用戶資產失敗",
  "LN1018": "新增系統資產失敗",
  "LN1019": "凍結系統資產失敗",
  "LN1020": "系統可用資產不足",
  "LN1021": "獲取系統可用資產失敗",
  "LN1022": "調整質押率數量錯誤",
  "LN1023": "借幣幣種或質押幣種類型錯誤",
  "LN1024": "借幣期限錯誤",
  "LN1025": "借幣幣種或質押幣種錯誤",
  "LN1026": "訂單已完成，無需繼續還幣",
  "LN1027": "借幣幣種發放失敗",
  "LN1028": "該幣種總可借數量不足",
  "LN1029": "新增幣種總可借數量失敗",
  "LN1030": "訂單狀態有變化不能進行還款操作",
  "LN1032": "資產總覽計算失敗",
  "LN1033": "網絡擁堵，請稍後再試",
  "LN1034": "此幣種暫無可抵押額度，請稍後再試。",
  "NFT100": "活動不存在",
  "NFT101": "未開始",
  "NFT102": "已結束",
  "NFT103": "此類型不允許直接購買",
  "NFT104": "此類型不允許買斷",
  "NFT105": "買斷金額錯誤",
  "NFT106": "已賣出",
  "NFT107": "不可重複上架",
  "NFT108": "NFT資產錯誤",
  "NFT109": "用帳戶餘額不足的多语言",
  "NFT110": "提現手續費不足",
  "NFT111": "當前NFT不可操作",
  "NFT112": "當前數據不可以取消提現",
  "NFT113": "提現數據異常",
  "NFT114": "上架參數錯誤",
  "NFT115": "出價金額不能小於當前最新出價",
  "NFT116": "價格不能為0",
  "NFT117": "拍品不存在",
  "NFT118": "交易中，無法撤銷",
  "NFT120": "暫停提現",
  "NFT121": "暫停充值",
  "NFT122": "您的提現已被凍結，若有疑問請聯系客服",
  "NFT123": "您已被禁止NFT交易，若有有疑問請聯系客服",
  "OAUTH100001": "支付已超時，請重新下單",
  "OAUTH100002": "簽名錯誤",
  "OAUTH100003": "認證錯誤",
  "OAUTH100004": "合約錯誤",
  "PB100000": "帳戶資產錯誤",
  "PB100001": "帳戶資產不足",
  "PB100002": "預購金額不能小於100",
  "PB100003": "錯誤的預購價格",
  "PB100004": "錯誤的幣種類型",
  "PB100005": "價格有變動，請重新下單購買",
  "PB100006": "預購金額有誤",
  "PB110000": "未到預購時間",
  "RT100": "驗證碼錯誤",
  "RT101": "參數格式有誤",
  "RT102": "交易對不存在",
  "RT103": "系統配置錯誤",
  "RT104": "系統環境異常",
  "RT201": "收藏已達上限",
  "RT301": "伺服器環境異常",
  "RT302": "伺服器環境異常",
  "RT303": "伺服器環境異常",
  "RT304": "伺服器環境異常",
  "RT398": "伺服器環境異常",
  "RT399": "伺服器請求頻率過高",
  "RT401": "不支持該劃轉類型",
  "RT999": "未定義異常",
  "SL303": "網絡擁堵，請稍後再試",
  "SL304": "委託失敗! 超過最大持倉上限！",
  "SP801": "已審核通過,不能修改上幣申請資訊",
  "SP802": "申請表不存在，請先填寫上幣申請",
  "SP803": "不能編輯參數配置資訊",
  "SP804": "上幣費未支付，請先支付上幣費",
  "SP805": "可用資金不足",
  "SP806": "上幣費已經支付",
  "SP807": "配置參數不存在",
  "TF001": "您已被禁止錢包帳戶劃轉，若有疑問請聯繫客服",
  "TF002": "您已被禁止幣幣帳戶劃轉，若有疑問請聯繫客服",
  "TF003": "您已被禁止永續帳戶劃轉，若有疑問請聯繫客服",
  "TF004": "您已被禁止 MT5 帳戶劃轉，若有疑問請聯繫客服",
  "TF005": "您已被禁止 CApp 帳戶劃轉，若有疑問請聯繫客服",
  "TS1001": "系統繁忙，請稍後再試",
  "TS1002": "系統繁忙，請稍後再試",
  "TS1003": "認購份額超限",
  "TS1004": "可用餘額不足",
  "TS1005": "查詢失敗",
  "TS1006": "認購失敗",
  "TS1007": "系統繁忙，請稍後再試",
  "TS1008": "系統繁忙，請稍後再試",
  "TS1009": "系統繁忙，請稍後再試",
  "TS1010": "系統繁忙，請稍後再試",
  "TS1011": "系統繁忙，請稍後再試",
  "TS1012": "系統繁忙，請稍後再試",
  "u100017": "資產帳號不存在",
  "u100018": "資產餘額不足",
  "u100019": "資產更新凍結錯誤",
  "u100020": "持倉不存在",
  "u100021": "持倉不足",
  "u100022": "幣種不存在",
  "u100023": "凍結異常",
  "u100024": "交易對不存在",
  "u100025": "抵押比列不一致",
  "u100026": "抵押參數數量錯誤",
  "u100027": "兌換參數數量錯誤",
  "u100028": "資金池為空",
  "u100029": "資金池金額不足",
  "u100030": "贖回比列錯誤",
  "u100031": "您已被禁止Swap交易，若有有疑問請聯系客服。",
  "u300006": "你的帳戶已被凍結，請聯繫客服",
  "u300007": "資產異常",
  "u300008": "系統繁忙",
  "u300009": "該幣種已存在",
  "UF001": "數據不能為空",
  "UF002": "提幣地址不可超過20個",
  "UF003": "充值地址已存在",
  "UF015": "用戶被凍結",
  "UF016": "提現地址不存在",
  "UF017": "提現地址不屬於本用戶",
  "UF018": "解析提幣地址失敗",
  "UF019": "您的提現已被凍結，若有有疑問請聯系客服",
  "UF020": "超過每日提現",
  "UF021": "用戶資產不足",
  "UF022": "存在未確認充值記錄",
  "UF023": "解析提幣地址失敗",
  "UF024": "無效的FIO地址",
  "UF030": "添加地址失敗，請檢查網路連接後重試",
  "UF034": "撤銷失敗",
  "UF036": "提現手續費低於最低限額",
  "UF037": "提現數量低於最低限額",
  "UF038": "此幣種不支持提現到 BitForex 充值地址，請使用其他地址",
  "UF039": "維護中，暫不支持提幣",
  "UF101": "TXID格式錯誤，請重新輸入",
  "UF102": "本小時已提交5次，請稍後再試",
  "UF108": "請使用您提交的地址進行充值",
  "UF301": "最新價已變化，請重新借入。",
  "UF302": "餘額不足",
  "UF303": "提交數量不合法",
  "UF304": "借入額度已達到限額",
  "UF305": "業務未開啟",
  "UF307": "用戶的借入數量達到限額",
  "UF308": "參與用戶數達到限額",
  "UF312": "您已爆倉，無法借入和歸還",
  "UF313": "歸還數量必須小於可歸還數量",
  "US1752": "手機號碼已註銷",
  "US1753": "郵箱已註銷",
  "US1754": "實名認證資訊已註銷",
  "US1755": "帳號已註銷",
  "US601": "郵箱格式錯誤",
  "US602": "密碼8-20位，包含大寫、小寫字母、數字，且無空格。",
  "US604": "上傳檔大小超過限制",
  "US605": "上傳檔格式錯誤",
  "US607": "請上傳身份證照片",
  "US608": "請上傳護照照片",
  "US609": "請先完成身份認證",
  "US651": "此APIKey不屬於操作用戶",
  "US660": "圖片驗證碼錯誤",
  "US661": "該證件已被其他帳戶使用，請重新錄入證件資訊。",
  "US665": "人機驗證失敗，請重試",
  "US667": "手機號不正確",
  "US668": "該證件已被其他帳戶使用，請重新錄入證件資訊。",
  "US669": "不支持的電子郵件域名",
  "US671": "TOTP驗證失敗次數過多，請稍後再試",
  "US701": "該郵箱已被註冊",
  "US702": "驗證碼不存在或已過期",
  "US703": "郵箱驗證碼錯誤",
  "US704": "此帳號不存在",
  "US705": "您的帳戶已被鎖定，請聯繫線上客服。",
  "US706": "您的帳戶已被鎖定，請1小時後再試",
  "US707": "您已連續5次輸入錯誤，請重新獲取",
  "US708": "該電子郵件未註冊",
  "US709": "郵箱或密碼錯誤",
  "US710": "該手機號已被註冊",
  "US711": "短信驗證碼不存在",
  "US712": "用戶名或密碼錯誤",
  "US715": "邀請人UID不存在",
  "US724": "用戶異地認證過於頻發，請稍後重試",
  "US725": "異地登陸認證失敗",
  "US726": "用戶異地認證過於頻發，請稍後重試",
  "US727": "用戶異地認證過於頻發，請稍後重試",
  "US732": "您已連續6次密碼輸入錯誤，請五分鐘後再試",
  "US733": "交易密碼輸入錯誤",
  "US750": "已成功綁定郵箱",
  "US752": "請綁定郵箱",
  "US753": "驗證碼錯誤",
  "US754": "驗證碼錯誤",
  "US756": "驗證碼不存在或已失效",
  "US801": "用戶未綁定手機",
  "US802": "還有30秒發送下一條短信",
  "US803": "短信驗證碼錯誤次數超出限制，請1小時後再試。",
  "US804": "用戶已經綁定手機",
  "US805": "該手機號已被綁定",
  "US806": "綁定手機的臨時資訊不存在",
  "US807": "您已連續5次輸入錯誤，請重新獲取",
  "US808": "驗證碼不存在或已失效",
  "US809": "請輸入正確的短信驗證碼。",
  "US810": "未設置安全認證。",
  "US811": "未綁定Google私鑰",
  "US812": "請輸入正確的Google雙重驗證口令。",
  "US813": "換綁舊手機的臨時資訊不存在",
  "US814": "換綁新手機的臨時資訊不存在。",
  "US815": "短信驗證碼錯誤次數超出限制，請1小時後再試。",
  "US816": "請輸入正確的短信驗證碼。",
  "US817": "請輸入正確舊的短信驗證碼。",
  "US818": "短信驗證碼錯誤次數超出限制，請1小時後再試。",
  "US819": "請輸入正確的短信驗證碼。",
  "US820": "請輸入正確新的的短信驗證碼。",
  "US821": "解綁手機的臨時資訊不存在",
  "US822": "用戶已經綁定Google私鑰",
  "US823": "【安全驗證】不存在",
  "US824": "【安全驗證】不能修改",
  "US825": "【安全驗證】沒有改變",
  "US826": "用戶已經綁定了安全認證",
  "US829": "驗證失敗，請重試。",
  "US830": "用戶APIKey數量超出限制",
  "US832": "驗證碼不存在或已失效",
  "US833": "驗證碼不正確",
  "US834": "請綁定郵箱",
  "US835": "驗證碼錯誤",
  "US857": "郵件驗證碼超過最大錯誤次數，請重新獲取郵件驗證碼",
  "US901": "【通知名稱】不存在",
  "US902": "通知狀態沒有改變",
  "UW205": "對不起，您的帳戶資產已被凍結，如有疑問，請聯繫線上客服。",
  "UW208": "郵箱驗證失敗，請檢查網路連接後重試",
  "UW209": "郵件發送失敗，請點擊“再次發送”進行重發",
  "UW210": "郵箱驗證碼已失效，請重新發送",
  "UW213": "鏈接已失效，請重新提交發送請求。",
  "UW236": "沒有綁定安全認證，請先進行安全認證",
  "WE101": "用戶未登錄",
  "WE102": "用戶無權限",
  "WE103": "用戶IP不在允許範圍內",
  "WE104": "用戶訪問頻率過高",
  "WE204": "用戶已經被凍結",
  "WE205": "用戶帳戶被凍結",
  "WE206": "超出每日限額",
  "WE207": "資金不足",
  "WE209": "郵箱發送失敗",
  "WE218": "用戶未完成身份認證",
  "WE219": "您還沒有實名認證"
}}