<template>
  <transition name="fade">
    <div>
      <div v-for="(item, index) in info" :key="index">
        <div class="dialogMask" v-if="item.show" @click="changeShow(index)">
          <div class="content" @click.stop>
            <div class="title">{{$t('conc_11')}}</div>
            <div class="info">
              <div class="num" :class="{'mt-8px':item.symbol === 'USDT'}">{{item.amount}} {{item.symbol}}</div>
              <div class="u_num" v-if="item.symbol !== 'USDT'">≈ {{item.amountUSDT | formatNumber(2) | effecNumber | formatMoney}} USDT</div>
            </div>
            <div class="btn" @click="gotoBuy(index, item.symbol)">{{$t('conc_13')}}</div>
            <div class="rule">
              <a href="https://support.bitforex.com/hc/en-us/articles/20000820100377" v-if="item.symbol === 'USDT'">{{$t('conc_14')}} >></a>
              <a :href="ruleLink" v-else>{{$t('conc_14')}} >></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapState } from 'vuex';
import api from '~/model/clientPerpetualModel.js';
export default {
  data () {
    return {
      info: {}
    };
  },
  computed: {
    ...mapState({
      locale: state => state.common.locale,
      isLogin: state => state.user.isLogin
    }),
    ruleLink () {
      const lang = ['cn', 'en', 'kr'].includes(this.locale) ? this.locale : 'en';
      const obj = {
        cn: 'zh-cn',
        en: 'en-us',
        kr: 'ko',
        ru: 'ru'
      };
      return `https://support.bitforex.com/hc/${obj[lang]}/articles/360036190451`;
    }
  },
  created () {
    if (this.$route.name !== 'login' && this.$route.name !== 'register' && this.$route.name !== 'registeredSuccess2' && this.$route.name !== 'game') {
      this.isLogin && this.query();
    }
  },
  watch: {
    // 监听cur的变化
    $route (val) {
      if (val.name !== 'registeredSuccess2' && val.name !== 'register' && val.name !== 'login' && this.$route.name !== 'game') {
        this.isLogin && this.query();
      }
    }
  },
  methods: {
    query () {
      this.isLogin && api.couponMessage().then(data => {
        if (data) {
          this.info = data.couponResultVOList;
          for (let i = 0; i < this.info.length; i++) {
            this.info[i].show = true;
          }
        }
      });
    },
    changeShow (index) {
      this.info[index].show = false;
      let obj = this.info[index];
      this.info.splice(index, 1, obj);
    },
    gotoBuy (index, symbol) {
      this.changeShow(index);
      this.$router.push({
        path: `/perpetual/btc_${symbol === 'USDT' ? 'usdt' : 'usd'}`
      });
    }
  }
};
</script>
<style lang="scss" scoped>

.dialogMask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2001;
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
}
.content {
  position: absolute;
  width: 400px;
  height: 454px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: url("./imgs/web_bonus_bg.png") no-repeat top center;
  background-size: 100% auto;
  .title{
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
    text-align: center;
    margin-top: 215px;
  }
  .info{
    width: 340px;
    height: 73px;
    background: #00553E;
    border-radius: 6px;
    text-align: center;
    padding-top: 10px;
    margin: 11px auto 21px auto;
    .num{
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      color: #FFCC2F;
    }
    .u_num{
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #FFFFFF;
    }
  }
  .btn{
    width: 300px;
    height: 40px;
    background: #FFCC2F;
    border-radius: 4px;
    text-align: center;
    line-height: 40px;
    font-weight: 700;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.8);
    margin: auto;
    cursor: pointer;
  }
  .rule{
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    margin-top: 13px;
    a{
      color: #FFFFFF !important;
    }
  }
}

</style>
