import vueForm from './vueForm.vue';
import vueInput from './vueInput.vue';
const components = [vueForm, vueInput];
const install = function (Vue) {
  if (install.installed) return;
  components.map(component => {
    Vue.component(component.name, component);
  });
  if (typeof window !== 'undefined' && window.Vue) {
    install(window.Vue);
  }
};
export default{
  install,
  components,
  vueForm,
  vueInput
};
