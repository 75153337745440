<template>
  <div class="announcement" :style="{width: width}" :class="theme">
    <ul class="navTitle">
      <li :class="{'active': activeIndex === 3}" @click="changeIndex(3)">{{$t('news_3')}}</li>
      <li :class="{'active': activeIndex === 2}" @click="changeIndex(2)">{{$t('news_2')}}</li>
      <li :class="{'active': activeIndex === 1}" @click="changeIndex(1)">{{$t('news_1')}}</li>
    </ul>
    <section class="articles">
      <!-- <div v-if="articleList.length">
        <a :href="articleList[0].url" target="_blank" rel="external nofollow noopener noreferrer" class="first oneline">{{articleList[0].title}}</a>
      </div> -->
      <div class="articleList">
        <a :href="item.url" target="_blank" rel="external nofollow noopener noreferrer" v-for="item in articleList" :key="item.edited_at"><span class="articleName oneline">{{item.title}}</span> <span class="articleDate">{{new Date(item.edited_at) | formatDataTime('MM/dd')}}</span></a>
      </div>
      <a :href="`https://support.bitforex.com/hc/${curLang}/sections/${activeIndex === 1 ? '360001541712' : activeIndex === 2 ? '360002130471' : activeIndex === 3 ? '360001499772' : '360001541712'}`" target="_blank" rel="external nofollow noopener noreferrer" class="more ripple-effect"><span>{{$t('coin_more')}}</span></a>
      <div class="loading" v-show="showLoading">
        <Loading :show-theme="theme"></Loading>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getListAnnouncement } from '~/model/clientStaticModel.js';
// import { sortByDate } from '~/utils/util.js';
export default {
  name: 'announcement',
  data () {
    return {
      articleList: [], // 当前显示的列表
      activeIndex: 3, // 类型
      showLoading: true,
      articleCashe: {
        1: {
          'zh-tw': [],
          'en-us': [],
          'pt-br': [],
          'pt-pt': [],
          'ko': [],
          'ru': [],
          'ja': []
        },
        2: {
          'zh-tw': [],
          'en-us': [],
          'pt-br': [],
          'pt-pt': [],
          'ko': [],
          'ru': [],
          'ja': []
        },
        3: {
          'zh-tw': [],
          'en-us': [],
          'pt-br': [],
          'pt-pt': [],
          'ko': [],
          'ru': [],
          'ja': []
        }
      }
    };
  },
  props: {
    width: {
      type: String,
      default: '100%'
    },
    theme: {
      type: String,
      default: 'white'
    }
  },
  computed: {
    ...mapState({
      locale: state => state.common.locale
    }),
    curLang () {
      switch (this.locale) {
        // case 'cn':
        //   return 'zh-cn';
        case 'hk':
          return 'zh-tw';
        case 'pt-br':
          return 'pt-pt';
        case 'kr':
          return 'ko';
        case 'ru':
          return 'ru';
        case 'jp':
          return 'ja';
        // case 'vn':
        //   return 'vi';
        default:
          return 'en-us';
      }
    }
  },
  mounted () {
    this.getArticle(this.activeIndex);
  },
  methods: {
    getArticle (type) {
      if (this.articleCashe[type][this.curLang].length > 0) {
        this.articleList = this.articleCashe[type][this.curLang];
      } else {
        this.articleList = [];
        this.showLoading = true;
        getListAnnouncement({ lang: this.curLang, type }).then(res => {
          if (res.success === true) {
            // res.data.sort(sortByDate('edited_at', false));
            this.articleList = res.data.slice(0, 5) || [];
            this.articleCashe[type][this.curLang] = res.data.slice(0, 5) || [];
            this.showLoading = false;
          } else {
            // this.getArticle(type);
          }
        });
      }
    },
    changeIndex (index) {
      this.activeIndex = index;
      this.getArticle(index);
    }
  },
  watch: {
    locale () {
      this.getArticle(this.activeIndex);
    }
  }
};
</script>

<style lang="scss" scoped>

.announcement {
  width: 100%;
  border: 1px solid #ECF1F4;
/* 外阴影 - S */
  overflow: hidden;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15), 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  .navTitle {
    padding: 0 15px;
    text-align: left;
    li {
      display: inline-block;
      margin-right: 20px;
      color: rgba(0, 0, 0, 0.87);
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      box-sizing: border-box;
      vertical-align: middle;
      white-space: nowrap;
      cursor: pointer;
      &.active,
      &:hover {
        color: var(--COLOR-brand);
      }
      &.active{
        border-bottom: 2px solid #14C393;
      }
    }
  }
  .articles {
    position: relative;
    width: 100%;
    min-height: 200px;
    box-sizing: border-box;
    background: #fff;
    .articleList {
      min-height: 200px;
      > a {
        padding: 0 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        font-size: 12px;
        line-height: 20px;
        cursor: pointer;
        &:hover {
          background: var(--color-background-2);
        }
        .articleName {
          flex: 1;
          color: rgba(0, 0, 0, 0.87);
          text-align: left;
        }
        .articleDate {
          width: 50px;
          text-align: right;
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }
    .loading {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 40px;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .more {
      padding: 0 15px;
      display: block;
      height: 40px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 40px;
      text-align: center;
      color: #14C393;
      &:hover {
        background-color: rgba(0, 0, 0, 0.02);
      }
    }
  }
}

</style>
