module.exports = {
  // MT 5
  mt5Agreement: '/mt5/agreement/agreement', // 同意协议
  mt5GetAgreement: '/mt5/agreement/getAgreement', // 获取协议状态
  mt5GetAccounts: '/mt5/account/accounts', // 得到用户所有的账号
  mt5GetPassword: '/mt5/account/applyShowPwdInfo', // 查看密码
  mt5CreatAccount: '/mt5/account/creatAccount', // 创建账号
  mt5ResetPassword: '/mt5/account/resetPassword', // 重置密码
  mt5SumAssets: '/mt5/assets/sumAssets', // 得到用户的资产汇总
  mt5TransferOut: '/mt5/transfer/mt5TransferOut', // MT5转到其他钱包
  mt5TransferRecord: '/mt5/transfer/transferRecord', // 申请记录
  mt5bills: (currency) => `/mt5/transfer/order/historicalBill/${currency}`, // 获取用户历史账单
  mt5TransferCancel: (id) => `/mt5/transfer/cancelTransfer/${id}`, // 取消划转申请
  getSystemStatus: '/mt5/systemConfig/getSystemStatus', // 获取mt5是否维护
  mt5EventRankList: '/mt5/act/getRankList', // 获取mt5交易大赛排名
  mt5EventPersonRankList: '/mt5/act/getThirdActRank', // 获取mt5交易大赛个人排名
  mt5EventBonusTips: '/mt5/activity/getBonusTips', // 获取赠金提示
  mt5EventActivity: '/mt5/activity/getActivity' // 赠金活动信息
};
