import * as types from '../../../types';
import Cookies from 'js-cookie';
import {
  getRates,
  getFrates
} from '~/model/clientStaticModel';
// eslint-disable-next-line no-unused-vars
import { getCurrencies } from '~/model/clientUserModel.js';
import { getCountryId } from '~/model/clientTradeModel';
import * as academyModel from '~/model/clientAcademyModel';
import eventBus from '../../../../utils/eventBus';
import {maintenanceConfigList} from '~/model/clientSwapModel';
// eslint-disable-next-line no-unused-vars
import axios from 'axios';
const handleError = (err) => {
  // handle error
  if (err.code === 401) {
    // notify({
    //   content: '请登录！'
    // })
    eventBus.$emit('auth');
  }
};

// 换算汇率
const formatRates = (opt) => {
  let rates = {
    cny: {},
    usd: {},
    krw: {}
  };
  const btc_usd = opt.btc_usd * 1;
  const eth_usd = opt.eth_usd * 1;
  const tusd_usd = opt.tusd_usd * 1;
  const usd_cny = opt.usd_cny * 1;
  const usd_krw = opt.usd_krw * 1;
  rates.usd = {
    btc: btc_usd,
    eth: eth_usd,
    usdt: 1,
    tusd: tusd_usd,
    usdc: 1
  };
  rates.cny = {
    btc: btc_usd * usd_cny * 1,
    eth: eth_usd * usd_cny * 1,
    usdt: usd_cny * 1,
    tusd: tusd_usd * usd_cny * 1,
    usdc: 1
  };
  rates.krw = {
    btc: btc_usd * usd_krw * 1,
    eth: eth_usd * usd_krw * 1,
    usdt: usd_krw * 1,
    tusd: tusd_usd * usd_krw * 1,
    usdc: 1
  };
  return rates;
};

export default {
  // 获取国家ID
  async getCountryId ({dispatch, commit}, params) {
    try {
      const res = await axios({
        method: 'GET',
        url: 'https://api.ipify.org?format=json'
      });
      return getCountryId({
        ip: res.data.ip
      }).then(res => {
        if (res.success) {
          commit(types.COUNTRY_ID, res.data || 'EN');
        }
      }).catch(err => {
        handleError(err);
      });
    } catch (error) {
      console.error(error);
    }
  },
  // 首次获取交易对兑法币汇率
  getRatesInit ({dispatch, commit}, params) {
    return getRates().then(res => {
      if (res.success) {
        commit(types.RATES, res.data);
      }
    }).catch(err => {
      handleError(err);
    });
  },
  // 获取法币汇率
  getFratesAsync ({dispatch, commit}, params) {
    return getFrates().then(res => {
      if (res.success) {
        commit(types.FRATES, res.data);
      }
    }).catch(err => {
      handleError(err);
    });
  },
  // 获取正在维护的系统列表
  async getMaintenanceList ({dispatch, commit}, params) {
    // 获取维护的页面
    try {
      let result = await maintenanceConfigList();
      if (result.success) {
        commit(types.MAINTENANCE_LIST, result.data);
      }
    } catch (e) {
      console.error(e);
    }
  },
  setMaintenance ({dispatch, commit}, obj) {
    commit(types.MAINTENANCE_OBJ, obj);
  },
  // 计算交易对兑法币汇率
  getRatesAsync ({dispatch, commit}, params) {
    try {
      let rates = {
        cny: {},
        usd: {},
        krw: {}
      };
      rates = formatRates(params);
      commit(types.RATES, rates);
    } catch (err) {
      handleError(err);
    }
  },
  async getAcademys ({ commit }, params) {
    const res = await academyModel.getAcademys(params);
    // console.log(res);
    commit('SET_ACADEMYS', res.data || []);
    commit('SET_ACADEMYS_COUNT', res.cursor.count || 0);
  },
  async getArticlesOfIndex ({ commit }, params) {
    const res = await academyModel.getArticlesOfIndex(params);
    // console.log(res);
    commit('SET_ACADEMYS', res.data || []);
    commit('SET_ACADEMYS_COUNT', res.cursor.count || 0);
  },
  async getArticlesOfCategories ({ commit }, params) {
    const res = await academyModel.getArticlesOfCategories(params);
    // console.log(res);
    commit('SET_ACADEMYS', res.data || []);
    commit('SET_ACADEMYS_COUNT', res.cursor.count || 0);
  },
  async getCategories ({ commit }, params) {
    const res = await academyModel.getCategories(params);
    // console.log(res);
    commit('SET_CATEGORIES', res.data || []);
  },
  async getArticleRecommend ({ commit }, params) {
    const res = await academyModel.getArticleRecommend(params);
    // console.log(res);
    commit('SET_RECOMMENDS', res.data || []);
  },
  async getArticleRelated ({ commit }, params) {
    const res = await academyModel.getArticleRelated(params);
    // console.log(res);
    commit('SET_RELATED', res.data || []);
  },
  async getCurrencies ({ commit }, params) {
    const res = await getCurrencies();
    const index = Cookies.get('current');
    commit('CURRENCY', res.data || [{id: 20,
        isDefault: 1,
        isShow: 1,
        isoCode: "USD",
        precision: 2,
        symbol: "USD",
        usdToThisRate: 1}]);
    if (index) {
      commit('CURRENT_CURRENCY', res.data[index] || [])
    } else {
      const isTrue = [];
      const isUsd = [];
      // 判断是否有isDefault
      res.data.map(item => {
        if (item.isDefault) {
          isTrue.push(1);
          commit('CURRENT_CURRENCY', item)
        }
      })
      // 当没有isDefault时是否有USD
      if (isTrue.length === 0) {
        res.data.map(item => {
          if (item.isoCode === 'USD') {
            isUsd.push(1);
            commit('CURRENT_CURRENCY', item)
          }
        })
      }
      // 当既没有USD也没有isDefault时
      if (isTrue.length === 0 && isUsd.length === 0) {
        const datas = res.data;
        datas.push({
          id: 20,
          isDefault: 1,
          isShow: 1,
          isoCode: "USD",
          precision: 2,
          symbol: "$",
          usdToThisRate: 1
        });
        commit('CURRENCY', datas || []);
        commit('CURRENT_CURRENCY', {
          id: 20,
          isDefault: 1,
          isShow: 1,
          isoCode: "USD",
          precision: 2,
          symbol: "$",
          usdToThisRate: 1
        })
      }
    }
  },
  setTopSticky ({ commit }, params) {
    commit(types.TOP_STICKY, params);
  }
};
