// eslint-disable-next-line no-unused-vars
import moment from 'moment';
import {
  createApp
} from './app';
// eslint-disable-next-line no-unused-vars
import eventBus from './utils/eventBus';
import {
  getCookie
  // setCookie
} from './utils/cookieUtil';
import {
  // eslint-disable-next-line no-unused-vars
  getMomentLocale,
  getLangLocal,
  // eslint-disable-next-line no-unused-vars
  getLocalLangData
  // getQueryVariable,
} from './utils/util';
// eslint-disable-next-line no-unused-vars
import {checkMaintenance} from './utils/maintinenceUtil';
// import { getOnlineLang } from '~/model/clientStaticModel';
// eslint-disable-next-line no-unused-vars

const locales = ['hk', 'en', 'ru', 'pt-br', 'vn', 'tr', 'pt', 'es', 'jp', 'kr']; // cn ,
let lang = window.location.pathname.split('/')[1];
if (!locales.includes(lang)) {
  const lang2 = getCookie('lang');
  lang = locales.includes(lang2) ? lang2 : 'en';
}
document.documentElement.setAttribute('lang', getLangLocal(lang));
// const inviterId = getQueryVariable('inviterId');
// inviterId && setCookie('inviterId', inviterId, '1d');
const {
  // eslint-disable-next-line no-unused-vars
  app,
  // eslint-disable-next-line no-unused-vars
  router,
  // eslint-disable-next-line no-unused-vars
  store,
  // eslint-disable-next-line no-unused-vars
  i18n
} = createApp(lang);

/* const lazyLocalLang = (lang, item) => {
  getOnlineLang({
    lang,
    project: 5,
    system: 'web',
    version: item.__version
  }).then(data => {
    if (data.data) {
      getLocalLangData(lang, item, data.data);
    }
    i18n.setLocaleMessage(lang, Object.assign(i18n.messages[lang], item, data.data || {}));
  });
}; */
