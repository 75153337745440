import axios from 'axios';
import {
  createError,
  getCookie
} from './util';
import Cookies from 'js-cookie';
import md5 from 'js-md5';
import eventBus from './eventBus';
import Vue from 'vue';
import i18n from '../locales';
import Common from './common';
import {
  errorCode
} from '../commonFun/errorCode.js';
// const host = 'http://192.168.199.166';
const request = axios.create({
  baseURL: '/'
});
request.defaults.withCredentials = true; // 让ajax携带cookie

  // 拦截response 获取crtoken
  request.interceptors.response.use((response) => {
    const bfusid = getCookie('bfusid');
    // 将crtoken存储在cookie
    if ( response.headers.crtoken) {
      Cookies.set('crtoken', md5(bfusid + response.headers.crtoken));
    }
    if (response.headers.sessiontoken) {
      Cookies.set('bf-sessionToken', headers.sessiontoken)
    }
    return response;
  }, error => {
    console.log(error);
    return Promise.reject(error);
  });

  // 拦截request
  request.interceptors.request.use(res => {
    if (Cookies.get('crtoken')) {
      res.headers['crtoken'] = Cookies.get('crtoken');
    }
    
    if(Common.headerData) {
      res.headers = Common.headerData;
    }
   
    return res;
  }, error => {
    console.log(error);
  });

function inArray (arr, api) {
  for (var i = 0; i < arr.length; i++) {
    // console.log(api.toLowerCase());
    // console.log(arr[i].toLowerCase());
    if (api.toLowerCase().indexOf(arr[i].toLowerCase()) >= 0) {
      return true;
    }
  };
  return false;
};

// 'cointrade.act?cmd=placeSubscribeBuyOrder'
let apis = [];
// 处理请求
const handleRequest = (request, isHandlerError = true) => {
  return new Promise((resolve, reject) => {
    request.then(res => {
      const data = res.data;
      if (!data) {
        return resolve(createError(data.code, 'no data'));
      }
      if (!data.success) {
        if (data.code === 401) {
          eventBus.$emit('auth');
        } else {
          if (data.code === 'WE101' || data.code === 'US705' || data.code === '100016') {
            let message = errorCode(data.code, data.message, i18n.locale || getCookie('lang') || 'en');
            eventBus.$emit('auth', message);
            return resolve(createError(data.code, data));
          } else if (data.code === 10203) {
            return false;
          } else if (isHandlerError) {
            let message = errorCode(data.code, data.message, i18n.locale || getCookie('lang') || 'en');
            if (message && !inArray(apis, res.request.responseURL)) {
              Vue.prototype.$alert(message);
            }
          }
        }
        return resolve(createError(data.code, data));
      }
      resolve(data);
    }).catch((err) => {
      if (err && err.response && err.response.status === 403) {
        if (err.response.request && err.response.request.responseURL.indexOf('tokeninsight?tokenInsightId') === -1) {
          Vue.prototype.$alert(errorCode('403', '', i18n.locale || getCookie('lang') || 'en'));
        }
      } else {
        Vue.prototype.$alert(errorCode('500', '', i18n.locale || getCookie('lang') || 'en'));
        // Vue.prototype.$alert(err.message || '出错了');
      }
    });
  });
};

const setQuery = (params) => {
  if (!params || JSON.stringify(params) === '{}') {
    return '';
  } else {
    // let querys = [];
    // for (let key in params) {
    //   querys.push(key + '=' + encodeURIComponent(params[key]));
    //   querys.push(key + '=' + params[key]);
    // }
    // return '&' + querys.join('&'); 
    let uri = '';
    for (let key in params) {
      if (params[key] || params[key] === 0) {
        uri += `&${key}=${params[key]}`;
      }
    }
    return uri;
  }
};

export {
  handleRequest,
  request,
  setQuery
};
