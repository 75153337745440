export default {jp:{
  "500": "システムエラーが発生しました",
  "5843": "ご提供頂いた身分証は別のアカウントに使われております。もう一度おやり直してください。",
  "10000": "サーバーが込み合っています、しばらくしてからもう一度お試しください。",
  "100001": "パラメータエラー",
  "100002": "異常パラメータ",
  "100003": "リクエストヘッダーエラー",
  "100004": "確認コードの有効期限が切れました",
  "100005": "確認コードエラー",
  "100006": "現在取引を制限されています。カスタマーサービスまでお問い合わせください",
  "100007": "ポジションはロックされています",
  "100008": "無効な数量",
  "100009": "最大保持ポジションがありません",
  "100010": "注文に失敗しました。最大保有ポジション数を超えました。",
  "100011": "最大注文額を超えました！",
  "100012": "証拠金不足！",
  "100013": "注文更新に失敗しました！",
  "100014": "注文が存在しません",
  "100015": "売りがないため注文できません。",
  "100016": "ログイン時間が切れました。もう一度ログインしてください。",
  "100017": "ポジションがないため、ポジションクローズはできません。",
  "100018": "入力された金額が無効です",
  "100019": "資産がありません",
  "100022": "金額が0の場合は利用できません",
  "200007": "この通貨の取引が終わりました",
  "200008": "契約が存在しません",
  "200009": "契約は現在通貨をサポートしていません",
  "200010": "小数点以下の桁数が許可されている桁数よりも大きいです",
  "200011": "相場情報を取得できませんでした",
  "200012": "取引ペアが存在しません",
  "200013": "取引ペアが必要です",
  "200015": "残高不足",
  "200017": "転送は失敗しました！",
  "200018": "”BitForex MT5 利用約款“を見て同意してください",
  "200024": "エラーが発生しました、カスタマサポートまでご連絡ください。",
  "200025": "転送額は{num}を超える必要がある。",
  "200026": "出金制限：最低一回の入金記録が必要。",
  "300004": "システムエラーが発生して転送に失敗しました",
  "300006": "転送に失敗しました。数量を確認してください",
  "300007": "安全のために保安認証をしてください",
  "1000025": "注文に失敗しました。クローズポジション注文は、既存のクローズポジション注文が約定したときにのみ行うことができます。",
  "1000026": "注文に失敗しました。アカウントでより良いベストビットオファー価格が検出されました。",
  "ACT1000": "パラメータエラー",
  "ACT1001": "投票は開始されていません",
  "ACT1002": "投票は締め切りました",
  "ACT1003": "最低投票額未満",
  "ACT1004": "不十分な資産",
  "ACT1005": "再投票",
  "BACK_UF019": "アカウントが凍結されています",
  "CAPP001": "アカウント検索なし",
  "CAPP0010": "該当の項目はオフラインしました、他のをお選びくださいませ。",
  "CAPP0011": "パスワードをもう一度確認してご入力くださいませ。",
  "CAPP0012": "時間切れなので、もう一度お願いします。",
  "CAPP002": "入金禁止",
  "CAPP003": "お客様の口座は凍結されており、カスタマーサポートセンターにてご連絡くださいませ。",
  "CAPP004": "出金禁止",
  "CAPP005": "資産異常あり",
  "CAPP006": "該当の仮想通貨の取引はできません。",
  "CAPP007": "アカウント異常あり",
  "CAPP008": "検索した注文は存在しません",
  "CAPP009": "お支払時間切れ、もう一度お願いします。",
  "CAPP010": "該当の項目はオフラインしました、他のをお選びくださいませ。",
  "CAPP011": "パスワードをもう一度確認してご入力くださいませ。",
  "CAPP012": "時間切れなので、もう一度お願いします。",
  "CAPP018": "資産の移転は禁止されています",
  "CAPP500": "システムエラー",
  "CT102": "この取引ペアはまだ取引が開始されていません。少々お待ちください",
  "CT103": "入力した値は許容価格範囲外です。",
  "CT104": "残高が不足しています",
  "CT105": "注文が存在しません",
  "CT106": "注文金額が制限価格を超えています",
  "CT107": "注文価格が最小可能注文数を下回っています",
  "CT108": "アカウントが凍結しています",
  "CT110": "最大制限を超えました。",
  "CT200": "現物取引は禁止されており、詳しくはカスタマーサポートセンターにてお問い合わせください。",
  "CT801": "電話番号がバインドされていません。",
  "CT804": "電話番号がバインドされています",
  "CT805": "この電話番号は既に登録されています。",
  "CT806": "確認コードの有効期限が切れています。",
  "CT807": "SMS確認コードのチェックが制限の数を超えました。",
  "CT809": "SMS確認コードが正しくありません。",
  "CT810": "Safety authenticationを設定してください。",
  "CT811": "Google秘密鍵をバインドしてください。",
  "CT812": "確認コードに誤りがあります",
  "CT822": "Google秘密鍵のバインド",
  "CT823": "確認できません",
  "CT824": "修正できません",
  "CT825": "変更できません",
  "CT826": "セキュリティ認証が正常にセットアップされました",
  "CT902": "確認できません",
  "FC101": "現在オーダーブックはストップしています",
  "FC102": "アカウントが凍結しています",
  "FC103": "価格が制限を超えています",
  "FC104": "最大注文可能金額を超えています",
  "FC105": "残高不足",
  "FC106": "ポジションに誤りがあります",
  "FC108": "注文のキャンセルに失敗しました",
  "FC109": "注文価格が最小可能注文数を下回っています",
  "FC200": "CAppの取引は禁止されており、詳しくはカスタマーサポートセンターにてお問い合わせください。",
  "FC999": "資産の移転は禁止されています",
  "FI105": "現在の注文は償還済みです",
  "FI109": "明細書がありません",
  "FI110": "現在の注文は償還済みです",
  "FT002": "アカウントの残高が不足しています",
  "LN1000": "資産の増額または減額プロセスが失敗しました",
  "LN1001": "パラメータエラー",
  "LN1002": "コイン借入数量が正しくありません",
  "LN1003": "ユーザーは存在しません",
  "LN1004": "ユーザーが利用可能な資産を取得できませんでした",
  "LN1005": "ユーザーが利用可能な資産が不足しています",
  "LN1006": "注文が見つかりません",
  "LN1007": "ユーザーの資産を凍結できませんでした",
  "LN1008": "注文の作成に失敗しました",
  "LN1009": "注文の返済に失敗しました",
  "LN1010": "注文のLTV調整に失敗しました",
  "LN1011": "ユーザーの資産を解凍できませんでした",
  "LN1012": "現在のレートが変更されました。ページを更新して再度お試しください。",
  "LN1013": "LTV調整ログの保存に失敗しました",
  "LN1014": "ユーザーの資産の凍結及びシステム資産の解凍に失敗しました",
  "LN1015": "ユーザーの資産を減額できませんでした",
  "LN1016": "システムの資産を減額できませんでした",
  "LN1017": "ユーザーの資産を増額できませんでした",
  "LN1018": "システム資産を増額できませんでした",
  "LN1019": "システム資産を凍結できませんでした",
  "LN1020": "システムが利用可能な資産が不足しています",
  "LN1021": "システムが利用可能な資産を取得できませんでした",
  "LN1022": "LTV調整数量が正しくありません",
  "LN1023": "コイン借入の通貨または担保通貨タイプが正しくありません",
  "LN1024": "コイン借入期限が正しくありません",
  "LN1025": "コイン借入の通貨または担保通貨が正しくありません",
  "LN1026": "注文は完了しているため、返済する必要がありません",
  "LN1027": "コイン借入通貨の発行に失敗しました",
  "LN1028": "この通貨の借入可能数量が不足しています",
  "LN1029": "通貨の借入可能数量を追加できませんでした",
  "LN1030": "注文のステータスが変更されたため、返済できません",
  "LN1032": "資産総覧計算の失敗",
  "LN1033": "ネットワークブロッキングは、後でもう一度試してください。",
  "LN1034": "この通貨に制限はありません。しばらくしてからもう一度お試しください。",
  "NFT100": "イベントが存在しません",
  "NFT101": "イベント未開始",
  "NFT102": "終了",
  "NFT103": "このタイプでは買い切りは不可",
  "NFT104": "このタイプは買い取り不可",
  "NFT105": "買取金額エラー",
  "NFT106": "売約済み",
  "NFT107": "再上場不可",
  "NFT108": "NFT資産エラー",
  "NFT110": "出金手数料不足",
  "NFT111": "現在のNFTは動作不能",
  "NFT112": "現在のデータでは、退会できません",
  "NFT113": "エラー発生",
  "NFT114": "パラメータエラー",
  "NFT115": "現在の最新の入札金額より低くすることはできません。",
  "NFT116": "価格を0にすることはできません",
  "NFT117": "入札アイテムが存在しない",
  "NFT118": "注文中、取消不可",
  "NFT120": "出金停止",
  "NFT121": "入金停止",
  "NFT122": "出金は凍結されており、詳しくカスタマーサポートセンターにてお問い合わせください。",
  "NFT123": "NFT取引は禁止されており、詳しくはカスタマーサポートセンターにてお問い合わせください。",
  "OAUTH100001": "お支払いセッションの期限が切れたので、もう一度お試しください。",
  "OAUTH100002": "エラー発生",
  "OAUTH100003": "認証エラー",
  "OAUTH100004": "契約エラー",
  "PB100000": "資産額に誤りがあります",
  "PB100001": "残高不足",
  "PB100002": "100未満を選択することはできません",
  "PB100003": "金額に誤りがあります",
  "PB100004": "トークンタイプが正しくありません。",
  "PB100005": "価格が更新されました。もう一度注文してください。",
  "PB100006": "金額に誤りがあります",
  "PB110000": "まだPre Purchaseの時間になっていません",
  "RT100": "確認コードエラー",
  "RT101": "パラメーターの形式が正しくありません。",
  "RT102": "取引ペアが存在しません",
  "RT103": "システム構成エラー。",
  "RT104": "システムエラー",
  "RT201": "お気に入りがいっぱいになりました。",
  "RT301": "サーバーエラー",
  "RT302": "サーバーエラー",
  "RT303": "サーバーエラー",
  "RT304": "サーバーエラー",
  "RT398": "サーバーエラー",
  "RT399": "サーバーへのアクセス頻度が高すぎます",
  "RT401": "サポートしない転送タイプです",
  "RT999": "エラー",
  "SL303": "ネットワークブロッキングは、後でもう一度試してください。",
  "SL304": "注文に失敗しました。最大保有ポジション数を超えました。",
  "SP801": "上場申請は承認されており、変更できません。",
  "SP802": "上場申請の記録はありません。 最初に上場申請してください。",
  "SP803": "構成パラメーターを編集することはできません。",
  "SP804": "最初にリスティング料金をお支払いください。",
  "SP805": "残高不足。",
  "SP806": "上場費用が支払われました",
  "SP807": "構成パラメーターが存在しません。",
  "TF001": "資金転送機能は只今ウォレットには禁止されており、カスタマーサポートセンターにて気軽にお問い合わせください。",
  "TF002": "資金転送機能は只今現物取引口座で禁止されており、カスタマーサポートセンターにて気軽にお問い合わせください。",
  "TF003": "資金転送機能は只今無期限契約口座で禁止されており、カスタマーサポートセンターにて気軽にお問い合わせください。",
  "TF004": "資金転送機能は只今MT5口座で禁止されており、カスタマーサポートセンターにて気軽にお問い合わせください。",
  "TF005": "資金転送機能は只今CApp口座で禁止されており、カスタマーサポートセンターにて気軽にお問い合わせください。",
  "TS1001": "システムがビジー状態です。後ほどお試しください。",
  "TS1002": "システムがビジー状態です。後ほどお試しください。",
  "TS1003": "限度額を超えています",
  "TS1004": "十分な資金がありません",
  "TS1005": "検索できません",
  "TS1006": "引き受けに失敗しました",
  "TS1007": "システムがビジー状態です。後ほどお試しください。",
  "TS1008": "システムがビジー状態です。後ほどお試しください。",
  "TS1009": "システムがビジー状態です。後ほどお試しください。",
  "TS1010": "システムがビジー状態です。後ほどお試しください。",
  "TS1011": "システムがビジー状態です。後ほどお試しください。",
  "TS1012": "システムがビジー状態です。後ほどお試しください。",
  "u100017": "アカウントが存在しない",
  "u100018": "無効な残高",
  "u100019": "資産更新エラー",
  "u100020": "ポジションが見つかりません",
  "u100021": "より多くのポジションが必要",
  "u100022": "コインが存在しない",
  "u100023": "トークンのフローズンエラー",
  "u100024": "取引ペアが見つかりません",
  "u100025": "比率 ミスマッチ エラー",
  "u100026": "金額 エラー",
  "u100027": "交換パラメータの数がおかしい",
  "u100028": "プール空",
  "u100029": "プールの残高が無効です",
  "u100030": "還元率エラー",
  "u100031": "口座のスワップ取引機能がロックされている、詳しくはカスタマーサポートセンターにてお問い合わせください。",
  "u300006": "アカウントが凍結されました。カスタマーサービスにご連絡ください。",
  "u300007": "アセットエラー",
  "u300008": "システムオーバーロード",
  "u300009": "コインはすでに存在する",
  "u400001": "選択できる最も早い時間帯は、参加してから24時間後です。",
  "UF001": "データを空にすることができません",
  "UF002": "引き出しアドレスは20つまでです",
  "UF003": "アカウントがすでに存在します",
  "UF015": "アカウントが凍結しています",
  "UF016": "出金アドレスが存在しません",
  "UF017": "この出金アドレスはこのユーザーのものではありません",
  "UF018": "アドレスの解決に失敗しました。",
  "UF019": "出金は凍結されており、詳しくカスタマーサポートセンターにてお問い合わせください。",
  "UF020": "1日の出金制限を超えています",
  "UF021": "残高不足。",
  "UF022": "未確認の入金があります",
  "UF023": "アドレスの解決に失敗しました。",
  "UF024": "FIO 暗号ハンドルが無効です。",
  "UF030": "アドレスの追加に失敗しました。通信環境を確認してください",
  "UF034": "取消失敗",
  "UF036": "出金取引手数料は最低限度額よりも低くなっています。",
  "UF037": "引き出し額が最小限度を下回っています。",
  "UF038": "該当のコイン及びトークンはBitForexのアドレスに入金できず、他のアドレスでやり直してください。",
  "UF039": "メンテナンス中のため、一時的に出金がサポートされません",
  "UF101": "TXID形式が正しくありません。もう一度入力してください。",
  "UF102": "1時間に5回リクエストしました。しばらくしてからもう一度お試しください。",
  "UF108": "送信したアドレスに送金してください",
  "UF301": "最新の価格が変更されました。もう一度操作を行ってください",
  "UF302": "残高不足",
  "UF303": "金額が無効です。",
  "UF304": "利用可能な借入額が制限に達しました。",
  "UF305": "現在利用できません",
  "UF307": "借入額が最大額に達しました。",
  "UF308": "参加者の数が制限に達しました。",
  "UF312": "強制清算のため、借り入れと返却はできません。",
  "UF313": "返却数量は返却可能数量より小さくなければいけません",
  "US1752": "携帯電話番号はすでに無効となりました。",
  "US1753": "e-mailアドレスはすでに無効となりました。",
  "US1754": "実名認証情報はすでに無効となりました。",
  "US1755": "アカウントを削除しました。",
  "US601": "メールアドレスの形式に誤りがあります",
  "US602": "パスワードは８－20文字で設定してください。英大文字・英小文字・数字それぞれを最低１文字ずつ含む必要があります。空白文字を使用しないでください",
  "US604": "ファイルサイズが制限を超えています。",
  "US605": "ファイルフォーマットに誤りがあります",
  "US607": "IDの写真をアップロードしてください。",
  "US608": "パスポートの写真をアップロードしてください",
  "US609": "KYCを完了してください",
  "US651": "API情報はユーザーに属していません",
  "US660": "画像確認コードに誤りがあります",
  "US661": "ご提供頂いた身分証は別のアカウントに使われております。もう一度おやり直してください。",
  "US665": "reCAPTCHAの認証に失敗しました、もう一度お試しください。",
  "US667": "携帯電話番号が正しくありません",
  "US668": "ご提供頂いた身分証は別のアカウントに使われております。もう一度おやり直してください。",
  "US671": "TOTPによる認証の失敗回数が多すぎます。しばらくしてからもう一度お試しください",
  "US701": "メールアドレスは既に登録されています",
  "US702": "確認コードの有効期限が切れています。",
  "US703": "確認コードが間違っています。",
  "US704": "このアカウントは存在しません。",
  "US705": "アカウントはロックされています。カスタマーサポートへご連絡ください",
  "US706": "アカウントはロックされています。1時間後にもう一度お試しください",
  "US707": "間違った確認コードを5回入力しました。 新しい確認コードをリクエストしてください。",
  "US708": "メールアドレスが存在しません",
  "US709": "メールアドレス、パスワードに誤りがあります",
  "US710": "携帯番号は既に登録されています",
  "US711": "SMS確認コードが正しくありません",
  "US712": "ユーザ名またはパスワードが無効です。",
  "US715": "紹介人UID不存在",
  "US724": "リモートログインの頻度が高すぎます。しばらくしてからもう一度お試しください。",
  "US725": "リモートログインに失敗しました。",
  "US726": "リモートログインの頻度が高すぎます。しばらくしてからもう一度お試しください。",
  "US727": "リモートログインの頻度が高すぎます。しばらくしてからもう一度お試しください。",
  "US732": "間違ったパスワードを6回入力しました。5分後にもう一度お試しください。",
  "US733": "取引パスワードが違います",
  "US750": "メール認証が完了しました",
  "US752": "電子メールアドレスをアカウントに登録してください。",
  "US753": "認証コードエラー",
  "US754": "認証コードエラー",
  "US756": "確認コードが存在しないか、有効期限が切れています。",
  "US801": "携帯番号の認証が済んでいません",
  "US802": "30秒以内に新しいSMS確認コードをリクエストしてください。",
  "US803": "SMSコードエラーが最大試行回数を超えています。 1時間後にもう一度お試しください。",
  "US804": "携帯番号がバインドされました",
  "US805": "携帯番号がバインドされています",
  "US806": "携帯と結びつける情報が存在しません",
  "US807": "間違った確認コードを5回入力しました。新しい確認コードをリクエストしてください。",
  "US808": "確認コードが存在しないか、有効期限が切れています。",
  "US809": "正しいSMS確認コードを入力してください。",
  "US810": "セキュリティ認証が設定されていません。",
  "US811": "Google秘密鍵が設定されていません。",
  "US812": "正しいGoogle 2FAパスワードを入力してください。",
  "US813": "以前使用していたモバイルインフォメーションが存在しません",
  "US814": "新しいモバイルインフォメーションは存在しません",
  "US815": "SMS確認コードの試行回数が多すぎます。1時間後にもう一度お試しください。",
  "US816": "正しいSMS確認コードを入力してください。",
  "US817": "以前の正しいSMS確認コードを入力してください。",
  "US818": "SMS確認コードの試行回数が多すぎます。1時間後にもう一度お試しください。",
  "US819": "正しいSMS確認コードを入力してください。",
  "US820": "正しい新しいSMS確認コードを入力してください。",
  "US821": "携帯電話の仮情報が存在しません",
  "US822": "Google秘密鍵がバインドされています。",
  "US823": "[securityAuthenticationName]は存在しません。",
  "US824": "[securityAuthenticationName]は変更できません。",
  "US825": "[securityAuthenticationName]は変更されていません。",
  "US826": "セキュリティ認証のセットアップ。",
  "US829": "セキュリティ認証エラー、もう一度やり直してください。",
  "US830": "APIキーの数量が制限を超えています。",
  "US832": "確認コードが存在しないか、有効期限が切れています。",
  "US833": "無効な確認コード。",
  "US834": "メールアドレスをアカウントにリンクしてください。",
  "US835": "認証コードエラー",
  "US857": "メール確認コードが5回失敗しました。 新しいメールコードをリクエストしてください。",
  "US901": "[notificationName]は存在しません。",
  "US902": "通知ステータスは変更されません。",
  "UW205": "アカウント資産が凍結されています。 BitForexオンラインカスタマーサービスにお問い合わせください。",
  "UW208": "メールの確認に失敗しました。ネットワーク接続を確認して、もう一度お試しください。",
  "UW209": "メールの送信に失敗しました。もう一度お試しください。",
  "UW210": "メール確認コードの有効期限が切れています。新しいコードをリクエストしてください。",
  "UW213": "リンクの有効期限が切れています。 送信リクエストを再送信してください。",
  "UW236": "最初にセキュリティ設定を行ってください",
  "WE101": "先にログインしてください",
  "WE102": "許可しません",
  "WE103": "IPアドレスが許可されていません",
  "WE104": "アクセス頻度が高すぎます",
  "WE204": "アカウントはロックされています",
  "WE205": "アカウントが凍結されています",
  "WE206": "1日の制限を超えました",
  "WE207": "残高不足",
  "WE209": "メールを送信できませんでした",
  "WE218": "ユーザー認証を完了していません",
  "WE219": "KYC認証を完了してください"
}}