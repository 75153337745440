import * as types from '../../../types';
export default {
  [types.IS_LOGIN] (state, status) {
    state.isLogin = status;
    // window.sensors.registerPage({
    //   is_login: status
    // }, () => console.log('Login'));
  },
  [types.USERBASEINFO] (state, status) {
    state.userBaseInfo = status;
  },
  [types.CUR_SYMBOL_ASSETS] (state, status) {
    state.curSymbolAssets = status;
  },
  [types.USERSAFEINFO] (state, status) {
    state.userSafeInfo = status;
  },
  [types.AUTHVERIFY] (state, status) {
    state.authVerify = status;
  },
  [types.BANKBINDING] (state, status) {
    state.bankBinding = status;
  },
  [types.MAIN_ASSETS_LIST] (state, status) {
    state.userMainAssetsList = status;
  },
  [types.TRADE_ASSETS_LIST] (state, status) {
    state.userTradeAssetsList = status;
  },
  [types.PERPETUAL_ASSETS_LIST] (state, status) {
    state.userPerpetualAssetsList = status;
  },
  [types.USDT_ASSETS_LIST] (state, status) {
    state.userUsdtAssetsList = status;
  },
  [types.MT5_ASSETS_LIST] (state, status) {
    state.userMt5AssetsList = status;
  },
  [types.CAPPS_ASSETS_LIST] (state, status) {
    state.userCappsAssetsList = status;
  },
  [types.DEFI_ASSETS_LIST] (state, status) {
    state.userDefiAssetsList = status;
  },
  [types.USER_TOTAL_ASSET] (state, status) {
    state.totalAsset = status;
  },
  [types.COIN_CODE] (state, status) {
    state.coinCode = status;
  },
  [types.COINS_INFO] (state, status) {
    state.coinsInfo = status;
  },
  [types.USER_SECURITY_SETTINGS] (state, status) {
    state.userSecuritySettings = status;
  },
  [types.LISTBOARD] (state, status) {
    state.listBoard = status;
  },
  userType (state, param) {
    state.userType = param;
  },
  [types.FUN_CONFIG] (state, status) {
    state.funConfig = status;
  }, 
  [types.VIPLEVELS] (state, status) {
    state.vipLevels = status;
  },
  
};
