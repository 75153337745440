export default {
  isApp: false,
  fromClient: '',
  theme: 'white',
  colors: 'cva',
  locales: ['hk', 'en', 'kr', 'ru', 'pt-br', 'vn', 'tr', 'pt', 'es', 'jp'], // cn
  mainLocales: ['hk', 'en', 'kr', 'ru', 'pt-br', 'jp'],
  locale: 'en',
  currency: [],
  currentCurrency: {},
  currentVal: Number(window.localStorage.getItem('currentVal')) || 0, // 资产显示索引
  currentUnit: window.localStorage.getItem('currentUnit') || 'BTC', // 资产显示单位
  countryId: '',
  isTradePage: false,
  isReload: true, // 静态刷新
  inviterIdType: '',
  maintenanceList: [],
  usdtPerpetualShow: true,
  rebateShow: false,
  currentMaintenance: null,
  currencyCodeSign: '$', // 交易盘$/E/฿
  topSticky: false,
  valuationCurrency: 'CNY', // 计价单位 CNY/KRW/USD
  valuationSymbol: '¥', // 计价单位 ¥/₩/$
  moonPayKEY: 'pk_live_OvVmKNCFTRHKEJdP47jfl1TB2EbDf',
  eurCountries: [
    'ALA',
    'ALB',
    'AND',
    'AUT',
    'BLR',
    'BEL',
    'BIH',
    'BGR',
    'HRV',
    'CZE',
    'DNK',
    'EST',
    'FRO',
    'FIN',
    'FRA',
    'DEU',
    'GIB',
    'GRC',
    'GGY',
    'VAT',
    'HUN',
    'ISL',
    'IRL',
    'IMN',
    'ITA',
    'JEY',
    'LVA',
    'LIE',
    'LTU',
    'LUX',
    'MKD',
    'MLT',
    'MDA',
    'MCO',
    'MNE',
    'NLD',
    'NOR',
    'POL',
    'PRT',
    'ROU',
    'RUS',
    'SMR',
    'SRB',
    'SVK',
    'SVN',
    'ESP',
    'SJM',
    'SWE',
    'CHE',
    'UKR',
    'GBR'
  ],
  frates: { // 法币汇率
    usd_cny: 1,
    usd_krw: 1
  },
  rates: { // 计价货币 兑 法币 汇率
    cny: { // 人民币汇率
      usdt: 1,
      eth: 1,
      btc: 1,
      tusd: 1,
      usdc: 1
    },
    usd: { // 美元汇率
      usdt: 1,
      eth: 1,
      btc: 1,
      tusd: 1,
      usdc: 1
    },
    krw: { // 韩元汇率
      usdt: 1,
      eth: 1,
      btc: 1,
      tusd: 1,
      usdc: 1
    }
  },
  academys: [],
  academys_count: 0,
  categories: [], // 分类列表
  recommends: [], // 底部推荐位
  related: [] // 左侧相关推荐位
};
