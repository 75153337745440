export const seo_home = {
  en: {
    title: 'BitForex | The World\'s Leading One-Stop Digital Asset Service Platform.',
    description: 'BitForex is the most active cryptocurrency exchange, featuring the fastest token listings, lowest fees, and easiest way to buy Bitcoin, Litecoin, ETH, Ripple, Tron, USDT, EOS, & more...',
    keywords: 'Bitcoin, Exchange, Bitcoin Exchange, Litecoin, Ethereum, blockchain assets exchange, cryptocurrency exchange, Bitcoin price, ETH price, ETH wallet registration, Binance, Coinbase, Bitfinex, bitstamp, Kraken, Poloniex, mining, wallet, buy bitcoin with US dollars'
  },
  hk: {
    title: '幣夫 | 全球領先的一站式數字資產服務平臺',
    description: '幣夫是流動性最強的數字貨幣交易平臺, 提供最快的上幣流程, 較低的手續費, 便捷買賣比特幣、萊特幣、以太坊、瑞波幣、波場、泰達幣、柚子幣...',
    keywords: '比特幣, 交易所, 比特幣交易所, 萊特幣, 以太坊, 區塊鏈資產交易平臺, 數字貨幣交易, 虛擬貨幣, 比特幣價格, 以太坊價格, ETH錢包註冊, 幣安, coinbase, bitfinex, bitstamp, kraken, poloniex, 挖礦, 錢包, 美元買比特幣。'
  },
  kr: {
    title: '비트포렉스 | BitForex 글로벌 디지털 자산 서비스 플랫폼',
    description: '비트포렉스는 가장 빠른 토큰 리스트, 최저 수수료, 그리고 비트코인, Lite코인, ETH, 리플, 트론, USDT, EOS 등을 구매할 수 있는 가장 간편한 특징으로 하는 가장 활발한 암호화폐 거래소입니다.',
    keywords: '비트포렉스,bitforex 거래소,비트포렉스 거래소,블록체인 거래소,가상화폐 거래소,비트코인 거래,이더리움 가격 동향,BF,BTC 시세,ETH 지갑 등록,LTC 비세,폴로니엑스.비트렉스'
  },
  ru: {
    title: 'БитФорекс | Ведущая в мире трейдинговая платформа для финансовых услуг',
    description: 'BitForex - это самая активная биржа криптовалют, которая предлагает самые быстрые листинги токенов, низкие комиссии и самый простой способ купить BTC, LTC, ETH, Ripple, Tron, USDT, EOS и другие ...',
    keywords: 'таких как Bitcoin, Ethereum, Ruibo и Wavefield.Биткойн, биржа, биржа биткойнов, Litecoin, Ethereum, платформа для торговли активами блокчейн, торговля цифровой валютой, виртуальная валюта, цена биткойна, цена Ethereum, регистрация кошелька ETH, Binance, coinbase, bitfinex, Bitstamp, kraken, poloniex, майнинг, кошелек, купить биткойн за доллары'
  },
  jp: {
    title: 'ビットフォレックス・世界前列のワンストップデジタル資産サービスプラットフォーム',
    description: 'ビットフォレックスは、高い流動性に誇る仮想通貨取引所であり、トークン上場の速さと割安な手数料は強みであります。ビットコインを始め、ライトコインとイーサリアムなどの人気トークンが簡単でお取引できます。',
    keywords: 'Bitcoin, Exchange, Bitcoin Exchange, Litecoin, Ethereum, blockchain assets exchange, cryptocurrency exchange, Bitcoin price, ETH price, ETH wallet registration, Binance, Coinbase, Bitfinex, bitstamp, Kraken, Poloniex, mining, wallet, buy bitcoin with US dollars'
  },
  pt: {
    title: 'BitForex | A plataforma líder mundial em serviços de ativos digitais.',
    description: 'Sendo a plataforma mais ativa de comércio de criptomoedas, BitForex oferece listagens de tokens mais rápidas, as taxas mais baixas e a maneira mais fácil de comprar e vender Bitcoin, Litecoin, ETH, Ripple, Tron, USDT, EOS e mais ..',
    keywords: 'Bitcoin, Troca, Troca de Bitcoin, Litecoin, Ethereum, troca de ativos blockchain, troca de criptomoedas, preço de Bitcoin, preço de ETH, registro de carteira ETH, Binance, Coinbase, Bitfinex, bitstamp, Kraken, Poloniex, mineração, carteira, comprar bitcoin com dólares americanos'
  },
  'pt-br': {
    title: 'BitForex | A plataforma líder mundial em serviços de ativos digitais.',
    description: 'Sendo a plataforma mais ativa de comércio de criptomoedas, BitForex oferece listagens de tokens mais rápidas, as taxas mais baixas e a maneira mais fácil de comprar e vender Bitcoin, Litecoin, ETH, Ripple, Tron, USDT, EOS e mais ..',
    keywords: 'Bitcoin, Troca, Troca de Bitcoin, Litecoin, Ethereum, troca de ativos blockchain, troca de criptomoedas, preço de Bitcoin, preço de ETH, registro de carteira ETH, Binance, Coinbase, Bitfinex, bitstamp, Kraken, Poloniex, mineração, carteira, comprar bitcoin com dólares americanos'
  },
  es: {
    title: 'BitForex | La plataforma de servicios de activos digitales líder en el mundo.',
    description: 'BitForex es el intercambio de criptomonedas más activo, con los listados de tokens más rápidos, las tarifas más bajas y la forma más fácil de comprar Bitcoin, Litecoin, ETH, Ripple, Tron, USDT, EOS y más ...',
    keywords: 'Bitcoin, Exchange, Bitcoin Exchange, Litecoin, Ethereum, exchange de activos blockchain, exchange de criptomonedas, precio del Bitcoin, precio de ETH, registro de cartera ETH, Binance, Coinbase, Bitfinex, bitstamp, Kraken, Poloniex, minería, carteras, compra bitcoin con dólares americanos'
  },
  tr: {
    title: 'BitForex | Dünyanın Önde Gelen Tek Noktadan Dijital Varlık Hizmet Platformu.',
    description: 'BitForex, en hızlı jeton listeleri, en düşük ücretler ve Bitcoin, Litecoin, ETH, Ripple, Tron, USDT, EOS ve daha fazlasını satın almanın en kolay yolunu içeren en aktif kripto para borsasıdır ...',
    keywords: 'Bitcoin, Borsa, Bitcoin Borsası, Litecoin, Ethereum, blok zinciri varlık değişimi, kripto para birimi değişimi, Bitcoin fiyatı, ETH fiyatı, ETH cüzdan kaydı, Binance, Coinbase, Bitfinex, bitstamp, Kraken, Poloniex, madencilik, cüzdan, ABD doları ile bitcoin satın al'
  },
  vn: {
    title: 'BitForex | Sàn giao dịch tài chính toàn diện hàng đầu thế giới',
    description: 'BitForex là sàn giao dịch tiền điện tử toàn diện nhất, niêm yết token nhanh nhất, phí thấp nhất và giao dịch Bitcoin, Litecoin, ETH, Ripple, Tron, USDT, EOS.... một cách dễ dàng nhất.',
    keywords: 'Bitcoin, Sàn giao dịch, Sàn giao dịch Bitcoin, Litecoin, Ethereum, giao dịch tài sản blockchain, giao dịch tiền điện tử, giá Bitcoin, giá ETH, đăng ký ví ETH, Binance, Coinbase, Bitfinex, bitstamp, Kraken, Poloniex, đào, ví, mua bitcoin bằng USD'
  }
};
export const seo_social = {
  en: {
    title: 'BitForex Social Trading | Follow & Copy The Best Trading strategy',
    description: 'Choose the best trading strategy from over 3000 global traders! Follow their trading strategy by using BitForex copy trade function!',
    keywords: '"Social Trading,Copy Trade,Trading Strategy"'
  },
  hk: {
    title: 'BitForex 合約洞察| 關注和跟單最佳的交易策略',
    description: '從3000多個全球交易者中選擇最佳的交易策略！通過使用BitForex跟單交易功能來遵循他們的交易策略！',
    keywords: '合約洞察,跟单交易，交易策略'
  },
  kr: {
    title: 'BitForex 소셜 트레이딩 | 최고의 투자 전략을 벤치마킹하라',
    description: '전세계 3000명 이상의 트레이더에게 배우는 거래 기법! BitForex 카피 트레이드로 전문 투자법을 따라가세요!',
    keywords: '소셜 트레이딩,카피 트레이드,투자 전략'
  },
  ru: {
    title: 'BitForex Социальная торговля | Подпишись & Копируй лучшие торговые стратегии',
    description: 'Выберайте лучшую торговую стратегию у более 3000 трейдеров в мире! Подписывайтесь на их торговые стратегии, используя функцию копитрейдинга BitForex!',
    keywords: 'Социальная Торговля,Копитрейдинг,Торговая стратегия'
  },
  jp: {
    title: 'ビットフォレックスソーシャルトレーディング・最高の取引戦略をフォロー及びコピーしよう',
    description: '3000位以上のグローバルトレーダーズから最高の取引戦略をお選びできます！ BitForexのコピートレーディング機能を利用し、優れた取引戦略にフォローしよう！',
    keywords: 'ソーシャルトレーディング,コピートレーディング,取引戦略'
  },
  pt: {
    title: 'Negociação Social do BitForex | Siga e Copie a Melhor Estratégia de Negociação',
    description: 'Escolhe a melhor estratégia de negociação dos 3000 traders globais! Siga as estratégias de negociação, usando a função Negociação Social do BitForex!',
    keywords: 'Negociação Social,Copia Trade,Estratégia de negociação'
  },
  'pt-br': {
    title: 'Negociação Social do BitForex | Siga e Copie a Melhor Estratégia de Negociação',
    description: 'Escolhe a melhor estratégia de negociação dos 3000 traders globais! Siga as estratégias de negociação, usando a função Negociação Social do BitForex!',
    keywords: 'Negociação Social,Copia Trade,Estratégia de negociação'
  }
};
