export default {kr:{
  "500": "시스템 오류가 발생했습니다.",
  "5843": "제출하신 신분증은 이미 다른 계정에 등록된 신분증입니다. 다시 확인 후 시도해 주세요.",
  "10000": "사용자 방문 빈도가 과도합니다.",
  "100001": "파라미터값 오류입니다.",
  "100002": "비정상적인 파라미터입니다.",
  "100003": "헤더가 없는 오류입니다.",
  "100004": "인증코드가 만료되었습니다.",
  "100005": "인증코드 오류입니다.",
  "100006": "거래가 금지되었습니다. 고객센터로 문의하시기 바랍니다.",
  "100007": "포지션이 청산되었습니다.",
  "100008": "유효하지 않은 수량입니다.",
  "100009": "Max Holding Position Missing",
  "100010": "최대 주문량을 초과하여 주문이 실패하였습니다.",
  "100011": "최대 주문량을 초과하였습니다.",
  "100012": "마진 사용가능 잔고가 부족합니다.",
  "100013": "주문이 실패하였습니다.",
  "100014": "주문이 존재하지 않습니다.",
  "100015": "거래 상대자가 없어 주문할 수 없습니다.",
  "100016": "로그인 세션 만료, 다시 로그인 해주세요.",
  "100017": "종료할 포지션이 없습니다.",
  "100018": "유효하지 않은 금액입니다.",
  "100019": "자산이 없습니다.",
  "100022": "가격에 0을 사용할 수 없습니다.",
  "200007": "검색하신 토큰의 거래는 종료되었습니다.",
  "200008": "주문이 존재하지 않습니다.",
  "200009": "해당 코인은 현재 가능하지 않습니다.",
  "200010": "소수점 자릿수가 해당 코인이 허용하는 자릿수보다 큽니다.",
  "200011": "Failed to get quote",
  "200012": "거래 페어가 존재하지 않습니다.",
  "200013": "거래 페어가 필요합니다.",
  "200015": "잔고부족",
  "200017": "송금할 수 없습니다",
  "200018": "\"BitForex MT5 이용 약관\"을 읽고 동의해주세요.",
  "200024": "송금 에러, 고객센터과와 확인하시기 바랍니다.",
  "200025": "송금 수량은 {num}보다 커야합니다.",
  "200026": "자금 송금 실패: 먼저 계정에 자금이 충전되어야 송금이 가능합니다.",
  "300004": "시스템 오류로 전송에 실패하였습니다",
  "300006": "전송에 실패하였습니다. 수량을 확인해주세요",
  "300007": "안전을 위해 보안 인증을 해주세요.",
  "1000025": "주문이 실패했습니다. 포지션 닫기는 기존 포지션 닫기 주문이 완료된 경우에만 진행할 수 있습니다.",
  "1000026": "주문이 실패했습니다. 계정에서 더 나은 최고 매수 호가가 탐지되었습니다.",
  "ACT1000": "매개변수 오류",
  "ACT1001": "투표가 시작되지 않았습니다.",
  "ACT1002": "투표가 종료되었습니다.",
  "ACT1003": "최소 투표 금액 미만",
  "ACT1004": "잔고 부족",
  "ACT1005": "중복 투표",
  "BACK_UF019": "사용자 계정 동결",
  "CAPP001": "존재하지 않는 계정입니다.",
  "CAPP0010": "선택하신 프로젝트를 지원하지 않습니다. 다시 선택해주세요.",
  "CAPP0011": "올바르지 않은 비밀번호입니다.",
  "CAPP0012": "인증서가 만료되었습니다. 인증을 갱신해주세요.",
  "CAPP002": "송금(충전)이 불가능합니다.",
  "CAPP003": "출금이 불가한 계정입니다. 고객지원팀으로 연락해주세요.",
  "CAPP004": "송금(인출)이 불가능합니다.",
  "CAPP005": "자산에서 비정상적인 에러가 발생하였습니다.",
  "CAPP006": "선택하신 코인은 더 이상 지원하지 않습니다.",
  "CAPP007": "계정에서 비정상적인 에러가 발생하였습니다.",
  "CAPP008": "선택하신 주문은 존재하지 않습니다.",
  "CAPP009": "만료된 주문입니다.",
  "CAPP010": "선택하신 프로젝트를 지원하지 않습니다. 다시 선택해주세요.",
  "CAPP011": "올바르지 않은 비밀번호입니다.",
  "CAPP012": "인증서가 만료되었습니다. 인증을 갱신해주세요.",
  "CAPP018": "자산 이동이 금지되었습니다.",
  "CAPP500": "시스템 내 비정상적인 에러가 발생하였습니다.",
  "CT102": "해당 거래는 현재 이용하실 수 없습니다. 거래 재개에 대한 공지사항을 확인해주세요.",
  "CT103": "입력하신 가격은 허용 범위를 초과하였습니다. 시장가 기준 80%~120% 이내의 가격을 입력해주세요.",
  "CT104": "사용자 사용 가능 자산 부족",
  "CT105": "주문이 존재하지 않습니다",
  "CT106": "주문 금액이 최대 주문 한도를 초과합니다.",
  "CT107": "주문 금액이 최소 주문 한도 미만입니다.",
  "CT108": "계정 동결",
  "CT110": "최대 매수량 한도 초과",
  "CT200": "계정의 코인 거래 기능이 잠겨 있습니다. 자세한 내용은 고객지원팀에 문의해주세요.",
  "CT801": "휴대폰 미인증 상태입니다.",
  "CT804": "휴대폰 인증 상태입니다.",
  "CT805": "이미 등록된 번호입니다. 확인 후 다시 시도해주세요.",
  "CT806": "바인딩 휴대폰의 임시 정보가 존재하지 않습니다",
  "CT807": "인증 코드 요청이 제한 횟수를 초과하였습니다.",
  "CT809": "휴대폰 인증 코드 오류",
  "CT810": "보안인증을 설정하지 않았습니다",
  "CT811": "구글 키를 바인딩하지 않았습니다",
  "CT812": "구글 인증 코드 오류",
  "CT822": "구글키 인증 상태입니다.",
  "CT823": "존재하지 않습니다",
  "CT824": "수정 불가",
  "CT825": "변경 무",
  "CT826": "보안인증이 성공적으로 완료되었습니다.",
  "CT902": "존재하지 않습니다",
  "FC101": "현재 계약 사용자 주문 일시 정지",
  "FC102": "귀하의 계정은 잠금되었습니다.",
  "FC103": "주문 가격이 허용 범위를 초과합니다.",
  "FC104": "주문 금액이 최대 주문 한도를 초과합니다.",
  "FC105": "사용자 자금 부족",
  "FC106": "사용자 미결제약정수량 부족",
  "FC108": "사용자 주문 취소 실패",
  "FC109": "주문량 과소",
  "FC200": "계정의 CApp 기능이 잠겨 있습니다. 자세한 내용은 고객지원팀에 문의해주세요.",
  "FC999": "자산 이동이 금지되었습니다.",
  "FI105": "현재 주문이 상환되었습니다.",
  "FI109": "존재하지 않는 주문입니다.",
  "FI110": "현재 주문이 상환되었습니다.",
  "FT002": "계정 잔액이 부족합니다.",
  "LN1000": "자산 반영에 실패했습니다.",
  "LN1001": "잘못된 입력 값입니다.",
  "LN1002": "대출하려는 금액이 올바르지 않습니다.",
  "LN1003": "접속 시간 만료로 로그아웃 되었습니다. 다시 로그인 해주세요.",
  "LN1004": "사용 가능한 사용자 자산을 가져오지 못했습니다.",
  "LN1005": "사용 가능한 사용자 자산이 충분하지 않습니다.",
  "LN1006": "해당 주문에 대한 값을 불러오지 못 했습니다.",
  "LN1007": "사용자 자산을 동결하지 못했습니다.",
  "LN1008": "주문 생성 실패",
  "LN1009": "주문 환불 실패",
  "LN1010": "주문 LTV 조정 실패",
  "LN1011": "사용자 자산 동결을 해제하지 못했습니다.",
  "LN1012": "토큰 비율이 변경되었습니다. 페이지를 새로고침 한 후 다시 시도하십시오.",
  "LN1013": "LTV 조정 내역을 저장하지 못했습니다.",
  "LN1014": "사용자 자산 동결 실패, 사용자 자산 동결 해제 실패",
  "LN1015": "사용자 자산 공제 실패",
  "LN1016": "시스템 자산 공제 실패",
  "LN1017": "사용자 자산을 추가하지 못했습니다.",
  "LN1018": "시스템 자산을 추가하지 못했습니다.",
  "LN1019": "시스템 자산을 동결하지 못했습니다.",
  "LN1020": "사용 가능한 시스템 자산이 부족합니다.",
  "LN1021": "사용 가능한 시스템 자산을 가져오지 못했습니다.",
  "LN1022": "LTV 조정 금액이 올바르지 않습니다.",
  "LN1023": "잘못된 종류의 코인 또는 담보 코인을 빌린 경우",
  "LN1024": "대출 기간이 올바르지 않습니다.",
  "LN1025": "대출 코인 또는 담보 코인이 올바르지 않습니다.",
  "LN1026": "주문이 완료되었으므로 상환할 필요가 없습니다.",
  "LN1027": "대출 코인 발행에 실패했습니다.",
  "LN1028": "빌릴 수 있는 코인이 부족합니다",
  "LN1029": "빌릴 수 있는 코인의 총량을 늘리지 못했습니다.",
  "LN1030": "주문 상태가 변경되었으므로 상환할 수 없습니다.",
  "LN1032": "전체 자산 계산이 실패했습니다",
  "LN1033": "네트워크가 복잡합니다. 잠시 후에 다시 시도하십시오",
  "LN1034": "이 통화에는 제한이 없습니다. 나중에 다시 시도해 주세요.",
  "NFT100": "해당 이벤트는 존재하지 않습니다.",
  "NFT101": "이벤트가 아직 시작하지 않았습니다.",
  "NFT102": "종료",
  "NFT103": "직접 구매는 불가합니다.",
  "NFT104": "바이아웃은 불가합니다.",
  "NFT105": "바이아웃 가격이 올바르지 않습니다.",
  "NFT106": "매진",
  "NFT107": "중복 구매는 불가합니다.",
  "NFT108": "NFT 자산 오류",
  "NFT110": "거래 수수료가 부족합니다.",
  "NFT111": "진행 중인 NFT가 아닙니다.",
  "NFT112": "출금 취소가 불가합니다.",
  "NFT113": "올바르지 않은 출금 정보입니다.",
  "NFT114": "매개변수 오류",
  "NFT115": "현재 입찰가보다 낮은 가격은 불가합니다.",
  "NFT116": "가격은 0보다 커야합니다.",
  "NFT117": "경매 랏이 존재하지 않습니다.",
  "NFT118": "거래를 취소할 수 없습니다",
  "NFT120": "출금 일시 중단",
  "NFT121": "입금 일시 중단",
  "NFT122": "계정의 출금 기능이 잠겨 있습니다. 자세한 내용은 고객지원팀에 문의해주세요.",
  "NFT123": "계정의 NFT 거래 기능이 잠겨 있습니다. 자세한 내용은 고객지원팀에 문의해주세요.",
  "OAUTH100001": "결제 페이지의 세션이 만료되었습니다. 새로고침 후 다시 시도해주세요.",
  "PB100000": "계정 자산 오류",
  "PB100001": "계정 자산 부족",
  "PB100002": "선주문 금액은 100이상이어야 합니다",
  "PB100003": "선주문 가격 오류",
  "PB100004": "코인 유형 오류",
  "PB100005": "가격이 변경되었으니, 다시 구매하십시오",
  "PB100006": "잘못된 선주문 금액",
  "PB110000": "아직 선주문 시간이 안되었습니다",
  "RT100": "인증코드 오류입니다.",
  "RT101": "파라미터 형식 오류",
  "RT102": "해당 거래는 존재하지 않습니다.",
  "RT103": "시스템 구성 오류",
  "RT104": "시스템 환경 이상",
  "RT201": "즐겨찾기가 최대에 도달했습니다.",
  "RT301": "서버환경 이상",
  "RT302": "서버환경 이상",
  "RT303": "서버환경 이상",
  "RT304": "서버환경 이상",
  "RT398": "서버환경 이상",
  "RT399": "현재 서버 요청이 과도한 상태입니다. 잠시 후 다시 시도해주세요.",
  "RT401": "지원되지 않는 전송 타입입니다",
  "RT999": "정의되지 않은 이상",
  "SL303": "네트워크가 복잡합니다. 잠시 후에 다시 시도하십시오",
  "SL304": "최대 주문량을 초과하여 주문이 실패하였습니다.",
  "SP801": "검증되어습니다 화폐신청메세지수정안됩니다",
  "SP802": "신청안되어습니다 먼저 화폐신청입력하시기바랍니다.",
  "SP803": "조작할수없습나다",
  "SP804": "화폐미지불 . 먼저 화페요금지불하시기바랍니다.",
  "SP805": "Fund not enough",
  "SP806": "Fee had paid",
  "SP807": "config Information not exists",
  "TF001": "지갑 계정의 자금 송금 기능이 잠겨 있습니다. 자세한 내용은 고객지원팀에 문의해주세요.",
  "TF002": "토큰거래 계정의 자금 송금 기능이 잠겨 있습니다. 자세한 내용은 고객지원팀에 문의해주세요.",
  "TF003": "무기한 계약 계정의 자금 송금 기능이 잠겨 있습니다. 자세한 내용은 고객지원팀에 문의해주세요.",
  "TF004": "MT5 계정의 자금 송금 기능이 잠겨 있습니다. 자세한 내용은 고객지원팀에 문의해주세요.",
  "TF005": "CApp 계정의 자금 송금 기능이 잠겨 있습니다. 자세한 내용은 고객지원팀에 문의해주세요.",
  "TS1001": "시스템 요청이 과도한 상태입니다. 잠시 후 다시 시도하십시오.",
  "TS1002": "시스템 요청이 과도한 상태입니다. 잠시 후 다시 시도하십시오.",
  "TS1003": "구매 주식 초과",
  "TS1004": "잔액 부족",
  "TS1005": "쿼리 실패",
  "TS1006": "구매 실패",
  "TS1007": "시스템 요청이 과도한 상태입니다. 잠시 후 다시 시도하십시오.",
  "TS1008": "시스템 요청이 과도한 상태입니다. 잠시 후 다시 시도하십시오.",
  "TS1009": "시스템 요청이 과도한 상태입니다. 잠시 후 다시 시도하십시오.",
  "TS1010": "시스템 요청이 과도한 상태입니다. 잠시 후 다시 시도하십시오.",
  "TS1011": "시스템 요청이 과도한 상태입니다. 잠시 후 다시 시도하십시오.",
  "TS1012": "시스템 요청이 과도한 상태입니다. 잠시 후 다시 시도하십시오.",
  "u100017": "가입된 계정이 아닙니다.",
  "u100018": "잘못된 수량입니다.",
  "u100019": "자산 업데이트 오류",
  "u100020": "포지션을 찾을 수 없습니다.",
  "u100021": "더 많은 포지션이 필요합니다.",
  "u100022": "검색하신 토큰을 찾을 수 없습니다.",
  "u100023": "토큰이 동결되었습니다.",
  "u100024": "검색하신 거래 페어를 찾을 수 없습니다.",
  "u100025": "비율 불일치 오류",
  "u100026": "수량 오류",
  "u100027": "스왑 수량 오류",
  "u100028": "풀이 비어 있습니다.",
  "u100029": "풀 밸런스가 잘못되었습니다.",
  "u100030": "상환 비율 오류입니다.",
  "u100031": "계정의 Swap 거래 기능이 잠겨 있습니다. 자세한 내용은 고객지원팀에 문의해주세요.",
  "u300006": "동결된 계정입니다. 고객지원팀으로 문의해주세요.",
  "u300007": "자산 오류",
  "u300008": "시스템 오버로드",
  "u300009": "이미 등록된 토큰입니다.",
  "UF001": "데이터가 비어서는 안됩니다",
  "UF002": "출금 주소는 최대 20개까지 가능합니다.",
  "UF003": "동일한 주소가 이미 존재",
  "UF015": "사용자 동결",
  "UF016": "인출 주소가 존재하지 않습니다",
  "UF017": "인출 주소가 본 사용자에게 속하지 않습니다",
  "UF018": "출금 주소를 확인하지 못했습니다.",
  "UF019": "계정의 출금 기능이 잠겨 있습니다. 자세한 내용은 고객지원팀에 문의해주세요.",
  "UF020": "매일 인출 한도 초과",
  "UF021": "사용자 자산 부족",
  "UF022": "미확인 충전 기록 존재",
  "UF023": "출금 주소를 확인하지 못했습니다.",
  "UF024": "잘못된 FIO 주소입니다.",
  "UF030": "주소 추가 실패, 인터넷 연결 확인후 재시도하십시오",
  "UF034": "취소 실패",
  "UF036": "인출 수수료 최저 한도액 이하",
  "UF037": "인출 금액 최저 한도액 이하",
  "UF038": "해당 코인/토큰은 비트포렉스 지갑 주소로 출금할 수 없습니다. 다른 주소로 출금을 신청해주세요.",
  "UF039": "시스템 유지보수 중, 출금이 일시적으로 불가합니다. 이용에 불편을 끼쳐드려 죄송합니다.",
  "UF101": "TXID 형식 오류, 확인하시기 바랍니다",
  "UF102": "최대 등록수 5번 초과,잠시 후 시도하시기 바랍니다",
  "UF108": "제출한 주소를 사용하여 충전하십시오",
  "UF301": "The latest price has changed. Please buy again.",
  "UF302": "잔액부족",
  "UF303": "Amount not allowed",
  "UF304": "렌트한도초과",
  "UF305": "현재반환불가능",
  "UF307": "사용자 렌트한도초과",
  "UF308": "참여가능 인수달성",
  "UF312": "강제전매되므로 반납과 렌트가 불가능합니다",
  "UF313": "입력하신 수량은 미납부수량을 초과하였습니다",
  "US1752": "전화번호 비활성화",
  "US1753": "이메일 주소 비활성화됨",
  "US1754": "본인인증 비활성화됨",
  "US1755": "계정 폐쇄",
  "US601": "잘못된 이메일 형식입니다. 다시 확인해주세요.",
  "US602": "비밀번호는 적어도 8자리에서 최대 20자리까지여야 하며, 대문자, 소문자, 숫자를 포함하여야 합니다. 스페이스는 불가능합니다.",
  "US604": "업로드 하시려는 파일의 크기가 너무 큽니다.",
  "US605": "형식에 맞지 않는 파일입니다.",
  "US607": "신분증 이미지를 업로드 해주세요.",
  "US608": "여권 이미지를 업로드 해주세요.",
  "US609": "신분인증(KYC)를 신청하시기 바랍니다",
  "US651": "해당 APIKey는 조작 사용자에게 속하지 않습니다",
  "US660": "이미지 인증에 실패하였습니다. 다시 시도해주세요.",
  "US661": "제출하신 신분증은 이미 다른 계정에 등록된 신분증입니다. 다시 확인 후 시도해 주세요.",
  "US665": "reCAPTCHA의 유효성 검사에 실패했습니다. 잠시 후 다시 시도하십시오",
  "US667": "등록된 핸드폰 번호를 입력해주세요.",
  "US668": "제출하신 신분증은 이미 다른 계정에 등록된 신분증입니다. 다시 확인 후 시도해 주세요.",
  "US671": "TOTP 인증 실패가 너무 많습니다. 잠시 후 다시 시도해주세요.",
  "US701": "이미 등록된 이메일 주소입니다.",
  "US702": "인증번호기간초과",
  "US703": "메일 인증 코드 오류",
  "US704": "존재하지 않는 사용자입니다",
  "US705": "귀하의 계정은 비밀번호 입력 오류로 일시 잠금 되었습니다. 고객지원팀으로 연락해주세요.",
  "US706": "귀하의 계정은 비밀번호 입력 오류로 일시 잠금 되었습니다. 1시간 후 다시 시도해주세요.",
  "US707": "연속5번입력에러 ,다시신청",
  "US708": "등록되지 않은 이메일입니다.",
  "US709": "메일 혹은 비밀번호 오류",
  "US710": "이미 등록된 휴대폰 번호입니다.",
  "US711": "인증 코드가 올바르지 않습니다.",
  "US712": "아이디 혹은 비밀번호 오류",
  "US715": "초대인 UID가 존재하지 않습니다.",
  "US724": "조작이 너무 많아 잠시 후에 다시 시도해 주십시오",
  "US725": "다른 지역 로그인 인증 실패",
  "US726": "조작이 너무 많아 잠시 후에 다시 시도해 주십시오",
  "US727": "조작이 너무 많아 잠시 후에 다시 시도해 주십시오",
  "US732": "6번이상 비밀번호 틀립니다 5분뒤 다시 입력",
  "US733": "거래 비밀번호 오류",
  "US750": "사용자가 메일을 바인딩하였습니다",
  "US752": "고객님 이메일 미 가입",
  "US753": "인증코드 오류입니다.",
  "US754": "인증코드 오류입니다.",
  "US756": "인증번호없습니다 혹은 실효",
  "US801": "사용자가 휴대폰을 바인딩하지 않았습니다",
  "US802": "30초 후에 인증코드 발송을 다시 요청해주세요.",
  "US803": "메시지 인증 코드 오류 횟수 제한 초과, 1시간후 재시도하십시오",
  "US804": "사용자가 휴대폰을 바인딩하였습니다",
  "US805": "이미 바인딩된 휴대폰입니다",
  "US806": "바인딩 휴대폰의 임시 정보가 존재하지 않습니다",
  "US807": "비밀번호 입력 오류 횟수가 5회 초과되었습니다. 비밀번호 재설정을 통해 로그인하세요.",
  "US808": "인증번호없습니다 혹은 실효",
  "US809": "정확한 메시지 인증 코드를 입력해주세요.",
  "US810": "보안인증을 설정하지 않았습니다",
  "US811": "구글 키를 바인딩하지 않았습니다",
  "US812": "정확한 구글 인증 코드를 입력해주세요.",
  "US813": "이전에 등록하신 휴대폰의 임시 정보가 존재하지 않습니다.",
  "US814": "신규 등록한 휴대폰의 임시 정보가 존재하지 않습니다.",
  "US815": "메시지 인증 코드 오류 횟수 제한 초과, 1시간 후에 다시 시도해주세요.",
  "US816": "정확한 메시지 인증 코드를 입력해주세요.",
  "US817": "정확한 기존 휴대폰의 메시지 인증 코드를 입력해주세요.",
  "US818": "메시지 인증 코드 오류 횟수 제한 초과, 1시간 후에 다시 시도해주세요.",
  "US819": "정확한 메시지 인증 코드를 입력하십시오",
  "US820": "정확한 신규 메시지 인증 코드를 입력하십시오",
  "US821": "바인딩 해제 휴대폰의 임시 정보가 존재하지 않습니다",
  "US822": "사용자가 구글 키를 바인딩하였습니다",
  "US823": "[보안인증] 존재하지 않습니다",
  "US824": "[보안인증] 수정 불가",
  "US825": "[보안인증] 무변경",
  "US826": "사용자가 보안인증을 바인딩하였습니다",
  "US829": "인증 실패, 다시 시도하시기 바랍니다.",
  "US830": "사용자 APIKey수량 제한 초과",
  "US832": "인증번호없습니다 혹은 실효",
  "US833": "인증번호틀립니다",
  "US834": "고객님 이메일 미 가입",
  "US835": "인증코드 오류입니다.",
  "US857": "이메일 인증코드 오류가 최대 5회를 초과하였습니다. 다시 시도해주세요.",
  "US901": "[알림명] 존재하지 않습니다",
  "US902": "알림 상황 무변경",
  "UW205": "죄송합니다. 고객님의 계정 내 자산이 동결되었습니다. 문의 사항이 있으시면 온라인 고객센터로 연락해주세요.",
  "UW208": "메일 인증 실패, 인터넷 연결 점검후 재시도하십시오",
  "UW209": "메일 전송 실패 \"재전송\"을 클릭하여 재전송하십시오",
  "UW210": "이메일 인증 코드가 만료되었습니다. 재전송을 눌러 다시 시도해주세요.",
  "UW213": "링크 실효, 클릭한 링크가 1시간내에 발송한 최신 링크임을 확인하십시오. 링크 실효시 발송 요청을 재제출 하십시오",
  "UW236": "출금을 위해서 먼저 보안 인증을 완료해주세요.",
  "WE101": "사용자가 로그인 되어 있지 않습니다",
  "WE102": "사용자 권한이 없습니다",
  "WE103": "사용자 IP가 허가 범위내에 있지 않습니다",
  "WE104": "사용자 방문 빈도 과도",
  "WE204": "귀하의 계정은 잠금되었습니다.",
  "WE205": "사용자 계정 동결",
  "WE206": "1일 한도를 초과하였습니다.",
  "WE207": "활동 자금 부족",
  "WE209": "메일 전송이 실패하였습니다.",
  "WE218": "실명인증을 완료하지 않은 사용자입니다.",
  "WE219": "실명인증을 완료해주세요."
}}